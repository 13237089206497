import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAnnounce } from '../../../models/domainConfig.model';

@Component({
  selector: 'fs-announce-img-viewer',
  templateUrl: './announce-img-viewer.component.html',
  styleUrls: ['./announce-img-viewer.component.scss'],
})
export class AnnounceImgViewerComponent {
  constructor(
    private dialogRef: MatDialogRef<AnnounceImgViewerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { announce: IAnnounce; appAnnounceVersion: string },
  ) {}

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
