import { Injector } from '@angular/core';

let StaticInjector: Injector;

export const SetStaticInjector = (injector: Injector) => {
  StaticInjector = injector;
};

export const GetStaticInjector = (): Injector => {
  return StaticInjector;
};
