import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Modalstatusservice } from '../../../../../shared/services/modalstatus.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';

@Component({
  selector: 'fs-group-single',
  templateUrl: './group-single.component.html',
  styleUrls: ['./group-single.component.scss'],
})
export class GroupSingleComponent implements OnInit {
  assetId: number;
  @Output() updatedObjExecutiveUserActions = new EventEmitter();
  arrListValuesForGroupSingle: any = [];
  groupSingleVal = '';

  constructor(
    private modalService: Modalstatusservice,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<GroupSingleComponent>,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.assetId = data.assetId;
  }

  ngOnInit() {
    this.arrListValuesForGroupSingle = [
      { name: 'G' },
      { name: 'S' },
      { name: 'GS' },
    ];
  }

  onGroupChanged(value: string) {
    this.groupSingleVal = value;
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  onClickApplyBtn() {
    if (this.groupSingleVal === '') {
      this.dialogRef.close();
      this.alertCustom(this.translate.instant('view-project.alert39'));
    } else {
      const obj = {
        assetId: this.assetId,
        groupSingle: this.groupSingleVal,
      };
      this.updatedObjExecutiveUserActions.emit(obj);
      this.dialogRef.close(obj);
    }
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
