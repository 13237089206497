import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SidebarService } from '../../../../sidebar-service/sidebar-service';
import { PaginationService } from '../../../pagination/pagination.service';
import { Activemodal } from '../../../../../../shared/models/activemodal.model';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../shared/services/loader.service';
import { Modalstatusservice } from '../../../../../../shared/services/modalstatus.service';
import { Utils } from '../../../../../../shared/utils/utils';
import { AppStateService } from '../../../../../../shared/services/app-state.service';
import { DialogService } from '../../../../../../shared/services/dialog/dialog.service';
import { GotoComponent } from '../../../top-menu/goto/goto.component';
import { takeUntil } from 'rxjs/operators';
import { LastSelectedImageStore } from '../../../../../../shared/store/last-selected-image-store';
import { SelectedImagesStore } from '../../../../../../shared/store/selected-images-store';
import { FilterEnum } from '../../../../../../shared/enum/filter.enum';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-talent-customfilter',
  templateUrl: './talent-customfilter.component.html',
  styleUrls: ['./talent-customfilter.component.scss'],
})
export class TalentCustomFilterComponent
  extends UnsubscriberComponent
  implements OnInit
{
  activemodal: Activemodal = new Activemodal();
  selectedOpt: any;
  routerObser: any;
  isLeftMenuPartShown = true;
  restartDataDTO: any;
  staticFilter = [{ name: 'Show All' }];
  dataGeneral = [{ name: 'Pending Approved' }, { name: 'Pending Rejected' }];
  isPrioritypixBtnShown: boolean;
  priritypixImageCount: number;
  currentFilter: string;
  noOfImages: any;

  constructor(
    private router: Router,
    private sidebarService: SidebarService,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private modalStatusService: Modalstatusservice,
    private translate: TranslateService,
    private paginationService: PaginationService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private appStateService: AppStateService,
    private dialogService: DialogService,
    private lastSelectedImageStore: LastSelectedImageStore,
    private selectedImagesStore: SelectedImagesStore,
  ) {
    super();
  }

  ngOnInit() {
    this.selectedOpt = this.staticFilter[0].name;

    this.viewProjectService.isLeftMenuOpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((leftmenuState) => {
        this.isLeftMenuPartShown = leftmenuState;
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.noOfImages = res.viewSettingDTO.imagePerPage;
        }
      });

    this.modalStatusService.getActiveModal
      .pipe(takeUntil(this.destroy$))
      .subscribe((activeModal) => {
        this.handleModalStatus();
      });

    this.viewProjectService.isPiorityPixDataLoadedGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.isPrioritypixBtnShown = res;
        if (this.isPrioritypixBtnShown) {
          this.selectedOpt = 'Show All';
        }
      });

    this.viewProjectService.priorityPixImageCountGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.priritypixImageCount = res;
      });

    this.viewProjectService.restartDataDTO
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.restartDataDTO = data;
      });

    this.viewProjectService.restartDataAll
      .pipe(takeUntil(this.destroy$))
      .subscribe((restartFilter) => {
        this.selectedOpt = 'Show All';
        if (this.restartDataDTO) {
          this.callShowAll(
            this.restartDataDTO.projectId,
            this.restartDataDTO.filter,
            this.noOfImages,
            this.restartDataDTO.pageNo,
            this.restartDataDTO.assetId,
          );
        } else {
          this.callShowAll(
            this.viewProjectService.getProjectId(),
            FilterEnum.ShowAll,
            this.noOfImages,
            1,
            0,
          );
        }
      });

    this.viewProjectService.restartPendingApproved
      .pipe(takeUntil(this.destroy$))
      .subscribe((restartFilter) => {
        this.selectedOpt = 'Pending Approved';
        if (this.restartDataDTO) {
          this.callShowAll(
            this.restartDataDTO.projectId,
            this.restartDataDTO.filter,
            this.noOfImages,
            this.restartDataDTO.pageNo,
            this.restartDataDTO.assetId,
          );
        } else {
          this.callShowAll(
            this.viewProjectService.getProjectId(),
            FilterEnum.ShowAll,
            this.noOfImages,
            1,
            0,
          );
        }
      });

    this.viewProjectService.restartDataAlbum
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.callShowAll();
      });

    this.viewProjectService.restartPendingRejected
      .pipe(takeUntil(this.destroy$))
      .subscribe((restartFilter) => {
        this.selectedOpt = 'Pending Rejected  ';
        if (this.restartDataDTO) {
          this.callShowAll(
            this.restartDataDTO.projectId,
            this.restartDataDTO.filter,
            this.noOfImages,
            this.restartDataDTO.pageNo,
            this.restartDataDTO.assetId,
          );
        } else {
          this.callShowAll(
            this.viewProjectService.getProjectId(),
            FilterEnum.ShowAll,
            this.noOfImages,
            1,
            0,
          );
        }
      });
  }

  callShowAll(projectId?, filterName?, numOfImages?, pageNum?, assetId?) {
    this.loaderService.displayLoader(true);
    this.viewProjectService.setExecutiveAlbumView(false);
    this.viewProjectService
      .callProjectImageIDs(
        projectId ? projectId : this.viewProjectService.getProjectId(),
        filterName ? filterName : FilterEnum.ShowAll,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res1) => {
          if (res1) {
            const assetsIds = Utils.getImageIdsBasedOnPage(
              pageNum ? pageNum : 1,
              numOfImages ? numOfImages : this.noOfImages,
              res1,
            );
            const data = {
              assetIds: assetsIds,
              projectId: projectId
                ? projectId
                : this.viewProjectService.getProjectId(),
              filter: filterName ? filterName : FilterEnum.ShowAll,
            };

            const routingProjectId = projectId
              ? projectId
              : this.viewProjectService.getProjectId();
            const routingPageNum = pageNum ? pageNum : 1;
            const routingFilter = filterName ? filterName : FilterEnum.ShowAll;
            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  this.router.navigate([
                    Utils.prepareFilterNavigationURL(
                      this.viewProjectService.getProjectRole(),
                      routingProjectId,
                      routingFilter,
                      routingPageNum,
                    ),
                  ]);

                  if (pageNum) {
                    this.paginationService.setPaginationIndex(
                      pageNum ? pageNum : 1,
                    );
                  }
                  this.handleProjectData(resData);
                  if (assetId !== 0 && assetId) {
                    const lastSelectedImageOnRestat =
                      this.getLastSelectedImagesOnRestatData(resData, assetId);
                    this.lastSelectedImageStore.set(
                      lastSelectedImageOnRestat[0],
                    );
                  } else {
                    this.lastSelectedImageStore.clear();
                  }
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(this.translate.instant('view-project.alert2'));
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  getLastSelectedImagesOnRestatData(res, assetId) {
    return res.assetInfoDTOList.filter((imageBbj) => {
      return imageBbj.id === assetId;
    });
  }

  handleProjectData(res) {
    this.selectedImagesStore.clear();
    this.viewProjectService.setExecutiveAlbum(false);
    this.sidebarService.setAlbumImageData(res);
    this.loaderService.displayLoader(false);
  }

  handleModalStatus() {
    this.dialogService
      .getDialogState(GotoComponent.identifier)
      .state$.pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        if (state.opened) {
          this.selectedOpt = 'Show All';
        }
      });

    this.appStateService.showAll$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ loaded }) => {
        if (loaded) {
          this.selectedOpt = 'Show All';
        }
      });
  }

  clickOnSelectFilter(selectedOptionData, evt) {
    evt.preventDefault();
    if (selectedOptionData === 'Show All') {
      this.viewProjectService.setSelectedBatchId(undefined);
      this.selectedOpt = selectedOptionData;
      this.callShowAll();
    }
  }

  clickOnGeneral(list, evt) {
    evt.preventDefault();
    switch (list.name) {
      case 'Pending Approved':
        this.viewProjectService.setSelectedBatchId(undefined);
        this.callDataFilterAPI(FilterEnum.PendingApproved, list.name);
        break;
      case 'Pending Rejected':
        this.viewProjectService.setSelectedBatchId(undefined);
        this.callDataFilterAPI(FilterEnum.PendingRejected, list.name);
        break;
    }
  }

  callDataFilterAPI(path, name) {
    this.loaderService.displayLoader(true);
    const filter = path;
    this.viewProjectService
      .callProjectImageIDs(this.viewProjectService.getProjectId(), filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res1) => {
          if (res1) {
            this.currentFilter = filter;
            const assetsIds = Utils.getImageIdsBasedOnPage(
              1,
              this.noOfImages,
              res1,
            );
            const data = {
              assetIds: assetsIds,
              projectId: this.viewProjectService.getProjectId(),
              filter: this.currentFilter,
            };

            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  if (resData.assetInfoDTOList.length > 0) {
                    this.selectedOpt = name;
                    this.router.navigate([
                      Utils.prepareFilterNavigationURL(
                        this.viewProjectService.getProjectRole(),
                        this.viewProjectService.getProjectId(),
                        this.currentFilter,
                        1,
                      ),
                    ]);

                    this.selectedImagesStore.clear();
                    this.sidebarService.setAlbumImageData(resData);
                  } else {
                    this.alertCustom(
                      this.translate.instant('view-project.alert2'),
                    );
                  }
                  this.loaderService.displayLoader(false);
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(this.translate.instant('view-project.alert2'));
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
