<div class="image-color-cont">
  <ng-container
    *ngIf="
      (albumColorTagsService.isColorPanelEnabled$ | async) &&
      imgData?.colorCodes?.length > 0
    "
  >
    <ng-container
      class="d-flex justify-content-center"
      *ngFor="let colorName of imgData.colorCodes"
    >
      <div
        *ngIf="colorName"
        class="fs-album-color-container mr-1 d-inline-block"
      >
        <label
          class="fs-album-color-round"
          [style.background-color]="colorName"
        >
        </label>
      </div>
    </ng-container>
  </ng-container>
</div>
