import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { takeUntil } from 'rxjs/operators';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';
import {LoaderService} from '../../../../../shared/services/loader.service';

@Component({
  selector: 'fs-image-video',
  templateUrl: './image-video.component.html',
  styleUrls: ['./image-video.component.scss'],
})
export class ImageVideoComponent
  extends UnsubscriberComponent
  implements OnInit
{
  videoUrl = '';
  isVideoRender = false;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    evt: KeyboardEvent,
  ) {
    this.closeWindow();
  }

  constructor(
    private viewProjectService: ViewProjectService,
    private logger: NGXLogger,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private dialogRef: MatDialogRef<ImageVideoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { imageVideoData: any },
  ) {
    super();
  }

  ngOnInit() {
    this.viewProjectService
      .getImageVideoUrl(this.data.imageVideoData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((resData) => {
        if (resData.status) {
          this.isVideoRender = true;
          this.videoUrl = resData?.message;
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
