import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Executive } from './executive.model';
import { ApiService } from '../../shared/services/api.service';
import { AppSettings } from '../../appSettings/appSettings';

@Injectable()
export class ExecutiveService {
  private executive = new BehaviorSubject<Executive>(new Executive());
  public executiveList = this.executive.asObservable();

  private setUpName = new BehaviorSubject<string>('');
  setUpNameGlobal = this.setUpName.asObservable();
  constructor(private apiService: ApiService) {}

  setSetUpNameGlobal(label) {
    this.setUpName.next(label);
  }

  setDepartmentData(data) {
    this.executive.next(data);
  }

  getExecutiveDataApiCall(executiveId): Observable<Executive[]> {
    const path = AppSettings.EXECUTIVE_PROJECTS(executiveId);
    return this.apiService.get(path).pipe(
      map((data) => {
        this.setDepartmentData(data);
        return data;
      }),
    );
  }
}
