<div
  #scrolling
  id="page-content-wrapper"
  class="db_com pcw"
  [ngClass]="{ leftmenufull: !isLeftMenuPartShown }"
>
  <div class="container-fluid">
    <div class="viewgallery_wpr">
      <div class="view_gal_cont" *ngIf="viewGallery">
        <div
          class="gallery_cont"
          *ngFor="let galInfo of viewGallery; index as ind"
        >
          <div
            class="gal_img"
            [ngClass]="{ potrait_bg: isPotrait[ind] }"
            [hidden]="!imageLoaded[ind]"
            preventdrag
          >
            <img
              *ngIf="!(galInfo.coverImage === ''); else fsSetupCoverImage300"
              id="img"
              [imageSrcWithAuthToken]="galInfo.url"
              [ngClass]="{ potrait_img: isPotrait[ind] }"
              (click)="viewGalAlbumInfo(galInfo)"
              (load)="onLoad($event, ind)"
            />
            <ng-template #fsSetupCoverImage300>
              <img
                id="img"
                src="/assets/images/setups_if_no_cover_image_300_300.png"
                [ngClass]="{ potrait_img: isPotrait[ind] }"
                (click)="viewGalAlbumInfo(galInfo)"
                (load)="onLoad($event, ind)"
              />
            </ng-template>
          </div>

          <div class="galbtn" (click)="viewGalAlbumInfo(galInfo)">
            {{ galInfo.label }}
          </div>
          <div class="galcount">
            {{ galInfo.assetCount }}
            {{ 'view-project.group.images' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
