import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { LauncherService } from '../../../launcher/launcher.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigKey } from '../../../../appSettings/key-config';
import { AssetType } from '../../../../shared/models/asset-type.model';
import { TopmenusService } from '../../dashboard-shared/top-menu/top-menu.service';
import { AssetInfo } from '../../../../shared/models/assetInfo.model';
import { ProjectDetailPermission } from '../../../../shared/models/projectpermission.model';
import { UserDetails } from '../../../../shared/models/userdetails.model';
import { UserRole } from '../../../../shared/enum/user-role.enum';
import { ViewProjectService } from '../../../../shared/services/view-project.service';
import { SidebarService } from '../../sidebar-service/sidebar-service';
import { Modalstatusservice } from '../../../../shared/services/modalstatus.service';
import { PaginationService } from '../../dashboard-shared/pagination/pagination.service';
import { UserService } from '../../../../shared/services/user.service';
import { AuthServerProvider } from '../../../../shared/services/auth.jwt.service';
import { TaggerService } from '../../../../shared/services/tagger.service';
import { Utils } from '../../../../shared/utils/utils';
import { takeUntil } from 'rxjs/operators';
import { AppStateService } from '../../../../shared/services/app-state.service';
import { TopMenuComponent } from '../../dashboard-shared/top-menu/top-menu.component';
import { DialogService } from '../../../../shared/services/dialog/dialog.service';
import { CaptionComponent } from './caption/caption.component';
import { SelectedImagesStore } from '../../../../shared/store/selected-images-store';
import { LastSelectedImageStore } from '../../../../shared/store/last-selected-image-store';
import { UnsubscriberComponent } from '../../../../shared/components/unsubscriber/unsubscriber.component';
import { DownloadService } from '../../dashboard-shared/top-menu/download/download.service';
import { ProjectRole } from '../../../../shared/enum/project-role.enum';
import {ApiErrorHandlerService} from '../../../../shared/services/api-error-handler.service';
import {LoaderService} from '../../../../shared/services/loader.service';

@Component({
  selector: 'fs-caption-enlarge',
  templateUrl: './caption-enlarge.component.html',
  styleUrls: ['./caption-enlarge.component.scss'],
})
export class CaptionEnlargeComponent
  extends UnsubscriberComponent
  implements OnInit, OnDestroy
{
  ShowHideRejected = true;
  projectId: string;
  projectImageData: AssetInfo = new AssetInfo();
  enlargeImageData = [];
  viewSetting: ProjectDetailPermission = new ProjectDetailPermission();

  noOfImages = 1;
  pageNo: number;
  imageId: string;
  currentImageNum: number;
  imageObjIndex = 0;
  currentViewProjectPageNu: number;
  isSuperZoom = false;
  albumListObser: any;
  modalStatusObser: any;
  isLeftMenuPartShown = true;
  imageLoaded: boolean[] = [];
  isLeftAlbumActive: boolean;
  selectedLeftAlbum: any;
  timer: any;
  pendingRequest: any;
  pendingDataRequest: any;
  topMenuComp: any;
  currentUser: UserDetails = new UserDetails();
  projectAuthority: string;
  userAuthority: string;
  mouseHide: boolean;
  selectedProjectValue: any;
  isExecutiveAlbumView = false;
  secureStorage = true;
  token: any;

  // On Focus Global
  onFocusGlobal = false;
  currentFilter: string;
  hasDownloadAccess = false;
  isEnlargeView = false;
  projectDetailPermission: ProjectDetailPermission =
    new ProjectDetailPermission();
  isCloseEnlarge = false;
  projectImageIds: any;
  ignorFirstPaginationEvent = true;
  mouseExcluded = true;
  userRole = UserRole;
  projectRole = ProjectRole;
  assetType = AssetType;
  routeParams: Route;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public viewProjectService: ViewProjectService,
    private sidebarService: SidebarService,
    private modalService: Modalstatusservice,
    private downloadService: DownloadService,
    private topmenusService: TopmenusService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private paginationService: PaginationService,
    private elementRef: ElementRef,
    private userService: UserService,
    private launcherService: LauncherService,
    private translate: TranslateService,
    private auth: AuthServerProvider,
    private taggerService: TaggerService,
    private appStateService: AppStateService,
    private dialogService: DialogService,
    private selectedImagesStore: SelectedImagesStore,
    private lastSelectedImageStore: LastSelectedImageStore,
  ) {
    super();
    this.token = this.auth.getToken();
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.params.projectId;
    this.routeParams = this.route.snapshot.params;

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isEnlargeView =
          event.url.search('enlarge') !== -1 ||
          event.url.search('compare') !== -1;
      }
    });

    this.viewSetting =
      this.viewProjectService.mergeWithProjectDetailPermissionData(
        this.viewSetting,
      );

    this.currentUser = this.userService.mergeWithCurrentUser(this.currentUser);

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority) => {
        this.userAuthority = userAuthority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.topmenusService.setTopMenuStatus('Enlarge');
    this.paginationService.setPaginationTempalte('EnlargeImage');
    this.topmenusService.setTopMenuPagination(true);

    this.viewProjectService.projectImageIdsGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageIds) => {
        this.projectImageIds = imageIds;
        this.paginationService.setPaginationLastIndexGlobal(
          this.projectImageIds.length,
        );
      });

    this.paginationService.paginationIndexGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((index) => {
        if (!this.ignorFirstPaginationEvent) {
          if (index === -1) {
            this.lastPage();
          } else if (index > 0 && !this.isCloseEnlarge) {
            this.paginationEvt(index);
          }
        } else {
          this.ignorFirstPaginationEvent = false;
        }
      });

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((parameters) => {
      this.imageId = parameters['imageId'];
      this.projectId = parameters.projectId.slice(
        1,
        parameters.projectId.length,
      );
      this.currentFilter = parameters.filterId;
      if (parameters.type === 'caption') {
        this.currentImageNum =
          this.projectImageIds.indexOf(Number(this.imageId)) + 1;
      }
      this.imageSetup(this.imageId);
    });

    this.handleModalStatus();

    this.viewProjectService.isLeftMenuOpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((leftmenuState) => {
        this.isLeftMenuPartShown = leftmenuState;
      });

    this.sidebarService.selectedAlbum
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        this.selectedLeftAlbum = album;
      });

    this.launcherService.selectedProjectGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectValue) => {
        this.selectedProjectValue = Number(projectValue);
      });

    this.viewProjectService.isExecutiveAlbumGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isExecutiveAlbumView = status;
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.projectDetailPermission = data;
        if (!Utils.isObjectEmpty(data)) {
          this.hasDownloadAccess = !!data.permissionDTO.downloadType;
          if (data.permissionDTO.secureAccess) {
            this.secureStorage = data.permissionDTO.secureAccess;
          }
        }
      });

    this.viewProjectService.setIsRedirectedFromEnlarge(true);
    this.openCationComponentDialog();
  }

  openCationComponentDialog() {
    this.dialogService.open(CaptionComponent, {
      data: {
        currentImageNum: this.currentImageNum,
        routeParams: this.routeParams,
        superZoomCloseFn: () => this.closeZoom(),
        closeWindowCaptionFn: () => this.closeZoom(),
        superZoomFn: () => this.superZoom(),
        pageChangeFn: () => this.onPageChanged,
      },
      position: {
        top: '110px',
        left: '20px',
      },
      enableHandlingUpKey: true,
      listener: {
        withListener: true,
        id: 'CaptionComponent',
      },
      hasBackdrop: false,
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-width-600-panel',
        'fs-dialog-no-shadow-panel',
        'fs-dialog-caption-panel',
      ],
    });
  }

  showRejected(x) {
    if (x === true) {
      this.ShowHideRejected = true;
      this.mouseExcluded = true;
    }
  }

  hideRejected(y) {
    if (y === true) {
      this.ShowHideRejected = false;
      this.mouseExcluded = false;
    }
  }

  onPageChanged = (event) => {
    this.currentImageNum = event;
    this.paginationEvt(this.currentImageNum);
  };

  imageSetup(imageId) {
    this.enlargeImageData = [];
    const data = {
      assetIds: [imageId],
      projectId: this.projectId,
      filter: this.currentFilter,
    };
    if (this.pendingRequest) {
      this.pendingRequest.unsubscribe();
    }
    this.pendingRequest = this.viewProjectService
      .callSingleAssetsInfoBasedOnAssetsId(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.enlargeImageData.push(res.assetInfoDTOList[0]);
        this.currentImageNum =
          this.projectImageIds.indexOf(Number(imageId)) + 1;
        this.mouseHide = res.assetInfoDTOList[0].rejected;
        this.handleLastSelectedImages(res.assetInfoDTOList);

        this.loadLastSelectedImage(res.assetInfoDTOList[0].id);
        this.paginationEvt(this.currentImageNum);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  onClickImage(event) {
    event.preventDefault();
  }

  paginationEvt(index) {
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    this.imageLoaded = [];
    if (index) {
      this.currentImageNum = index;
    }
    this.currentViewProjectPageNu = Math.ceil(
      this.currentImageNum / this.viewSetting.viewSettingDTO.imagePerPage,
    );

    const indexEnalegeData = this.enlargeImageData
      .map(function (e) {
        return e.id;
      })
      .indexOf(this.projectImageIds[index - 1]);

    if (indexEnalegeData === -1) {
      // Jaydeep
      this.handleEnlargeRoute();

      this.loadLastSelectedImage(
        this.projectImageIds[this.currentImageNum - 1],
      );
    }

    const indexViewprojectData = this.projectImageData.assetInfoDTOList
      .map(function (e) {
        return e.id;
      })
      .indexOf(this.projectImageIds[index - 1]);

    if (indexViewprojectData === -1) {
      this.loadProjectDataWithAssetIds();
    }
  }

  loadProjectDataWithAssetIds() {
    if (this.pendingDataRequest) {
      this.pendingDataRequest.unsubscribe();
    }
    const assetsIds = Utils.getImageIdsBasedOnPage(
      this.currentViewProjectPageNu,
      this.viewSetting.viewSettingDTO.imagePerPage,
      this.projectImageIds,
    );
    const data = {
      assetIds: assetsIds,
      projectId: this.projectId,
      filter: this.currentFilter,
    };
    this.pendingDataRequest = this.viewProjectService
      .callAssetsInfoBasedOnAssetsId(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.projectImageData = res;
        this.viewProjectService.setProjectData(this.projectImageData);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  selectImageOnDoubleClick() {
    this.superZoom();
  }

  selectZoomImageOnDoubleClick() {
    this.closeZoom();
  }

  onLoad(i) {
    this.imageLoaded[i] = true;
  }

  closeZoom = () => {
    this.imageLoaded = [];
    if (this.isSuperZoom) {
      this.isSuperZoom = !this.isSuperZoom;
      this.topmenusService.setTopMenuStatus('Enlarge');
    } else {
      this.viewProjectService.setCurrentPageNumber(
        this.currentViewProjectPageNu,
      );
      this.isCloseEnlarge = true;
      this.sidebarService.resetAlbumImageData();
      this.paginationService.setPaginationIndex(this.currentViewProjectPageNu);
      this.modalService.resetActiveModal();
      this.topmenusService.setTopMenuStatus('');
      this.dialogService.closeDialogById(CaptionComponent.identifier);
      this.router.navigate([
        Utils.prepareFilterNavigationURL(
          this.viewProjectService.getProjectRole(),
          this.viewProjectService.getProjectId(),
          this.currentFilter,
          this.currentViewProjectPageNu,
        ),
      ]);
    }
  };

  superZoom = () => {
    this.isSuperZoom = true;
    this.imageLoaded = [];
    this.topmenusService.setTopMenuStatus('SuperZoom');
  };

  lastPage(): void {
    this.imageLoaded = [];
    this.currentImageNum = this.projectImageIds.length;
    this.handleEnlargeRoute();
  }

  handleLastSelectedImages(imageData) {
    this.lastSelectedImageStore.set(imageData[0]);
  }

  loadLastSelectedImage(imageID) {
    const restartData = {
      filter: this.currentFilter,
      assetId: imageID,
      projectId: this.viewProjectService.getProjectId(),
      pageNo: this.currentViewProjectPageNu,
    };
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.viewProjectService
        .callLastSelectedImage(restartData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {},
          (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    }, 3000);
  }

  handleModalStatus() {
    this.appStateService.zoomDialog$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: { loaded: boolean }) => {
        if (state.loaded) {
          this.superZoom();
        }
      });

    this.dialogService
      .getDialogState(TopMenuComponent.identifier)
      .state$.pipe(takeUntil(this.destroy$))
      .subscribe(({ opened }) => {
        if (opened) {
          this.superZoom();
        }
      });

    this.appStateService.closeZoomDialog$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ loaded }: { loaded: boolean }) => {
        if (loaded) {
          this.closeZoom();
        }
      });

    this.appStateService.approveDialog$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ loaded }: { loaded: boolean }) => {
        if (loaded) {
          this.approveBorder();
        }
      });

    this.appStateService.rejectDialog$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ loaded }: { loaded: boolean }) => {
        if (loaded) {
          this.rejectBorder();
        }
      });
  }

  approveBorder() {
    const obj = this.prepareExecutiveAlbumTagObj(
      1,
      this.projectImageIds[this.currentImageNum - 1],
    );
    this.callExicutiveAlbumTag(obj);
  }

  rejectBorder() {
    const obj = this.prepareExecutiveAlbumTagObj(
      3,
      this.projectImageIds[this.currentImageNum - 1],
    );
    this.callExicutiveAlbumTag(obj);
  }

  callExicutiveAlbumTag(obj) {
    this.viewProjectService
      .postExecutiveAlbumTag(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const imageIndex = this.projectImageData.assetInfoDTOList
          .map(function (e) {
            return e.id;
          })
          .indexOf(obj.assetId);

        if (this.selectedProjectValue === 3) {
          this.projectImageData.assetInfoDTOList[
            imageIndex
          ].executiveSignoffDTOList = res;
        } else {
          this.projectImageData.assetInfoDTOList[
            imageIndex
          ].executiveWideEditDTOList = res;
        }

        res.forEach((element) => {
          if (element.userId === this.currentUser.id) {
            if (element.actionTypeId === 3) {
              this.projectImageData.assetInfoDTOList[
                imageIndex
              ].exicutiveRejected = 3;
              this.projectImageData.assetInfoDTOList[imageIndex].exicutiveLike =
                false;
            } else {
              this.projectImageData.assetInfoDTOList[
                imageIndex
              ].exicutiveRejected = 1;
            }
          }
        });
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  prepareExecutiveAlbumTagObj(actionTypeId: number, assetId: number) {
    return {
      actionTypeId: actionTypeId,
      albumId: this.viewProjectService.getExecutiveAlbumID(),
      assetId: assetId,
      projectId: this.projectId,
      typeId: this.selectedProjectValue,
    };
  }

  callAlbumTagging(id) {
    const data = {
      assetIds: [this.projectImageIds[this.currentImageNum - 1]],
      projectId: this.projectId,
      id: id,
    };

    this.sidebarService
      .callAPIToTaggingAlbumData(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.sidebarService.setUpdatedTaggingAlbum(res);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  onClickAdminExcludeRelease() {
    // Call web service
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    const obj = {
      assetIds: [this.projectImageIds[this.currentImageNum - 1]],
      projectId: Number(this.projectId),
    };
    this.viewProjectService
      .putSetExcudeRelease(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.status) {
          res.t.forEach((element) => {
            const index = this.projectImageData.assetInfoDTOList
              .map(function (e) {
                return e.id;
              })
              .indexOf(element.assetId);
            this.projectImageData.assetInfoDTOList[index].excluded =
              element.excluded;
            this.projectImageData.assetInfoDTOList[index].releasedDate =
              element.releasedDate;

            if (this.enlargeImageData.length > 0) {
              this.enlargeImageData[0].excluded = element.excluded;
              this.enlargeImageData[0].releasedDate = element.releasedDate;
            }
            if (this.selectedImagesStore.size() === 1) {
              this.selectedImagesStore.set(
                this.projectImageData.assetInfoDTOList[index],
              );
            }
          });
        } else {
          this.alertCustom(res.message);
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  onRightClick() {
    return false;
  }

  removeFocusout() {
    window.focus();
  }

  isDialogOpenedWithAllowedKeys() {
    return (
      !this.dialogService.isAtLeastOneOpened() ||
      this.dialogService.isHandlingUpKeyEnabled()
    );
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyEvents(event: KeyboardEvent) {
    if (
      this.isDialogOpenedWithAllowedKeys() &&
      !this.viewProjectService.isInputFocused()
    ) {
      if (event.keyCode === ConfigKey.ArrowRight) {
        if (this.currentImageNum === this.projectImageIds.length) {
          this.alertCustom(this.translate.instant('view-project.alert7'));
        } else {
          this.currentImageNum++;
          this.paginationEvt(this.currentImageNum);
        }
      } else if (event.keyCode === ConfigKey.ArrowLeft) {
        if (this.currentImageNum === 1) {
          this.alertCustom(this.translate.instant('view-project.alert7'));
        } else {
          this.currentImageNum--;
          this.paginationEvt(this.currentImageNum);
        }
      }
      if (event.keyCode === ConfigKey.ArrowUp) {
        this.superZoom();
      } else if (
        event.keyCode === ConfigKey.ArrowDown ||
        event.keyCode === ConfigKey.Esc
      ) {
        this.closeZoom();
      } else if (event.keyCode === ConfigKey.Space) {
        if (this.isSuperZoom) {
          this.closeZoom();
        } else {
          this.superZoom();
        }
      }

      if (this.hasDownloadAccess) {
        if (
          event.keyCode === ConfigKey.lowerD ||
          event.keyCode === ConfigKey.upperD
        ) {
          this.downloadService
            .downloadAssets(
              this.isEnlargeView,
              this.projectDetailPermission,
              this.userAuthority,
              this.projectAuthority,
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe();
        }
      }
      if (
        event.keyCode === ConfigKey.lowerX ||
        event.keyCode === ConfigKey.upperX
      ) {
        if (
          this.userAuthority === UserRole.ROLE_ADMIN ||
          this.projectAuthority === ProjectRole.ROLE_EXEC
        ) {
          this.onClickAdminExcludeRelease();
        }
      }
      // Album Tagging
      if (
        event.keyCode === ConfigKey.lowerT ||
        event.keyCode === ConfigKey.upperT
      ) {
        if (this.selectedLeftAlbum) {
          this.callAlbumTagging(this.selectedLeftAlbum.id);
        }
      }
    }
  }

  handleEnlargeRoute() {
    this.router.navigate([
      '/dashboard/viewproject/' +
        this.viewProjectService.getProjectRole() +
        this.projectId +
        '/filter/' +
        this.currentFilter +
        '/page/' +
        this.currentViewProjectPageNu +
        '/enlarge/' +
        this.projectImageIds[this.currentImageNum - 1] +
        '/caption',
    ]);
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  onClickTelentApproveReject(imageData) {
    // Call web service
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    const obj = {
      assetId: imageData.id,
      rejected: imageData.rejected,
      projectId: this.projectId,
      tagId: 0,
    };
    this.viewProjectService
      .putSetTalentActionRejected(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.rejected) {
          this.mouseHide = true;
        } else {
          this.mouseHide = false;
        }
        const imgIndex = this.projectImageData.assetInfoDTOList
          .map(function (e) {
            return e.id;
          })
          .indexOf(imageData.id);
        if (imgIndex !== -1) {
          this.projectImageData.assetInfoDTOList[imgIndex].rejected =
            res.rejected;
        }
        this.viewProjectService.setProjectData(this.projectImageData);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  onRightClickPress(imgData: any) {
    if (imgData.rejected) {
      this.mouseHide = false;
    }

    if (imgData.excluded) {
      this.mouseExcluded = false;
    }
  }

  onRightClickLeave(imgData) {
    if (imgData.rejected) {
      this.mouseHide = true;
    }
    if (imgData.excluded) {
      this.mouseExcluded = true;
    }
  }

  ngOnDestroy() {
    if (this.pendingRequest) {
      this.pendingRequest.unsubscribe();
    }
    this.dialogService.closeDialogById(CaptionComponent.identifier);
    this.enlargeImageData = [];
    super.ngOnDestroy();
  }
}
