<div class="container-fluid dep-exe-container">
  <div class="block-header">
    <span
      >{{ 'dashboard.galleries' | translate }} (
      {{ galleryInfo.length }} )</span
    >
    <div class="search-project-comp">
      <fs-search-project
        [searchList]="galleryInfo"
        [fromDepartment]="false"
      ></fs-search-project>
    </div>
  </div>
  <div class="galleryWrap">
    <div class="flex-container">
      <div class="flex-item project-block" *ngFor="let group of galleryInfo">
        <div class="header-block">
          <div class="img-block">
            <img src="/assets/images/demo-img.jpeg" />
          </div>
          <div class="header-name">{{ group.groupName }}</div>
        </div>
        <div class="body-content" *ngIf="group.items.length">
          <div>
            <div *ngFor="let item of group.items">
              <a href="javascript:void(0)" (click)="galListInfo(group, item)">
                <span>
                  {{ item.label }}
                </span>
                <span *ngIf="item.setupCount > 0">
                  ({{ item.setupCount }} {{ 'dashboard.setups' | translate }})
                </span>
                <span>
                  {{ 'dashboard.posted' | translate }} {{ item.createdAt }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
