import { FavListModel } from '../interfaces/dashboard-favourite-rule.model';
import { DashboardFavouriteProjectRuleset } from '../rule-set/dashboard-favourite-project-ruleset';
import { LauncherService } from '../../../../launcher/launcher.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardFavouriteProjectHandler {
  private launcherService = GetStaticInjector().get(LauncherService);
  private viewProjectService = GetStaticInjector().get(ViewProjectService);

  constructor(private selectedProject: FavListModel) {}

  handle() {
    this.viewProjectService.talentFirstTimePriorityCount.next(true);
    this.viewProjectService.setSelectedProjectData(this.selectedProject);
    this.launcherService.setSelectedDepLogo(this.selectedProject.logo);
    this.launcherService.setSelectedProject(this.selectedProject.value);
    this.viewProjectService.setProjectRole(this.selectedProject.role);
    this.launcherService.setSelectedProjectName(
      this.selectedProject.displayText,
    );

    return new DashboardFavouriteProjectRuleset(this.selectedProject).apply();
  }
}
