import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettings } from '../../../../../appSettings/appSettings';
import { ProjectDetailPermission } from '../../../../../shared/models/projectpermission.model';
import { ApiService } from '../../../../../shared/services/api.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';

@Injectable()
export class ViewsettingsService {
  projectDetailPermission: ProjectDetailPermission =
    new ProjectDetailPermission();

  constructor(
    private apiService: ApiService,
    private viewProjectService: ViewProjectService,
  ) {}

  setViewSettingsApiCall(body): Observable<any> {
    const path = AppSettings.VIEW_SETTING;
    return this.apiService.put(path, body).pipe(map((data) => data));
  }

  defaultViewSettings(columns, images, size, global) {
    const viewsettings = {
      global: global,
      projectId: this.viewProjectService.getProjectId(),
      columnPerPage: columns,
      imagePerPage: images,
      size: size,
    };
    return viewsettings;
  }
}
