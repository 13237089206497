<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.actor-approval-report.title'
        | translate
    }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="container">
    <div class="row">
      <div
        class="col-lg-12 col-md-12 col-sm-12 col-xs-12 select_talent"
        id="select_talent_drp"
      >
        <div>
          <mat-select
            class="fs-selectbox-dark"
            [placeholder]="
              'left-menu.tab-bar.sidemenus.administration.actor-approval-report.labels.select-talent'
                | translate
            "
            [value]="selectedTalent"
            (valueChange)="onChangeListDropdoen($event)"
          >
            <mat-option
              class="fs-sm-txt fs-selectbox-options"
              *ngFor="let data of dataTalents"
              [value]="data"
            >
              {{ data.tagName }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button class="fs-dialog-btn w-auto" (click)="closeWindow()">
    {{
      'left-menu.tab-bar.sidemenus.administration.actor-approval-report.button.close'
        | translate
    }}
  </button>
</div>
