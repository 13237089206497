import { AppSource } from './enum/AppSource';

export interface IDomain {
  abbr?: AppSource;
  actorApprovalReport?: boolean;
  alt?: string;
  appleAppLogo?: string;
  appleAppUrl?: string;
  logo?: string;
  version?: string;
  outageMessage?: string;
  newFeatureAnnounce?: INewFeatureAnnounce;
}

export class DomainConfig implements IDomain {
  constructor(
    public logo?: string,
    public alt?: string,
    public actorApprovalReport?: boolean,
    public abbr?: AppSource,
    public appleAppLogo?: string,
    public appleAppUrl?: string,
    public version?: string,
    public outageMessage?: string,
    public newFeatureAnnounce?: INewFeatureAnnounce,
  ) {
    this.logo = logo || 'splash.gif';
    this.alt = alt || 'Film Solutions, LLC';
    this.actorApprovalReport = !!actorApprovalReport;
    this.abbr = abbr || AppSource.ALL;
    this.appleAppLogo = appleAppLogo || '';
    this.appleAppUrl = appleAppUrl || '';
    this.version = version || '';
    this.outageMessage = outageMessage || '';
    this.newFeatureAnnounce = newFeatureAnnounce || ({} as INewFeatureAnnounce);
  }
}

export interface INewFeatureAnnounce {
  version: string;
  announces: IAnnounce[];
}

export interface IAnnounce {
  announceText?: string;
  enus?: string;
  br?: string;
  de?: string;
  fr?: string;
  ja?: string;
  kr?: string;
  announceImgPath?: string;
}
