<div class="tag-info-enlarg">
  <div class="menu-item project-info">
    <span class="my_folders">
      <span>Tag Info</span>
    </span>
  </div>
  <div class="basic-filter d-flex">
    <span
      [ngClass]="{
        'view-1': taggingView1Open,
        'view-2': taggingView2Open,
        'view-3': auditTagViewOpen
      }"
    >
      {{ tagData }}
    </span>
  </div>
</div>
