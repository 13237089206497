import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '../../../../../../shared/services/snack-bar.service';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { SelectedImagesStore } from '../../../../../../shared/store/selected-images-store';

@Component({
  selector: 'fs-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  passwordForm: UntypedFormGroup;
  isClickedOnClearSingleTag: boolean;
  isClickedOnClearTag: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<PasswordComponent>,
    private viewProjectService: ViewProjectService,
    private snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public selectedImagesStore: SelectedImagesStore,
  ) {
    this.isClickedOnClearSingleTag = data.isClickedOnClearSingleTag;
    this.isClickedOnClearTag = data.isClickedOnClearTag;
  }

  ngOnInit(): void {
    this.createFormControls();
  }

  @HostListener('document:keydown.escape') onKeydownHandler() {
    this.closeWindow();
  }

  createFormControls() {
    this.passwordForm = this.fb.group({
      password: ['', Validators.required],
    });
  }

  submitPasswordForm() {
    if (this.passwordForm.value.password === '13qe!#QE') {
      if (this.isClickedOnClearTag) {
        this.isClickedOnClearTag = false;
        this.dialogRef.close(true);
      } else if (this.isClickedOnClearSingleTag) {
        this.isClickedOnClearSingleTag = false;
        this.dialogRef.close(true);
      }
    } else {
      this.snackBarService.showSnackBarError('Incorrect password!');
    }
    this.passwordForm.reset();
  }

  closeWindow() {
    this.dialogRef.close(false);
  }
}
