<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">{{ 'image-history.title' | translate }}</div>

  <button class="fs-close-btn w-auto" data-dismiss="modal" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div
    class="d-flex flex-column fs-scroll-table-body fs-header-sort-color align-items-center justify-content-center"
  >
    <!-- NOTE: usage of [hidden] because matSort is not working with *ngIf-->
    <mat-table
      matSort
      class="fs-table-container"
      matSortDirection="desc"
      matSortActive="transactionTime"
      [hidden]="dataSource.data.length === 0"
      [dataSource]="dataSource"
    >
      <!-- Action Taken Column -->
      <ng-container matColumnDef="actionTaken">
        <mat-header-cell
          class="fs-sm-txt"
          mat-sort-header
          class="pl-2"
          *matHeaderCellDef
        >
          {{ 'image-history.table.header.actionTaken' | translate }}
        </mat-header-cell>
        <mat-cell
          class="pl-2 fs-sm-txt fs-txt-white-grey-color-hover"
          *matCellDef="let element"
        >
          {{ element.actionTaken }}
        </mat-cell>
      </ng-container>

      <!-- Transaction Time Column -->
      <ng-container matColumnDef="transactionTime">
        <mat-header-cell
          class="fs-sm-txt"
          mat-sort-header
          class="pl-2"
          *matHeaderCellDef
        >
          {{ 'image-history.table.header.transactionTime' | translate }}
        </mat-header-cell>
        <mat-cell
          class="pl-2 fs-sm-txt fs-txt-white-grey-color-hover"
          *matCellDef="let element"
        >
          {{ element.transactionTime }}
        </mat-cell>
      </ng-container>

      <!-- Transaction Detail Column -->
      <ng-container matColumnDef="transactionDetail">
        <mat-header-cell
          class="fs-sm-txt"
          mat-sort-header
          class="pl-2"
          *matHeaderCellDef
        >
          {{ 'image-history.table.header.transactionDetail' | translate }}
        </mat-header-cell>
        <mat-cell
          class="pl-2 fs-sm-txt fs-txt-white-grey-color-hover"
          *matCellDef="let element"
        >
          <div [innerHTML]="element.transactionDetail"></div>
        </mat-cell>
      </ng-container>

      <!-- User Name Column --><!--todo Ask to receive userName with camelCase from back-end-->
      <ng-container matColumnDef="username">
        <mat-header-cell
          class="fs-sm-txt"
          mat-sort-header
          class="pl-2"
          *matHeaderCellDef
        >
          {{ 'image-history.table.header.username' | translate }}
        </mat-header-cell>
        <mat-cell
          class="pl-2 fs-sm-txt fs-txt-white-grey-color-hover"
          *matCellDef="let element"
        >
          {{ element.username }}
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        class="fs-cursor-pointer"
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>
    </mat-table>
  </div>

  <ng-container *ngIf="dataSource.data.length === 0">
    <div class="p-5 text-center">
      {{ 'global.no-record' | translate }}
    </div>
  </ng-container>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <div class="d-flex justify-content-end align-items-center">
    <button class="fs-dialog-btn w-auto" (click)="closeWindow()">
      {{ 'image-history.buttons.close' | translate }}
    </button>
  </div>
</div>
