import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class UrlEncryptService {
  secretKey = 'bXVzdGJlMTZieXRlc2tleQ==';
  parsedBase64Key = CryptoJS.enc.Base64.parse(this.secretKey);

  encrypt(urlString: string): string {
    // return CryptoJS.AES.encrypt(urlString, this.secretKey.trim()).toString();
    let encryptedData: string = CryptoJS.AES.encrypt(
      urlString,
      this.parsedBase64Key,
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      },
    );
    encryptedData = encryptedData.toString().split('+').join('-');
    return encryptedData.split('/').join('_');
  }
}
