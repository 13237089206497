export const DEPARTMENT = 'Department';
export const PROJECT = 'Project';
export const ONLINE = 'ONLINE';
export const OFFLINE = 'OFFLINE';
export const EXECUTIVE_PROJECT = 'Executive Project';
export const ALBUM = 'Album';

export const ConstantsGlobal = {
  MODELNAMES: {
    FILTEREDFILE: 'filteredfile',
    SELECTEDFILE: 'selectedfile',
    IMAGEEXCLUSIVES: 'imageexclusives',
    ADDEXCLUSIVE: 'addexclusive',
    TALENTMANAGEMENT: 'talentmanagement',
    ACTOREAPPROVAL: 'actoreapproval',
    APPROVALREPORT: 'approvalreport',
    IMAGENOTES: 'imagenotesreport',
    NOTESREPORT: 'notesreport',
    IMAGECAPTIONREPORT: 'imagecaptionreport',
    PROJECT_SUMMARY_REPORT: 'projectsummaryreport',
    CAPTIONREPORT: 'captionreport',
    TAGGINGVIEW1: 'taggingview-1',
    TAGGINGVIEW2: 'taggingview-2',
    BATCHMANAGEMENT: 'batchManagement',
    APPLYEXIF: 'applyexif',
    ADVANCEDIMAGETAGGING: 'advancedimagetagging',
    FOX_ARCHIVE: 'fox_archive_delivery',
    DELIVERY: 'delivery',
    FINALIZEUNFINALIZE: 'finalizeunfinalize',
    REJECTIONTOGGLE: 'rejectiontoggle',
    CONFLICTS: 'conflicts',
    NOCONFLICTS: 'no-conflicts',
    ADDALBUM: 'Add Album',
    RENAMEALBUM: 'Rename Album',
    DELETEALBUM: 'Delete Album',
    DELETEASSET: 'Delete Asset',
    EDITALBUM: 'Edit Album',
    GOTO: 'GoTo',
    VIEWSETTING: 'View Setting',
    LINK: 'link',
    SENDMAIL: 'sendmail',
    DOWNLOAD: 'download',
    PRINTTOOL: 'printtool',
    IMDONE: 'imdone',
    FINALIZATIONWINDOW: 'finalizationwindow',
    FINALIZATIONMODAL: 'finalizationModal',
    ADVANCESEARCHFILTER: 'AdvanceSearchFilter',
    ADVANCEIMAGECORRECT: 'advanceimage-correct',
    EDITPERMISSIONEXECUTIVEALBUM: 'editpermission_executive_album',
    CLEARSINGLETAG: 'clearsingletag',
    SENDEMAILNOTIFICATION: 'sendemailnotification',
    DELETEASSETS: 'deleteassets',
    SECONDLEVELCOMPARE: 'Second Level Compare',
    HELPWINDOW: 'Help Window',
    ACTORIDREPORT: 'actoridreport',
    ADVANCESEARCHFILTERSTATUS: false,
    FINALIZATIONWINDOWSTATUS: false,
  },
};

export const EmailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
