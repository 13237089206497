export class ConvertAssetModel {
  status: boolean;
  message: string;
  t: ConvertAssetInfoModel[];
}

export class ConvertAssetInfoModel {
  id: number;
  value: boolean;
}

export enum AssetTypesEnum {
  image = 1,
  video = 2,
  docx = 3,
  pdf = 4,
  zip = 5,
}
