import { Injectable } from '@angular/core';
import { AppSettings } from '../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../shared/services/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AdministrationService {
  constructor(private apiService: ApiService) {}

  putAssetsToggle(data: any): Observable<any> {
    const path = AppSettings.ASSETS_TOGGLE;
    return this.apiService.put(path, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  putMakeCover(data: any): Observable<any> {
    const path = AppSettings.MAKE_COVER;
    return this.apiService.put(path, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }
}
