<div class="error-page-container text-center">
  <div class="logo-block d-flex justify-content-center">
    <img
      class="logo"
      [src]="'/assets/images/' + domainConfigData.logo"
      [alt]="domainConfigData.alt"
    />
  </div>
  <div class="error-information">
    <H1>Oops</H1>
    <h4>404 - PAGE NOTE FOUND</h4>
    <div>
      The page you are looking for might have been removed, had its name changed
      or its temporary unavaialvle.
    </div>
  </div>
  <div class="login-btn-container">
    <input
      type="submit"
      class="login-btn"
      value="GO TO LOGIN"
      (click)="onClickBackToLoginScreen()"
    />
  </div>
  <div class="app-information py-3">
    <div class="fs-sm-txt">
      <span class="fs-txt-white-grey-color-hover"
        >{{ 'login.intro' | translate }}
        <br />
        {{ 'login.labelcontact' | translate }}:
      </span>
      <a
        class="fs-txt-white-grey-color-hover"
        href="mailto:info@filmsolutions.com?Subject=Contact%20Support"
        target="_top"
        >info&#64;filmsolutions.com</a
      >
    </div>
    <div class="fs-sm-txt">
      <a class="fs-txt-white-grey-color-hover" href="tel:+1818-588-3500"
        >+1 (818) 588 3500</a
      >
    </div>
    <div>
      <span class="fs-txt-white-grey-color-hover"
        >Version {{ environmentName }} {{ version }}</span
      >
    </div>
  </div>
</div>
