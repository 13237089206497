import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { DomainConfig } from '../shared/models/domainConfig.model';
import { DomainConfigService } from '../shared/services/domain-config.service';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../shared/utils/utils';
import { UnsubscriberComponent } from '../shared/components/unsubscriber/unsubscriber.component';

@Component({
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent
  extends UnsubscriberComponent
  implements OnInit
{
  domainConfigData = new DomainConfig();
  version = '';
  environmentName = '';
  constructor(
    private router: Router,
    private domainConfigService: DomainConfigService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.version = environment.version;
    this.environmentName = environment.environmentName;
    this.domainConfigService.domainConfigDataGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((domainData) => {
        if (!Utils.isObjectEmpty(domainData)) {
          this.domainConfigData = domainData;
        }
      });
  }

  onClickBackToLoginScreen() {
    this.router.navigateByUrl('/');
  }
}
