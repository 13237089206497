import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fs-actor-id-history-report',
  templateUrl: './actor-id-history-report.component.html',
  styleUrls: ['./actor-id-history-report.component.scss'],
})
export class ActorIdHistoryReportComponent implements OnInit {
  projectName: string;
  today: Date;
  printHistoryData;
  historyColumnsList;
  sum;
  exportReportHistory;
  displayedColumns: string[] = [];
  dataSource = [];

  constructor(
    private dialogRef: MatDialogRef<ActorIdHistoryReportComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.projectName = data.projectName;
    this.today = data.today;
    this.printHistoryData = data.printHistoryData;
    this.historyColumnsList = data.historyColumnsList;
    this.sum = data.sum;
    this.exportReportHistory = data.exportReportHistory();
  }

  ngOnInit() {
    this.initRowName();
    this.initColumnNameData();
    this.initDataSource();
  }

  initColumnNameData() {
    this.displayedColumns = this.historyColumnsList;
  }

  initDataSource() {
    this.dataSource = [...this.printHistoryData, ...[this.sum]];
  }

  initRowName() {
    this.historyColumnsList.unshift(
      this.translate.instant(
        'left-menu.tab-bar.sidemenus.administration.actorid-report.table.publish-date',
      ),
    );
    this.sum.unshift(
      this.translate.instant(
        'left-menu.tab-bar.sidemenus.administration.actorid-report.table.totals',
      ),
    );
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
