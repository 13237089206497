<div class="ml-1" *ngIf="captionAccess && !isTaggerRole">
  <div
    class="fs-cursor-pointer"
    title="{{ 'view-project.captions' | translate }}"
    (click)="captionView.emit(imgData)"
  >
    <span class="fs-char-icon">
      <img
        src="/assets/images/icon-chat_previous.png"
        [class.highlighted-icon]="imgData.caption"
      />
    </span>
  </div>
</div>
