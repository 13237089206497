<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.taggingview-1.title'
        | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="onStopCallTagging()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="d-flex flex-wrap">
    <div
      [ngClass]="{ 'd-none': i % 3 != 0 }"
      class="mx-1"
      *ngFor="let taglist of taggingList; let i = index"
    >
      <div class="d-block" *ngIf="i % 3 == 0">
        <div
          class="fs-txt-white-dark-color fs-txt-white-grey-color-hover fs-tiny-txt fs-cursor-pointer my-1 mx-1"
          *ngIf="i < taggingList.length"
          (click)="onClickTagList(taggingList[i])"
        >
          <span>{{ taggingList[i].hotkey }}</span
          >={{ taggingList[i].tagName }}({{ taggingList[i].imageCount }})
        </div>

        <div
          class="fs-txt-white-dark-color fs-txt-white-grey-color-hover fs-tiny-txt fs-cursor-pointer my-1 mx-1"
          *ngIf="i + 1 < taggingList.length"
          (click)="onClickTagList(taggingList[i + 1])"
        >
          <span>{{ taggingList[i + 1].hotkey }}</span
          >={{ taggingList[i + 1].tagName }}({{
            taggingList[i + 1].imageCount
          }})
        </div>

        <div
          class="fs-txt-white-dark-color fs-txt-white-grey-color-hover fs-tiny-txt fs-cursor-pointer my-1 mx-1"
          *ngIf="i + 2 < taggingList.length"
          (click)="onClickTagList(taggingList[i + 2])"
        >
          <span>{{ taggingList[i + 2].hotkey }}</span
          >={{ taggingList[i + 2].tagName }}({{
            taggingList[i + 2].imageCount
          }})
        </div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <button class="fs-dialog-btn w-auto ml-3" (click)="submitForReview()">
    {{
      'left-menu.tab-bar.sidemenus.administration.taggingview-1.button.submit'
        | translate
    }}
  </button>

  <button class="fs-dialog-btn w-auto ml-3" (click)="onStopCallTagging()">
    {{
      'left-menu.tab-bar.sidemenus.administration.taggingview-1.button.release'
        | translate
    }}
    &amp;
    {{
      'left-menu.tab-bar.sidemenus.administration.taggingview-1.button.close'
        | translate
    }}
  </button>

  <div class="fs-text-color-yellow font-weight-bold ml-3 w-auto">
    {{
      'left-menu.tab-bar.sidemenus.administration.taggingview-1.labels.label1'
        | translate
    }}
  </div>
</div>
