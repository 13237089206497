<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{ 'left-menu.tab-bar.sidemenus.audit.convertAssetType.title' | translate }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content [formGroup]="assetsTypeForm">
  <mat-select
    class="form-control fs-selectbox-dark"
    [placeholder]="
      'left-menu.tab-bar.sidemenus.audit.convertAssetType.placeholder'
        | translate
    "
    panelClass="fs-convert-option-position-panel fs-option-position-panel"
    formControlName="assetType"
  >
    <mat-option
      class="fs-sm-txt fs-selectbox-options"
      *ngFor="let item of assetTypes"
      [value]="item.value"
    >
      {{ item.label }}
    </mat-option>
  </mat-select>
</div>

<div mat-dialog-actions align="end">
  <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
    {{
      'left-menu.tab-bar.sidemenus.audit.convertAssetType.buttons.cancel'
        | translate
    }}
  </button>
  <button
    class="fs-dialog-btn w-auto ml-3"
    [disabled]="!assetsTypeForm.valid"
    (click)="onSubmitTypeChange()"
  >
    {{
      'left-menu.tab-bar.sidemenus.audit.convertAssetType.buttons.submit'
        | translate
    }}
  </button>
</div>
