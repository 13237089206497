import { Modalstatusservice } from './../../services/modalstatus.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DownloadManagerService } from './download-manager.service';
import { SlideInOutAnimation } from './download-manager.animation';
import { DialogService } from '../../services/dialog/dialog.service';
import { ConstantsGlobal } from '../../constants/constants';
import { takeUntil } from 'rxjs/operators';
import { IFrameComponent } from '../../../dashboard/project/dashboard-shared/top-menu/iframe/iframe.component';
import { UnsubscriberComponent } from '../../components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'fs-download-manager',
  templateUrl: './download-manager.component.html',
  styleUrls: ['./download-manager.component.scss'],
  animations: [SlideInOutAnimation],
})
export class DownloadManagerComponent
  extends UnsubscriberComponent
  implements OnInit, OnDestroy
{
  public isDownloadEnabled = false;
  public isCollapse = false;
  public isSingleCallEnable = false;
  public downloadItems: any;
  public icon = 'arrow_down.png';
  public animationState = 'in';
  constantsGlobalData: any;

  constructor(
    private downloadManagerService: DownloadManagerService,
    private modalService: Modalstatusservice,
    private dialogService: DialogService,
  ) {
    super();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.downloadManagerService.isDownloadEnabledGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isDownloadEnabled = status;
      });

    this.downloadManagerService.downloadItemsGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.downloadItems = res.map((item) => {
          item.filename1 = item.filename.replace(/\.[^/.]+$/, '');
          return item;
        });
        if (this.downloadItems.length && !this.isSingleCallEnable) {
          this.isDownloadEnabled = true;
          this.isCollapse = true;
          this.isSingleCallEnable = true;
        }
      });
  }

  hideAndShow() {
    this.isCollapse = this.isCollapse ? false : true;
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
    this.icon = this.isCollapse ? 'arrow_down.png' : 'arrow_up.png';
  }

  abortDownload(item, i) {
    if (item.downloadedPercent === 100) {
      if (this.downloadItems.length === 1) {
        this.isCollapse = false;
        this.isDownloadEnabled = false;
        this.isSingleCallEnable = false;
      }
      this.downloadManagerService.cancelDownload(i);
    } else {
      this.dialogService
        .openConfirmationDialog({
          title: this.constantsGlobalData.MODELNAMES.DOWNLOAD,
          message: 'Do you want to Abort download?',
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((state: boolean) => {
          if (state) {
            if (this.downloadItems.length === 1) {
              this.isCollapse = false;
              this.isDownloadEnabled = false;
              this.isSingleCallEnable = false;
            }
            this.downloadManagerService.cancelDownload(i);
          }
        });
    }
  }

  clearAll() {
    this.downloadManagerService.setIsDownloadEnabled(false);
    this.downloadManagerService.setDownloadItems([]);
  }

  onClickExpressDownloadFile(item) {
    this.dialogService.open(IFrameComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
      ],
      data: item,
    });
  }

  ngOnDestroy() {
    this.downloadManagerService.setDownloadItems([]);
    super.ngOnDestroy();
  }
}
