<div
  class="container-fluid dep-exe-container launcher-container {{
    favProjectclass
  }}"
>
  <div #favDiv class="listcont">
    <div *ngIf="this.favProjects.length > 0">
      <fs-favourite-project
        [groupedProject]="groupedProject"
        (updatedFavStaus)="updatedFavStaus($event)"
      >
      </fs-favourite-project>
    </div>
  </div>

  <!-- project and department container -->
  <div class="project-dept-block">
    <div class="block-header">
      <span>{{ 'dashboard.department' | translate }}</span>
      <div class="search-project-comp">
        <fs-search-project
          [searchList]="searchList"
          [fromDepartment]="false"
        ></fs-search-project>
      </div>
    </div>

    <ng-scrollbar
      autoHeightDisabled="false"
      class="dashboard-projectload-block"
      #scrollDiv
      track="all"
      appearance="compact"
    >
      <div
        class="scrollable-content"
        infiniteScroll
        scrollViewport
        [infiniteScrollDistance]="scrollDistance"
        [infiniteScrollUpDistance]="scrollUpDistance"
        [infiniteScrollThrottle]="throttle"
        [scrollWindow]="false"
        (scrolled)="onScrollDown()"
      >
        <div class="flex-container">
          <div class="row search-results">
            <ng-container *ngFor="let group of leftSideListClone">
              <div class="col-md-6 col-sm-6 fs-project-item-container">
                <div
                  class="flex-item"
                  [ngClass]="{
                    'project-block': group.groupType == 'Project',
                    'department-block': group.groupType == 'Department'
                  }"
                >
                  <div class="header-block">
                    <div
                      *ngIf="group.groupType == 'Department'"
                      class="img-block"
                    >
                      <img
                        *ngIf="group.groupLogo !== 'logo'"
                        [src]="'assets/images/branding/' + group.groupLogo | projectAssetsBaseUrl"
                        onerror="this.style.display='none'"
                      />
                    </div>
                    <div
                      class="header-name deptBlock"
                      *ngIf="group.groupType == 'Department'"
                    >
                      <a
                        href="javascript:void(0)"
                        class="fs-txt-white-grey-color-hover"
                        (click)="navigateToProject(group.groupType, group)"
                      >
                        {{ group.groupName }}
                      </a>
                    </div>
                    <div
                      class="department"
                      *ngIf="group.groupType == 'Department'"
                    >
                      <a
                        href="javascript:void(0)"
                        class="fs-txt-white-grey-color-hover"
                        (click)="navigateToProject(group.groupType, group)"
                      >
                        {{ 'dashboard.depart' | translate }}
                      </a>
                    </div>
                    <div
                      class="header-name"
                      *ngIf="group.groupType == 'Project'"
                    >
                      <div *ngIf="group.items.length > 1">
                        {{ group.groupName }}
                      </div>
                      <div *ngIf="group.items.length === 1">
                        <a
                          href="javascript:void(0)"
                          class="project-title fs-txt-white-grey-color-hover"
                          (click)="
                            navigateToProject(group.groupType, group.items[0])
                          "
                        >
                          {{ group.items[0].label }}
                        </a>
                      </div>
                    </div>
                    <div
                      *ngIf="
                        group.groupType == 'Project' && group.items.length === 1
                      "
                    >
                      <a
                        href="javascript:void(0)"
                        title="{{ 'dashboard.addToShortcuts' | translate }}"
                        class="project-title-favorite fs-txt-white-grey-color-hover"
                        *ngIf="!group.items[0].fav"
                        (click)="addToFav(group.items[0])"
                      >
                        <i class="fa fa-plus"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    class="body-content"
                    *ngIf="
                      group.items.length > 1 && group.groupType == 'Project'
                    "
                  >
                    <div>
                      <div *ngFor="let item of group.items">
                        <a
                          href="javascript:void(0)"
                          class="fs-txt-white-grey-color-hover"
                          (click)="navigateToProject(group.groupType, item)"
                        >
                          {{ item.label }}
                        </a>
                        <a
                          href="javascript:void(0)"
                          title="{{ 'dashboard.addToShortcuts' | translate }}"
                          class="add-favourite"
                          *ngIf="!item.fav"
                          (click)="addToFav(item)"
                        >
                          <i class="fa fa-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</div>
