<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.batch-management.title'
        | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <form class="container form-horizontal" #batchForm="ngForm">
    <div class="form-group row">
      <div class="control-label col-4">
        {{
          'left-menu.tab-bar.sidemenus.administration.batch-management.labels.selectLabel'
            | translate
        }}
      </div>

      <div class="col-8">
        <mat-select
          class="fs-selectbox-dark form-control"
          required
          [placeholder]="
            'left-menu.tab-bar.sidemenus.administration.batch-management.labels.defaultSelectLabel'
              | translate
          "
          [value]="batchModelData"
          (valueChange)="onChangeBatchModelData($event)"
        >
          <mat-option
            class="fs-sm-txt fs-selectbox-options"
            *ngFor="let batch of batchListData"
            [value]="batch"
          >
            {{ batch.label }}
          </mat-option>
        </mat-select>
      </div>
    </div>

    <div class="form-group row">
      <div class="control-label col-4">
        {{
          'left-menu.tab-bar.sidemenus.administration.batch-management.labels.optionsLabel'
            | translate
        }}
      </div>
      <div class="col-8">
        <mat-radio-group
          [value]="batchModelData.operationType"
          (change)="batchModelData.operationType = $event.value"
        >
          <mat-radio-button class="mr-3 fs-radio-button" value="UPDATE">
            <span class="cb-title fs-sm-txt">
              {{
                'left-menu.tab-bar.sidemenus.administration.batch-management.labels.update'
                  | translate
              }}
            </span>
          </mat-radio-button>
          <mat-radio-button class="fs-radio-button" value="DELETE">
            <span class="cb-title fs-sm-txt">
              {{
                'left-menu.tab-bar.sidemenus.administration.batch-management.labels.delete'
                  | translate
              }}
            </span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div
      class="form-group row"
      *ngIf="batchModelData.operationType === radioValue[0]"
    >
      <div class="control-label col-4">
        {{
          'left-menu.tab-bar.sidemenus.administration.batch-management.labels.enterNameLabel'
            | translate
        }}
      </div>
      <div class="col-8">
        <input
          type="text"
          [(ngModel)]="batchModelData.updatedLabel"
          class="form-control"
          name="updatedLabel"
          required
        />
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
    {{
      'left-menu.tab-bar.sidemenus.administration.batch-management.button.cancel'
        | translate
    }}
  </button>

  <button
    class="fs-dialog-btn w-auto ml-3"
    (click)="confirmAction()"
    [disabled]="!batchForm.form.valid"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.batch-management.button.confirm'
        | translate
    }}
  </button>
</div>
