import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImagenotesService } from './imagenotes-report.service';
import { Subject, Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { NotesReportComponent } from './notes-report/notes-report.component';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';

@Component({
  selector: 'fs-imagenotes-report',
  templateUrl: './imagenotes-report.component.html',
  styleUrls: ['./imagenotes-report.component.scss'],
})
export class ImagenotesReportComponent implements OnInit {
  repotyType = '1';
  filterName = '';
  constantsGlobalData: any;

  constructor(
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private imagenotesService: ImagenotesService,
    private viewProjectService: ViewProjectService,
    private dialogRef: MatDialogRef<ImagenotesReportComponent>,
    private dialogService: DialogService,
  ) {}

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.filterName = this.viewProjectService.getCurrentFilter();
    this.repotyType = '1';
    this.imagenotesService.setNoteType(this.repotyType);
    this.imagenotesService.setNoteType(this.repotyType);
  }

  onNotesChange(e) {
    this.imagenotesService.setNoteType(e.value);
  }

  closeWindow() {
    this.dialogRef.close();
  }

  notesReport() {
    this.dialogService.open(NotesReportComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-ls-panel',
        'fs-dialog-link-panel',
      ],
      data: this.imagenotesService.getNoteType(),
    });
    this.dialogRef.close();
  }
}
