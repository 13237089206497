import { NgStyleModel } from '../../models/ng-style.model';
import { TooltipPosition } from '@angular/material/tooltip';

export interface PopupConfigModel<T = any> {
  data?: T;
  closeOnBackdropClick?: boolean;
  hasBackdrop?: boolean;
  showDelay?: number;
  hideDelay?: number;
  panelClass?: NgStyleModel;
  backDropClass?: string;
  id?: string;
  cdkOverlayContainerClass?: string;
  position?: TooltipPosition;
  withArrow?: boolean;
  closeOnNavigate?: boolean;
}

export class PopupConfig<T> implements Required<PopupConfigModel<T>> {
  readonly data: T = null;
  readonly closeOnBackdropClick = false;
  readonly hasBackdrop = false;
  readonly withArrow = true;
  readonly backDropClass = 'cdk-overlay-transparent-backdrop';
  readonly closeOnNavigate = true;
  readonly id = '';
  readonly showDelay = 100;
  readonly cdkOverlayContainerClass = '';
  readonly hideDelay = 100;
  readonly panelClass: NgStyleModel = '';
  readonly position: TooltipPosition = 'below';

  constructor(config: PopupConfigModel<T>) {
    Object.entries(config)
      .filter(([key, _]) => Object.prototype.hasOwnProperty.call(this, key))
      .forEach(([key, value]) => (this[key] = value));
  }
}
