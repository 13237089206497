<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">{{ 'shortcuts.title' | translate }}</div>
  <button class="fs-close-btn w-auto" mat-dialog-close>
    <i (click)="closeWindow()" class="fa fa-times-circle"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="fx-dialog-icon-container">
    <span class="mr-2">
      <div class="fs-scroll-table-body fs-header-sort-color">
        <mat-table
          matSort
          [hidden]="!dataSource.data.length"
          [dataSource]="dataSource"
        >
          <ng-container matColumnDef="key">
            <mat-header-cell
              disableClear
              mat-sort-header="name"
              class="pl-2 px-2"
              *matHeaderCellDef
            >
              {{ 'shortcuts.keyCodeTitle' | translate }}
            </mat-header-cell>
            <mat-cell
              class="pl-2 fs-cursor-pointer pr-2 pt-1 pb-1 fs-sm-txt fs-txt-white-grey-color-hover"
              *matCellDef="let element"
            >
              <span>{{ element.key }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell
              disableClear
              mat-sort-header="name"
              class="pl-2 px-2"
              *matHeaderCellDef
            >
              {{ 'shortcuts.keyActionTitle' | translate }}
            </mat-header-cell>
            <mat-cell
              class="pl-2 fs-cursor-pointer pr-2 pt-1 pb-1 fs-sm-txt fs-txt-white-grey-color-hover"
              *matCellDef="let element"
            >
              <span>{{ element.action | translate }}</span>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <div>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </div>
        </mat-table>
      </div>
      <div *ngIf="!dataSource.data.length">
        <div class="p-4 text-center">
          {{ 'global.no-record' | translate }}
        </div>
      </div>
    </span>
  </div>
</div>
