<mat-accordion [displayMode]="'flat'" class="fs-accordion-dark-theme">
  <mat-expansion-panel [disabled]="true" [expanded]="true" [hideToggle]="true">
    <mat-expansion-panel-header
      [collapsedHeight]="'30px'"
      [expandedHeight]="'30px'"
    >
      <mat-panel-title>
        <span class="fs-sm-txt">
          {{ 'left-menu.tab-bar.title2' | translate }}
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="pl-4 pt-2 pb-2 fs-txt-white-dark-color fs-sm-txt">
      <div *ngIf="groupLogo != ''">
        <div>
          {{ 'left-menu.tab-bar.excutive-project-info.photo' | translate }} :
          {{ photoCredit }}
        </div>
        <div>
          {{ 'left-menu.tab-bar.excutive-project-info.shoot' | translate }} :
          {{ shootDate }}
        </div>
        <div class="imageLi">
          <img
            class="executiveimg"
            [src]="'assets/images/project/' + logoName + '.jpg' | projectAssetsBaseUrl"
            width="100"
            height="50"
            [alternativeImage]="alternativeLogo$ | async"
          />
        </div>
      </div>

      <div [ngClass]="{ active: selectAllImagesCount > 0 }">
        {{ 'left-menu.project-info.selectedImages' | translate }} :
        {{ selectAllImagesCount }}
      </div>

      <div>
        {{ 'left-menu.project-info.filteredImages' | translate }} :
        {{ totalFilterCount }}
      </div>

      <div *ngIf="projectAuthority !== projectRole.ROLE_ALBUMVIEWER">
        {{ 'left-menu.project-info.totalImages' | translate }} :
        {{ totalImageCount }}
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
