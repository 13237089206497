import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { ApiImageService } from './api-image.service';
import { AuthModule } from './auth/auth.module';
import { ErrorPageModule } from './errorPage/error-page.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar } from '@angular/material/snack-bar';

// Routing
import { AppRouting } from './app.routing';

// ngx-Translate
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// HotKeys
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { interceptorProviders } from './shared/http-interceptors/interceptors';
import { AppSettings } from './appSettings/appSettings';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// infinite scroll
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// Logger
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { SharedModule } from './shared/shared.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DashboardManagerModule } from './dashboard/dashboard-manager/dashboard-manager.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    AuthModule,
    ErrorPageModule,
    DashboardModule,
    DashboardManagerModule,
    HttpClientModule,
    AppRouting,
    ScrollingModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgIdleKeepaliveModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    LoggerModule.forRoot({
      level: !environment.production ? NgxLoggerLevel.LOG : NgxLoggerLevel.OFF,
      // serverLogLevel
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
  ],
  providers: [
    CookieService,
    AppSettings,
    interceptorProviders,
    MatSnackBar,
    ApiImageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
