import { Component, OnInit } from '@angular/core';
import { ContributorsService } from './contributors.service';
import { DrawImageService } from '../../../../project-detail/draw-image/draw-image.service';
import { UserDataService } from '../../../../../../shared/services/userdata.service';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../../../../../../shared/utils/utils';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import {ApiErrorHandlerService} from '../../../../../../shared/services/api-error-handler.service';
import {LoaderService} from '../../../../../../shared/services/loader.service';

@Component({
  selector: 'fs-contributors',
  templateUrl: './contributors.component.html',
  styleUrls: ['./contributors.component.scss'],
})
export class ContributorsComponent
  extends UnsubscriberComponent
  implements OnInit
{
  contributors: any = [];
  checked = true;

  constructor(
    private contributorsService: ContributorsService,
    private drawImageService: DrawImageService,
    private loaderService: LoaderService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private userDataService: UserDataService,
    private viewProjectService: ViewProjectService,
  ) {
    super();
  }

  ngOnInit() {
    this.drawImageService.retouchDrawsGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          const userIds = res.map((userData) => {
            return userData.userId;
          });

          if (userIds.length) {
            this.userDataService
              .getUserData(this.viewProjectService.getProjectId(), [
                ...new Set(userIds),
              ])
              .pipe(takeUntil(this.destroy$))
              .subscribe((value) => {
                this.contributors = value;
              },(error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              });
          }
        } else {
          this.contributors = [];
        }
      });

    this.drawImageService.retouchDrawsContributorListGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        setTimeout(() => {
          this.userDataService
            .getUserData(this.viewProjectService.getProjectId(), res)
            .pipe(takeUntil(this.destroy$))
            .subscribe((value) => {
              this.contributors = value;
            },(error) => {
              this.apiErrorHandlerService.getHandler().handle(error);
              this.loaderService.displayLoader(false);
            });
        }, 1500);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  onFilterChange(e) {
    this.drawImageService.setRemoveUsers({
      userId: e.source.value,
      checked: e.checked,
    });
  }
}
