<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{ 'left-menu.finalization.title' | translate }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    aria-hidden="true"
    (click)="closeWindow()"
  >
    <i class="fa fa-times-circle"></i>
  </button>
</div>

<div mat-dialog-content [formGroup]="logOutForm">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div>
          <mat-radio-group formControlName="approvalstatus">
            <mat-radio-button
              class="fs-radio-button"
              name="approvalstatus"
              value="exit"
              checked
            >
              <span class="cb-title fs-sm-txt" style="font-size: 14px">
                {{ 'left-menu.finalization.opetion1' | translate }}
              </span>
            </mat-radio-button>
            <p class="logout-ptxt">
              {{ 'left-menu.finalization.opetion1subtext' | translate }}
            </p>

            <mat-radio-button
              class="fs-radio-button"
              name="approvalstatus"
              value="full-finalize"
            >
              <span class="cb-title fs-sm-txt" style="font-size: 14px">
                {{ 'left-menu.finalization.opetion2' | translate }}
              </span>
            </mat-radio-button>
            <p class="logout-ptxt">
              {{ 'left-menu.finalization.opetion2subtext' | translate }}
            </p>

            <mat-radio-button
              class="fs-radio-button"
              name="approvalstatus"
              value="partial-finalize"
            >
              <span class="cb-title fs-sm-txt" style="font-size: 14px">
                {{ 'left-menu.finalization.opetion3' | translate }}
              </span>
            </mat-radio-button>
            <p class="logout-ptxt">
              {{ 'left-menu.finalization.opetion3subtext' | translate }}
            </p>
          </mat-radio-group>
        </div>
        <div>
          <label> {{ 'left-menu.finalization.FromPages' | translate }} </label
          ><br />
          <input
            class="form-control image-text"
            formControlName="from"
            name="from"
            type="text"
            value="1"
            (focus)="onFocustextInput()"
            (keypress)="onlyNumberKey($event)"
          />
          <br />

          <label>{{ 'left-menu.finalization.ThruPages' | translate }}</label
          ><br />
          <input
            class="form-control image-text"
            formControlName="to"
            name="to"
            type="text"
            value="1"
            (focus)="onFocustextInput()"
            (keypress)="onlyNumberKey($event)"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button class="fs-dialog-btn w-auto ml-3" (click)="resetForm()">
    {{ 'left-menu.finalization.reset' | translate }}
  </button>
  <button class="fs-dialog-btn w-auto ml-3" (click)="submitForm()">
    {{ 'left-menu.finalization.submit' | translate }}
  </button>
</div>
