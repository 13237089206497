import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  matTooltipAnimations,
  TooltipPosition,
  TooltipVisibility,
} from '@angular/material/tooltip';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import { Observable, Subject } from 'rxjs';
import { AnimationEvent } from '@angular/animations';
import Timeout = NodeJS.Timeout;
import { NgStyleModel } from '../../models/ng-style.model';
import { PopupConfig } from '../../services/popup/popup-config';
import {
  ConnectedOverlayPositionChange,
  FlexibleConnectedPositionStrategy,
  OverlayRef,
} from '@angular/cdk/overlay';

/**
 * Internal component that wraps popup content.
 */
@Component({
  selector: 'fs-popup-container',
  templateUrl: './popup-container.component.html',
  animations: [matTooltipAnimations.tooltipState],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupContainerComponent implements OnInit {
  @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet;

  @ViewChild('popupContainer') popupContainer: ElementRef;

  panelClass: NgStyleModel;

  target: HTMLElement;

  overlayContainerRef: HTMLElement;

  cdkOverlayContainerClass: string;

  withArrow: boolean;

  position: TooltipPosition;

  positionChange: Observable<ConnectedOverlayPositionChange>;

  visibility: TooltipVisibility = 'initial';

  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset,
  );

  private readonly onHide: Subject<void> = new Subject();

  private showTimeoutId: Timeout | null;

  private hideTimeoutId: Timeout | null;

  constructor(
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    public popupConfig: PopupConfig<unknown>,
    public element: ElementRef,
    public overlayRef: OverlayRef,
  ) {
    this.panelClass = this.popupConfig.panelClass;
    this.withArrow = this.popupConfig.withArrow;
    this.position = this.popupConfig.position;
    this.cdkOverlayContainerClass = this.popupConfig.cdkOverlayContainerClass;
    this.overlayContainerRef = this.overlayRef.overlayElement;
    const position = this.overlayRef.getConfig()
      .positionStrategy as FlexibleConnectedPositionStrategy;
    this.positionChange = position.positionChanges;
  }

  ngOnInit(): void {
    this.show(this.popupConfig.showDelay);
  }

  attachContentPortal<C>(componentPortal: ComponentPortal<C>) {
    return this.portalOutlet.attachComponentPortal(componentPortal);
  }

  show(delay: number): void {
    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId);
      this.hideTimeoutId = null;
    }

    this.showTimeoutId = setTimeout(() => {
      this.visibility = 'visible';
      this.showTimeoutId = null;
      this.cdr.markForCheck();
    }, delay);
  }

  hide(delay: number): void {
    if (this.showTimeoutId) {
      clearTimeout(this.showTimeoutId);
      this.showTimeoutId = null;
    }

    this.hideTimeoutId = setTimeout(() => {
      this.visibility = 'hidden';
      this.hideTimeoutId = null;
      this.cdr.markForCheck();
    }, delay);
  }

  afterHidden(): Observable<void> {
    return this.onHide.asObservable();
  }

  isVisible(): boolean {
    return this.visibility === 'visible';
  }

  animationDone(event: AnimationEvent): void {
    const toState = event.toState as TooltipVisibility;

    if (toState === 'hidden' && !this.isVisible()) {
      this.onHide.next();
      this.onHide.complete();
    }
  }
}
