<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title" id="fsadreport">
    {{
      'left-menu.tab-bar.sidemenus.administration.actorid-report.title2'
        | translate
    }}
  </div>
  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    (click)="closeWindow()"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle"></i>
  </button>
</div>
<div mat-dialog-content>
  <div class="header_fixed_wraper">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="db_com adreport-p">
          <div class="d-flex fs-md-txt justify-content-between mt-2 mb-2">
            <h6>
              {{
                'left-menu.tab-bar.sidemenus.administration.actorid-report.labels.project'
                  | translate
              }}
              : {{ projectName }}
            </h6>
            <h6>{{ today | date : 'medium' }}</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-auto">
      <table class="actor-report-table" mat-table [dataSource]="dataSource">
        <ng-container
          *ngFor="let column of displayedColumns; let i = index"
          [matColumnDef]="column"
        >
          <th class="table-column p-1 fs-sm-txt" *matHeaderCellDef>
            {{ column }}
          </th>
          <td class="table-row p-1 fs-sm-txt" *matCellDef="let row">
            {{ row[i] }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button class="fs-dialog-btn w-auto ml-3">
    {{
      'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.export'
        | translate
    }}
    <i
      (click)="exportReportHistory('excel')"
      class="fa fa-file-excel-o"
      aria-hidden="true"
    ></i>
    <i
      (click)="exportReportHistory('pdf')"
      class="fa fa-file-pdf-o"
      aria-hidden="true"
    ></i>
  </button>
  <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
    {{
      'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.close'
        | translate
    }}
  </button>
</div>
