import { Router } from '@angular/router';
import { LauncherService } from '../../../../launcher/launcher.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { SearchProjectModel } from '../interfaces/dashboard-search-project-rule.model';
import { DepartmentService } from '../../../../department/department.service';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { DomainConfigService } from '../../../../../shared/services/domain-config.service';
import { AppSource } from '../../../../../shared/models/enum/AppSource';
import { ProjectRoleId } from '../../../../../shared/enum/project-role.enum';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardSearchDepartmentExecTalentProjectRule
  implements DashboardRuleModel
{
  private router = GetStaticInjector().get(Router);
  private launcherService = GetStaticInjector().get(LauncherService);
  private viewProjectService = GetStaticInjector().get(ViewProjectService);
  private departmentService = GetStaticInjector().get(DepartmentService);
  private domainConfigService = GetStaticInjector().get(DomainConfigService);

  constructor(
    private fromDepartment: boolean,
    private selectedProjectId: number,
    private selectedProjectData: SearchProjectModel,
  ) {}

  matches(): boolean {
    return (
      this.fromDepartment &&
      !(
        this.selectedProjectData.role === Number(ProjectRoleId.ROLE_TALENT) &&
        this.domainConfigService.getDomainConfigData().abbr === AppSource.ALL
      ) &&
      !(
        this.selectedProjectData.role === Number(ProjectRoleId.ROLE_ALBUMVIEWER)
      ) &&
      !(
        this.selectedProjectData.role === Number(ProjectRoleId.ROLE_TALENT) &&
        this.domainConfigService.getDomainConfigData().abbr === AppSource.ALL
      ) &&
      !(
        this.selectedProjectData.role === Number(ProjectRoleId.ROLE_ALBUMVIEWER)
      ) &&
      !(this.selectedProjectId === 3 || this.selectedProjectId === 4)
    );
  }

  apply(): void {
    this.viewProjectService.setProjectID(this.selectedProjectData.value);
    this.departmentService.setSetUpNameGlobal(this.selectedProjectData.label);
    this.departmentService.setSetUpNameGroupGlobal(
      this.selectedProjectData.groupName,
    );
    this.departmentService.setSetUpIDGlobal(this.selectedProjectData.value);
    this.launcherService.setProjectRole('1');
    this.router.navigate(
      ['/dashboard/viewgallery/' + this.selectedProjectData.value],
      { state: { selectedProjectRole: this.selectedProjectData.role } },
    );
  }
}
