<div
  class="fs-popup-title d-flex align-content-center justify-content-end"
  id="user-info"
>
  <button class="fs-close-btn w-auto" type="button" (click)="closeWindow()">
    <i class="fa fa-times-circle"></i>
  </button>
</div>
<div class="fs-popup-content">
  <form [formGroup]="noteForm" #formName="ngForm">
    <textarea
      maxlength="500"
      fsAutofocus
      rows="2"
      [preventSpecialChars]="['|']"
      formControlName="imageNoteText"
      [(ngModel)]="submitNoteData.imageNoteText"
    >
    </textarea>

    <div
      class="nmt-btm d-flex flex-row align-content-center justify-content-between mt-2"
    >
      <div
        class="mf-sngle"
        *ngIf="projectRole.ROLE_TALENT !== projectAuthority"
      >
        <mat-radio-group
          formControlName="noteType"
          class="d-flex flex-row"
          [(ngModel)]="submitNoteData.noteType"
        >
          <mat-radio-button
            class="mr-2 fs-radio-button"
            *ngFor="let ntType of noteData.note; index as ind"
            [value]="ntType.id"
          >
            <span class="fs-sm-txt cb-title">
              {{
                'left-menu.tab-bar.sidemenus.imageInfo.tab.note.radioBtn' +
                  '.' +
                  ntType.key | translate
              }}
            </span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </form>
</div>

<div class="fs-popup-actions d-flex justify-content-end">
  <button
    class="fs-dialog-btn w-auto"
    [disabled]="submitNoteData.imageNoteText == ''"
    (click)="saveImageNote()"
  >
    {{ 'left-menu.tab-bar.sidemenus.imageInfo.tab.note.saveNote' | translate }}
  </button>
</div>
