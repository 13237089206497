import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { AdvanceSearchFilter } from './fsdelivery.modal';
import { FsDeliveryService } from './fsdelivery.service';
import { SnackBarService } from '../../../../../../../shared/services/snack-bar.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ProjectDetailPermission } from '../../../../../../../shared/models/projectpermission.model';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { UserService } from '../../../../../../../shared/services/user.service';
import { SidebarService } from '../../../../../sidebar-service/sidebar-service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { Utils } from '../../../../../../../shared/utils/utils';
import { UserRole } from '../../../../../../../shared/enum/user-role.enum';
import { AppStateService } from '../../../../../../../shared/services/app-state.service';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { Taggingview1Component } from '../taggingview-1/taggingview-1.component';
import { Taggingview2Component } from '../taggingview-2/taggingview-2.component';
import { ConflictsComponent } from '../../audit/conflicts/conflicts.component';
import { map, takeUntil, tap } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../../shared/store/selected-images-store';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FilterEnum } from '../../../../../../../shared/enum/filter.enum';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-fsdelivery',
  templateUrl: './fsdelivery.component.html',
  styleUrls: ['./fsdelivery.component.scss'],
})
export class FsdeliveryComponent
  extends UnsubscriberComponent
  implements OnInit
{
  selected: boolean;
  adFilterOption: any;
  advanceSearchObj = JSON.parse(JSON.stringify(AdvanceSearchFilter));
  customAlbumList: any;
  projectAlbumList = [];
  tagList: any;
  batchModelData: any;
  batchList: any;
  exclusiveList: any;
  projectAuthority: string;
  userAuthority: string;
  projectDetailPermission: ProjectDetailPermission =
    new ProjectDetailPermission();
  viewFilter = false;
  selectedBatch: any;
  selectedExclusive: any;
  // Back route
  currentPageNumber = 1;
  noOfImages: any;
  assetsIds: any;
  getFilterDetails: any;
  restartDataAdvanceSearchObser: any;
  isTaggingWithkillRight: boolean;
  restartDataDTO: any = {};
  constantsGlobalData: any;
  isdeliveryShown = false;
  configurationGroup: UntypedFormGroup;
  selectedDeliveryServer: any = 1;
  selectedRemoteServer = '';
  totalSelectedFileSize: any = [];
  deliveryOption: any = {
    imageSize: [
      {
        id: 1,
        lable: 'Image Files',
        isChecked: true,
      },
    ],
    fileSize: [
      {
        id: 1,
        lable: 'Super Zoomed',
        isChecked: false,
      },
      {
        id: 2,
        lable: 'Master',
        isChecked: true,
      },
      {
        id: 3,
        lable: 'Original',
        isChecked: false,
      },
    ],
    delieryServer: [
      {
        id: 1,
        lable: 'Default Server',
        isChecked: false,
      },
      {
        id: 2,
        lable: 'AMZN Server',
        isChecked: false,
      },
      {
        id: 3,
        lable: 'Remote Server',
        isChecked: false,
      },
    ],
    otherOptions: [
      {
        id: 1,
        lable: 'Semicolon Delimiter',
        isChecked: false,
      },
      {
        id: 2,
        lable: 'CSV Files',
        isChecked: false,
      },
    ],
    remoteServers: [
      {
        id: 1,
        lable: 'Test 1',
      },
      {
        id: 2,
        lable: 'Test 2',
      },
      {
        id: 3,
        lable: 'Test 3',
      },
    ],
  };

  constructor(
    private router: Router,
    private viewProjectService: ViewProjectService,
    private advanceSearchService: FsDeliveryService,
    private loaderService: LoaderService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private modalStatusService: Modalstatusservice,
    private userService: UserService,
    private sidebarService: SidebarService,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<FsdeliveryComponent>,
    private appStateService: AppStateService,
    private dialogService: DialogService,
    private selectedImagesStore: SelectedImagesStore,
    private fb: UntypedFormBuilder,
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.initFormGroup();

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority) => {
        this.userAuthority = userAuthority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.viewProjectService.restartDataDTO
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.restartDataDTO = data;
      });

    this.batchModelData = {};
    this.adFilterOption = {
      statusAssets: [
        { id: 'All', name: 'All', key: 'All' },
        { id: 'Pending', name: 'Pending', key: 'Pending' },
        { id: 'Locked', name: 'Locked', key: 'Locked' },
      ],
      excludedAssets: [
        { id: 'All', name: 'All', key: 'All' },
        { id: 'Excluded', name: 'Excluded', key: 'Excluded' },
        { id: 'Released', name: 'Released', key: 'Released' },
      ],
      exclusives: [
        { id: 1, name: 'All Exclusives' },
        { id: 2, name: 'Select Exclusive', value: '' },
      ],
    };

    this.sidebarService.albumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.customAlbumList = Utils.getValuesByKey(albumList.albums, 'id');
          if (albumList.projectAlbum) {
            this.projectAlbumList = albumList.projectAlbum.children;
          } else {
            this.projectAlbumList = [];
          }
        }
      });

    this.sidebarService.updatedAlbumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.customAlbumList = Utils.getValuesByKey(albumList, 'id');
        }
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.noOfImages = res.viewSettingDTO.imagePerPage;
          this.batchList = res.batchListDTO;
          if (this.batchList[0]) {
            this.configurationGroup
              .get('batch')
              .setValue(this.batchList[0].value);
            this.advanceSearchObj.imageBatch[1].value = this.batchList[0].value;
          }
          this.exclusiveList = res.exclusiveListDTO;
          if (res.projectTagListDTO.length > 0) {
            // Sensitive
            if (res.permissionDTO.viewSensitiveAccess) {
              this.tagList = res.projectTagListDTO;
            } else {
              this.tagList = res.projectTagListDTO.filter(
                (item) => item.tagName !== 'Sensitive',
              );
            }
          } else {
            this.tagList = [];
          }
        }
      });

    this.handleModalStatus();
    this.isdeliveryShown = true;
    this.viewProjectService.isTaggingWithkillRightGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.isTaggingWithkillRight = response;
      });

    this.getFilterDetails = this.viewProjectService.getCurrentFilter();
    this.checkedFilterOption();
  }

  initFormGroup() {
    this.configurationGroup = this.fb.group({
      batch: [''],
      exclusive: [''],
    });
  }

  checkedFilterOption() {
    const filterType = this.getFilterDetails.split(':');
    if (filterType[0] !== FilterEnum.Advancesearch) {
      this.onClickedReset();
    }

    if (filterType[0] === FilterEnum.Album) {
      this.onChangedAlbum({ id: Number(filterType[1]) });
    } else if (filterType[0].toLowerCase() === FilterEnum.General) {
      this.advanceSearchObj.approvalStatus.forEach((loopData) => {
        if (loopData.name.toLowerCase() === filterType[1].toLowerCase()) {
          loopData.isChecked = true;
        }
      });

      this.advanceSearchObj.talentAppearance.forEach((loopData) => {
        if (loopData.name.toLowerCase() === filterType[1].toLowerCase()) {
          loopData.isChecked = true;
        }
      });

      if (
        filterType[1].toLowerCase() === 'notes' ||
        filterType[1].toLowerCase() === 'retouch'
      ) {
        this.advanceSearchObj.other.forEach((loopData) => {
          if (
            loopData.name === 'Retouch Comment' &&
            filterType[1].toLowerCase() === 'retouch'
          ) {
            loopData.isChecked = true;
          }
          if (
            loopData.name === 'Image Notes' &&
            filterType[1].toLowerCase() === 'notes'
          ) {
            loopData.isChecked = true;
          }
        });
      }
    } else if (filterType[0] === 'taggedwith') {
      this.onChangedTag({
        id: Number(filterType[1]),
        killRight: this.isTaggingWithkillRight,
      });
    }
  }

  handleModalStatus() {
    const observables = [];

    observables.push(
      this.appStateService.noConflicts$
        .pipe(takeUntil(this.destroy$))
        .subscribe((state) => {
          if (state.loaded) {
            this.viewFilter = true;
          } else {
            this.viewFilter = false;
          }
        }),
    );

    observables.push(
      this.dialogService
        .getDialogState(Taggingview1Component.identifier)
        .state$.pipe(
          takeUntil(this.destroy$),
          tap((state) => {
            if (state.opened) {
              this.viewFilter = true;
            } else {
              this.viewFilter = false;
            }
          }),
        ),
    );

    observables.push(
      this.dialogService
        .getDialogState(Taggingview2Component.identifier)
        .state$.pipe(takeUntil(this.destroy$)),
      tap((state: any) => {
        if (state.opened) {
          this.viewFilter = true;
        } else {
          this.viewFilter = false;
        }
      }),
    );

    observables.push(
      this.dialogService
        .getDialogState(ConflictsComponent.identifier)
        .state$.pipe(takeUntil(this.destroy$)),
      tap((state: any) => {
        if (state.opened) {
          this.viewFilter = true;
        } else {
          this.viewFilter = false;
        }
      }),
    );

    observables.push(
      this.appStateService.bypass2ndtagger$.pipe(takeUntil(this.destroy$)),
      map((state: any) => {
        if (state.loaded) {
          this.viewFilter = true;
        } else {
          this.viewFilter = false;
        }
      }),
    );

    combineLatest(observables)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([
          noConflicts,
          taggingview1,
          taggingview2,
          conflicts,
          bypass2ndtagger,
        ]: { loaded: boolean }[]) => {
          if (
            !bypass2ndtagger.loaded &&
            !taggingview1.loaded &&
            !taggingview2.loaded &&
            !conflicts.loaded &&
            !noConflicts.loaded
          ) {
            this.viewFilter = false;
          }
        },
      );
  }

  onChangedTag(tagdata) {
    if (tagdata.killRight) {
      const index = this.advanceSearchObj.publishedTagIds.indexOf(tagdata.id);
      if (index === -1) {
        this.advanceSearchObj.publishedTagIds.push(tagdata.id);
      } else {
        this.advanceSearchObj.publishedTagIds.splice(index, 1);
      }
    } else {
      const index = this.advanceSearchObj.generalTagIds.indexOf(tagdata.id);
      if (index === -1) {
        this.advanceSearchObj.generalTagIds.push(tagdata.id);
      } else {
        this.advanceSearchObj.generalTagIds.splice(index, 1);
      }
    }
  }

  onChangedProjectAlbum(albumData) {
    const index = this.advanceSearchObj.albums.indexOf(albumData.id);
    if (index === -1) {
      this.advanceSearchObj.projectAlbum.push(albumData.id);
    } else {
      this.advanceSearchObj.projectAlbum.splice(index, 1);
    }
  }

  onChangedAlbum(albumData) {
    const index = this.advanceSearchObj.albums.indexOf(albumData.id);
    if (index === -1) {
      this.advanceSearchObj.albums.push(albumData.id);
    } else {
      this.advanceSearchObj.albums.splice(index, 1);
    }
  }

  onStatusChange(statusAssets) {
    this.advanceSearchObj.status = statusAssets;
  }

  onExcludedStatusChange(excludedAssets) {
    this.advanceSearchObj.excludedReleasedStatus = excludedAssets;
  }

  onApprovalStatusChange(approvalStatus) {
    if (approvalStatus.name === 'Approved') {
      this.advanceSearchObj.approvalStatus[0].isChecked = true;
      this.advanceSearchObj.approvalStatus[1].isChecked = false;
    } else {
      this.advanceSearchObj.approvalStatus[0].isChecked = false;
      this.advanceSearchObj.approvalStatus[1].isChecked = true;
    }
  }

  onImageBatchChange(imageBatch) {
    imageBatch.isChecked = !imageBatch.isChecked;
    if (
      !this.advanceSearchObj.imageBatch[1].isChecked &&
      this.batchList.length
    ) {
      this.configurationGroup.get('batch').setValue(this.batchList[0].value);
      this.selectedBatch = '';
    }
  }

  onChangeExclusives(exclusives) {
    exclusives.isChecked = !exclusives.isChecked;
    if (exclusives.name === 'All Exclusives' && exclusives.isChecked) {
      this.advanceSearchObj.exclusives[1].isChecked = false;
    } else if (exclusives.name === 'Select Exclusive' && exclusives.isChecked) {
      this.advanceSearchObj.exclusives[0].isChecked = false;
    }

    if (
      !this.advanceSearchObj.exclusives[1].isChecked &&
      this.exclusiveList.length
    ) {
      this.configurationGroup
        .get('exclusive')
        .setValue(this.exclusiveList[0].value);
      this.selectedExclusive = '';
    }
  }

  onClickedReset() {
    this.advanceSearchObj = JSON.parse(JSON.stringify(AdvanceSearchFilter));
    if (this.batchList.length) {
      this.configurationGroup.get('batch').setValue(this.batchList[0].value);
    }
    if (this.exclusiveList.length) {
      this.configurationGroup
        .get('exclusive')
        .setValue(this.exclusiveList[0].value);
    }
    this.selectedBatch = '';
    this.selectedExclusive = '';
  }

  closeWindow() {
    this.onClickedReset();
    this.isdeliveryShown = false;
    this.dialogRef.close();
  }

  filterForImageBatch(value) {
    this.selectedBatch = value;
    this.advanceSearchObj.imageBatch[1].value = value;
  }

  onClikedSubmit() {
    this.projectDetailPermission =
      this.viewProjectService.mergeWithProjectDetailPermissionData(
        this.projectDetailPermission,
      );

    const obj = {
      albumIds: this.advanceSearchObj.albums,
      projectAlbumIds: this.advanceSearchObj.projectAlbum,
      allExclusive: this.advanceSearchObj.exclusives[0].isChecked,
      approved: this.advanceSearchObj.approvalStatus[0].isChecked,
      assetName: this.advanceSearchObj.imageBatch[0].isChecked
        ? this.advanceSearchObj.imageBatch[0].value
        : '',
      batchId: this.advanceSearchObj.imageBatch[1].isChecked
        ? this.selectedBatch
        : '',
      excludeReleaseStatus: this.advanceSearchObj.excludedReleasedStatus,
      excludedFrom: this.advanceSearchObj.talentExecAttributes[2].isChecked,
      exclusiveId: this.advanceSearchObj.exclusives[1].isChecked
        ? this.selectedExclusive
        : '',
      filterOption: this.advanceSearchObj.filterOpetion[0].isChecked,
      finalizedBy: this.advanceSearchObj.talentExecAttributes[0].isChecked,
      generalTagIds: this.advanceSearchObj.generalTagIds,
      group: this.advanceSearchObj.talentAppearance[1].isChecked,
      lockedPendingStatus: this.advanceSearchObj.status,
      not: this.advanceSearchObj.talentExecAttributes[3].isChecked,
      notes: this.advanceSearchObj.other[0].isChecked,
      priorityPix: this.advanceSearchObj.other[1].isChecked,
      projectId: this.viewProjectService.getProjectId(),
      publishedTagIds: this.advanceSearchObj.publishedTagIds,
      rejected: this.advanceSearchObj.approvalStatus[1].isChecked,
      rejectedBy: this.advanceSearchObj.talentExecAttributes[1].isChecked,
      retouch: this.advanceSearchObj.other[2].isChecked,
      solo: this.advanceSearchObj.talentAppearance[0].isChecked,
      viewFilter: this.viewFilter,
      images: this.deliveryOption.imageSize[0].isChecked,
      types: this.selectedFileType(),
      serverType: this.selectedDeliveryServer,
      serverPath: '',
      semicolonDelimiter: this.deliveryOption.otherOptions[0].isChecked,
      csv: this.deliveryOption.otherOptions[1].isChecked,
    };

    this.loaderService.displayLoader(true);
    this.advanceSearchService
      .postAdvanceDelivery(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            this.selectedImagesStore.clear();
            this.snackBarService.showSnackBar(res.message);
            this.closeWindow();
          } else {
            this.alertCustom(this.translate.instant('view-project.alert2'));
          }
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  onChangeFIleSize() {
    this.totalSelectedFileSize = this.selectedFileType();
    if (this.totalSelectedFileSize.length === 0) {
      this.deliveryOption.imageSize[0].isChecked = false;
    }
  }

  selectedFileType() {
    const tempArray = [];
    this.deliveryOption.fileSize.forEach((obj) => {
      if (obj.isChecked) {
        tempArray.push(obj?.lable?.split(' ').join(''));
      }
    });

    return tempArray;
  }

  onChangeTalentExecAttributes(talentExecAttributes) {
    talentExecAttributes.isChecked = !talentExecAttributes.isChecked;
    if (
      !this.advanceSearchObj.talentExecAttributes[0].isChecked &&
      !this.advanceSearchObj.talentExecAttributes[1].isChecked &&
      !this.advanceSearchObj.talentExecAttributes[2].isChecked
    ) {
      this.advanceSearchObj.talentExecAttributes[3].isChecked = false;
    }
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  onChangeImageSize(imageSizeSelection) {
    this.deliveryOption.fileSize[1].isChecked = imageSizeSelection;
    if (!imageSizeSelection) {
      this.deliveryOption.fileSize.forEach((fileSizeObj) => {
        fileSizeObj.isChecked = false;
      });
    }
    this.totalSelectedFileSize = this.selectedFileType();
  }
}
