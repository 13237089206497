import { DashboardLauncherAddFavouriteIconRule } from '../rules/dashboard-launcher-add-favourite-icon-rule';
import { FavSelectedProjectModel } from '../../dashboard-favourite-project/interfaces/dashboard-favourite-icon-rule.model';

type DashboardEventsUnion = DashboardLauncherAddFavouriteIconRule;

export class DashboardLauncherFavouriteIconRuleset {
  ruleSet: DashboardEventsUnion[];

  constructor(
    private active: boolean,
    private selectedProject: FavSelectedProjectModel,
    private setHeightToProjectListContainerFn: () => void,
    private getFavClassFn: (count: number) => void,
  ) {
    this.ruleSet = [
      new DashboardLauncherAddFavouriteIconRule(
        active,
        selectedProject,
        setHeightToProjectListContainerFn,
        getFavClassFn,
      ),
    ];
  }

  apply(): void {
    for (const rule of this.ruleSet) {
      if (rule.matches()) {
        rule.apply();
      }
    }
  }
}
