<div class="sub-header" *ngIf="id">
  <pagination-template
    #p="paginationApi"
    (pageChange)="onPageChange($event, true)"
    [id]="id"
  >
    <div class="pagination">
      <div
        class="pagination-row firstpage"
        (click)="onClickFirstpage()"
        *ngIf="isGallaryMode || isFavGallaryMode"
      >
        <span class="fa fa-step-backward" aria-hidden="true"></span>
      </div>

      <div
        class="pagination-row firstpage"
        (click)="onClickFirstpage()"
        [ngClass]="{ disabled: p.isFirstPage() }"
        *ngIf="!isGallaryMode && !isFavGallaryMode"
      >
        <span class="fa fa-step-backward" aria-hidden="true"></span>
      </div>

      <div
        class="pagination-row firstpage"
        [ngClass]="{ disabled: p.isFirstPage() }"
      >
        <span
          class="fa fa-caret-left fa-caret"
          aria-hidden="true"
          (click)="p.previous()"
        ></span>
      </div>

      <ng-container *ngIf="p.pages?.length > 0; else noPages">
        <div
          class="pagination-row page-num-counter"
          *ngFor="let page of p.pages"
          [class.active]="p.getCurrent() === page.value"
        >
          <a
            (click)="p.setCurrent(page.value)"
            [ngClass]="{ hide: p.getCurrent() !== page.value }"
          >
            <span>
              {{ page.label }} {{ of }}
              {{ lastElement ? lastElement : 0 }}</span
            >
          </a>
        </div>
      </ng-container>
      <!--Sometimes p.pages array is empty, this ng-template to handle this case-->
      <ng-template #noPages>
        <div class="page_num_li active">
          <span> {{ lastElement }} {{ of }} {{ lastElement }}</span>
        </div>
      </ng-template>
      <div
        class="pagination-row lastpage"
        [ngClass]="{ disabled: p.isLastPage() }"
      >
        <span
          class="fa fa-caret-right fa-caret pagination-row-icon-padding"
          aria-hidden="true"
          (click)="p.next()"
        >
        </span>
      </div>

      <div
        class="pagination-row lastpage"
        *ngIf="isGallaryMode || isFavGallaryMode"
        (click)="onClickLastPage()"
      >
        <span
          class="fa fa-step-forward pagination-row-icon-padding"
          aria-hidden="true"
        ></span>
      </div>

      <div
        class="pagination-row lastpage"
        [ngClass]="{ disabled: p.isLastPage() }"
        (click)="onClickLastPage()"
        *ngIf="!isGallaryMode && !isFavGallaryMode"
      >
        <span
          class="fa fa-step-forward pagination-row-icon-padding"
          aria-hidden="true"
        ></span>
      </div>
    </div>
  </pagination-template>
</div>
