import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { PopupContainerComponent } from '../../components/popup-container/popup-container.component';
import { PopupConfig } from './popup-config';
import { ComponentRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

export class PopupRef<C> {
  componentInstance: C;

  get popupContainer() {
    return this.popupContainerRef.instance;
  }

  private afterClosedPopup: Subject<unknown> = new Subject<unknown>();
  private destroy$ = new Subject(); // todo think what to do with this in class

  constructor(
    public overlayRef: OverlayRef,
    private popupConfig: PopupConfig<unknown>,
    public popupContainerRef: ComponentRef<PopupContainerComponent>,
    public target: HTMLElement,
  ) {}

  hide<T>(data: T = null, delay?: number) {
    this.popupContainer.hide(delay ?? this.popupConfig.hideDelay);
    this.popupContainer
      .afterHidden()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.overlayRef.detachBackdrop();
        this.overlayRef.dispose();
        this.afterClosedPopup.next(data);
        this.afterClosedPopup.complete();
      });
  }

  show() {
    this.popupContainer.show(this.popupConfig.showDelay);
  }

  afterClosed() {
    return this.afterClosedPopup.asObservable();
  }

  updatePosition() {
    this.overlayRef.updatePosition();
  }
}
