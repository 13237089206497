import { NgModule } from '@angular/core';
import { ExpressDownloadResultsContainerComponent } from './express-download-results-container.component';
import { ExpressDownloadStoreService } from '../../services/express-download-store.service';
import { SharedModule } from '../../shared.module';

@NgModule({
  imports: [SharedModule],
  declarations: [ExpressDownloadResultsContainerComponent],
  exports: [ExpressDownloadResultsContainerComponent],
  providers: [ExpressDownloadStoreService],
})
export class ExpressDownloadResultsModule {}
