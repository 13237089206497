import { DashboardFavouriteProjectSetupRule } from '../rules/dashboard-favourite-project-setup-rule';
import { DashboardFavouriteProjectRule } from '../rules/dashboard-favourite-project-rule';
import { FavListModel } from '../interfaces/dashboard-favourite-rule.model';

type DashboardEventsUnion =
  | DashboardFavouriteProjectSetupRule
  | DashboardFavouriteProjectRule;

export class DashboardFavouriteProjectRuleset {
  ruleSet: DashboardEventsUnion[];

  constructor(private selectedProject: FavListModel) {
    this.ruleSet = [
      new DashboardFavouriteProjectSetupRule(selectedProject),
      new DashboardFavouriteProjectRule(selectedProject),
    ];
  }

  apply(): void {
    for (const rule of this.ruleSet) {
      if (rule.matches()) {
        rule.apply();
      }
    }
  }
}
