import { Component, Inject } from '@angular/core';
import { PopupRef } from '../../../../../../shared/services/popup/popup-ref';
import { FS_POPUP_DATA } from '../../../../../../shared/services/popup/popup.constants';
import { SelectedImages } from '../../../../../../shared/models/selectedImages.model';

interface NotesMenuData {
  selectImg: SelectedImages;
  index: number;
  event: MouseEvent;
  noteOptionType: string;
}

@Component({
  selector: 'fs-note-menu',
  templateUrl: './note-menu.component.html',
  styleUrls: ['./note-menu.component.scss'],
})
export class NoteMenuComponent {
  public ind: number;
  public event: MouseEvent;
  public imgData: SelectedImages;
  public selectNoteOption: (data: NotesMenuData) => void;

  constructor(
    private popupRef: PopupRef<NoteMenuComponent>,
    @Inject(FS_POPUP_DATA)
    private data: {
      imgData: SelectedImages;
      event: MouseEvent;
      selectNoteOption: (data: NotesMenuData) => void;
      ind: number;
    },
  ) {
    this.ind = data.ind;
    this.event = data.event;
    this.imgData = data.imgData;
    this.selectNoteOption = data.selectNoteOption;
  }

  onSelectImageOnClickNotes({
    selectImg,
    index,
    event,
    noteOptionType,
  }: NotesMenuData) {
    this.selectNoteOption({
      selectImg,
      index,
      event: this.event,
      noteOptionType,
    });
    this.popupRef.hide();
  }

  closeWindow() {
    this.popupRef.hide();
  }
}
