import { Utils } from './../../../../../../shared/utils/utils';
import { Component, OnInit } from '@angular/core';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { takeUntil } from 'rxjs/operators';
import {
  ProjectDetailPermission,
  TalentProjectInfoDTO,
} from '../../../../../../shared/models/projectpermission.model';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';

enum COLOR {
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
}

@Component({
  selector: 'fs-talent-projectinfo',
  templateUrl: './talent-projectinfo.component.html',
  styleUrls: ['./talent-projectinfo.component.scss'],
})
export class TalentProjectInfoComponent
  extends UnsubscriberComponent
  implements OnInit
{
  tertiary = false;
  totalImageCount = 0;
  totalFilterCount = 0;
  objTelentUserProjectInfo: any = {};

  talentUserProjectInfo: TalentProjectInfoDTO;
  selectedBatchId: number;
  initialProjectDetailPermission: ProjectDetailPermission;
  priorityPixView: boolean;
  projectDetailPermission: ProjectDetailPermission;

  soloColor: string = COLOR.GREEN;
  groupColor: string = COLOR.GREEN;
  groupPlusColor: string = COLOR.GREEN;

  constructor(private viewProjectService: ViewProjectService) {
    super();
  }

  ngOnInit() {
    this.viewProjectService.totalImageCountGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((count) => {
        this.totalImageCount = count;
        this.totalFilterCount = count;
      });

    this.viewProjectService.projectDataGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.totalFilterCount = data.assetInfoDTOList.length;
        }
      });

    this.viewProjectService.selectedBatchId$
      .pipe(takeUntil(this.destroy$))
      .subscribe((batchId) => {
        this.selectedBatchId = batchId;
        this.buildTalentUserProjectInfo();
        this.callTalentProjectChecks();
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (Object.keys(data).length !== 0) {
          this.initialProjectDetailPermission = JSON.parse(
            JSON.stringify(data),
          );
          this.projectDetailPermission = JSON.parse(JSON.stringify(data));
          this.tertiary = data.tertiary;

          this.buildTalentUserProjectInfo();
          this.callTalentProjectChecks();
        }
      });

    this.viewProjectService.priorityPixHandling$
      .pipe(takeUntil(this.destroy$))
      .subscribe((priorityPixView) => {
        this.priorityPixView = priorityPixView;

        if (this.priorityPixView) {
          this.buildTalentUserProjectInfo();
          this.callTalentProjectChecks();
        }
      });
  }

  private buildTalentUserProjectInfo() {
    if (!this.initialProjectDetailPermission) {
      return;
    }
    // restore initial data
    this.projectDetailPermission = JSON.parse(
      JSON.stringify(this.initialProjectDetailPermission),
    );
    this.talentUserProjectInfo = JSON.parse(
      JSON.stringify(this.projectDetailPermission.talentProjectInfoDTO),
    );
    if (!this.selectedBatchId || this.priorityPixView) {
      // if batch is not selected -> we are in ALL project view || WDTV gallery view
      // if priorityPixView is selected -> we are in priorotyView, so we should display data for all batches together
      // continue with old logic -> keep in talentUserProjectInfo exact copy of data.talentProjectInfoDTO
      // continue with old logic -> keep in projectDetailPermission exact copy of initialProjectDetailPermission
      // just need to reset totalFilterCount
      this.totalFilterCount = this.talentUserProjectInfo.currentBatch;
    } else {
      // if batch is selected -> we are in filter/batchid:XXXX view
      // get data.talentProjectInfoDTO and fill only data related to selected batch to this.talentUserProjectInfo

      // find selectedBatch
      const selectedBatch = this.projectDetailPermission.talentStatDTOList.find(
        (batchData) => batchData.batchId === this.selectedBatchId,
      );

      // get necessary data from data.talentProjectInfoDTO for selectedBatch and store it in talentUserProjectInfo
      // Solo
      this.talentUserProjectInfo.soloAssetCount =
        selectedBatch.pendingSoloCount;
      this.talentUserProjectInfo.soloAssetRejection =
        selectedBatch.pendingSoloRejectedCount;
      this.talentUserProjectInfo.soloApproved =
        selectedBatch.totalSoloCount === 0
          ? 0
          : Math.ceil(
              (100 *
                (selectedBatch.totalSoloCount -
                  selectedBatch.totalSoloRejectedCount)) /
                selectedBatch.totalSoloCount,
            );
      // Group
      this.talentUserProjectInfo.groupAssetCount =
        selectedBatch.pendingGroupCount;
      this.talentUserProjectInfo.groupAssetRejection =
        selectedBatch.pendingGroupRejectedCount;
      this.talentUserProjectInfo.groupApproved =
        selectedBatch.totalGroupCount === 0
          ? 0
          : Math.ceil(
              (100 *
                (selectedBatch.totalGroupCount -
                  selectedBatch.totalGroupRejectedCount)) /
                selectedBatch.totalGroupCount,
            );
      // Group+
      this.talentUserProjectInfo.groupPlusAssetCount =
        selectedBatch.pendingTertiaryCount;
      this.talentUserProjectInfo.groupPlusAssetRejection =
        selectedBatch.pendingTertiaryRejectedCount;
      this.talentUserProjectInfo.groupPlusApproved =
        selectedBatch.totalTertiaryCount === 0
          ? 0
          : Math.ceil(
              (100 *
                (selectedBatch.totalTertiaryCount -
                  selectedBatch.totalTertiaryRejectedCount)) /
                selectedBatch.totalTertiaryCount,
            );

      // All data
      this.talentUserProjectInfo.currentBatch =
        selectedBatch.imageCount - selectedBatch.finalizedCount;
      this.totalFilterCount =
        selectedBatch.imageCount - selectedBatch.finalizedCount;

      // store this.talentUserProjectInfo to this.projectDetailPermission // TODO double check, this looks weird, but with out it -> it doesnt work
      this.projectDetailPermission.talentProjectInfoDTO = JSON.parse(
        JSON.stringify(this.talentUserProjectInfo),
      );

      // keep only selected batch
      this.projectDetailPermission.talentStatDTOList = JSON.parse(
        JSON.stringify([selectedBatch]),
      );
    }
  }

  private callTalentProjectChecks() {
    if (!this.projectDetailPermission) {
      return;
    }
    const data = JSON.parse(JSON.stringify(this.projectDetailPermission));
    switch (true) {
      case Utils.talentSoloRejectionCalculation(data) === 0:
        this.soloColor = COLOR.RED;
        break;
      case Utils.talentSoloRejectionCalculation(data) > 0 &&
        Utils.talentSoloRejectionCalculation(data) < 10:
        this.soloColor = COLOR.YELLOW;
        break;
      default:
        this.soloColor = COLOR.GREEN;
        break;
    }

    switch (true) {
      case Utils.talentGroupRejectionCalculation(data) === 0:
        this.groupColor = COLOR.RED;
        break;
      case Utils.talentGroupRejectionCalculation(data) > 0 &&
        Utils.talentGroupRejectionCalculation(data) < 10:
        this.groupColor = COLOR.YELLOW;
        break;
      default:
        this.groupColor = COLOR.GREEN;
        break;
    }

    switch (true) {
      case this.talentUserProjectInfo.groupPlusAssetRejection === 0:
        this.groupPlusColor = COLOR.RED;
        break;
      case this.talentUserProjectInfo.groupPlusAssetRejection > 0 &&
        this.talentUserProjectInfo.groupPlusAssetRejection < 10:
        this.groupPlusColor = COLOR.YELLOW;
        break;
      default:
        this.groupPlusColor = COLOR.GREEN;
        break;
    }
  }
}
