import { Router } from '@angular/router';
import { LauncherService } from '../../../../launcher/launcher.service';
import { SelectedProjectModel } from '../interfaces/dashboard-launcher-rule.model';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { DEPARTMENT } from '../../../../../shared/constants/constants';
import { ProjectRoleId } from '../../../../../shared/enum/project-role.enum';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardLauncherDepartmentRule implements DashboardRuleModel {
  private router = GetStaticInjector().get(Router);
  private launcherService = GetStaticInjector().get(LauncherService);

  constructor(
    private groupType: string,
    private selectedProject: SelectedProjectModel,
  ) {}

  matches(): boolean {
    return this.groupType === DEPARTMENT;
  }

  apply(): void {
    this.launcherService.setSelectedProject(this.selectedProject.groupId);
    this.launcherService.setSelectedProjectName(this.selectedProject.groupId);
    this.launcherService.setIsGallaryModeStatus(true);
    this.launcherService.setProjectRole(ProjectRoleId.ROLE_EXEC);
    this.router.navigate([
      '/dashboard/department/' + this.selectedProject.groupId,
    ]);
  }
}
