import { Component, OnInit } from '@angular/core';
import { AuditService } from './audit.service';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../../../../shared/services/snack-bar.service';
import { PasswordComponent } from '../password/password.component';
import { TopmenusService } from '../../../top-menu/top-menu.service';
import { Activemodal } from '../../../../../../shared/models/activemodal.model';
import { AssetInfo } from '../../../../../../shared/models/assetInfo.model';
import { ProjectDetailPermission } from '../../../../../../shared/models/projectpermission.model';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { Modalstatusservice } from '../../../../../../shared/services/modalstatus.service';
import { LoaderService } from '../../../../../../shared/services/loader.service';
import { DialogService } from '../../../../../../shared/services/dialog/dialog.service';
import { ConstantsGlobal } from '../../../../../../shared/constants/constants';
import { ClearSingleTagComponent } from './clear-single-tag/clear-single-tag.component';
import { ConflictsComponent } from './conflicts/conflicts.component';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../shared/store/selected-images-store';
import { Taggingview1Component } from '../administration/taggingview-1/taggingview-1.component';
import { Taggingview2Component } from '../administration/taggingview-2/taggingview-2.component';
import { AppStateService } from '../../../../../../shared/services/app-state.service';
import { FilterEnum } from '../../../../../../shared/enum/filter.enum';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { ConvertAssetTypeComponent } from './convert-asset-type/convert-asset-type.component';
import {ApiErrorHandlerService} from '../../../../../../shared/services/api-error-handler.service';
import { MetaDataService } from '../../../../../../shared/services/meta-data.service';
import { UserService } from '../../../../../../shared/services/user.service';

@Component({
  selector: 'fs-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
  providers: [AuditService],
})
export class AuditComponent extends UnsubscriberComponent implements OnInit {
  activemodalTag: any;
  activemodal: Activemodal = new Activemodal();
  isAuditActive = false;
  projectImageData: AssetInfo = new AssetInfo();
  projectDetailData: ProjectDetailPermission = new ProjectDetailPermission();

  constantsGlobalData: any;
  isEnlarge = false;
  isSuperZoom = false;
  taggingEnable: Array<string> = [];
  istaggingEnabled = false;
  taggingView1Open = false;
  taggingView2Open = false;
  isTaggerRole: boolean;

  constructor(
    private auditService: AuditService,
    private viewProjectService: ViewProjectService,
    private snackBarService: SnackBarService,
    private modalStatusService: Modalstatusservice,
    private loaderService: LoaderService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private fb: UntypedFormBuilder,
    private metaDataService: MetaDataService,
    private translate: TranslateService,
    private topmenusService: TopmenusService,
    private dialogService: DialogService,
    private userService: UserService,
    private appStateService: AppStateService,
    private selectedImagesStore: SelectedImagesStore,
  ) {
    super();
  }

  ngOnInit() {
    this.isTaggerRole = this.userService.isAdminWithTaggerRole();

    this.constantsGlobalData = ConstantsGlobal;
    this.taggingEnable = [
      this.constantsGlobalData.MODELNAMES.TAGGINGVIEW1,
      this.constantsGlobalData.MODELNAMES.TAGGINGVIEW2,
    ];

    // todo: need to fix

    this.modalStatusService.getActiveModal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (this.taggingEnable.indexOf(res.modalName) !== -1) {
          this.activemodalTag = res;
          if (res.isActive) {
            this.istaggingEnabled = true;
          } else {
            this.istaggingEnabled = false;
          }
        }
      });

    this.topmenusService.topMenuStatus
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res === 'Enlarge') {
          this.isEnlarge = true;
        } else {
          this.isEnlarge = false;
        }

        if (res === 'SuperZoom') {
          this.isSuperZoom = true;
        } else {
          this.isSuperZoom = false;
        }
      });

    this.viewProjectService.isTaggingView1OpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.taggingView1Open = status;
      });

    this.viewProjectService.isTaggingView2OpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.taggingView2Open = status;
      });
  }

  onClickClearTag() {
    if (this.selectedImagesStore.hasEntities()) {
      this.openPasswordDialog({
        isClickedOnClearTag: true,
      }).subscribe((data) => {
        if (data) {
          this.callClearAllTagsAPI();
        }
      });
      this.viewProjectService.isModelPopupWindowOpen.next(true);
    } else {
      this.alertCustom(this.translate.instant('view-project.alert21'));
    }
  }

  onOpenClearSingleTagComponent() {
    this.dialogService.open(ClearSingleTagComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-md-panel',
        'fs-dialog-link-panel',
      ],
    });
  }

  onClickClearSingleTag() {
    if (this.selectedImagesStore.hasEntities()) {
      this.openPasswordDialog({
        isClickedOnClearSingleTag: true,
      }).subscribe((data) => {
        if (data) {
          this.onOpenClearSingleTagComponent();
        }
      });
      this.viewProjectService.isModelPopupWindowOpen.next(true);
    } else {
      this.alertCustom(this.translate.instant('view-project.alert21'));
    }
  }

  onConflictsClick() {
    this.checkTaggingDialogStatus();
    const filter = FilterEnum.AuditAndConflicts;
    this.loaderService.displayLoader(true);
    this.viewProjectService
      .callProjectImageIDs(this.viewProjectService.getProjectId(), filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.loaderService.displayLoader(false);
        if (response) {
          this.openConflictsDialog(filter, response);
        } else {
          this.alertCustom(this.translate.instant('view-project.alert2'));
        }
      }, (error => {
        this.apiErrorHandlerService.getHandler().handle(error);
      }));
  }

  checkTaggingDialogStatus() {
    if (this.taggingView1Open) {
      this.dialogService.closeDialogById(Taggingview1Component.identifier);
    }
    if (this.taggingView2Open) {
      this.dialogService.closeDialogById(Taggingview2Component.identifier);
    }
  }

  openConflictsDialog(filter, response) {
    this.dialogService.open(ConflictsComponent, {
      hasBackdrop: false,
      position: {
        top: '10px',
        left: '10%',
      },
      panelClass: ['fs-dialog-panel', 'fs-dialog-container-auto-width-panel'],
      enableHandlingUpKey: true,
      data: {
        filter: filter,
        response: response,
      },
      listener: {
        withListener: true,
        id: 'ConflictsComponent',
      },
    });
  }

  callClearAllTagsAPI() {
    this.loaderService.displayLoader(true);
    const body = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      projectId: this.viewProjectService.getProjectId(),
    };
    this.auditService
      .putClearAllTags(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.loaderService.displayLoader(false);
          if (res) {
            this.updateProjectData(res);
            this.snackBarService.showSnackBar('Success');
          } else {
            this.alertCustom('Fail');
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  onOpenConvertAssetTypeDialog() {
    if (this.selectedImagesStore.hasEntities()) {
      this.dialogService.open(ConvertAssetTypeComponent, {
        panelClass: [
          'fs-dialog-panel',
          'fs-dialog-size-md-panel',
          'fs-dialog-link-panel',
        ],
      });
    } else {
      this.alertCustom(this.translate.instant('view-project.alert13'));
    }
  }

  updateProjectData(res) {
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    this.projectDetailData =
      this.viewProjectService.mergeWithProjectDetailPermissionData(
        this.projectDetailData,
      );
    this.projectDetailData.unpublishedAssets = res.unpublishedAssets;
    this.projectDetailData.projectTagListDTO = res.projectTagListDTO;
    this.viewProjectService.setProjectDetailPermissionData(
      this.projectDetailData,
    );
    this.selectedImagesStore.clear();

    res.assetIds.forEach((eachObj) => {
      const imageIndex = this.projectImageData.assetInfoDTOList
        .map(function (e) {
          return e.id;
        })
        .indexOf(eachObj);

      if (imageIndex !== -1) {
        res.blankObj = [];
        this.projectImageData.assetInfoDTOList[imageIndex].status = 6;
        this.projectImageData.assetInfoDTOList[imageIndex].generalTags =
          res.blankObj;
        this.projectImageData.assetInfoDTOList[imageIndex].publishedTags =
          res.blankObj;
        this.projectImageData.assetInfoDTOList[imageIndex].tagKeys =
          res.blankObj;
      }
    });

    this.viewProjectService.setProjectData(this.projectImageData);
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  private openPasswordDialog(data) {
    return this.dialogService.open(PasswordComponent, {
      data,
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-md-panel',
        'fs-dialog-link-panel',
      ],
    });
  }
}
