import { FavSelectedProjectModel } from '../interfaces/dashboard-favourite-icon-rule.model';
import { Group } from '../../../../../shared/models/projectlist.model';
import { EventEmitter } from '@angular/core';
import { DashboardFavouriteRemoveProjectRule } from '../rules/dashboard-favourite-remove-project-rule';

type DashboardEventsUnion = DashboardFavouriteRemoveProjectRule;

export class DashboardFavouriteIconRuleset {
  ruleSet: DashboardEventsUnion[];

  constructor(
    private active: boolean,
    private selectedProject: FavSelectedProjectModel,
    private groupedProject: Group[],
    private updatedFavStatus: EventEmitter<Group[]>,
  ) {
    this.ruleSet = [
      new DashboardFavouriteRemoveProjectRule(
        active,
        selectedProject,
        groupedProject,
        updatedFavStatus,
      ),
    ];
  }

  apply(): void {
    for (const rule of this.ruleSet) {
      if (rule.matches()) {
        rule.apply();
      }
    }
  }
}
