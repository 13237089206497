<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.talent-management.title'
        | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="talent-management-table-grid form-group">
    <div class="d-flex mr-1 ml-1 table-cell">
      <label
        class="control-label text-uppercase d-flex justify-content-center"
        style="padding-left: 10px"
      >
        {{
          'left-menu.tab-bar.sidemenus.administration.talent-management.name'
            | translate
        }}
      </label>
    </div>
    <div class="d-flex mr-1 ml-1 table-cell">
      <label class="control-label text-uppercase d-flex justify-content-center">
        {{
          'left-menu.tab-bar.sidemenus.administration.talent-management.username'
            | translate
        }}
      </label>
    </div>
    <div class="d-flex mr-1 ml-1 table-cell">
      <label class="control-label text-uppercase d-flex justify-content-center">
        {{
          'left-menu.tab-bar.sidemenus.administration.talent-management.password'
            | translate
        }}
      </label>
    </div>
    <div class="d-flex mr-1 ml-1 table-cell-small">
      <label class="control-label text-uppercase d-flex justify-content-center">
        {{
          'left-menu.tab-bar.sidemenus.administration.talent-management.solo'
            | translate
        }}% <span class="label-star ml-1"> * </span>
      </label>
    </div>
    <div class="d-flex mr-1 ml-1 table-cell-small">
      <label class="control-label d-flex justify-content-center">
        {{
          'left-menu.tab-bar.sidemenus.administration.talent-management.group'
            | translate
        }}% <span class="label-star ml-1"> * </span>
      </label>
    </div>
    <div class="d-flex mr-1 ml-1 table-cell">
      <label class="control-label d-flex justify-content-center">
        {{
          'left-menu.tab-bar.sidemenus.administration.talent-management.date'
            | translate
        }}
      </label>
    </div>
    <div class="d-flex mr-1 ml-1 table-cell">
      <label class="control-label d-flex justify-content-center">
        {{
          'left-menu.tab-bar.sidemenus.administration.talent-management.welcome'
            | translate
        }}
      </label>
    </div>
    <div class="d-flex mr-1 justify-content-center ml-1 table-cell-small">
      <label class="control-label text-uppercase d-flex justify-content-center">
        {{
          'left-menu.tab-bar.sidemenus.administration.talent-management.disbled'
            | translate
        }}
      </label>
    </div>
  </div>
  <ng-scrollbar autoHeightDisabled="false" track="all">
    <div class="fs-table-rows-container">
      <div
        class="talent-management-table-grid form-group"
        *ngFor="let telentData of arrListTalentedExistingData; let i = index"
      >
        <div class="d-flex mr-1 ml-1 table-cell">
          <input
            class="form-control input-sm black-font"
            type="text"
            [disabled]="true"
            [(ngModel)]="telentData.fullName"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
        <div class="d-flex mr-1 ml-1 table-cell eye-symbol-wpr">
          <input
            class="form-control input-sm black-font"
            type="text"
            [disabled]="true"
            [(ngModel)]="telentData.username"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
        <div class="d-flex mr-1 ml-1 table-cell eye-symbol-wpr">
          <input
            class="form-control input-sm black-font"
            type="text"
            *ngIf="telentData['isPasswordShown']"
            [disabled]="true"
            [(ngModel)]="telentData.password"
            [ngModelOptions]="{ standalone: true }"
          />
          <input
            class="form-control input-sm black-font"
            type="password"
            *ngIf="!telentData['isPasswordShown']"
            [disabled]="true"
            [(ngModel)]="telentData.password"
            [ngModelOptions]="{ standalone: true }"
          />
          <span class="eye-symbol" (click)="onClickEyeIcon(i)">
            <i
              class="fs-cursor-pointer fa"
              [ngClass]="{
                'fa-eye': !telentData.isPasswordShown,
                'fa-eye-slash': telentData.isPasswordShown
              }"
              aria-hidden="true"
            >
            </i>
          </span>
        </div>
        <div class="d-flex mr-1 ml-1 table-cell err-msg-cont">
          <!--                      TODO TEST THIS, Angular10 said no (change) listener, it is better to use (input)            -->
          <input
            type="number"
            (change)="
              onTalentDataChange(
                'soloKillPct',
                telentData.soloKillPct,
                telentData
              )
            "
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="telentData.soloKillPct"
            #solotext="ngModel"
            class="form-control input-sm"
            required
          />
          <div
            class="err-msg err-color"
            *ngIf="solotext.errors && (solotext.dirty || solotext.touched)"
          >
            <div *ngIf="solotext.errors.required">
              {{
                'left-menu.tab-bar.sidemenus.administration.talent-management.field'
                  | translate
              }}
            </div>
          </div>
        </div>

        <div class="d-flex mr-1 ml-1 table-cell-small">
          <!--                      TODO TEST THIS, Angular10 said no (change) listener, it is better to use (input)            -->
          <input
            type="number"
            class="form-control input-sm"
            required
            #group="ngModel"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="telentData.groupKillPct"
            (change)="
              onTalentDataChange(
                'groupKillPct',
                telentData.groupKillPct,
                telentData
              )
            "
          />

          <div
            class="err-msg err-color"
            *ngIf="group.errors && (group.dirty || group.touched)"
          >
            <div *ngIf="group.errors.required">
              {{
                'left-menu.tab-bar.sidemenus.administration.talent-management.field'
                  | translate
              }}
            </div>
          </div>
        </div>

        <div class="d-flex mr-1 ml-1 table-cell">
          <span class="d-flex align-items-center form-control pr-0">
            <input
              matInput
              inputRestriction="onlyInteger"
              class="fs-background-color-white-gray fs-input-table-data pl-0 pr-0"
              [placeholder]="
                'left-menu.tab-bar.sidemenus.administration.talent-management.placeholder.selectdate'
                  | translate
              "
              inputRestriction="integer"
              [matDatepicker]="picker"
              [value]="telentData.expireAt"
              [(ngModel)]="telentData.expireAt"
              [min]="min"
              (dateChange)="
                onTalentDataChange('expireAt', $event.value, telentData)
              "
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker [startAt]="min"></mat-datepicker>
          </span>
        </div>

        <div class="d-flex mr-1 ml-1 table-cell err-msg-cont">
          <input
            type="text"
            [maxlength]="100"
            (change)="
              onTalentDataChange(
                'welcomeMessage',
                telentData.welcomeMessage,
                telentData
              )
            "
            (keyup)="
              checkRemainingCharacters(
                telentData.userId,
                telentData.welcomeMessage
              )
            "
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="telentData.welcomeMessage"
            name="{{ telentData.welcomeMessage }}"
            class="form-control input-sm"
          />

          <span class="err-msg" *ngIf="telentData.userId == activeUserId">
            {{
              'left-menu.tab-bar.sidemenus.administration.talent-management.left'
                | translate
            }}
            {{ numberOfCharLeft }}
          </span>
        </div>

        <div
          class="d-flex justify-content-center align-items-center mr-1 ml-1 table-cell-small control-label disabled-checkbox"
        >
          <mat-checkbox
            (change)="
              onTalentDataChange('disabled', telentData.disabled, telentData)
            "
            [ngModelOptions]="{ standalone: true }"
            class="fs-checkbox"
            [checked]="telentData.disabled"
            name="{{ telentData.disabled }}"
            [(ngModel)]="telentData.disabled"
          >
            <span class="cb-title"></span>
          </mat-checkbox>
        </div>
      </div>
    </div>
  </ng-scrollbar>
</div>

<div mat-dialog-actions align="end">
  <button class="fs-dialog-btn w-auto ml-3" (click)="onclickSave()">
    {{
      'left-menu.tab-bar.sidemenus.administration.talent-management.save'
        | translate
    }}
  </button>

  <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
    {{
      'left-menu.tab-bar.sidemenus.administration.talent-management.cancel'
        | translate
    }}
  </button>
</div>
