<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{ 'view-project.group-single.title' | translate }}
  </div>
  <button
    type="button"
    (click)="closeWindow()"
    class="fs-close-btn w-auto"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle"></i>
  </button>
</div>

<div mat-dialog-content>
  <div>L_00025.jpg</div>
  <div class="text-uppercase my-2">
    <mat-select
      class="fs-selectbox-dark pl-2 py-1 pr-3"
      required
      placeholder="{{ 'view-project.group-single.select' | translate }}"
      optionLabel="name"
      (valueChange)="onGroupChanged($event)"
    >
      <mat-option
        class="fs-sm-txt fs-selectbox-options"
        *ngFor="let value of arrListValuesForGroupSingle"
        [value]="value.name"
      >
        {{ value.name }}
      </mat-option>
    </mat-select>
  </div>
</div>

<div mat-dialog-actions class="mf-sngle ftr-btns">
  <div>
    <button class="btn fs-close-btn h-auto w-auto ml-3" (click)="closeWindow()">
      {{ 'view-project.group-single.close' | translate }}
    </button>
  </div>
  <div>
    <button
      (click)="onClickApplyBtn()"
      class="btn h-auto fs-close-btn w-auto ml-3"
      id="mail_send_btn"
    >
      {{ 'view-project.group-single.apply' | translate }}
    </button>
  </div>
</div>
