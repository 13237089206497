import { Router } from '@angular/router';
import { LauncherService } from '../../../../launcher/launcher.service';
import { SearchProjectModel } from '../interfaces/dashboard-search-project-rule.model';
import { DepartmentService } from '../../../../department/department.service';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardSearchProjectSetupRule implements DashboardRuleModel {
  private router = GetStaticInjector().get(Router);
  private launcherService = GetStaticInjector().get(LauncherService);
  private departmentService = GetStaticInjector().get(DepartmentService);

  constructor(
    private fromDepartment: boolean,
    private selectedProjectId: number,
    private selectedProjectData: SearchProjectModel,
  ) {}

  matches(): boolean {
    return !this.fromDepartment && this.selectedProjectData.useSetup;
  }

  apply(): void {
    this.launcherService.setIsFavGallaryModeStatus(true);
    this.launcherService.setGallariesDepartmentID(
      this.selectedProjectData.departmentId,
    );
    this.departmentService.setSetUpNameGlobal(this.selectedProjectData.label);
    this.launcherService.setProjectRole(this.selectedProjectData.role);
    this.router.navigate(
      ['/dashboard/viewgallery/' + this.selectedProjectData.value],
      { state: { selectedProjectRole: this.selectedProjectData.role } },
    );
  }
}
