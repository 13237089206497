<span
  class="fa-stack position-relative fs-cursor-pointer"
  *ngIf="imgData.customAlbums.length > 0"
  (click)="customAlbumView.emit(imgData)"
>
  <i class="fa fa-folder fa-stack-2x"></i>
  <span class="album-folder-number">
    {{ imgData.customAlbums.length }}
  </span>
</span>
