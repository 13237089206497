import {
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  Renderer2,
  RendererStyleFlags2,
  SimpleChanges,
} from '@angular/core';
import {
  ConnectedOverlayPositionChange,
  HorizontalConnectionPos,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';

type ArrowPosition =
  | Exclude<HorizontalConnectionPos, 'center'>
  | Exclude<VerticalConnectionPos, 'center'>;

@Directive({ selector: '[popupArrowPosition]' })
export class PopupArrowPositionDirective implements OnChanges {
  @Input() targetElement: HTMLElement;
  @Input() position: ConnectedOverlayPositionChange;
  @Input() overlayContainerElement: HTMLElement;
  @Input() popupContainer: HTMLElement;

  constructor(
    private renderer: Renderer2,
    private element: ElementRef,
    private zone: NgZone,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.position?.currentValue) {
      this.handleArrowPosition(this.position);
    }
  }

  handleArrowPosition(position: ConnectedOverlayPositionChange) {
    const overlayPositionMapping: Record<ArrowPosition, Function> = {
      bottom: () => this.setPositionArrowBottom(),
      top: () => this.setPositionArrowTop(),
      start: () => this.setPositionArrowRight(),
      end: () => this.setPositionArrowLeft(),
    };
    position.connectionPair.overlayX === 'center'
      ? overlayPositionMapping[position.connectionPair.overlayY]?.()
      : overlayPositionMapping[position.connectionPair.overlayX]?.();
  }

  setPositionArrowTop(): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        const targetClientRect = this.targetElement.getBoundingClientRect();
        const overlayClientRect =
          this.overlayContainerElement.getBoundingClientRect();
        const arrowWidth = 20;
        const targetWidth = targetClientRect.width;
        const left =
          targetClientRect.x -
          overlayClientRect.x -
          arrowWidth / 2 +
          targetWidth / 2;

        this.refreshClasses();
        this.setArrowStyle('left', `${left}px`);
        this.setArrowStyle('top', `0px`);
        this.setArrowStyle('transform', `rotateZ(${0}deg)`);
      });
    });
  }

  setPositionArrowLeft(): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        const targetClientRect = this.targetElement.getBoundingClientRect();
        const overlayClientRect =
          this.overlayContainerElement.getBoundingClientRect();
        const arrowHeight = 14;

        const targetHeight = targetClientRect.height;
        const top =
          targetClientRect.y -
          overlayClientRect.y -
          arrowHeight / 2 +
          targetHeight / 2;

        this.refreshClasses();
        this.setArrowStyle('top', `${top}px`);
        this.setArrowStyle('left', `-3px`);
        this.setArrowStyle('transform', `rotateZ(${90}deg)`);

        this.renderer.addClass(this.popupContainer, 'arrow-position-left');
      });
    });
  }

  setPositionArrowRight(): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        const targetClientRect = this.targetElement.getBoundingClientRect();
        const overlayClientRect =
          this.overlayContainerElement.getBoundingClientRect();
        const arrowHeight = 14;
        const targetHeight = targetClientRect.height;
        const top =
          targetClientRect.y -
          overlayClientRect.y -
          arrowHeight / 2 +
          targetHeight / 2;

        this.refreshClasses();
        this.setArrowStyle('top', `${top}px`);
        this.setArrowStyle('right', `-4px`);
        this.setArrowStyle('transform', `rotateZ(${-90}deg)`);

        this.renderer.addClass(this.popupContainer, 'arrow-position-right');
      });
    });
  }

  setPositionArrowBottom(): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        const targetClientRect = this.targetElement.getBoundingClientRect();
        const overlayClientRect =
          this.overlayContainerElement.getBoundingClientRect();
        const arrowWidth = 20;
        const targetWidth = targetClientRect.width;
        const left =
          targetClientRect.x -
          overlayClientRect.x -
          arrowWidth / 2 +
          targetWidth / 2;

        this.refreshClasses();
        this.setArrowStyle('left', `${left}px`);
        this.setArrowStyle('top', `-2px`);
        this.setArrowStyle('transform', `rotateZ(${180}deg)`);

        this.renderer.addClass(this.popupContainer, 'arrow-position-top');
      });
    });
  }

  refreshClasses() {
    this.renderer.removeClass(this.popupContainer, 'arrow-position-right');
    this.renderer.removeClass(this.popupContainer, 'arrow-position-top');
    this.renderer.removeClass(this.popupContainer, 'arrow-position-left');
  }

  setArrowStyle(style, value): void {
    this.renderer.setStyle(
      this.element.nativeElement,
      style,
      value,
      RendererStyleFlags2.Important,
    );
  }
}
