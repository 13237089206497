import { ViewProjectService } from './../../../../../../../shared/services/view-project.service';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FinalizationService } from './finalization.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserDetails } from '../../../../../../../shared/models/userdetails.model';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { UserService } from '../../../../../../../shared/services/user.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../../../../../../../shared/utils/utils';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';
import { TranslateService } from '@ngx-translate/core';

const confirmTranslations = {
  en: 'CONFIRM',
  'en-us': 'CONFIRM',
  br: 'CONFIRMAR',
  fr: 'CONFIRMER',
  es: 'CONFIRMAR',
  ja: '確認する',
  kr: '확인',
  de: 'BESTÄTIGEN',
};
@Component({
  selector: 'fs-finalization',
  templateUrl: './finalization.component.html',
  styleUrls: ['./finalization.component.scss'],
})
export class FinalizationComponent
  extends UnsubscriberComponent
  implements OnInit
{
  showConfirm: boolean;
  showFinalize: boolean;
  confirm: string;
  constantsGlobalData: any;
  projectName = '';
  currentUser: UserDetails = new UserDetails();
  currentUserName = '';
  translatedConfirmWord = '';

  constructor(
    private modalService: Modalstatusservice,
    private finalizationService: FinalizationService,
    private loaderService: LoaderService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private viewProjectService: ViewProjectService,
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
    private dialogService: MatDialogRef<FinalizationComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.currentUser = this.userService.mergeWithCurrentUser(this.currentUser);
    this.translatedConfirmWord =
      confirmTranslations[this.translate.currentLang];
    this.currentUserName =
      this.currentUser.firstName + ' ' + this.currentUser.lastName + ' ';
    this.showConfirm = false;
    this.showFinalize = true;

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.projectName = res.projectName;
        } else {
          this.projectName = '';
        }
      });
  }

  closeWindow() {
    this.dialogService.close();
  }

  showConfirmMethod() {
    this.showConfirm = true;
    this.showFinalize = false;
  }

  onClickGo() {
    this.loaderService.displayLoader(true);
    this.finalizationService
      .putFinalizationAPI(this.data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            this.closeWindow();
            this.router.navigateByUrl('/');
          }
          this.closeWindow();
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }
}
