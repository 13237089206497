<div
  class="fs-results-box d-flex justify-content-center flex-column p-2"
  *ngIf="expressDownloadStoreService.isExpressDownloadResults()"
>
  <div
    class="fs-result-item fs-cursor-pointer d-flex justify-content-center my-1 align-items-center"
    (click)="onOpenExpressDownloadFinalStep(expressDownloadResult)"
    *ngFor="let expressDownloadResult of expressDownloadResultPool"
  >
    <span class="d-flex justify-content-center text-center align-items-center">
      {{ 'top-menu.expressDownload.alert' | translate }}</span
    >
  </div>
</div>
