import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarService } from '../../../../sidebar-service/sidebar-service';
import { LoaderService } from '../../../../../../shared/services/loader.service';
import { Modalstatusservice } from '../../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { Utils } from '../../../../../../shared/utils/utils';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../shared/store/selected-images-store';
import { DialogService } from '../../../../../../shared/services/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { FilterEnum } from '../../../../../../shared/enum/filter.enum';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-talent-proritypix-butons',
  templateUrl: './talent-proritypix-butons.html',
  styleUrls: ['./talent-proritypix-butons.scss'],
})
export class TalentProritypixButons
  extends UnsubscriberComponent
  implements OnInit
{
  isPrioritypixBtnShown: boolean;
  priritypixImageCount: number;
  noOfImages: any;

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private sidebarService: SidebarService,
    private modalStatusService: Modalstatusservice,
    private selectedImagesStore: SelectedImagesStore,
    private dialogService: DialogService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private translate: TranslateService,
    private viewProjectService: ViewProjectService,
  ) {
    super();
  }

  ngOnInit() {
    this.viewProjectService.isPiorityPixDataLoadedGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.isPrioritypixBtnShown = res;
      });

    this.viewProjectService.priorityPixImageCountGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.priritypixImageCount = res;
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.noOfImages = res.viewSettingDTO.imagePerPage;
        }
      });
  }

  onClickPriorityPixBtn() {
    this.viewProjectService.priorityPixHandling.next(true);
  }

  callShowAll() {
    this.loaderService.displayLoader(true);

    this.viewProjectService
      .callProjectImageIDs(
        this.viewProjectService.getProjectId(),
        FilterEnum.ShowAll,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res1) => {
          if (res1) {
            const assetsIds = Utils.getImageIdsBasedOnPage(
              1,
              this.noOfImages,
              res1,
            );
            const data = {
              assetIds: assetsIds,
              projectId: this.viewProjectService.getProjectId(),
              filter: FilterEnum.ShowAll,
            };
            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  const filter = 'showall';
                  this.viewProjectService.isPiorityPixDataLoaded.next(false);
                  this.viewProjectService.setCurrentFilter(FilterEnum.ShowAll);
                  this.selectedImagesStore.clear();
                  this.sidebarService.setAlbumImageData(resData);
                  this.loaderService.displayLoader(false);
                  this.viewProjectService.priorityPixHandling.next(false);
                  this.router
                    .navigate([
                      Utils.prepareFilterNavigationURL(
                        this.viewProjectService.getProjectRole(),
                        this.viewProjectService.getProjectId(),
                        filter,
                        1,
                      ),
                    ])
                    .then(() => {
                      this.setTotalAssetCount(filter);
                    });
                },
                (error ) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(this.translate.instant('view-project.alert2'));
          }
        },
        (error ) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  setTotalAssetCount(filter: string): void {
    this.viewProjectService
      .callProjectImageIDs(this.viewProjectService.getProjectId(), filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.loaderService.displayLoader(false);
        this.viewProjectService.setTotalImageCount(res.length);
      },(error ) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
