import { AssetInfoDTOList } from '../../../../../shared/models/assetInfo.model';

export interface ExpressDownloadAssetsDataModel {
  albumId?: number;
  isFromTopMenu?: boolean;
  stepIndex: number;
  token?: string;
  assetsCount?: number;
  assetIds?: number[];
  videoAssets?: AssetInfoDTOList[];
}

export interface AdhocSettingsDTOModel {
  allowDownload: boolean;
  allowExpressDownload: boolean;
  downloadSize: string;
  expressDownloadSize: string;
  linkExpires: number;
  numberOfViews: number;
  passphrase: string;
  userId: number;
}

export interface AssetInfoData {
  batchId: number;
  batchLocator: string;
  batchName: string;
  id: number;
  name: string;
}

export interface AssetsDetailSingleSize {
  batchId: number;
  batchLocator: string;
  batchName: string;
  id: number;
  name: string;
  size: number;
  downloadId: number;
  locator: string;
  type: string;
}

export interface ExpressDownloadResultData {
  albumId: number;
  id: string;
  assetsCount?: number;
  lastIndexStep: number;
  token: string;
}

export enum ExpressDownloadTypes {
  HIGH_RES_JPEG = '3',
  RAW = '4',
  VIDEO = '5',
}
