<div
  class="fs-text-color-green"
  *ngIf="
    isConflictsActive &&
    imgData.taggingView1.length > 0 &&
    imgData.taggingView2.length > 0
  "
>
  <span>Audit: </span>
  <fs-tag-name-title-component [auditTags]="imgData.auditTags">
  </fs-tag-name-title-component>
</div>
