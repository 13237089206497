import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { TelentNoteService } from './telent-note.service';
import { SidebarService } from '../../../../sidebar-service/sidebar-service';
import { AssetInfo } from '../../../../../../shared/models/assetInfo.model';
import { ProjectDetailPermission } from '../../../../../../shared/models/projectpermission.model';
import { Activemodal } from '../../../../../../shared/models/activemodal.model';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../shared/services/loader.service';
import { Modalstatusservice } from '../../../../../../shared/services/modalstatus.service';
import { UserDataService } from '../../../../../../shared/services/userdata.service';
import { Utils } from '../../../../../../shared/utils/utils';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../shared/store/selected-images-store';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { ArrayUtils } from '../../../../../../shared/utils/array.utils';
import {ApiErrorHandlerService} from '../../../../../../shared/services/api-error-handler.service';
import {AssetErrorKeys} from '../../../../../../shared/enum/asset-error-keys.enum';
import {TranslateService} from '@ngx-translate/core';
import {DialogService} from '../../../../../../shared/services/dialog/dialog.service';

@Component({
  selector: 'fs-talent-note',
  templateUrl: './talent-note.component.html',
  styleUrls: ['./talent-note.component.scss'],
})
export class TalentNoteComponent
  extends UnsubscriberComponent
  implements OnInit
{
  @ViewChild('scrollMe', { static: false })
  private myScrollContainer: ElementRef;
  @Output() closeNotePopup = new EventEmitter();

  projectImageData: AssetInfo = new AssetInfo();
  albumList: any;
  response: any;
  imageData: object;
  projectId: number;
  imgInfo: any;
  projectDetail: ProjectDetailPermission = new ProjectDetailPermission();
  noteType: any;
  activemodal: Activemodal = new Activemodal();

  noteData: any;
  submitNoteData: any;
  isImageInfoActive = false;
  imageInfoTab: any = 'data';
  distinctUserIds: Array<any> = [];
  distinctUserData;
  ntType: {
    id: 1;
  };

  hideDataNoteListOption = false;
  closeNotePopupObj: any = {};

  currentPageNumber: any;
  projectImageIds: any;
  noOfImages: number;

  constructor(
    private telentNoteService: TelentNoteService,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private modalStatusService: Modalstatusservice,
    private userDataService: UserDataService,
    private sidebarService: SidebarService,
    public selectedImagesStore: SelectedImagesStore,
  ) {
    super();
  }

  ngOnInit() {
    this.onClickImageInfo();

    this.noteData = {
      imageNoteText: 'Enter Note',
      note: [
        {
          id: 2,
          lableName: 'Public',
          isSelect: false,
          key: 'publicNote',
        },
        {
          id: 1,
          lableName: 'Private',
          isSelect: false,
          key: 'privateNote',
        },
      ],
    };

    this.submitNoteData = {
      imageNoteText: '',
      noteType: 1,
    };

    this.imgInfo = {
      releasedDate: '',
      batch: '',
      name: '',
      imageStatus: '',
      taggedwith: [],
      approvedBy: [],
      publishedBy: [],
      rejectedBy: [],
      albums: [],
      generalAlbums: [],
      customAlbums: [],
      caption: '',
      photographer: '',
    };

    this.viewProjectService.isAddNoteFromAddNoteGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.hideDataNoteListOption = response;
      });

    this.sidebarService.albumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.albumList = Utils.getValuesByKey(albumList.albums, 'id');
        }
      });

    this.viewProjectService.projectImageIdsGLobal.subscribe((imageIds) => {
      this.projectImageIds = imageIds;
    });

    this.sidebarService.updatedAlbumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.albumList = Utils.getValuesByKey(albumList, 'id');
        }
      });

    this.telentNoteService.whichImageTabGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.imageInfoTab = res;
      });

    this.selectedImagesStore
      .getCollection()
      .pipe(takeUntil(this.destroy$))
      .subscribe((images) => {
        if (images.length === 1) {
          this.distinctUserIds = [
            ...new Set(
              this.selectedImagesStore
                .getHeadItem()
                .assetNoteDTOList.map((item) => item.createdBy),
            ),
          ];

          if (this.distinctUserIds.length > 0) {
            this.userDataService
              .getUserData(
                this.viewProjectService.getProjectId(),
                this.distinctUserIds,
              )
              .pipe(takeUntil(this.destroy$))
              .subscribe((value) => {
                this.distinctUserData = value;
                this.selectedImagesStore
                  .getHeadItem()
                  .assetNoteDTOList.forEach((item: any, index) => {
                    this.distinctUserData.forEach((child, key) => {
                      if (child.id === item.createdBy) {
                        item.name = child.firstName + ' ' + child.lastName;
                      }
                    });
                  });
              },(error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              });
          }
        }
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectDetailPermission) => {
        if (!Utils.isObjectEmpty(projectDetailPermission)) {
          this.projectDetail = projectDetailPermission;
          this.noOfImages = projectDetailPermission.viewSettingDTO.imagePerPage;
        }
      });
  }

  onClickImageInfo() {
    this.isImageInfoActive = true;
  }

  saveImageNote(formValus) {
    this.currentPageNumber =
      this.viewProjectService.getCurrentPageNumberClone();
    const data = {
      assetIds: [this.selectedImagesStore.getHeadItem().id],
      type: formValus.noteType,
      value: formValus.imageNoteText,
      albumId: this.viewProjectService.getExecutiveAlbumID() || 0,
      currentAssetIds: Utils.getImageIdsBasedOnPage(
        this.currentPageNumber,
        this.noOfImages,
        this.projectImageIds,
      ),
      projectId: this.viewProjectService.getProjectId(),
    };
    this.createAssetsNotes(data);
  }

  createAssetsNotes(data) {
    this.loaderService.displayLoader(true);
    this.telentNoteService
      .callCreateAssetsNotes(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.projectImageData = this.viewProjectService.mergeWithProjectData(
            this.projectImageData,
          );
          const notes = res;
          const createdBy = [];
          createdBy.push(res[0].assetNoteResponseDTO.createdBy);

          this.userDataService
            .getUserData(this.viewProjectService.getProjectId(), createdBy)
            .pipe(takeUntil(this.destroy$))
            .subscribe((value) => {
              this.projectImageData.assetInfoDTOList.forEach((imageObj) => {
                notes.forEach((updatedNoteObj) => {
                  if (updatedNoteObj.assetId === imageObj.id) {
                    updatedNoteObj.assetNoteResponseDTO['name'] =
                      value[0].firstName + ' ' + value[0].lastName;
                    if (
                      !ArrayUtils.hasDuplicates(
                        imageObj.assetNoteDTOList,
                        (v) => v.id === updatedNoteObj.assetNoteResponseDTO.id,
                      )
                    ) {
                      imageObj.assetNoteDTOList.push(
                        updatedNoteObj.assetNoteResponseDTO,
                      );
                    }
                  }
                });
              });

              this.loaderService.displayLoader(false);
              if (this.hideDataNoteListOption) {
                this.closeNotePopupObj['close'] = false;
                this.closeNotePopupObj['noteSave'] = true;
                this.closeWindow(this.closeNotePopupObj);
              }
              this.submitNoteData = {
                imageNoteText: '',
                noteType: 1,
              };
            },(error) => {
              this.apiErrorHandlerService.getHandler().handle(error);
              this.loaderService.displayLoader(false);
            });
          try {
            this.myScrollContainer.nativeElement.scrollTop =
              this.myScrollContainer.nativeElement.scrollHeight + 30;
          } catch (err) {
            //**//
          }
        },
        (errorData) => {
          this.apiErrorHandlerService.getHandler().handle(errorData);

           this.loaderService.displayLoader(false);
          if (this.hideDataNoteListOption) {
            this.closeNotePopupObj['close'] = false;
            this.closeNotePopupObj['noteSave'] = true;
            this.closeWindow(this.closeNotePopupObj);
          }
          this.submitNoteData = {
            imageNoteText: '',
            noteType: 1,
          };
        },
      );
  }


  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  closeWindow(closeNotePopupObj) {
    this.closeNotePopup.emit(closeNotePopupObj);
  }
}
