<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.imagenotes-report.notes-report.title'
        | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>
<div mat-dialog-content>
  <div class="notes-report-table">
    <div class="fs-scroll-table-body fs-header-sort-color">
      <mat-table
        matSort
        [hidden]="!dataSource.data.length"
        [dataSource]="dataSource"
      >
        <ng-container matColumnDef="name">
          <mat-header-cell
            disableClear
            mat-sort-header="name"
            class="pl-2 px-2"
            *matHeaderCellDef
          >
            {{
              'left-menu.tab-bar.sidemenus.administration.imagenotes-report.table.name'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="pl-2 fs-cursor-pointer pr-2 pt-1 pb-1 fs-sm-txt fs-txt-white-grey-color-hover"
            *matCellDef="let element"
          >
            <span
              class="fs-text-underline"
              (click)="onClickImageName(element.id)"
              >{{ element.name }}</span
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="imageTag">
          <mat-header-cell
            disableClear
            mat-sort-header="imageTag"
            mat-header-cell
            class="pl-2"
            *matHeaderCellDef
          >
            {{
              'left-menu.tab-bar.sidemenus.administration.imagenotes-report.table.tag'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="pl-2 pr-2 pt-1 pb-1 fs-sm-txt fs-txt-white-grey-color-hover"
            *matCellDef="let element"
          >
            <span *ngFor="let tagName of element.imageTag">
              {{ tagName }} <br />
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="assetNoteDTOList">
          <mat-header-cell
            disableClear
            mat-sort-header="assetNoteDTOList"
            mat-header-cell
            *matHeaderCellDef
            class="pl-2"
          >
            {{
              'left-menu.tab-bar.sidemenus.administration.imagenotes-report.table.note'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element; let i = index"
            class="pl-2 pr-2 pt-1 pb-1 fs-sm-txt d-flex align-items-start flex-column"
          >
            <fs-expandable-list
              [dataList]="element.assetNoteDTOList"
              [initialNumberOfItemsToShow]="2"
            >
              <ng-template expandableListTemplate let-note="note">
                {{ getFullName(note.createdBy) }}
                - {{ note.createdAt | date : 'yyyy-MM-dd' }} &#64;
                {{ note.createdAt | date : 'hh:mm:ss' }}
                <span *ngIf="note.type == 2"> [PUBLIC] </span>
                <span *ngIf="note.type == 1"> [PRIVATE] </span> :
                {{ note.value }}
              </ng-template>
            </fs-expandable-list>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <div>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </div>
      </mat-table>
    </div>
    <div *ngIf="!dataSource.data.length">
      <div class="p-4 text-center">
        {{ 'global.no-record' | translate }}
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button
    class="fs-dialog-btn w-auto ml-3"
    (click)="onClickExport(exportType.Excel)"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.export'
        | translate
    }}
    as xls
  </button>

  <button
    class="fs-dialog-btn w-auto ml-3"
    (click)="onClickExport(exportType.Pdf)"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.export'
        | translate
    }}
    as PDF
  </button>
</div>
