<div
  class="tagging-data-cont"
  [class.tagging-data-cont_height]="
    isTaggingView1Active || isTaggingView2Active
  "
>
  <span *ngIf="imgData.hidden" class="fs-text-color-red"> hidden </span>
  <span
    class="fs-text-color-yellow text-left"
    *ngIf="imgData.taggingView1.length && isTaggingView1Active"
  >
    <fs-tag-name-title-component [auditTags]="imgData.taggingView1">
    </fs-tag-name-title-component>
  </span>
  <span
    class="fs-text-color-light-red text-left"
    *ngIf="imgData.taggingView2.length && isTaggingView2Active"
  >
    <fs-tag-name-title-component [auditTags]="imgData.taggingView2">
    </fs-tag-name-title-component>
  </span>
  <div *ngIf="!isTaggingView1Active && !isTaggingView2Active">
    <div
      class="fs-text-color-yellow d-flex justify-content-center"
      *ngIf="
        isConflictsActive || isNoConflictsActive || isByPass2ndTaggerActive
      "
    >
      <span class="mr-1" *ngIf="!imgData.lastTagger1">Tagger 1: </span>
      <span
        class="mr-1"
        *ngIf="imgData.lastTagger1 && imgData.taggingView1.length > 0"
      >
        {{ imgData.lastTagger1 }}:
      </span>
      <fs-tag-name-title-component [auditTags]="imgData.taggingView1">
      </fs-tag-name-title-component>
    </div>
    <div
      class="fs-text-color-light-red d-flex justify-content-center"
      *ngIf="isConflictsActive || isNoConflictsActive"
    >
      <span class="mr-1" *ngIf="!imgData.lastTagger2">Tagger 2: </span>
      <span
        class="mr-1"
        *ngIf="imgData.lastTagger2 && imgData.taggingView2.length > 0"
      >
        {{ imgData.lastTagger2 }} :
      </span>
      <fs-tag-name-title-component [auditTags]="imgData.taggingView2">
      </fs-tag-name-title-component>
    </div>
    <!--Audit-->
    <fs-tagging-audit-component
      [imgData]="imgData"
      [isConflictsActive]="isConflictsActive"
    >
    </fs-tagging-audit-component>
  </div>
  <fs-title-for-review-panel-component
    [imgData]="imgData"
    [isTaggingView2Active]="isTaggingView2Active"
    [isTaggingView1Active]="isTaggingView1Active"
  >
  </fs-title-for-review-panel-component>
</div>
