import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe, DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { ImagenotesService } from '../imagenotes-report.service';
import { AlbumsService } from './../../../albums/albums.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ViewProjectService } from '../../../../../../../../shared/services/view-project.service';
import { Modalstatusservice } from '../../../../../../../../shared/services/modalstatus.service';
import { LoaderService } from '../../../../../../../../shared/services/loader.service';
import { UserService } from '../../../../../../../../shared/services/user.service';
import { ConstantsGlobal } from '../../../../../../../../shared/constants/constants';
import { Utils } from '../../../../../../../../shared/utils/utils';
import { takeUntil } from 'rxjs/operators';
import { ExportType } from '../../../../../../../../shared/models/enum/ExportType';
import { DialogService } from '../../../../../../../../shared/services/dialog/dialog.service';
import { FilterEnum } from '../../../../../../../../shared/enum/filter.enum';
import { UnsubscriberComponent } from '../../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../../shared/services/api-error-handler.service';

class Assets {
  imageName: any;
  tagInfo: any;
  imgNote: any;
}
@Component({
  selector: 'fs-notes-report',
  templateUrl: './notes-report.component.html',
  styleUrls: ['./notes-report.component.scss'],
})
export class NotesReportComponent
  extends UnsubscriberComponent
  implements OnInit
{
  @ViewChild(MatSort) sort: MatSort;

  // array of all items to be paged
  allItems: any;
  sortF: any;
  getDataFromService: any;
  Imagenotereportassets: Assets[] = [];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject();
  arrListImageDetailsAll: any = [];
  projectDetail;
  selectedFilterForReport: string;
  arrListToExport: any = [];
  pipe = new DatePipe('en-US');
  currentFilter = 'show all';
  filterName: any;
  noteType: any;
  noOfImages: any;
  constantsGlobalData: any;
  userDataInfo: any;
  exportType = ExportType;
  displayedColumns: string[] = ['name', 'imageTag', 'assetNoteDTOList'];
  dataSource = new MatTableDataSource([]);

  constructor(
    private imagenotesService: ImagenotesService,
    private translate: TranslateService,
    private viewProjectService: ViewProjectService,
    private modalService: Modalstatusservice,
    private loaderService: LoaderService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private albumsService: AlbumsService,
    private userService: UserService,
    private dialogService: DialogService,
    private elRef: ElementRef,
    private dialogRef: MatDialogRef<NotesReportComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.viewProjectService.currentFilterGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentFilterName) => {
        this.currentFilter = currentFilterName;
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectDetailPermission) => {
        if (!Utils.isObjectEmpty(projectDetailPermission)) {
          this.projectDetail = projectDetailPermission;
          this.noOfImages = projectDetailPermission.viewSettingDTO.imagePerPage;
        }
      });

    this.loaderService.displayLoader(true);
    this.selectedFilterForReport = this.data;
    this.filterName = this.viewProjectService.getCurrentFilter();
    this.noteType = this.data;
    this.dataSource.data = [];

    if (this.noteType === '1') {
      this.filterName = FilterEnum.ShowAll;
    }
    this.loaderService.displayLoader(true);
    this.imagenotesService
      .getProjectNotesReportWithFilter(
        this.viewProjectService.getProjectId(),
        this.filterName || FilterEnum.ShowAll,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.loaderService.displayLoader(false);
        if (res) {
          this.prepareData(res);
        } else {
          this.closeWindow();
          this.alertCustom('No data found'); // 204 Needs to be changes as per i18N
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });

    this.albumsService.userDataInfoGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.userDataInfo = data;
      });
  }

  onClickImageName(id) {
    const filter = 'report:note';
    const imageIDs = [id];

    this.viewProjectService.setProjectImageIDs(imageIDs);

    const assetsIds = Utils.getImageIdsBasedOnPage(
      1,
      this.noOfImages,
      imageIDs,
    );
    const data = {
      assetIds: assetsIds,
      projectId: this.viewProjectService.getProjectId(),
      filter: filter,
    };
    this.loaderService.displayLoader(true);
    this.viewProjectService
      .callAssetsInfoBasedOnAssetsId(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (resData) => {
          if (resData.assetInfoDTOList.length > 0) {
            this.closeWindow();
            this.viewProjectService.setProjectData(resData);
            this.viewProjectService.isProjectDataLoadedFromNoteReports.next(
              true,
            );
          } else {
            this.alertCustom(this.translate.instant('view-project.alert2'));
          }
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  prepareData(response) {
    const arrNoteDataForTable = [];
    this.dataSource.data = [];

    response.forEach((imgdata) => {
      if (imgdata.assetNoteDTOList.length > 0) {
        arrNoteDataForTable.push({
          id: imgdata.id,
          name: imgdata.name,
          imageTag: this.getImageTagList(
            imgdata.generalTags,
            imgdata.publishedTags,
          ),
          assetNoteDTOList: imgdata.assetNoteDTOList,
        });
      }
    });
    this.dataSource.data = arrNoteDataForTable;
    this.dataSource.sort = this.sort;
  }

  getImageTagList(generalTags, publishedTags) {
    const tempArray = generalTags.concat(publishedTags);
    const arrayToSend = [];
    tempArray.forEach((publishedTag) => {
      const index = this.projectDetail.projectTagListDTO
        .map(function (e) {
          return e.id;
        })
        .indexOf(publishedTag);
      if (index !== -1) {
        arrayToSend.push(this.projectDetail.projectTagListDTO[index].tagName);
      }
    });
    return arrayToSend;
  }

  onClickExport(reportType) {
    this.arrListToExport = [];
    this.dataSource.data.forEach((noteList) => {
      this.arrListToExport.push({
        Name: noteList.name,
        Taglist: noteList.imageTag.join(),
        ImageNotes: this.prePeareNoteSection(noteList.assetNoteDTOList),
      });
    });

    if (reportType === this.exportType.Excel) {
      this.userService.exportReport(
        'Note Report',
        this.arrListToExport,
        'NoteReport.xlsx',
      );
    } else {
      const colNames = this.userService.getColumnsName(this.arrListToExport[0]);
      this.userService.exportPDFDataInHorizontalOrientation(
        this.arrListToExport,
        colNames,
      );
    }
  }

  prePeareNoteSection(notesList) {
    let tempString = '';
    notesList.forEach((objItem) => {
      let tempDate: any = '';
      let tempType = '';
      let tempVal = '';
      tempDate =
        this.getFullName(objItem.createdBy) ||
        '' +
          ' ' +
          this.pipe.transform(objItem.createdAt, 'yyyy-MM-dd') +
          '@' +
          this.pipe.transform(objItem.createdAt, 'hh:mm:ss');
      if (objItem.type === 1) {
        tempType = ' [PRIVATE]: ';
      } else {
        tempType = ' [PUBLIC]: ';
      }
      tempVal = objItem.value + ' ';
      tempString += tempDate += tempType += tempVal += ', ';
    });
    return tempString;
  }

  closeWindow() {
    this.dialogRef.close();
  }

  getFullName(id: number) {
    const user = this.userDataInfo.find((user) => Number(user.id) === id);

    if (user) {
      return `${user.firstName} ${user.lastName}`;
    }

    const tag = this.viewProjectService
      .getProjectDetailPermissionData()
      .projectTagListDTO.find((tag) => Number(tag.userId) === id);

    if (tag) {
      return tag.tagName;
    }

    return null;
  }
}
