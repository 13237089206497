import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {LoaderService} from './loader.service';
import {DialogService} from './dialog/dialog.service';
import {Router} from '@angular/router';
import {ErrorCodeConfig} from '../../appSettings/error-codes-config';

export class ApiError {
  constructor(public code: number, public error: {}) {}
}

class ErrorHandler {
  private pool = {};
  private defaultHandler: (error: ApiError) => void;

  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private router: Router
  ) {

    this.setHandler(ErrorCodeConfig.BAD_REQUEST, (error) => {
      this.alertCustom(this.translate.instant('statusError.badRequest'));
    });

    this.setHandler(ErrorCodeConfig.UNAUTHORIZED, (error) => {
      this.alertCustom('statusError.unauthorized');
    });

    this.setHandler(ErrorCodeConfig.PAYMENT_REQUIRED, (error) => {
      this.alertCustom(this.translate.instant('statusError.paymentRequired'));
    });

    this.setHandler(ErrorCodeConfig.FORBIDDEN, (error) => {
      this.alertCustom(this.translate.instant('statusError.forbidden'));
    });

    this.setHandler(ErrorCodeConfig.NOT_FOUND, (error) => {
      this.alertCustom(this.translate.instant('statusError.notFound'));
    });

    this.setHandler(ErrorCodeConfig.METHOD_NOT_ALLOWED, (error) => {
      this.alertCustom(this.translate.instant('statusError.methodNotAllowed'));
    });

    this.setHandler(ErrorCodeConfig.CONFLICT, (error) => {
      this.alertCustom(this.translate.instant('statusError.tooManyRequests'));
    });

    this.setHandler(ErrorCodeConfig.INTERNAL_SERVER_ERROR, (error) => {
      this.alertCustom(this.translate.instant('statusError.internalServerError'));
    });

    this.setHandler(ErrorCodeConfig.NOT_IMPLEMENTED, (error) => {
      this.alertCustom(this.translate.instant('statusError.notImplemented'));
      this.router.navigate(['/dashboard']);
    });

    this.setHandler(ErrorCodeConfig.SERVICE_UNAVAILABLE, (error) => {
      this.alertCustom(this.translate.instant('statusError.serviceUnavailable'));
    });

    this.setHandler(ErrorCodeConfig.GATEWAY_TIMEOUT, (error) => {
      this.alertCustom(this.translate.instant('statusError.gatewayTimeout'));
    });

    // Network Error
    this.setHandler(ErrorCodeConfig.NETWORK_ERROR, (error) => {
      this.alertCustom(this.translate.instant('error.networkError'));
    });

    this.setDefaultHandler(() => {
      this.alertCustom(this.translate.instant('statusError.defaultError'));
    });
  }

  setHandler(code: number, handler: (error: ApiError) => void): this {
    this.pool[code] = handler;
    return this;
  }

  setDefaultHandler(handler: (error: ApiError) => void): this {
    this.defaultHandler = handler;
    return this;
  }

  getHandlerPool() {
    return this.pool;
  }

  handle(error: { status?: number }): void {
    const apiError = new ApiError(error.status, error);
    const handler = this.pool[error.status];
    handler
      ? handler(apiError)
      : this.defaultHandler(apiError);
  }

  alertCustom(displayText: string) {
    this.dialogService
      .openInformationDialog({
        title: this.translate.instant('view-project.confirm22'),
        message: displayText,
      })
  }
}

@Injectable({
  providedIn: 'root',
})
export class ApiErrorHandlerService {
  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private router: Router,
  ) {}

  getHandler() {
    return new ErrorHandler(
      this.httpClient,
      this.translate,
      this.loaderService,
      this.dialogService,
      this.router
    );
  }
}
