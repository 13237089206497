import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettings } from '../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../shared/services/api.service';

@Injectable()
export class AdhocService {
  constructor(private apiService: ApiService) {}

  getAdhocAlbums(projectid): Observable<any> {
    const path = AppSettings.GET_ADHOC_ALBUMS(projectid);
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getAlbumImageData(obj): Observable<any> {
    const path = AppSettings.GET_ASSETS_ALBUMID(obj.albumId, obj.projectId);
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }
}
