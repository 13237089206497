import { MonoTypeOperatorFunction, Observable, pipe, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

export class Listener<T> {
  private _listener: Subject<T> = new Subject<T>();
  private isWithInitialValue = false;
  private currentState: T = null;
  private initialState: T = null;

  withInitialValue(data: T = null) {
    this.isWithInitialValue = true;
    this.currentState = data;
    this.initialState = data;
  }

  getValue(): T {
    return this.currentState;
  }

  listen(): Observable<T> {
    const operators: MonoTypeOperatorFunction<T>[] = [];
    if (this.isWithInitialValue) {
      operators.push(startWith(this.initialState));
    }

    return pipe.apply(null, operators)(this._listener.asObservable());
  }

  stopListen() {
    this._listener.complete();
  }

  emit(data: T = null) {
    this.currentState = data;
    this._listener.next(data);
  }
}
