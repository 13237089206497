import { Component, Inject, OnInit } from '@angular/core';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExportType } from '../../../../../../../shared/models/enum/ExportType';
import {
  DuplicateAssetListModel,
  DuplicatedPerProjectModel,
} from '../../../../../../../shared/models/duplicate-asset-list.model';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { UserService } from '../../../../../../../shared/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { DuplicateMenuService } from '../../../../../../../shared/services/duplicate-menu.service';

@Component({
  selector: 'fs-duplicate-list-menu',
  templateUrl: './duplicate-list-menu.component.html',
  styleUrl: './duplicate-list-menu.component.scss'
})
export class DuplicateListMenuComponent extends UnsubscriberComponent implements OnInit {
  exportType = ExportType;
  duplicatedPerProject: DuplicatedPerProjectModel[];

  constructor(
    private dialogRef: MatDialogRef<DuplicateListMenuComponent>,
    public viewProjectService: ViewProjectService,
    private userService: UserService,
    private duplicateMenuService: DuplicateMenuService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: DuplicateAssetListModel
  ) {
    super();
    this.duplicatedPerProject = data.duplicatedPerProjectAssets;
  }

  ngOnInit(): void {
  }

  onClickExport(reportType) {
    const arrListToExport = [];

    const warningLabel = this.translate.instant('left-menu.tab-bar.sidemenus.administration.duplicate-menu.labels.warning');

    this.duplicatedPerProject.forEach((projectItem) => {
      const projectData = JSON.stringify(projectItem);
      arrListToExport.push({
        [warningLabel]: projectData,
      });
    });

    if (reportType === this.exportType.Excel) {
      this.userService.exportReport(
        'Duplicated Asset List',
        arrListToExport,
        'DuplicatedAssetList.xlsx',
      );
    } else {
      const documentTitle = `${this.viewProjectService.getProjectDetailPermissionData().projectName} DuplicatedAssetList.pdf`;
      this.duplicateMenuService.exportPDFData(arrListToExport, documentTitle);
    }
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
