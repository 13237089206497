import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * TODO IMPROVE PERFORMANCE by adding caching system here in future.
 * Idea is to create a hashmap object, which will have imgData.id as a key, and colors as a value.
 * Then each time when we call a function getRoundColorArray we check if object already exist, if yes, then return colors
 * if no, then do normal logic
 */

@Injectable({
  providedIn: 'root',
})
export class AlbumColorTagsService {
  private _isColorPanelEnabled = new BehaviorSubject<boolean>(false);
  public isColorPanelEnabled$ = this._isColorPanelEnabled.asObservable();

  public setIsColorPanelEnabled(value: boolean) {
    this._isColorPanelEnabled.next(value);
  }

  public updateImageDataWithColorCodes(images: any[], albumList: any) {
    if (!this._isColorPanelEnabled.getValue()) {
      return;
    }
    if (albumList) {
      images.forEach((imageData) => {
        // find albums for particular image
        const filteredAlbumList = albumList.filter(
          (currentAlbum) =>
            this.isCustomAlbum(imageData.customAlbums, currentAlbum.id) &&
            this.hasAlbumTagColor(currentAlbum.tagColor),
        );
        // mutate image colorCodes with color codes for all albums
        imageData.colorCodes = filteredAlbumList.map(
          (currentAlbum) => currentAlbum.tagColorCode,
        );
      });
    }
  }

  isCustomAlbum(customAlbums, currentAlbumId) {
    return customAlbums.indexOf(currentAlbumId) !== -1;
  }

  hasAlbumTagColor(tagColor) {
    return tagColor !== -1;
  }
}
