<div class="d-flex flex-column">
  <div class="img-tile text-center">
    <span
      (mouseover)="showFullName()"
      (mouseout)="hideFullName()"
      class="fs-txt-white-grey-color-hover"
    >
      {{ truncatedAssetName }}
    </span>
  </div>
</div>
