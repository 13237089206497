<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  class="fs-cursor-move"
  cdkDragHandle
  mat-dialog-title
>
  <div class="modal-title">{{ title }}</div>
</div>

<div mat-dialog-content>
  <span [class]="iconStyle">
    {{ message }}
  </span>
</div>

<div mat-dialog-actions class="d-flex flex-row justify-content-end">
  <button
    *ngIf="showRejectBtn"
    class="fs-dialog-btn w-auto ml-3"
    type="button"
    (click)="reject()"
  >
    {{ rejectBtn | translate }}
  </button>

  <button
    *ngIf="showAcceptBtn"
    class="fs-dialog-btn w-auto ml-3"
    type="button"
    (click)="accept()"
  >
    {{ acceptBtn | translate }}
  </button>
</div>
