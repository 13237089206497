import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { map } from 'rxjs/internal/operators/map';
import { SnackBarModel } from './snack-bar.model';

@Component({
  selector: 'fs-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent {
  duration: number;
  message: string;
  snackBarRef: MatSnackBar;
  progressbarValue = 100;
  curSec = 0;

  startTimer$ = interval(this.progressbarValue).pipe(
    takeWhile(() => this.curSec !== this.duration),
    map((sec) => {
      this.progressbarValue = 100 - (sec * 100) / this.duration;
      this.curSec = sec;
      return this.progressbarValue;
    }),
  );

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarModel) {
    this.duration = data.duration / this.progressbarValue;
    this.message = data.message;
    this.snackBarRef = data.snackBarRef;
  }
}
