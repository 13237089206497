import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ConfigKey } from '../../../../../appSettings/key-config';
import { SliderService } from './slider.service';
import { TopmenusService } from '../../../dashboard-shared/top-menu/top-menu.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { PaginationService } from '../../../dashboard-shared/pagination/pagination.service';
import { AuthServerProvider } from '../../../../../shared/services/auth.jwt.service';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../../../../../shared/utils/utils';
import { FilterEnum } from '../../../../../shared/enum/filter.enum';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'fs-enlarge-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  animations: [
    trigger('move', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => left', [
        style({ transform: 'translateX(100%)' }),
        animate(200),
      ]),
      transition('left => void', [
        animate(200, style({ transform: 'translateX(0)' })),
      ]),
      transition('void => right', [
        style({ transform: 'translateX(-100%)' }),
        animate(200),
      ]),
      transition('right => void', [
        animate(200, style({ transform: 'translateX(0)' })),
      ]),
    ]),
  ],
})
export class SliderComponent extends UnsubscriberComponent implements OnInit {
  currentImageIndex: number;
  pageNumber: any;
  @Input() currentImageNum = 2;
  public imageUrls: any;
  public state = 'void';
  public stateNew = 'right';
  public disableSliderButtons = false;
  baseUrl = '/';
  currentPageNumber = 1;
  projectId: any;
  viewSetting: any;
  isEnlarge = '';
  route: any;
  totalRecordOfData: any;
  firstImageId: number;
  lastImageId: number;
  numOfImage: any = 10;
  imageId: any;
  imageIdNumber: any;
  token: any;
  secureStorage = true;
  selectedImageIndex;
  currentFilter = FilterEnum.ShowAll;
  releaseKey = true;
  lastImagIndex: any = 0;
  constructor(
    public viewProjectService: ViewProjectService,
    private topmenusService: TopmenusService,
    private router: Router,
    private paginationService: PaginationService,
    private activatedRoute: ActivatedRoute,
    private authServerProvider: AuthServerProvider,
    private sliderService: SliderService,
  ) {
    super();
    this.token = this.authServerProvider.getToken();
  }

  ngOnInit() {
    this.viewProjectService.projectIDGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((id) => {
        this.projectId = id;
      });

    this.viewProjectService.projectDataGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.totalRecordOfData = res.assetInfoDTOList;
          this.imageUrls = res.assetInfoDTOList.filter((image, index) => {
            return index <= this.numOfImage - 1;
          });
          this.lastImagIndex =
            res.assetInfoDTOList[res.assetInfoDTOList.length - 1];
          this.baseUrl = res.baseUrl;
        }
      });

    this.paginationService.paginationIndexGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((index) => {
        if (
          this.isEnlarge === 'Enlarge' &&
          index > 0 &&
          this.totalRecordOfData.length > 0
        ) {
          if (this.totalRecordOfData[index - 1]) {
            this.imageIdNumber = this.totalRecordOfData[index - 1].id;
          }
        }
      });

    this.topmenusService.topMenuStatus
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isEnlarge = status;
        if (status === 'Enlarge') {
          this.imageIdNumber = Number(
            this.router.routerState.snapshot.url.split('/')[
              this.router.routerState.snapshot.url.split('/').length - 1
            ],
          );
        }

        this.activatedRoute.params
          .pipe(takeUntil(this.destroy$))
          .subscribe((parameters) => {
            if (Object.keys(parameters).length !== 0) {
              this.currentFilter = parameters.filterId;
              this.projectId = parameters.projectId.slice(
                1,
                parameters.projectId.length,
              );
              this.imageId = parameters.imageId;
              this.imageIdNumber = Number(parameters.imageId);
              this.pageNumber = Number(parameters.pageNumber);
              this.changeImageAsPerId();
            }
          });
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.viewSetting = res;
        if (!Utils.isObjectEmpty(res)) {
          if (res.permissionDTO) {
            this.secureStorage = res.permissionDTO.secureAccess;
          }
        }
      });

    this.sliderService.keyboardArrowStateGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => (this.stateNew = state));
  }

  changeImageAsPerId() {
    this.currentImageIndex = this.totalRecordOfData.indexOf(
      this.totalRecordOfData.filter(
        (item) => item.id === this.imageIdNumber,
      )[0],
    );
    if (
      !this.imageUrls.filter((item) => item.id === this.imageIdNumber).length
    ) {
      if (this.stateNew === 'right') {
        this.getNextRecord();
      } else if (this.stateNew === 'left') {
        this.getPreviousRecord();
      }
    }
  }

  getNextRecord() {
    let getSelectedImageData = [];
    if (this.currentImageIndex === 0 || !this.currentImageIndex) {
      this.currentImageIndex = 0;
      getSelectedImageData = this.totalRecordOfData.slice(
        this.currentImageIndex,
        this.currentImageIndex + this.numOfImage - 1,
      );
    } else {
      if (
        this.totalRecordOfData.slice(
          this.currentImageIndex,
          this.currentImageIndex + this.numOfImage,
        ).length < this.numOfImage
      ) {
        const calculatedLengthOfFetchedData = this.totalRecordOfData.slice(
          this.currentImageIndex,
          this.currentImageIndex + this.numOfImage,
        ).length;
        const remainingBlockImage =
          this.numOfImage - calculatedLengthOfFetchedData;
        getSelectedImageData = this.totalRecordOfData.slice(
          this.currentImageIndex - remainingBlockImage,
          this.currentImageIndex + this.numOfImage,
        );
      } else {
        getSelectedImageData = this.totalRecordOfData.slice(
          this.currentImageIndex,
          this.currentImageIndex + this.numOfImage,
        );
      }
    }
    this.imageUrls = getSelectedImageData;
  }

  getPreviousRecord() {
    let getSelectedImageData = [];
    if (this.currentImageIndex === 0) {
      getSelectedImageData = this.totalRecordOfData.slice(0, this.numOfImage);
    } else {
      const startImageIndex = this.currentImageIndex - this.numOfImage;
      if (Math.sign(startImageIndex) === 1) {
        getSelectedImageData = this.totalRecordOfData.slice(
          startImageIndex + 1,
          this.currentImageIndex + 1,
        );
      } else {
        getSelectedImageData = this.totalRecordOfData.slice(
          0,
          this.currentImageIndex,
        );
      }
    }
    this.imageUrls = getSelectedImageData;
  }

  onClickImage(id) {
    if (this.imageUrls.length) {
      const lastImageIndex = this.totalRecordOfData.findIndex(
        (image) => image.id === Number(this.lastImageId),
      );
      const currentImageIndex = this.imageUrls.findIndex(
        (image) => image.id === Number(id),
      );

      if (currentImageIndex > lastImageIndex && lastImageIndex >= 0) {
        this.currentImageIndex = lastImageIndex;
        this.imageUrls = this.getNextRecord();
      }
      this.test(id);
    }
  }

  test(id) {
    this.imageIdNumber = id;
    this.firstImageId = this.imageUrls[0].id;
    this.lastImageId = this.imageUrls[this.imageUrls.length - 1].id;

    const index = this.totalRecordOfData
      .map(function (e) {
        return e.id;
      })
      .indexOf(id);

    this.imageUrls.forEach((image, index) => {
      if (Number(id) === image.id) {
        this.selectedImageIndex = index;
      }
    });
    if (index !== -1) {
      this.currentPageNumber = Math.ceil(
        index / this.viewSetting.viewSettingDTO.imagePerPage,
      );
      this.router.navigate([
        '/dashboard/viewproject/' +
          this.viewProjectService.getProjectRole() +
          this.viewProjectService.getProjectId() +
          '/filter/' +
          this.currentFilter +
          '/page/' +
          this.currentPageNumber +
          '/enlarge/' +
          id,
      ]);
    }
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyEvents(event: KeyboardEvent) {
    if (event.keyCode === ConfigKey.ArrowRight) {
      this.stateNew = 'right';
    } else if (event.keyCode === ConfigKey.ArrowLeft) {
      this.stateNew = 'left';
    }
  }
}
