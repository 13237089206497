import { Utils } from '../../../../../shared/utils/utils';
import { ProjectRoleId } from '../../../../../shared/enum/project-role.enum';
import { Router } from '@angular/router';
import { LauncherService } from '../../../../launcher/launcher.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { UserDataService } from '../../../../../shared/services/userdata.service';
import { AlbumType } from '../../../../../shared/enum/album-type.enum';
import {
  GroupDataModel,
  GroupItemDataModel,
} from '../interfaces/dashboard-department-rule.model';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { ALBUM } from '../../../../../shared/constants/constants';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardDepartmentAlbumRule implements DashboardRuleModel {
  private readonly DEFAULT_PAGE_NUMBER = 1;

  private router = GetStaticInjector().get(Router);
  private launcherService = GetStaticInjector().get(LauncherService);
  private viewProjectService = GetStaticInjector().get(ViewProjectService);
  private userDataService = GetStaticInjector().get(UserDataService);

  constructor(
    private group: GroupDataModel,
    private galBotInfo: GroupItemDataModel,
  ) {}

  matches(): boolean {
    return this.group.groupType === ALBUM;
  }

  apply(): void {
    const isSignOffOrWideEdit =
      Number(this.launcherService.selectedProject$) === AlbumType.SignOff ||
      Number(this.launcherService.selectedProject$) === AlbumType.WideEdit;

    this.userDataService.resetUserData();
    this.viewProjectService.setProjectID(this.galBotInfo.groupId);
    this.launcherService.setSelectedProjectName(this.galBotInfo.label);
    this.viewProjectService.setExecutiveAlbumID(this.galBotInfo.value);
    this.launcherService.setSelectedProject(
      this.launcherService.selectedProject$,
    );
    this.router.navigate([
      Utils.prepareFilterNavigationURL(
        isSignOffOrWideEdit ? ProjectRoleId.ROLE_EXEC : this.galBotInfo.role,
        this.galBotInfo.groupId,
        'album:' + this.galBotInfo.value,
        this.DEFAULT_PAGE_NUMBER,
      ),
    ]);
  }
}
