import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettings } from '../../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../../shared/services/api.service';

@Injectable()
export class ImagenotesService {
  private noteTypeSubject = new BehaviorSubject<any>([]);

  constructor(private apiService: ApiService) {}

  setNoteType(data) {
    this.noteTypeSubject.next(data);
  }

  getNoteType() {
    return this.noteTypeSubject.value;
  }

  callProjectData(projectId: any): Observable<{}> {
    const path = AppSettings.ASSETS_INFO(projectId);
    return this.apiService.get(path).pipe(
      map((data) => {
        return data;
      }),
    );
  }

  getProjectNotesReportWithFilter(projectId, filter): Observable<any> {
    const path = AppSettings.NOTE_REPORTS_FILTER(projectId, filter);
    return this.apiService.get(path).pipe(map((data) => data));
  }
}
