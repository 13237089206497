<div
  class="ml-1"
  *ngIf="
    retouchAccess &&
    !isTaggerRole &&
    imgData.assetType == assetType.IMAGE &&
    projectDetailPermission.permissionDTO.retouchAccess
  "
>
  <div
    class="fs-cursor-pointer"
    title="{{ 'view-project.retouch' | translate }}"
    (click)="drawImage.emit(imgData)"
  >
    <span class="fs-char-icon">
      <img
        [class.highlighted-icon]="imgData.retouch"
        src="/assets/images/icon-pencil_previous.png"
      />
    </span>
  </div>
</div>
