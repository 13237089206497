import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Modalstatusservice } from '../../services/modalstatus.service';
import { ConstantsGlobal } from '../../constants/constants';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'fs-help-window',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpWindowComponent implements OnInit {
  constantsGlobalData: any;
  videoName: string;
  defaultLanguage: any = 'en-us';
  videoAssetBaseUrl: string = environment.projectAssetBaseUrl + 'assets/videos/';

  constructor(
    private modalService: Modalstatusservice,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<HelpWindowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { topicName: any; videoName: any },
  ) {
    this.videoName = data.videoName;
    this.defaultLanguage = this.translate.getDefaultLang();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
