import { Component, Input } from '@angular/core';

@Component({
  selector: 'fs-char-icon-with-border',
  templateUrl: './char-icon-with-border.component.html',
  styleUrls: ['./char-icon-with-border.component.scss'],
})
export class CharIconWithBorderComponent {
  @Input() imgData;
}
