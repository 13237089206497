import { UrlEncryptService } from './url-encrypt.service';
import {
  Directive,
  EventEmitter,
  Inject,
  Injectable,
  Output,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { AppSettings } from '../../appSettings/appSettings';
import { ApiService } from './api.service';
import { UserDataService } from './userdata.service';
import { ApiImageService } from '../../api-image.service';
import { LauncherService } from '../../dashboard/launcher/launcher.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../services/loader.service';
import { AuthServerProvider } from '../services/auth.jwt.service';
import { ProjectDetailPermission } from '../models/projectpermission.model';
import { AssetInfo, AssetInfoDTOList } from '../models/assetInfo.model';
import { AlbumsInfo } from '../models/albumsInfo.model';
import { Utils } from '../utils/utils';
import { LastSelectedImageStore } from '../store/last-selected-image-store';
import { SelectedImagesStore } from '../store/selected-images-store';
import { DOCUMENT } from '@angular/common';
import { DialogService } from './dialog/dialog.service';
import { FilterEnum } from '../enum/filter.enum';
import { ImageSize } from '../models/enum/download-size';
import { HttpClient } from '@angular/common/http';
import { AssetsInfoView } from '../enum/assets-info-view.enum';
import { ProjectRole, ProjectRoleId } from '../enum/project-role.enum';

@Directive()
@Injectable()
export class ViewProjectService {
  private destroy$ = new Subject(); // todo think what to do with this in service
  currentUserId: number;
  currentUserName = '';
  // Project Detail Permission
  private projectDetailPermission =
    new BehaviorSubject<ProjectDetailPermission>(new ProjectDetailPermission());
  projectDetailPermissionGLobal = this.projectDetailPermission.asObservable();
  private selectedBatchId = new BehaviorSubject<number>(undefined);
  selectedBatchId$ = this.selectedBatchId.asObservable();

  mainProjectData: any;

  private projectData = new BehaviorSubject<AssetInfo>(new AssetInfo());
  projectDataGLobal = this.projectData.asObservable();

  private projectImageIds = new BehaviorSubject<any>([]);
  projectImageIdsGLobal = this.projectImageIds.asObservable();

  projectImageData: AssetInfo = new AssetInfo();

  private albumsInfo = new BehaviorSubject<any>({});
  albumsInfoGLobal = this.albumsInfo.asObservable();

  private savedAlbums = new Subject<any>();
  savedAlbums$ = this.savedAlbums.asObservable();

  private currentProjectAuthority = new BehaviorSubject<string>('');
  public currentProjectAuthority$ = this.currentProjectAuthority.asObservable();

  private wideEditAlbumsInfo = new BehaviorSubject<any>({});
  wideEditAlbumsInfoGlobal = this.wideEditAlbumsInfo.asObservable();

  private sensitiveID = new BehaviorSubject<number>(0);
  sensitiveIDGlobal = this.sensitiveID.asObservable();

  private isLeftMenuOpen = new BehaviorSubject<boolean>(true);
  isLeftMenuOpenGlobal = this.isLeftMenuOpen.asObservable();

  private isExecutiveAlbum = new BehaviorSubject<boolean>(false);
  isExecutiveAlbumGlobal = this.isExecutiveAlbum.asObservable();

  private isExecutiveAlbumView = new BehaviorSubject<boolean>(false);

  private totalImageCount = new BehaviorSubject<number>(0);
  totalImageCountGlobal = this.totalImageCount.asObservable();

  isPiorityPixDataLoaded = new BehaviorSubject<boolean>(false);
  isPiorityPixDataLoadedGlobal = this.isPiorityPixDataLoaded.asObservable();

  talentFirstTimePriorityCount = new BehaviorSubject<boolean>(false);
  talentFirstTimePriorityCountGlobal =
    this.talentFirstTimePriorityCount.asObservable();

  isModelPopupWindowOpen = new BehaviorSubject<boolean>(false);
  isModelPopupWindowOpenGlobal = this.isModelPopupWindowOpen.asObservable();

  isProjectDataLoadedFromNoteReports = new BehaviorSubject<boolean>(false);
  isProjectDataLoadedFromNoteReportsGlobal =
    this.isProjectDataLoadedFromNoteReports.asObservable();

  isRedirectedFromEnlarge = new BehaviorSubject<boolean>(false);
  isRedirectedFromEnlargeGlobal = this.isRedirectedFromEnlarge.asObservable();

  priorityPixImageCount = new BehaviorSubject<number>(0);
  priorityPixImageCountGlobal = this.priorityPixImageCount.asObservable();

  priorityPixHandling = new BehaviorSubject<boolean>(false);
  priorityPixHandling$ = this.priorityPixHandling.asObservable();

  private priorityPixAssetIds = new BehaviorSubject<number[]>([]);
  priorityPixAssetIds$ = this.priorityPixAssetIds.asObservable();

  isAddNoteFromAddNote = new BehaviorSubject<boolean>(false);
  isAddNoteFromAddNoteGlobal = this.isAddNoteFromAddNote.asObservable();

  isAddNoteFromTopMenu = new BehaviorSubject<boolean>(false);
  isAddNoteFromTopMenuGlobal = this.isAddNoteFromTopMenu.asObservable();

  selectedProjectData = new BehaviorSubject<any>({});
  selectedProjectDataGlobal = this.selectedProjectData.asObservable();

  currentFilter = new BehaviorSubject<string>('show all');
  currentFilterGlobal = this.currentFilter.asObservable();

  isTaggingWithkillRight = new BehaviorSubject<boolean>(false);
  isTaggingWithkillRightGlobal = this.isTaggingWithkillRight.asObservable();

  activatedLeftTabIndex = new BehaviorSubject<number>(-1);
  activatedLeftTabIndexGlobal = this.activatedLeftTabIndex.asObservable();

  private activatedLeftProjectAlbum = new BehaviorSubject<boolean>(false);
  activatedLeftProjectAlbumGlobal =
    this.activatedLeftProjectAlbum.asObservable();

  private isViewsettingChanged = new BehaviorSubject<boolean>(false);
  isViewsettingChangedGlobal = this.isViewsettingChanged.asObservable();

  private _nowTime = new Date(); // workAround, moved out from getWebImageURL() to avoid ExpressionChangedAfterItHasBeenCheckedError in console

  @Output() projectIDGlobal: EventEmitter<any> = new EventEmitter();
  @Output() viewProjectNoteIconClick: EventEmitter<boolean> =
    new EventEmitter();
  @Output() executiveAlbumIDGlobal: EventEmitter<any> = new EventEmitter();

  @Output() tagginView1Data: EventEmitter<any> = new EventEmitter();
  @Output() tagginView2Data: EventEmitter<any> = new EventEmitter();

  @Output() tagginView1DataEnlarge: EventEmitter<boolean> = new EventEmitter(
    false,
  );
  @Output() tagginView2DataEnlarge: EventEmitter<boolean> = new EventEmitter(
    false,
  );
  @Output() ConflictsEnlarge: EventEmitter<boolean> = new EventEmitter();
  @Output() conflictsTagginData: EventEmitter<any> = new EventEmitter();

  // Restart data
  @Output() restartDataDTO: EventEmitter<any> = new EventEmitter();
  @Output() deleteAssetGlobal: EventEmitter<any> = new EventEmitter(false);

  @Output() restartDataAlbum: EventEmitter<any> = new EventEmitter();
  @Output() restartDataTaggingWith: EventEmitter<any> = new EventEmitter();
  @Output() restartDataGeneral: EventEmitter<any> = new EventEmitter();
  @Output() restartDataTagging: EventEmitter<any> = new EventEmitter();
  @Output() restartDataAll: EventEmitter<any> = new EventEmitter();
  @Output() restartDataCustomFilter: EventEmitter<any> = new EventEmitter();
  @Output() restartDataAdvanceSearch: EventEmitter<any> = new EventEmitter();

  // Talent Custom Filte
  @Output() restartPendingApproved: EventEmitter<any> = new EventEmitter();
  @Output() restartPendingRejected: EventEmitter<any> = new EventEmitter();

  //
  @Output() restartTaggingView1: EventEmitter<any> = new EventEmitter();
  @Output() restartTaggingView2: EventEmitter<any> = new EventEmitter();

  // Global Taggingview1/2 open

  isSessionAcceptedFromGallery = new BehaviorSubject<boolean>(false);
  isSessionAcceptedFromGalleryGlobal =
    this.isSessionAcceptedFromGallery.asObservable();

  isTaggingView1Open = new BehaviorSubject<boolean>(false);
  isTaggingView1OpenGlobal = this.isTaggingView1Open.asObservable();

  isTaggingView2Open = new BehaviorSubject<boolean>(false);
  isTaggingView2OpenGlobal = this.isTaggingView2Open.asObservable();

  userSecureAccess = new BehaviorSubject<boolean>(false);

  isAuditTaggingView = new BehaviorSubject<boolean>(false);
  isAuditTaggingViewGlobal = this.isAuditTaggingView.asObservable();

  isDrawSessionOpenFromCompareMenu = new BehaviorSubject<boolean>(false);
  isDrawSessionOpenFromCompareMenuGlobal =
    this.isAuditTaggingView.asObservable();

  public imgNames = new BehaviorSubject<any[]>([]);
  imageName = this.imgNames.asObservable();
  private testprojectid: any;
  private projectRole: any;
  private executiveAlbumId: any;
  private currentPageNumber: number;
  private totalPages: number;
  sendGlobalConstants: any;
  currentPageNumberClone: any;

  constructor(
    private apiService: ApiService,
    private apiImageService: ApiImageService,
    private launcherService: LauncherService,
    private http: HttpClient,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private authServerProvider: AuthServerProvider,
    private userDataService: UserDataService,
    private urlEncryptService: UrlEncryptService,
    private selectedImagesStore: SelectedImagesStore,
    private dialogService: DialogService,
    private lastSelectedImageStore: LastSelectedImageStore,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  getBaseUrl() {
    return this.apiImageService.baseUrlGlobal$;
  }

  setCurrentUserId(id) {
    this.currentUserId = id;
  }

  setCurrentUserName(name) {
    this.currentUserName = name;
  }

  setIsSessionAcceptedFromGallery(name) {
    this.isSessionAcceptedFromGallery.next(name);
  }

  getIsSessionAcceptedFromGallery() {
    return this.isSessionAcceptedFromGallery.getValue();
  }

  setUserSecureAccess(secureAccess: boolean): void {
    this.userSecureAccess.next(secureAccess);
  }

  getUserSecureAccess() {
    return this.userSecureAccess.getValue();
  }

  getCurrentUsername() {
    return this.currentUserName;
  }

  getIsDrawSessionOpenFromCompareMenu() {
    return this.isDrawSessionOpenFromCompareMenu.getValue();
  }

  setIsTaggingView1Open(status) {
    if (status) {
      this.isTaggingView2Open.next(false);
      this.isAuditTaggingView.next(false);
    }
    this.isTaggingView1Open.next(status);
  }

  setIsTaggingView2Open(status) {
    if (status) {
      this.isTaggingView1Open.next(false);
      this.isAuditTaggingView.next(false);
    }
    this.isTaggingView2Open.next(status);
  }

  setIsAuditViewOpen(status) {
    if (status) {
      this.isTaggingView2Open.next(false);
      this.isTaggingView1Open.next(false);
    }
    this.isAuditTaggingView.next(status);
  }

  setSensitiveID(id: number) {
    this.sensitiveID.next(id);
  }

  setsDrawSessionOpenFromCompareMenu(flag: boolean) {
    this.isDrawSessionOpenFromCompareMenu.next(flag);
  }

  reSetSensitiveID() {
    this.sensitiveID.next(0);
  }

  // set Activate tab index

  setActivatedLeftTabIndex(index: number) {
    this.activatedLeftTabIndex.next(index);
  }

  setActivatedLeftProjectAlbum(status: boolean) {
    this.activatedLeftProjectAlbum.next(status);
  }

  setViewProjectNoteIconClick(status) {
    this.viewProjectNoteIconClick.emit(status);
  }

  // set project IS
  setProjectID(id) {
    this.testprojectid = id;
    this.projectIDGlobal.emit(id);
  }

  setProjectRole(id) {
    this.projectRole = id;
  }

  getProjectRole() {
    return this.projectRole ? this.projectRole : '1';
  }

  // Selected Project Global

  setSelectedProjectData(data) {
    this.selectedProjectData.next(data);
  }

  getSelectedProjectData() {
    return this.selectedProjectData.value;
  }

  resetSelectedProjectData() {
    this.selectedProjectData.next({});
  }

  // set project IS
  setTagginView1Data(data) {
    this.tagginView1Data.emit(data);
  }

  setConflictsTagginData(data) {
    this.conflictsTagginData.emit(data);
  }

  setTagginView2Data(data) {
    this.tagginView2Data.emit(data);
  }

  setTagginView1DataEnlarge(status) {
    this.tagginView1DataEnlarge.emit(status);
  }

  setTagginView2DataEnlarge(status) {
    this.tagginView2DataEnlarge.emit(status);
  }

  setDeleteAssetGlobal(status) {
    this.deleteAssetGlobal.emit(status);
  }

  setConflictsEnlarge(status) {
    this.ConflictsEnlarge.emit(status);
  }

  setExecutiveAlbumID(id) {
    this.executiveAlbumIDGlobal.emit(id);
    this.executiveAlbumId = id;
  }

  getProjectId() {
    return this.testprojectid;
  }

  getExecutiveAlbumID() {
    return this.executiveAlbumId;
  }

  getProjectID() {
    return this.projectIDGlobal;
  }

  resetProjectID() {
    this.projectIDGlobal.emit('');
    this.testprojectid = '';
    this.resetProjectRole();
  }

  resetProjectRole() {
    this.projectRole = '';
  }

  resetExecutiveAlbumID() {
    this.executiveAlbumIDGlobal.emit('');
    this.executiveAlbumId = '';
  }

  // Project Detail Permission
  setProjectDetailPermissionData(data) {
    this.projectDetailPermission.next(data);
  }

  getProjectDetailPermissionData() {
    return this.projectDetailPermission.value;
  }

  mergeWithProjectDetailPermissionData(data: ProjectDetailPermission) {
    return Object.assign(data, this.getProjectDetailPermissionData());
  }

  resetProjectDetailPermissionData() {
    this.projectDetailPermission.next(new ProjectDetailPermission());
  }

  setTotalImageCount(count) {
    this.totalImageCount.next(count);
  }

  resetTotalImageCount() {
    this.totalImageCount.next(0);
  }

  updateLeftMenuState(leftMenuState) {
    this.isLeftMenuOpen.next(leftMenuState);
  }

  setExecutiveAlbum(status) {
    this.isExecutiveAlbum.next(status);
  }

  setExecutiveAlbumView(status) {
    this.isExecutiveAlbumView.next(status);
  }

  setProjectData(data) {
    this.projectData.next(data);
  }

  resetProjectData() {
    this.projectData.next(new AssetInfo());
  }

  getProjectData() {
    return this.projectData.value;
  }

  mergeWithProjectData(assetInfo: AssetInfo) {
    return Object.assign(assetInfo, this.getProjectData());
  }

  setProjectImageIDs(data) {
    this.projectImageIds.next(data);
  }

  resetProjectImageIDs() {
    this.projectImageIds.next([]);
  }

  getProjectImageIDs() {
    return this.projectImageIds.value;
  }

  getPageByImageID(imageId) {
    let currentPageNumber = 0;
    if (this.getProjectImageIDs().indexOf(imageId) !== -1) {
      let projectDataPermissiion: ProjectDetailPermission =
        new ProjectDetailPermission();
      projectDataPermissiion = this.mergeWithProjectDetailPermissionData(
        projectDataPermissiion,
      );
      const index = this.getProjectImageIDs().indexOf(imageId) + 1;
      currentPageNumber = Math.ceil(
        index / projectDataPermissiion.viewSettingDTO.imagePerPage,
      );
      if (currentPageNumber === 0) {
        currentPageNumber = 1;
      }
    }
    return currentPageNumber;
  }

  setMainProjectData(data) {
    const copy = JSON.parse(JSON.stringify(data));
    this.mainProjectData = copy;
  }

  resetMainProjectData() {
    this.mainProjectData = {};
  }

  getCurrentProjectAuthority(): string {
    return this.currentProjectAuthority.getValue();
  }

  setCurrentProjectAuthority(authority): void {
    if (authority) {
      this.currentProjectAuthority.next(
        this.getProjectAuthority(this.getProjectRole()),
      );
    }
  }

  resetCurrentProjectAuthority(): void {
    this.currentProjectAuthority.next('');
  }

  getProjectAuthority(projectRoleId: string) {
    return {
      [ProjectRoleId.ROLE_EXEC]: ProjectRole.ROLE_EXEC,
      [ProjectRoleId.ROLE_TALENT]: ProjectRole.ROLE_TALENT,
      [ProjectRoleId.ROLE_ALBUMVIEWER]: ProjectRole.ROLE_ALBUMVIEWER,
    }[projectRoleId];
  }

  getMainProjectData() {
    return this.mainProjectData;
  }

  // Albums Data
  setAlbumsInfo(data) {
    this.albumsInfo.next(data);
  }

  setWideEditalbumsInfo(data) {
    this.wideEditAlbumsInfo.next(data);
  }

  setPriorityPixAssetIds(ids: number[]) {
    this.priorityPixAssetIds.next(ids);
  }

  reSetWideEditalbumsInfo() {
    this.wideEditAlbumsInfo.next({});
  }

  isInputFocused() {
    const inputs = ['input', 'select', 'textarea'];
    if (
      this.document.activeElement &&
      inputs.indexOf(this.document.activeElement.tagName.toLowerCase()) !== -1
    ) {
      return true;
    }
    return false;
  }

  getAlbumsInfo() {
    return this.albumsInfo.value;
  }

  resetAlbumsInfo() {
    this.albumsInfo.next(new AlbumsInfo());
  }

  /* Set Restart Data */
  setRestartDataDTO(filter: any) {
    this.restartDataDTO.emit(filter);
  }

  setRestartTaggingView1(filter: any) {
    this.restartTaggingView1.emit(filter);
  }

  setRestartTaggingView2(filter: any) {
    this.restartTaggingView2.emit(filter);
  }

  setRestartDataAlbum(filter: any) {
    this.restartDataAlbum.emit(filter);
  }

  setRestartDataTaggingWith(filter: any) {
    this.restartDataTaggingWith.emit(filter);
  }

  setRestartDataGeneral(filter: any) {
    this.restartDataGeneral.emit(filter);
  }

  setRestartDataTagging(filter: any) {
    this.restartDataTagging.emit(filter);
  }

  setRestartDataCuatomFilter(filter: any) {
    this.restartDataCustomFilter.emit(filter);
  }

  setRestartDataAdvanceSearch(filter: any) {
    this.restartDataAdvanceSearch.emit(filter);
  }

  setRestartPendingApproved(filter: any) {
    this.restartPendingApproved.emit(filter);
  }

  setRestartPendingRejected(filter: any) {
    this.restartPendingRejected.emit(filter);
  }

  setRestartDataAll(filter: any) {
    this.restartDataAll.emit(filter);
  }

  setIsRedirectedFromEnlarge(flag: boolean) {
    this.isRedirectedFromEnlarge.next(flag);
  }

  assignFilter(currentFilter: string) {
    // todo replace this shitty crap with switch
    if (currentFilter === FilterEnum.ShowAll) {
      this.setRestartDataAll(currentFilter);
    } else if (currentFilter.search(FilterEnum.Album) !== -1) {
      this.setRestartDataAlbum(currentFilter);
    } else if (currentFilter.search(FilterEnum.Taggedwith) !== -1) {
      this.setRestartDataTaggingWith(currentFilter);
    } else if (currentFilter.search(FilterEnum.General) !== -1) {
      this.setRestartDataGeneral(currentFilter);
    } else if (currentFilter === FilterEnum.CustomFilter) {
      this.setRestartDataCuatomFilter(currentFilter);
    } else if (currentFilter.search(FilterEnum.Advancesearch) !== -1) {
      this.setRestartDataAdvanceSearch(currentFilter);
    } else if (currentFilter.search(FilterEnum.PendingApproved) !== -1) {
      this.setRestartPendingApproved(currentFilter);
    } else if (currentFilter.search(FilterEnum.PendingRejected) !== -1) {
      this.setRestartPendingRejected(currentFilter);
    } else if (currentFilter.search(FilterEnum.Tagging1) !== -1) {
      this.setRestartTaggingView1(currentFilter);
    } else if (currentFilter.search(FilterEnum.Tagging2) !== -1) {
      this.setRestartTaggingView2(currentFilter);
    } else if (currentFilter.search(FilterEnum.Tagging) !== -1) {
      this.setRestartDataTagging(currentFilter);
    } else if (currentFilter.search(FilterEnum.Batch) !== -1) {
      this.setRestartDataAll(currentFilter);
    }
  }

  assignLastSelectedImage(assetID: number, data: any) {
    let currentImageNum = -1;
    const assets = [];
    data.assetInfoDTOList.forEach((obj, i) => {
      if (assetID === obj.id) {
        assets.push(obj);
        currentImageNum = i + 1;
      }
    });

    this.selectedImagesStore.clear();
    this.lastSelectedImageStore.set(assets);

    if (currentImageNum !== -1) {
      return currentImageNum;
    } else {
      // JAYDEEP - Re Construct this logic - Temporary comment
    }
  }

  assignLastSelectedImageForSetUp(assetID: number) {
    let currentImageNum = -1;
    this.projectImageData = this.mergeWithProjectData(this.projectImageData);
    const assets = [];
    this.projectImageData.assetInfoDTOList.map((obj, i) => {
      if (assetID === obj.id) {
        assets.push(obj);
        currentImageNum = i + 1;
      }
    });

    this.selectedImagesStore.clear();
    this.lastSelectedImageStore.set(assets);

    return currentImageNum;
  }

  /* Load selected project data */
  callProjectImageIDs(projectId: any, filter: any): Observable<number[]> {
    const path = AppSettings.ASSETS_ID_INFO(
      projectId,
      filter,
      this.getProjectRole(),
    );
    return this.apiService.get(path).pipe(
      map((data) => {
        if (data !== null) {
          this.setProjectImageIDs(data);
          return data;
        }
      }),
    );
  }

  callAssetsInfoBasedOnAssetsId(data: any): Observable<any> {
    if (this.isTaggingView1Open.getValue()) {
      data.view = 'tag-view-1';
    } else if (this.isTaggingView2Open.getValue()) {
      data.view = 'tag-view-2';
    } else if (this.isExecutiveAlbumView.getValue()) {
      data.view = 'executive-album-2';
    } else if (Number(this.launcherService.selectedProject$) === 3) {
      data.view = 'executive-album-3';
    } else if (Number(this.launcherService.selectedProject$) === 4) {
      data.view = 'executive-album-4';
    }

    if (this.currentFilter.getValue() === FilterEnum.AuditAndConflicts) {
      data.view = FilterEnum.Conflicts;
    } else if (
      this.currentFilter.getValue() === FilterEnum.AuditAndNoConflicts
    ) {
      data.view = FilterEnum.NoConflicts;
    } else if (
      this.currentFilter.getValue() === FilterEnum.AuditAndBypassTagger2
    ) {
      data.view = FilterEnum.BypassTagger2;
    }

    data.projectRoleId = this.getProjectRole();

    const path = AppSettings.ASSETS_INFO_BASEDON_ASSETS_ID;
    return this.apiService.post(path, data).pipe(
      map((res) => {
        if (Number(this.launcherService.selectedProject$) === 4) {
          res = this.constructDataForWideEditSpecialAlbums(res);
        }
        const newAssetsInfoData =
          Utils.synchronizeAssetIdsWithAssetInfoDTOListIds(res, data);
        this.resetProjectData();
        this.setProjectData(newAssetsInfoData);
        return newAssetsInfoData;
      }),
    );
  }

  constructDataForWideEditSpecialAlbums(res) {
    res.assetInfoDTOList.forEach((imageData) => {
      imageData.executiveWideEditDTOList.forEach((list) => {
        if (list.userId === this.currentUserId && list.actionTypeId === 1) {
          imageData.exicutiveRejected = 1;
        } else if (
          list.userId === this.currentUserId &&
          list.actionTypeId === 3
        ) {
          imageData.exicutiveRejected = 3;
        }

        if (list.userId === this.currentUserId && list.like) {
          imageData.exicutiveLike = true;
        } else if (list.userId === this.currentUserId && !list.like) {
          imageData.exicutiveLike = false;
        }
      });
    });

    return res;
  }

  callSingleAssetsInfoBasedOnAssetsId(data: any): Observable<any> {
    if (data.filter !== FilterEnum.ShowAll) {
      // BE will return error if send showall + data.view
      switch (true) {
        case this.isTaggingView1Open.getValue():
          data.view = 'tag-view-1';
          break;
        case this.isTaggingView2Open.getValue():
          data.view = 'tag-view-2';
          break;
        case this.isExecutiveAlbumView.getValue():
          data.view = 'executive-album-2';
          break;
        case Number(this.launcherService.selectedProject$) === 3:
          data.view = 'executive-album-3';
          break;
        case Number(this.launcherService.selectedProject$) === 4:
          data.view = 'executive-album-4';
          break;
        case this.currentFilter.getValue() === FilterEnum.AuditAndConflicts:
          data.view = FilterEnum.Conflicts;
          break;
        case this.currentFilter.getValue() === FilterEnum.AuditAndNoConflicts:
          data.view = FilterEnum.NoConflicts;
          break;
        case this.currentFilter.getValue() === FilterEnum.AuditAndBypassTagger2:
          data.view = FilterEnum.BypassTagger2;
          break;
        default:
          break;
      }
    }
    data.projectRoleId = this.getProjectRole();
    const path = AppSettings.ASSETS_INFO_BASEDON_ASSETS_ID;
    return this.apiService.post(path, data).pipe(
      map((res) => {
        const newAssetsInfoData =
          Utils.synchronizeAssetIdsWithAssetInfoDTOListIds(res, data);
        return newAssetsInfoData;
      }),
    );
  }

  getImagesNameBasedOnAssetsId(data: any): Observable<any> {
    const path = AppSettings.IMAGE_NAMES_BASEDON_ASSETS_ID;
    return this.apiService.post(path, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getImageGroupData(data: any): Observable<any> {
    const path = AppSettings.CALCULATE_GS;
    return this.apiService.put(path, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getClearSingleTagsData(data: any): Observable<any> {
    const path = AppSettings.GET_CLEAR_SINGLETAGS_DATA;
    return this.apiService.post(path, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  doAdvanceTagging(data: any): Observable<any> {
    const path = AppSettings.ADVANCE_TAGGING;
    return this.apiService.post(path, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  callSetUpData(projectId: any, setupId: any): Observable<AssetInfo> {
    const path = AppSettings.SETUP_ASSETS_INFO(projectId, setupId);
    return this.apiService.get(path).pipe(
      map((data) => {
        this.setProjectImageIDs(data);
        return data;
      }),
    );
  }

  callLastSelectedImage(imagData: any): Observable<any> {
    const path = AppSettings.RESTART_DATA;
    return this.apiService.put(path, imagData).pipe(
      map((data) => {
        return data;
      }),
    );
  }

  isJPEGType(viewSettingDTOSize: string) {
    return {
      [ImageSize.SMALL_JPEG]: ImageSize.SMALL_JPEG,
      [ImageSize.MEDIUM_JPEG]: ImageSize.MEDIUM_JPEG,
      [ImageSize.LARGE_JPEG]: ImageSize.LARGE_JPEG,
    }[viewSettingDTOSize];
  }

  excludeDownloadTypes(
    downloadType: number,
    lookedApproved: boolean,
    assetDetail,
  ) {
    if (downloadType === 10 || (downloadType === 10 && lookedApproved)) {
      Object.keys(assetDetail.Image).map(
        (key: string) => !this.isJPEGType(key) && delete assetDetail.Image[key],
      );
      assetDetail.Other = {};
      return assetDetail;
    } else {
      return assetDetail;
    }
  }

  callAssetsIdOnAssetsName(projectId: any, assetsName: any): Observable<any> {
    const path =
      AppSettings.ASSETSID_ON_ASSETSNAME + assetsName + '/project/' + projectId;
    return this.apiService.get(path).pipe(
      map((data) => {
        return data;
      }),
    );
  }

  callProjectDetailPermissionAPI(
    projectId: string,
    projectRole: string,
  ): Observable<ProjectDetailPermission> {
    const path = AppSettings.PROJECT_DETAIL_WITH_ROLE(projectId, projectRole);
    return this.apiService.get(path).pipe(
      map((data) => {
        this.authServerProvider.saveToken(data.id_token);
        if (data.projectTagListDTO.length > 0) {
          data.projectTagListDTO.forEach((element) => {
            if (element.tagName === 'Sensitive') {
              this.setSensitiveID(element.id);
            }
          });
        }
        // Restart page will force to change if totalpage is lower then restart page no.
        if (data.restartDataDTO && data.restartDataDTO.pageNo) {
          const totalActualPages = Math.ceil(
            data.totalAssets / data.viewSettingDTO.imagePerPage,
          );
          if (data.restartDataDTO.pageNo > totalActualPages) {
            data.restartDataDTO.pageNo = totalActualPages;
          }
        }
        return data;
      }),
    );
  }

  callAlbumsInfo(projectId: any, albumTypeId: any): Observable<AlbumsInfo> {
    const path = AppSettings.ALBUMS_INFO(projectId, albumTypeId);
    return this.apiService.get(path).pipe(
      map((data) => {
        this.setAlbumsInfo(data);
        return data;
      }),
    );
  }

  callAlbumImageIDs(projectId: number, filter: string): Observable<number[]> {
    const path = AppSettings.ASSETS_ID_INFO(
      projectId,
      filter,
      this.getProjectRole(),
    );
    return this.apiService.get(path).pipe(
      map((data) => {
        if (data !== null) {
          return data;
        }
      }),
    );
  }

  callAssetsInfoBasedOnAssetIds(data: {
    assetIds: number[];
    projectId: number;
    filter: string;
    view?: string;
  }): Observable<AssetInfo> {
    this.getAssetsInfoViewBasedOnCurrentFilter(data);
    const path = AppSettings.ASSETS_INFO_BASEDON_ASSETS_ID;
    return this.apiService.post(path, data).pipe(
      map((res) => {
        if (Number(this.launcherService.selectedProject$) === 4) {
          res = this.constructDataForWideEditSpecialAlbums(res);
        }
        return Utils.synchronizeAssetIdsWithAssetInfoDTOListIds(res, data);
      }),
    );
  }

  getAssetsInfoViewBasedOnCurrentFilter(data: {
    assetIds: number[];
    projectId: number;
    filter: string;
    view?: string;
  }): void {
    if (this.isTaggingView1Open.getValue()) {
      data.view = AssetsInfoView.TagView1;
    } else if (this.isTaggingView2Open.getValue()) {
      data.view = AssetsInfoView.TagView2;
    } else if (this.isExecutiveAlbumView.getValue()) {
      data.view = AssetsInfoView.ExecutiveAlbum2;
    } else if (Number(this.launcherService.selectedProject$) === 3) {
      data.view = AssetsInfoView.ExecutiveAlbum3;
    } else if (Number(this.launcherService.selectedProject$) === 4) {
      data.view = AssetsInfoView.ExecutiveAlbum4;
    }

    if (this.currentFilter.getValue() === FilterEnum.AuditAndConflicts) {
      data.view = FilterEnum.Conflicts;
    } else if (
      this.currentFilter.getValue() === FilterEnum.AuditAndNoConflicts
    ) {
      data.view = FilterEnum.NoConflicts;
    } else if (
      this.currentFilter.getValue() === FilterEnum.AuditAndBypassTagger2
    ) {
      data.view = FilterEnum.BypassTagger2;
    }
  }

  callWideEditAlbumsInfo(
    projectId: any,
    albumTypeId: any,
  ): Observable<AlbumsInfo> {
    const path = AppSettings.ALBUMS_INFO(projectId, albumTypeId);
    return this.apiService.get(path).pipe(
      map((data) => {
        this.setWideEditalbumsInfo(data);
        return data;
      }),
    );
  }

  setCurrentPageNumber(pageNumber) {
    this.currentPageNumber = pageNumber;
    if (pageNumber) {
      this.currentPageNumberClone = pageNumber;
    }
  }

  setTotalProjectPages(
    totalImagesCount: number,
    imagePerPageCount: number,
  ): void {
    if (totalImagesCount > imagePerPageCount) {
      totalImagesCount = Math.ceil(totalImagesCount / imagePerPageCount);
    } else {
      totalImagesCount = 1;
    }
    this.setTotalPageNumber(totalImagesCount);
  }

  getCurrentPageNumber() {
    return this.currentPageNumber;
  }

  getCurrentPageNumberClone() {
    return this.currentPageNumberClone;
  }

  setTotalPageNumber(pageNumber) {
    this.totalPages = pageNumber;
  }

  getTotalPageNumber() {
    return this.totalPages;
  }

  setSavedAlbums(album) {
    return this.savedAlbums.next(album);
  }

  getCurrentFilter() {
    return this.currentFilter.value;
  }

  setCurrentFilter(filter) {
    this.currentFilter.next(filter);
  }

  sendImages(imgName) {
    this.imgNames.next(imgName);
  }

  putSetPriorityPix(data: any): Observable<any> {
    const path = AppSettings.PUT_PRIORITY_PIX;
    return this.apiService.put(path, data).pipe(map((res) => res));
  }

  putSetTalentActionRejected(data: any): Observable<any> {
    const path = AppSettings.TALENT_ACTION_REJECT;
    return this.apiService.put(path, data).pipe(map((res) => res));
  }

  putSetExcudeRelease(data: any): Observable<any> {
    const path = AppSettings.EXCLUDE_RELEASE;
    return this.apiService.put(path, data).pipe(map((res) => res));
  }

  exclusiveHistory(projectId, assetsId): Observable<any> {
    const path = AppSettings.EXCLUSIVES_HISTORY() + projectId + '/' + assetsId;
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getPriorityPixCount(projectId): Observable<any> {
    const path = AppSettings.GET_PRIORITY_PIX_COUNT(projectId);
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  // Executive Album Approved Reject
  postExecutiveAlbumTag(data: any): Observable<any> {
    const path = AppSettings.EXECUTIVE_ALBUM_TAG;
    return this.apiService.post(path, data).pipe(map((res) => res));
  }

  postExecutiveAlbumTagToggle(data: any): Observable<any> {
    const path = AppSettings.EXECUTIVE_ALBUM_TAG_TOGGLE_ACTION;
    return this.apiService.post(path, data).pipe(map((res) => res));
  }

  getBookMarkData(pojectID: any): Observable<any> {
    const path = AppSettings.GET_BOOKMARKDATA(pojectID);
    return this.apiService.get(path).pipe(map((res) => res));
  }

  createBookMark(data: any): Observable<any> {
    const path = AppSettings.CREATE_BOOKMARK;
    return this.apiService.post(path, data).pipe(map((res) => res));
  }

  deleteBookmark(pojectID: any, data: any): Observable<any> {
    const path = AppSettings.GET_BOOKMARKDATA(pojectID);
    const finalPath = path + '/' + data;
    return this.apiService.delete(finalPath).pipe(map((res) => res));
  }

  getImageVideoUrl(data: any): Observable<any> {
    const path = AppSettings.IMAGE_VIDEO_URL(data);
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getAssetLinkWithAuthToken(src: string): Observable<string> {
    const authToken = this.authServerProvider.getToken();

    return this.http
      .get(src, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        responseType: 'blob',
      })
      .pipe(
        map((blob) => {
          return URL.createObjectURL(blob);
        }),
      );
  }

  getWebImageURL(imgData, imageDim): string {
    const projectDetail = this.getProjectDetailPermissionData();
    const secureAccess =
      this.getUserSecureAccess() === undefined
        ? false
        : this.getUserSecureAccess();
    const UTCSeconds = this._nowTime.getTime();
    let baseURL =
      imageDim === '1600'
        ? this.apiImageService.internalBaseUrl$
        : this.apiImageService.webImageUrlGlobal$;
    // add secure root if needed

    baseURL = secureAccess
      ? `${baseURL}${AppSettings.SZ_SECURE_WEB_IMAGE_US}`
      : `${baseURL}${AppSettings.SZ_NON_SECURE_WEB_IMAGE_US}`;

    const currentUserName = this.currentUserName;
    const watermark = projectDetail.permissionDTO
      ? projectDetail.permissionDTO.watermark
      : false;
    let fullURL = '';
    let middleKey = '';
    let middleKey1 = '';
    if (watermark) {
      middleKey = `${UTCSeconds}%7C${projectDetail.locator.replace(
        /\|/g,
        '%7C',
      )}%7C${imgData.batchLocator}%7C${
        projectDetail.permissionDTO.watermarkInnerTransparency
      }%7C${
        projectDetail.permissionDTO.watermarkOuterTransparency
      }%7C${currentUserName}%7C${
        imgData.name ? imgData.name : imgData.coverImage
      }`;
      middleKey1 = `${UTCSeconds}|${projectDetail.locator}|${
        imgData.batchLocator
      }|${projectDetail.permissionDTO.watermarkInnerTransparency}|${
        projectDetail.permissionDTO.watermarkOuterTransparency
      }|${currentUserName}|${imgData.name ? imgData.name : imgData.coverImage}`;
    } else {
      middleKey = `${UTCSeconds}%7C${projectDetail.locator.replace(
        /\|/g,
        '%7C',
      )}%7C${imgData.batchLocator}%7C${
        imgData.name ? imgData.name : imgData.coverImage
      }`;
      middleKey1 = `${UTCSeconds}|${projectDetail.locator}|${
        imgData.batchLocator
      }|${imgData.name ? imgData.name : imgData.coverImage}`;
    }
    fullURL = this.getFullWebImageUrl(
      secureAccess,
      baseURL,
      middleKey,
      middleKey1,
      imageDim,
    );
    return fullURL;
  }

  private getFullWebImageUrl(
    secureAccess: boolean,
    baseURL: string,
    middleKey: string,
    middleKey1: string,
    imageDim: string,
  ): string {
    let fullURL = '';
    if (secureAccess) {
      const encryptURL = this.urlEncryptService.encrypt(middleKey1);
      fullURL = Utils.getWebImageUrl(baseURL, encryptURL, imageDim);
    } else {
      fullURL = Utils.getWebImageUrl(baseURL, middleKey, imageDim);
    }
    return fullURL;
  }

  getHistoryData(pojectID: any, assetID, historyReportType): Observable<any> {
    const path = AppSettings.HISTORY_DATA(pojectID, assetID, historyReportType);
    return this.apiService.get(path).pipe(map((res) => res));
  }

  getActorIDReport(pojectID: any): Observable<any> {
    const path = AppSettings.ACTORID_REPORT(pojectID);
    return this.apiService.get(path).pipe(map((res) => res));
  }

  setisViewsettingChangedGlobal(flag: boolean) {
    this.isViewsettingChanged.next(flag);
  }

  IamDone(data: any): Observable<any> {
    const path = AppSettings.I_AM_DONE;
    return this.apiService.post(path, data).pipe(map((res) => res));
  }

  sendEmailNotifications(data: any): Observable<any> {
    const path = AppSettings.SEND_EMAIL_NOTIFICATIONS;
    return this.apiService.post(path, data).pipe(map((res) => res));
  }

  setSelectedBatchId(batchId: number) {
    this.selectedBatchId.next(batchId);
  }

  resetSelectedBatchId() {
    this.selectedBatchId.next(undefined);
  }

  getSelectedAssetsInfoData(data): Observable<AssetInfoDTOList[]> {
    this.loaderService.displayLoader(true);
    return this.callAssetsInfoBasedOnAssetIds(data).pipe(
      takeUntil(this.destroy$),
      map((resData: AssetInfo) => {
        return resData.assetInfoDTOList;
      }),
      catchError((error) => {
        this.loaderService.displayLoader(false);
        return throwError(error);
      }),
    );
  }

  getSensitiveAssets(data): Observable<AssetInfoDTOList[]> {
    const path = AppSettings.GET_SENSITIVE_ASSETS;
    return this.apiService.post(path, data).pipe(map((res) => res));
  }

  getVideoAssets(data): Observable<AssetInfoDTOList[]> {
    const path = AppSettings.GET_VIDEO_ASSETS;
    return this.apiService.post(path, data).pipe(map((res) => res));
  }

  getAssetIndices(assetInfoDTOList, val): any {
    return assetInfoDTOList
      .map((item, i) => item.name.includes(val) ? i : -1)
      .filter(index => index !== -1);
  }

  selectFoundAssets(assetInfoDTOList, indices) {
    if (indices.length >= 2) {
      indices.map(id => {
        this.selectedImagesStore.add(
          assetInfoDTOList[id],
        );
      });
    }
  }
}
