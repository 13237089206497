export const AdvanceSearchFilter: any = {
  status: 'All',
  excludedReleasedStatus: 'All',
  filterOpetion: [
    {
      name: 'Only/Exact Match',
      isChecked: false,
      trans: 'left-menu.tab-bar.advance-search.checkbox.only-exactmatch',
    },
  ],
  talentExecAttributes: [
    {
      name: 'Finalized By',
      isChecked: false,
      trans: 'left-menu.tab-bar.advance-search.checkbox.finalized-by',
    },
    {
      name: 'Rejected By',
      isChecked: false,
      trans: 'left-menu.tab-bar.advance-search.checkbox.rejected-by',
    },
    {
      name: 'Excluded From',
      isChecked: false,
      trans: 'left-menu.tab-bar.advance-search.checkbox.excluded-from',
    },
    {
      name: 'Not',
      isChecked: false,
      trans: 'left-menu.tab-bar.advance-search.checkbox.not',
    },
  ],
  approvalStatus: [
    {
      name: 'Approved',
      isChecked: false,
      trans: 'left-menu.tab-bar.advance-search.checkbox.approved',
    },
    {
      name: 'Rejected',
      isChecked: false,
      trans: 'left-menu.tab-bar.advance-search.checkbox.Rejected',
    },
  ],
  talentAppearance: [
    {
      name: 'Solo',
      isChecked: false,
      trans: 'left-menu.tab-bar.advance-search.checkbox.solo',
    },
    {
      name: 'Group',
      isChecked: false,
      trans: 'left-menu.tab-bar.advance-search.checkbox.Group',
    },
  ],
  other: [
    {
      name: 'Image Notes',
      isChecked: false,
      trans: 'left-menu.tab-bar.advance-search.checkbox.image-notes',
    },
    {
      name: 'PriorityPix',
      isChecked: false,
      trans: 'left-menu.tab-bar.advance-search.checkbox.prioritypix',
    },
    {
      name: 'Retouch Comment',
      isChecked: false,
      trans: 'left-menu.tab-bar.advance-search.checkbox.retouch-comment',
    },
  ],
  exclusives: [
    {
      name: 'All Exclusives',
      isChecked: false,
      trans: 'left-menu.tab-bar.advance-search.checkbox.all-exclusive',
    },
    {
      name: 'Select Exclusive',
      value: '',
      isChecked: false,
      trans: 'left-menu.tab-bar.advance-search.checkbox.select-exclusive',
    },
  ],
  imageBatch: [
    {
      name: 'Image',
      isChecked: false,
      value: '',
      trans: 'left-menu.tab-bar.advance-search.checkbox.Image',
    },
    {
      name: 'Batch',
      isChecked: false,
      value: Number,
      trans: 'left-menu.tab-bar.advance-search.checkbox.Batch',
    },
  ],
  tags: [],
  generalTagIds: [],
  publishedTagIds: [],
  projectAlbum: [],
  albums: [],
};
