import { DashboardLauncherDepartmentRule } from '../rules/dashboard-launcher-department-rule';
import { DashboardLauncherProjectAlbumViewerRule } from '../rules/dashboard-launcher-project-album-viewer-rule';
import { DashboardLauncherExecSetupRule } from '../rules/dashboard-launcher-exec-setup-rule';
import { DashboardLauncherTalentProjectPkoRule } from '../rules/dashboard-launcher-talent-project-pko-rule';
import { DashboardLauncherTalentSetupWdtvRule } from '../rules/dashboard-launcher-talent-setup-wdtv-rule';
import { SelectedProjectModel } from '../interfaces/dashboard-launcher-rule.model';

type DashboardEventsUnion =
  | DashboardLauncherDepartmentRule
  | DashboardLauncherProjectAlbumViewerRule
  | DashboardLauncherExecSetupRule
  | DashboardLauncherTalentProjectPkoRule
  | DashboardLauncherTalentSetupWdtvRule;

export class DashboardLauncherRuleset {
  ruleSet: DashboardEventsUnion[];

  constructor(
    private groupType: string,
    private selectedProject: SelectedProjectModel,
  ) {
    this.ruleSet = [
      new DashboardLauncherDepartmentRule(groupType, selectedProject),
      new DashboardLauncherProjectAlbumViewerRule(groupType, selectedProject),
      new DashboardLauncherExecSetupRule(groupType, selectedProject),
      new DashboardLauncherTalentProjectPkoRule(groupType, selectedProject),
      new DashboardLauncherTalentSetupWdtvRule(groupType, selectedProject),
    ];
  }

  apply(): void {
    for (const rule of this.ruleSet) {
      if (rule.matches()) {
        rule.apply();
      }
    }
  }
}
