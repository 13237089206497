<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{ 'view-project.email.title' | translate }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="container">
    <div class="row mb-3">
      <div class="col mr-3 d-flex">
        <button class="fs-dialog-btn w-auto" (click)="onClickSendRecepients()">
          {{
            'left-menu.tab-bar.sidemenus.administration.SelectRecepient.title'
              | translate
          }}
        </button>
        <span
          class="w-100 mt-2 mb-2 ml-0 mr-0 text-center"
          *ngIf="isDataEmitedFromChild && selectedEmailCount != 0"
        >
          {{ selectedEmailCount }}
          {{
            'left-menu.tab-bar.sidemenus.administration.emailNotification.recepients'
              | translate
          }}
        </span>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <input
          type="text"
          class="form-control fs-md-txt"
          id="subject"
          [(ngModel)]="subject"
          [placeholder]="'top-menu.sendMail.subject' | translate"
        />
      </div>
    </div>

    <div class="row">
      <div class="col" id="filteredList">
        <textarea
          name="message"
          class="compose-msg"
          [(ngModel)]="message"
          [placeholder]="
            'left-menu.tab-bar.sidemenus.administration.emailNotification.compose-message'
              | translate
          "
        >
        </textarea>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button
    class="fs-dialog-btn w-auto ml-3"
    [disabled]="!arrLlistEmails.length"
    (click)="onClickSend()"
  >
    {{ 'view-project.email.send' | translate }}
  </button>
  <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
    {{
      'left-menu.tab-bar.sidemenus.administration.filtered-list.button.close'
        | translate
    }}
  </button>
</div>
