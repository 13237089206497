import { Router } from '@angular/router';
import { DepartmentService } from '../../../../department/department.service';
import { LauncherService } from '../../../../launcher/launcher.service';
import { FavListModel } from '../interfaces/dashboard-favourite-rule.model';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardFavouriteProjectSetupRule implements DashboardRuleModel {
  private router = GetStaticInjector().get(Router);
  private departmentService = GetStaticInjector().get(DepartmentService);
  private launcherService = GetStaticInjector().get(LauncherService);

  constructor(private selectedProject: FavListModel) {}

  matches(): boolean {
    return this.selectedProject.useSetup;
  }

  apply(): void {
    this.launcherService.setIsFavGallaryModeStatus(true);
    this.launcherService.setGallariesDepartmentID(
      this.selectedProject.departmentId,
    );
    this.departmentService.setSetUpNameGlobal(this.selectedProject.value);
    this.departmentService.setSetUpNameGroupGlobal(this.selectedProject.label);
    this.launcherService.setProjectRole(this.selectedProject.role);
    this.router.navigate(
      ['/dashboard/viewgallery/' + this.selectedProject.value],
      { state: { selectedProjectRole: this.selectedProject.role } },
    );
  }
}
