// our root app component
import { Injectable } from '@angular/core';
import { ViewProjectService } from './view-project.service';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { AssetInfo, AssetSoloGroupModel } from '../models/assetInfo.model';
import { AppSettings } from '../../appSettings/appSettings';
import { FilterEnum } from '../enum/filter.enum';
import { Utils } from '../utils/utils';

@Injectable()
export class SingleExcludeService {
  private _selectedImagesFullAssetsInfo = new BehaviorSubject<AssetSoloGroupModel[]>([new AssetSoloGroupModel()]);
  public selectedImagesFullAssetsInfo$ =
    this._selectedImagesFullAssetsInfo.asObservable();

  constructor(
    private viewProjectService: ViewProjectService,
    private apiService: ApiService,
  ) {}

  /**
   * Get Full asset info for all selected images
   */
  getSelectedImagesFullAssetsInfo(
    allSelectedImageIDs: number[],
  ): Promise<AssetSoloGroupModel[]> {
    const data = {
      assetIds: allSelectedImageIDs,
      projectId: this.viewProjectService.getProjectId()
    };
    const path = AppSettings.EXCLUDE_RELEASE_SOLO_GROUP;
    return this.apiService
      .post(path, data)
      .pipe(
        tap((res: AssetSoloGroupModel[]) => {
          this._selectedImagesFullAssetsInfo.next(res);
        }),
      )
      .toPromise();
  }
}
