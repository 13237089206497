import { Injectable } from '@angular/core';

@Injectable()
export class AppSettings {
  // Get IP Address
  public static get GET_IP(): string {
    return 'https://api.ipify.org/?format=json';
  }
  public static get REFRESH_TOKEN(): string {
    return 'api/refresh';
  }
  public static get LOG_OUT(): string {
    return 'api/logout';
  }

  // Domain
  public static get DOMAIN_DATA(): string {
    return 'api/domain';
  }

  // Department Projects
  public static DEPARTMENT_ALBUMS(id: string): string {
    return 'services/central/api/albums/type/' + id;
  }

  public static DEPARTMENT_PROJECTS(id: string): string {
    return 'services/central/api/departments/' + id + '/projects';
  }

  // Executive Projects
  public static EXECUTIVE_PROJECTS(id: string): string {
    return 'services/central/api/albums/executive/' + id + '/projects';
  }

  public static DUPLICATE_PROJECT_ASSETS(projectId: string): string {
    return 'services/central/api/assets/duplicated-names/' + projectId;
  }

  public static ORIGINAL_ASSET_NAMES(): string {
    return 'services/central/api/assets/name-report';
  }

  public static get EXECUTIVE_ALBUM_NOTIFY(): string {
    return 'services/central/api/executive-album/notify';
  }

  public static get EXECUTIVE_ALBUM_TAG(): string {
    return 'services/central/api/executive-album-tag';
  }

  public static get EXECUTIVE_ALBUM_TAG_TOGGLE_ACTION(): string {
    return 'services/central/api/executive-album-tag/toggle-action';
  }

  public static get PROJECT_DATA(): string {
    return 'projects/';
  }

  public static PROJECT_DETAIL_WITH_ROLE(id: string, role: string): string {
    return 'services/central/api/projects/' + id + '/' + role + '/detail';
  }

  public static ALBUMS_INFO(id: string, albumTypeId: string): string {
    return 'services/central/api/albums/' + albumTypeId + '/project/' + id;
  }

  public static ALBUMS_GROUP(projectId): string {
    return 'services/central/api/albums/project/' + projectId + '/';
  }

  public static ALBUMS_VIEWER_GROUP(projectId): string {
    return 'services/central/api/albums/viewer/project/' + projectId + '/';
  }

  public static ASSETS_INFO(id: string): string {
    return 'services/central/api/projects/' + id + '/assets';
  }

  public static ASSETS_ID_INFO(id: any, filter: any, projectRoleId): string {
    return (
      'services/central/api/projects/' +
      id +
      '/projectRoleId/' +
      projectRoleId +
      '/filter/' +
      filter +
      '/assets'
    );
  }

  public static get ASSETS_INFO_BASEDON_ASSETS_ID(): string {
    return 'services/central/api/projects/assets';
  }

  public static get EXCLUDE_RELEASE_SOLO_GROUP(): string {
    return 'services/central/api/projects/assets/solo-group';
  }

  public static get IMAGE_NAMES_BASEDON_ASSETS_ID(): string {
    return 'services/central/api/assets/name';
  }

  public static get CALCULATE_GS(): string {
    return 'services/central/api/assets/calculate-gs';
  }

  public static get GET_CLEAR_SINGLETAGS_DATA(): string {
    return 'services/central/api/project-roles/talent-name';
  }

  public static get ADVANCE_TAGGING(): string {
    return 'services/central/api/asset/advance-tagging';
  }

  public static ASSETS_INFO_PAGINATION(
    id: string,
    pageNo: any,
    limitPerPage: any,
  ): string {
    return (
      'services/central/api/projects/' +
      id +
      '/assetss/?page=' +
      pageNo +
      '?limit=' +
      limitPerPage
    );
  }

  public static SETUP_ASSETS_INFO(id: string, setupId: string): string {
    return (
      'services/central/api/projects/' + id + '/setups/' + setupId + '/assets'
    );
  }

  public static GET_SETUP_DATA(gValue): string {
    return 'services/central/api/projects/' + gValue + '/setups';
  }

  public static get RESTART_DATA(): string {
    return 'services/central/api/project-user-relations/restart-data';
  }

  public static get ASSETSID_ON_ASSETSNAME(): string {
    return 'services/central/api/assets/';
  }

  public static get META_DATA_UPDATE(): string {
    return 'services/central/api/assets/meta-data';
  }

  public static get META_DATA_LIST(): string {
    return 'services/central/api/assets/meta-data/list';
  }

  // Top Menu
  public static get ASEETS_INFODATA(): string {
    return 'services/central/api/assets/detail';
  }

  public static get ASEET_DETAIL(): string {
    return 'asset/detail';
  }

  public static get ASEET_DETAIL_SINGLE_SIZE(): string {
    return 'asset/detail/single-size';
  }

  public static get SEND_MAIL(): string {
    return 'asset/email';
  }

  public static SINGLE_FILE_DOWNLOAD(token: string, fileName: string): string {
    return 'asset/download/' + token + '/' + fileName;
  }

  public static DOWNLOAD_SINGLE_FILE_DOWNLOAD(fileName: string): string {
    return 'download/image/' + fileName;
  }

  public static get ZIP_FILE_DOWNLOAD(): string {
    return 'asset/zip';
  }

  public static get EXPRESS_ZIP_FILE_DOWNLOAD(): string {
    return 'asset/express-download';
  }

  public static get MEDIA_SHUTTLE_DELIVERY(): string {
    return 'asset/mediashuttle-delivery';
  }

  public static get ADD_UPDATE_ALBUM(): string {
    return 'services/central/api/albums';
  }

  public static get ALBUM_TAG_KEY(): string {
    return 'services/central/api/albums/tag-key';
  }

  public static get ALBUM_TAG_COLOR(): string {
    return 'services/central/api/albums/tag-color';
  }

  public static ADVANCE_SEARCH_CREATORS(projectId: number): string {
    return `services/central/api/assets/meta-data/creators/${projectId}`;
  }

  public static ADVANCE_SEARCH_KEYWORDS(projectId: number): string {
    return `services/central/api/assets/meta-data/keywords/${projectId}`;
  }

  public static get ALBUM_EXECUTIVE(): string {
    return 'services/central/api/albums/executive';
  }

  public static get ADD_ALBUM_PERMISSION(): string {
    return 'services/central/api/album-permission';
  }

  public static DELETE_ALBUM(id, projectId): string {
    return 'services/central/api/albums/' + id + '/project/' + projectId;
  }

  public static GET_ASSETS_ALBUMID(albumId, projectID): string {
    return (
      'services/central/api/albums/' +
      albumId +
      '/project/' +
      projectID +
      '/assets'
    );
  }

  public static GET_EXECUTIVE_ASSETS_ALBUMID(
    albumId,
    projectValue,
    projectID,
  ): string {
    return (
      'services/central/api/executive-album/' +
      projectValue +
      '/' +
      albumId +
      '/project/' +
      projectID +
      '/assets'
    );
  }

  public static get GET_SENSITIVE_ASSETS(): string {
    return 'services/central/api/projects/assets/sensitive';
  }

  public static get GET_VIDEO_ASSETS(): string {
    return 'services/central/api/projects/assets/video';
  }

  public static get GET_EXCLUSIVE_ASSETS(): string {
    return 'services/central/api/projects/assets/exclusive';
  }

  public static get GET_LINK_EXCLUSIVE_ASSETS(): string {
    return 'services/central/api/projects/assets/adhoc-creating/exclusive';
  }

  public static get ALBUM_TAGGING(): string {
    return 'services/central/api/albums/asset/tag';
  }

  public static get PUT_PRIORITY_PIX(): string {
    return 'services/central/api/assets/prioritypix';
  }

  public static get ALBUM_STATUS(): string {
    return 'services/central/api/albums/status';
  }

  public static get ADD_ADHOC_ALBUM(): string {
    return 'services/central/api/albums/adhoc';
  }

  public static GET_ADHOC_ALBUMS(projectID): string {
    return 'services/central/api/albums/adhoc/project/' + projectID;
  }

  public static get ALBUM_COPY_CREATE(): string {
    return 'services/central/api/albums/copy-create';
  }

  public static get ALBUM_EXECUTIVE_ALBUM(): string {
    return 'services/central/api/executive-album';
  }

  public static ALBUM_ACCESS_CHECK(projectId, albumId): string {
    return 'services/central/api/albums/redirect/' + projectId + '/' + albumId;
  }

  // Create Note
  public static get CREATE_ASSET_NOTE(): string {
    return 'services/central/api/asset-notes';
  }

  // Delete Note
  public static DELETE_NOTE(projectId, noteId): string {
    return 'services/central/api/asset-notes/' + projectId + '/' + noteId;
  }

  public static NOTE_REPORTS(id) {
    return 'services/central/api/asset-notes/report/' + id;
  }

  public static PROJECT_REPORTS(id) {
    return 'services/central/api/projects/' + id + '/report';
  }

  public static CAPTION_REPORTS(id, filter): string {
    return 'services/central/api/asset-captions/report/' + id + '/' + filter;
  }

  public static REMOVE_CAPTION(projectID, assetsID): string {
    return 'services/central/api/asset-captions/' + projectID + '/' + assetsID;
  }

  public static NOTE_REPORTS_FILTER(projectId, filter): string {
    return (
      'services/central/api/asset-notes/report/' + projectId + '/' + filter
    );
  }

  // Caption Key
  public static CAPTION_KEYS(projectID): string {
    // Get All Keys
    return 'services/central/api/caption-short-keys/project/' + projectID;
  }

  public static get CREATE_CAPTION_KEY(): string {
    // Get All Keys
    return 'services/central/api/caption-short-keys';
  }

  public static get IMAGE_CAPTION_REPORT(): string {
    return 'services/central/api/assets/data/';
  }

  public static get CAPTION_REPLACE(): string {
    return 'services/central/api/asset-captions/replace';
  }

  public static DELETE_CAPTIONKEY(id): string {
    return 'services/central/api/caption-short-keys/' + id;
  }

  public static CAPTION_ALL(filter): string {
    // Get All Keys
    return 'services/central/api/asset-captions/project/' + filter;
  }

  public static get CREATE_CAPTION(): string {
    // Create Caption
    return 'services/central/api/asset-captions';
  }

  public static CAPTION_REPORT(projectId, filter): string {
    return 'services/central/api/asset-captions' + projectId + '/' + filter;
  }

  // Advance Filter

  public static get ADVANCE_SEARCH(): string {
    // Create Caption
    return 'services/central/api/filter/advance-search';
  }

  // delivery

  public static get ADVANCE_DELIVERY(): string {
    // Create Caption
    return 'services/central/api/filter/delivery';
  }

  // Apply Exif
  public static get APPLY_EXIF(): string {
    // Create Caption
    return 'services/central/api/assets/exif';
  }

  public static ADVANCE_SEARCH_BACK(uuid): string {
    return 'services/central/api/filter/advance-search/' + uuid;
  }

  // Custom Filter - Tagge With
  public static FILTER_TAG_WITH(projectId, tagID): string {
    return (
      'services/central/api/filter/' +
      projectId +
      '/tag-with/' +
      tagID +
      '/assets'
    );
  }

  // Custom Filter - Locked-Approved
  public static FILTER_LOCKED_APPROVED(projectId): string {
    return (
      'services/central/api/filter/' + projectId + '/assets/locked-approved'
    );
  }

  // Custom Filter - Data Tagging
  public static NON_SUBMITTED(projectId, tagView): string {
    return (
      'services/central/api/tagging/non-submitted/' + projectId + '/' + tagView
    );
  }

  public static NON_TAGGED(projectId, tagView): string {
    return (
      'services/central/api/tagging/non-tagged/' + projectId + '/' + tagView
    );
  }

  public static GENERAL_FILTER(projectId, filterName): string {
    return (
      'services/central/api/filter/' + projectId + '/assets/' + filterName + '/'
    );
  }

  public static HIDDEN_TAGGING(projectId): string {
    return 'services/central/api/filter/' + projectId + '/hidden/assets';
  }

  public static UN_PUBLISHED(projectId): string {
    return 'services/central/api/tagging/unpublished/' + projectId;
  }

  public static PUBLISHED(projectId): string {
    return 'services/central/api/tagging/published/' + projectId;
  }

  // Tagging
  public static get TAGGING_START(): string {
    return 'services/central/api/tagging/start';
  }

  public static get TAGGING_CONFIRM(): string {
    return 'services/central/api/tagging/confirm';
  }

  public static get TAGGING_STOP(): string {
    return 'services/central/api/tagging/stop';
  }

  public static get TAGGING_IMAGES(): string {
    return 'services/central/api/tagging/preliminary-tag';
  }

  public static get TAGGING_SUBMIT_FOR_REVIEW(): string {
    return 'services/central/api/tagging/review';
  }

  public static GET_ASSET_BY_TAGID(tagID, projectId, tagview): string {
    return (
      'services/central/api/tagging/filter/' +
      tagID +
      '/' +
      projectId +
      '/' +
      tagview
    );
  }

  // Audit Conflicts
  public static AUDIT_START(projectId): string {
    return 'services/central/api/audit/start/' + projectId;
  }

  public static get AUDIT_TAG() {
    return 'services/central/api/audit/tag';
  }

  // Audit NO Conflicts
  // Audit clear tags
  public static get CLEAR_TAGS(): string {
    return 'services/central/api/audit/clear-tags';
  }

  // Audit clear tag
  public static get CLEAR_TAG(): string {
    return 'services/central/api/audit/clear-tag';
  }

  // Audit Publish Images
  public static get PUBLISH_IMAGES(): string {
    return 'services/central/api/audit/publish';
  }

  public static get PUBLISHS_IMAGES(): string {
    return 'services/central/api/audit/publishs';
  }
  // Audit Hide
  public static get HIDE_IMAGES(): string {
    return 'services/central/api/assets/hide';
  }

  public static get CONVERT_ASSET_TYPES(): string {
    return 'services/central/api/assets/convertType';
  }

  public static get UN_HIDE_IMAGES(): string {
    return 'services/central/api/assets/unhide';
  }

  // get ImageExclusive List
  public static IMAGE_EXCLUSIVES_LIST(projectId): string {
    return 'services/central/api/exclusives/project/' + projectId;
  }

  public static ADD_IMAGE_EXCLUSIVES_LABEL(): string {
    return 'services/central/api/exclusives/';
  }

  public static SUBMIT_IMAGE_EXCLUSIVES_FORM(): string {
    return 'services/central/api/exclusive-assets/';
  }

  public static ADD_IMAGE_EXCLUSIVES_LABEL_RELEASE(): string {
    return 'services/central/api/exclusive-assets/release/';
  }

  // get ImageExclusiveHistory List
  public static EXCLUSIVES_HISTORY(): string {
    return 'services/central/api/exclusive-assets/history/';
  }

  // Administration Batch processing
  public static get UPDATE_BATCH(): string {
    return 'services/central/api/batches/management';
  }

  public static DELETE_BATCH(id, projectId): string {
    return 'services/central/api/batches/management/' + id + '/' + projectId;
  }

  public static get DELETE_ASSETS(): string {
    return 'services/central/api/assets';
  }

  public static get SAVE_FAV_SEQ(): string {
    return 'services/central/api/project-user-relations/fav/sequence';
  }

  public static get UPDATE_FAV(): string {
    return 'services/central/api/project-user-relations/fav';
  }

  public static get PROJECT_LIST(): string {
    return 'services/central/api/projects/lists';
  }

  public static PROJECT_LIST_WITH_PAGE(pageNumber: number): string {
    return 'services/central/api/projects/lists?page=' + pageNumber;
  }

  // Retouch
  public static RETOUCH_DRAWS_GET(projectId, assetsId): string {
    return (
      'services/central/api/retouch/draws/projects/' +
      projectId +
      '/assets/' +
      assetsId
    );
  }

  public static RETOUCH_DRAWS(): string {
    return 'services/central/api/retouch/draws';
  }

  public static RETOUCH_COMMENTS_GET(projectId, assetsId): string {
    return (
      'services/central/api/retouch/comments/projects/' +
      projectId +
      '/assets/' +
      assetsId
    );
  }

  public static RETOUCH_COMMENTS(): string {
    return 'services/central/api/retouch/comments';
  }

  public static USER_DETAILS(projectId): string {
    return `services/central/api/contacts/${projectId}/full-name/`;
  }

  public static ASSET_TRANSACTION_DETAILS(assetId: number): string {
    return `asset/transaction-details/${assetId}`;
  }

  public static get VIEW_SETTING(): string {
    return 'services/central/api/project-user-relations/view-setting';
  }

  public static TALENT_MANAGEMENT(id: any): string {
    return 'services/central/api/project-roles/project/' + id;
  }

  public static UPDATE_TALENT_MANAGEMENT(id: any): string {
    return 'services/central/api/project-roles/project/?projectId=' + id;
  }

  public static get TALENT_ACTION_REJECT(): string {
    return 'services/central/api/assets/talent-action';
  }

  public static get TALENT_FINALIZE(): string {
    return 'services/central/api/assets/talent-finalize';
  }

  public static get EXCLUDE_RELEASE(): string {
    return 'services/central/api/assets/exclude-release';
  }

  public static get ASSETS_TOGGLE(): string {
    return 'services/central/api/assets/toggle';
  }

  public static GET_PRIORITY_PIX_COUNT(projectId): string {
    return 'services/central/api/filter/' + projectId + '/assets/prioritypix';
  }

  public static GET_BOOKMARKDATA(id: any): string {
    return 'services/central/api/bookmarks/' + id;
  }

  public static get CREATE_BOOKMARK(): string {
    return 'services/central/api/bookmarks';
  }

  public static GET_ACTOR_APPROVAL_REPORTS(projectId, actorID): string {
    return (
      'services/central/api/project-roles/' +
      projectId +
      '/approval-report/' +
      actorID
    );
  }
  // printtool
  public static PRINT_TOOL(): string {
    return 'asset/pdf';
  }

  public static IMAGE_VIDEO_URL(data: any): string {
    return (
      'services/central/api/assets/' +
      data.id +
      '/project/' +
      data.projectId +
      '/video'
    );
  }

  public static get SEND_EMAIL_NOTIFICATIONS(): string {
    return 'services/central/api/albums/wide-edit/send-notification';
  }

  public static get WIDE_EDIT_ADVANCE_SEARCH(): string {
    return 'services/central/api/filter/wide-edit';
  }

  public static HISTORY_DATA(projectId, assetID, historyReportType): string {
    if (historyReportType === 'exclusive') {
      return (
        'services/central/api/exclusive-assets/history/project/' +
        projectId +
        '/asset/' +
        assetID
      );
    } else {
      return (
        'services/central/api/history/project/' +
        projectId +
        '/asset/' +
        assetID
      );
    }
  }

  public static ACTORID_REPORT(projectId): string {
    return (
      'services/central/api/project-roles/' + projectId + '/approval-id-report'
    );
  }

  public static get I_AM_DONE(): string {
    return 'services/central/api/albums/wide-edit/i-m-done';
  }

  public static get BUG_REPORT(): string {
    return 'services/central/api/bug-report/server';
  }

  // Make Cover only for wdtvphotos
  public static get MAKE_COVER(): string {
    return 'services/central/api/batches/cover-image';
  }

  // Secure image with encryption

  public static get SZ_SECURE_WEB_IMAGE_US(): string {
    return 'web-image/pko/';
  }

  public static get SZ_NON_SECURE_WEB_IMAGE_US(): string {
    return 'web-image/pko-m/';
  }

  // Non secure without encryption
}
