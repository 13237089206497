import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AlbumsService } from '../../albums/albums.service';
import { SidebarService } from '../../../../../sidebar-service/sidebar-service';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdvanceImageCorrectComponent } from './advanceImage-correct/advanceImage-correct.component';
import { AssetInfo } from '../../../../../../../shared/models/assetInfo.model';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { TaggerService } from '../../../../../../../shared/services/tagger.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { UserService } from '../../../../../../../shared/services/user.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { Utils } from '../../../../../../../shared/utils/utils';
import { SortByPipe } from '../../../../../../../shared/pipe/sort-by.pipe';
import { UserRole } from '../../../../../../../shared/enum/user-role.enum';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../../shared/store/selected-images-store';
import { SelectItemGroup } from '../../../../../../../shared/models/select-item';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-advanceimage-tagging',
  templateUrl: './advanceimage-tagging.component.html',
  styleUrls: ['./advanceimage-tagging.component.scss'],
})
export class AdvanceimageTaggingComponent
  extends UnsubscriberComponent
  implements OnInit, OnDestroy
{
  gotoSettings: any;
  selectedValue: any;
  imageName: any;
  imageId: any;
  customAlbums: any;
  publishedTags: any;
  isImageSelected: boolean;
  dataTaggingWith: any;
  imageTagging: any[] = [];
  presentImages: any[] = [];
  albumList = [];
  wideEditAlbumList = [];

  imageNameList: any;
  notExistFileInRecord = [];

  taggingForm: UntypedFormGroup;
  selectedItem: string;
  commonImagesName: any[] = [];
  xv: any[];
  selectedoption = null;
  selectedAlbumid: string;
  regex = /\r?\n/g;
  assetInfoDTOList: Array<any>;
  cloneUnkownImageList: Array<any> = [];
  isCaseSensitive = false;
  selectedDropDownValue = 'Select';
  isShow = false;
  actorList: Array<any> = [];
  selectedFilterType = '';
  projectImageData: AssetInfo = new AssetInfo();
  projectAuthority: string;
  userAuthority: string;
  groupedAlbumActor: SelectItemGroup[] = [
    { label: '--------------Album---------------', items: [] },
    { label: '--------------Actor---------------', items: [] },
  ];
  groupedAlbumForWideEdit: SelectItemGroup[] = [
    { label: '--------------Album---------------', items: [] },
  ];
  constantsGlobalData: any;

  currentPageNumber: any;
  projectImageIds: any;
  noOfImages: number;
  comingFromWideEdit = false;

  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private modalService: Modalstatusservice,
    private albumsService: AlbumsService,
    private viewProjectService: ViewProjectService,
    private taggerService: TaggerService,
    private loaderService: LoaderService,
    private userService: UserService,
    private sidebarService: SidebarService,
    private dialogService: DialogService,
    private selectedImagesStore: SelectedImagesStore,
    private dialogRef: MatDialogRef<AdvanceimageTaggingComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    super();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.groupedAlbumActor = [];
    this.taggingForm = this.fb.group({
      prefix: [''],
      pad: [''],
      append: [''],
      unkownImageList: ['', Validators.required],
      selectedAlbumActor: ['', Validators.required],
    });

    if (this.data) {
      this.comingFromWideEdit = this.data.comingFromWideEdit;
    } else {
      this.comingFromWideEdit = false;
    }

    this.viewProjectService.wideEditAlbumsInfoGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (albumList.albums) {
          albumList = albumList.albums;
        }
        if (!Utils.isObjectEmpty(albumList)) {
          this.wideEditAlbumList = Utils.getValuesByKey(albumList, 'id');
          this.wideEditAlbumList = SortByPipe.prototype.transform(
            this.wideEditAlbumList,
            'value',
          );
          this.wideEditAlbumList = this.wideEditAlbumList
            .filter((item) => item && item.value)
            .map((item) => {
              return {
                label: item.value,
                value: { item: item, type: 'album' },
              };
            });
        } else {
          this.wideEditAlbumList = [];
        }

        this.groupedAlbumForWideEdit[0] = {
          label: '--------------Album---------------',
          items: this.wideEditAlbumList,
        };
      });

    this.sidebarService.updatedAlbumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (albumList?.albums) {
          albumList = albumList.albums;
        }
        if (!Utils.isObjectEmpty(albumList)) {
          this.albumList = Utils.getValuesByKey(albumList, 'id');
          this.albumList = SortByPipe.prototype.transform(
            this.albumList,
            'value',
          );
          this.albumList = this.albumList
            .filter((item) => item && item.value)
            .map((item) => {
              return {
                label: item.value,
                value: { item: item, type: 'album' },
              };
            });
        } else {
          this.albumList = [];
        }
        this.groupedAlbumActor[0] = {
          label: '--------------Album---------------',
          items: this.albumList,
        };
      });

    this.sidebarService.albumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.albumList = Utils.getValuesByKey(albumList.albums, 'id');
          this.albumList = this.albumList
            .filter((item) => item && item.value)
            .map((item) => {
              return {
                label: item.value,
                value: { item: item, type: 'album' },
              };
            });
          this.albumList = SortByPipe.prototype.transform(
            this.albumList,
            'label',
          );
        } else {
          this.albumList = [];
        }
        this.groupedAlbumActor[0] = {
          label: '--------------Album---------------',
          items: this.albumList,
        };
      });

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority) => {
        this.userAuthority = userAuthority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.noOfImages = res.viewSettingDTO.imagePerPage;
          this.groupedAlbumActor[1] = null;
          this.actorList =
            res.projectTagListDTO &&
            res.projectTagListDTO.map((item) => {
              return {
                label: item.tagName,
                value: { item: item, type: 'actor' },
              };
            });

          if (this.userAuthority === UserRole.ROLE_ADMIN) {
            this.groupedAlbumActor[1] = {
              label: '--------------Actor---------------',
              items: this.actorList,
            };
          } else {
            this.groupedAlbumActor[1] = {
              label: '',
              items: [],
            };
          }
        }
      });

    this.viewProjectService.projectImageIdsGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageIds) => {
        this.projectImageIds = imageIds;
      });

    this.viewProjectService.projectDataGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.projectImageData = data;
        }
      });
  }

  format() {
    const prefix = this.taggingForm.value.prefix
      ? this.taggingForm.value.prefix
      : '';
    const pad = this.taggingForm.value.pad ? this.taggingForm.value.pad : '';
    const append = this.taggingForm.value.append
      ? this.taggingForm.value.append
      : '';
    const processedFilenameArray = [];
    const unkownImageList = this.taggingForm.value.unkownImageList
      ? this.taggingForm.value.unkownImageList
      : '';
    const filenameArray = unkownImageList.split('\n');

    for (let f = 0; f < filenameArray.length; f++) {
      let ppText = '';
      let apText = '';
      let isAppend = false;
      if (prefix && prefix.trim()) {
        ppText = prefix;
      }
      if (append && append.trim()) {
        if (append.split('.').length > 1) {
          apText = append;
        } else {
          apText = '.' + append;
        }
        isAppend = true;
      }

      if (pad && pad.trim().toString() === '') {
        processedFilenameArray[f] =
          ppText + filenameArray[f].trim() + apText + '\n';
      } else {
        processedFilenameArray[f] =
          ppText +
          this.paddingStringView(
            filenameArray[f].trim(),
            pad.length,
            pad.charAt(0),
            true,
          ) +
          apText;
      }
    }
    this.taggingForm.patchValue({
      unkownImageList: processedFilenameArray.join('\n'),
    });
  }

  paddingStringView(s, n, c, paddingLeft) {
    const strLength = s.length;
    if (n > 0 && n > strLength) {
      for (let i = 0; i <= n; i++) {
        if (paddingLeft) {
          if (i < n - strLength) {
            s = c.concat(s);
          }
        }
      }
    }
    return s.toString();
  }

  // New code shivaji start
  getApply() {
    let message = '';
    if (this.gotoSettings.type === 'album') {
      // Need i18n
      message = 'Do you want to add to this album?';
    } else {
      // Need i18n
      message = 'Do you want to Continue tagging?';
    }

    this.dialogService
      .openConfirmationDialog({
        message: message,
        title: 'Confirm',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: boolean) => {
        if (state) {
          this.prepareDataForadvanceTagging();
        }
      });
  }

  prepareDataForadvanceTagging() {
    const getFreshArray = this.taggingForm.value.unkownImageList.split('\n');
    const imageNameArray = [];
    getFreshArray.forEach((element) => {
      imageNameArray.push(element.replace('JPG', 'jpg'));
    });
    const unknowImageList = imageNameArray.filter((str) => {
      return str !== '';
    });
    if (unknowImageList.length !== 0) {
      this.currentPageNumber =
        this.viewProjectService.getCurrentPageNumberClone();
      const dataForTagging = {
        assetNames: unknowImageList,
        caseSensitive: this.isCaseSensitive,
        filter: this.selectedFilterType,
        projectId: this.viewProjectService.getProjectId(),
        currentAssetIds: Utils.getImageIdsBasedOnPage(
          this.currentPageNumber,
          this.noOfImages,
          this.projectImageIds,
        ),
        trash: false,
      };
      this.loaderService.displayLoader(true);
      this.viewProjectService
        .doAdvanceTagging(dataForTagging)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          this.loaderService.displayLoader(false);

          if (!response.status) {
            if (this.gotoSettings.type === 'album') {
              if (response.t) {
                const messageSeparate = response.message.split('|');

                if (!this.comingFromWideEdit) {
                  response.message = messageSeparate[1];
                  response.status = true;
                  this.updateCustomeAlbumsID(
                    this.gotoSettings.id,
                    response.t,
                    imageNameArray,
                  );
                  this.sidebarService.setAdvanceImageToAlbumID(
                    this.gotoSettings.id,
                  );
                  this.sidebarService.setUpdatedTaggingAlbum(response);
                } else {
                  response.message = messageSeparate[1];
                  response.status = true;
                  this.sidebarService.setAdvanceImageToWideEditAlbumID(
                    this.gotoSettings.id,
                  );
                  this.sidebarService.setUpdatedTaggingWideEdit(response);
                }
                const imagesList = messageSeparate[0].split(',');
                this.dialogService.open(AdvanceImageCorrectComponent, {
                  panelClass: [
                    'fs-dialog-panel',
                    'fs-dialog-size-lg-panel',
                    'fs-dialog-link-panel',
                  ],
                  data: imagesList,
                });
                this.dialogService.openInformationDialog({
                  title: this.translate.instant('view-project.confirm22'),
                  message: 'Operation performed successfully',
                });
              } else {
                const messageSeparate = response.message.split('|');
                let imagesList;
                if (messageSeparate.length > 1) {
                  imagesList = messageSeparate[0].split(',');
                } else {
                  imagesList = response.message.split(',');
                }
                this.dialogService.open(AdvanceImageCorrectComponent, {
                  panelClass: [
                    'fs-dialog-panel',
                    'fs-dialog-size-lg-panel',
                    'fs-dialog-link-panel',
                  ],
                  data: imagesList,
                });
              }
            } else {
              const images = response.message.split(',');
              this.dialogService.open(AdvanceImageCorrectComponent, {
                panelClass: [
                  'fs-dialog-panel',
                  'fs-dialog-size-lg-panel',
                  'fs-dialog-link-panel',
                ],
                data: images,
              });
            }
          } else {
            if (this.gotoSettings.type === 'album') {
              if (!this.comingFromWideEdit) {
                this.updateCustomeAlbumsID(
                  this.gotoSettings.id,
                  response.t,
                  imageNameArray,
                );
                this.sidebarService.setAdvanceImageToAlbumID(
                  this.gotoSettings.id,
                );
                this.sidebarService.setUpdatedTaggingAlbum(response);
              } else {
                this.sidebarService.setAdvanceImageToWideEditAlbumID(
                  this.gotoSettings.id,
                );
                this.sidebarService.setUpdatedTaggingWideEdit(response);
              }
              this.selectedImagesStore.clear();
            }
            this.dialogService.openInformationDialog({
              title: this.translate.instant('view-project.confirm22'),
              message: 'Operation performed successfully',
            });
          }
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    }
  }

  isCaseSensitiveEvent(e) {
    this.isCaseSensitive = e.checked;
  }

  updateCustomeAlbumsID(id, isAdd, imageList) {
    const updatedSelectedImages = [];
    isAdd = isAdd.tag;
    for (let i = 0; i < imageList.length; i++) {
      const index = this.projectImageData.assetInfoDTOList
        .map(function (e) {
          return e.name.toLowerCase();
        })
        .indexOf(imageList[i].toLowerCase());

      if (index !== -1) {
        if (isAdd) {
          this.projectImageData.assetInfoDTOList[index].customAlbums.push(id);
          updatedSelectedImages.push(
            this.projectImageData.assetInfoDTOList[index],
          );
        } else {
          const customeAlbumIndex: number =
            this.projectImageData.assetInfoDTOList[index].customAlbums.indexOf(
              id,
            );
          if (customeAlbumIndex !== -1) {
            this.projectImageData.assetInfoDTOList[index].customAlbums.splice(
              customeAlbumIndex,
              1,
            );
            updatedSelectedImages.push(
              this.projectImageData.assetInfoDTOList[index],
            );
          }
        }
      } else {
        const data = {
          id: imageList[i].id,
        };
        updatedSelectedImages.push(data);
      }
    }

    this.selectedImagesStore.set(updatedSelectedImages);
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  reset() {
    this.cloneUnkownImageList = [];
    this.gotoSettings = null;
    this.selectedDropDownValue = 'Select';
    this.taggingForm.reset();
    this.taggingForm.value.prefix = '';
    this.taggingForm.value.unkownImageList = '';
    this.taggingForm.value.pad = '';
    this.taggingForm.value.append = '';
    this.isCaseSensitive = false;
  }

  closeWindow() {
    this.reset();
    this.dialogRef.close();
  }

  selectAlbumOrActor(obj, name, type) {
    if (type === 'actor') {
      this.selectedFilterType = 'taggedwith:' + obj.id;
    } else {
      this.selectedFilterType = type + ':' + obj.id;
    }

    this.gotoSettings = { ...obj, type: type };
    this.selectedDropDownValue = name;
    this.isShow = false;
  }

  selectedItems(selectedItem) {
    const selected = selectedItem;
    this.selectAlbumOrActor(
      selected.item,
      selected.item?.tagName,
      selected.type,
    );
  }

  ngOnDestroy() {
    this.reset();
    super.ngOnDestroy();
  }
}
