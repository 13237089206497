import { Directive, Injectable, EventEmitter, Output } from '@angular/core';
import { SelectedImages } from '../../../../../shared/models/selectedImages.model';
import { AssetSoloGroupModel } from '../../../../../shared/models/assetInfo.model';

@Directive()
@Injectable()
export class GroupService {
  @Output() enableGroupGlobal: EventEmitter<any> = new EventEmitter();

  setStatusOfGroup(status) {
    this.enableGroupGlobal.emit(status);
  }

  getExcludedSelectedImages(selectedImage: AssetSoloGroupModel[], userId: number) {
    return selectedImage.filter((item) =>
      (item.excludedFrom).includes(userId),
    );
  }

  getNotExcludedSelectedImages(selectedImage: AssetSoloGroupModel[], userId: number) {
    return selectedImage.filter(
      (item) =>
        (item.publishedTags).includes(userId) &&
        !(item.excludedFrom).includes(userId),
    );
  }
}
