import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImageexclusivesService } from '../image-exclusives.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ProjectDetailPermission } from '../../../../../../../../shared/models/projectpermission.model';
import { Modalstatusservice } from '../../../../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../../../../shared/services/view-project.service';
import { ConstantsGlobal } from '../../../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from '../../../../../../../../shared/services/dialog/dialog.service';
import { UnsubscriberComponent } from '../../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../../shared/services/api-error-handler.service';
import {LoaderService} from '../../../../../../../../shared/services/loader.service';

@Component({
  selector: 'fs-add-exclusives',
  templateUrl: './add-exclusives.component.html',
  styleUrls: ['./add-exclusives.component.scss'],
})
export class AddExclusivesComponent
  extends UnsubscriberComponent
  implements OnInit
{
  exclusiveDis: string;
  projectDetailPermission: ProjectDetailPermission =
    new ProjectDetailPermission();
  constantsGlobalData: any;

  constructor(
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private imageExclusive: ImageexclusivesService,
    private viewProjectService: ViewProjectService,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<AddExclusivesComponent>,
  ) {
    super();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.exclusiveDis = '';
  }

  closeWindow() {
    this.dialogRef.close();
  }

  addExclusive() {
    if (this.exclusiveDis) {
      this.imageExclusive
        .addExclusiveLabel({
          projectId: this.viewProjectService.getProjectId(),
          value: this.exclusiveDis,
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          const customArr = this.imageExclusive.getExClusiveUserListData();
          customArr.push({
            value: res.id,
            label: res.value,
          });
          this.projectDetailPermission =
            this.viewProjectService.mergeWithProjectDetailPermissionData(
              this.projectDetailPermission,
            );
          const customArr1 = this.projectDetailPermission.exclusiveListDTO;
          customArr1.push({
            value: res.id,
            label: res.value,
          });
          this.projectDetailPermission.exclusiveListDTO = customArr1;
          this.imageExclusive.setExClusiveUserListData(customArr);
          this.closeWindow();
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    } else {
      this.alertCustom(this.translate.instant('view-project.alert14'));
    }
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
