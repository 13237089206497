import { Component, HostListener, OnInit } from '@angular/core';
import { BatchManagementService } from './batch-management.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../../../../../shared/services/snack-bar.service';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../../../../../../../shared/utils/utils';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

enum RadioValue {
  UPDATE,
  DELETE,
}

interface BatchValue {
  value: number;
  label: string;
}

@Component({
  selector: 'fs-batch-management',
  templateUrl: './batch-management.component.html',
  styleUrls: ['./batch-management.component.scss'],
})
export class BatchManagementComponent
  extends UnsubscriberComponent
  implements OnInit
{
  batchListData: Array<BatchValue>;
  radioValue: any = RadioValue;
  batchModelData: any;
  projectDetailPermission: any;
  constantsGlobalData: any;

  constructor(
    private batchManagement: BatchManagementService,
    private viewProjectService: ViewProjectService,
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private snackBarService: SnackBarService,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<BatchManagementComponent>,
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    evt: KeyboardEvent,
  ) {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.batchModelData = {};
    this.batchModelData.operationType = this.radioValue[0];

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.projectDetailPermission = res;
          this.batchListData = this.projectDetailPermission.batchListDTO;
        }
      });
  }

  confirmAction() {
    if (this.batchModelData.operationType === this.radioValue[0]) {
      this.updateBatch();
    } else if (this.batchModelData.operationType === this.radioValue[1]) {
      this.deleteConfirm();
    }
  }

  deleteConfirm() {
    this.dialogService
      .openConfirmationDialog({
        message: this.translate.instant('view-project.confirm1'),
        title: this.translate.instant('view-project.confirm2'),
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: boolean) => {
        if (state) {
          this.deleteBatch();
        }
      });
  }

  updateBatch() {
    const editData = {
      existingLabel: this.batchModelData.selectedBatch.label,
      id: this.batchModelData.selectedBatch.value,
      newLabel: this.batchModelData.updatedLabel,
      projectId: this.viewProjectService.getProjectId(),
    };
    this.loaderService.displayLoader(true);
    this.batchManagement
      .updateBatch(editData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            const index = this.batchListData.findIndex(
              (item) => item.value === this.batchModelData.selectedBatch.value,
            );
            this.batchListData[index].label = this.batchModelData.updatedLabel;

            this.updateData(this.batchListData);

            this.closeWindow();
            this.loaderService.displayLoader(false);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  onChangeBatchModelData(event) {
    this.batchModelData.selectedBatch = event;
  }

  deleteBatch() {
    const dataObj = {
      id: this.batchModelData.selectedBatch.value,
      projectId: this.viewProjectService.getProjectId(),
    };
    this.loaderService.displayLoader(true);
    this.batchManagement
      .deleteBatch(dataObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            this.viewProjectService.setDeleteAssetGlobal(true);
            this.closeWindow();
            this.snackBarService.showSnackBar(
              this.translate.instant(
                'left-menu.tab-bar.sidemenus.administration.batch-management.deleteSuccessMsg',
              ),
            );
          } else {
            this.snackBarService.showSnackBarError(res.message);
          }
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  updateData(updatedBatchListData) {
    this.projectDetailPermission.batchListDTO = updatedBatchListData;
    this.viewProjectService.setProjectDetailPermissionData(
      this.projectDetailPermission,
    );
  }

  closeWindow() {
    this.batchModelData = {};
    this.dialogRef.close();
  }
}
