<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">Video</div>

  <button class="fs-close-btn w-auto" data-dismiss="modal" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <div *ngIf="isVideoRender && videoUrl" class="w-100 text-center">
      <video
        class="w-100"
        autoplay="autoplay"
        preload="auto"
        controls
        controlsList="nodownload"
      >
        <source [src]="videoUrl" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <div class="d-flex justify-content-end align-items-center">
    <button class="fs-dialog-btn w-auto" (click)="closeWindow()">
      {{ 'image-history.buttons.close' | translate }}
    </button>
  </div>
</div>
