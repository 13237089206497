import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../../shared/services/api.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ViewProjectService } from '../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../shared/services/loader.service';
import { Utils } from '../../../../shared/utils/utils';
import { DialogService } from '../../../../shared/services/dialog/dialog.service';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable()
export class ViewGalleryService {
  private setups = new BehaviorSubject<any>({});
  public setupsGlobal = this.setups.asObservable();
  private totalGalleryImage = new BehaviorSubject<number>(0);
  public totalGalleryImageGlobal = this.totalGalleryImage.asObservable();

  setUpData1: any;

  constructor(
    private apiService: ApiService,
    private viewProjectService: ViewProjectService,
    private router: Router,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
  ) {}

  setTotalGalleryImage(total) {
    this.totalGalleryImage.next(total);
  }

  getSetUpData() {
    return this.setups.value;
  }

  setSetUpData(data) {
    this.setups.next(data);
  }

  getSetUpDataApiCall(path): Observable<any> {
    return this.apiService.get(path).pipe(
      map((data) => {
        this.setSetUpData(data);
        return data;
      }),
    );
  }

  findCurrentSetUpId(setUpID: number) {
    let currentSetUpIndex = -1;
    this.setUpData1 = this.getSetUpData();
    this.setUpData1.setupDTOList.forEach((obj, i) => {
      if (setUpID === obj.value) {
        currentSetUpIndex = i + 1;
      }
    });
    return currentSetUpIndex;
  }

  callPriorityPixFromGalleryView(projectId, noOfImages) {
    this.loaderService.displayLoader(true);
    const prioritypixFilter = 'prioritypix';
    return this.viewProjectService
      .callProjectImageIDs(projectId, prioritypixFilter)
      .pipe(
        switchMap((res1) => {
          if (res1 !== null) {
            this.viewProjectService.setCurrentFilter(prioritypixFilter);
            this.viewProjectService.setPriorityPixAssetIds(res1);
            const assetsIds = Utils.getImageIdsBasedOnPage(1, noOfImages, res1);
            return this.viewProjectService
              .callAssetsInfoBasedOnAssetsId({
                assetIds: assetsIds,
                projectId: projectId,
                filter: prioritypixFilter,
              })
              .pipe(
                tap(
                  (resData) => {
                    if (resData.assetInfoDTOList.length) {
                      this.viewProjectService.isPiorityPixDataLoaded.next(true);
                      this.loaderService.displayLoader(false);
                      this.router.navigate([
                        Utils.prepareFilterNavigationURL(
                          this.viewProjectService.getProjectRole(),
                          this.viewProjectService.getProjectId(),
                          'prioritypix',
                          1,
                        ),
                      ]);
                    } else {
                      this.alertCustom(
                        this.translate.instant('view-project.alert2'),
                      );
                    }
                    this.loaderService.displayLoader(false);
                  },
                  () => {
                    this.loaderService.displayLoader(false);
                  },
                ),
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(this.translate.instant('view-project.alert2'));
          }
        }),
        catchError(() => {
          this.loaderService.displayLoader(false);
          return throwError('');
        }),
      );
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
