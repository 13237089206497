import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettings } from '../../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../../shared/services/api.service';

@Injectable()
export class FsDeliveryService {
  constructor(private apiService: ApiService) {}

  postAdvanceSearch(obj): Observable<any> {
    const path = AppSettings.ADVANCE_SEARCH;
    return this.apiService.post(path, obj).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  postAdvanceDelivery(obj): Observable<any> {
    const path = AppSettings.ADVANCE_DELIVERY;
    return this.apiService.post(path, obj).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getAdvanceSearchBack(uuid): Observable<any> {
    const path = AppSettings.ADVANCE_SEARCH_BACK(uuid);
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }
}
