<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{ 'top-menu.expressDownload.exclusiveOrSensitiveTitle' | translate }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content class="d-flex flex-column">
  <div class="fs-md-txt">
    {{ 'top-menu.expressDownload.error.exclusiveOrSensitiveAsset' | translate }}
  </div>

  <div class="mt-3">
    {{ 'top-menu.expressDownload.descriptions.exclusive' | translate }}
  </div>

  <div class="w-100 fs-images-container">
    <cdk-virtual-scroll-viewport
      class="fs-virtual-scroll-content-margin fs-virtual-scroll-viewport-default"
      minBufferPx="30"
      [class.fs-virtual-scroll-viewport-max-height]="extractAssets.length >= 5"
      maxBufferPx="300"
      itemSize="30"
    >
      <div
        class="d-flex align-items-center pl-1 pr-1 align-content image-card-container flex-column"
        *cdkVirtualFor="let selectedImage of extractAssets; index as ind"
      >
        <div class="is-img">
          <img
            class="fs-image-container"
            [lazySrc]="viewProjectService.getWebImageURL(selectedImage, '125')"
            alt=""
          />
        </div>
        <div class="is-name">{{ selectedImage.name }}</div>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</div>

<div mat-dialog-actions class="mf-sngle d-flex justify-content-end">
  <ng-container>
    <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
      {{ 'top-menu.expressDownload.buttons.close' | translate }}
    </button>
    <button class="fs-dialog-btn w-auto ml-3" (click)="onOpenExpressDownload()">
      {{ 'top-menu.expressDownload.buttons.executeExclusive' | translate }}
    </button>
  </ng-container>
</div>
