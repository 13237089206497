import { Injectable } from '@angular/core';
import { EntityStore, Store } from './entity-store';
import { SelectedImages } from '../models/selectedImages.model';
import EntityState = Store.EntityState;
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SelectedImagesStore extends EntityStore<SelectedImages> {
  constructor() {
    super();
  }

  headItem$: Observable<SelectedImages> = this.select().pipe(
    map((state: EntityState<SelectedImages>) => state.ids),
    map((ids: number[]) => ids[0]),
    map((headItemId: number | undefined) =>
      headItemId === undefined ? null : this.getEntity(headItemId),
    ),
    distinctUntilChanged(),
  );

  getItemsIds(): number[] {
    return this.getState().ids as number[];
  }

  hasEntities(): boolean {
    return Boolean(this.size());
  }

  getHeadItem(): SelectedImages {
    const state = this.getState();
    const headId = state.ids[0];
    return headId ? this.getEntity(headId) : null;
  }

  getTailItem(): SelectedImages {
    const state = this.getState();
    const tailId = state.ids[state.ids.length - 1];
    return tailId ? this.getEntity(tailId) : null;
  }
}
