<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.taggingview-1.conflicts'
        | translate
    }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content class="d-flex flex-wrap">
  <div
    [ngClass]="{ 'd-none': i % 3 != 0 }"
    class="mb-2 mx-1"
    *ngFor="let taglist of taggingList; let i = index"
  >
    <div *ngIf="i % 3 == 0" class="d-block">
      <div
        *ngIf="i < taggingList.length"
        class="fs-txt-white-dark-color my-1 fs-txt-white-grey-color-hover fs-tiny-txt fs-cursor-pointer mx-1"
      >
        <span>{{ taggingList[i].hotkey }}</span
        >={{ taggingList[i].tagName }}({{ taggingList[i].imageCount }})
      </div>

      <div
        *ngIf="i + 1 < taggingList.length"
        class="fs-txt-white-dark-color my-1 fs-txt-white-grey-color-hover fs-tiny-txt fs-cursor-pointer mx-1"
      >
        <span>{{ taggingList[i + 1].hotkey }}</span
        >={{ taggingList[i + 1].tagName }}({{ taggingList[i + 1].imageCount }})
      </div>

      <div
        *ngIf="i + 2 < taggingList.length"
        class="fs-txt-white-dark-color my-1 fs-txt-white-grey-color-hover fs-tiny-txt fs-cursor-pointer mx-1"
      >
        <span>{{ taggingList[i + 2].hotkey }}</span
        >={{ taggingList[i + 2].tagName }}({{ taggingList[i + 2].imageCount }})
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end" class="d-flex align-content-center">
  <div>
    <button
      class="fs-dialog-btn w-auto ml-3"
      data-dismiss="modal"
      (click)="closeWindow()"
    >
      {{
        'left-menu.tab-bar.sidemenus.administration.taggingview-1.button.close'
          | translate
      }}
    </button>
  </div>

  <div class="fs-text-color-dark-green ml-3 font-weight-bold">
    {{
      'left-menu.tab-bar.sidemenus.administration.taggingview-1.labels.label2'
        | translate
    }}
  </div>
</div>
