import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './auth/auth.component';
import { ErrorPageComponent } from './errorPage/error-page.component';
import { ReloadGuard } from './shared/services/reload-guard.service';
import { AuthGuard } from './shared/services/auth-guard.service';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: AuthComponent },
  // {   path: '', pathMatch: 'full', redirectTo: 'login' }, // todo uncomment this when remove # from all BE verifications
  // {   path: 'login', component: AuthComponent }, // todo uncomment this when remove # from all BE verifications
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
    canDeactivate: [ReloadGuard],
  },
  { path: '404', component: ErrorPageComponent },
  { path: '**', redirectTo: '/404' },
];

// todo set useHash: false when remove # from all verifications
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRouting {}
