import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

export interface AppStateData {
  data?: any;
}

export interface AppStateModelIsLoadedData extends AppStateData {
  loaded: boolean;
}

export interface AppStateActive extends AppStateData {
  active: boolean;
}

@Injectable({ providedIn: 'root' })
export class AppStateService {
  private noConflicts: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private bypass2ndtagger: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private showAll: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private conflicts: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private goTOFileName: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private goTOAlbumActor: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private goTOPage: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private selectAll: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private deSelectAll: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private noAuditImageAvailable: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private advanceSearchCustomFilter: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private restartToShowAll: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private zoomDialog: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private closeZoomDialog: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private leftMenuAlbums: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private rejectDialog: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private approveDialog: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private compare: Subject<AppStateActive> = new Subject<AppStateActive>();
  private renameAlbum: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private addAlbum: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private deleteAlbum: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private editAlbum: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private editPermissionAlbum: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();
  private displayCommonDialog: Subject<AppStateModelIsLoadedData> =
    new Subject<AppStateModelIsLoadedData>();

  get noConflicts$() {
    return this.noConflicts.asObservable();
  }

  get compare$() {
    return this.compare.asObservable();
  }

  get restartToShowAll$() {
    return this.restartToShowAll.asObservable();
  }

  get renameAlbum$() {
    return this.renameAlbum.asObservable();
  }

  get addAlbum$() {
    return this.addAlbum.asObservable();
  }

  get deleteAlbum$() {
    return this.deleteAlbum.asObservable();
  }

  get editPermissionAlbum$() {
    return this.editPermissionAlbum.asObservable();
  }

  get editAlbum$() {
    return this.editAlbum.asObservable();
  }

  get zoomDialog$() {
    return this.zoomDialog.asObservable();
  }

  get closeZoomDialog$() {
    return this.closeZoomDialog.asObservable();
  }

  get displayCommonDialog$() {
    return this.displayCommonDialog.asObservable();
  }

  get approveDialog$() {
    return this.approveDialog.asObservable();
  }

  get rejectDialog$() {
    return this.rejectDialog.asObservable();
  }

  get leftMenuAlbums$() {
    return this.leftMenuAlbums.asObservable();
  }

  get advanceSearchCustomFilter$() {
    return this.advanceSearchCustomFilter.asObservable();
  }

  get noAuditImageAvailable$() {
    return this.noAuditImageAvailable.asObservable();
  }

  get deSelectAll$() {
    return this.deSelectAll.asObservable();
  }

  get selectAll$() {
    return this.selectAll.asObservable();
  }

  get goTOAlbumActor$() {
    return this.goTOAlbumActor.asObservable();
  }

  get goToPageName$() {
    return this.goTOPage.asObservable();
  }

  get goTOFileName$() {
    return this.goTOFileName.asObservable();
  }

  get conflicts$() {
    return this.conflicts.asObservable();
  }

  get showAll$() {
    return this.showAll.asObservable();
  }

  get bypass2ndtagger$() {
    return this.bypass2ndtagger.asObservable();
  }

  cleanAppState() {
    this.noConflicts.next({ loaded: false });
    this.bypass2ndtagger.next({ loaded: false });
    this.showAll.next({ loaded: false });
    this.conflicts.next({ loaded: false });
    this.leftMenuAlbums.next({ loaded: false });
    this.goTOFileName.next({ loaded: false });
    this.goTOAlbumActor.next({ loaded: false });
    this.selectAll.next({ loaded: false });
    this.deSelectAll.next({ loaded: false });
    this.renameAlbum.next({ loaded: false });
    this.addAlbum.next({ loaded: false });
    this.deleteAlbum.next({ loaded: false });
    this.editPermissionAlbum.next({ loaded: false });
    this.editAlbum.next({ loaded: false });
    this.displayCommonDialog.next({ loaded: false });
    this.noAuditImageAvailable.next({ loaded: false });
    this.advanceSearchCustomFilter.next({ loaded: false });
    this.restartToShowAll.next({ loaded: false });
    this.zoomDialog.next({ loaded: false });
    this.closeZoomDialog.next({ loaded: false });
    this.compare.next({ active: false });
  }

  setShowAll(state: AppStateModelIsLoadedData) {
    this.showAll.next(state);
  }

  setNoConflicts(state: AppStateModelIsLoadedData) {
    this.noConflicts.next(state);
  }

  setBypass2ndtagger(state: AppStateModelIsLoadedData) {
    this.bypass2ndtagger.next(state);
  }

  setCompare(state: AppStateActive) {
    this.compare.next(state);
  }

  setRenameAlbum(state: AppStateModelIsLoadedData) {
    this.renameAlbum.next(state);
  }

  setAddAlbum(state: AppStateModelIsLoadedData) {
    this.addAlbum.next(state);
  }

  setEditAlbum(state: AppStateModelIsLoadedData) {
    this.editAlbum.next(state);
  }

  setDeleteAlbum(state: AppStateModelIsLoadedData) {
    this.deleteAlbum.next(state);
  }

  setEditPermissionAlbum(state: AppStateModelIsLoadedData) {
    this.editPermissionAlbum.next(state);
  }

  setGoTOFileName(state: AppStateModelIsLoadedData) {
    this.goTOFileName.next(state);
  }

  setNoAuditImageAvailable(state: AppStateModelIsLoadedData) {
    this.noAuditImageAvailable.next(state);
  }

  setGoTOAlbumActor(state: AppStateModelIsLoadedData) {
    this.goTOAlbumActor.next(state);
  }

  setConflicts(state: AppStateModelIsLoadedData) {
    this.conflicts.next(state);
  }

  setDisplayCommonDialog(state: AppStateModelIsLoadedData) {
    this.displayCommonDialog.next(state);
  }

  setZoomDialog(state: AppStateModelIsLoadedData) {
    this.zoomDialog.next(state);
  }

  setCloseZoomDialog(state: AppStateModelIsLoadedData) {
    this.closeZoomDialog.next(state);
  }

  setLeftMenuAlbums(state: AppStateModelIsLoadedData) {
    this.leftMenuAlbums.next(state);
  }

  setGoToPage(state: AppStateModelIsLoadedData) {
    this.goTOPage.next(state);
  }

  setApproveDialog(state: AppStateModelIsLoadedData) {
    this.approveDialog.next(state);
  }

  setRejectDialog(state: AppStateModelIsLoadedData) {
    this.rejectDialog.next(state);
  }

  setSelectAll(state: AppStateModelIsLoadedData) {
    this.selectAll.next(state);
  }

  setAdvanceSearchCustomFilter(state: AppStateModelIsLoadedData) {
    this.advanceSearchCustomFilter.next(state);
  }

  setDeselectAll(state: AppStateModelIsLoadedData) {
    this.deSelectAll.next(state);
  }
}
