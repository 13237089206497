<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="d-flex justify-content-between align-items-center"
>
  <div class="modal-title" id="taggign_view_1">
    {{ 'image-caption.editShortCut' | translate }}
  </div>
  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    (click)="closeDialog()"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle"></i>
  </button>
</div>
<div mat-dialog-content>
  <form class="form-inline">
    <div class="remove-data-list row w-100">
      <div class="remove-data-list_item d-flex justify-content-center">
        <span class="key-box mr-2 ml-0">{{
          'image-caption.key' | translate
        }}</span>
        <span class="description-box d-flex justify-content-center">{{
          'image-caption.description' | translate
        }}</span>
      </div>
      <div
        class="remove-data-list_item d-flex justify-content-center edit_row"
        *ngFor="let key of editModel; let i = index"
        [attr.data-index]="i"
      >
        <input
          class="key-box text-uppercase fs-background-color-white-gray mb-1 form-control"
          type="text"
          value="{{ key.keyText }}"
          [(ngModel)]="editModel[i].keyText"
          (focus)="onFocusFn()"
          (blur)="onBlurFn()"
          [ngModelOptions]="{ standalone: true }"
        />
        <input
          class="ml-3 description-box fs-background-color-white-gray form-control"
          type="text"
          [(ngModel)]="editModel[i].value"
          value="{{ key.value }}"
          (keyup.enter)="editKeyFn()"
          (focus)="onFocusFn()"
          (blur)="onBlurFn()"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button
    value="close"
    class="fs-dialog-btn w-auto ml-3"
    (click)="closeDialog()"
  >
    {{ 'image-caption.buttons.close' | translate }}
  </button>
  <button value="Add" class="fs-dialog-btn w-auto ml-3" (click)="editKeyFn()">
    {{ 'image-caption.buttons.update' | translate }}
  </button>
</div>
