import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from '../../../../../shared/services/snack-bar.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { Modalstatusservice } from '../../../../../shared/services/modalstatus.service';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-i-m-done',
  templateUrl: './imdone.component.html',
  styleUrls: ['./imdone.component.scss'],
})
export class IMDoneComponent extends UnsubscriberComponent implements OnInit {
  doneText = '';
  isConfirmShown = false;
  constantsGlobalData: any;
  currentFilter: any;

  constructor(
    private viewProjectService: ViewProjectService,
    private modalService: Modalstatusservice,
    private translate: TranslateService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private route: Router,
    private activRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
    private dialogRef: MatDialogRef<IMDoneComponent>,
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
  }

  onChangeDoneText() {
    if (this.doneText.toLowerCase() === 'done') {
      this.isConfirmShown = true;
    } else {
      this.isConfirmShown = false;
    }
  }

  onClickDone() {
    this.viewProjectService
      .IamDone({
        projectId: this.viewProjectService.getProjectId(),
        albumId: this.viewProjectService.getExecutiveAlbumID(),
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.closeWindow();
        this.snackBarService.showSnackBar(response.message);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  closeWindow() {
    this.doneText = '';
    this.dialogRef.close();
  }
}
