<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{ 'left-menu.tab-bar.sidemenus.audit.labels.label4' | translate }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content [formGroup]="passwordForm">
  <div *ngIf="isClickedOnClearSingleTag">
    ({{ selectedImagesStore.size() }}){{
      'left-menu.tab-bar.sidemenus.audit.labels.label5' | translate
    }}
    {{ 'left-menu.tab-bar.sidemenus.audit.labels.label6' | translate }}
  </div>

  <div *ngIf="isClickedOnClearTag">
    {{ 'left-menu.tab-bar.sidemenus.audit.labels.label8' | translate }}
    {{ selectedImagesStore.size() }}
    {{ 'left-menu.tab-bar.sidemenus.audit.labels.label9' | translate }}
  </div>

  <div class="pt-2">
    <fieldset class="form-group">
      <input
        fsAutofocus
        type="text"
        class="form-control"
        id="password"
        formControlName="password"
        name="password"
        [placeholder]="
          'left-menu.tab-bar.sidemenus.audit.labels.label4' | translate
        "
      />
    </fieldset>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button
    class="fs-dialog-btn w-auto ml-3"
    [disabled]="!passwordForm.valid"
    (click)="submitPasswordForm()"
  >
    {{ 'left-menu.tab-bar.sidemenus.audit.labels.label7' | translate }}
  </button>
</div>
