import { Injectable } from '@angular/core';
import { SelectedImages } from '../models/selectedImages.model';
import { EntityStore } from './entity-store';

@Injectable({ providedIn: 'root' })
export class LastSelectedImageStore extends EntityStore<SelectedImages> {
  constructor() {
    super();
  }

  hasEntities(): boolean {
    return Boolean(this.size());
  }

  getHeadItem(): SelectedImages {
    const state = this.getState();
    const headId = state.ids[0];
    return headId ? this.getEntity(headId) : null;
  }

  getItemsIds(): number[] {
    return this.getState().ids as number[];
  }
}
