import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { CookieBannerService } from './cookie-banner.service';
import { TranslateService } from '@ngx-translate/core';
import { FsCookieService } from '../../services/fs-cookie.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'fs-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent {
  @ViewChild('cookieDivElement') cookieDivElement: ElementRef;

  constructor(
    private localStorage: LocalStorageService,
    private translate: TranslateService,
    private sessionStorage: SessionStorageService,
    private cookieService: FsCookieService,
    private cookieBannerService: CookieBannerService,
    private languageService: LanguageService,
  ) {}

  onAcceptCookiePolicy() {
    this.closeBanner();
    this.setCookieExpirationDate();
    this.cookieService.setCookieStatus(true);
    this.setLanguageCookieIfAllowed();
  }

  onRejectCookiePolicy() {
    this.cookieService.setCookieStatus(false);
    this.closeBanner();
    this.cookieService.clearStorageValues();
  }

  setLanguageCookieIfAllowed(): void {
    this.languageService.setLanguageCookie();
    this.languageService.setEnableSelectLanguage(true);
  }

  closeBanner() {
    this.cookieDivElement.nativeElement.classList.remove(
      'fs-active-cookie-container',
    );
    this.cookieBannerService.close();
  }

  setCookieExpirationDate() {
    const daysInMilliseconds = 2592000000;
    const cookieExpirationDate = new Date().getTime() + daysInMilliseconds;
    this.localStorage.store('cookieExpirationDate', cookieExpirationDate);
  }
}
