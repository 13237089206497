import { LauncherService } from './../../../../../../launcher/launcher.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppStateService } from '../../../../../../../shared/services/app-state.service';
import { AssetInfo } from '../../../../../../../shared/models/assetInfo.model';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { UserService } from '../../../../../../../shared/services/user.service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { Utils } from '../../../../../../../shared/utils/utils';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FinalizationComponent } from '../finalization/finalization.component';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'fs-pko-logout',
  templateUrl: './pko-logout.component.html',
  styleUrls: ['./pko-logout.component.scss'],
})
export class PkoLogoutComponent
  extends UnsubscriberComponent
  implements OnInit
{
  projectDetail: any;
  userData: any;
  model: any[] = [];
  noOfImages = 0;

  projectImageData: AssetInfo = new AssetInfo();
  constantsGlobalData: any;
  selectedProjectname = '';

  logOutForm = new UntypedFormGroup({
    approvalstatus: new UntypedFormControl('exit'),
    from: new UntypedFormControl('1'),
    to: new UntypedFormControl('1'),
  });

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  constructor(
    private router: Router,
    private modalService: Modalstatusservice,
    private userService: UserService,
    private viewProjectService: ViewProjectService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private launcherService: LauncherService,
    private dialogRef: MatDialogRef<PkoLogoutComponent>,
    private appStateService: AppStateService,
  ) {
    super();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.viewProjectService.projectDataGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.projectImageData = data;
        }
      });

    this.launcherService.selectedProjectNameGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.selectedProjectname = data;
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.projectDetail = data;
        }
      });

    this.userService.currentUserGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((userData) => {
        if (!Utils.isObjectEmpty(userData)) {
          this.userData = userData;
        }
      });
  }

  closeWindow() {
    this.resetForm();
    this.dialogRef.close();
  }

  onFocustextInput() {
    this.logOutForm.get('approvalstatus').setValue('partial-finalize');
  }

  resetForm() {
    this.logOutForm.get('approvalstatus').setValue('exit');
    this.logOutForm.get('from').setValue('1');
    this.logOutForm.get('to').setValue('1');
  }

  onlyNumberKey(event: any) {
    return event.charCode === 8 || event.charCode === 0
      ? null
      : event.charCode >= 48 && event.charCode <= 57;
  }

  getTagId() {
    try {
      const userId = this.userData.id;

      const filteredArray = this.projectDetail.projectTagListDTO.filter(
        (item) => {
          if (item.userId === userId) {
            return true;
          } else {
            return false;
          }
        },
      );
      return filteredArray[0].id;
    } catch {
      return 0;
    }
  }

  submitForm() {
    const approvalstatus = this.logOutForm.get('approvalstatus').value;
    const from = this.logOutForm.get('from').value;
    const to = this.logOutForm.get('to').value;
    if (approvalstatus === 'exit') {
      this.closeWindow();
      this.appStateService.cleanAppState();
      this.router.navigateByUrl('/');
    } else if (approvalstatus === 'full-finalize') {
      this.getTagId();
      const obj = {
        action: approvalstatus,
        assetIds: this.viewProjectService.getProjectImageIDs(),
        projectId: this.viewProjectService.getProjectId(),
        tagId: this.getTagId(),
        batchId: 0,
        batchName: '',
        projectName: this.projectDetail.projectName,
        useSetup: this.projectDetail.useSetup,
      };
      this.callFinalizationModal(obj);
    } else if (approvalstatus === 'partial-finalize') {
      const fromPage = Number(from);
      const toPage = Number(to);
      const totalPages = Number(this.viewProjectService.getTotalPageNumber());
      if (totalPages) {
        if (
          fromPage > toPage ||
          fromPage < 1 ||
          fromPage > totalPages ||
          toPage < 1 ||
          toPage > totalPages
        ) {
          this.alertCustom(this.translate.instant('view-project.alert27'));
        } else {
          const obj = {
            action: approvalstatus,
            assetIds: Utils.getImageIdsBasedOnPageRange(
              fromPage,
              toPage,
              this.projectDetail.viewSettingDTO.imagePerPage,
              this.viewProjectService.getProjectImageIDs(),
            ),
            projectId: this.viewProjectService.getProjectId(),
            tagId: this.getTagId(),
            batchId: 0,
            batchName: '',
            projectName: this.projectDetail.projectName,
            useSetup: this.projectDetail.useSetup,
          };
          this.callFinalizationModal(obj);
        }
      }
    }
  }

  callFinalizationModal(data: any) {
    this.closeWindow();
    this.dialogService.open(FinalizationComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
      ],
      data,
    });
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
