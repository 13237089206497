import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'fs-view-project-solo-icon-with-border-component',
  templateUrl: './view-project-solo-icon-with-border.component.html',
  styleUrls: ['./view-project-solo-icon-with-border.component.scss'],
})
export class ViewProjectSoloIconWithBorderComponent {
  @Input() imgData;
  @Input() isTaggerRole: boolean;
  @Input() assetType;
  @Output() clickOnGroupImages = new EventEmitter();
}
