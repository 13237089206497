import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { ActorapprovalreportService } from './actoreapproval-report.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { ApprovalReportComponent } from './approval-report/approval-report.component';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { Utils } from '../../../../../../../shared/utils/utils';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-actoreapproval-report',
  templateUrl: './actoreapproval-report.component.html',
  styleUrls: ['./actoreapproval-report.component.scss'],
})
export class ActoreapprovalReportComponent
  extends UnsubscriberComponent
  implements OnInit
{
  selectedTalent: any = '';
  dataTalents: Array<object>;
  arrListActorApprovalReport: any = [];
  dataToSendChildComponent: any = {};
  isApproveCheck = false;
  constantsGlobalData: any;

  constructor(
    private actorApprovalReport: ActorapprovalreportService,
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private elementRef: ElementRef,
    private loaderService: LoaderService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private viewProjectService: ViewProjectService,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<ActoreapprovalReportComponent>,
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          if (res.projectTagListDTO.length > 0) {
            this.dataTalents = res.projectTagListDTO.filter((objTelent) => {
              return objTelent['killRight'] === true;
            });
            this.dataToSendChildComponent['taggedList'] = this.dataTalents;
          }
        }
      });
  }

  onChangeListDropdoen(selectedTalent) {
    this.loaderService.displayLoader(true);
    this.getReportDetails(
      this.viewProjectService.getProjectId(),
      selectedTalent.id,
    );
    this.selectedTalent = selectedTalent;
  }

  getReportDetails(projectID, actorID) {
    this.actorApprovalReport
      .getActorApprovalReports(projectID, actorID)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.loaderService.displayLoader(false);
          if (response) {
            this.arrListActorApprovalReport = response;
            this.approvalReport();
          } else {
            this.arrListActorApprovalReport = [];
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  closeWindow() {
    this.dialogRef.close();
  }

  approvalReport() {
    this.dialogService.open(ApprovalReportComponent, {
      data: {
        taggedList: this.dataTalents,
        selectedTalent: this.selectedTalent,
        isApproveCheck: this.isApproveCheck,
        data: this.arrListActorApprovalReport,
      },
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-link-panel',
        'fs-dialog-full-screen-panel',
      ],
    });
    this.selectedTalent = '';
    this.isApproveCheck = false;
    this.closeWindow();
  }
}
