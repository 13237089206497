<div class="dep-exe-container">
  <div class="block-header">
    <span>{{ 'dashboard.shortcuts' | translate }} </span>
  </div>
  <div class="flex-container">
    <div
      class="flex-item favourite-block"
      *ngFor="let favProject of favouriteProjects"
    >
      <div class="project-name">
        <a href="javascript:void(0)" (click)="navigateToProject(favProject)">
          {{ favProject.displayText }}</a
        >
        <a
          href="javascript:void(0)"
          class="remove-favourite"
          [title]="'dashboard.removeFromShortcuts' | translate"
          (click)="removeFromFavList(favProject)"
        >
          <i class="fa fa-close"></i
        ></a>
      </div>
    </div>
  </div>
  <div *ngIf="favouriteProjects.length == 0" class="text-center">
    {{ 'dashboard.blank' | translate }}
  </div>
</div>
