<div class="ml-1" *ngIf="imgData.assetType == assetType.IMAGE">
  <div
    class="fs-cursor-pointer"
    title="{{ 'view-project.enlarge' | translate }}"
    (click)="enlargeView.emit(imgData)"
  >
    <span class="fs-char-icon">
      <img src="/assets/images/icon-zoom_previous.png" />
    </span>
  </div>
</div>
