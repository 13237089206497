import { Component, HostListener, OnInit } from '@angular/core';
import { ApplyExifService } from './apply-exif.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../../../../../shared/services/snack-bar.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../../shared/store/selected-images-store';
import { Utils } from '../../../../../../../shared/utils/utils';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

interface BatchValue {
  value: string;
  label: string;
}

@Component({
  selector: 'fs-apply-exif',
  templateUrl: './apply-exif.component.html',
  styleUrls: ['./apply-exif.component.scss'],
})
export class ApplyExifComponent
  extends UnsubscriberComponent
  implements OnInit
{
  delimiterData: Array<BatchValue>;
  applyExifData: any;
  projectDetailPermission: any;
  constantsGlobalData: any;

  constructor(
    private applyExifService: ApplyExifService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private viewProjectService: ViewProjectService,
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private loaderService: LoaderService,
    private snackBarService: SnackBarService,
    private selectedImagesStore: SelectedImagesStore,
    private dialogRef: MatDialogRef<ApplyExifComponent>,
  ) {
    super();
    this.delimiterData = [
      { value: ',', label: ',' },
      { value: ';', label: ';' },
      { value: '|', label: '|' },
    ];
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.applyExifData = {};
    this.applyExifData.operationType = '1';

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.projectDetailPermission = res;
        }
      });
  }

  confirmAction() {
    const obj = {
      action: 2, // overwrite
      assetIds: this.selectedImagesStore.getItemsIds(),
      copyright: this.applyExifData.copyright
        ? this.applyExifData.copyright
        : '',
      photographer: this.applyExifData.author ? this.applyExifData.author : '',
      projectId: Number(this.viewProjectService.getProjectId()),
    };

    this.loaderService.displayLoader(true);
    this.applyExifService
      .updateApplyExif(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            this.snackBarService.showSnackBar(res.message);
          } else {
            this.snackBarService.showSnackBarError(res.message);
          }
          this.loaderService.displayLoader(false);
          this.closeWindow();
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  closeWindow() {
    this.dialogRef.close();
    this.applyExifData = {};
  }
}
