<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.SelectRecepient.title'
        | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <ng-scrollbar autoHeightDisabled="false" trackY="'true'">
    <div class="w-100 imreport-divtable container">
      <div class="row">
        <div class="col">
          <mat-checkbox
            [checked]="isAllemailsSelected"
            class="fs-checkbox"
            (change)="onSelecDeselectAll(isAllemailsSelected)"
            id="isAllemailsSelected"
            name="isAllemailsSelected"
            [(ngModel)]="isAllemailsSelected"
            type="checkbox"
          >
            <span class="cb-title">
              {{ !isAllemailsSelected ? 'Select All' : 'Deselect All' }}
            </span>
          </mat-checkbox>
        </div>
      </div>

      <div
        class="row mt-1"
        *ngFor="
          let emailList of arrListEmails | sortBy : 'name';
          let idx = index
        "
      >
        <div class="col">
          <mat-checkbox
            id="emailchk{{ idx }}"
            class="fs-checkbox"
            [checked]="emailList['isChecked']"
            (change)="onClickSingleCheckBox(idx)"
          >
            <span class="cb-title"> {{ emailList.name }} </span>
          </mat-checkbox>
        </div>
      </div>
    </div>
  </ng-scrollbar>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <button class="fs-dialog-btn w-auto ml-3" (click)="onClickDoneBtn()">
    {{
      'left-menu.tab-bar.sidemenus.administration.filtered-list.button.done'
        | translate
    }}
  </button>

  <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
    {{
      'left-menu.tab-bar.sidemenus.administration.filtered-list.button.close'
        | translate
    }}
  </button>
</div>
