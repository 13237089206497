<div mat-dialog-title class="d-flex justify-content-between align-items-center">
  <div class="modal-title selected_filename_list" id="fiexlLabel">
    {{
      'left-menu.tab-bar.sidemenus.administration.image-exclusives.title'
        | translate
    }}
  </div>
  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    (click)="closeWindow()"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle"></i>
  </button>
</div>
<div mat-dialog-content>
  <span class="fa fa-question-circle">{{ popupMsg }}</span>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button (click)="closeWindow()" class="fs-dialog-btn w-auto">
    {{
      'left-menu.tab-bar.sidemenus.administration.image-exclusives.button.ok'
        | translate
    }}
  </button>
</div>
