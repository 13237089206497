import { Component, Inject } from '@angular/core';
import { PopupRef } from '../../../services/popup/popup-ref';
import { FS_POPUP_DATA } from '../../../services/popup/popup.constants';
import { Router } from '@angular/router';
import { DialogService } from '../../../services/dialog/dialog.service';
import { AppStateService } from '../../../services/app-state.service';
import { PreviousRouteService } from '../../../services/previous-route.service';
import { UserRole } from '../../../enum/user-role.enum';
import { PkoLogoutComponent } from '../../../../dashboard/project/dashboard-shared/left-menu/talent-user/finalize-images/pko-logout/pko-logout.component';
import { PopupService } from '../../../services/popup/popup.service';
import { ProjectRole } from '../../../enum/project-role.enum';

@Component({
  selector: 'fs-user-avatar-menu-popup',
  templateUrl: './user-avatar-menu-popup.component.html',
  styleUrls: ['./user-avatar-menu-popup.component.scss'],
})
export class UserAvatarMenuPopupComponent {
  get currentUser() {
    return this.data.currentUser;
  }

  constructor(
    private popupRef: PopupRef<UserAvatarMenuPopupComponent>,
    @Inject(FS_POPUP_DATA)
    private data: { projectAuthority; projectImageIds; currentUser },
    private previousRouteService: PreviousRouteService,
    private router: Router,
    private dialogService: DialogService,
    private popupService: PopupService,
    private appStateService: AppStateService,
  ) {}

  signOut() {
    if (this.data.projectAuthority === ProjectRole.ROLE_TALENT) {
      if (!this.data.projectImageIds) {
        this.router.navigateByUrl('/');
      } else if (
        this.data.projectImageIds &&
        !this.data.projectImageIds.length
      ) {
        this.router.navigateByUrl('/');
      } else {
        const currentUrl = this.previousRouteService.getCurrentUrl();
        if (currentUrl === '/dashboard') {
          this.appStateService.cleanAppState();
          this.router.navigateByUrl('/');
        } else {
          this.popupRef.hide();
          // todo: need  to refactor
          this.dialogService.open(PkoLogoutComponent, {
            panelClass: [
              'fs-dialog-panel',
              'fs-dialog-size-lg-panel',
              'fs-dialog-link-panel',
            ],
          });
        }
      }
    } else {
      this.appStateService.cleanAppState();
      this.router.navigateByUrl('/');
    }
  }
}
