import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettings } from '../../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../../shared/services/api.service';

@Injectable()
export class ImagecaptionService {
  private reportCaptionSubject = new BehaviorSubject<any>([]);
  reportCaptionGlobal = this.reportCaptionSubject.asObservable();

  private captionTypeSubject = new BehaviorSubject<any>([]);

  constructor(private apiService: ApiService) {}

  setImagecaptionReport(data) {
    this.reportCaptionSubject.next(data);
  }

  getImagecaptionReport() {
    return this.reportCaptionSubject.value;
  }

  reSetImagecaptionReport() {
    this.reportCaptionSubject.next([]);
  }

  setCaptionType(data) {
    this.captionTypeSubject.next(data);
  }

  getCaptionType() {
    return this.captionTypeSubject.value;
  }

  callImagecaptionReport(projectId, filter): Observable<any> {
    const path = AppSettings.CAPTION_REPORTS(projectId, filter);
    return this.apiService.get(path).pipe(
      map((data) => {
        this.setImagecaptionReport(data);
        return data;
      }),
    );
  }

  callReplaceCaption(payload): Observable<any> {
    const path = AppSettings.CAPTION_REPLACE;
    return this.apiService.put(path, payload).pipe(
      map((data) => {
        return data;
      }),
    );
  }
}
