import { UserService } from './../../../../../../shared/services/user.service';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '../../../../../../shared/services/dialog/dialog.service';
import { PopupService } from '../../../../../../shared/services/popup/popup.service';
import { SendEmailNotificationComponent } from '../wide-edit-administration/send-email-notification/send-email-notification.component';
import { AlbumColorTagsService } from '../../../../../../shared/services/album-color-tags.service';
import {
  AssetInfo,
  AssetInfoDTOList,
} from '../../../../../../shared/models/assetInfo.model';
import { UserRole } from '../../../../../../shared/enum/user-role.enum';
import { Modalstatusservice } from '../../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { SidebarService } from '../../../../sidebar-service/sidebar-service';
import { ConstantsGlobal } from '../../../../../../shared/constants/constants';
import { AddalbumComponent } from './addalbum/addalbum.component';
import { DeletealbumComponent } from './deletealbum/deletealbum.component';
import { RenamealbumComponent } from './renamealbum/renamealbum.component';
import { Utils } from '../../../../../../shared/utils/utils';
import { AdvanceimageTaggingComponent } from '../administration/advanceimage-tagging/advanceimage-tagging.component';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../shared/store/selected-images-store';
import { EditalbumComponent } from './editalbum/editalbum.component';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { ProjectRole } from '../../../../../../shared/enum/project-role.enum';

export enum DialogType {
  Rename,
  AdvancedImageTagging,
  AddAlbum,
  DeleteAlbum,
  EditAlbum,
  EmailNotification,
}

@Component({
  selector: 'fs-shared-menu',
  templateUrl: 'shared-menu.component.html',
  styleUrls: ['shared-menu.component.scss'],
})
export class SharedMenuComponent
  extends UnsubscriberComponent
  implements OnInit
{
  @ViewChild('actionsContainerRef') private actionsContainerRef: ElementRef;
  @ViewChild('permissionsRef') private permissionsRef: ElementRef;
  @ViewChild('tagsIcon') private tagsIcon: ElementRef;
  @ViewChild('renameAlbum') private renameAlbum: ElementRef;
  albumsData: any;
  selectedAlbum: any;
  constantsGlobalData: any;
  trashTooltip: any;
  projectImageData: AssetInfo = new AssetInfo();
  @Input() albumType: any;
  @Input() hide: boolean;
  currentPageNumber: number;
  noOfImages: number;
  projectImageIds: any;
  projectAuthority: string;
  tagColorOnViewPage = false;
  userRole = UserRole;
  projectRole = ProjectRole;
  activePopup: DialogType;
  dialogType = DialogType;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: Modalstatusservice,
    private viewProjectService: ViewProjectService,
    private sidebarService: SidebarService,
    private translate: TranslateService,
    private userService: UserService,
    private dialogService: DialogService,
    private popupService: PopupService,
    private albumColorTagsService: AlbumColorTagsService,
    private selectedImagesStore: SelectedImagesStore,
  ) {
    super();
  }

  @HostListener('click', ['$event'])
  onClickHost(event) {
    event.stopPropagation();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.sidebarService.selectedAlbum
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        this.albumsData = album;
      });

    this.sidebarService.selectedAlbum
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        if (!Utils.isObjectEmpty(album)) {
          this.trashTooltip = 'deleteasset.albumselected';
          this.selectedAlbum = album;
        } else {
          this.trashTooltip = 'deleteasset.noalbumselect';
          this.selectedAlbum = undefined;
        }
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.noOfImages = data.viewSettingDTO.imagePerPage;
        }
      });

    this.viewProjectService.projectImageIdsGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageIds) => {
        this.projectImageIds = imageIds;
      });

    this.albumColorTagsService.isColorPanelEnabled$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.tagColorOnViewPage = status;
      });
  }

  showAddAlbumPopup() {
    if (this.selectedAlbum) {
      if (this.albumType === 'wideEdit' || this.albumType === 'signOff') {
        this.dialogService.openInformationDialog({
          title: this.translate.instant('view-project.confirm22'),
          message: 'You are not allow to add sub album.',
        });
      } else {
        this.confirmForAddAlbumToSubFolder();
      }
    } else {
      this.activePopup = DialogType.AddAlbum;
      this.openAddNewAlbum();
    }
  }

  openAddNewAlbum() {
    this.popupService.open(
      AddalbumComponent,
      this.actionsContainerRef.nativeElement,
      {
        position: 'right',
        backDropClass: 'fs-dialog-overlay',
        hasBackdrop: true,
        panelClass: ['fs-popup-base-panel', 'fs-popup-sm-panel'],
        data: {
          albumType: this.albumType,
        },
      },
    );
  }

  confirmForAddAlbumToSubFolder() {
    this.dialogService
      .openConfirmationDialog({
        title: this.translate.instant('confirmDialog.addMessage.title'),
        message:
          this.translate.instant('confirmDialog.addMessage.message') +
          ' ' +
          this.selectedAlbum.value +
          '?',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: boolean) => {
        if (state) {
          this.openAddNewAlbum();
        }
      });
  }

  showDeleteAlbumPopup() {
    if (!Utils.isObjectEmpty(this.albumsData)) {
      this.activePopup = DialogType.DeleteAlbum;
      this.popupService.open(
        DeletealbumComponent,
        this.actionsContainerRef.nativeElement,
        {
          position: 'right',
          hasBackdrop: true,
          backDropClass: 'fs-popup-overlay',
          panelClass: [
            'fs-popup-base-panel',
            'fs-popup-sm-panel',
            'fs-popup-max-width-panel',
          ],
          data: {
            albumType: this.albumType,
          },
        },
      );
    } else {
      this.alertCustom(this.translate.instant('view-project.alert26'));
    }
  }

  showRenameAlbumPopup() {
    if (!Utils.isObjectEmpty(this.albumsData)) {
      this.activePopup = DialogType.Rename;
      this.popupService.open(
        RenamealbumComponent,
        this.actionsContainerRef.nativeElement,
        {
          position: 'right',
          hasBackdrop: true,
          data: this.albumType,
          backDropClass: 'fs-popup-overlay',
          panelClass: ['fs-popup-base-panel', 'fs-popup-sm-panel'],
        },
      );
    } else {
      this.alertCustom(this.translate.instant('view-project.alert26'));
    }
  }

  showEditAlbumPopup() {
    if (!Utils.isObjectEmpty(this.albumsData)) {
      this.activePopup = DialogType.EditAlbum;
      this.popupService.open(
        EditalbumComponent,
        this.permissionsRef.nativeElement,
        {
          position: 'right',
          hasBackdrop: true,
          data: {
            albumType: this.albumType,
          },
          backDropClass: 'fs-popup-overlay',
          panelClass: ['fs-popup-base-panel', 'fs-popup-md-panel'],
        },
      );
    } else {
      this.alertCustom(this.translate.instant('view-project.alert26'));
    }
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  handleTagColorOnViewPage(event) {
    this.albumColorTagsService.setIsColorPanelEnabled(event.checked);
  }

  advancedImageTagging() {
    this.dialogService.open(AdvanceimageTaggingComponent, {
      hasBackdrop: true,
      data: {
        comingFromWideEdit: true,
      },
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
      ],
      listener: {
        withListener: true,
        id: 'AdvanceimageTaggingComponent',
      },
    });
  }

  sendEmailNotification() {
    if (!Utils.isObjectEmpty(this.albumsData)) {
      this.activePopup = DialogType.EmailNotification;
      this.dialogService.open(SendEmailNotificationComponent, {
        hasBackdrop: true,
        panelClass: [
          'fs-dialog-panel',
          'fs-dialog-size-lg-panel',
          'fs-dialog-link-panel',
        ],
        data: this.albumsData,
      });
    } else {
      this.alertCustom(this.translate.instant('view-project.alert26'));
    }
  }
}
