<div
  id="topMenuNavBar"
  class="filter-bar topmenu-container"
  *ngIf="currentState.displaySetting.topMenu.visible"
>
  <div class="row d-block">
    <div
      *ngIf="projectAuthority === projectRole.ROLE_ALBUMVIEWER"
      class="d-flex flex-fill justify-content-between"
    >
      <ng-container [ngTemplateOutlet]="roleAlbumViewer"> </ng-container>
    </div>
    <div *ngIf="userAuthority === userRole.ROLE_ADMIN">
      <ng-container
        *ngIf="selectedProjectValue === 3"
        [ngTemplateOutletContext]="{
          setUpTitle: setUpTitle,
          approveRejectAccess: true,
          isEmailIcon: false,
          isDeleteIcon: false,
          starAccess: false
        }"
        [ngTemplateOutlet]="roleAdmin"
      >
      </ng-container>
      <ng-container
        *ngIf="selectedProjectValue !== 4 && selectedProjectValue !== 3"
        [ngTemplateOutletContext]="{
          setUpTitle: getSetUpTitle(setUpGroup, setUpTitle),
          isEmailIcon: true,
          isDeleteIcon: true,
          starAccess: true
        }"
        [ngTemplateOutlet]="roleAdmin"
      >
      </ng-container>
      <ng-container
        *ngIf="selectedProjectValue === 4"
        [ngTemplateOutletContext]="{
          setUpTitle: setUpTitle,
          iAmDoneAccess: true,
          approveRejectAccess: true,
          isEmailIcon: false,
          starAccess: false,
          isDeleteIcon: true
        }"
        [ngTemplateOutlet]="roleAdmin"
      >
      </ng-container>
    </div>

    <div
      *ngIf="
        userAuthority === userRole.ROLE_EXTERNAL &&
        projectAuthority === projectRole.ROLE_EXEC
      "
    >
      <ng-container
        *ngIf="selectedProjectValue !== 4 && selectedProjectValue !== 3"
        [ngTemplateOutletContext]="{ setUpTitle: setUpTitle, isStarIcon: true }"
        [ngTemplateOutlet]="roleExec"
      >
      </ng-container>
      <ng-container
        *ngIf="selectedProjectValue !== 4 && selectedProjectValue === 3"
        [ngTemplateOutletContext]="{
          setUpTitle: getSetUpTitle(setUpGroup, setUpTitle),
          isStarIcon: true
        }"
        [ngTemplateOutlet]="roleExec"
      >
      </ng-container>

      <ng-container
        *ngIf="selectedProjectValue === 4"
        [ngTemplateOutletContext]="{
          setUpTitle: setUpTitle,
          approveRejectAccess: true,
          iAmDoneAccess: true,
          isStarIcon: false
        }"
        [ngTemplateOutlet]="roleExec"
      >
      </ng-container>
    </div>

    <div
      *ngIf="
        userAuthority === userRole.ROLE_EXTERNAL &&
        projectAuthority === projectRole.ROLE_TALENT
      "
    >
      <ng-container
        [ngTemplateOutlet]="roleTalent"
        [ngTemplateOutletContext]="{ setUpTitle: setUpTitle }"
      >
      </ng-container>
    </div>
  </div>
</div>

<ng-template #roleTalent let-setUpTitle="setUpTitle">
  <div class="telent-view d-flex flex-fill justify-content-between">
    <div class="col-4">
      <div class="left-bar">
        <div class="basic-filter">
          <div
            class="global-help-icon-wraper"
            *ngIf="currentState.displaySetting.topMenu.items.goto"
          >
            <a
              class="text-uppercase"
              data-toggle="modal"
              title="{{ 'top-menu.goto.tooltip' | translate }}"
              (click)="goToPage()"
            >
              {{ 'top-menu.goto.tooltip' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-4"
      *ngIf="currentState.displaySetting.topMenu.items.pagination"
    >
      <div class="text-center">
        <div class="pagination-content">
          <fs-pagination id="ViewProject" *ngIf="isPagination"></fs-pagination>
        </div>
      </div>
    </div>

    <div>
      <div
        class="text-center"
        *ngIf="currentState.displaySetting.topMenu.items.setUpTitle"
      >
        <div class="pagination-content">
          {{ setUpGroup }} - {{ setUpTitle }}
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="right-bar">
        <div
          class="adv-filter d-flex align-items-center justify-content-end mr-2"
        >
          <div *ngIf="currentState.displaySetting.topMenu.items.viewSetting">
            <a
              href="javascript:void(0);"
              title="{{ 'top-menu.viewsettings.tooltip' | translate }}"
              (click)="setViewSetting()"
            >
              <img src="/assets/images/icon-window.png" alt="icon" />
            </a>
          </div>

          <div *ngIf="currentState.displaySetting.topMenu.items.closeImage">
            <a
              href="javascript:void(0);"
              title="{{ 'view-project.reduce' | translate }}"
              (click)="closeEnlarge()"
            >
              <img
                src="/assets/images/icon-zoom-minus_previous.png"
                alt="icon"
              />
            </a>
          </div>

          <div
            *ngIf="
              currentState.displaySetting.topMenu.items.enlargeImage &&
              !isSupperZoomTopMenu
            "
          >
            <a
              href="javascript:void(0);"
              title="{{ 'view-project.enlarge' | translate }}"
              (click)="enlargeImage()"
            >
              <img src="/assets/images/icon-zoom_previous.png" alt="icon" />
            </a>
          </div>

          <div
            class="image-note-li"
            *ngIf="currentState.displaySetting.topMenu.items.addNotes"
          >
            <a
              #addNoteIcon
              href="javascript:void(0);"
              title="{{
                'left-menu.tab-bar.sidemenus.imageInfo.labels.addNote'
                  | translate
              }}"
              (click)="onClickNoteIcon()"
            >
              <img src="/assets/images/icon-file_previous.png" alt="icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #roleExec
  let-isStarIcon="isStarIcon"
  let-iAmDoneAccess="iAmDoneAccess"
  let-approveRejectAccess="approveRejectAccess"
  let-isEmailIcon="isEmailIcon"
  let-setUpTitle="setUpTitle"
>
  <div class="d-flex flex-fill justify-content-between">
    <div class="col-4">
      <div class="left-bar h-100">
        <div class="basic-filter h-100">
          <div *ngIf="currentState.displaySetting.topMenu.items.selectAll">
            <a
              class="text-uppercase select-all"
              title="{{ 'top-menu.selectAll' | translate }}"
              data-toggle="modal"
              (click)="selectAllImages()"
            >
              {{ 'top-menu.selectAll' | translate }}
            </a>
          </div>
          <div *ngIf="currentState.displaySetting.topMenu.items.deselectAll">
            <a
              class="text-uppercase select-alle"
              title="{{ 'top-menu.deSelectAll' | translate }}"
              (click)="deSelectAllImages()"
            >
              {{ 'top-menu.deSelectAll' | translate }}
            </a>
          </div>
          <div
            class="global-help-icon-wraper"
            *ngIf="currentState.displaySetting.topMenu.items.goto"
          >
            <a
              class="text-uppercase"
              data-toggle="modal"
              title="{{ 'top-menu.goto.tooltip' | translate }}"
              (click)="goToPage()"
            >
              {{ 'top-menu.goto.tooltip' | translate }}
            </a>
          </div>
          <div
            *ngIf="
              currentState.displaySetting.topMenu.items.imdone && iAmDoneAccess
            "
          >
            <a
              class="text-uppercase"
              data-toggle="modal"
              title="{{ 'top-menu.done' | translate }}"
              (click)="onOpenImDoneModal()"
            >
              {{ 'top-menu.done' | translate }}
            </a>
          </div>

          <div
            class="d-flex align-items-center mr-0"
            *ngIf="
              approveRejectAccess &&
              currentState.displaySetting.topMenu.items.approved
            "
          >
            <div (click)="onClickApprovedIcon()">
              <i
                class="fs-cursor-pointer p-1 fa fa-check-circle fs-approved-rejected-icon-size fs-text-color-light-green"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div
            class="d-flex align-items-center mr-0"
            *ngIf="
              approveRejectAccess &&
              currentState.displaySetting.topMenu.items.rejected
            "
          >
            <div (click)="onClickRejectedIcon()">
              <i
                class="fs-cursor-pointer p-1 fa fa-times-circle fs-approved-rejected-icon-size fs-text-color-red"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-4"
      *ngIf="currentState.displaySetting.topMenu.items.pagination"
    >
      <div class="text-center">
        <div class="pagination-content">
          <fs-pagination id="ViewProject" *ngIf="isPagination"></fs-pagination>
        </div>
      </div>
    </div>

    <div>
      <div
        class="text-center"
        *ngIf="currentState.displaySetting.topMenu.items.setUpTitle"
      >
        <div class="pagination-content">
          {{ setUpTitle }}
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="right-bar">
        <div
          class="adv-filter d-flex align-items-center justify-content-end mr-2"
        >
          <div *ngIf="isExecutiveAlbumView && !iAmDoneAccess">
            <a
              (click)="onOpenImDoneModal()"
              [title]="'top-menu.done' | translate"
            >
              <button class="im_done">{{ 'top-menu.done' | translate }}</button>
            </a>
          </div>

          <div *ngIf="currentState.displaySetting.topMenu.items.viewSetting">
            <a
              href="javascript:void(0);"
              title="{{ 'top-menu.viewsettings.tooltip' | translate }}"
              (click)="setViewSetting()"
            >
              <img src="/assets/images/icon-window.png" alt="icon" />
            </a>
          </div>

          <div *ngIf="currentState.displaySetting.topMenu.items.compare">
            <a
              href="javascript:void(0);"
              title="{{ 'top-menu.compareImages' | translate }}"
              (click)="compareImages()"
            >
              <img src="/assets/images/icon-gallery.png" alt="icon" />
            </a>
          </div>

          <div
            *ngIf="
              adhocLinkAccess &&
              currentState.displaySetting.topMenu.items.link &&
              !isExecutiveAlbumView
            "
          >
            <a
              [title]="'top-menu.link' | translate"
              (click)="linkPage()"
              data-toggle="modal"
              data-backdrop="static"
            >
              <img src="/assets/images/icon-link.png" alt="icon" />
            </a>
          </div>

          <div *ngIf="currentState.displaySetting.topMenu.items.scompare">
            <a
              data-toggle="modal"
              data-target="#fs_secondcompare"
              title="{{ 'top-menu.compareImages' | translate }}"
              (click)="secondLevelCompare()"
            >
              <img alt="icon" src="/assets/images/second-compare.png" />
            </a>
          </div>

          <div
            *ngIf="
              emailAccess &&
              currentState.displaySetting.topMenu.items.email &&
              !isExecutiveAlbumView
            "
          >
            <a
              data-toggle="modal"
              [title]="'top-menu.sendMail.tooltip' | translate"
              (click)="sendMail()"
              data-backdrop="static"
            >
              <img src="/assets/images/icon-email.png" alt="icon" />
            </a>
          </div>

          <div
            *ngIf="
              !!downloadType &&
              currentState.displaySetting.topMenu.items.download
            "
          >
            <a
              data-toggle="modal"
              [title]="'top-menu.downloadImages.tooltip' | translate"
              (click)="Download()"
              data-backdrop="static"
            >
              <img src="/assets/images/icon-download.png" alt="icon" />
            </a>
          </div>

          <div
            *ngIf="
              expressDownload &&
              currentState.displaySetting.topMenu.items.expressDownload &&
              !hasWatermark
            "
          >
            <a
              data-toggle="modal"
              [title]="'top-menu.downloadImages.tooltip' | translate"
              (click)="onOpenExpressDownloadModal()"
              data-backdrop="static"
            >
              <img src="/assets/images/down-arrow.png" alt="icon" />
            </a>
          </div>

          <div
            *ngIf="
              printAccess && currentState.displaySetting.topMenu.items.print
            "
          >
            <a
              class="cp"
              title="{{ 'top-menu.printTool.tooltip' | translate }}"
              (click)="printTool()"
            >
              <img src="/assets/images/icon-print.png" alt="icon" />
            </a>
          </div>

          <div
            class="image-note-li"
            *ngIf="currentState.displaySetting.topMenu.items.addNotes"
          >
            <a
              #addNoteIcon
              href="javascript:void(0);"
              title="{{
                'left-menu.tab-bar.sidemenus.imageInfo.labels.addNote'
                  | translate
              }}"
              (click)="onClickNoteIcon()"
            >
              <img src="/assets/images/icon-file_previous.png" alt="icon" />
            </a>
          </div>

          <div
            *ngIf="
              isStarIcon && currentState.displaySetting.topMenu.items.favorite
            "
          >
            <a
              class="cp favourite"
              [ngClass]="{ favourited: isCurrentFavorite }"
              title="{{ favTitle }}"
              (click)="favTool(isCurrentFavorite)"
            >
              <i class="fa fa-star"></i>
            </a>
          </div>

          <div
            *ngIf="
              deleteAssetAccess &&
              currentState.displaySetting.topMenu.items.delete
            "
          >
            <a
              href="javascript:void(0);"
              title="{{ 'top-menu.deleteAssets.tooltip' | translate }}"
              (click)="deleteAssets()"
            >
              <i class="fa fa-lg fa-trash"></i>
            </a>
          </div>

          <div *ngIf="currentState.displaySetting.topMenu.items.closeImage">
            <a
              href="javascript:void(0);"
              title="{{ 'view-project.reduce' | translate }}"
              (click)="closeEnlarge()"
            >
              <img
                src="/assets/images/icon-zoom-minus_previous.png"
                alt="icon"
              />
            </a>
          </div>

          <div
            *ngIf="
              currentState.displaySetting.topMenu.items.enlargeImage &&
              !isSupperZoomTopMenu
            "
          >
            <a
              href="javascript:void(0);"
              title="{{ 'view-project.enlarge' | translate }}"
              (click)="enlargeImage()"
            >
              <img src="/assets/images/icon-zoom_previous.png" alt="icon" />
            </a>
          </div>

          <div *ngIf="currentState.displaySetting.topMenu.items.close">
            <a
              href="javascript:void(0);"
              name="Close23"
              class="close"
              title="{{ 'top-menu.compareClose' | translate }}"
              (click)="compareClose()"
            >
              <i class="fa fa-times-circle"></i>
            </a>
          </div>

          <div
            *ngIf="
              currentState.displaySetting.topMenu.items.saveDraw
                ? currentState.displaySetting.topMenu.items.saveDraw
                : false
            "
          >
            <a
              href="javascript:void(0);"
              name="saveDraw"
              class="saveDraw d-flex"
              title="{{ 'top-menu.drawSave' | translate }}"
              (click)="saveDraw()"
            >
              <i class="fa fa-floppy-o"></i>
            </a>
          </div>

          <div
            *ngIf="
              currentState.displaySetting.topMenu.items.closeDraw
                ? currentState.displaySetting.topMenu.items.closeDraw
                : false
            "
          >
            <a
              href="javascript:void(0);"
              name="CloseDraw"
              class="close"
              title="{{ 'top-menu.compareClose' | translate }}"
              (click)="closeDraw()"
            >
              <i class="fa fa-times-circle"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #roleAdmin
  let-isEmailIcon="isEmailIcon"
  let-iAmDoneAccess="iAmDoneAccess"
  let-approveRejectAccess="approveRejectAccess"
  let-isDeleteIcon="isDeleteIcon"
  let-starAccess="starAccess"
  let-setUpTitle="setUpTitle"
>
  <div class="d-flex flex-fill justify-content-between">
    <div class="col-4">
      <div class="left-bar h-100">
        <div class="basic-filter h-100">
          <div *ngIf="currentState.displaySetting.topMenu.items.selectAll">
            <a
              class="text-uppercase select-all"
              title="{{ 'top-menu.selectAll' | translate }}"
              data-toggle="modal"
              (click)="selectAllImages()"
            >
              {{ 'top-menu.selectAll' | translate }}
            </a>
          </div>
          <div *ngIf="currentState.displaySetting.topMenu.items.deselectAll">
            <a
              class="text-uppercase select-alle"
              title="{{ 'top-menu.deSelectAll' | translate }}"
              (click)="deSelectAllImages()"
            >
              {{ 'top-menu.deSelectAll' | translate }}
            </a>
          </div>
          <div
            class="global-help-icon-wraper"
            *ngIf="currentState.displaySetting.topMenu.items.goto"
          >
            <a
              class="text-uppercase"
              data-toggle="modal"
              title="{{ 'top-menu.goto.tooltip' | translate }}"
              (click)="goToPage()"
            >
              {{ 'top-menu.goto.tooltip' | translate }}
            </a>
          </div>
          <div
            *ngIf="
              currentState.displaySetting.topMenu.items.imdone && iAmDoneAccess
            "
          >
            <a
              class="text-uppercase"
              data-toggle="modal"
              title="{{ 'top-menu.done' | translate }}"
              (click)="onOpenImDoneModal()"
            >
              {{ 'top-menu.done' | translate }}
            </a>
          </div>
          <div
            class="d-flex align-items-center mr-0"
            *ngIf="
              approveRejectAccess &&
              currentState.displaySetting.topMenu.items.approved
            "
          >
            <div (click)="onClickApprovedIcon()">
              <i
                class="fs-cursor-pointer p-1 fa fa-check-circle fs-approved-rejected-icon-size fs-text-color-light-green"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div
            class="d-flex align-items-center mr-0"
            *ngIf="
              approveRejectAccess &&
              currentState.displaySetting.topMenu.items.rejected
            "
          >
            <div (click)="onClickRejectedIcon()">
              <i
                class="fs-cursor-pointer p-1 fa fa-times-circle fs-approved-rejected-icon-size fs-text-color-red"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-4"
      *ngIf="currentState.displaySetting.topMenu.items.pagination"
    >
      <div class="text-center">
        <div class="pagination-content">
          <fs-pagination id="ViewProject" *ngIf="isPagination"></fs-pagination>
        </div>
      </div>
    </div>

    <div>
      <div
        class="text-center"
        *ngIf="currentState.displaySetting.topMenu.items.setUpTitle"
      >
        <div class="pagination-content">
          {{ setUpTitle }}
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="right-bar">
        <div
          class="adv-filter d-flex align-items-center justify-content-end mr-2"
        >
          <div *ngIf="isExecutiveAlbumView">
            <a
              (click)="onOpenImDoneModal()"
              title="{{ 'top-menu.done' | translate }}"
            >
              <button class="im_done">{{ 'top-menu.done' | translate }}</button>
            </a>
          </div>

          <div *ngIf="currentState.displaySetting.topMenu.items.viewSetting">
            <a
              href="javascript:void(0);"
              title="{{ 'top-menu.viewsettings.tooltip' | translate }}"
              (click)="setViewSetting()"
            >
              <img src="/assets/images/icon-window.png" alt="icon" />
            </a>
          </div>

          <div *ngIf="currentState.displaySetting.topMenu.items.compare">
            <a
              href="javascript:void(0);"
              title="{{ 'top-menu.compareImages' | translate }}"
              (click)="compareImages()"
            >
              <img src="/assets/images/icon-gallery.png" alt="icon" />
            </a>
          </div>

          <div *ngIf="currentState.displaySetting.topMenu.items.scompare">
            <a
              data-toggle="modal"
              data-target="#fs_secondcompare"
              title="{{ 'top-menu.compareImages' | translate }}"
              (click)="secondLevelCompare()"
            >
              <img alt="icon" src="/assets/images/second-compare.png" />
            </a>
          </div>

          <div
            *ngIf="
              currentState.displaySetting.topMenu.items.link &&
              !isTaggerRole &&
              !isExecutiveAlbumView
            "
          >
            <a
              title="{{ 'top-menu.link' | translate }}"
              (click)="linkPage()"
              data-toggle="modal"
              data-backdrop="static"
            >
              <img src="/assets/images/icon-link.png" alt="icon" />
            </a>
          </div>

          <div
            *ngIf="
              isEmailIcon &&
              currentState.displaySetting.topMenu.items.email &&
              !isTaggerRole &&
              !isExecutiveAlbumView
            "
          >
            <a
              data-toggle="modal"
              title="{{ 'top-menu.sendMail.tooltip' | translate }}"
              (click)="sendMail()"
              data-backdrop="static"
            >
              <img src="/assets/images/icon-email.png" alt="icon" />
            </a>
          </div>

          <div
            *ngIf="
              currentState.displaySetting.topMenu.items.download &&
              !isTaggerRole
            "
          >
            <a
              data-toggle="modal"
              [title]="'top-menu.downloadImages.tooltip' | translate"
              (click)="Download()"
              data-backdrop="static"
            >
              <img src="/assets/images/icon-download.png" alt="icon" />
            </a>
          </div>

          <div
            *ngIf="
              currentState.displaySetting.topMenu.items.expressDownload &&
              !isTaggerRole &&
              !hasWatermark
            "
          >
            <a
              data-toggle="modal"
              [title]="'top-menu.expressDownload.tooltip' | translate"
              (click)="onOpenExpressDownloadModal()"
              data-backdrop="static"
            >
              <img src="/assets/images/down-arrow.png" alt="icon" />
            </a>
          </div>

          <div
            *ngIf="
              currentState.displaySetting.topMenu.items.print && !isTaggerRole
            "
          >
            <a
              class="cp"
              title="{{ 'top-menu.printTool.tooltip' | translate }}"
              (click)="printTool()"
            >
              <img src="/assets/images/icon-print.png" alt="icon" />
            </a>
          </div>

          <div
            class="image-note-li"
            *ngIf="currentState.displaySetting.topMenu.items.addNotes"
          >
            <a
              #addNoteIcon
              href="javascript:void(0);"
              title="{{
                'left-menu.tab-bar.sidemenus.imageInfo.labels.addNote'
                  | translate
              }}"
              (click)="onClickNoteIcon()"
            >
              <img src="/assets/images/icon-file_previous.png" alt="icon" />
            </a>
          </div>

          <div
            *ngIf="
              starAccess && currentState.displaySetting.topMenu.items.favorite
            "
          >
            <a
              class="cp favourite"
              [ngClass]="{ favourited: isCurrentFavorite }"
              title="{{ favTitle }}"
              (click)="favTool(isCurrentFavorite)"
            >
              <i class="fa fa-star"></i>
            </a>
          </div>

          <div
            *ngIf="
              isDeleteIcon &&
              deleteAssetAccess &&
              !isTaggerRole &&
              currentState.displaySetting.topMenu.items.delete
            "
          >
            <a
              href="javascript:void(0);"
              title="{{ 'top-menu.deleteAssets.tooltip' | translate }}"
              (click)="deleteAssets()"
            >
              <i class="fa fa-lg fa-trash"></i>
            </a>
          </div>

          <div *ngIf="currentState.displaySetting.topMenu.items.closeImage">
            <a
              href="javascript:void(0);"
              title="{{ 'view-project.reduce' | translate }}"
              (click)="closeEnlarge()"
            >
              <img
                src="/assets/images/icon-zoom-minus_previous.png"
                alt="icon"
              />
            </a>
          </div>

          <div
            *ngIf="
              currentState.displaySetting.topMenu.items.enlargeImage &&
              !isSupperZoomTopMenu
            "
          >
            <a
              href="javascript:void(0);"
              title="{{ 'view-project.enlarge' | translate }}"
              (click)="enlargeImage()"
            >
              <img src="/assets/images/icon-zoom_previous.png" alt="icon" />
            </a>
          </div>

          <div *ngIf="currentState.displaySetting.topMenu.items.close">
            <a
              href="javascript:void(0);"
              name="CloseCompare"
              class="close"
              title="{{ 'top-menu.compareClose' | translate }}"
              (click)="compareClose()"
            >
              <i class="fa fa-times-circle"></i>
            </a>
          </div>

          <div
            *ngIf="
              currentState.displaySetting.topMenu.items.saveDraw
                ? currentState.displaySetting.topMenu.items.saveDraw
                : false
            "
          >
            <a
              href="javascript:void(0);"
              name="saveDraw"
              class="saveDraw d-flex"
              title="{{ 'top-menu.drawSave' | translate }}"
              (click)="saveDraw()"
            >
              <i class="fa fa-floppy-o"></i>
            </a>
          </div>

          <div
            *ngIf="
              currentState.displaySetting.topMenu.items.closeDraw
                ? currentState.displaySetting.topMenu.items.closeDraw
                : false
            "
          >
            <a
              href="javascript:void(0);"
              name="CloseDraw"
              class="close"
              title="{{ 'top-menu.compareClose' | translate }}"
              (click)="closeDraw()"
            >
              <i class="fa fa-times-circle"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #roleAlbumViewer>
  <div class="d-flex flex-fill justify-content-between">
    <div class="col-4">
      <div class="left-bar h-100">
        <div class="basic-filter h-100">
          <div *ngIf="currentState.displaySetting.topMenu.items.selectAll">
            <a
              class="text-uppercase select-all"
              title="{{ 'top-menu.selectAll' | translate }}"
              data-toggle="modal"
              (click)="selectAllImages()"
            >
              {{ 'top-menu.selectAll' | translate }}
            </a>
          </div>
          <div *ngIf="currentState.displaySetting.topMenu.items.deselectAll">
            <a
              class="text-uppercase select-alle"
              title="{{ 'top-menu.deSelectAll' | translate }}"
              (click)="deSelectAllImages()"
            >
              {{ 'top-menu.deSelectAll' | translate }}
            </a>
          </div>
          <div
            class="global-help-icon-wraper"
            *ngIf="currentState.displaySetting.topMenu.items.goto"
          >
            <a
              class="text-uppercase"
              data-toggle="modal"
              title="{{ 'top-menu.goto.tooltip' | translate }}"
              (click)="goToPage()"
            >
              {{ 'top-menu.goto.tooltip' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-4"
      *ngIf="currentState.displaySetting.topMenu.items.pagination"
    >
      <div class="text-center">
        <div class="pagination-content">
          <fs-pagination id="ViewProject" *ngIf="isPagination"></fs-pagination>
        </div>
      </div>
    </div>

    <div>
      <div
        class="text-center"
        *ngIf="currentState.displaySetting.topMenu.items.setUpTitle"
      >
        <div class="pagination-content">
          {{ setUpTitle }}
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="right-bar">
        <div
          class="adv-filter d-flex align-items-center justify-content-end mr-2"
        >
          <div *ngIf="currentState.displaySetting.topMenu.items.viewSetting">
            <a
              href="javascript:void(0);"
              title="{{ 'top-menu.viewsettings.tooltip' | translate }}"
              (click)="setViewSetting()"
            >
              <img src="/assets/images/icon-window.png" alt="icon" />
            </a>
          </div>

          <div *ngIf="currentState.displaySetting.topMenu.items.compare">
            <a
              href="javascript:void(0);"
              title="{{ 'top-menu.compareImages' | translate }}"
              (click)="compareImages()"
            >
              <img src="/assets/images/icon-gallery.png" alt="icon" />
            </a>
          </div>

          <div *ngIf="currentState.displaySetting.topMenu.items.scompare">
            <a
              data-toggle="modal"
              data-target="#fs_secondcompare"
              title="{{ 'top-menu.compareImages' | translate }}"
              (click)="secondLevelCompare()"
            >
              <img alt="icon" src="/assets/images/second-compare.png" />
            </a>
          </div>

          <div
            *ngIf="
              adhocLinkAccess &&
              currentState.displaySetting.topMenu.items.link &&
              !isExecutiveAlbumView
            "
          >
            <a
              title="{{ 'top-menu.link' | translate }}"
              (click)="linkPage()"
              data-toggle="modal"
              data-backdrop="static"
            >
              <img src="/assets/images/icon-link.png" alt="icon" />
            </a>
          </div>

          <div
            *ngIf="
              emailAccess &&
              currentState.displaySetting.topMenu.items.email &&
              !isExecutiveAlbumView
            "
          >
            <a
              data-toggle="modal"
              title="{{ 'top-menu.sendMail.tooltip' | translate }}"
              (click)="sendMail()"
              data-backdrop="static"
            >
              <img src="/assets/images/icon-email.png" alt="icon" />
            </a>
          </div>

          <div
            *ngIf="
              !!downloadType &&
              currentState.displaySetting.topMenu.items.download
            "
          >
            <a
              data-toggle="modal"
              [title]="'top-menu.downloadImages.tooltip' | translate"
              (click)="Download()"
              data-backdrop="static"
            >
              <img src="/assets/images/icon-download.png" alt="icon" />
            </a>
          </div>

          <div
            *ngIf="
              expressDownload &&
              currentState.displaySetting.topMenu.items.expressDownload &&
              !hasWatermark
            "
          >
            <a
              data-toggle="modal"
              [title]="'top-menu.expressDownload.tooltip' | translate"
              (click)="onOpenExpressDownloadModal()"
              data-backdrop="static"
            >
              <img src="/assets/images/down-arrow.png" alt="icon" />
            </a>
          </div>

          <div
            *ngIf="
              printAccess && currentState.displaySetting.topMenu.items.print
            "
          >
            <a
              class="cp"
              title="{{ 'top-menu.printTool.tooltip' | translate }}"
              (click)="printTool()"
            >
              <img src="/assets/images/icon-print.png" alt="icon" />
            </a>
          </div>

          <div
            *ngIf="
              currentState.displaySetting.topMenu.items.saveDraw
                ? currentState.displaySetting.topMenu.items.saveDraw
                : false
            "
          >
            <a
              href="javascript:void(0);"
              name="saveDraw"
              class="save-draw d-flex"
              title="{{ 'top-menu.drawSave' | translate }}"
              (click)="saveDraw()"
            >
              <i class="fa fa-floppy-o"></i>
            </a>
          </div>

          <div
            *ngIf="
              currentState.displaySetting.topMenu.items.closeDraw
                ? currentState.displaySetting.topMenu.items.closeDraw
                : false
            "
          >
            <a
              href="javascript:void(0);"
              name="CloseDraw"
              class="close"
              title="{{ 'top-menu.compareClose' | translate }}"
              (click)="closeDraw()"
            >
              <i class="fa fa-times-circle"></i>
            </a>
          </div>

          <div *ngIf="currentState.displaySetting.topMenu.items.closeImage">
            <a
              href="javascript:void(0);"
              title="{{ 'view-project.reduce' | translate }}"
              (click)="closeEnlarge()"
            >
              <img
                src="/assets/images/icon-zoom-minus_previous.png"
                alt="icon"
              />
            </a>
          </div>

          <div
            *ngIf="
              currentState.displaySetting.topMenu.items.enlargeImage &&
              !isSupperZoomTopMenu
            "
          >
            <a
              href="javascript:void(0);"
              title="{{ 'view-project.enlarge' | translate }}"
              (click)="enlargeImage()"
            >
              <img src="/assets/images/icon-zoom_previous.png" alt="icon" />
            </a>
          </div>

          <div *ngIf="currentState.displaySetting.topMenu.items.close">
            <a
              href="javascript:void(0);"
              name="Close23"
              class="close"
              title="{{ 'top-menu.compareClose' | translate }}"
              (click)="compareClose()"
            >
              <i class="fa fa-times-circle"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
