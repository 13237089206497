import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppSettings } from '../../appSettings/appSettings';
import { ApiService } from './api.service';
import { UserData } from '../models/userdata.model';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable()
export class UserDataService {
  private destroy$ = new Subject(); // todo think what to do with this in service
  userData: UserData[];
  private userDataSubject = new BehaviorSubject<any>([]);

  constructor(private apiService: ApiService) {
    this.userData = [];
  }

  setUserData(data: UserData) {
    this.userData.push(data);
    this.userDataSubject.next(this.userData);
  }

  resetUserData() {
    this.userData = [];
    this.userDataSubject.next(this.userData);
  }

  fetchUserName(id) {
    let name = '';
    this.userData.forEach((data) => {
      if (id === data.id) {
        name = data.firstName + ' ' + data.lastName;
      }
    });
    return name;
  }

  getUserData(projectId, userIdsArray) {
    let newIds = [];
    let returnArray = [];
    userIdsArray.forEach((userid) => {
      if (this.userData.length > 0) {
        if (!this.userData.some((item) => item.id === userid)) {
          newIds.push(userid);
        }
      } else {
        newIds = userIdsArray;
      }
    });

    if (newIds.length > 0) {
      return this.getUserDataApiCall(projectId, newIds.toString()).pipe(
        takeUntil(this.destroy$),
        tap((res) => {
          if (res) {
            res.forEach((userdata) => {
              if (!this.userData.some((item) => item.id === userdata.id)) {
                this.setUserData(userdata);
              }
            });
          }
          returnArray = [];
          userIdsArray.forEach((id) => {
            this.userData.forEach((data) => {
              if (id === data.id) {
                returnArray.push(data);
              }
            });
          });
          return this.userData;
        }),
      );
    } else {
      returnArray = [];
      userIdsArray.forEach((id) => {
        this.userData.forEach((data) => {
          if (id === data.id) {
            returnArray.push(data);
          }
        });
      });
      return of(returnArray);
    }
  }

  getUserDataApiCall(projectId: number, userIds): Observable<any> {
    const path = AppSettings.USER_DETAILS(projectId) + userIds;
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }
}
