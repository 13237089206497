import { Directive, ElementRef, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[fsOverlayZIndex]',
})
export class OverlayZIndexDirective {
  @Input() cdkOverlayContainerClass: string;

  constructor(
    private elRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.changeOverlayVisibilityByCondition();
  }

  changeOverlayVisibilityByCondition() {
    setTimeout(() => {
      if (this.cdkOverlayContainerClass) {
        this.document.body
          .querySelector('.cdk-overlay-container')
          .classList.add(this.cdkOverlayContainerClass);
      }
    });
  }
}
