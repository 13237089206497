import { Router } from '@angular/router';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { SearchProjectModel } from '../interfaces/dashboard-search-project-rule.model';
import { AppSource } from '../../../../../shared/models/enum/AppSource';
import { ProjectRoleId } from '../../../../../shared/enum/project-role.enum';
import { DomainConfigService } from '../../../../../shared/services/domain-config.service';
import { Utils } from '../../../../../shared/utils/utils';
import { FilterEnum } from '../../../../../shared/enum/filter.enum';
import { SessionTypeEnum } from '../../../../../shared/enum/session-type.enum';
import { ViewGalleryResumeModalStateService } from '../../../../../shared/services/view-gallery-resume-modal.service';
import { SidebarService } from '../../../../project/sidebar-service/sidebar-service';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardSearchExecProjectRule implements DashboardRuleModel {
  private readonly DEFAULT_PAGE_NUMBER = 1;

  private router = GetStaticInjector().get(Router);
  private viewProjectService = GetStaticInjector().get(ViewProjectService);
  private domainConfigService = GetStaticInjector().get(DomainConfigService);
  private viewGalleryResumeModalService = GetStaticInjector().get(
    ViewGalleryResumeModalStateService,
  );
  private sidebarService = GetStaticInjector().get(SidebarService);

  constructor(
    private fromDepartment: boolean,
    private selectedProjectId: number,
    private selectedProjectData: SearchProjectModel,
  ) {}

  matches(): boolean {
    return (
      !this.fromDepartment &&
      this.selectedProjectData.role &&
      !this.selectedProjectData.useSetup &&
      !(
        this.domainConfigService.getDomainConfigData().abbr ===
          AppSource.WDTV &&
        this.selectedProjectData.role === Number(ProjectRoleId.ROLE_TALENT)
      )
    );
  }

  apply(): void {
    this.viewProjectService.setProjectID(this.selectedProjectData.value);
    this.router.navigate([
      Utils.prepareFilterNavigationURL(
        this.selectedProjectData.role,
        this.selectedProjectData.value,
        FilterEnum.ShowAll,
        this.DEFAULT_PAGE_NUMBER,
      ),
    ]);
    this.viewGalleryResumeModalService.setDialogActionState(
      SessionTypeEnum.Continue,
    );
    this.sidebarService.resetAlbumImageData();
  }
}
