import { DashboardDepartmentRuleSet } from '../rule-set/dashboard-department-ruleset';
import {
  GroupDataModel,
  GroupItemDataModel,
} from '../interfaces/dashboard-department-rule.model';
import { AlbumType } from '../../../../../shared/enum/album-type.enum';
import { ProjectRoleId } from '../../../../../shared/enum/project-role.enum';
import { LauncherService } from '../../../../launcher/launcher.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { IHandler } from '../../../interfaces/dashboard-handler.model';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardDepartmentHandler implements IHandler {
  private launcherService = GetStaticInjector().get(LauncherService);
  private viewProjectService = GetStaticInjector().get(ViewProjectService);

  constructor(
    private group: GroupDataModel,
    private galBotInfo: GroupItemDataModel,
    private selectedProject: number,
  ) {}

  handle(): void {
    this.viewProjectService.setSelectedProjectData(this.galBotInfo);
    const isSignOffOrWideEdit =
      this.selectedProject === AlbumType.SignOff ||
      this.selectedProject === AlbumType.WideEdit;
    this.launcherService.setIsGallaryModeStatus(true);

    isSignOffOrWideEdit
      ? this.viewProjectService.setProjectRole(ProjectRoleId.ROLE_EXEC)
      : this.viewProjectService.setProjectRole(this.galBotInfo.role);

    return new DashboardDepartmentRuleSet(
      this.group,
      this.galBotInfo,
      this.selectedProject,
    ).apply();
  }
}
