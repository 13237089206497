import {
  Directive,
  Input,
  HostListener,
  EventEmitter,
  Output,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ContextMenuPosition } from '../models/contextMenuPosition.model';

@Directive({
  selector: '[fsContextMenuHandler]',
})
export class ContextMenuHandlerDirective {
  @Input() matMenuTrigger: MatMenuTrigger;
  @Input() node: Node;
  @Output() setUpContextMenuPosition: EventEmitter<ContextMenuPosition> =
    new EventEmitter();

  menuTopLeftPosition: ContextMenuPosition = { x: '0', y: '0' };

  @HostListener('document:click')
  onKeydownHandler() {
    this.matMenuTrigger.closeMenu();
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event: MouseEvent) {
    this.matMenuTrigger.closeMenu();
    event.preventDefault();
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';
    this.matMenuTrigger.menuData = { node: this.node };

    this.setUpContextMenuPosition.emit(this.menuTopLeftPosition);
    this.delay(200).then(() => {
      this.matMenuTrigger.openMenu();
    });
  }

  delay(delayTime: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(1);
      }, delayTime);
    });
  }
}
