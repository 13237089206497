import { ApiService } from './api.service';
import { ImagecaptionService } from './../../dashboard/project/dashboard-shared/left-menu/menus/administration/imagecaption-report/imagecaption-report.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthServerProvider } from './auth.jwt.service';
import { AppSettings } from '../../appSettings/appSettings';
import { ViewProjectService } from './view-project.service';
import { SidebarService } from '../../dashboard/project/sidebar-service/sidebar-service';
import { LauncherService } from '../../dashboard/launcher/launcher.service';
import { PaginationService } from '../../dashboard/project/dashboard-shared/pagination/pagination.service';
import { UserDataService } from './userdata.service';
import * as XLSX from 'xlsx';
import { utils, WorkBook, write } from 'xlsx';
import { saveAs } from 'file-saver';
import printJS from 'print-js';
import { ApiImageService } from '../../api-image.service';
import { environment } from '../../../environments/environment';
import { AlbumColorTagsService } from './album-color-tags.service';
import { UserDetails } from '../models/userdetails.model';
import { DomainConfig } from '../models/domainConfig.model';
import { SelectedImagesStore } from '../store/selected-images-store';
import { LastSelectedImageStore } from '../store/last-selected-image-store';
import { UserRole } from '../enum/user-role.enum';
import { FilterEnum } from '../enum/filter.enum';

@Injectable()
export class UserService {
  // todo refactor this to not have reset on each page. It should be owerwritten only once on auth.page
  private currentUserSubject = new BehaviorSubject<UserDetails>(
    new UserDetails(),
  );
  public currentUserGlobal = this.currentUserSubject.asObservable();
  /**
   *   todo try to merge this subject with getCurrentUserAuthority
   *   todo probably refactor whole logic.
   *   todo We can reset authority once, then we can just overwrite it on each call, but do not reset to ROLE_EXTERNAL
   * @private
   */
  private userAuthority = new BehaviorSubject<string>(UserRole.ROLE_EXTERNAL);
  userAuthority$ = this.userAuthority.asObservable();

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  private isSelectionDisable = new BehaviorSubject<any>(1);
  public isSelectionDisableGlobal = this.isSelectionDisable.asObservable();

  private refreshTokenErrorStatus = new BehaviorSubject<any>(null);

  private inactivityLogout = new BehaviorSubject<any>(false);

  constructor(
    private apiService: ApiService,
    private jwtService: AuthServerProvider,
    private router: Router,
    private viewProjectService: ViewProjectService,
    private sidebarService: SidebarService,
    private paginationService: PaginationService,
    private launcherService: LauncherService,
    private userDataService: UserDataService,
    private imagecaptionService: ImagecaptionService,
    private apiImageService: ApiImageService,
    private albumColorTagsService: AlbumColorTagsService,
    private selectedImagesStore: SelectedImagesStore,
    private lastSelectedImageStore: LastSelectedImageStore,
  ) {}

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  populate() {
    // If JWT detected, attempt to get & store user's info
    if (this.jwtService.getToken()) {
      // Will Activate below code if we validate user and continue from cureent page after reload
      this.router.navigate(['/']);
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  setAuth(data) {
    // Save JWT sent from server in localstorage
    this.jwtService.saveToken(data.id_token);
    if (data.refresh_token) {
      this.jwtService.saveRefreshToken(data.refresh_token);
    }
  }

  setCurrentUser(user: UserDetails) {
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  setRefreshTokenErrorStatus(status) {
    this.refreshTokenErrorStatus.next(status);
  }

  setInactivityLogout(status) {
    this.inactivityLogout.next(status);
  }

  getRefreshTokenErrorStatus(): number {
    return this.refreshTokenErrorStatus.getValue();
  }

  getInactivityLogout(): number {
    return this.inactivityLogout.getValue();
  }

  updateWebImageSrc(user: UserDetails) {
    if (user.userProfileDTO && user.userProfileDTO.webImageSource) {
      // checking that webImageSource received
      const index = user.userProfileDTO.webImageSource - 1;
      if (environment.internalBaseUrl[index]) {
        // checking that internalBaseUrl has value with webImageSource index
        this.apiImageService.setInternalBaseUrl(
          environment.internalBaseUrl[index],
        );
      }
    }
  }

  getCurrentUser(): UserDetails {
    return this.currentUserSubject.value;
  }

  mergeWithCurrentUser(data: UserDetails): UserDetails {
    return { ...data, ...this.getCurrentUser() };
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    this.jwtService.destroyRefreshToken();
    // Set current user to an empty object
    this.currentUserSubject.next(new UserDetails());
    this.viewProjectService.resetCurrentProjectAuthority();
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    // Remove Project Data
  }

  getAuthenticationStatus() {
    return this.isAuthenticatedSubject;
  }

  attemptAuth(type, credentials): Observable<any> {
    return this.apiService.post(type, credentials).pipe(
      map((data) => {
        if (data.id_token) {
          this.setAuth(data);
        }
        return data;
      }),
    );
  }

  refreshAuth(refreshToken): Observable<any> {
    const url = AppSettings.REFRESH_TOKEN;
    return this.apiService.post(url, refreshToken).pipe(
      map((data) => {
        if (Object.prototype.hasOwnProperty.call(data, 'status')) {
          if (!data.status) {
            this.purgeAuth();
            this.router.navigateByUrl('/');
          } else {
            if (data.id_token) {
              this.setAuth(data);
            }
            return data;
          }
        } else {
          if (data.id_token) {
            this.setAuth(data);
          }
          return data;
        }
      }),
    );
  }

  userDetail(type): Observable<UserDetails> {
    return this.apiService.get(type).pipe(
      map((data) => {
        this.setCurrentUser(data);
        this.setUserAuthority(data.authorities[0]);
        this.updateWebImageSrc(data);
        this.viewProjectService.setCurrentUserId(data.id);
        this.viewProjectService.setCurrentUserName(
          data.firstName + ' ' + data.lastName,
        );
        return data;
      }),
    );
  }

  // Update the user on the server (email, pass, etc)
  update(user): Observable<UserDetails> {
    return this.apiService.put('/user', { user }).pipe(
      map((data) => {
        // Update the currentUser observable
        this.currentUserSubject.next(data);
        return data;
      }),
    );
  }

  signOut(refreshToken) {
    const url = AppSettings.LOG_OUT;
    return this.apiService.post(url, refreshToken).pipe(map((data) => data));
  }

  setIsSelectionDisable(inc) {
    this.isSelectionDisable.next(inc);
  }

  resetData() {
    this.selectedImagesStore.clear();
    this.viewProjectService.resetProjectData();
    this.viewProjectService.resetProjectImageIDs();
    this.viewProjectService.resetTotalImageCount();
    this.viewProjectService.resetProjectDetailPermissionData();
    this.viewProjectService.resetExecutiveAlbumID();
    this.viewProjectService.reSetSensitiveID();
    this.viewProjectService.resetAlbumsInfo();
    this.sidebarService.resetAlbumListData();
    this.sidebarService.resetAlbumImageData();
    this.viewProjectService.reSetWideEditalbumsInfo();
    this.sidebarService.setDeSelectedAlbum({});
    this.sidebarService.setUpdatedTaggingAlbum({});
    this.sidebarService.setUpdatedTaggingSignOffAlbum({});
    this.sidebarService.setSelectedAlbumName({});
    this.sidebarService.removeHoverAlbum();
    this.sidebarService.removeHoverAlbumClicked();
    this.lastSelectedImageStore.clear();
    this.paginationService.setPaginationIndex(0);
    this.viewProjectService.setExecutiveAlbum(false);
    this.viewProjectService.isPiorityPixDataLoaded.next(false);
    this.viewProjectService.priorityPixImageCount.next(0);
    this.viewProjectService.setCurrentPageNumber(null);
    this.viewProjectService.setTotalPageNumber(0);
    this.launcherService.resetSelectedProject();
    this.viewProjectService.isProjectDataLoadedFromNoteReports.next(false);
    this.viewProjectService.setCurrentFilter(FilterEnum.ShowAll);
    this.viewProjectService.resetProjectID();
    this.albumColorTagsService.setIsColorPanelEnabled(false);
    this.userDataService.resetUserData();
    this.viewProjectService.setActivatedLeftTabIndex(-1);
    this.sidebarService.setUpdatedTaggingSignOffAlbum({});
    this.sidebarService.setUpdatedTaggingWideEditAlbum({});
    this.viewProjectService.priorityPixHandling.next(false);
    this.imagecaptionService.reSetImagecaptionReport();
    this.resetUserAuthority();
    this.viewProjectService.resetCurrentProjectAuthority();
    this.viewProjectService.resetSelectedProjectData();
  }

  setUserAuthority(userRole: string): void {
    this.userAuthority.next(userRole);
  }

  resetUserAuthority(): void {
    this.userAuthority.next('');
  }

  resetGalleryData() {
    this.launcherService.setIsGallaryModeStatus(false);
    this.launcherService.setIsFavGallaryModeStatus(false);
    this.launcherService.setIsProjectUseSetupStatus(false);
  }

  exportReport(reportName, arrReportData, reportFileName) {
    const wsName = reportName;
    const wb: WorkBook = { SheetNames: [], Sheets: {} };
    const ws: any = utils.json_to_sheet(arrReportData);
    wb.SheetNames.push(wsName);
    wb.Sheets[wsName] = ws;
    const wbout = write(wb, {
      bookType: 'xlsx',
      bookSST: true,
      type: 'binary',
    });

    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) {
        // tslint:disable-next-line: no-bitwise
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    }
    saveAs(
      new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
      this.viewProjectService.getProjectDetailPermissionData()
        .projectGroupName +
        ' ' +
        reportFileName,
    );
  }

  exportReportApprovalReport(reportName, arrReportData, reportFileName) {
    const wsName = reportName;
    const wb: WorkBook = { SheetNames: [], Sheets: {} };
    const ws: any = XLSX.utils.json_to_sheet(arrReportData);
    wb.SheetNames.push(wsName);
    wb.Sheets[wsName] = ws;
    const wbout = write(wb, {
      bookType: 'xlsx',
      bookSST: true,
      type: 'binary',
    });

    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) {
        // tslint:disable-next-line: no-bitwise
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    }
    saveAs(
      new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
      reportFileName +
        '_' +
        this.viewProjectService.getProjectDetailPermissionData().projectName +
        '.xlsx',
    );
  }

  exportPDFData(jsonData, columnNames?) {
    printJS({ printable: jsonData, properties: columnNames, type: 'json' });
  }

  exportPDFDataInHorizontalOrientation(jsonData, columnNames?) {
    printJS({
      printable: jsonData,
      properties: columnNames,
      type: 'json',
      gridHeaderStyle: 'border: 2px solid #282828; font-size: 10px',
      style: '@page { size: Letter landscape; }',
      gridStyle: 'border: 2px solid #282828; font-size: 10px',
    });
  }

  getColumnsName(columObj) {
    return Object.keys(columObj);
  }

  getFullUserName(): string {
    return (
      this.getCurrentUser().firstName + ' ' + this.getCurrentUser().lastName
    );
  }

  isAdminWithTaggerRole(): boolean {
    return (
      this.getCurrentUser().authorities[0] === UserRole.ROLE_ADMIN &&
      this.getCurrentUser().userProfileDTO.tagger
    );
  }

  postDomainData(data): Observable<any> {
    const path = AppSettings.DOMAIN_DATA;
    return this.apiService.post(path, data).pipe(
      map((res) => {
        return res as DomainConfig;
      }),
    );
  }
}
