import { DialogState, FsStateDialogs } from './dialog-state';
import DialogStateListenerData = FsStateDialogs.DialogStateListenerData;

export class DialogsStatePool {
  private pool: Map<string, DialogState> = new Map<string, DialogState>();

  get(id: string) {
    return this.pool.get(id);
  }

  set(id: string, data: DialogStateListenerData) {
    const dialogState = new DialogState(data);
    this.pool.set(id, dialogState);
  }

  remove(id: string) {
    this.pool.delete(id);
  }

  has(id: string) {
    return this.pool.has(id);
  }

  getEntries() {
    return Array.from(this.pool.entries());
  }

  destroy() {
    this.getEntries().forEach(([id, state]) => state.destroy());
  }
}
