<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{'view-project.designation.title' | translate}}
  </div>
  <button
    type="button"
    (click)="closeWindow()"
    class="fs-close-btn w-auto"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle"></i>
  </button>
</div>
<div id="designation" mat-dialog-content role="dialog" aria-hidden="true">
  <div>L_00025.jpg</div>
  <div class="text-uppercase my-2">
    <mat-select
      class="fs-selectbox-dark pl-2 py-1 pr-3"
      required
      disableOptionCentering
      placeholder="{{'view-project.group-single.select' | translate}}"
      optionLabel="name"
      (valueChange)="onDesignationValueChanged($event)"
      [(ngModel)]="designationValue"
    >
      <mat-option
        class="fs-sm-txt fs-selectbox-options"
        *ngFor="let destination of arrListDestinationOptions"
        [value]="destination.name"
      >
        {{destination.name}}
      </mat-option>
    </mat-select>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <div class="mf-sngle ftr-btns">
    <div class="pull-right">
      <button
        (click)="onClickApply()"
        class="btn h-auto fs-close-btn w-auto ml-3"
        id="mail_send_btn"
      >
        {{'view-project.designation.apply' | translate}}
      </button>
    </div>
    <div class="pull-right">
      <button
        class="btn fs-close-btn w-auto h-auto ml-3"
        (click)="closeWindow()"
      >
        {{'view-project.designation.close' | translate}}
      </button>
    </div>
  </div>
</div>
