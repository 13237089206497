<div
  class="fs-drop-down-btn fs-btn-container"
  [class.open]="customFilterMenuTrigger.menuOpen"
>
  <div
    #customFilterMenuTriggerRef
    class="fs-cursor-pointer"
    [matMenuTriggerFor]="customFilterMenu"
    #customFilterMenuTrigger="matMenuTrigger"
  >
    {{ selectedOpt | slice : 0 : 25 }}
    <i class="fa fa-caret-down" aria-hidden="true"></i>
  </div>
</div>

<mat-menu
  class="w-100"
  #customFilterMenu="matMenu"
  [classList]="
    'overflow-hidden mw-100 fs-drop-down-menu fs-drop-down-no-padding'
  "
>
  <div
    class="container scroll-section overflow-auto py-1"
    [style.width]="customFilterMenuTriggerRef.offsetWidth - 2 + 'px'"
  >
    <div class="row">
      <div class="col">
        <div class="fs-cursor-default fs-sm-txt py-1 fs-txt-white-dark-color">
          {{ 'left-menu.filter.label' | translate }}
        </div>
        <div
          class="ml-3 fs-cursor-pointer fs-txt-white-grey-color-hover"
          *ngFor="let filterName of staticFilterArray"
        >
          <div
            class="fs-txt-white-dark-color py-1 fs-sm-txt"
            [class.fs-element-highlighted]="
              isFilterSelected(selectedOpt, filterName.name)
            "
            (click)="clickOnSelectFilter(filterName.name, $event)"
          >
            {{ 'left-menu.filter.options' + '.' + filterName.key | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="projectAlbumList.length > 0">
      <div class="col">
        <div class="fs-cursor-default fs-txt-white-dark-color py-1 fs-sm-txt">
          {{ 'left-menu.filter.options.projectAlbum' | translate }}
        </div>
        <div
          class="ml-3 fs-cursor-pointer fs-txt-white-grey-color-hover"
          *ngFor="let album of projectAlbumList"
        >
          <div
            class="fs-txt-white-dark-color py-1 fs-sm-txt"
            [class.fs-element-highlighted]="
              isFilterSelected(album.id, currentFilterAlbumId)
            "
            (click)="clickOnAlbum(album, $event)"
          >
            {{ album.value | slice : 0 : 25 }} ({{ album.assetCount }})
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="customAlbumList.length > 0">
      <div class="col">
        <div class="fs-cursor-default fs-txt-white-dark-color py-1 fs-sm-txt">
          {{ 'left-menu.filter.options.customAlbum' | translate }}
        </div>
        <div
          class="ml-3 fs-cursor-pointer fs-txt-white-grey-color-hover"
          *ngFor="let album of customAlbumList | sortBy : 'value'"
        >
          <div
            class="fs-txt-white-dark-color py-1 fs-sm-txt"
            [class.fs-element-highlighted]="
              isFilterSelected(album.id, currentFilterAlbumId)
            "
            (click)="clickOnAlbum(album, $event)"
          >
            {{ album.value | slice : 0 : 25 }} ({{ album.assetCount }})
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="fs-cursor-default fs-txt-white-dark-color py-1 fs-sm-txt">
          {{ 'left-menu.filter.options.taggingWith' | translate }}
        </div>
        <div
          class="ml-3 fs-cursor-pointer fs-txt-white-grey-color-hover"
          *ngFor="let Tagw_list of dataTaggingWith"
        >
          <div
            class="fs-txt-white-dark-color py-1 fs-sm-txt"
            [class.fs-element-highlighted]="
              isFilterSelected(selectedOpt, Tagw_list.tagName)
            "
            (click)="clickOnTaggingWith(Tagw_list, $event)"
          >
            {{ Tagw_list.tagName }} ({{ Tagw_list.imageCount }})
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="fs-cursor-default py-1 fs-txt-white-dark-color fs-sm-txt">
          {{ 'left-menu.filter.options.general' | translate }}
        </div>
        <div
          class="ml-3 fs-cursor-pointer fs-txt-white-grey-color-hover"
          *ngFor="let G_list of dataGeneral"
        >
          <div
            class="fs-txt-white-dark-color py-1 fs-sm-txt"
            [class.fs-element-highlighted]="
              isFilterSelected(selectedOpt, G_list.name)
            "
            (click)="clickOnGeneral(G_list.name, $event)"
          >
            {{
              'left-menu.filter.generalOptions' + '.' + G_list.key | translate
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="viewUntaggedAccess">
      <div class="col">
        <div class="fs-cursor-default py-1 fs-txt-white-dark-color fs-sm-txt">
          {{ 'left-menu.filter.options.tagging' | translate }}
        </div>
        <div
          class="ml-3 fs-cursor-pointer fs-txt-white-grey-color-hover"
          *ngFor="let list of dataTagging"
        >
          <div
            class="fs-txt-white-dark-color py-1 fs-sm-txt"
            [class.fs-element-highlighted]="
              isFilterSelected(selectedOpt, list.name)
            "
            (click)="clickOnDataTaggingFilter(list.name, $event, list)"
          >
            {{ list.key }}
            <span
              *ngIf="list.count >= 0"
              class="p-0 fs-txt-white-grey-color-hover"
            >
              ({{ list.count }})</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="fs-cursor-default fs-txt-white-dark-color py-1 fs-sm-txt">
          {{ 'left-menu.filter.options.customFilter' | translate }}
        </div>
        <div
          class="ml-3 fs-cursor-pointer fs-txt-white-grey-color-hover"
          *ngFor="let list of customeFilter"
        >
          <div
            class="fs-txt-white-dark-color py-1 fs-sm-txt"
            [class.fs-element-highlighted]="
              isFilterSelected(selectedOpt, list.name)
            "
            *ngIf="list.visible"
            (click)="clickOnCustomeFilter(list.name, $event)"
          >
            {{ 'left-menu.filter' + '.' + list.key | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-menu>
