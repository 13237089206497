import { Component, Input, OnInit } from '@angular/core';
import { AlbumColorTagsService } from '../../services/album-color-tags.service';
import { AssetInfoDTOList } from '../../models/assetInfo.model';

@Component({
  selector: 'fs-image-color-tag-panel',
  templateUrl: './image-color-tag-panel.component.html',
  styleUrls: ['./image-color-tag-panel.component.scss'],
})
export class ImageColorTagPanelComponent {
  @Input() imgData: AssetInfoDTOList;

  constructor(public albumColorTagsService: AlbumColorTagsService) {}
}
