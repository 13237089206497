import { Utils } from './../../shared/utils/utils';
import { Component, Input, OnInit } from '@angular/core';
import { LauncherService } from '../launcher/launcher.service';
import { Router } from '@angular/router';
import { DepartmentService } from '../department/department.service';
import { UntypedFormControl } from '@angular/forms';
import { ViewProjectService } from '../../shared/services/view-project.service';
import { SidebarService } from '../project/sidebar-service/sidebar-service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ProjectList } from '../../shared/models/projectlist.model';
import { Department, Items } from '../department/department.model';
import { UnsubscriberComponent } from '../../shared/components/unsubscriber/unsubscriber.component';
import { ViewGalleryResumeModalStateService } from '../../shared/services/view-gallery-resume-modal.service';
import {
  DomainConfig,
  INewFeatureAnnounce,
} from '../../shared/models/domainConfig.model';
import { DomainConfigService } from '../../shared/services/domain-config.service';
import { DashboardEventBus } from '../dashboard-manager/dashboard-event-bus';
import { DashboardSearchProjectEvent } from '../dashboard-manager/dashboard-manager-rules/dashboard-search-project/events/dashboard-search-project-event';
import { DashboardSearchProjectHandler } from '../dashboard-manager/dashboard-manager-rules/dashboard-search-project/handler/dashboard-search-project-handler';

@Component({
  selector: 'fs-search-project',
  templateUrl: './search-project.component.html',
  styleUrls: ['./search-project.component.scss'],
})
export class SearchProjectComponent
  extends UnsubscriberComponent
  implements OnInit
{
  @Input() fromDepartment: boolean;
  _searchList: any[];
  projectName: string;
  arrAlbumList: any = [];
  initDataAlbumList: Items[] | ProjectList[] = [];
  selectedProject: any = null;
  domainConfig: DomainConfig;
  public searchFormControl = new UntypedFormControl();

  @Input() set searchList(value: Department[] | ProjectList[]) {
    this._searchList = value;
    this.arrAlbumList = this.setInitialAlbumList(value);
  }

  constructor(
    private launcherService: LauncherService,
    private viewProjectService: ViewProjectService,
    private router: Router,
    private viewGalleryResumeModalService: ViewGalleryResumeModalStateService,
    private dashboardEventBus: DashboardEventBus,
    private sidebarService: SidebarService,
    private departmentService: DepartmentService,
    private domainConfigService: DomainConfigService,
  ) {
    super();
  }

  ngOnInit() {
    this.searchFormControl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(300))
      .subscribe((data) => {
        this.filterProject(data);
      });

    this.domainConfigService.domainConfigDataGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((domainData: DomainConfig) => {
        if (!Utils.isObjectEmpty(domainData)) {
          this.domainConfig = domainData;
        }
      });

    this.launcherService.selectedProjectGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedProject) => {
        if (selectedProject) {
          this.selectedProject = Number(selectedProject);
        } else {
          this.selectedProject = null;
        }
      });
  }

  setInitialAlbumList(value) {
    return (this.initDataAlbumList = this.fromDepartment
      ? this.departmentService.initProjectDataList(value, this.selectedProject)
      : value);
  }

  navigateToProject(selectedProject) {
    this.viewProjectService.setSelectedProjectData(selectedProject);
    this.viewProjectService.setProjectRole(selectedProject.role);

    if (!this.fromDepartment) {
      if (selectedProject.departmentId && selectedProject.departmentId !== 0) {
        this.launcherService.updateProjectChangeState(true);
        this.launcherService.setSelectedProject(selectedProject.departmentId);
        this.launcherService.setSelectedProjectName(selectedProject.label);
      } else {
        this.launcherService.updateProjectChangeState(true);
        this.launcherService.setSelectedProject(selectedProject.value);
        this.launcherService.setSelectedProjectName(selectedProject.label);
      }
    }

    this.dashboardEventBus.emit(
      new DashboardSearchProjectEvent(
        new DashboardSearchProjectHandler(
          this.fromDepartment,
          this.selectedProject,
          selectedProject,
        ),
      ),
    );
  }

  filterProject(event) {
    const query = event;
    if (this.fromDepartment) {
      this.arrAlbumList = this.departmentService.filterDepartment(
        query,
        this.initDataAlbumList as Items[],
      );
    } else {
      this.arrAlbumList = this.filterProjectName(query);
    }
  }

  filterProjectName(query): any[] {
    if (!query) {
      return this._searchList;
    }
    return this._searchList.filter(
      (option) =>
        option.label.toLowerCase().includes(query.toLowerCase()) &&
        option.groupId > 0,
    );
  }
}
