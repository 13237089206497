<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title" id="taggign_view_1">
    {{ 'image-caption.addShortCut' | translate }}
  </div>
  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    (click)="closeDialog()"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle"></i>
  </button>
</div>
<div mat-dialog-content>
  <div class="key-add-container row">
    <div class="key-add-container_key col-2">
      <span>{{ 'image-caption.key' | translate }}</span>
      <input
        fsAutofocus
        class="key-add-container_labelChange form-control"
        type="text"
        [(ngModel)]="keyIdentifier"
        (focus)="onFocusFn()"
        (blur)="onBlurFn()"
      />
    </div>
    <div class="key-add-container_dis col-sm-10">
      <span>{{ 'image-caption.enter' | translate }}</span>
      <input
        type="text"
        [(ngModel)]="keyDescription"
        (keyup.enter)="
          addKeyFn({
            keyDescription: keyDescription,
            keyIdentifier: keyIdentifier
          })
        "
        (focus)="onFocusFn()"
        (blur)="onBlurFn()"
        class="form-control"
      />
    </div>
  </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <div>
    <button
      value="close"
      class="fs-dialog-btn w-auto ml-3"
      (click)="closeDialog()"
    >
      {{ 'image-caption.buttons.close' | translate }}
    </button>
  </div>
  <div>
    <button
      value="Add"
      class="fs-dialog-btn w-auto ml-3"
      (click)="
        addKeyFn({
          keyDescription: keyDescription,
          keyIdentifier: keyIdentifier
        })
      "
    >
      {{ 'image-caption.buttons.add' | translate }}
    </button>
  </div>
</div>
