import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {
  transform(array: any, field: string): any[] {
    if (!array) {
      return array;
    }
    try {
      array.sort((a: any, b: any) => {
        if ((a[field] || '').toLowerCase() < (b[field] || '').toLowerCase()) {
          return -1;
        } else if (
          (a[field] || '').toLowerCase() > (b[field] || '').toLowerCase()
        ) {
          return 1;
        } else {
          return 0;
        }
      });
    } catch {
      return array;
    }
    return array;
  }
}
