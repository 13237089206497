<div
  class="fs-sm-txt fs-txt-white-dark-color list-unstyled components sideBar-menu py-2 pr-2"
>
  <div class="right-content position-relative">
    <div  class="
          fs-cursor-pointer
          fs-md-txt mr-2
          text-uppercase
          fs-txt-white-grey-color-hover
          fs-toggle-btn"
         (click)="onOpenMetadata()"
    >
      {{
      'left-menu.tab-bar.sidemenus.administration.meta-data.button.edit'
        | translate
      }}
    </div>
      <div class="container d-block sub-menu-list sub-menu-text-spacing m-0">
        <div class="row fs-md-txt pb-1 pl-3" *ngIf="selectedImagesStore.size() > 1">
            {{
            'left-menu.tab-bar.sidemenus.administration.meta-data.multipleImageLabel'
              | translate
            }}
        </div>
        <div class="fs-md-txt row pb-1 pl-3" *ngIf="!selectedImagesStore.size()">
          {{
          'left-menu.tab-bar.sidemenus.imageInfo.labels.noImageLabel'
            | translate
          }}
        </div>
        <div *ngIf="selectedImagesStore.size() === 1">
          <div class="fs-sm-txt row pb-1">
            <div class="col-4 fs-break-word pr-0 font-weight-bold">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.creator'
                | translate
              }}:
            </div>
            <div class="col-8 pl-1 font-weight-normal" *ngIf="isDataLoaded; else loader">
              <span class="font-weight-normal fs-overflow-wrap-break-word">{{ selectedMetaData.metaCreator }}</span>
            </div>
          </div>
          <div class="fs-sm-txt row pb-1">
            <div class="col-4 fs-break-word pr-0 font-weight-bold">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.credit'
                | translate
              }}:
            </div>
            <div class="col-8 pl-1 font-weight-normal" *ngIf="isDataLoaded; else loader">
              <span class="font-weight-normal fs-overflow-wrap-break-word">{{ selectedMetaData.metaCredit }}</span>
            </div>
          </div>
          <div class="fs-sm-txt row pb-1">
            <div class="col-4 fs-break-word pr-0 font-weight-bold">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.title'
                | translate
              }}:
            </div>
            <div class="col-8 pl-1 font-weight-normal" *ngIf="isDataLoaded; else loader">
              <span class="font-weight-normal fs-overflow-wrap-break-word">{{ selectedMetaData.metaTitle }}</span>
            </div>
          </div>
          <div class="fs-sm-txt row pb-1">
            <div class="col-4 fs-break-word pr-0 font-weight-bold">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.description-caption'
                | translate
              }}:
            </div>
            <div class="col-8 pl-1 font-weight-normal" *ngIf="isDataLoaded; else loader">
              <span class="font-weight-normal fs-overflow-wrap-break-word">{{ selectedMetaData.metaDescription }}</span>
            </div>
          </div>
          <div class="fs-sm-txt row pb-1">
            <div class="col-4 fs-break-word pr-0 font-weight-bold">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.source'
                | translate
              }}:
            </div>
            <div class="col-8 pl-1 font-weight-normal" *ngIf="isDataLoaded; else loader">
              <span class="font-weight-normal fs-overflow-wrap-break-word">{{ selectedMetaData.metaSource }}</span>
            </div>
          </div>
          <div class="fs-sm-txt row pb-1">
            <div class="col-4 fs-break-word pr-0 font-weight-bold">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.copyright-status'
                | translate
              }}:
            </div>
            <div class="col-8 pl-1 font-weight-normal" *ngIf="isDataLoaded; else loader">
              <span class="font-weight-normal fs-overflow-wrap-break-word">{{ selectedMetaData.metaCopyrightStatus }}</span>
            </div>
          </div>
          <div class="fs-sm-txt row pb-1">
            <div class="col-4 fs-break-word pr-0 font-weight-bold">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.copyright-notice'
                | translate
              }}:
            </div>
            <div class="col-8 pl-1 font-weight-normal" *ngIf="isDataLoaded; else loader">
              <span class="font-weight-normal fs-overflow-wrap-break-word">{{ selectedMetaData.metaCopyrightNotice }}</span>
            </div>
          </div>
          <div class="fs-sm-txt row pb-1">
            <div class="col-4 fs-break-word pr-0 font-weight-bold">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.keywords'
                | translate
              }}:
            </div>
            <div class="col-8 pl-1 font-weight-normal" *ngIf="isDataLoaded; else loader">
              <div *ngFor="let keyword of selectedMetaData.metaKeywords">
                <span class="font-weight-normal">{{keyword}}</span>
              </div>
            </div>
          </div>
          <div class="fs-sm-txt row pb-1">
            <div class="col-4 fs-break-word pr-0 font-weight-bold">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.persons-shown'
                | translate
              }}:
            </div>
            <div class="col-8 pl-1" *ngIf="isDataLoaded; else loader">
              <div *ngFor="let personShown of selectedMetaData.metaPersonsShown">
                <span class="font-weight-normal">{{personShown}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>

<ng-template #loader>
  <span class="font-weight-normal text-lowercase">
    {{'loading'| translate}}...</span>
</ng-template>
