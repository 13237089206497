import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { SidebarService } from '../../../sidebar-service/sidebar-service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserRole } from '../../../../../shared/enum/user-role.enum';
import { Modalstatusservice } from '../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { UserService } from '../../../../../shared/services/user.service';
import { ConstantsGlobal } from '../../../../../shared/constants/constants';
import { Utils } from '../../../../../shared/utils/utils';
import { SortByPipe } from '../../../../../shared/pipe/sort-by.pipe';
import { takeUntil } from 'rxjs/operators';
import { Activemodal } from '../../../../../shared/models/activemodal.model';
import { AppStateService } from '../../../../../shared/services/app-state.service';
import { SelectedImagesStore } from '../../../../../shared/store/selected-images-store';
import { SelectItemGroup } from '../../../../../shared/models/select-item';
import { FilterEnum } from '../../../../../shared/enum/filter.enum';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import { ProjectRole } from '../../../../../shared/enum/project-role.enum';

class GotoSettings {
  gotoPageNumber: string;
  gotoFileName: string;
}

@Component({
  selector: 'fs-goto',
  templateUrl: './goto.component.html',
  styleUrls: ['./goto.component.scss'],
})
export class GotoComponent extends UnsubscriberComponent implements OnInit {
  static identifier = 'GotoComponent';
  albumList: Array<any> = [];
  gotoSettings: GotoSettings = new GotoSettings();
  gotoPageForm: UntypedFormGroup;
  gotofileForm: UntypedFormGroup;
  gotoImageInForm: UntypedFormGroup;
  activeModal: Activemodal = new Activemodal();

  isImageSelected = true;
  dataTaggingWith: Array<any> = [];
  customAlbums: Array<any> = [];
  publishedTags: Array<any> = [];

  albumsList: Array<any> = [];
  actorList: Array<any> = [];
  isShow = false;
  selectedValue: any = 'Go To Image In';
  selectedDropDownValue = 'Select';
  imageName: any;
  imageId: any;
  isExecutiveAlbumView = false;
  projectAuthority: string;

  groupedAlbumeActor: SelectItemGroup[] = [];
  selectedAlbumeActor: string;

  isPageNumGoValid = true;
  isFileNameGoValid = true;
  isAlbumActorGoValid = true;
  constantsGlobalData: any;
  projectRole = ProjectRole;

  constructor(
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private viewProjectService: ViewProjectService,
    private sidebarService: SidebarService,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    public dialogRef: MatDialogRef<GotoComponent>,
    private selectedImagesStore: SelectedImagesStore,
    private appStateService: AppStateService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    super();
    this.gotoPageForm = fb.group({
      pageNumber: ['', Validators.required],
    });

    this.gotofileForm = fb.group({
      fileName: ['', Validators.required],
    });

    this.gotoImageInForm = fb.group({
      selectedAlbumeActor: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.gotoSettings.gotoPageNumber = '';
    this.gotoSettings.gotoFileName = '';

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.viewProjectService.isExecutiveAlbumGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isExecutiveAlbumView = status;
      });

    this.selectedDropDownValue = 'Select';
    this.isPageNumGoValid = true;
    this.isFileNameGoValid = true;
    this.isAlbumActorGoValid = true;

    this.sidebarService.albumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.albumList = Utils.getValuesByKey(albumList.albums, 'id');
        }
      });

    this.sidebarService.updatedAlbumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.albumList = Utils.getValuesByKey(albumList, 'id');
        }
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.dataTaggingWith = res.projectTagListDTO;
        } else {
          this.dataTaggingWith = [];
        }
      });

    this.selectedImagesStore.headItem$
      .pipe(takeUntil(this.destroy$))
      .subscribe((headSelectedImage) => {
        this.selectedValue = 'Go To Image In';
        this.isShow = false;
        this.groupedAlbumeActor = [];
        this.selectedAlbumeActor = '';
        if (this.selectedImagesStore.size() === 1 && headSelectedImage.id) {
          this.selectedValue = headSelectedImage.name;
          this.isImageSelected = true;
          this.customAlbums = headSelectedImage.customAlbums;
          this.publishedTags = headSelectedImage.publishedTags;
          this.imageName = headSelectedImage.name;
          this.imageId = headSelectedImage.id;
          let albumsListArr = [];
          const actorListArr = [];

          this.groupedAlbumeActor.push({
            label: this.translate.instant('top-menu.goto.dropdown.select'),
            items: [
              {
                label: this.translate.instant('top-menu.goto.dropdown.showall'),
                value: {
                  item: 'showall',
                  type: 'showall',
                },
              },
            ],
          });
          if (this.customAlbums) {
            this.albumList.forEach((item, index) => {
              if (this.customAlbums.indexOf(item.id) !== -1) {
                albumsListArr.push(item);
              }
            });

            albumsListArr = SortByPipe.prototype.transform(
              albumsListArr,
              'value',
            );
            this.albumsList = albumsListArr.map((item) => {
              return {
                label: item.value,
                value: { item: item, type: 'album' },
              };
            });
            if (this.albumsList.length > 0) {
              this.groupedAlbumeActor.push({
                label: this.translate.instant('top-menu.goto.dropdown.album'),
                items: this.albumsList,
              });
            }
          }

          if (this.publishedTags) {
            if (this.publishedTags.length > 0) {
              this.dataTaggingWith.forEach((itemValue, key) => {
                if (this.publishedTags.indexOf(itemValue.id) !== -1) {
                  actorListArr.push(itemValue);
                }
              });
            }
          }

          if (headSelectedImage.generalTags) {
            if (headSelectedImage.generalTags.length > 0) {
              this.dataTaggingWith.forEach((itemValue, key) => {
                if (
                  headSelectedImage.generalTags.indexOf(itemValue.id) !== -1
                ) {
                  actorListArr.push(itemValue);
                }
              });
            }
          }

          if (actorListArr.length > 0) {
            this.actorList = actorListArr.map((item) => {
              return {
                label: item.tagName,
                value: { item: item, type: 'actor' },
              };
            });
            this.groupedAlbumeActor.push({
              label: this.translate.instant('top-menu.goto.dropdown.actor'),
              items: this.actorList,
            });
          }
        } else {
          this.isImageSelected = false;
        }
      });
  }

  onPageNumChanged(v) {
    this.isPageNumGoValid = v.trim() === '';
  }

  onFileNameChanged(v) {
    this.isFileNameGoValid = v.trim() === '';
  }

  selectedItems(selectedItem) {
    this.isAlbumActorGoValid = false;
    const selected = selectedItem;
    switch (selected.type) {
      case FilterEnum.ShowAll:
        this.selectAlbumOrActor(null, selected.item, selected.type);
        break;

      case FilterEnum.Album:
        this.selectAlbumOrActor(
          selected.item,
          selected.item.value,
          selected.type,
        );
        break;

      case FilterEnum.Actor:
        this.selectAlbumOrActor(
          selected.item,
          selected.item.tagName,
          selected.type,
        );
        break;
    }
  }

  setGotoSettings(): void {
    this.gotoSettings.gotoPageNumber = this.gotoSettings.gotoPageNumber.trim();
    this.appStateService.setGoToPage({ loaded: true, data: this.gotoSettings });
    this.gotoSettings = new GotoSettings();
    this.dialogRef.close();
  }

  setGotoSettingsFileName(): void {
    this.gotoSettings.gotoFileName = this.gotoSettings.gotoFileName.trim();
    this.appStateService.setGoTOFileName({
      loaded: true,
      data: this.gotoSettings,
    });
    this.gotoSettings = new GotoSettings();
    this.dialogRef.close();
  }

  setGotoSettingsImageIn(): void {
    this.appStateService.setGoTOAlbumActor({
      loaded: true,
      data: this.gotoSettings,
    });
    this.gotoSettings = new GotoSettings();
    this.dialogRef.close();
  }

  onClickAlbum() {
    this.isShow = !this.isShow;
  }

  selectAlbumOrActor(obj, name, type) {
    if (type === 'showall') {
      const data = { customName: this.imageName };
      obj = data;

      this.gotoSettings = {
        ...obj,
        type: type,
        imageId: this.imageId,
        imageName: this.imageName,
      };
    } else {
      this.gotoSettings = {
        ...obj,
        type: type,
        imageId: this.imageId,
        imageName: this.imageName,
      };
    }
    this.selectedDropDownValue = name;
    this.isShow = false;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeGoTO();
  }

  closeGoTO() {
    this.dialogRef.close();
    this.gotoSettings.gotoPageNumber = '';
    this.gotoSettings.gotoFileName = '';
  }
}
