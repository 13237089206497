import { Component, HostListener, OnInit } from '@angular/core';
import { ClearSingleTagService } from './clear-single-tag.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../../../../../shared/services/snack-bar.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AssetInfo } from '../../../../../../../shared/models/assetInfo.model';
import { ProjectDetailPermission } from '../../../../../../../shared/models/projectpermission.model';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../../shared/store/selected-images-store';
import { Utils } from '../../../../../../../shared/utils/utils';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';
import { MetaDataService } from '../../../../../../../shared/services/meta-data.service';

@Component({
  selector: 'fs-clear-single-tag',
  templateUrl: './clear-single-tag.component.html',
  styleUrls: ['./clear-single-tag.component.scss'],
})
export class ClearSingleTagComponent
  extends UnsubscriberComponent
  implements OnInit
{
  projectImageData: AssetInfo = new AssetInfo();
  projectDetailData: ProjectDetailPermission = new ProjectDetailPermission();
  taggingList: any[] = [];
  taggingListModel = null;
  tagList: any[] = [];
  projectTagListDTO: any;
  selectedAssestIdList: any = [];
  constantsGlobalData: any;

  constructor(
    private modalStatusService: Modalstatusservice,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private clearSingleTagService: ClearSingleTagService,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private metaDataService: MetaDataService,
    private dialogRef: MatDialogRef<ClearSingleTagComponent>,
    private selectedImagesStore: SelectedImagesStore,
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.projectTagListDTO = data.projectTagListDTO;
        }
      });

    this.taggingListModel = null;
    this.selectedAssestIdList = this.selectedImagesStore.getItemsIds();
    const data = {
      assetIds: this.selectedAssestIdList,
      projectId: this.viewProjectService.getProjectId(),
    };

    this.viewProjectService
      .getClearSingleTagsData(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((clearSingleTagsData) => {
        this.taggingList = clearSingleTagsData;

        this.taggingList.forEach((tagList) => {
          const index = this.projectTagListDTO
            .map(function (e) {
              return e.id;
            })
            .indexOf(tagList.id);
          if (index !== -1) {
            tagList.value =
              this.projectTagListDTO[index].hotkey +
              ' - ' +
              this.projectTagListDTO[index].tagName;
          }
        });
      }, (error => {
        this.apiErrorHandlerService.getHandler().handle(error);
      }));
  }

  closeWindow() {
    this.dialogRef.close();
  }

  onClickedonExecute() {
    if (this.taggingListModel) {
      this.callClearAllTagsAPI(this.taggingListModel.id);
    } else {
      this.alertCustom(this.translate.instant('view-project.alert22'));
    }
  }

  callClearAllTagsAPI(tagID) {
    this.loaderService.displayLoader(true);
    const body = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      projectId: this.viewProjectService.getProjectId(),
      tagId: tagID,
    };

    this.clearSingleTagService
      .putClearAllTag(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res) {
            this.updateProjectData(res);
            this.dialogRef.close();
            this.snackBarService.showSnackBar('Success');
          } else {
            this.alertCustom('Fail');
          }
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  updateProjectData(res) {
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    this.projectDetailData =
      this.viewProjectService.mergeWithProjectDetailPermissionData(
        this.projectDetailData,
      );

    this.selectedImagesStore.clear();
    if (res.assets.length > 0) {
      res.assets.forEach((eachObj) => {
        const imageIndex = this.projectImageData.assetInfoDTOList
          .map(function (e) {
            return e.id;
          })
          .indexOf(eachObj.assetId);

        if (imageIndex !== -1) {
          if (eachObj.status === 0) {
            this.projectDetailData.unpublishedAssets =
              this.projectDetailData.unpublishedAssets + 1;

            const imageIndex1 = this.projectImageData.assetInfoDTOList
              .map(function (e) {
                return e.id;
              })
              .indexOf(eachObj.assetId);

            if (imageIndex1 !== -1) {
              res.blankObj = [];
              this.projectImageData.assetInfoDTOList[imageIndex].generalTags =
                res.blankObj;
              this.projectImageData.assetInfoDTOList[imageIndex].publishedTags =
                res.blankObj;
            }
          } else {
            this.projectImageData.assetInfoDTOList[imageIndex].status =
              eachObj.status;
            this.projectImageData.assetInfoDTOList[imageIndex].tagKeys =
              eachObj.tagKeys;
            if (res.publishedTag) {
              const index = this.projectImageData.assetInfoDTOList[
                imageIndex
              ].publishedTags.indexOf(res.tagId);
              if (index > -1) {
                this.projectImageData.assetInfoDTOList[
                  imageIndex
                ].publishedTags.splice(index, 1);
              }
            } else {
              const index = this.projectImageData.assetInfoDTOList[
                imageIndex
              ].generalTags.indexOf(res.tagId);
              if (index > -1) {
                this.projectImageData.assetInfoDTOList[
                  imageIndex
                ].generalTags.splice(index, 1);
              }
            }

            const indexApprovedBy = this.projectImageData.assetInfoDTOList[
              imageIndex
            ].approvedBy
              .map(function (e) {
                return e.id;
              })
              .indexOf(res.tagId);

            if (indexApprovedBy > -1) {
              this.projectImageData.assetInfoDTOList[
                imageIndex
              ].approvedBy.splice(indexApprovedBy, 1);
            }

            const indexRejectedBy = this.projectImageData.assetInfoDTOList[
              imageIndex
            ].rejectedBy
              .map(function (e) {
                return e.id;
              })
              .indexOf(res.tagId);

            if (indexRejectedBy > -1) {
              this.projectImageData.assetInfoDTOList[
                imageIndex
              ].rejectedBy.splice(indexRejectedBy, 1);
            }
            this.selectedImagesStore.add(
              this.projectImageData.assetInfoDTOList[imageIndex],
            );
          }
          this.viewProjectService.setProjectData(this.projectImageData);
        } else {
          this.selectedImagesStore.add(eachObj.assetId, null);
        }
      });
    }

    if (res.assetIds.length > 0) {
      res.assetIds.forEach((id) => {
        const imageIndex = this.projectImageData.assetInfoDTOList
          .map(function (e) {
            return e.id;
          })
          .indexOf(id);

        this.projectDetailData =
          this.viewProjectService.mergeWithProjectDetailPermissionData(
            this.projectDetailData,
          );
        this.projectDetailData.unpublishedAssets =
          this.projectDetailData.unpublishedAssets + 1;
        this.viewProjectService.setProjectDetailPermissionData(
          this.projectDetailData,
        );

        if (imageIndex !== -1) {
          res.blankObj = [];
          this.projectImageData.assetInfoDTOList[imageIndex].status = 6;
          this.projectImageData.assetInfoDTOList[imageIndex].generalTags =
            res.blankObj;
          this.projectImageData.assetInfoDTOList[imageIndex].publishedTags =
            res.blankObj;
          this.projectImageData.assetInfoDTOList[imageIndex].publishedTags =
            res.blankObj;
          this.projectImageData.assetInfoDTOList[imageIndex].tagKeys =
            res.blankObj;
        }
        this.viewProjectService.setProjectData(this.projectImageData);
      });
    }

    this.projectDetailData =
      this.viewProjectService.mergeWithProjectDetailPermissionData(
        this.projectDetailData,
      );
    this.projectDetailData.projectTagListDTO.forEach((tagList, index) => {
      if (tagList.id === res.tagId) {
        this.projectDetailData.projectTagListDTO[index].imageCount =
          res.imageCount;
      }
    });
    this.viewProjectService.setProjectDetailPermissionData(
      this.projectDetailData,
    );
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
