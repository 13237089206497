import { BaseModel } from './base.model';

export class SelectedImages extends BaseModel<SelectedImages> {
  id: number;
  name: string;
  batchId: number;
  batchName: string;

  taggingView1?: string[];
  taggingView2?: string[];
  auditTags?: string[];
  generalTags?: string[];
  publishedTags?: string[];
  customAlbums?: string[];
  generalAlbums?: string[];
  excluded?: boolean;
  excludedFrom?: string[];
  approvedBy?: ApprovedBy[];
  rejectedBy?: RejectedBy[];
  photographer?: string;
  caption?: Caption;
  assetNoteDTOList?: AssetNoteDTOList[];
  url?: string;
  hidden?: boolean;
  releasedDate?: string;
  priorityPix?: boolean;
  webUpload?: boolean;
  exclusive?: boolean;
  assetType?: string;
  exclusiveHistory?: boolean;
  submittedByTagView1?: boolean;
  submittedByTagView2?: boolean;
  status?: number;
  retouch?: boolean;
  description?: string;
  keywords?: string[];
  source?: string;
  lastTagger1?: string;
  lastTagger2?: string;
  sensitive?: boolean;
  rejected?: boolean;
  executiveSignoffDTOList?: ExecutiveSignoffDTOList[];
  executiveWideEditDTOList?: ExecutiveWideEditDTOList[];

  exicutiveRejected? = 0;
  exicutiveLike? = false;
  tagKeys?: string;
  colorCodes?: string[];
}

class ApprovedBy {
  id: number;
  value: string;
}

class RejectedBy {
  id: number;
  value: string;
}

class AssetNoteDTOList {
  id: number;
  value: string;
  createdAt: string;
  type: string;
  createdBy: string;
}

class Caption {
  id: number;
  value: string;
}

class ExecutiveSignoffDTOList {
  userId: number;
  actionTypeId: number;
}

class ExecutiveWideEditDTOList {
  userId: number;
  available: string;
  like: boolean;
  actionTypeId: number;
  gs: string;
}
