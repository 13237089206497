import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fs-solo-icon-component',
  templateUrl: './solo-icon-component.component.html',
  styleUrls: ['./solo-icon-component.component.scss'],
})
export class SoloIconComponentComponent {
  @Input() imgData;
  @Input() isTaggerRole: boolean;
  @Input() assetType;
  @Input() viewProjectSoloIcon;
  @Output() clickOnGroupImages = new EventEmitter();
}
