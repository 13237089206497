<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.projectsummary-report.title'
        | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div *ngIf="this.dataSource.data.length">
    <div class="row project_summary_details">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <p class="mb-2">
          {{
            'left-menu.tab-bar.sidemenus.administration.projectsummary-report.labels.project'
              | translate
          }}
          : {{ projectReportData.name }}
        </p>
      </div>

      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-8">
            <div class="db_com psreport-p d-flex flex-column">
              <div>
                {{
                  'left-menu.tab-bar.sidemenus.administration.projectsummary-report.labels.label2'
                    | translate
                }}
                :
              </div>

              <div>
                {{
                  'left-menu.tab-bar.sidemenus.administration.projectsummary-report.labels.label3'
                    | translate
                }}
                :
              </div>

              <div>
                {{
                  'left-menu.tab-bar.sidemenus.administration.projectsummary-report.labels.label4'
                    | translate
                }}
                :
              </div>
            </div>
          </div>

          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-4">
            <div class="db_com psreport-p psreport-value d-flex flex-column">
              <div>{{ projectReportData.assets }}</div>
              <div>{{ projectReportData.lockedAssets }}</div>
              <div>{{ projectReportData.lockedApprovedAssets }}</div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-8">
            <div class="db_com psreport-p d-flex flex-column">
              <div>
                {{
                  'left-menu.tab-bar.sidemenus.administration.projectsummary-report.labels.label5'
                    | translate
                }}
                :
              </div>

              <div>
                {{
                  'left-menu.tab-bar.sidemenus.administration.projectsummary-report.labels.label6'
                    | translate
                }}
                :
              </div>

              <div>
                {{
                  'left-menu.tab-bar.sidemenus.administration.projectsummary-report.labels.label7'
                    | translate
                }}
                :
              </div>
            </div>
          </div>

          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-4">
            <div class="db_com psreport-p psreport-value d-flex flex-column">
              <div>{{ projectReportData.pendingAssets }}</div>
              <div>{{ projectReportData.excludedAssets }}</div>
              <div>{{ projectReportData.sensitiveAssets }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <div class="db_com psreport-datetime">
          {{ today | date : 'medium' }}
        </div>
      </div>
    </div>
    <div
      class="fs-overflow-auto fs-scroll-table-body fs-header-sort-color fs-project-summary-report-table"
    >
      <mat-table
        class="fs-table-container"
        [hidden]="!dataSource.data.length"
        [dataSource]="dataSource"
      >
        <!-- Action Taken Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell class="pl-2 fs-sm-txt" *matHeaderCellDef>
            {{
              'left-menu.tab-bar.sidemenus.administration.projectsummary-report.table.actor'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="p-1 fs-sm-txt"
            *matCellDef="let element"
            [class.green-row]="element.reviewStatus == 'COMPLETE'"
          >
            {{ element.name }}
          </mat-cell>
        </ng-container>
        <!-- Transaction Time Column -->
        <ng-container matColumnDef="lastLogin">
          <mat-header-cell class="pl-2 fs-sm-txt" *matHeaderCellDef>
            {{
              'left-menu.tab-bar.sidemenus.administration.projectsummary-report.table.list'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="p-1 fs-sm-txt"
            *matCellDef="let element"
            [class.green-row]="element.reviewStatus == 'COMPLETE'"
          >
            {{ element.lastLogin }}
          </mat-cell>
        </ng-container>
        <!-- Transaction Detail Column -->
        <ng-container matColumnDef="lastFinalization">
          <mat-header-cell class="pl-2 fs-sm-txt" *matHeaderCellDef>
            {{
              'left-menu.tab-bar.sidemenus.administration.projectsummary-report.table.last'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="p-1 fs-sm-txt"
            *matCellDef="let element"
            [class.green-row]="element.reviewStatus == 'COMPLETE'"
          >
            <div [innerHTML]="element.lastFinalization"></div>
          </mat-cell>
        </ng-container>
        <!-- User Name Column --><!--todo Ask to receive userName with camelCase from back-end-->
        <ng-container matColumnDef="reviewStatus">
          <mat-header-cell class="pl-2 fs-sm-txt" *matHeaderCellDef>
            {{
              'left-menu.tab-bar.sidemenus.administration.projectsummary-report.table.review'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="p-1 fs-sm-txt"
            *matCellDef="let element"
            [class.green-row]="element.reviewStatus == 'COMPLETE'"
          >
            {{ element.reviewStatus }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="excludedAssets">
          <mat-header-cell class="pl-2 fs-sm-txt" *matHeaderCellDef>
            {{
              'left-menu.tab-bar.sidemenus.administration.projectsummary-report.table.excluded'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="p-1 fs-sm-txt"
            *matCellDef="let element"
            [class.green-row]="element.reviewStatus == 'COMPLETE'"
          >
            {{ element.excludedAssets }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sensitiveAssets">
          <mat-header-cell class="pl-2 fs-sm-txt" *matHeaderCellDef>
            {{
              'left-menu.tab-bar.sidemenus.administration.projectsummary-report.table.sensitive'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="p-1 fs-sm-txt"
            *matCellDef="let element"
            [class.green-row]="element.reviewStatus == 'COMPLETE'"
          >
            {{ element.sensitiveAssets }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="pendingAssets">
          <mat-header-cell class="pl-2 fs-sm-txt" *matHeaderCellDef>
            {{
              'left-menu.tab-bar.sidemenus.administration.projectsummary-report.table.pending'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="p-1 fs-sm-txt"
            *matCellDef="let element"
            [class.green-row]="element.reviewStatus == 'COMPLETE'"
          >
            {{ element.pendingAssets }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="finalizedAssets">
          <mat-header-cell class="pl-2 fs-sm-txt" *matHeaderCellDef>
            {{
              'left-menu.tab-bar.sidemenus.administration.projectsummary-report.table.finalized'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="p-1 fs-sm-txt"
            *matCellDef="let element"
            [class.green-row]="element.reviewStatus == 'COMPLETE'"
          >
            {{ element.finalizedAssets }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="soloRejected">
          <mat-header-cell class="pl-2 fs-sm-txt" *matHeaderCellDef>
            {{
              'left-menu.tab-bar.sidemenus.administration.projectsummary-report.table.solo'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="p-1 fs-sm-txt"
            *matCellDef="let element"
            [class.green-row]="element.reviewStatus == 'COMPLETE'"
          >
            {{ element.soloRejected }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="groupRejected">
          <mat-header-cell class="pl-2 fs-sm-txt" *matHeaderCellDef>
            {{
              'left-menu.tab-bar.sidemenus.administration.projectsummary-report.table.group'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="p-1 fs-sm-txt"
            *matCellDef="let element"
            [class.green-row]="element.reviewStatus == 'COMPLETE'"
          >
            {{ element.groupRejected }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="totalRejected">
          <mat-header-cell class="pl-2 fs-sm-txt" *matHeaderCellDef>
            {{
              'left-menu.tab-bar.sidemenus.administration.projectsummary-report.table.total'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="p-1 fs-sm-txt"
            *matCellDef="let element"
            [class.green-row]="element.reviewStatus == 'COMPLETE'"
          >
            {{ element.totalRejected }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lockedApproved">
          <mat-header-cell class="pl-2 fs-sm-txt" *matHeaderCellDef>
            {{
              'left-menu.tab-bar.sidemenus.administration.projectsummary-report.table.locked'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="p-1 fs-sm-txt"
            *matCellDef="let element"
            [class.green-row]="element.reviewStatus === 'COMPLETE'"
          >
            {{ element.lockedApproved }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastRejectionPage">
          <mat-header-cell class="pl-2 fs-sm-txt" *matHeaderCellDef>
            {{
              'left-menu.tab-bar.sidemenus.administration.projectsummary-report.table.page'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="p-1 fs-sm-txt"
            *matCellDef="let element"
            [class.green-row]="element.reviewStatus == 'COMPLETE'"
          >
            {{ element.lastRejectionPage }}
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>

  <div *ngIf="!dataSource.data.length">
    <div class="row">
      <div class="no-data-found">No actor with kill rights</div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button
    class="fs-dialog-btn w-auto ml-3"
    (click)="exportReport(exportType.Excel)"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.export'
        | translate
    }}
    as xls
  </button>

  <button
    class="fs-dialog-btn w-auto ml-3"
    (click)="exportReport(exportType.Pdf)"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.export'
        | translate
    }}
    as PDF
  </button>

  <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
    {{
      'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.close'
        | translate
    }}
  </button>
</div>
