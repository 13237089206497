import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { AngularDeviceInformationService } from 'angular-device-information';

@Injectable()
export class IpService {
  private userIPAddress: BehaviorSubject<string> = new BehaviorSubject<string>(
    '',
  );
  private browserInfo: BehaviorSubject<string> = new BehaviorSubject<string>(
    '',
  );

  constructor(
    private httpClient: HttpClient,
    private deviceInformationService: AngularDeviceInformationService,
  ) {}

  get userIPAddress$(): string {
    return this.userIPAddress.getValue();
  }

  setUserIPAddress(ip): void {
    this.userIPAddress.next(ip);
  }

  get browserInfo$(): string {
    return this.browserInfo.getValue();
  }

  getBrowserInfo(): void {
    const browserData = {
      name: this.deviceInformationService.getDeviceInfo().browser,
      version: this.deviceInformationService.getDeviceInfo().browserVersion,
      os: this.deviceInformationService.getDeviceInfo().os,
      type: 'browser',
    };
    this.browserInfo.next(JSON.stringify(browserData));
  }

  receiveUserIPAddress() {
    return fetch('https://jsonip.com', { mode: 'cors' });
  }
}
