import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettings } from '../../appSettings/appSettings';
import { AlbumList, Department, Items } from './department.model';
import { ApiService } from '../../shared/services/api.service';

@Injectable()
export class DepartmentService {
  private department = new BehaviorSubject<Department>(new Department());
  public DepartmentList = this.department.asObservable();

  private setUpName = new BehaviorSubject<string>('');
  setUpNameGlobal = this.setUpName.asObservable();

  private setUpNameGroup = new BehaviorSubject<string>('');
  setUpNameGroupGlobal = this.setUpNameGroup.asObservable();

  private setUpID = new BehaviorSubject<string>('');
  setUpIDGlobal = this.setUpID.asObservable();

  constructor(private apiService: ApiService) {}

  setSetUpIDGlobal(lable) {
    this.setUpID.next(lable);
  }

  setSetUpNameGlobal(lable) {
    this.setUpName.next(lable);
  }

  setSetUpNameGroupGlobal(lable) {
    this.setUpNameGroup.next(lable);
  }

  setDepartmentData(data) {
    this.department.next(data);
  }

  getDepartmentAlbumsDataApiCall(id): Observable<Department[]> {
    const path = AppSettings.DEPARTMENT_ALBUMS(id);
    return this.apiService.get(path).pipe(
      map((data) => {
        this.setDepartmentData(data);
        return data;
      }),
    );
  }

  getDepartmentDataApiCall(id): Observable<Department[]> {
    const path = AppSettings.DEPARTMENT_PROJECTS(id);
    return this.apiService.get(path).pipe(
      map((data) => {
        this.setDepartmentData(data);
        return data;
      }),
    );
  }

  initProjectDataList(
    arrAlbumList: Department[],
    selectedProject: number,
  ): Items[] {
    if (arrAlbumList.length) {
      return this.combineAlbumItems(arrAlbumList).map((o) => {
        this.changeGroupDetails(o.item, o.element, selectedProject);
        return o.item;
      });
    }
  }

  combineAlbumItems(arrAlbumList: Department[]): AlbumList[] {
    return arrAlbumList
      .map((element) => element.items.map((item) => ({ element, item })))
      .reduce((a, b) => a.concat(b));
  }

  changeGroupDetails(
    item: Items,
    element: Department,
    selectedProject: number,
  ): void {
    item.groupName = element.groupName;
    if (selectedProject !== 3 && selectedProject !== 4) {
      item.groupId = element.groupId;
    }
  }

  filterDepartment(query: string, searchList: Items[]): Items[] {
    if (searchList.length) {
      return searchList.filter((o) =>
        o.label.toLowerCase().includes(query.toLowerCase()),
      );
    }
  }
}
