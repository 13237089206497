<div
  class="breadcrumb"
  fsTextOverflowEllipsis
  *ngIf="currentState?.displaySetting?.header?.items?.breadcrumb"
>
  <div>
    <a
      class="fs-txt-white-grey-color-hover"
      routerLink="/dashboard"
      [class.disabled]="breadcrumbs.length === 0"
    >
      {{ 'breadcrumb.dashboard' | translate }}</a
    >
  </div>

  <div class="ml-1" *ngFor="let breadc of breadcrumbs">
    <span> / </span>

    <a
      class="fs-txt-white-grey-color-hover"
      [routerLink]="[breadc.url]"
      [class.disabled]="breadc.disable"
      (click)="onClickLabel(breadc)"
    >
      {{ breadc.label }}
    </a>
  </div>
</div>
