import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { tap } from 'rxjs/internal/operators/tap';
import { SessionTypeEnum } from '../enum/session-type.enum';

@Injectable()
export class ViewGalleryResumeModalStateService {
  private handledResumeSession = new BehaviorSubject<boolean>(false);
  public handledResumeSession$ = this.handledResumeSession.asObservable();

  private dialogAction = new BehaviorSubject<SessionTypeEnum>(null);

  setHandledResumeSession(flag) {
    this.handledResumeSession.next(flag);
  }

  resetHandledResumeSession() {
    this.handledResumeSession.next(false);
  }

  getHandledResumeSession() {
    return this.handledResumeSession.getValue();
  }

  getDialogActionAndClear() {
    return this.dialogAction.pipe(
      filter(Boolean),
      tap((action: SessionTypeEnum) => {
        if (action) {
          this.dialogAction.next(null);
        }
      }),
    );
  }

  setDialogActionState(state) {
    this.dialogAction.next(state);
  }
}
