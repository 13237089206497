<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{ 'left-menu.tab-bar.advance-search.labels.advanced' | translate }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    aria-hidden="true"
    (click)="closeWindow()"
  >
    <i class="fa fa-times-circle"></i>
  </button>
</div>

<div mat-dialog-content [formGroup]="albumDialogForm">
  <div class="container">
    <div class="row">
      <div class="col-sm-3">
        <div #users class="single-filter">
          <div class="modal-title">
            {{ 'left-menu.tab-bar.sidemenus.wideeditalbum.users' | translate }}
          </div>
          <div class="user_li" *ngIf="userDataInfo.length > 0">
            <ng-scrollbar autoHeightDisabled="false" trackY="'true'">
              <div *ngFor="let userData of userDataInfo | sortBy : 'firstName'">
                <mat-checkbox
                  name="users"
                  [checked]="checkIfUserSelected(userData)"
                  class="fs-checkbox"
                  (change)="onChangedProjectUser(userData)"
                >
                  <span class="cb-title">
                    {{ userData['firstName'] }} {{ userData['lastName'] }}
                  </span>
                </mat-checkbox>
              </div>
            </ng-scrollbar>
          </div>

          <div class="user_li" *ngIf="userDataInfo.length === 0">
            <ng-scrollbar autoHeightDisabled="false" trackY="'true'">
              <div>
                <label>
                  <span class="cb-title"> No active user </span>
                </label>
              </div>
            </ng-scrollbar>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="single-filter">
          <div class="modal-title">
            {{
              'left-menu.tab-bar.sidemenus.wideeditalbum.approval' | translate
            }}
          </div>
          <div>
            <mat-radio-group
              class="d-flex flex-column"
              formControlName="approvalStatus"
            >
              <mat-radio-button
                class="fs-radio-button"
                name="approvalStatus"
                value="approved"
              >
                <span class="fs-sm-txt cb-title">
                  {{
                    'left-menu.tab-bar.sidemenus.wideeditalbum.approved'
                      | translate
                  }}
                </span>
              </mat-radio-button>

              <mat-radio-button
                class="fs-radio-button"
                name="approvalStatus"
                value="rejected"
              >
                <span class="fs-sm-txt cb-title">
                  {{
                    'left-menu.tab-bar.sidemenus.wideeditalbum.rejected'
                      | translate
                  }}
                </span>
              </mat-radio-button>

              <mat-radio-button
                class="fs-radio-button"
                name="approvalStatus"
                value="pending"
              >
                <span class="fs-sm-txt cb-title">
                  {{
                    'left-menu.tab-bar.sidemenus.wideeditalbum.pending'
                      | translate
                  }}
                </span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="single-filter">
          <div class="modal-title">
            {{ 'left-menu.tab-bar.sidemenus.wideeditalbum.talent' | translate }}
          </div>
          <div>
            <mat-radio-group
              class="d-flex flex-column"
              formControlName="talentAppearance"
            >
              <mat-radio-button
                class="fs-radio-button"
                name="talentAppearance"
                value="single"
              >
                <span class="fs-sm-txt cb-title">
                  {{
                    'left-menu.tab-bar.sidemenus.wideeditalbum.single'
                      | translate
                  }}
                </span>
              </mat-radio-button>

              <mat-radio-button
                class="fs-radio-button"
                name="talentAppearance"
                value="group"
              >
                <span class="fs-sm-txt cb-title">
                  {{
                    'left-menu.tab-bar.sidemenus.wideeditalbum.group'
                      | translate
                  }}
                </span>
              </mat-radio-button>

              <mat-radio-button
                class="fs-radio-button"
                name="talentAppearance"
                value="group/single"
              >
                <span class="fs-sm-txt cb-title">
                  {{
                    'left-menu.tab-bar.sidemenus.wideeditalbum.groupsingle'
                      | translate
                  }}
                </span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="single-filter">
          <div class="modal-title">
            {{
              'left-menu.tab-bar.sidemenus.wideeditalbum.designation'
                | translate
            }}
          </div>
          <div>
            <mat-radio-group
              class="d-flex flex-column"
              formControlName="designation"
            >
              <mat-radio-button
                class="fs-radio-button"
                name="designation"
                value="launch"
              >
                <span class="fs-sm-txt cb-title">
                  {{
                    'left-menu.tab-bar.sidemenus.wideeditalbum.launch'
                      | translate
                  }}
                </span>
              </mat-radio-button>

              <mat-radio-button
                class="fs-radio-button"
                name="designation"
                value="fall"
              >
                <span class="fs-sm-txt cb-title">
                  {{
                    'left-menu.tab-bar.sidemenus.wideeditalbum.fall' | translate
                  }}
                </span>
              </mat-radio-button>

              <mat-radio-button
                class="fs-radio-button"
                name="designation"
                value="later"
              >
                <span class="fs-sm-txt cb-title">
                  {{
                    'left-menu.tab-bar.sidemenus.wideeditalbum.later'
                      | translate
                  }}
                </span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="single-filter">
          <div class="modal-title">
            {{ 'left-menu.tab-bar.sidemenus.wideeditalbum.others' | translate }}
          </div>
          <mat-checkbox
            name="like"
            class="fs-checkbox"
            #like
            formControlName="like"
            value="like"
          >
            <span class="cb-title">{{
              'left-menu.tab-bar.sidemenus.wideeditalbum.like' | translate
            }}</span>
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
    {{ 'left-menu.tab-bar.sidemenus.wideeditalbum.cancel' | translate }}
  </button>
  <button (click)="resetValue()" class="fs-dialog-btn w-auto ml-3" type="reset">
    {{ 'left-menu.tab-bar.sidemenus.wideeditalbum.reset' | translate }}
  </button>
  <button (click)="submitform()" class="fs-dialog-btn w-auto ml-3">
    {{ 'left-menu.tab-bar.sidemenus.wideeditalbum.apply' | translate }}
  </button>
</div>
