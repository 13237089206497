<div class="ml-1 mr-1" *ngIf="!isTaggerRole">
  <div
    class="fs-cursor-pointer"
    title="{{ 'view-project.transaction' | translate }}"
    (click)="openImageTransaction.emit(imgData)"
  >
    <span class="fs-char-icon">
      <img src="/assets/icons/t.png" />
    </span>
  </div>
</div>
