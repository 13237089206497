import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private _snackBar: MatSnackBar) {}

  showSnackBar(
    message,
    action = '',
    duration = 500,
    horizontalPosition: MatSnackBarHorizontalPosition = 'right',
    verticalPosition: MatSnackBarVerticalPosition = 'top',
  ) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      duration: duration,
      data: {
        duration: duration,
        message: message,
      },
      announcementMessage: message,
      horizontalPosition,
      verticalPosition,
      panelClass: ['bg-dark', 'mat-snack-bar-container', 'mat-simple-snackbar'],
    });
  }

  showSnackBarSuccess(
    message,
    action = '',
    duration = 500,
    horizontalPosition: MatSnackBarHorizontalPosition = 'right',
    verticalPosition: MatSnackBarVerticalPosition = 'top',
  ) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      duration: duration,
      data: {
        duration: duration,
        message: message,
      },
      announcementMessage: message,
      horizontalPosition,
      verticalPosition,
      panelClass: [
        'bg-success',
        'mat-snack-bar-container',
        'mat-simple-snackbar',
      ],
    });
  }

  showSnackBarWarning(
    message,
    action = '',
    duration = 50000,
    horizontalPosition: MatSnackBarHorizontalPosition = 'right',
    verticalPosition: MatSnackBarVerticalPosition = 'top',
    snackBarTypePanelName = 'snack-bar-color-panel',
  ) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      duration: duration,
      data: {
        duration: duration,
        message: message,
        snackBarRef: this._snackBar,
      },
      horizontalPosition,
      verticalPosition,
      panelClass: [
        snackBarTypePanelName,
        'mat-snack-bar-container',
        'mat-simple-snackbar',
        'snack-bar-bg-color-panel',
      ],
    });
  }

  showSnackBarError(
    message,
    action = '',
    duration = 500,
    horizontalPosition: MatSnackBarHorizontalPosition = 'right',
    verticalPosition: MatSnackBarVerticalPosition = 'top',
  ) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      duration: duration,
      data: {
        duration: duration,
        message: message,
      },
      announcementMessage: message,
      horizontalPosition,
      verticalPosition,
      panelClass: [
        'bg-danger',
        'mat-snack-bar-container',
        'mat-simple-snackbar',
      ],
    });
  }
}
