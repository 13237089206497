import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
} from '@angular/core';
import { PopupRef } from '../../../../../../../shared/services/popup/popup-ref';
import { FS_POPUP_DATA } from '../../../../../../../shared/services/popup/popup.constants';
import { AlbumssharedService } from '../albums-service/albums-shared.service';
import { SidebarService } from '../../../../../sidebar-service/sidebar-service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../../../../../../../shared/utils/utils';
import { AppStateService } from '../../../../../../../shared/services/app-state.service';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

@Component({
  templateUrl: 'deletealbum.component.html',
  styleUrls: ['deletealbum.component.scss'],
})
export class DeletealbumComponent
  extends UnsubscriberComponent
  implements OnInit
{
  delteAlbumData: any;
  albumName = '';
  @Output() closePopUp = new EventEmitter();
  constantsGlobalData: any;

  constructor(
    private albumSharedService: AlbumssharedService,
    private sidebarService: SidebarService,
    private appStateService: AppStateService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private popupRef: PopupRef<DeletealbumComponent>,
    @Inject(FS_POPUP_DATA) private data: { albumType: string },
  ) {
    super();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.sidebarService.selectedAlbum
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        if (!Utils.isObjectEmpty(album)) {
          this.delteAlbumData = album;
          this.albumName = album.value;
        }
      });
  }

  closeDeleteAlbum() {
    this.popupRef.hide();
  }

  deleteAlbum() {
    const obj = {
      id: this.delteAlbumData.id,
      value: this.delteAlbumData.value,
      parentAlbumId: this.delteAlbumData.parentAlbumId,
      projectId: this.viewProjectService.getProjectId(),
    };
    this.loaderService.displayLoader(true);
    this.albumSharedService.callDeleteAlbum(obj).subscribe(
      (res) => {
        if (res.status) {
          this.appStateService.setDeleteAlbum({
            loaded: true,
            data: Utils.addValueToObject(
              this.delteAlbumData,
              'dialogName',
              this.data.albumType,
            ),
          });
          this.closeDeleteAlbum();
        }
        this.loaderService.displayLoader(false);
      },
      (error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      },
    );
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    evt: KeyboardEvent,
  ) {
    this.closeDeleteAlbum();
  }
}
