import { Utils } from './../../../../shared/utils/utils';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DrawImageService } from './draw-image.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import { DialogService } from '../../../../shared/services/dialog/dialog.service';
import { TopmenusService } from '../../dashboard-shared/top-menu/top-menu.service';
import { AssetInfo } from '../../../../shared/models/assetInfo.model';
import { ProjectDetailPermission } from '../../../../shared/models/projectpermission.model';
import { UserDetails } from '../../../../shared/models/userdetails.model';
import { ViewProjectService } from '../../../../shared/services/view-project.service';
import { PaginationService } from '../../dashboard-shared/pagination/pagination.service';
import { UserService } from '../../../../shared/services/user.service';
import { LoaderService } from '../../../../shared/services/loader.service';
import { takeUntil } from 'rxjs/operators';
import { LastSelectedImageStore } from '../../../../shared/store/last-selected-image-store';
import { ConflictsComponent } from '../../dashboard-shared/left-menu/menus/audit/conflicts/conflicts.component';
import { UnsubscriberComponent } from '../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-draw-image',
  templateUrl: './draw-image.component.html',
  styleUrls: ['./draw-image.component.scss'],
})
export class DrawImageComponent
  extends UnsubscriberComponent
  implements OnInit, OnDestroy
{
  @ViewChild('jsPaintRef') jsPaintRef: ElementRef;
  @ViewChild('htmlCanvasElement', { static: true })
  htmlCanvasElement: ElementRef;
  @ViewChild('imageGallery', { static: true }) imageGallery: ElementRef;
  projectId: string;
  projectImageData: AssetInfo = new AssetInfo();
  viewSetting: ProjectDetailPermission = new ProjectDetailPermission();

  noOfImages = 1;
  pageNo: number;
  imageId: string;
  totalImages: number;
  totalPages: number;
  currentImageNum: number;
  isSuperZoom = false;
  imageList: any;
  isCaption = false;
  routerObser: any;
  albumListObser: any;
  isLeftMenuPartShown = true;
  isLeftAlbumActive: boolean;
  selectedLeftAlbum: any;
  timer: any;

  cPushArray = [];
  cStep = -1;
  x = 0;
  y = 0;
  base64Image: any;
  canvasReDrawArr = [];
  canvasReDrawArrTemp = [];
  paintCanvas: any;
  selectImage: any;
  disablePaint = false;
  commentList = [];
  ifTagEnable = false;
  ifTagEnableOffsetX = 0;
  ifTagEnableOffsetY = 0;
  lineWidthCopy: any;
  color = '#17DC1C';
  editComment: any;
  isEditComment = false;
  editIndex: any = null;
  editCommentId: any = null;
  currentLoggedInId: number;
  distinctUserIdFromDraw: Array<any>;
  distinctUserIdFromComment: Array<any>;
  combineUserIdDrawAndComment: Array<any>;
  compareSelectedImages: Array<any>;

  // observer
  callRetouchDrawObser: any;
  brushLineWidth: number;

  currentUser: UserDetails = new UserDetails();
  currentFilter: any;
  viewProjectPageNumber: any;
  imageURL = '';

  // Paginations
  ignorFirstPaginationEvent = true;
  projectImageIds: any;
  currentPageNumber: number;
  imageObjIndex = 0;
  drawImageData = [];
  isCloseDraw = false;

  constructor(
    private route: ActivatedRoute,
    public viewProjectService: ViewProjectService,
    private topmenusService: TopmenusService,
    private paginationService: PaginationService,
    private drawImageService: DrawImageService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private userService: UserService,
    private location: Location,
    private loaderService: LoaderService,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
    private router: Router,
    private dialogService: DialogService,
    private lastSelectedImageStore: LastSelectedImageStore,
  ) {
    super();
    this.commentList = [];
  }
  ngOnInit() {
    this.dialogService.closeDialogById(ConflictsComponent.identifier, {
      withoutChanges: true,
    });
    this.projectId = this.route.snapshot.params.projectId;
    this.currentUser = this.userService.mergeWithCurrentUser(this.currentUser);
    this.viewSetting =
      this.viewProjectService.mergeWithProjectDetailPermissionData(
        this.viewSetting,
      );
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );

    this.viewProjectService.projectImageIdsGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageIds) => {
        this.projectImageIds = imageIds;
      });

    this.drawImageService.closeDrawGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (status) {
          this.closeDraw();
        }
      });

    this.drawImageService.saveDrawGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (status) {
          this.save(false);
        }
      });

    this.paginationService.paginationIndexGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((index) => {
        if (!this.ignorFirstPaginationEvent) {
          if (index === -1) {
            this.lastPage();
          } else if (index > 0 && !this.isCloseDraw) {
            this.paginationEvt(index);
          }
        } else {
          this.ignorFirstPaginationEvent = false;
        }
      });

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((parameters) => {
      this.resetObject();
      this.imageId = parameters['imageId'];
      this.projectId = parameters.projectId.slice(
        1,
        parameters.projectId.length,
      );
      this.currentFilter = parameters.filterId;
      this.viewProjectPageNumber = parameters.pageNumber;
      this.imageSetup(this.imageId);
    });

    this.userService.currentUserGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.currentLoggedInId = Number(res.id);
      });

    this.topmenusService.setTopMenuStatus('DrawImage');
    this.paginationService.setPaginationTempalte('EnlargeImage');
    this.topmenusService.setTopMenuPagination(true);

    this.drawImageService.retouchRemoveUsersGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.canvasReDrawArrTemp = [];
        if (this.prepareTempDraw().length > 0) {
          this.prepareTempDraw().forEach((data) => {
            this.canvasReDrawArr.push(data);
          });
        }

        this.canvasReDrawArr.forEach((draw, index) => {
          if (draw.userId === Number(res.userId)) {
            draw.active = res.checked;
          }
          const canvasPic = new Image();
          canvasPic.src = this.base64Image;
          const context: any = this.paintCanvas.getContext('2d');
          canvasPic.onload = () => {
            context.drawImage(canvasPic, 0, 0);
            this.reDraw(draw);
          };
        });

        this.commentList.forEach((comment, index) => {
          if (comment.userId === Number(res.userId)) {
            comment.active = res.checked;
          }
        });
      });

    this.drawImageService.retouchDrawsGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.length) {
          this.canvasReDrawArr = res;
          this.distinctUserIdFromDraw = [
            ...new Set(res.map((item) => item.userId)),
          ];
          this.distinctUserIdFromComment = [
            ...new Set(this.commentList.map((item) => item.userId)),
          ];
          this.drawImageService.setRetouchContributorList(
            this.distinctUserIdFromDraw
              .concat(this.distinctUserIdFromComment)
              .filter((v, i, a) => a.indexOf(v) === i),
          );
        }
      });

    this.drawImageService.retouchCommentsGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.commentList = res;
        this.distinctUserIdFromComment = [
          ...new Set(res.map((item) => item.userId)),
        ];
        this.distinctUserIdFromDraw = [
          ...new Set(this.canvasReDrawArr.map((item) => item.userId)),
        ];
        this.drawImageService.setRetouchContributorList(
          this.distinctUserIdFromComment
            .concat(this.distinctUserIdFromDraw)
            .filter((v, i, a) => a.indexOf(v) === i),
        );
      });
  }

  imageSetup(imageId) {
    this.resetObject();
    this.totalImages = this.projectImageIds.length;
    this.imageList = this.projectImageData.assetInfoDTOList;
    this.totalPages = Math.ceil(
      this.projectImageData.assetInfoDTOList.length /
        this.viewSetting.viewSettingDTO.imagePerPage,
    );

    this.paginationService.setPaginationLastIndexGlobal(this.totalImages);
    this.drawImageData = [];
    for (let i = 0; i < this.projectImageData.assetInfoDTOList.length; i++) {
      if (Number(imageId) === this.imageList[i].id) {
        this.imageObjIndex = i;
        this.currentImageNum =
          this.projectImageIds.indexOf(Number(imageId)) + 1;
        this.drawImageData.push(this.projectImageData.assetInfoDTOList[i]);
        this.lastSelectedImageStore.set(this.imageList[i]);
        this.imageURL = this.viewProjectService.getWebImageURL(
          this.imageList[i],
          '1600',
        );
      }
    }
    this.drawImageService.setRetouchDraws([]);
    this.paginationEvt(this.currentImageNum);
    this.projectImageData.assetInfoDTOList = this.imageList;
    this.makeBase();
  }

  lastPage(): void {
    this.currentPageNumber = this.totalPages;
    this.currentImageNum = this.totalImages;
    this.handleEnlargeRoute();
    this.handleLastSelectedImages();
  }

  paginationEvt(index) {
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    if (index) {
      this.currentImageNum = index;
    }
    this.currentPageNumber = Math.ceil(
      this.currentImageNum / this.viewSetting.viewSettingDTO.imagePerPage,
    );
    const isAvailable = this.projectImageData.assetInfoDTOList
      .map(function (e) {
        return e.id;
      })
      .indexOf(this.projectImageIds[index - 1]);
    if (isAvailable !== -1) {
      // Jaydeep
      this.handleEnlargeRoute();
      this.handleLastSelectedImages();
      this.loadLastSelectedImage(
        this.projectImageIds[this.currentImageNum - 1],
      );
    } else {
      this.drawImageData = [];
      this.loadProjectDataWithAssetIds();
    }
  }

  loadProjectDataWithAssetIds() {
    const assetsIds = Utils.getImageIdsBasedOnPage(
      this.currentPageNumber,
      this.viewSetting.viewSettingDTO.imagePerPage,
      this.projectImageIds,
    );
    const data = {
      assetIds: assetsIds,
      projectId: this.projectId,
      filter: this.currentFilter,
    };
    this.viewProjectService
      .callAssetsInfoBasedOnAssetsId(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.projectImageData = res;
        this.viewProjectService.setProjectData(this.projectImageData);
        this.handleEnlargeRoute();
        this.handleLastSelectedImages();
        this.loadLastSelectedImage(
          this.projectImageIds[this.currentImageNum - 1],
        );
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  loadLastSelectedImage(imageID) {
    const restartData = {
      filter: this.currentFilter,
      assetId: imageID,
      projectId: this.viewProjectService.getProjectId(),
      pageNo: this.currentPageNumber,
    };
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.viewProjectService
        .callLastSelectedImage(restartData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () =>{},
          (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    }, 3000);
  }

  handleLastSelectedImages() {
    if (
      this.projectImageData &&
      this.projectImageData.assetInfoDTOList &&
      this.projectImageData.assetInfoDTOList.length > 0
    ) {
      this.lastSelectedImageStore.set(
        this.projectImageData.assetInfoDTOList[this.imageObjIndex],
      );
    }
  }

  handleEnlargeRoute() {
    this.router.navigate([
      '/dashboard/viewproject/' +
        this.viewProjectService.getProjectRole() +
        this.projectId +
        '/filter/' +
        this.currentFilter +
        '/page/' +
        this.currentPageNumber +
        '/draw/' +
        this.projectImageIds[this.currentImageNum - 1],
    ]);
  }

  resetObject() {
    this.distinctUserIdFromDraw = [];
    this.distinctUserIdFromComment = [];
    this.combineUserIdDrawAndComment = [];
    this.compareSelectedImages = [];
    this.canvasReDrawArr = [];
    this.canvasReDrawArrTemp = [];
    this.drawImageService.setRetouchComments([]);
    this.drawImageService.setRetouchDraws([]);
    this.drawImageService.setRetouchContributorList([]);

    this.utilDraw();
  }

  utilDraw() {
    this.canvasReDrawArr.forEach((draw) => {
      this.reDraw(draw);
    });
  }

  reDraw(data) {
    if (data.active) {
      const context: any = this.paintCanvas.getContext('2d');
      setTimeout(() => {
        data.coordinates.forEach((cordinates, index) => {
          this.DrawImage(
            context,
            cordinates,
            data.lineWidth,
            data.strokeStyle,
            index,
          );
        });
      }, 0);
    }
  }

  DrawImage(context, cordinates, lineWidth, strokeStyle, index = 1) {
    const newX = cordinates[0];
    const newY = cordinates[1];
    if (index === 0) {
      this.x = cordinates[0];
      this.y = cordinates[1];
    }
    context.lineWidth = lineWidth;
    context.strokeStyle = strokeStyle;
    context.beginPath();
    context.moveTo(this.x, this.y);
    context.lineTo(newX, newY);
    context.stroke();
    context.closePath();
    this.x = newX;
    this.y = newY;
  }

  getCommentObject(data) {
    if (data) {
      const coordinate = JSON.parse(data.coordinate);
      const eventObject = {
        comment: data.comment,
        x: Number(coordinate.x),
        y: Number(coordinate.y),
        id: data.id,
        userId: data.userId,
        active: data.active,
      };
      return eventObject;
    }
    return {};
  }

  getContributorObject(data) {
    if (data) {
      const eventObject = {
        coordinates: JSON.parse(data.coordinate),
        strokeStyle: data.color,
        lineWidth: data.brushSize,
        active: data.active,
        id: data.id,
        userId: data.userId,
      };
      return eventObject;
    }
    return {};
  }

  getCommentListData() {
    if (this.projectId && this.imageId) {
      this.drawImageService
        .callRetouchComments(this.projectId, this.imageId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          res.forEach((data) => {
            this.commentList.push(this.getCommentObject(data));
          });
          this.drawImageService.setRetouchComments(this.commentList);
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    }
  }

  getContributorlistData() {
    if (this.projectId && this.imageId) {
      this.callRetouchDrawObser = this.drawImageService
        .callRetouchDraw(this.projectId, this.imageId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.canvasReDrawArr = [];
          if (res.length > 0) {
            res.forEach((data) => {
              this.canvasReDrawArr.push(this.getContributorObject(data));
            });
          } else {
            this.canvasReDrawArr = [];
          }
          this.drawImageService.setRetouchDraws(this.canvasReDrawArr);

          this.canvasReDrawArr.forEach((draw, index) => {
            const canvasPic = new Image();
            canvasPic.src = this.base64Image;
            const context: any = this.paintCanvas.getContext('2d');
            canvasPic.onload = () => {
              context.drawImage(canvasPic, 0, 0);
              this.reDraw(draw);
            };
          });
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    }
  }

  makeBase() {
    this.cPushArray = [];
    this.cStep = -1;
    this.paintCanvas = this.htmlCanvasElement.nativeElement;
    this.viewProjectService
      .getAssetLinkWithAuthToken(this.imageURL)
      .pipe(takeUntil(this.destroy$))
      .subscribe((url: string) => {
        const context: any = this.paintCanvas.getContext('2d');
        const baseImage = new Image();
        this.base64Image = url;
        baseImage.src = this.base64Image;
        baseImage.onload = () => {
          this.paintCanvas.width = baseImage.width;
          this.paintCanvas.height = baseImage.height;
          context.drawImage(baseImage, 0, 0);
          this.utilDraw();
        };
        context.lineCap = 'round';
        context.strokeStyle = this.color;
        this.brushLineWidth = 3;
        this.lineWidthCopy = 3;

        context.lineWidth = this.brushLineWidth;
        const imageGallery = this.imageGallery.nativeElement;
        imageGallery.style.width = '100%';
        let isMouseDown = false;
        const stopDrawing = () => {
          if (isMouseDown && !this.disablePaint) {
            isMouseDown = false;
            if (this.cPushArray.length > 1) {
              this.canvasReDrawArrTemp.push({
                coordinates: this.cPushArray,
                strokeStyle: context.strokeStyle,
                lineWidth: context.lineWidth,
              });
            }
          }
        };
        const startDrawing = (event) => {
          context.strokeStyle = this.color;
          context.lineWidth = this.lineWidthCopy;
          if (!this.disablePaint) {
            isMouseDown = true;
            this.ifTagEnable = false;
            this.cPushArray = [];
            [this.x, this.y] = [event.offsetX, event.offsetY];
            this.cPush(context, [this.x, this.y]);
          } else {
            this.ifTagEnable = true;
            this.ifTagEnableOffsetX = event.offsetX;
            this.ifTagEnableOffsetY = event.offsetY;
            this.editIndex = null;
            this.editCommentId = null;
            this.editComment = null;
            this.isEditComment = false;
          }
        };
        const drawLine = (event) => {
          if (isMouseDown && !this.disablePaint) {
            const newX = event.offsetX;
            const newY = event.offsetY;
            this.cPush(context, [newX, newY]);
            this.DrawImage(
              context,
              [newX, newY],
              context.lineWidth,
              context.strokeStyle,
            );
          }
        };

        this.paintCanvas.addEventListener('mousedown', startDrawing);
        this.paintCanvas.addEventListener('mousemove', drawLine);
        this.paintCanvas.addEventListener('mouseup', stopDrawing);
        this.paintCanvas.addEventListener('mouseout', stopDrawing);
        this.resetObject();
        this.getContributorlistData();
        this.getCommentListData();
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  hideTagForm() {
    this.ifTagEnable = false;
    this.ifTagEnableOffsetX = 0;
    this.ifTagEnableOffsetY = 0;
  }

  submitComment(e) {
    if (e.target.comment.value) {
      this.loaderService.displayLoader(true);
      this.drawImageService
        .saveRetouchComments({
          projectId: this.projectId,
          assetId: this.imageId,
          comment: e.target.comment.value,
          coordinate: JSON.stringify({
            x: e.target.x.value,
            y: e.target.y.value,
          }),
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.loaderService.displayLoader(false);
            if (res.id) {
              this.commentList.push(this.getCommentObject(res));
              this.drawImageService.setRetouchComments(this.commentList);
              this.updateRetouchFlag();
              // Need i18n
              this.snackBarService.showSnackBar('Comment saved');
            }
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
      this.ifTagEnable = false;
      this.isEditComment = false;
      this.editIndex = null;
      this.editCommentId = null;
      this.editComment = null;
    } else {
      // Need i18n
      this.snackBarService.showSnackBarWarning('Please comment some text!');
    }
  }

  submitCommentEdit(e) {
    const editIndex = this.editIndex;
    if (e.target.comment.value && editIndex) {
      this.loaderService.displayLoader(true);
      this.drawImageService
        .updateRetouchComments({
          id: this.editCommentId,
          projectId: this.projectId,
          assetId: this.imageId,
          comment: e.target.comment.value,
          coordinate: JSON.stringify({
            x: e.target.x.value,
            y: e.target.y.value,
          }),
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.loaderService.displayLoader(false);
            if (res.id) {
              this.commentList[editIndex] = this.getCommentObject(res);
              this.drawImageService.setRetouchComments(this.commentList);
              this.ifTagEnable = false;
              this.isEditComment = false;
              this.editIndex = null;
              this.editCommentId = null;
              this.editComment = null;
              // Need i18n
              this.snackBarService.showSnackBar('Comment edited');
            }
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);

            this.loaderService.displayLoader(false);
          },
        );
    } else if (!editIndex) {
      this.isEditComment = false;
    }
  }

  cPush(context, coordinates) {
    this.cPushArray.push(coordinates);
  }

  cUndo() {
    if (!this.disablePaint) {
      if (this.canvasReDrawArrTemp.length > 0) {
        const canvasPic = new Image();
        canvasPic.src = this.base64Image;
        this.canvasReDrawArrTemp.pop();
        const context: any = this.paintCanvas.getContext('2d');
        canvasPic.onload = () => {
          context.drawImage(canvasPic, 0, 0);
          this.canvasReDrawArr
            .concat(this.canvasReDrawArrTemp)
            .forEach((draw, index) => {
              this.reDraw({ ...draw, active: true });
            });
        };
      }
    } else {
      // Need i18n
      this.snackBarService.showSnackBarWarning('Enable Paint first!');
    }
  }

  closeDraw() {
    if (this.preparePayloadDraw().length > 0) {
      this.dialogService
        .openConfirmationDialog({
          message: this.translate.instant('view-project.confirm14'),
          title: this.translate.instant('view-project.confirm15'),
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((state: boolean) => {
          if (state) {
            this.save(true);
          } else {
            this.handleNavigate(true);
          }
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    } else {
      this.handleNavigate(true);
    }
  }

  handleNavigate(navigate) {
    if (navigate) {
      this.viewProjectService.setCurrentPageNumber(this.currentPageNumber);
      this.isCloseDraw = true;
      this.paginationService.setPaginationIndex(this.currentPageNumber);
      this.topmenusService.setTopMenuStatus('');
      this.router.navigate([
        Utils.prepareFilterNavigationURL(
          this.viewProjectService.getProjectRole(),
          this.viewProjectService.getProjectId(),
          this.currentFilter,
          this.viewProjectPageNumber,
        ),
      ]);
    }
  }

  preparePayloadDraw() {
    const payload = [];
    this.canvasReDrawArrTemp.forEach((data) => {
      payload.push({
        assetId: this.imageId,
        brushSize: data.lineWidth,
        color: data.strokeStyle,
        coordinate: JSON.stringify(data.coordinates),
      });
    });
    return payload;
  }

  prepareTempDraw() {
    const payload = [];
    this.canvasReDrawArrTemp.forEach((data) => {
      payload.push({
        active: true,
        coordinates: data.coordinates,
        lineWidth: data.lineWidth,
        strokeStyle: data.strokeStyle,
        userId: this.currentUser.id,
      });
    });
    return payload;
  }

  editCommentMethod(commentId, index = null) {
    const data = JSON.parse(
      JSON.stringify(this.commentList.filter((data) => data.id === commentId)),
    )[0];
    if (data.userId === this.currentLoggedInId) {
      this.isEditComment = true;
      this.editIndex = index;
      this.editCommentId = commentId;
      this.ifTagEnable = true;
      this.ifTagEnableOffsetX = Number(data.x);
      this.ifTagEnableOffsetY = Number(data.y);
      this.editComment = data.comment;
    } else {
      this.snackBarService.showSnackBarWarning(
        this.translate.instant('view-project.alert46'),
      );
    }
  }

  onLoad() {
    this.makeBase();
  }

  deleteMethod() {
    this.loaderService.displayLoader(true);
    this.drawImageService
      .deleteRetouchDraw(this.projectId, this.imageId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (!res) {
            const canvasArr = [];
            this.canvasReDrawArr.forEach((data) => {
              if (
                this.currentLoggedInId &&
                this.currentLoggedInId !== data.userId
              ) {
                canvasArr.push(data);
              }
            });
            this.canvasReDrawArr = canvasArr;
            this.drawImageService.setRetouchDraws(this.canvasReDrawArr);
            const canvasPic = new Image();
            canvasPic.src = this.base64Image;
            const context: any = this.paintCanvas.getContext('2d');
            canvasPic.onload = () => {
              context.drawImage(canvasPic, 0, 0);
              this.utilDraw();
            };

            if (
              this.commentList.length === 0 &&
              this.canvasReDrawArr.length === 0
            ) {
              const projectData = this.viewProjectService.getProjectData();
              const assetInfoDTOList = projectData.assetInfoDTOList;
              assetInfoDTOList[this.currentImageNum - 1].retouch = false;
              this.viewProjectService.setProjectData({
                ...projectData,
                assetInfoDTOList: assetInfoDTOList,
              });
            }
            this.canvasReDrawArrTemp = [];
            // Need i18n
            this.snackBarService.showSnackBar('Deleted');
          }
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);

          this.loaderService.displayLoader(false);
        },
      );
  }

  paint() {
    this.disablePaint = false;
    this.ifTagEnable = false;
  }

  comment() {
    this.disablePaint = true;
  }

  deleteComment(e, index) {
    this.loaderService.displayLoader(true);
    this.drawImageService
      .deleteRetouchComments(
        this.projectId,
        this.imageId,
        this.commentList[index].id,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (!res) {
            this.commentList.splice(index, 1);
            this.ifTagEnable = false;
            this.ifTagEnableOffsetX = null;
            this.ifTagEnableOffsetY = null;
            this.editComment = '';
            this.drawImageService.setRetouchComments(this.commentList);

            if (
              this.commentList.length === 0 &&
              this.canvasReDrawArr.length === 0
            ) {
              const projectData = this.viewProjectService.getProjectData();
              const assetInfoDTOList = projectData.assetInfoDTOList;
              assetInfoDTOList[this.currentImageNum - 1].retouch = false;
              this.viewProjectService.setProjectData({
                ...projectData,
                assetInfoDTOList: assetInfoDTOList,
              });
            }
            // Need i18n
            this.snackBarService.showSnackBar('Deleted');
          }

          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);

          this.loaderService.displayLoader(false);

        },
      );
  }

  save(isBack) {
    if (this.preparePayloadDraw().length > 0) {
      this.loaderService.displayLoader(true);
      this.drawImageService
        .saveRetouchDraw(this.preparePayloadDraw())
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            res.forEach((data) => {
              this.canvasReDrawArr.push(this.getContributorObject(data));
            });
            this.drawImageService.setRetouchDraws(this.canvasReDrawArr);
            this.canvasReDrawArrTemp = [];
            this.updateRetouchFlag();
            this.loaderService.displayLoader(false);
            // Need i18n
            this.snackBarService.showSnackBar('Saved');
            if (isBack) {
              this.location.back();
            }
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);

            this.loaderService.displayLoader(false);
          },
        );
    } else {
      // Need i18n
      this.snackBarService.showSnackBarError('Please Draw first!');
    }
  }

  updateRetouchFlag() {
    const res = [];
    res.push({ id: Number(this.imageId) });
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    res.forEach((eachObj) => {
      const imageIndex = this.projectImageData.assetInfoDTOList
        .map(function (e) {
          return e.id;
        })
        .indexOf(eachObj.id);

      if (imageIndex !== -1) {
        this.projectImageData.assetInfoDTOList[imageIndex].retouch = true;
      }
    });
    this.viewProjectService.setProjectData(this.projectImageData);
  }

  ngOnDestroy() {
    if (this.callRetouchDrawObser) {
      this.callRetouchDrawObser.unsubscribe();
    }
    super.ngOnDestroy();
  }

  setBrushLineWidth(width) {
    this.brushLineWidth = width;
    this.lineWidthCopy = width;
  }
}
