import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Injectable()
export class AuthServerProvider {
  constructor(
    private sessionStorage: SessionStorageService,
    private localStorage: LocalStorageService,
  ) {}

  getToken(): string {
    return this.localStorage.retrieve('jwtToken');
  }

  saveToken(token: string) {
    this.localStorage.store('jwtToken', token);
  }

  destroyToken() {
    this.localStorage.clear('jwtToken');
  }

  getRefreshToken(): string {
    return this.localStorage.retrieve('refreshToken');
  }

  saveRefreshToken(token: string) {
    this.localStorage.store('refreshToken', token);
  }

  destroyRefreshToken() {
    this.localStorage.clear('refreshToken');
  }
}
