import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fs-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  @Input() pageIndex: number;
  @Input() length: number;
  @Input() pageSize: number;
  @Input() isFirstElement: boolean;
  @Input() isLastElement: boolean;
  @Output() goToFirst = new EventEmitter();
  @Output() goToLast = new EventEmitter();
  @Output() goToPrevious = new EventEmitter();
  @Output() goToNext = new EventEmitter();
}
