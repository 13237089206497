import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CompareService } from '../../../project-detail/compare/compare.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Modalstatusservice } from '../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { ConstantsGlobal } from '../../../../../shared/constants/constants';
import { SelectedImages } from '../../../../../shared/models/selectedImages.model';
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';

@Component({
  selector: 'fs-seconcompare',
  templateUrl: './secondcompare.component.html',
  styleUrls: ['./secondcompare.component.scss'],
})
export class SecondCompareComponent implements OnInit {
  slider: any;
  img: any;
  clicked: any = 0;
  w: any;
  h: any;
  selectedImages: any;
  timer: any;
  rangeValue = 650;
  rangeValue1 = 40;
  imgWidth = 640;
  height = 640;
  span3Width: number = this.rangeValue;
  compareContWidth: any;
  imgContHeight: any;
  imageVisible0 = true;
  imageVisible1 = true;
  imageCenterVisible = true;
  constantsGlobalData: any;
  isImage0CompleteShown = false;

  @ViewChild('childabs') childabs: ElementRef;
  @ViewChild('parentabs') parentabs: ElementRef;
  @ViewChild('imagTag') imagTag: ElementRef;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeSecondCompare();
  }

  constructor(
    private modalService: Modalstatusservice,
    private elementRef: ElementRef,
    private compareService: CompareService,
    public viewProjectService: ViewProjectService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<SecondCompareComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { selectedImages: SelectedImages[] },
  ) {
    this.selectedImages = data.selectedImages;
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    setTimeout(() => {
      this.initComparisons();
    }, 600);
  }

  onLoad() {
    setTimeout(() => {
      if (this.rangeValue === 0) {
        this.rangeValue = 780;
      }
    }, 500);
  }

  mouseUpShow(index) {
    this.isImage0CompleteShown = false;
    if (index === 0) {
      this.imageVisible1 = true;
      this.imageCenterVisible = true;
    } else if (index === 1) {
      this.imageVisible0 = true;
      this.imageCenterVisible = true;
    }
  }

  mouseDownHide(index) {
    if (index === 0) {
      this.isImage0CompleteShown = true;
      this.imageVisible1 = false;
      this.imageCenterVisible = false;
    } else if (index === 1) {
      this.isImage0CompleteShown = false;
      this.imageVisible0 = false;
      this.imageCenterVisible = false;
    }
  }

  zoomImageWidth(event) {
    const rangPx = (event.target.value * 1600) / 100;
    if (rangPx < 300) {
      this.imgWidth = 160;
      this.span3Width = 160;
    } else {
      this.imgWidth = (event.target.value * 1600) / 100;
      this.span3Width = (event.target.value * 1600) / 100;
    }

    this.compareContWidth = 100 + '%';
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.initComparisons();
    }, 600);
  }

  iterateDataFromObjectMap(index) {
    let imgdata = null;
    let inc = 0;
    this.selectedImages.forEach((element, key) => {
      if (inc === index) {
        imgdata = key;
      }
      inc++;
    });
    return this.selectedImages.get(imgdata);
  }

  initComparisons() {
    let i;
    const x =
      this.elementRef.nativeElement.getElementsByClassName('img-comp-overlay');
    for (i = 0; i < x.length; i++) {
      this.compareImages(x[i]);
    }
    this.img = x[0];
  }

  compareImages(img: any) {
    this.w = img.offsetWidth;
    this.h = img.offsetHeight;
    this.imgContHeight = this.h;
    img.style.width = this.w / 2 + 'px';

    this.slider =
      this.elementRef.nativeElement.getElementsByClassName(
        'img-comp-slider',
      )[0];

    this.slider.style.top = this.h / 2 - this.slider.offsetHeight / 2 + 'px';
    this.slider.style.left = this.w / 2 - this.slider.offsetWidth / 2 + 'px';
    /*execute a function when the mouse button is pressed:*/
    this.slider.addEventListener('mousedown', this.slideReady.bind(this));
    /*and another function when the mouse button is released:*/
    window.addEventListener('mouseup', this.slideFinish.bind(this));
    /*or touched (for touch screens:*/
    this.slider.addEventListener('touchstart', this.slideReady.bind(this));
    /*and released (for touch screens:*/
    window.addEventListener('touchstop', this.slideFinish.bind(this));
  }

  slideReady(e: any) {
    /*prevent any other actions that may occur when moving over the image:*/
    e.preventDefault();
    this.clicked = 1;
    window.addEventListener('mousemove', this.slideMove.bind(this));
    window.addEventListener('touchmove', this.slideMove.bind(this));
  }

  slideFinish() {
    /*the slider is no longer clicked:*/
    this.clicked = 0;
  }

  slideMove(e: any) {
    let pos;
    /*if the slider is no longer clicked, exit this function:*/
    if (this.clicked === 0) {
      return false;
    }
    /*get the cursor's x position:*/
    pos = this.getCursorPos(e);
    /*prevent the slider from being positioned outside the image:*/
    if (pos < 0) {
      pos = 0;
    }

    if (pos > this.w) {
      pos = this.w;
    }
    /*execute a function that will resize the overlay image according to the cursor:*/
    this.slide(pos);
  }

  getCursorPos(e: any) {
    const xup =
      this.elementRef.nativeElement.getElementsByClassName(
        'img-comp-overlay',
      )[0];
    let x = 0;
    e = e || window.event;
    /*get the x positions of the image:*/
    const a = xup.getBoundingClientRect();
    /*calculate the cursor's x coordinate, relative to the image:*/
    x = e.pageX - a.left;
    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    return x;
  }

  slide(x: any) {
    this.img.style.width = x + 'px';
    this.slider.style.left =
      this.img.offsetWidth - this.slider.offsetWidth / 2 + 'px';
  }

  closeSecondCompare() {
    this.dialogRef.close();
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
