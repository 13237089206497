<div class="input-group-wrap">
  <div class="group-btn">
    <button id="down" class="btn-inc" (click)="down('1')">
      <span class="fa fa-minus"></span>
    </button>
  </div>

  <input
    type="text"
    name="inputNumber"
    [(ngModel)]="inputNumber"
    disabled
    class="input-number"
    value="1"
  />

  <div class="group-btn">
    <button id="up" class="btn-inc" (click)="up('5')">
      <span class="fa fa-plus"></span>
    </button>
  </div>
</div>
