import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'fs-add-key-caption-component',
  templateUrl: './add-key-caption-component.component.html',
})
export class AddKeyCaptionComponentComponent {
  static identifier = 'AddKeyCaptionComponentComponent';
  keyDescription;
  keyIdentifier;
  addKeyFn: (obj: { keyDescription: string; keyIdentifier: string }) => void;
  onBlurFn: () => void;
  onFocusFn: () => void;

  constructor(
    private dialogRef: MatDialogRef<AddKeyCaptionComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.keyDescription = data.keyDescription;
    this.keyIdentifier = data.keyIdentifier;
    this.addKeyFn = data.addKey;
    this.onBlurFn = data.onBlur;
    this.onFocusFn = data.onFocus;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
