import { Router } from '@angular/router';
import { LauncherService } from '../../../../launcher/launcher.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { SidebarService } from '../../../../project/sidebar-service/sidebar-service';
import {
  SelectedProjectModel,
  SelectedProjectTypeModel,
} from '../interfaces/dashboard-select-project-rule.model';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { ProjectRoleId } from '../../../../../shared/enum/project-role.enum';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardSelectSignoffWideEditProjectRule
  implements DashboardRuleModel
{
  private router = GetStaticInjector().get(Router);
  private launcherService = GetStaticInjector().get(LauncherService);
  private viewProjectService = GetStaticInjector().get(ViewProjectService);
  private sidebarService = GetStaticInjector().get(SidebarService);

  constructor(
    private selectedProject: string,
    private selectedRole: SelectedProjectTypeModel,
    private selectedAlbum: SelectedProjectModel,
  ) {}

  matches(): boolean {
    return (
      this.selectedProject.toString() === '3' ||
      this.selectedProject.toString() === '4'
    );
  }

  apply(): void {
    this.viewProjectService.setProjectRole(ProjectRoleId.ROLE_EXEC);
    this.launcherService.setIsGallaryModeStatus(true);
    this.sidebarService.setUpdatedTaggingWideEditAlbum({});
    this.sidebarService.setUpdatedTaggingSignOffAlbum({});
    this.router.navigate(['/dashboard/department/' + this.selectedProject]);
  }
}
