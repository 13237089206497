import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatAccordionTogglePosition } from '@angular/material/expansion';

@Component({
  selector: 'fs-accordion-panel',
  templateUrl: './accordion-panel.component.html',
  styleUrls: ['accordion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionPanelComponent {
  @Input() expanded: boolean;
  @Input() hideToggle: boolean;
  @Input() title: string;
  @Input() tooltip: string;
  @Input() togglePosition: MatAccordionTogglePosition;
  @Output() afterExpand: EventEmitter<void> = new EventEmitter<void>();
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @Output() opened: EventEmitter<void> = new EventEmitter<void>();
  @Output() clickOnHelpIcon: EventEmitter<Event> = new EventEmitter<Event>();
}
