<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move fs-selectox-wrapper d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.image-exclusives.title'
        | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content class="fs-txt-white-dark-color font-weight-bold">
  {{ arrListSelectedImagesData.length }}
  {{
    'left-menu.tab-bar.sidemenus.administration.image-exclusives.labels.image-elected'
      | translate
  }}

  <div class="container">
    <div class="row">
      <div class="col-6">
        <h5 class="exclusive-sub-title mt-3 mb-3">
          {{
            'left-menu.tab-bar.sidemenus.administration.image-exclusives.labels.exclusive-details'
              | translate
          }}
        </h5>
      </div>
      <div class="col-6 selectpadding"></div>
    </div>

    <div class="db_com iex-cont exclusive_details">
      <div class="row">
        <div class="col-7">
          {{
            'left-menu.tab-bar.sidemenus.administration.image-exclusives.labels.label1'
              | translate
          }}
        </div>

        <div class="col-5 right_col1">
          <div class="form-group down-selectpic" id="add_new_item_drp">
            <!-- placeholder="Select Batch" -->
            <mat-select
              class="form-control fs-selectbox-dark"
              required
              disableOptionCentering
              [placeholder]="
                'left-menu.tab-bar.sidemenus.administration.image-exclusives.dropdown.select-exclusives'
                  | translate
              "
              [(ngModel)]="exclusives"
              optionLabel="label"
              [compareWith]="selectValueComparator"
              (valueChange)="addExclusive($event)"
            >
              <mat-option
                class="fs-sm-txt fs-selectbox-options"
                *ngFor="let batch of companyNameList"
                [value]="batch"
              >
                {{ batch.label }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-7">
          {{
            'left-menu.tab-bar.sidemenus.administration.image-exclusives.labels.label2'
              | translate
          }}
        </div>
        <div class="col-5">
          <div class="form-group contact_person_input">
            <input
              type="text"
              class="form-control"
              name="exclusivesContactPerson"
              [(ngModel)]="exclusivesContactPerson"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-7">
          {{
            'left-menu.tab-bar.sidemenus.administration.image-exclusives.labels.label3'
              | translate
          }}
        </div>

        <div class="col-5">
          <div class="form-group exclusive_reason">
            <input
              type="text"
              class="form-control"
              value="exclusivesReason"
              [(ngModel)]="exclusivesReason"
              name="exclusivesReason"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <h5 class="exclusive-sub-title mt-3 mb-3">
    {{
      'left-menu.tab-bar.sidemenus.administration.image-exclusives.labels.exclusive-hold-duration'
        | translate
    }}
  </h5>

  <div class="container">
    <div class="row">
      <div class="col-7">
        <mat-radio-group
          class="d-flex flex-column"
          [(ngModel)]="exclusivesHoldDuration"
        >
          <mat-radio-button
            class="fs-radio-button"
            value="manaully"
            (click)="onClickCheckbox('manaully')"
          >
            <span class="cb-title fs-sm-txt">
              {{
                'left-menu.tab-bar.sidemenus.administration.image-exclusives.labels.label4'
                  | translate
              }}
            </span>
          </mat-radio-button>

          <mat-radio-button
            class="fs-radio-button"
            value="tillDate"
            (click)="onClickCheckbox('tillDate')"
          >
            <span class="cb-title fs-sm-txt">
              {{
                'left-menu.tab-bar.sidemenus.administration.image-exclusives.labels.label5'
                  | translate
              }}
            </span>
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="col-5">
        <span
          class="d-flex align-items-center form-control pr-0"
          [class.disabled]="exclusivesHoldDuration == 'manaully'"
        >
          <input
            matInput
            inputRestriction="onlyInteger"
            [placeholder]="
              'left-menu.tab-bar.sidemenus.administration.image-exclusives.placeholder.select-date'
                | translate
            "
            [matDatepicker]="picker"
            [value]="dateTime"
            [min]="min"
            [disabled]="exclusivesHoldDuration == 'manaully'"
            (dateInput)="dateTime = $event.value"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker [startAt]="min"></mat-datepicker>
        </span>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <button
    class="fs-dialog-btn w-auto ml-3"
    *ngIf="isEdit"
    (click)="holdExclusive()"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.image-exclusives.button.hold'
        | translate
    }}
  </button>

  <button
    class="fs-dialog-btn w-auto ml-3"
    (click)="submitExclusive()"
    [disabled]="!exclusives"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.image-exclusives.button.save'
        | translate
    }}
  </button>
</div>
