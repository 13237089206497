import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DomainConfig } from '../models/domainConfig.model';

@Injectable()
export class DomainConfigService {
  private domainConfigData = new BehaviorSubject<DomainConfig>(
    new DomainConfig(),
  );
  domainConfigDataGlobal = this.domainConfigData.asObservable();
  @Output() isDomainSuccess: EventEmitter<boolean> = new EventEmitter(false);

  setDomainConfigData(data) {
    this.domainConfigData.next(data);
  }

  getDomainConfigData() {
    return this.domainConfigData.getValue();
  }

  resetDomainConfigData() {
    this.domainConfigData.next(new DomainConfig());
  }

  setIsDomainSuccess(status) {
    this.isDomainSuccess.next(status);
  }
}
