<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.imagecaption-report.title'
        | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="fs-txt-white-dark-color font-weight-bold">
    {{
      'left-menu.tab-bar.sidemenus.administration.imagecaption-report.labels.label1'
        | translate
    }}
  </div>

  <mat-radio-group
    class="d-flex flex-column"
    [(ngModel)]="captionType"
    (change)="onCaptionChange($event)"
  >
    <mat-radio-button class="fs-radio-button" value="1">
      <span class="cb-title fs-sm-txt">
        {{
          'left-menu.tab-bar.sidemenus.administration.imagecaption-report.labels.all'
            | translate
        }}
      </span>
    </mat-radio-button>

    <mat-radio-button
      class="fs-radio-button"
      value="2"
      [disabled]="filterName === 'showall' || filterName === 'Show All'"
    >
      <span class="cb-title fs-sm-txt">
        {{
          'left-menu.tab-bar.sidemenus.administration.imagecaption-report.labels.current-filter'
            | translate
        }}
      </span>
    </mat-radio-button>
  </mat-radio-group>
</div>

<div mat-dialog-actions align="end">
  <button
    class="fs-dialog-btn w-auto ml-3"
    *ngIf="existingImageCaptionData.length > 0"
    (click)="captionReportExisting()"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.imagecaption-report.button.returnToPrevious'
        | translate
    }}
  </button>

  <button class="fs-dialog-btn w-auto ml-3" (click)="captionReport()">
    {{
      'left-menu.tab-bar.sidemenus.administration.imagecaption-report.button.generate'
        | translate
    }}
  </button>
</div>
