import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PaginationService {
  private paginationIndex = new BehaviorSubject<any>(0);
  paginationIndexGlobal = this.paginationIndex.asObservable();

  private paginationTemplateId = new BehaviorSubject<any>(0);
  paginationTemplateIdGlobal = this.paginationTemplateId.asObservable();

  private paginationLastIndex = new BehaviorSubject<any>(0);
  paginationLastIndexGlobal = this.paginationLastIndex.asObservable();

  setPaginationIndex(index) {
    this.paginationIndex.next(index);
  }

  setPaginationTempalte(id) {
    this.paginationTemplateId.next(id);
  }

  setPaginationLastIndexGlobal(lastIndex) {
    this.paginationLastIndex.next(lastIndex);
  }
}
