import { NgModule } from '@angular/core';
import { SliderComponent } from './slider.component';
import { SliderService } from './slider.service';
import { SharedModule } from '../../../../../shared/shared.module';

@NgModule({
  imports: [SharedModule],
  declarations: [SliderComponent],
  providers: [SliderService],
  exports: [SliderComponent],
})
export class SliderModule {}
