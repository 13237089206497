import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { BehaviorSubject } from 'rxjs';
import { CookieBannerService } from '../components/cookie-banner/cookie-banner.service';

@Injectable()
export class FsCookieService extends CookieService {
  acceptCookieStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.localStorage.retrieve('cookieAllowed'),
  );
  acceptCookieStatusGlobal = this.acceptCookieStatus.asObservable();

  constructor(
    private cookieBannerService: CookieBannerService,
    private sessionStorage: SessionStorageService,
    private localStorage: LocalStorageService,
    @Inject(DOCUMENT) document: Document,
    @Inject(PLATFORM_ID) platformId,
  ) {
    super(document, platformId);
  }

  getCookie(name: string): string {
    return this.isCookieAllowed() && this.get(name);
  }

  setCookieStatus(status) {
    this.localStorage.store('cookieAllowed', status);
    this.acceptCookieStatus.next(status);
  }

  setCookieIfAllowed(name: string, value: string): void {
    if (this.isCookieAllowed()) {
      this.set(name, value);
    }
  }

  isCookieAllowed(): boolean {
    return JSON.parse(this.localStorage.retrieve('cookieAllowed'));
  }

  isCookieExist(): boolean {
    return this.localStorage.retrieve('cookieAllowed') !== null;
  }

  clearStorageValues() {
    this.deleteAll();
    this.localStorage.clear('cookieExpirationDate');
    this.localStorage.clear('selectedLanguage');
  }

  checkCookieExpiration() {
    if (this.isCookieAllowed()) {
      const cookieExpirationDate = this.localStorage.retrieve(
        'cookieExpirationDate',
      );
      if (new Date().getTime() > cookieExpirationDate) {
        this.localStorage.clear('cookieAllowed');
        this.localStorage.clear('cookieExpirationDate');
        this.cookieBannerService.open();
      }
    }
  }
}
