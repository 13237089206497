import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'fs-edit-key-caption-component',
  templateUrl: './edit-key-caption-component.component.html',
  styleUrls: ['./edit-key-caption.component.scss'],
})
export class EditKeyCaptionComponentComponent {
  editModel;
  editKeyPopUPFn: () => void;
  onBlurFn: () => void;
  editKeyFn: () => void;
  onFocusFn: () => void;
  constructor(
    private dialogRef: MatDialogRef<EditKeyCaptionComponentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      editModel: any;
      editKey: () => void;
      onBlur: () => void;
      onFocus: () => void;
    },
  ) {
    this.editModel = data.editModel;
    this.editKeyFn = data.editKey;
    this.onBlurFn = data.onBlur;
    this.onFocusFn = data.onFocus;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
