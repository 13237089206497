<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{ 'view-project.transaction' | translate }}: {{ assetName }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <mat-stepper linear #stepper>
    <mat-step>
      <div
        class="fs-overflow-auto fs-scroll-table-body fs-header-sort-color fs-project-summary-report-table"
      >
        <mat-table
          class="fs-table-container"
          matSort
          matSortDirection="asc"
          matSortActive="id"
          [hidden]="dataSource.data.length === 0"
          [dataSource]="dataSource"
        >
          <ng-container matColumnDef="id">
            <mat-header-cell mat-sort-header class="pl-2" *matHeaderCellDef>
              Id
            </mat-header-cell>
            <mat-cell
              class="p-2 fs-txt-white-grey-color-hover"
              *matCellDef="let element"
            >
              {{ element.id }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="transactionTime">
            <mat-header-cell mat-sort-header class="pl-2" *matHeaderCellDef>
              {{
                'image-transaction.ddSubMenuLabel.transactionTime' | translate
              }}
            </mat-header-cell>
            <mat-cell
              class="p-2 fs-txt-white-grey-color-hover"
              *matCellDef="let element"
            >
              {{ element.transactionTime }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="transactionType">
            <mat-header-cell mat-sort-header class="pl-2" *matHeaderCellDef>
              {{
                'image-transaction.ddSubMenuLabel.transactionType' | translate
              }}
            </mat-header-cell>
            <mat-cell
              class="p-2 fs-txt-white-grey-color-hover"
              *matCellDef="let element"
            >
              {{ element.transactionType }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ip">
            <mat-header-cell mat-sort-header class="pl-2" *matHeaderCellDef>
              {{ 'image-transaction.ddSubMenuLabel.ipAddress' | translate }}
            </mat-header-cell>
            <mat-cell
              class="p-2 fs-txt-white-grey-color-hover"
              *matCellDef="let element"
            >
              {{ element.ip }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="projectName">
            <mat-header-cell mat-sort-header class="pl-2" *matHeaderCellDef>
              {{ 'image-transaction.ddSubMenuLabel.project' | translate }}
            </mat-header-cell>
            <mat-cell
              class="p-2 fs-txt-white-grey-color-hover"
              *matCellDef="let element"
            >
              {{ element.projectName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="userId">
            <mat-header-cell mat-sort-header class="pl-2" *matHeaderCellDef>
              {{ 'image-transaction.ddSubMenuLabel.userId' | translate }}
            </mat-header-cell>
            <mat-cell
              class="p-2 fs-txt-white-grey-color-hover"
              *matCellDef="let element"
            >
              {{ element.userId }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="userName">
            <mat-header-cell mat-sort-header class="pl-2" *matHeaderCellDef>
              {{ 'image-transaction.ddSubMenuLabel.userName' | translate }}
            </mat-header-cell>
            <mat-cell
              class="p-2 fs-txt-white-grey-color-hover"
              *matCellDef="let element"
            >
              {{ element.userName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="authorityName">
            <mat-header-cell mat-sort-header class="pl-2" *matHeaderCellDef>
              {{ 'image-transaction.ddSubMenuLabel.authorityName' | translate }}
            </mat-header-cell>
            <mat-cell
              class="p-2 fs-txt-white-grey-color-hover"
              *matCellDef="let element"
            >
              {{ element.authorityName }}
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            class="fs-cursor-pointer"
            (click)="onOpenDetailTable(row)"
            *matRowDef="let row; columns: displayedColumns; let i = index"
          ></mat-row>
        </mat-table>
      </div>
    </mat-step>
    <mat-step>
      <fs-transaction-report-details
        [stepper]="stepper"
      ></fs-transaction-report-details>
    </mat-step>
  </mat-stepper>

  <ng-container *ngIf="dataSource.data.length === 0">
    <div class="p-5 text-center">
      {{ 'global.no-record' | translate }}
    </div>
  </ng-container>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <div class="d-flex justify-content-end align-items-center">
    <button
      *ngIf="stepper.selectedIndex === 0 && dataSource.data.length"
      class="fs-dialog-btn w-auto ml-3"
      (click)="exportToExcel(exportType.Excel)"
    >
      {{
        'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.export'
          | translate
      }}
      as xls
    </button>
    <button
      *ngIf="stepper.selectedIndex === 0 && dataSource.data.length"
      class="fs-dialog-btn w-auto ml-3"
      (click)="exportToExcel(exportType.Pdf)"
    >
      {{
        'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.export'
          | translate
      }}
      as PDF
    </button>
    <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
      {{ 'image-history.buttons.close' | translate }}
    </button>
  </div>
</div>
