export class ProjectDetailPermission {
  totalAssets?: number;
  assetStatusDTO?: AssetStatusDTO[];
  batchListDTO?: BatchListDTO[];
  exclusiveListDTO?: ExclusiveListDTO[];
  id_token?: string;
  location?: string;
  locator?: string;
  tertiary?: boolean;
  permissionDTO?: PermissionDTO;
  projectGroupName?: string;
  groupLogo?: string;
  projectId?: number;
  projectName?: string;
  projectTagListDTO?: ProjectTagListDTO[];
  restartDataDTO?: RestartDataDTO;
  unpublishedAssets: number;
  viewSettingDTO?: ViewSettingDTO;
  fav?: boolean;
  priorityPixCount?: number;
  talentProjectInfoDTO?: TalentProjectInfoDTO;
  talentStatDTOList?: TalentStatDTOList[];
  internalUsers?: any[];
  execUsers?: any[];
  useSetup?: boolean;
  shootDate: string;
  photoCredit: string;
  authorities: string[];
}

class TalentStatDTOList {
  batchId: number;
  imageCount: number;
  pendingSoloCount: number;
  pendingGroupCount: number;
  pendingTertiaryCount: number;
  pendingSoloRejectedCount: number;
  pendingGroupRejectedCount: number;
  pendingTertiaryRejectedCount: number;
  totalSoloCount: number;
  totalGroupCount: number;
  totalTertiaryCount: number;
  totalSoloRejectedCount: number;
  totalGroupRejectedCount: number;
  totalTertiaryRejectedCount: number;
  priorityPixCount: number;
  finalizedCount: number;
}

export class ViewSettingDTO {
  projectId: number;
  columnPerPage: number;
  imagePerPage: number;
  size: string;
  global: boolean;
}

export class TalentProjectInfoDTO {
  groupApprovalMin: number;
  groupApproved: number;
  groupAssetCount: number;
  groupAssetRejection: number;
  soloApprovalMin: number;
  soloApproved: number;
  soloAssetCount: number;
  soloAssetRejection: number;
  groupPlusApprovalMin: number;
  groupPlusApproved: number;
  groupPlusAssetCount: number;
  groupPlusAssetRejection: number;
  currentBatch: number;
  welcomeMessage: string;
}

class PermissionDTO {
  id: number;
  execAccess?: false;
  downloadType?: number;
  expressDownload?: false;
  isAdhocExtraSecured?: boolean;
  metaDataAccess?: boolean;
  emailAccess?: false;
  printAccess?: false;
  downloadOnlyLA?: false;
  metaTool?: false;
  lockApprovedRestriction?: false;
  priorityPixAccess?: false;
  releaseExcludeAccess?: false;
  viewSensitiveAccess?: false;
  watermark?: false;
  watermarkInnerTransparency?: number;
  watermarkOuterTransparency?: number;
  internalAccess?: false;
  vendorAccess?: false;
  talentManagementAccess?: false;
  showFinalizationAccess?: false;
  readOnly?: false;
  hasVideo?: false;
  viewUntaggedAccess?: false;
  exclusiveAccess?: false;
  exclusiveStatus?: number;
  fileUploadAccess?: false;
  deleteAssetAccess?: false;
  batchManagementAccess?: false;
  secureAccess?: true;
  signoffManagement?: false;
  datgManagement?: false;
  executiveAlbumManagement?: false;
  captionAccess?: false;
  abcViewer?: false;
  adhocLinkAccess?: false;
  minExpressDownload?: number;
  retouchAccess?: false;
}

class BatchListDTO {
  value: number;
  label: string;
}

class ExclusiveListDTO {
  value: number;
  label: string;
}

export class ProjectTagListDTO {
  id: number;
  tagName: string;
  hotkey: string;
  imageCount: number;
  characterName: string;
  userId: number;
}

export class RestartDataDTO {
  assetId: number;
  filter: string;
  projectId: number;
  pageNo: number;
}

class AssetStatusDTO {
  id: number;
  value: string;
}
