<div class="fs-popup-title">
  <div class="modal-title text-uppercase">{{ currentUser | fullName }}</div>
</div>

<div class="fs-popup-content fs-user-avatar-menu-content">
  <span
    class="fs-txt-white-dark-color fs-txt-white-grey-color-hover fs-cursor-pointer fs-sm-txt"
    (click)="signOut()"
  >
    <i class="fa fa-fw fa-sign-out"></i> {{ 'header.signout' | translate }}
  </span>
</div>
