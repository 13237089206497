<div
  class="fs-image-panel-container"
  (mousedown)="
    selectImageOnClick.emit({ selectImg: imgData, index: ind, event: $event })
  "
  (mouseup)="
    selectImageOnClickUP.emit({ selectImg: imgData, index: ind, event: $event })
  "
>
  <div
    class="image-container mb-2 d-flex justify-content-center align-items-center"
    [class.last-select-border]="
      lastSelectedImageStore.hasEntity(imgData.id) && imageLoaded[ind]
    "
    [class.fs-border-none]="
      blockedHover &&
      !(selectedImagesStore.hasEntity(imgData.id) && imageLoaded[ind])
    "
  >
    <div class="hover-border">
      <img
        class="image"
        [src]="'/assets/images/' + format + '_icon.png'"
        [class.selected-border]="selectedImagesStore.hasEntity(imgData.id)"
      />
    </div>
  </div>
</div>
