import { Component, OnDestroy } from '@angular/core';
import { WideEditAlbumDialogComponent } from '../wide-edit-album-dialog/wide-edit-album-dialog.component';
import { DialogService } from '../../../../../../shared/services/dialog/dialog.service';
import { WideEditAdvanceSearchService } from './wide-edit-album.service';

@Component({
  selector: 'fs-wide-edit-album',
  templateUrl: './wide-edit-album.component.html',
  styleUrls: ['./wide-edit-album.component.scss'],
})
export class WideEditAlbumComponent implements OnDestroy {
  constructor(
    private dialogService: DialogService,
    private wideEditAdvanceSearchService: WideEditAdvanceSearchService,
  ) {}

  openDialog() {
    this.dialogService.open(WideEditAlbumDialogComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-plus-panel',
        'fs-dialog-link-panel',
      ],
    });
  }

  ngOnDestroy() {
    this.wideEditAdvanceSearchService.resetWideEditAdvanceSearchData();
  }
}
