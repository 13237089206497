import { Router } from '@angular/router';
import { DepartmentService } from '../../../../department/department.service';
import { LauncherService } from '../../../../launcher/launcher.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { UserService } from '../../../../../shared/services/user.service';
import {
  SelectedProjectModel,
  SelectedProjectTypeModel,
} from '../interfaces/dashboard-select-project-rule.model';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { PROJECT } from '../../../../../shared/constants/constants';
import { ProjectRoleId } from '../../../../../shared/enum/project-role.enum';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardSelectExecSetupRole implements DashboardRuleModel {
  private router = GetStaticInjector().get(Router);
  private launcherService = GetStaticInjector().get(LauncherService);
  private viewProjectService = GetStaticInjector().get(ViewProjectService);
  private userService = GetStaticInjector().get(UserService);
  private departmentService = GetStaticInjector().get(DepartmentService);

  constructor(
    private currentSelectedProjectData: SelectedProjectModel,
    private selectedProject: string,
    private selectedProjectType: SelectedProjectTypeModel,
    private selectedAlbum: SelectedProjectModel,
  ) {}

  matches(): boolean {
    return (
      this.selectedProjectType?.label === PROJECT &&
      this.selectedAlbum.useSetup &&
      this.selectedAlbum.role === Number(ProjectRoleId.ROLE_EXEC)
    );
  }

  apply(): void {
    this.viewProjectService.setProjectRole(
      this.currentSelectedProjectData.role,
    );
    this.userService.resetData();
    this.launcherService.setIsGallaryModeStatus(true);
    this.launcherService.setIsProjectUseSetupStatus(true);
    this.launcherService.updateProjectChangeState(true);
    this.launcherService.setSelectedProject(this.selectedAlbum.value);

    this.launcherService.setGallariesDepartmentID(
      this.selectedAlbum.departmentId,
    );
    this.departmentService.setSetUpNameGlobal(this.selectedAlbum.value);
    this.departmentService.setSetUpNameGroupGlobal(this.selectedAlbum.label);
    this.launcherService.setProjectRole(this.selectedAlbum.role);
    this.router.navigate(
      ['/dashboard/viewgallery/' + this.selectedAlbum.value],
      { state: { selectedProjectRole: this.selectedAlbum.role } },
    );
  }
}
