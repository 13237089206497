import { DashboardSelectSignoffWideEditProjectRule } from '../rules/dashboard-select-signoff-wide-edit-project-rule';
import { DashboardSelectExecSetupRole } from '../rules/dashboard-select-exec-setup-role';
import { DashboardSelectWdtvTalentProjectRule } from '../rules/dashboard-select-wdtv-talent-project-rule';
import { DashboardSelectPkoExecProjectRule } from '../rules/dashboard-select-pko-exec-project-rule';
import { DashboardSelectDepartmentRule } from '../rules/dashboard-select-department-rule';
import { DashboardSelectExecutiveProjectRule } from '../rules/dashboard-select-executive-project-rule';
import {
  SelectedProjectModel,
  SelectedProjectTypeModel,
} from '../interfaces/dashboard-select-project-rule.model';

type DashboardEventsUnion =
  | DashboardSelectSignoffWideEditProjectRule
  | DashboardSelectExecSetupRole
  | DashboardSelectWdtvTalentProjectRule
  | DashboardSelectPkoExecProjectRule
  | DashboardSelectDepartmentRule
  | DashboardSelectExecutiveProjectRule;

export class DashboardSelectProjectRuleset {
  ruleSet: DashboardEventsUnion[];

  constructor(
    private currentSelectedProjectData: SelectedProjectModel,
    private selectedProject: string,
    private selectedProjectType: SelectedProjectTypeModel,
    private selectedAlbum: SelectedProjectModel,
  ) {
    this.ruleSet = [
      new DashboardSelectSignoffWideEditProjectRule(
        selectedProject,
        selectedProjectType,
        selectedAlbum,
      ),
      new DashboardSelectExecSetupRole(
        currentSelectedProjectData,
        selectedProject,
        selectedProjectType,
        selectedAlbum,
      ),
      new DashboardSelectWdtvTalentProjectRule(
        currentSelectedProjectData,
        selectedProject,
        selectedProjectType,
        selectedAlbum,
      ),
      new DashboardSelectPkoExecProjectRule(
        currentSelectedProjectData,
        selectedProject,
        selectedProjectType,
        selectedAlbum,
      ),
      new DashboardSelectDepartmentRule(
        currentSelectedProjectData,
        selectedProject,
        selectedProjectType,
        selectedAlbum,
      ),
      new DashboardSelectExecutiveProjectRule(
        currentSelectedProjectData,
        selectedProject,
        selectedProjectType,
        selectedAlbum,
      ),
    ];
  }

  apply(): void {
    for (const rule of this.ruleSet) {
      if (rule.matches()) {
        rule.apply();
      }
    }
  }
}
