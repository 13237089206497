import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'fs-pagination-prev-set-up-component',
  templateUrl: './pagination-prev-set-up-component.component.html',
  styleUrls: ['./pagination-prev-set-up-component.component.scss'],
})
export class PaginationPrevSetUpComponentComponent {
  gotoLastImageInCurrentFn: () => void;
  gotoPreviousFn: () => void;
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<PaginationPrevSetUpComponentComponent>,
  ) {
    this.gotoLastImageInCurrentFn = data.gotoLastImageInCurrentSetUpFn;
    this.gotoPreviousFn = data.gotoPreviousSetUpFn;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeNextSetUpWindow();
  }

  closeNextSetUpWindow() {
    this.dialogRef.close();
  }

  gotoPreviousSetUp() {
    this.gotoPreviousFn();
    this.dialogRef.close();
  }

  gotoLastImageInCurrentSetUp() {
    this.gotoLastImageInCurrentFn();
    this.dialogRef.close();
  }
}
