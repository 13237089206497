import { Component, HostListener, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PopupRef } from '../../../../../../../shared/services/popup/popup-ref';
import { AlbumssharedService } from '../albums-service/albums-shared.service';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { SidebarService } from '../../../../../sidebar-service/sidebar-service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { FS_POPUP_DATA } from '../../../../../../../shared/services/popup/popup.constants';
import { Utils } from '../../../../../../../shared/utils/utils';
import { AppStateService } from '../../../../../../../shared/services/app-state.service';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-album-rename',
  templateUrl: 'renamealbum.component.html',
  styleUrls: ['renamealbum.component.scss'],
})
export class RenamealbumComponent
  extends UnsubscriberComponent
  implements OnInit
{
  renameForm: UntypedFormGroup;
  albumData: any;
  albumType: any;
  constantsGlobalData: any;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    evt: KeyboardEvent,
  ) {
    this.closeRenamePopup();
  }

  constructor(
    private albumSharedService: AlbumssharedService,
    private modalService: Modalstatusservice,
    private sidebarService: SidebarService,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private fb: UntypedFormBuilder,
    private popupRef: PopupRef<RenamealbumComponent>,
    private appStateService: AppStateService,
    @Inject(FS_POPUP_DATA) private data: any,
  ) {
    super();
    this.albumType = data;
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.sidebarService.selectedAlbum
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        if (!Utils.isObjectEmpty(album)) {
          this.albumData = album;
        }
      });
    this.renameForm = this.createControls();
  }

  createControls() {
    return this.fb.group({
      albumRename: [this.albumData.value, Validators.required],
    });
  }

  closeRenamePopup() {
    this.popupRef.hide();
  }

  submitForm() {
    const obj = {
      id: this.albumData.id,
      value: this.renameForm.value.albumRename,
      parentAlbumId: this.albumData.parentAlbumId,
      projectId: this.viewProjectService.getProjectId(),
      albumTypeId: this.albumData.albumTypeId,
    };
    this.loaderService.displayLoader(true);
    this.albumSharedService
      .callRenameAlbum(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.renameForm.reset();
          this.loaderService.displayLoader(false);
          this.appStateService.setRenameAlbum({
            loaded: true,
            data: Utils.addValueToObject(res, 'dialogName', this.albumType),
          });
          this.popupRef.hide();
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.renameForm.reset();
          this.loaderService.displayLoader(false);
        },
      );
  }
}
