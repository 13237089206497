import { Component, Input } from '@angular/core';

@Component({
  selector: 'fs-priority-pix-icon-component',
  templateUrl: './priority-pix-icon-component.component.html',
  styleUrls: ['./priority-pix-icon-component.component.scss'],
})
export class PriorityPixIconComponentComponent {
  @Input() priorityPixAccess;
  @Input() isTaggerRole;
  @Input() imgData;
  @Input() assetType;
}
