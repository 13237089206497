import {
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Modalstatusservice } from '../../../../../../../../shared/services/modalstatus.service';
import { UserService } from '../../../../../../../../shared/services/user.service';
import { ConstantsGlobal } from '../../../../../../../../shared/constants/constants';
import { ExportType } from '../../../../../../../../shared/models/enum/ExportType';

@Component({
  selector: 'fs-advanceimage-corrct',
  templateUrl: './advanceImage-correct.component.html',
  styleUrls: ['./advanceImage-correct.component.scss'],
})
export class AdvanceImageCorrectComponent implements OnInit {
  strSelectedImageList: any = '';
  arrListToExport: any = [];
  selectAll: boolean;
  exportType = ExportType;
  @ViewChild('listTextArea') listTextArea;
  constantsGlobalData: any;

  constructor(
    private modalService: Modalstatusservice,
    private userService: UserService,
    private dialogRef: MatDialogRef<AdvanceImageCorrectComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {}

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.strSelectedImageList = '';
    this.filterImageNames(this.data);
  }

  filterImageNames(imageList: any) {
    imageList.forEach((list) => {
      this.strSelectedImageList += list + '\n';
    });
  }

  onClickExport(reportType) {
    this.arrListToExport = [];
    const tempArray = this.strSelectedImageList.split('\n');
    tempArray.forEach((item) => {
      this.arrListToExport.push({
        name: item,
      });
    });

    if (reportType === this.exportType.Excel) {
      this.userService.exportReport(
        'Selected Filename List',
        this.arrListToExport,
        'SelectedFilenameList.xlsx',
      );
    } else {
      const colNames = this.userService.getColumnsName(this.arrListToExport[0]);
      this.userService.exportPDFData(this.arrListToExport, colNames);
    }
  }

  onClickSelectAll() {
    this.selectAll = !this.selectAll;
    const inputElem = this.listTextArea.nativeElement;
    if (this.selectAll) {
      inputElem.select();
    }
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
