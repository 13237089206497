import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'fs-pagination-next-set-up-component',
  templateUrl: './pagination-next-set-up-component.component.html',
  styleUrls: ['./pagination-next-set-up-component.component.scss'],
})
export class PaginationNextSetUpComponentComponent {
  gotoFirstImageInCurrentFn: () => void;
  gotoNextFn: () => void;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<PaginationNextSetUpComponentComponent>,
  ) {
    this.gotoFirstImageInCurrentFn = data.gotoFirstImageInCurrentSetUpFn;
    this.gotoNextFn = data.gotoNextSetUpFn;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeNextSetUpWindow();
  }

  closeNextSetUpWindow() {
    this.dialogRef.close();
  }

  gotoNextSetUp() {
    this.gotoNextFn();
    this.dialogRef.close();
  }

  gotoFirstImageInCurrentSetUp() {
    this.gotoFirstImageInCurrentFn();
    this.dialogRef.close();
  }
}
