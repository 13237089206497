<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between text-uppercase"
>
  <div class="modal-title">{{ 'top-menu.goto.title' | translate }}</div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeGoTO()"></i>
  </button>
</div>

<div mat-dialog-content>
  <form
    class="container mb-3"
    autocomplete="off"
    [formGroup]="gotoPageForm"
    (ngSubmit)="setGotoSettings()"
  >
    <div class="row flex-fill flex-nowrap align-items-center">
      <label class="col-3 pl-0 pr-0 goto-dropdown-label font-weight-bold">{{
        'top-menu.goto.placeholder.page' | translate
      }}</label>
      <input
        id="gotoPageId"
        fsAutofocus
        type="text"
        class="fs-goto-input form-control col-6 mr-3"
        formControlName="pageNumber"
        name="pageNumber"
        (input)="onPageNumChanged($event.target.value)"
        [(ngModel)]="gotoSettings.gotoPageNumber"
      />
      <button
        type="submit"
        class="fs-goto-btn w-auto col-auto"
        [disabled]="isPageNumGoValid"
      >
        {{ 'top-menu.goto.button.go' | translate }}
      </button>
    </div>
  </form>

  <form
    class="container mb-3"
    autocomplete="off"
    [formGroup]="gotofileForm"
    (ngSubmit)="setGotoSettingsFileName()"
  >
    <div class="row flex-fill flex-nowrap align-items-center">
      <label class="col-3 pl-0 goto-dropdown-label pr-0 font-weight-bold">{{
        'top-menu.goto.placeholder.Filename' | translate
      }}</label>
      <input
        id="gotoFileId"
        type="text"
        class="fs-goto-input form-control mr-3 col-6"
        formControlName="fileName"
        name="fileName"
        (input)="onFileNameChanged($event.target.value)"
        [(ngModel)]="gotoSettings.gotoFileName"
      />
      <button
        type="submit"
        class="fs-goto-btn w-auto col-auto"
        [disabled]="isFileNameGoValid"
      >
        {{ 'top-menu.goto.button.go' | translate }}
      </button>
    </div>
  </form>

  <form
    autocomplete="off"
    class="container"
    *ngIf="
      !isExecutiveAlbumView &&
      isImageSelected &&
      projectAuthority !== projectRole.ROLE_TALENT
    "
    [formGroup]="gotoImageInForm"
  >
    <div class="row flex-fill flex-nowrap flex-row w-100 align-items-center">
      <label
        class="goto-dropdown-label d-flex flex-shrink-0 font-weight-bold text-nowrap"
        >{{ 'top-menu.goto.dropdown.goto' | translate }} ' {{ selectedValue }} '
        In
      </label>

      <mat-select
        class="ml-1 fs-selectbox-dark form-control fs-goto-input fs-sm-txt mr-3 w-100"
        formControlName="selectedAlbumeActor"
        [placeholder]="'top-menu.goto.dropdown.albumoractor' | translate"
        [value]="selectedAlbumeActor"
        (valueChange)="selectedItems($event)"
      >
        <mat-optgroup
          *ngFor="let data of groupedAlbumeActor"
          [label]="data.label"
        >
          <mat-option
            class="fs-sm-txt"
            *ngFor="let item of data.items"
            [value]="item.value"
          >
            {{ item.label }}
          </mat-option>
        </mat-optgroup>
      </mat-select>

      <button
        [disabled]="isAlbumActorGoValid"
        type="button"
        class="fs-goto-btn w-auto col-auto"
        (click)="setGotoSettingsImageIn()"
      >
        {{ 'top-menu.goto.button.go' | translate }}
      </button>
    </div>
  </form>
</div>
