import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[disableCharacterInputDirective]',
})
export class DisableCharacterInputDirective {
  @HostListener('keydown', ['$event']) handleInput(event): void {
    if (event.keyCode > 31 && (event.keyCode < 48 || event.keyCode > 57)) {
      event.preventDefault();
    }
  }
}
