import { LauncherService } from '../../../../launcher/launcher.service';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';
import { FavSelectedProjectModel } from '../../dashboard-favourite-project/interfaces/dashboard-favourite-icon-rule.model';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';

export class DashboardDepartmentFavouriteAddProjectRule
  implements DashboardRuleModel
{
  private launcherService = GetStaticInjector().get(LauncherService);
  private loaderService = GetStaticInjector().get(LoaderService);
  private dialogService = GetStaticInjector().get(DialogService);
  private translate = GetStaticInjector().get(TranslateService);
  private apiErrorHandlerService = GetStaticInjector().get(ApiErrorHandlerService);

  constructor(
    private active: boolean,
    private selectedProject: FavSelectedProjectModel,
  ) {}

  matches(): boolean {
    return this.active;
  }

  apply(): void {
    const objToAdd = {
      active: true,
      id: this.selectedProject.value,
      name: this.selectedProject.label,
    };
    this.loaderService.displayLoader(true);
    this.launcherService.updateFav(objToAdd).subscribe((res) => {
      if (res.status) {
        this.selectedProject.fav = true;
      } else {
        // There is a limit of 6 on favourite projects list - show error alert/popup
        this.dialogService.openInformationDialog({
          title: this.translate.instant('dashboard.favouriteMessage'),
          message: res.message,
          iconStyle: 'fa fa-exclamation-circle',
        });
      }
      this.loaderService.displayLoader(false);
    },
      (error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }
}
