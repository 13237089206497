<div class="fs-popup-title fs-cursor-move d-flex justify-content-between">
  <div class="modal-title text-uppercase">
    {{ 'renamealbum.title' | translate }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    aria-hidden="true"
    (click)="closeRenamePopup()"
  >
    <i class="fa fa-times-circle"></i>
  </button>
</div>

<div class="fs-popup-content" [formGroup]="renameForm">
  <fieldset class="form-group">
    <input
      fsAutofocus
      type="text"
      class="form-control"
      id="albumRename"
      name="albumRename"
      formControlName="albumRename"
      placeholder="{{ 'renamealbum.text' | translate }}"
    />
  </fieldset>
</div>

<div class="fs-popup-actions d-flex justify-content-center">
  <button class="fs-dialog-btn w-auto mr-3" (click)="closeRenamePopup()">
    {{ 'renamealbum.buttons.cancel' | translate }}
  </button>
  <button
    class="fs-dialog-btn w-auto"
    [disabled]="!renameForm.valid"
    (click)="submitForm()"
  >
    {{ 'renamealbum.buttons.rename' | translate }}
  </button>
</div>
