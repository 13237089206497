import { DashboardDepartmentAlbumRule } from '../rules/dashboard-department-album-rule';
import { DashboardDepartmentExecProjectRule } from '../rules/dashboard-department-exec-project-rule';
import { DashboardDepartmentExecSetupRule } from '../rules/dashboard-department-exec-setup-rule';
import { DashboardDepartmentTalentWdtvRule } from '../rules/dashboard-department-talent-wdtv-rule';
import { DashboardDepartmentAlbumViewerRule } from '../rules/dashboard-department-albumviewers-rule';
import { DashboardDepartmentTalentPkoRule } from '../rules/dashboard-department-talent-pko-rule';
import {
  GroupDataModel,
  GroupItemDataModel,
} from '../interfaces/dashboard-department-rule.model';

export type DashboardEventsUnion =
  | DashboardDepartmentAlbumRule
  | DashboardDepartmentExecProjectRule
  | DashboardDepartmentExecSetupRule
  | DashboardDepartmentTalentWdtvRule
  | DashboardDepartmentAlbumViewerRule
  | DashboardDepartmentTalentPkoRule;

export class DashboardDepartmentRuleSet {
  ruleSet: DashboardEventsUnion[];

  constructor(
    private group: GroupDataModel,
    private galBotInfo: GroupItemDataModel,
    private selectedProject: number,
  ) {
    this.ruleSet = [
      new DashboardDepartmentAlbumRule(group, galBotInfo),
      new DashboardDepartmentTalentWdtvRule(group, galBotInfo),
      new DashboardDepartmentAlbumViewerRule(group, galBotInfo),
      new DashboardDepartmentTalentPkoRule(group, galBotInfo),
      new DashboardDepartmentExecSetupRule(group, galBotInfo),
      new DashboardDepartmentExecProjectRule(group, galBotInfo),
    ];
  }

  apply(): void {
    for (const rule of this.ruleSet) {
      if (rule.matches()) {
        rule.apply();
      }
    }
  }
}
