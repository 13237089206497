import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from '../components/spinner/spinner.component';

@Injectable()
export class LoaderService {
  private renderer: Renderer2;
  private overlayRef: OverlayRef = this.overlay.create({
    panelClass: ['fs-spinner-overlay-panel'],
  });

  constructor(
    private overlay: Overlay,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  displayLoader(show: boolean) {
    show ? this.attachSpinner() : this.detachSpinner();
  }

  attachSpinner() {
    if (!this.isOverlayAttached()) {
      const spinnerOverlayPortal = new ComponentPortal(SpinnerComponent);
      this.overlayRef.attach(spinnerOverlayPortal);
    }
  }

  detachSpinner() {
    if (this.isOverlayAttached()) {
      this.renderer.setStyle(this.overlayRef.overlayElement, 'opacity', '0');
      setTimeout(() => {
        this.overlayRef.detach();
        this.renderer.setStyle(this.overlayRef.overlayElement, 'opacity', '1');
      }, 500);
    }
  }

  isOverlayAttached() {
    return this.overlayRef.hasAttached();
  }
}
