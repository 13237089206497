import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';

// infinite scroll
import { DashboardComponent } from './dashboard.component';
import { DashboardRouting } from './dashboard.routing';
import { SidebarService } from './project/sidebar-service/sidebar-service';
import { GroupService } from './project/project-detail/view-project/group/group-service';
import { DepartmentComponent } from './department/department.component';
import { DepartmentService } from './department/department.service';
import { ProjectModule } from './project/project.module';
import { SharedModule } from '../shared/shared.module';
import { LauncherComponent } from './launcher/launcher.component';
import { ExecutiveComponent } from './executive/executive.component';
import { FavouriteProjectComponent } from './launcher/favourite-project/favourite-project.component';
import { SearchProjectComponent } from './search-project/search-project.component';
import { FilterPipe } from './search-project/filter.pipe';
import { LauncherService } from './launcher/launcher.service';
import { ExecutiveService } from './executive/executive.service';
import { ViewGalleryResumeModalStateService } from '../shared/services/view-gallery-resume-modal.service';
import { ViewGalleryService } from './project/project-detail/view-gallery/view-gallery.service';
import { DrawImageService } from './project/project-detail/draw-image/draw-image.service';
import { CompareService } from './project/project-detail/compare/compare.service';
import { CaptionService } from './project/project-detail/caption-enlarge/caption/caption.service';
import { ViewGalleryPriorityPixStateService } from '../shared/services/view-gallery-priority-pix.service';
import { ExpressDownloadResultsModule } from '../shared/layout/express-download-results-container/express-download-results.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  imports: [
    NgxPaginationModule,
    DashboardRouting,
    InfiniteScrollModule,
    ProjectModule,
    SharedModule,
    ExpressDownloadResultsModule,
  ],
  declarations: [
    DashboardComponent,
    LauncherComponent,
    DepartmentComponent,
    ExecutiveComponent,
    FavouriteProjectComponent,
    SearchProjectComponent,
    FilterPipe,
  ],

  providers: [
    GroupService,
    SidebarService,
    LauncherService,
    ViewGalleryService,
    DrawImageService,
    CompareService,
    DepartmentService,
    ExecutiveService,
    CaptionService,
    ViewGalleryPriorityPixStateService,
    ViewGalleryResumeModalStateService,
  ],
})
export class DashboardModule {}
