import { AppConfig } from './../../../../app.configuration';
import { DrawImageService } from './../../project-detail/draw-image/draw-image.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TopmenusService } from './top-menu.service';
import { CompareService } from '../../project-detail/compare/compare.service';
import { SidebarService } from '../../sidebar-service/sidebar-service';
import { LauncherService } from '../../../launcher/launcher.service';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import { DialogService } from '../../../../shared/services/dialog/dialog.service';
import { LinkComponent } from './link/link.component';
import { ViewsettingsComponent } from './viewsettings/viewsettings.component';
import { SendmailComponent } from './sendmail/sendmail.component';
import { PrinttoolComponent } from './printtool/printtool.component';
import { DeleteComponent } from './deleteassets/deleteassets.component';
import { SecondCompareComponent } from './secondlevelcompare/secondcompare.component';
import { IMDoneComponent } from './IMDone/imdone.component';
import { DownloadComponent } from './download/download.component';
import { GotoComponent } from './goto/goto.component';
import { DepartmentService } from '../../../department/department.service';
import { HelpWindowComponent } from '../../../../shared/layout/help/help.component';
import { AppStateService } from '../../../../shared/services/app-state.service';
import { ProjectDetailPermission } from '../../../../shared/models/projectpermission.model';
import { SelectedImages } from '../../../../shared/models/selectedImages.model';
import { UserRole } from '../../../../shared/enum/user-role.enum';
import { Modalstatusservice } from '../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../shared/services/view-project.service';
import { CurrentStateService } from '../../../../shared/services/current.state.service';
import { LoaderService } from '../../../../shared/services/loader.service';
import { UserService } from '../../../../shared/services/user.service';
import { ConstantsGlobal } from '../../../../shared/constants/constants';
import { Utils } from '../../../../shared/utils/utils';
import { PopupService } from '../../../../shared/services/popup/popup.service';
import { takeUntil } from 'rxjs/operators';
import { LastSelectedImageStore } from '../../../../shared/store/last-selected-image-store';
import { SelectedImagesStore } from '../../../../shared/store/selected-images-store';
import { ImageNotesDialogComponent } from '../left-menu/menus/imageinfo/image-info-dialog/image-notes-dialog.component';
import { DialogsStateService } from '../../../../shared/services/dialog/dialogs-state.service';
import { UnsubscriberComponent } from '../../../../shared/components/unsubscriber/unsubscriber.component';
import { MetadataComponent } from '../../project-detail/view-project/metadata/metadata.component';
import { map } from 'rxjs/internal/operators/map';
import { forkJoin, Observable } from 'rxjs';
import { AssetInfoDTOList } from '../../../../shared/models/assetInfo.model';
import { ExpressDownloadComponent } from './express-download/express-download.component';
import { ProjectRole } from '../../../../shared/enum/project-role.enum';
import { ExclusiveSensitiveAssetDialogComponent } from '../../../../shared/components/exclusive-sensitive-asset-dialog/exclusive-sensitive-asset-dialog.component';
import { DownloadService } from './download/download.service';
import {AssetErrorKeys} from '../../../../shared/enum/asset-error-keys.enum';
import {ApiErrorHandlerService} from '../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent extends UnsubscriberComponent implements OnInit {
  static identifier = 'TopMenuComponent';
  @ViewChild('addNoteIcon') private addNoteIcon: ElementRef;

  projectDetailPermission: ProjectDetailPermission =
    new ProjectDetailPermission();

  showDiv: boolean;
  showAllImages: boolean;
  isEnlargeTopMenu = false;
  isSupperZoomTopMenu = false;
  islinkPage = false;
  isLeftMenuPartShown = true;
  totalImageCount = 0;
  totalFilterCount = 0;
  lastSelectedImage2: SelectedImages[] = [];
  currentTopMenu: any;

  isPagination = false;
  setUpTitle: string;
  currentState: any;
  setUpGroup;
  projectAuthority: string;
  userAuthority: string;
  selectedProjectValue;
  isEnlargeView = false;
  isExecutiveAlbumView = false;
  sensitiveID: string;
  isClickedOnAddNote = false;
  isCurrentFavorite = false;
  favList = [];
  favTitle = '';
  constantsGlobalData: any;
  minExpressDownload: number;
  userRole = UserRole;
  projectRole = ProjectRole;

  // Project Permission
  downloadType = 0;
  metaDataAccess = false;
  downloadOnlyLA = false;
  emailAccess = false;
  hasWatermark = false;
  printAccess = false;
  deleteAssetAccess = false;
  adhocLinkAccess = false;
  expressDownload = false;
  isTaggerRole: boolean;

  SELECTION_LIMIT = 250;
  SELECTION_PRINT_LIMIT = 1000;
  userRoles = UserRole;
  startAccess: any;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private topmenuService: TopmenusService,
    private modalService: Modalstatusservice,
    private compareService: CompareService,
    private viewProjectService: ViewProjectService,
    private sidebarService: SidebarService,
    private currentStateService: CurrentStateService,
    private departmentService: DepartmentService,
    private loaderService: LoaderService,
    private userService: UserService,
    private launcherService: LauncherService,
    private snackBarService: SnackBarService,
    private drawImageService: DrawImageService,
    private downloadService: DownloadService,
    private dialogService: DialogService,
    private appStateService: AppStateService,
    private popupService: PopupService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    public selectedImagesStore: SelectedImagesStore,
    public lastSelectedImageStore: LastSelectedImageStore,
    public dialogStateService: DialogsStateService,
  ) {
    super();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.isTaggerRole = this.userService.isAdminWithTaggerRole();

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority) => {
        this.userAuthority = userAuthority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.search('enlarge') !== -1 ||
          event.url.search('compare') !== -1
        ) {
          this.isEnlargeView = true;
        } else {
          this.isEnlargeView = false;
        }
      }
    });

    this.viewProjectService.isExecutiveAlbumGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isExecutiveAlbumView = status;
      });

    this.viewProjectService.sensitiveIDGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((id) => {
        this.sensitiveID = id.toString();
      });

    this.topmenuService.cast
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.showDiv = state;
      });

    this.topmenuService.topMenuPagination
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.isPagination = state;
      });

    this.currentStateService.getCurrentState
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentState) => {
        this.currentState = currentState;
      });

    this.departmentService.setUpNameGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((title) => {
        this.setUpTitle = title;
      });

    this.departmentService.setUpNameGroupGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((title) => {
        this.setUpGroup = title;
      });

    this.launcherService.selectedProjectGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectValue) => {
        this.selectedProjectValue = Number(projectValue);
      });

    this.topmenuService.topMenuStatus
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.currentTopMenu = status;
        switch (status) {
          case 'Enlarge':
            this.isEnlargeTopMenu = true;
            this.isSupperZoomTopMenu = false;
            break;
          case 'SuperZoom':
            this.isEnlargeTopMenu = false;
            this.isSupperZoomTopMenu = true;
            break;
          default:
        }
      });

    this.viewProjectService.isLeftMenuOpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((leftmenuState) => {
        this.isLeftMenuPartShown = leftmenuState;
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.projectDetailPermission = data;
          this.setAllPermission(data);
          this.isCurrentFavorite = false;
          if (this.projectDetailPermission.fav) {
            this.isCurrentFavorite = this.projectDetailPermission.fav;
            if (this.isCurrentFavorite) {
              this.favTitle = this.translate.instant(
                'dashboard.removeFromShortcuts',
              );
            } else {
              this.favTitle = this.translate.instant(
                'dashboard.addToShortcuts',
              );
            }
          } else {
            this.favTitle = this.translate.instant('dashboard.addToShortcuts');
          }
        } else {
          this.resetAllPermission();
        }
      });

    this.viewProjectService.totalImageCountGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((count) => {
        this.totalImageCount = count;
        this.totalFilterCount = count;
      });

    this.sidebarService.albumImageDataGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.totalFilterCount = res.assetInfoDTOList.length;
        }
      });

    this.lastSelectedImageStore
      .getCollection()
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageArray) => {
        this.lastSelectedImage2 = imageArray;
      });

    this.viewProjectService.isAddNoteFromTopMenuGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (!response) {
          this.isClickedOnAddNote = false;
        }
      });
  }

  showAll() {
    this.showAllImages = !this.showAllImages;
  }

  showHelpWindow(helpTopic?, videoName?) {
    this.dialogService.open(HelpWindowComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
        'fs-dialog-no-overflow-panel',
      ],
      data: {
        topicName: helpTopic,
        videoName: videoName,
      },
    });
  }

  setAllPermission(data) {
    if (
      this.userAuthority === UserRole.ROLE_ADMIN ||
      this.projectAuthority === ProjectRole.ROLE_EXEC ||
      this.projectAuthority === ProjectRole.ROLE_ALBUMVIEWER
    ) {
      this.downloadType = data.permissionDTO.downloadType;
      this.downloadOnlyLA = data.permissionDTO.downloadOnlyLA;
      this.emailAccess = data.permissionDTO.emailAccess;
      this.printAccess = data.permissionDTO.printAccess;
      this.expressDownload = data.permissionDTO.expressDownload;
      this.deleteAssetAccess = data.permissionDTO.deleteAssetAccess;
      this.adhocLinkAccess = data.permissionDTO.adhocLinkAccess;
      this.metaDataAccess = data.permissionDTO.metaDataAccess;
      this.minExpressDownload = data.permissionDTO.minExpressDownload;
      this.hasWatermark = data.permissionDTO.watermark;
    }
  }

  resetAllPermission() {
    this.downloadType = 0;
    this.emailAccess = false;
    this.downloadOnlyLA = false;
    this.metaDataAccess = false;
    this.printAccess = false;
    this.deleteAssetAccess = false;
    this.adhocLinkAccess = false;
  }

  selectAllImages() {
    this.appStateService.setSelectAll({ loaded: true });
  }

  deSelectAllImages() {
    this.appStateService.setDeselectAll({ loaded: true });
  }

  compareImages() {
    this.appStateService.setCompare({ active: true });
  }

  secondLevelCompare() {
    const selectedImages = this.compareService.getAllselectedImages();

    if (selectedImages && selectedImages.size === 2) {
      if (
        Array.from(selectedImages)[0][1].id !==
        Array.from(selectedImages)[1][1].id
      ) {
        this.dialogService.open(SecondCompareComponent, {
          panelClass: ['fs-dialog-panel', 'fs-dialog-full-screen-panel'],
          data: { selectedImages },
        });
      } else {
        this.alertCustom(this.translate.instant('view-project.alert29'));
      }
    } else {
      this.alertCustom(this.translate.instant('view-project.alert30'));
    }
  }

  compareClose() {
    this.appStateService.setCompare({ active: false });
  }

  closeDraw() {
    this.drawImageService.setCloseDrawGlobal(true);
  }

  saveDraw() {
    this.drawImageService.setCloseDrawGlobal(true);
  }

  closeEnlarge() {
    this.appStateService.setCloseZoomDialog({ loaded: true });
  }

  enlargeImage() {
    this.appStateService.setZoomDialog({ loaded: true });
  }

  goToPage() {
    this.dialogService.open(GotoComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-message-base-panel',
        'fs-dialog-auto-width-panel',
      ],
      listener: {
        withListener: true,
        id: 'CaptionReportComponent',
      },
      position: {
        top: '0px',
      },
    });
  }

  onOpenImDoneModal() {
    this.dialogService.open(IMDoneComponent, {
      panelClass: ['fs-dialog-panel', 'fs-dialog-link-panel'],
    });
  }

  onClickNoteIcon() {
    if (this.selectedImagesStore.hasEntities()) {
      this.popupService.open(
        ImageNotesDialogComponent,
        this.addNoteIcon.nativeElement,
        {
          panelClass: ['fs-popup-base-panel', 'fs-popup-md-panel'],
          position: 'below',
          closeOnBackdropClick: false,
          hasBackdrop: false,
          withArrow: false,
          data: {
            isFromTopMenu: true,
            projectAuthority: this.projectAuthority,
          },
        },
      );
      this.viewProjectService.isAddNoteFromAddNote.next(false);
      this.viewProjectService.isAddNoteFromTopMenu.next(true);
    } else {
      this.alertCustom(this.translate.instant('view-project.alert13'));
    }
  }

  getSetUpTitle(setUpGroup, setUpTitle) {
    return `${setUpGroup} - ${setUpTitle}`;
  }

  setViewSetting() {
    this.dialogService.open(ViewsettingsComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-md-panel',
        'fs-dialog-link-panel',
      ],
      position: {
        top: '112px',
        right: '240px',
      },
      listener: {
        withListener: true,
        id: 'ViewsettingsComponent',
      },
    });
  }

  sendMail() {
    if (
      this.selectedImagesStore.hasEntities() &&
      this.selectedImagesStore.size() <= this.SELECTION_LIMIT
    ) {
      this.getSensitiveAndExclusiveImages()
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          ([sensitiveAssets, exclusiveAssets]: [
            AssetInfoDTOList[],
            AssetInfoDTOList[],
          ]) => {
            if (!sensitiveAssets.length && !exclusiveAssets.length) {
              if (
                this.userAuthority === UserRole.ROLE_EXTERNAL &&
                this.projectAuthority === ProjectRole.ROLE_EXEC
              ) {
                if (this.emailAccess) {
                  this.callEmailAPI();
                } else {
                  this.loaderService.displayLoader(false);
                  this.alertCustom('You do not have permission to send email.');
                }
              } else {
                this.callEmailAPI();
              }
            } else if (
              this.isAllSelectedAssetsExclusiveOrSensitive(
                sensitiveAssets,
                exclusiveAssets,
              )
            ) {
              this.loaderService.displayLoader(false);
              this.alertCustom(
                this.translate.instant(
                  'top-menu.expressDownload.error.allAssetAreExclusiveOrSensitive',
                ),
              );
            } else {
              this.loaderService.displayLoader(false);
              this.openExclusiveSensitiveAssetModal(
                Utils.mergeAndRemoveDuplicatesByKey<AssetInfoDTOList>(
                  [...sensitiveAssets, ...exclusiveAssets],
                  'id',
                ),
                this.callEmailAPI,
              );
            }
          },(error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          }
        );
    } else if (this.selectedImagesStore.size() >= this.SELECTION_LIMIT + 1) {
      this.alertCustom(this.translate.instant('view-project.alert41'));
    } else {
      this.alertCustom(this.translate.instant('view-project.alert13'));
    }
  }

  printTool() {
    if (
      this.selectedImagesStore.hasEntities() &&
      this.selectedImagesStore.size() <= this.SELECTION_PRINT_LIMIT
    ) {
      this.getSensitiveAndExclusiveImages()
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          ([sensitiveAssets, exclusiveAssets]: [
            AssetInfoDTOList[],
            AssetInfoDTOList[],
          ]) => {
            if (!sensitiveAssets.length && !exclusiveAssets.length) {
              if (
                this.userAuthority === UserRole.ROLE_EXTERNAL &&
                this.projectAuthority === ProjectRole.ROLE_EXEC
              ) {
                if (this.printAccess) {
                  this.callPrintAPI();
                } else {
                  this.loaderService.displayLoader(false);
                  this.alertCustom('You do not have permission to print.');
                }
              } else {
                this.callPrintAPI();
              }
            } else if (
              this.isAllSelectedAssetsExclusiveOrSensitive(
                sensitiveAssets,
                exclusiveAssets,
              )
            ) {
              this.loaderService.displayLoader(false);
              this.alertCustom(
                this.translate.instant(
                  'top-menu.expressDownload.error.allAssetAreExclusiveOrSensitive',
                ),
              );
            } else {
              this.loaderService.displayLoader(false);
              this.openExclusiveSensitiveAssetModal(
                Utils.mergeAndRemoveDuplicatesByKey<AssetInfoDTOList>(
                  [...sensitiveAssets, ...exclusiveAssets],
                  'id',
                ),
                this.callPrintAPI,
              );
            }
          },(error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          }
        );
    } else if (
      this.selectedImagesStore.size() >=
      this.SELECTION_PRINT_LIMIT + 1
    ) {
      this.alertCustom(this.translate.instant('view-project.alert42'));
    } else {
      this.alertCustom(this.translate.instant('view-project.alert13'));
    }
  }

  onOpenExpressDownloadModal(): void {
    if (this.selectedImagesStore.hasEntities()) {
      this.getSensitiveAndExclusiveImages()
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          ([sensitiveAssets, exclusiveAssets]: [
            AssetInfoDTOList[],
            AssetInfoDTOList[],
          ]) => {
            if (!sensitiveAssets.length && !exclusiveAssets.length) {
              this.openExpressDownloadDialog();
            } else if (
              this.isAllSelectedAssetsExclusiveOrSensitive(
                sensitiveAssets,
                exclusiveAssets,
              )
            ) {
              this.loaderService.displayLoader(false);
              this.alertCustom(
                this.translate.instant(
                  'top-menu.expressDownload.error.allAssetAreExclusiveOrSensitive',
                ),
              );
            } else {
              this.loaderService.displayLoader(false);
              this.openExclusiveSensitiveAssetModal(
                Utils.mergeAndRemoveDuplicatesByKey<AssetInfoDTOList>(
                  [...sensitiveAssets, ...exclusiveAssets],
                  'id',
                ),
                this.openExpressDownloadDialog,
              );
            }
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    } else {
      this.loaderService.displayLoader(false);
      this.alertCustom(this.translate.instant('view-project.alert13'));
    }
  }

  openExpressDownloadDialog = (): void => {
    if (this.minExpressDownload <= this.selectedImagesStore.size()) {
      this.loaderService.displayLoader(true);
      this.downloadService.getVideoAssetInfo().subscribe(
        (videoAssets) => {
          this.loaderService.displayLoader(false);
          this.dialogService.getDialogState('ExpressDownloadComponent');
          this.dialogService.open(ExpressDownloadComponent, {
            panelClass: [
              'fs-dialog-panel',
              'fs-dialog-size-lg-panel',
              'fs-dialog-link-panel',
            ],
            listener: {
              withListener: true,
              id: 'ExpressDownloadComponent',
            },
            data: { stepIndex: 0, isFromTopMenu: true, videoAssets },
          });
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
    } else {
      this.loaderService.displayLoader(false);
      this.alertCustom(
        this.translate.instant(
          'top-menu.expressDownload.error.minExpressDownloadNumber',
        ) + this.minExpressDownload,
      );
    }
  };

  openExclusiveSensitiveAssetModal(
    extractAssets: AssetInfoDTOList[],
    downloadFn,
  ) {
    this.dialogService.open(ExclusiveSensitiveAssetDialogComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
      ],
      listener: {
        withListener: true,
        id: 'ExclusiveSensitiveAssetDialogComponent',
      },
      data: {
        openDownloadDialog: downloadFn,
        extractAssets: extractAssets,
        isFromTopMenu: true,
      },
    });
  }

  favTool(isFav?) {
    let objToRemove: any;
    if (isFav) {
      objToRemove = {
        active: false,
        id: this.projectDetailPermission.projectId,
        name: this.projectDetailPermission.projectName,
      };
      this.loaderService.displayLoader(true);
      this.launcherService
        .updateFav(objToRemove)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.status) {
            this.projectDetailPermission.fav = false;
            this.isCurrentFavorite = false;
            this.favTitle = this.translate.instant('dashboard.addToShortcuts');
            this.snackBarService.showSnackBar(
              this.translate.instant('dashboard.favorites'),
            );
          }

          this.loaderService.displayLoader(false);
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    } else {
      objToRemove = {
        active: true,
        id: this.projectDetailPermission.projectId,
        name: this.projectDetailPermission.projectName,
      };

      this.loaderService.displayLoader(true);
      this.launcherService
        .updateFav(objToRemove)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.status) {
            this.projectDetailPermission.fav = true;
            this.isCurrentFavorite = true;
            this.favTitle = this.translate.instant(
              'dashboard.removeFromShortcuts',
            );
            this.snackBarService.showSnackBar(
              this.translate.instant('dashboard.favorites'),
            );
          } else {
            this.dialogService.openInformationDialog({
              title: this.translate.instant('dashboard.favouriteMessage'),
              message: res.message,
              iconStyle: 'fa fa-exclamation-circle',
            });
          }
          this.loaderService.displayLoader(false);
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    }
  }

  deleteAssets() {
    if (this.selectedImagesStore.hasEntities()) {
      this.dialogService.open(DeleteComponent, {
        panelClass: [
          'fs-dialog-panel',
          'fs-dialog-size-md-panel',
          'fs-dialog-message-base-panel',
        ],
      });
    } else {
      this.alertCustom(this.translate.instant('view-project.alert13'));
    }
  }

  Download() {
    if (this.selectedImagesStore.hasEntities()) {
      if (
        this.selectedImagesStore.size() > this.SELECTION_LIMIT &&
        !AppConfig.SETTINGS.EXPRESS_DOWNLOAD
      ) {
        this.alertCustom(this.translate.instant('view-project.alert41'));
      } else {
        this.downloadService
          .getSensitiveImages()
          .pipe(takeUntil(this.destroy$))
          .subscribe((sensitiveAssets: AssetInfoDTOList[]) => {
            this.downloadService
              .getExclusiveImages()
              .pipe(takeUntil(this.destroy$))
              .subscribe((exclusiveAssets: AssetInfoDTOList[]) => {
                if (!sensitiveAssets.length && !exclusiveAssets.length) {
                  if (
                    this.userAuthority === UserRole.ROLE_EXTERNAL &&
                    this.projectAuthority === ProjectRole.ROLE_EXEC
                  ) {
                    if (this.checkDownloadStatus()) {
                      this.callDownloadAPI();
                    } else {
                      this.loaderService.displayLoader(false);
                      this.alertCustom(
                        "You do not have permission to download image'(s).",
                      );
                    }
                  } else {
                    this.callDownloadAPI();
                  }
                } else if (
                  this.isAllSelectedAssetsExclusiveOrSensitive(
                    sensitiveAssets,
                    exclusiveAssets,
                  )
                ) {
                  this.loaderService.displayLoader(false);
                  this.alertCustom(
                    this.translate.instant(
                      'top-menu.expressDownload.error.allAssetAreExclusiveOrSensitive',
                    ),
                  );
                } else {
                  this.loaderService.displayLoader(false);
                  this.openExclusiveSensitiveAssetModal(
                    Utils.mergeAndRemoveDuplicatesByKey<AssetInfoDTOList>(
                      [...sensitiveAssets, ...exclusiveAssets],
                      'id',
                    ),
                    this.callDownloadAPI,
                  );
                }
              },(error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              });
          },(error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          });
      }
    } else {
      this.alertCustom(this.translate.instant('view-project.alert13'));
    }
  }

  checkDownloadStatus() {
    if (this.downloadType) {
      return true;
    } else if (this.downloadOnlyLA) {
      let lockApproved = true;
      this.selectedImagesStore.getAll().forEach((item) => {
        if (item.status !== 5) {
          lockApproved = false;
          return lockApproved;
        }
      });
      return lockApproved;
    }
  }

  callDownloadAPI = () => {
    this.loaderService.displayLoader(true);
    const dataObj = {
      assetIds: this.isEnlargeView
        ? [this.lastSelectedImageStore.getHeadItem().id]
        : this.selectedImagesStore.getItemsIds(),
      filter: 'download',
      projectId: this.viewProjectService.getProjectId(),
      authorities: [this.userAuthority],
    };
    this.topmenuService
      .postAssetsInfodata(dataObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            const obj = Utils.getAssetDetaillObj(
              res.message,
              res.t,
              this.viewProjectService.getProjectId(),
              this.projectDetailPermission,
              this.viewProjectService.getCurrentUsername(),
            );
            this.topmenuService.postAssetsDetail(obj).subscribe(
              (resData) => {
                if (
                  Object.keys(resData.assetDetail.Image).length === 0 &&
                  Object.keys(resData.assetDetail.Other).length === 0
                ) {
                  this.alertCustom('No image size is available for download');
                } else {
                  this.openDownloadDialog(resData);
                }
                this.loaderService.displayLoader(false);
              },
              (error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              },
            );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(res.message);
          }
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  };

  openDownloadDialog(resData: any) {
    this.dialogService.open(DownloadComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
      ],
      listener: {
        withListener: true,
        id: 'DownloadComponent',
      },
      data: resData,
    });
  }

  callEmailAPI = () => {
    this.loaderService.displayLoader(true);
    const dataObj = {
      assetIds: this.isEnlargeView
        ? [this.lastSelectedImageStore.getHeadItem().id]
        : this.selectedImagesStore.getItemsIds(),
      filter: 'email',
      projectId: this.viewProjectService.getProjectId(),
      authorities: [
        this.userAuthority === UserRole.ROLE_ADMIN
          ? this.userAuthority
          : this.projectAuthority,
      ],
    };
    this.topmenuService
      .postAssetsInfodata(dataObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            const obj = Utils.getAssetDetaillObj(
              res.message,
              res.t,
              this.viewProjectService.getProjectId(),
              this.projectDetailPermission,
              this.viewProjectService.getCurrentUsername(),
            );
            this.topmenuService
              .postAssetsDetail(obj)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  this.dialogService.open(SendmailComponent, {
                    panelClass: [
                      'fs-dialog-panel',
                      'fs-dialog-size-lg-panel',
                      'fs-dialog-link-panel',
                      'fs-dialog-auto-height-panel',
                    ],
                    data: resData,
                  });
                  this.loaderService.displayLoader(false);
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(res.message);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  };

  callPrintAPI = () => {
    this.loaderService.displayLoader(true);
    const dataObj = {
      assetIds: this.isEnlargeView
        ? [this.lastSelectedImageStore.getHeadItem().id]
        : this.selectedImagesStore.getItemsIds(),
      filter: 'print',
      projectId: this.viewProjectService.getProjectId(),
      authorities: [this.userAuthority],
    };
    this.topmenuService
      .postAssetsInfodata(dataObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            this.dialogService.open(PrinttoolComponent, {
              panelClass: [
                'fs-dialog-panel',
                'fs-dialog-size-lg-panel',
                'fs-dialog-link-panel',
              ],
              data: res,
            });
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(res.message);
          }
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  };

  linkPage() {
    if (this.selectedImagesStore.hasEntities()) {
      this.getSensitiveAndLinkExclusiveImages()
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          ([sensitiveAssets, exclusiveAssets]: [
            AssetInfoDTOList[],
            AssetInfoDTOList[],
          ]) => {
            if (!sensitiveAssets.length && !exclusiveAssets.length) {
              if (
                this.userAuthority === UserRole.ROLE_EXTERNAL &&
                this.projectAuthority === ProjectRole.ROLE_EXEC
              ) {
                if (this.checkDownloadStatus()) {
                  this.callLinkAPI();
                } else {
                  this.loaderService.displayLoader(false);
                  this.alertCustom(
                    "You do not have permission to share imae'(s).",
                  );
                }
              } else {
                this.callLinkAPI();
              }
            } else {
              this.loaderService.displayLoader(false);
              this.alertCustom(
                this.translate.instant(
                  'top-menu.linkTool.error.exclusiveOrSensitive',
                ),
              );
            }
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    } else {
      this.alertCustom(this.translate.instant('view-project.alert13'));
    }
  }

  callLinkAPI() {
    this.loaderService.displayLoader(true);
    const dataObj = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      filter: this.constantsGlobalData.MODELNAMES.LINK,
      projectId: this.viewProjectService.getProjectId(),
      authorities: [
        this.userAuthority === UserRole.ROLE_ADMIN
          ? this.userAuthority
          : this.projectAuthority,
      ],
    };
    this.topmenuService
      .postAssetsInfodata(dataObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            this.dialogService.open(LinkComponent, {
              panelClass: [
                'fs-dialog-panel',
                'fs-dialog-size-lg-panel',
                'fs-dialog-link-panel',
                'fs-dialog-auto-height-panel',
              ],
              listener: {
                withListener: true,
                id: 'LinkComponent',
              },
              data: res,
            });
          } else {
            this.alertCustom(res.message);
          }
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  getLinkExclusiveImages(): Observable<AssetInfoDTOList[]> {
    const data = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      projectId: this.viewProjectService.getProjectId(),
      projectRoleId: this.viewProjectService.getProjectRole(),
      filter: 'showall',
    };

    this.loaderService.displayLoader(true);
    return this.topmenuService
      .getLinkExclusiveAssets(data)
      .pipe(takeUntil(this.destroy$));
  }

  private isAllSelectedAssetsExclusiveOrSensitive(
    sensitiveAssets,
    exclusiveAssets,
  ): boolean {
    return (
      Utils.mergeAndRemoveDuplicatesByKey<AssetInfoDTOList>(
        [...sensitiveAssets, ...exclusiveAssets],
        'id',
      ).length === this.selectedImagesStore.getItemsIds().length
    );
  }

  private getSensitiveAndLinkExclusiveImages(): Observable<
    [AssetInfoDTOList[], AssetInfoDTOList[]]
  > {
    const sensitiveImages$ = this.downloadService.getSensitiveImages();
    const linkExclusiveImages$ = this.getLinkExclusiveImages();

    return forkJoin([sensitiveImages$, linkExclusiveImages$]);
  }

  private getSensitiveAndExclusiveImages(): Observable<
    [AssetInfoDTOList[], AssetInfoDTOList[]]
  > {
    const sensitiveImages$ = this.downloadService.getSensitiveImages();
    const exclusiveImages$ = this.downloadService.getExclusiveImages();

    return forkJoin([sensitiveImages$, exclusiveImages$]);
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  onClickApprovedIcon() {
    this.appStateService.setApproveDialog({ loaded: true });
  }

  onClickRejectedIcon() {
    this.appStateService.setRejectDialog({ loaded: true });
  }
}
