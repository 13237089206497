<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.finalize-unfinalize.title'
        | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="fs-txt-white-dark-color font-weight-bold text-center mb-2">
    <span>{{ selectedAssestIdList.length }}</span>
    {{
      'left-menu.tab-bar.sidemenus.administration.finalize-unfinalize.labels.image-selected'
        | translate
    }}
  </div>

  <div class="mb-2">
    <mat-select
      class="form-control fs-selectbox-dark"
      required
      [value]="selectedName"
      [placeholder]="
        'left-menu.tab-bar.sidemenus.administration.finalize-unfinalize.drpdown.select-talent'
          | translate
      "
      (valueChange)="selectedName = $event"
    >
      <mat-option
        class="fs-sm-txt fs-selectbox-options"
        *ngFor="let tag of tagList"
        [value]="tag"
      >
        {{ tag.value }}
      </mat-option>
    </mat-select>
  </div>

  <div>
    <mat-radio-group
      class="d-flex justify-content-around"
      [(ngModel)]="finalize"
    >
      <mat-radio-button class="fs-radio-button" value="finalize">
        <span class="cb-title fs-sm-txt">
          {{
            'left-menu.tab-bar.sidemenus.administration.finalize-unfinalize.labels.finalize'
              | translate
          }}
        </span>
      </mat-radio-button>

      <mat-radio-button class="fs-radio-button" value="unfinalize">
        <span class="cb-title fs-sm-txt">
          {{
            'left-menu.tab-bar.sidemenus.administration.finalize-unfinalize.labels.unfinalize'
              | translate
          }}
        </span>
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button
    class="fs-dialog-btn w-auto"
    (click)="execute()"
    [disabled]="!selectedName"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.finalize-unfinalize.button.execute'
        | translate
    }}
  </button>
</div>
