<div #cookieDivElement class="">
  <div class="p-3 pb-4 fs-txt-white-dark-color fs-text-color-white">
    <div class="row">
      <div class="col-10">
        <h2>Allow Cookies</h2>
      </div>
      <div class="col-2 text-center">
        <i
          class="fa fs-close-cookie-btn fs-cursor-pointer fa-times"
          (click)="onRejectCookiePolicy()"
        >
        </i>
      </div>
    </div>
    <p class="mt-0 fs-cookie-description-container mb-3">
      Do you like cookies? 🍪 We use cookies to ensure you get the best
      experience on our website. Learn more
    </p>
    <button
      class="btn fs-btn-color-danger w-100 fs-cookie-btn"
      (click)="onAcceptCookiePolicy()"
    >
      Accept
    </button>
  </div>
</div>
