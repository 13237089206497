import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettings } from '../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../shared/services/api.service';
import { WideSearchModel } from '../../../../../../shared/models/wide-search.model';

@Injectable()
export class WideEditAdvanceSearchService {
  wideEditAdvanceSearch: BehaviorSubject<WideSearchModel | {}> =
    new BehaviorSubject<WideSearchModel | {}>({});

  constructor(private apiService: ApiService) {}

  postAdvanceSearch(obj): Observable<any> {
    const path = AppSettings.WIDE_EDIT_ADVANCE_SEARCH;
    return this.apiService.post(path, obj).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getWideEditAdvanceSearchData() {
    return this.wideEditAdvanceSearch.asObservable();
  }

  setWideEditAdvanceSearchData(data) {
    this.wideEditAdvanceSearch.next(data);
  }

  resetWideEditAdvanceSearchData() {
    this.wideEditAdvanceSearch.next({});
  }
}
