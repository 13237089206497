<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">{{ 'view-project.bookmark.title' | translate }}</div>

  <button class="fs-close-btn w-auto" data-dismiss="modal" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <div class="row w-100 mb-2 text-center">
      <div class="col-sm-3">
        {{ 'view-project.bookmark.fileName' | translate }}
      </div>
      <div class="col-sm-3">
        {{ 'view-project.bookmark.filters' | translate }}
      </div>
      <div class="col-sm-3">
        {{ 'view-project.bookmark.description' | translate }}
      </div>
      <div class="col-sm-3">
        {{ 'view-project.bookmark.deleteModify' | translate }}
      </div>
    </div>
    <div
      class="row w-100 mb-2 align-items-center text-center"
      *ngFor="let currentData of arrListExistingBookmarkData"
    >
      <div class="col-sm-3">
        {{ currentData.assetName }}
      </div>
      <div class="col-sm-3">
        {{
          currentData.filter
            | filterNameDisplay : albumList : dataTaggingWith
            | uppercase
        }}
      </div>
      <div class="col-sm-4 d-flex align-items-center justify-content-start">
        <input
          type="text"
          disabled
          value="{{ currentData.description }}"
          class="form-control input-sm description-text"
        />
        <button
          type="button"
          (click)="onClickGoBtn(currentData)"
          class="btn btn-default go-btn"
        >
          {{ 'view-project.bookmark.go' | translate }}
        </button>
      </div>
      <div class="col-sm-2">
        <mat-checkbox
          [checked]="currentData.isSelected"
          class="fs-checkbox"
          (change)="onChangeDesCheckbox(currentData)"
        >
          <span class="cb-title"> </span>
        </mat-checkbox>
      </div>
    </div>
    <div class="row w-100 align-items-center text-center">
      <div class="col-sm-3">
        {{ imageName }}
      </div>
      <div class="col-sm-3">
        {{
          data.currentFilter
            | filterNameDisplay : albumList : dataTaggingWith
            | uppercase
        }}
      </div>
      <div class="col-sm-4 text-left">
        <input
          type="text"
          [(ngModel)]="bookMarkDescription"
          name="bookMarkDescription"
          (change)="onDescriptionChange()"
          class="form-control input-sm description-text"
        />
      </div>
      <div class="col-sm-2"></div>
    </div>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <div class="d-flex justify-content-end align-items-center">
    <button
      class="fs-dialog-btn w-auto ml-3"
      *ngIf="selectedBookmarkDataCount > 0"
      (click)="onClickDeleteBtn()"
    >
      {{ 'view-project.bookmark.dlete' | translate }} (
      {{ selectedBookmarkDataCount }} )
    </button>
    <button
      class="fs-dialog-btn w-auto"
      *ngIf="arrListExistingBookmarkData.length > 0"
      (click)="onClickSelectAll()"
    >
      {{ 'view-project.bookmark.selectAll' | translate }}
    </button>
    <button class="fs-dialog-btn w-auto ml-3" (click)="onClickAddBtn()">
      {{ 'view-project.bookmark.add' | translate }}
    </button>
    <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
      {{ 'image-history.buttons.close' | translate }}
    </button>
  </div>
</div>
