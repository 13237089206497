import { Utils } from '../../../../../shared/utils/utils';
import { Router } from '@angular/router';
import { LauncherService } from '../../../../launcher/launcher.service';
import { FilterEnum } from '../../../../../shared/enum/filter.enum';
import { SidebarService } from '../../../../project/sidebar-service/sidebar-service';
import { FavListModel } from '../interfaces/dashboard-favourite-rule.model';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardFavouriteProjectRule implements DashboardRuleModel {
  private readonly DEFAULT_PAGE_NUMBER = 1;

  private router = GetStaticInjector().get(Router);
  private launcherService = GetStaticInjector().get(LauncherService);
  private sidebarService = GetStaticInjector().get(SidebarService);

  constructor(private selectedProject: FavListModel) {}

  matches(): boolean {
    return !this.selectedProject.useSetup;
  }

  apply(): void {
    this.launcherService.updateProjectChangeState(true);
    this.router.navigate([
      Utils.prepareFilterNavigationURL(
        this.selectedProject.role,
        this.selectedProject.value,
        FilterEnum.ShowAll,
        this.DEFAULT_PAGE_NUMBER,
      ),
    ]);
    this.sidebarService.resetAlbumImageData();
  }
}
