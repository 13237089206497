import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettings } from '../../../../../appSettings/appSettings';
import { AssetInfo } from '../../../../../shared/models/assetInfo.model';
import { ApiService } from '../../../../../shared/services/api.service';

@Injectable()
export class SliderService {
  private projectData = new BehaviorSubject<AssetInfo>(new AssetInfo());
  projectDataGLobal = this.projectData.asObservable();

  private keyboardArrowState: BehaviorSubject<string> =
    new BehaviorSubject<string>('right');
  keyboardArrowStateGLobal = this.keyboardArrowState.asObservable();

  constructor(private apiService: ApiService) {}

  setProjectSliderData(data) {
    this.projectData.next(data);
  }

  resetProjectSliderData() {
    this.projectData.next(new AssetInfo());
  }

  getProjectSliderData() {
    return this.projectData.value;
  }

  setKeyboardArrowState(state) {
    this.keyboardArrowState.next(state);
  }
}
