import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeScript,
  SafeStyle,
  SafeUrl,
} from '@angular/platform-browser';

enum SaveValueType {
  HTML = 'html',
  STYLE = 'style',
  SCRIPT = 'script',
  URL = 'url',
  RESOURCE_URL = 'resourceUrl',
}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(
    value: SaveValueType,
    type: string,
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case SaveValueType.HTML:
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case SaveValueType.STYLE:
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case SaveValueType.SCRIPT:
        return this.sanitizer.bypassSecurityTrustScript(value);
      case SaveValueType.URL:
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case SaveValueType.RESOURCE_URL:
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}
