import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { AssetTransactionData } from './transmodel';
import { AppSettings } from '../../../../../appSettings/appSettings';
import { map } from 'rxjs/operators';
import { ApiService } from '../../../../../shared/services/api.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { MetadataTransactionDetails } from '../metadata/metadata.model';
import { IpService } from '../../../../../shared/services/ip-service.service';
import { UserService } from '../../../../../shared/services/user.service';

@Injectable()
export class ImageTransactionsService {
  private imageTransactionDetail: Subject<AssetTransactionData> = new Subject();

  constructor(
    private ipService: IpService,
    private userService: UserService,
    private apiService: ApiService,
    private viewProjectService: ViewProjectService,
  ) {}

  setImageTransactionDetail(transactionDetailData: AssetTransactionData): void {
    this.imageTransactionDetail.next(transactionDetailData);
  }

  getImageTransactionDetailData(): Observable<AssetTransactionData> {
    return this.imageTransactionDetail.asObservable();
  }

  receiveAssetTransactionData(
    assetId: number,
  ): Observable<AssetTransactionData[]> {
    const path = AppSettings.ASSET_TRANSACTION_DETAILS(assetId);
    return this.apiService.get(path, this.viewProjectService.getBaseUrl()).pipe(
      map((res: AssetTransactionData[]) => {
        return res;
      }),
    );
  }

  getTransactionDetails(): MetadataTransactionDetails {
    return {
      ip: this.ipService.userIPAddress$,
      browserInfo: this.ipService.browserInfo$,
      userName: this.userService.getFullUserName(),
      authorityName: this.userService.getCurrentUser().authorities[0],
      userId: this.userService.getCurrentUser().id,
    }
  }
}
