import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ViewGalleryPriorityPixStateService {
  private handledPrioritySession = new BehaviorSubject<boolean>(false);
  public handledPrioritySession$ = this.handledPrioritySession.asObservable();

  setHandledPrioritySession(flag) {
    this.handledPrioritySession.next(flag);
  }

  isHandledPrioritySession() {
    return this.handledPrioritySession.getValue();
  }
}
