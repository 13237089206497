import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { LauncherService } from '../../dashboard/launcher/launcher.service';
import { ViewProjectService } from './view-project.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class LogoService {
  constructor(
    private launcherService: LauncherService,
    private viewProjectService: ViewProjectService,
  ) {}

  getDepartmentLogo() {
    return combineLatest([
      this.launcherService.selectedGroupLogoGlobal,
      this.viewProjectService.projectDetailPermissionGLobal,
    ]).pipe(
      take(1),
      map(([selectedGroupLogo, permissionData]) =>
        environment.projectAssetBaseUrl + 'assets/images/branding/' + selectedGroupLogo || permissionData.groupLogo
      )
    );
  }
}
