import { Directive, HostListener, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';


@Directive({
  selector: '[fsSidebarAccordionDynamicHeight]'
})
export class SidebarAccordionDynamicHeightDirective implements OnInit {
  @Input('topSideBarContainer') topSideBarContainer: HTMLDivElement;
  @Input('sidenavElementRef') sidenavElementRef: MatSidenav;
  @Input('sidenavPadding') sidenavPadding: number;
  @Input('albumAccordionHeader') albumAccordionHeader: number;

  private resizeObserver: ResizeObserver;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.setDynamicHeight();

    const observer = new MutationObserver(() => {
      this.setDynamicHeight();
    });
    observer.observe(this.topSideBarContainer, { attributes: true, childList: true, subtree: true });

    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        this.setDynamicHeight();
      }
    });

    // @ts-ignore
    this.resizeObserver.observe(this.sidenavElementRef._elementRef.nativeElement);

  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setDynamicHeight();
  }

  setDynamicHeight() {
    if (this.topSideBarContainer) {
      const topMenuNavBarHeight = this.getTopMenuNavBarHeight();
      const topSideBarContainerHeight = this.topSideBarContainer.offsetHeight;

      // @ts-ignore
      const parentHeight = this.sidenavElementRef._elementRef.nativeElement.offsetHeight;
      const accordionContainerHeight = `calc(${parentHeight}px - ${topSideBarContainerHeight}px - ${this.sidenavPadding}px - ${this.albumAccordionHeader}px - ${topMenuNavBarHeight}px)`;
      this.renderer.setStyle(this.elRef.nativeElement, 'height', accordionContainerHeight);
    }
  }

  private getTopMenuNavBarHeight(): number {
    const defaultNavBarHeight = 38;

    const filterBarElement = document.getElementById('topMenuNavBar');
    if (filterBarElement) {
      return filterBarElement.offsetHeight - defaultNavBarHeight;
    }
    return 0;
  }
}
