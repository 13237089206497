import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ViewProjectService } from '../services/view-project.service';
import { takeUntil } from 'rxjs/operators';
import { UnsubscriberComponent } from '../components/unsubscriber/unsubscriber.component';
import {LoaderService} from '../services/loader.service';
import {ApiErrorHandlerService} from '../services/api-error-handler.service';

@Directive({
  selector: 'img[imageSrcWithAuthToken]',
})
export class ImageSrcWithAuthTokenDirective
  extends UnsubscriberComponent
  implements OnDestroy, OnInit
{
  @Input('imageSrcWithAuthToken') src: string;

  constructor(
    private elRef: ElementRef,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private viewProjectService: ViewProjectService,
  ) {
    super();
  }

  ngOnInit() {
    this.viewProjectService
      .getAssetLinkWithAuthToken(this.src)
      .pipe(takeUntil(this.destroy$))
      .subscribe((url: string) => {
        this.elRef.nativeElement.setAttribute('src', url);
        this.elRef.nativeElement.onload = () => {
          URL.revokeObjectURL(url);
        };
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
