import { UntypedFormArray } from '@angular/forms';

export const LinkSettingsData: any = {
  to: [],
  subject: '',
  message: '',
  passwordEmails: [],
  linkExpires: '',
  numberOfViews: {
    isCheckedValue: 'unlimited',
    value: '',
  },
  addPassphrase: {
    name: 'Add Passphrase',
    isChecked: false,
    value: '',
  },
  allowDownload: {
    name: 'Allow Download',
    isChecked: false,
    value: false,
  },
  allowExpressDownload: {
    name: 'Allow Express Download',
    isChecked: false,
    value: false,
  },
  downloadTypeValue: new UntypedFormArray([]),
  expressDownloadTypeValue: new UntypedFormArray([]),
  rememberSetting: false,
};
