import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  ViewContainerRef,
  NgZone,
  Inject,
  Optional,
  Renderer2,
} from '@angular/core';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MAT_TOOLTIP_SCROLL_STRATEGY,
  MatTooltip,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';
import { Overlay, ScrollDispatcher } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[fsTextOverflowEllipsis]',
})
export class TextOverflowEllipsisDirective
  extends MatTooltip
  implements OnInit
{
  @Input() fsTextOverflowEllipsis: string;

  constructor(
    private renderer: Renderer2,
    private overlay: Overlay,
    private elementRef: ElementRef,
    private scrollDispatcher: ScrollDispatcher,
    private viewContainerRef: ViewContainerRef,
    private ngZone: NgZone,
    private platform: Platform,
    private ariaDescriber: AriaDescriber,
    private focusMonitor: FocusMonitor,
    @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) private scrollStrategy: any,
    @Optional() private dir: Directionality,
    @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS)
    private defaultOptions: MatTooltipDefaultOptions,
    @Inject(DOCUMENT) private document,
  ) {
    super(
      overlay,
      elementRef,
      scrollDispatcher,
      viewContainerRef,
      ngZone,
      platform,
      ariaDescriber,
      focusMonitor,
      scrollStrategy,
      dir,
      defaultOptions,
      document,
    );
    this.initElementClasses();
  }

  ngOnInit() {
    this.message = this.getMessage();
    this.disabled = !this.isTextWithEllipsis(this.elementRef.nativeElement);
  }

  getMessage() {
    return (
      this.fsTextOverflowEllipsis ||
      this.elementRef.nativeElement.textContent?.trim() ||
      ''
    );
  }

  private initElementClasses() {
    this.renderer.addClass(this.elementRef.nativeElement, 'text-truncate');
  }

  // make sure element has text-overflow: ellipsis style
  private isTextWithEllipsis(element: HTMLElement) {
    const styles = getComputedStyle(element);
    const widthElement = parseFloat(styles.width);
    const ctx = this.renderer.createElement('canvas').getContext('2d');
    ctx.font = `${styles.fontSize} ${styles.fontFamily}`;
    const text = ctx.measureText(element.innerText);
    return text.width > widthElement;
  }
}
