<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">{{ 'top-menu.printTool.title' | translate }}</div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content class="modal-print-tool">
  <div class="send-email-body">
    <form class="theme-form container">
      <div class="row">
        <div class="col-6 print-left">
          <div class="form-group row">
            <label class="font-weight-bold col-4 col-form-label">{{
              'top-menu.printTool.labels.image' | translate
            }}</label>
            <div class="col-8 pl-0">
              <mat-select
                class="fs-selectbox-dark form-control flex-fill"
                [value]="selectedValue['imageSize']"
                (valueChange)="onChangeSelect('imageSize', $event)"
              >
                <mat-option
                  class="fs-sm-txt fs-selectbox-options"
                  *ngFor="let size of data.imageSize"
                  [value]="size.value"
                >
                  {{ size.label }}
                </mat-option>
              </mat-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="font-weight-bold col-4 col-form-label">{{
              'top-menu.printTool.labels.layout' | translate
            }}</label>
            <div class="col-8 pl-0">
              <mat-select
                class="fs-selectbox-dark form-control flex-fill"
                [value]="selectedValue['layout']"
                (valueChange)="onChangeSelect('layout', $event)"
              >
                <mat-option
                  class="fs-sm-txt fs-selectbox-options"
                  *ngFor="let layout of data.layout"
                  [value]="layout.value"
                >
                  {{ layout.label }}
                </mat-option>
              </mat-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="font-weight-bold col-4 col-form-label">{{
              'top-menu.printTool.labels.pageSize' | translate
            }}</label>
            <div class="col-8 pl-0">
              <mat-select
                class="fs-selectbox-dark form-control flex-fill"
                [(value)]="selectedValue['pageSize']"
                [compareWith]="selectPageSizeValueComparator"
                (valueChange)="onChangeSelect('pageSize', $event)"
              >
                <mat-option
                  class="fs-sm-txt fs-selectbox-options"
                  *ngFor="let pageSize of data.pageSize"
                  [value]="pageSize.value"
                >
                  {{ pageSize.label }}
                </mat-option>
              </mat-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="font-weight-bold col-4 col-form-label">{{
              'top-menu.printTool.labels.gutter' | translate
            }}</label>
            <div class="col-8 pl-0">
              <mat-select
                class="fs-selectbox-dark form-control flex-fill"
                [(value)]="selectedValue['gutter']"
                (valueChange)="onChangeSelect('gutter', $event)"
              >
                <mat-option
                  class="fs-sm-txt fs-selectbox-options"
                  *ngFor="let gutter of data.gutter"
                  [value]="gutter.value"
                >
                  {{ gutter.label }}
                </mat-option>
              </mat-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="font-weight-bold col-4 col-form-label">{{
              'top-menu.printTool.labels.imageCount' | translate
            }}</label>
            <div class="fs-txt-white-dark-color col-8 num-text flex-fill">
              {{ selectedValue.imageCount }}
            </div>
          </div>

          <div class="form-group row">
            <label class="font-weight-bold col-4 col-form-label">{{
              'top-menu.printTool.labels.perPage' | translate
            }}</label>
            <div class="fs-txt-white-dark-color col-8 num-text flex-fill">
              {{ selectedValue.layout }}
            </div>
          </div>

          <div class="form-group row">
            <label class="font-weight-bold col-4 col-form-label">{{
              'top-menu.printTool.labels.totalPages' | translate
            }}</label>
            <div class="fs-txt-white-dark-color col-8 num-text flex-fill">
              {{ selectedValue.totalPages }}
            </div>
          </div>

          <div class="form-group row">
            <label class="font-weight-bold col-4 col-form-label">{{
              'top-menu.printTool.labels.title' | translate
            }}</label>
            <div class="col-8 pl-0">
              <input
                class="form-control flex-fill"
                type="text"
                name="title"
                placeholder="Title"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="selectedValue.title"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="font-weight-bold col-4 col-form-label">
              &copy;
            </label>
            <div class="col-2">
              <mat-checkbox
                class="fs-checkbox"
                name="PLAM"
                [(ngModel)]="selectedValue.copyRight.status"
                [checked]="selectedValue.copyRight.status"
              >
                <span class="cb-title"></span>
              </mat-checkbox>
            </div>
            <div class="col-6 pl-0">
              <input
                class="manage-size-input form-control"
                type="text"
                placeholder="Copyright Info"
                name="copyRight"
                [disabled]="!selectedValue.copyRight.status"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="selectedValue.copyRight.value"
                [value]="selectedValue.copyRight.value"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="font-weight-bold col-4 col-form-label">{{
              'top-menu.printTool.labels.photoCredit' | translate
            }}</label>
            <div class="col-2">
              <mat-checkbox
                class="fs-checkbox"
                name="photoCredit"
                [(ngModel)]="selectedValue.photoCredit.status"
                [checked]="selectedValue.photoCredit.status"
              >
                <span class="cb-title"></span>
              </mat-checkbox>
            </div>
            <div class="col-6 pl-0">
              <input
                type="text"
                name="photoCreditValue"
                class="manage-size-input form-control"
                [placeholder]="
                'top-menu.printTool.labels.photographer' | translate
              "
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="selectedValue.photoCredit.value"
                [value]="selectedValue.photoCredit.value"
                [disabled]="!selectedValue.photoCredit.status"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="font-weight-bold col-4 col-form-label">{{
              'top-menu.printTool.labels.date' | translate
            }}</label>
            <div class="col-2">
              <mat-checkbox
                class="fs-checkbox"
                name="isDate"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="selectedValue.date.status"
                [checked]="selectedValue.date.status"
              >
                <span class="cb-title"></span>
              </mat-checkbox>
            </div>

            <div
              class="col-6 pl-0"
              [class.disabled]="!selectedValue.date.status"
            >
              <mat-form-field class="fs-sm-txt fs-datepicker-default form-control">
                <input
                  matInput
                  [placeholder]="'left-menu.tab-bar.sidemenus.administration.talent-management.placeholder.selectdate' | translate"
                  inputRestriction="onlyInteger"
                  (dateInput)="selectedValue.date.value = $event.value"
                  [disabled]="!selectedValue.date.status"
                  [matDatepicker]="picker"
                  [value]="selectedValue.date.value"
                />
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker [startAt]="min"></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="form-group row">
            <label class="font-weight-bold col-4 col-form-label">{{
              'top-menu.printTool.labels.water' | translate
            }}</label>
            <div class="col-2">
              <mat-checkbox
                class="fs-checkbox"
                type="checkbox"
                name="waterMarkCheckBox"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="selectedValue.watermark.status"
                [checked]="selectedValue.watermark.status"
              >
                <span class="cb-title"></span>
              </mat-checkbox>
            </div>
            <div class="col-6 pl-0">
              <input
                type="text"
                class="manage-size-input form-control"
                name="waterMark"
                [(ngModel)]="selectedValue.watermark.value"
                [value]="selectedValue.watermark.value"
                [disabled]="!selectedValue.watermark.status"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="font-weight-bold col-4 col-form-label">{{
              'top-menu.printTool.labels.opacity' | translate
            }}</label>
            <div class="col-8 pl-0">
              <mat-select
                class="fs-selectbox-dark form-control flex-fill"
                name="opacity"
                [(ngModel)]="selectedValue.opacity"
                (valueChange)="onChangeSelect('opacity', $event)"
              >
                <mat-option
                  class="fs-sm-txt fs-selectbox-options"
                  *ngFor="let opacity of data.opacity"
                  [value]="opacity.value"
                >
                  {{ opacity.label }}
                </mat-option>
              </mat-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="font-weight-bold col-4 col-form-label">{{
              'top-menu.printTool.labels.auto' | translate
            }}</label>
            <div class="col-8">
              <mat-checkbox
                class="fs-checkbox"
                name="auto"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="selectedValue.autoSize"
                [checked]="selectedValue.autoSize"
              >
                <span class="cb-title"></span>
              </mat-checkbox>
            </div>
          </div>

          <div class="form-group row">
            <label class="font-weight-bold col-4 col-form-label">{{
              'top-menu.printTool.labels.angle' | translate
            }}</label>
            <div class="col-8 pl-0">
              <mat-select
                class="fs-selectbox-dark form-control flex-fill"
                name="angle"
                [value]="selectedValue['angle']"
                (valueChange)="onChangeSelect('angle', $event)"
              >
                <mat-option
                  class="fs-sm-txt fs-selectbox-options"
                  *ngFor="let angle of data.angle"
                  [value]="angle.value"
                >
                  {{ angle.label }}
                </mat-option>
              </mat-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="font-weight-bold col-4 col-form-label">{{
              'top-menu.printTool.labels.size' | translate
            }}</label>
            <div class="col-8 pl-0">
              <input
                class="form-control"
                type="text"
                name="size"
                [disabled]="selectedValue.autoSize"
                [(ngModel)]="selectedValue.size"
                [value]="selectedValue.size"
              />
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="fs-background-color-white p-2 mb-1">
            <div class="page-header d-flex justify-content-between">
              <div class="fs-text-color-l-blk">
                <span *ngIf="selectedValue.title"
                  ><b>{{ selectedValue.title }}</b></span
                >
                <span *ngIf="selectedValue.copyRight.status"
                  >&copy; {{ selectedValue.copyRight.value }}</span
                >
              </div>

              <div>
                <span *ngIf="selectedValue.photoCredit.status">
                  Photo Credit: {{ selectedValue.photoCredit.value }}</span
                >
              </div>
            </div>
            <div>
              <img [src]="imageUrl" />
            </div>
            <div class="page-footer">
              <div class="d-flex fs-text-color-l-blk justify-content-between">
                <span *ngIf="selectedValue.date.status">
                  {{ selectedValue.date.value | date : 'shortDate' }}</span
                >
                <span
                  >{{ 'top-menu.printTool.labels.page' | translate }} #</span
                >
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="isEmailAvailable" class="col-12">
          <div class="form-group row">
            <label class="col-2 font-weight-bold col-form-label">{{
              'top-menu.printTool.labels.emailTo' | translate
            }}</label>
            <div class="col-10 fs-chips fs-printtool-email-chips">
              <mat-form-field
                floatLabel="never"
                class="fs-chip-list"
                appearance="fill"
              >
                <mat-chip-grid
                  class="w-100"
                  #toEmail
                  [(ngModel)]="selectedValue.email"
                  name="emailTo"
                >
                  <mat-chip-row
                    *ngFor="let email of arrListEmailTo; let i = index"
                    [removable]="true"
                    (removed)="onRemoveEmailId('to', i)"
                  >
                    {{ email }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip-row>

                  <input
                    matInput
                    [placeholder]="'top-menu.sendMail.to' | translate"
                    [matChipInputFor]="toEmail"
                    [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="onAdValidateEmail($event, 'to')"
                  />
                </mat-chip-grid>
              </mat-form-field>
              <span [hidden]="!isToEmailIdValid" class="fs-text-color-red">
                <span *ngIf="isToemailFocus">
                  {{ 'top-menu.sendMail.invalid' | translate }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <div class="mf-sngle d-flex justify-content-end">
    <button
      *ngIf="isEmailAvailable"
      class="fs-dialog-btn w-auto ml-3"
      [disabled]="arrListEmailTo.length === 0"
      (click)="submitPrintTool('email')"
    >
      {{ 'top-menu.printTool.buttons.email' | translate }}
    </button>

    <button
      class="fs-dialog-btn w-auto ml-3"
      (click)="submitPrintTool(constantsGlobalData.MODELNAMES.DOWNLOAD)"
    >
      {{ 'top-menu.printTool.buttons.download' | translate }}
    </button>
  </div>
</div>
