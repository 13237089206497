<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.delivery.title' | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content [formGroup]="configurationGroup">
  <div class="mx-0 px-0">
    <div class="row">
      <div class="col settings-container">
        <div class="font-weight-bold mb-2">
          {{
            'left-menu.tab-bar.advance-search.labels.filter-option' | translate
          }}
        </div>
        <div>
          <div
            class="check-height"
            *ngFor="let filterOpetion of advanceSearchObj.filterOpetion"
          >
            <mat-checkbox
              name="filterOpetion"
              class="fs-checkbox fs-checkbox-margin"
              [checked]="filterOpetion.isChecked"
              (change)="filterOpetion.isChecked = !filterOpetion.isChecked"
            >
              <span class="cb-title">{{
                filterOpetion.trans | translate
              }}</span>
            </mat-checkbox>
          </div>
        </div>

        <div class="font-weight-bold mb-2">
          {{
            'left-menu.tab-bar.advance-search.labels.talent-exec-attributes'
              | translate
          }}
        </div>
        <div>
          <div
            *ngFor="
              let talentExecAttributes of advanceSearchObj.talentExecAttributes
            "
          >
            <mat-checkbox
              name="talentExecAttributes"
              class="fs-checkbox fs-checkbox-margin"
              [disabled]="
                talentExecAttributes.name === 'Not' &&
                !advanceSearchObj.talentExecAttributes[0].isChecked &&
                !advanceSearchObj.talentExecAttributes[1].isChecked &&
                !advanceSearchObj.talentExecAttributes[2].isChecked
              "
              [checked]="talentExecAttributes.isChecked"
              (change)="onChangeTalentExecAttributes(talentExecAttributes)"
            >
              <span class="cb-title">{{
                talentExecAttributes.trans | translate
              }}</span>
            </mat-checkbox>
          </div>
        </div>

        <div class="font-weight-bold mb-2">
          {{
            'left-menu.tab-bar.advance-search.labels.apppr-status' | translate
          }}
        </div>
        <div>
          <div *ngFor="let approvalStatus of advanceSearchObj.approvalStatus">
            <mat-checkbox
              name="approvalStatus"
              class="fs-checkbox fs-checkbox-margin"
              [checked]="approvalStatus.isChecked"
              (change)="approvalStatus.isChecked = !approvalStatus.isChecked"
            >
              <span class="cb-title">{{
                approvalStatus.trans | translate
              }}</span>
            </mat-checkbox>
          </div>
        </div>
      </div>

      <div class="col settings-container">
        <div class="font-weight-bold mb-2">
          {{
            'left-menu.tab-bar.advance-search.labels.talent-appearance'
              | translate
          }}
        </div>
        <div>
          <div
            *ngFor="let talentAppearance of advanceSearchObj.talentAppearance"
          >
            <mat-checkbox
              name="talentAppearance"
              class="fs-checkbox fs-checkbox-margin"
              [checked]="talentAppearance.isChecked"
              (change)="
                talentAppearance.isChecked = !talentAppearance.isChecked
              "
            >
              <span class="cb-title">{{
                talentAppearance.trans | translate
              }}</span>
            </mat-checkbox>
          </div>
        </div>

        <div class="font-weight-bold mt-2 mb-2">
          {{
            'left-menu.tab-bar.advance-search.labels.image-batch' | translate
          }}
        </div>
        <div class="image_batch">
          <div *ngFor="let imageBatch of advanceSearchObj.imageBatch">
            <mat-checkbox
              name="imageBatch"
              class="fs-checkbox fs-checkbox-margin"
              [checked]="imageBatch.isChecked"
              (change)="onImageBatchChange(imageBatch)"
            >
              <span class="cb-title">{{ imageBatch.trans | translate }}</span>
            </mat-checkbox>
            <div class="select_parent" *ngIf="imageBatch.name === 'Image'">
              <input
                type="text"
                class="form-control input-box imageText"
                name="batchText"
                [disabled]="!advanceSearchObj.imageBatch[0].isChecked"
              />
            </div>
            <div *ngIf="imageBatch.name === 'Batch'">
              <mat-select
                class="form-control input-box fs-selectbox-dark"
                formControlName="batch"
                [disabled]="!advanceSearchObj.imageBatch[1].isChecked"
                (valueChange)="filterForImageBatch($event)"
              >
                <mat-option
                  class="fs-sm-txt fs-selectbox-options"
                  *ngFor="let batch of batchList"
                  [value]="batch.value"
                >
                  {{ batch.label }}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>

        <div class="font-weight-bold mt-2">
          {{ 'left-menu.tab-bar.advance-search.labels.other' | translate }}
        </div>
        <div class="image_batch-last">
          <div *ngFor="let other of advanceSearchObj.other">
            <mat-checkbox
              name="other"
              [checked]="other.isChecked"
              class="fs-checkbox fs-checkbox-margin"
              (change)="other.isChecked = !other.isChecked"
            >
              <span class="cb-title">{{ other.trans | translate }}</span>
            </mat-checkbox>
          </div>

          <div *ngFor="let exclusives of advanceSearchObj.exclusives">
            <div
              [ngClass]="{
                'exclusives-dropdown-sibling':
                  exclusives.name === 'Select Exclusive'
              }"
              class="select_sib"
            >
              <mat-checkbox
                name="exclusives"
                class="fs-checkbox fs-checkbox-margin"
                [checked]="exclusives.isChecked"
                (change)="onChangeExclusives(exclusives)"
              >
                <span class="cb-title">
                  {{ exclusives.trans | translate }}</span
                >
              </mat-checkbox>
            </div>

            <div
              *ngIf="exclusives.name === 'Select Exclusive'"
              class="select_parent form-group"
            >
              <mat-select
                class="form-control input-box fs-selectbox-dark"
                formControlName="exclusive"
                [disabled]="!advanceSearchObj.exclusives[1].isChecked"
                (valueChange)="selectedExclusive = $event"
              >
                <mat-option
                  class="fs-sm-txt fs-selectbox-options"
                  *ngFor="let exclusive of exclusiveList"
                  [value]="exclusive.value"
                >
                  {{ exclusive.label }}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </div>

      <div class="col settings-container">
        <div class="font-weight-bold mb-2">
          {{ 'left-menu.tab-bar.advance-search.tags.title' | translate }}
        </div>
        <div class="album-tag-scroll">
          <div
            class="m-0 d-flex justify-content-between"
            *ngFor="let tags of tagList"
          >
            <mat-checkbox
              name="general"
              class="fs-checkbox fs-checkbox-margin"
              [checked]="
                advanceSearchObj.generalTagIds.indexOf(tags.id) !== -1 ||
                advanceSearchObj.publishedTagIds.indexOf(tags.id) !== -1
              "
              (change)="onChangedTag(tags)"
            >
              <span class="cb-title">{{ tags.tagName }}</span>
            </mat-checkbox>
            <span class="cb-right"> {{ tags.imageCount }}</span>
          </div>
        </div>
      </div>

      <div class="col settings-container" *ngIf="projectAlbumList.length > 0">
        <div class="font-weight-bold mb-2">
          {{
            'left-menu.tab-bar.advance-search.albums.projectAlbum' | translate
          }}
        </div>
        <div class="album-tag-scroll">
          <div
            class="m-0 d-flex justify-content-between"
            *ngFor="let album of projectAlbumList | sortBy : 'value'"
          >
            <mat-checkbox
              name="album"
              [checked]="advanceSearchObj.projectAlbum.indexOf(album.id) !== -1"
              class="fs-checkbox fs-checkbox-margin"
              (change)="onChangedProjectAlbum(album)"
            >
              <span class="cb-title">{{ album.value | slice : 0 : 25 }}</span>
            </mat-checkbox>
            <span class="cb-right">{{ album.assetCount }}</span>
          </div>
        </div>
      </div>

      <div class="col settings-container" *ngIf="customAlbumList.length > 0">
        <div class="font-weight-bold mb-2">
          {{ 'left-menu.tab-bar.advance-search.albums.title' | translate }}
        </div>
        <div class="album-tag-scroll">
          <div
            class="m-0 d-flex justify-content-between"
            *ngFor="let album of customAlbumList | sortBy : 'value'"
          >
            <mat-checkbox
              name="album"
              class="fs-checkbox fs-checkbox-margin"
              [checked]="advanceSearchObj.albums.indexOf(album.id) !== -1"
              (change)="onChangedAlbum(album)"
            >
              <span class="cb-title">{{ album.value | slice : 0 : 25 }}</span>
            </mat-checkbox>
            <span class="cb-right">{{ album.assetCount }}</span>
          </div>
        </div>
      </div>

      <div class="col settings-container">
        <div class="font-weight-bold mb-2">
          {{
            'left-menu.tab-bar.sidemenus.administration.delivery.title'
              | translate
          }}
        </div>
        <div class="album-tag-scroll">
          <div class="deliveryoptionul">
            <div *ngFor="let option of deliveryOption.imageSize">
              <mat-checkbox
                name="album"
                class="fs-checkbox fs-checkbox-margin"
                [checked]="option.isChecked"
                (change)="
                  option.isChecked = !option.isChecked;
                  onChangeImageSize(option.isChecked)
                "
              >
                <span class="cb-title">{{ option.lable }}</span>
              </mat-checkbox>
            </div>
          </div>

          <div class="font-weight-bold mb-2 deliveryoptionhd">
            Select File Size
          </div>
          <div class="deliveryoptionul">
            <div *ngFor="let option of deliveryOption.fileSize">
              <mat-checkbox
                [disabled]="!deliveryOption.imageSize[0].isChecked"
                name="album"
                class="fs-checkbox fs-checkbox-margin"
                [checked]="option.isChecked"
                (change)="
                  option.isChecked = !option.isChecked; onChangeFIleSize()
                "
              >
                <span class="cb-title">{{ option.lable }}</span>
              </mat-checkbox>
            </div>
          </div>

          <div class="font-weight-bold mb-2 deliveryoptionhd">
            Select Delivery Server
          </div>
          <div class="deliveryoptionul">
            <mat-radio-group
              class="d-flex flex-column"
              [value]="selectedDeliveryServer"
              (change)="selectedDeliveryServer = $event.value"
            >
              <mat-radio-button
                class="fs-radio-button"
                *ngFor="let option of deliveryOption.delieryServer"
                [value]="option.id"
              >
                <span class="cb-title fs-sm-txt">{{ option.lable }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <div>
            <mat-select
              class="form-control input-box mlr-3 fs-selectbox-dark"
              [value]="selectedRemoteServer"
              [disabled]="selectedDeliveryServer != 3"
              (valueChange)="selectedRemoteServer = $event"
            >
              <mat-option
                class="fs-sm-txt fs-selectbox-options"
                *ngFor="let option of deliveryOption.remoteServers"
                [value]="option"
              >
                {{ option.lable }}
              </mat-option>
            </mat-select>
          </div>

          <div class="font-weight-bold mb-2 deliveryoptionhd">
            Other Options
          </div>
          <div class="deliveryoptionul">
            <div *ngFor="let option of deliveryOption.otherOptions">
              <mat-checkbox
                name="album"
                class="fs-checkbox fs-checkbox-margin"
                [checked]="option.isChecked"
                (change)="option.isChecked = !option.isChecked"
              >
                <span class="cb-title">{{ option.lable }}</span>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <div class="container">
    <div class="row d-flex justify-content-between">
      <div class="w-auto">
        <mat-radio-group class="d-flex mr-3">
          <mat-radio-button
            class="mr-3 fs-radio-button"
            *ngFor="let statusAsets of adFilterOption.statusAssets"
            [value]="statusAsets.id"
            [checked]="advanceSearchObj.status === statusAsets.id"
            (change)="onExcludedStatusChange(statusAsets.name)"
          >
            <span class="fs-sm-txt cb-title">
              {{
                'left-menu.tab-bar.advance-search.statusAsets' +
                  '.' +
                  statusAsets.key | translate
              }}
            </span>
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="w-auto">
        <mat-radio-group class="d-flex mr-3">
          <mat-radio-button
            class="mr-3 fs-radio-button fs-sm-txt"
            *ngFor="let excludedAsets of adFilterOption.excludedAssets"
            [value]="excludedAsets.id"
            [checked]="
              advanceSearchObj.excludedReleasedStatus === excludedAsets.id
            "
            (change)="onExcludedStatusChange(excludedAsets.name)"
          >
            <span class="cb-title fs-sm-txt">
              {{
                'left-menu.tab-bar.advance-search.excludedAsets' +
                  '.' +
                  excludedAsets.key | translate
              }}
            </span>
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="w-auto">
        <div class="d-flex">
          <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
            {{ 'left-menu.tab-bar.advance-search.buttons.cancel' | translate }}
          </button>

          <button class="fs-dialog-btn w-auto ml-3" (click)="onClickedReset()">
            {{ 'left-menu.tab-bar.advance-search.buttons.reset' | translate }}
          </button>

          <button
            class="fs-dialog-btn w-auto ml-3"
            (click)="onClikedSubmit()"
            [disabled]="
              totalSelectedFileSize.length == 0 &&
              !deliveryOption.otherOptions[1].isChecked &&
              !deliveryOption.otherOptions[0].isChecked &&
              !deliveryOption.imageSize[0].isChecked
            "
          >
            {{ 'left-menu.tab-bar.advance-search.buttons.submit' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
