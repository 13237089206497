import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  delay,
  map,
  switchMap,
  takeUntil,
  timeout,
} from 'rxjs/operators';
import { AppSettings } from '../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../shared/services/api.service';
import { environment } from '../../../../../../environments/environment';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { DownloadComponent } from './download.component';
import { tap } from 'rxjs/internal/operators/tap';
import { Utils } from '../../../../../shared/utils/utils';
import { ProjectDetailPermission } from '../../../../../shared/models/projectpermission.model';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { SelectedImagesStore } from '../../../../../shared/store/selected-images-store';
import { LastSelectedImageStore } from '../../../../../shared/store/last-selected-image-store';
import { TopmenusService } from '../top-menu.service';
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';
import { UserService } from '../../../../../shared/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from '../../../../../app.configuration';
import { UserRole } from '../../../../../shared/enum/user-role.enum';
import { ProjectRole } from '../../../../../shared/enum/project-role.enum';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import { AssetInfoDTOList } from '../../../../../shared/models/assetInfo.model';
import { ExclusiveSensitiveAssetDialogComponent } from '../../../../../shared/components/exclusive-sensitive-asset-dialog/exclusive-sensitive-asset-dialog.component';

@Injectable()
export class DownloadService
  extends UnsubscriberComponent
  implements OnDestroy
{
  apiUrl: string;
  selectAllImagesCount = 0;
  SELECTION_LIMIT = 250;
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private translate: TranslateService,
    private userService: UserService,
    private dialogService: DialogService,
    private topmenusService: TopmenusService,
    private lastSelectedImageStore: LastSelectedImageStore,
    private selectedImagesStore: SelectedImagesStore,
    private loaderService: LoaderService,
    private viewProjectService: ViewProjectService,
  ) {
    super();
    this.apiUrl = environment.apiUrl;
  }

  // need to add big timeout (30 mins) to fix issue with zip downloads of big files
  downloadZipFile(obj: any): Observable<any> {
    const path = AppSettings.ZIP_FILE_DOWNLOAD;
    return this.apiService
      .postAssets(this.viewProjectService.getBaseUrl(), path, obj)
      .pipe(
        delay(2000), // need a delay to fix download issue for zip files with small assets
        timeout(1800000),
        map((data) => {
          return data;
        }),
      );
  }

  downloadExpressZipFile(obj: any): Observable<any> {
    const path = AppSettings.EXPRESS_ZIP_FILE_DOWNLOAD;
    return this.apiService
      .postAssets(this.viewProjectService.getBaseUrl(), path, obj)
      .pipe(
        map((data) => {
          return data;
        }),
      );
  }

  expressDownloadFiles<T>(
    obj: T,
  ): Observable<{ packageId: string; tokenUrl: string }> {
    const path = AppSettings.MEDIA_SHUTTLE_DELIVERY;
    return this.apiService
      .postAssets(this.viewProjectService.getBaseUrl(), path, obj)
      .pipe(map((data: { packageId: string; tokenUrl: string }) => data));
  }

  checkSentiveImage() {
    return this.selectedImagesStore
      .getAll()
      .some((imageObj) => imageObj.sensitive);
  }

  checkDownloadStatus(downloadStatus: number): boolean {
    if (downloadStatus === 1) {
      return true;
    } else if (downloadStatus === 5) {
      return this.selectedImagesStore
        .getAll()
        .every((item) => item.status === 5);
    }
    return false;
  }

  checkExclusiveStatus(exclusiveStatus: number): boolean {
    return exclusiveStatus === 0
      ? false
      : exclusiveStatus === 1 || exclusiveStatus === 2;
  }

  downloadAssets(
    isEnlargeView: boolean,
    projectDetailPermission: ProjectDetailPermission,
    userAuthority,
    projectAuthority,
  ) {
    if (this.selectedImagesStore.hasEntities()) {
      if (
        this.selectAllImagesCount > this.SELECTION_LIMIT &&
        !AppConfig.SETTINGS.EXPRESS_DOWNLOAD
      ) {
        this.alertCustom(this.translate.instant('view-project.alert41'));
        return of('');
      } else {
        return this.handleAssetDownloads().pipe(
          tap(
            ([sensitiveAssets, exclusiveAssets]: [
              AssetInfoDTOList[],
              AssetInfoDTOList[],
            ]) => {
              if (!sensitiveAssets.length && !exclusiveAssets.length) {
                if (
                  userAuthority === UserRole.ROLE_EXTERNAL &&
                  projectAuthority === ProjectRole.ROLE_EXEC
                ) {
                  if (
                    this.checkDownloadStatus(
                      projectDetailPermission.permissionDTO.downloadType,
                    )
                  ) {
                    if (
                      this.checkExclusiveStatus(
                        projectDetailPermission.permissionDTO.exclusiveStatus,
                      )
                    ) {
                      return this.callDownloadAPI(
                        isEnlargeView,
                        projectDetailPermission,
                      );
                    } else {
                      this.alertCustom(
                        "You do not have permission to download image'(s).",
                      );
                      return of('');
                    }
                  } else {
                    this.alertCustom(
                      "You do not have permission to download image'(s).",
                    );
                    return of('');
                  }
                } else {
                  return this.callDownloadAPI(
                    isEnlargeView,
                    projectDetailPermission,
                  );
                }
              } else if (
                this.isAllSelectedAssetsExclusiveOrSensitive(
                  sensitiveAssets,
                  exclusiveAssets,
                )
              ) {
                this.loaderService.displayLoader(false);
                this.alertCustom(
                  this.translate.instant(
                    'top-menu.expressDownload.error.allAssetAreExclusiveOrSensitive',
                  ),
                );
              } else {
                this.loaderService.displayLoader(false);
                return this.openExclusiveSensitiveAssetModal(
                  isEnlargeView,
                  projectDetailPermission,
                  Utils.mergeAndRemoveDuplicatesByKey<AssetInfoDTOList>(
                    [...sensitiveAssets, ...exclusiveAssets],
                    'id',
                  ),
                  this.callDownloadAPI,
                );
              }
            },
          ),
        );
      }
    } else {
      this.alertCustom(this.translate.instant('view-project.alert13'));
      return of('');
    }
  }

  private isAllSelectedAssetsExclusiveOrSensitive(
    sensitiveAssets,
    exclusiveAssets,
  ): boolean {
    return (
      Utils.mergeAndRemoveDuplicatesByKey<AssetInfoDTOList>(
        [...sensitiveAssets, ...exclusiveAssets],
        'id',
      ).length === this.selectedImagesStore.getItemsIds().length
    );
  }

  openExclusiveSensitiveAssetModal(
    isEnlargeView: boolean,
    projectDetailPermission: ProjectDetailPermission,
    extractAssets: AssetInfoDTOList[],
    downloadFn,
  ) {
    const downloadFnWithArgs = downloadFn.bind(
      this,
      isEnlargeView,
      projectDetailPermission,
    );

    return this.dialogService.open(ExclusiveSensitiveAssetDialogComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
      ],
      listener: {
        withListener: true,
        id: 'ExclusiveSensitiveAssetDialogComponent',
      },
      data: {
        openDownloadDialog: downloadFnWithArgs,
        extractAssets: extractAssets,
        isFromTopMenu: true,
      },
    });
  }

  callDownloadAPI = (
    isEnlargeView: boolean,
    projectDetailPermission: ProjectDetailPermission,
  ) => {
    this.loaderService.displayLoader(true);
    const dataObj = {
      assetIds: isEnlargeView
        ? [this.lastSelectedImageStore.getHeadItem().id]
        : this.selectedImagesStore.getItemsIds(),
      filter: 'download',
      projectId: this.viewProjectService.getProjectId(),
    };
    return this.topmenusService
      .postAssetsInfodata(dataObj)
      .pipe(
        switchMap((res) => {
          if (res.status) {
            const obj = Utils.getAssetDetaillObj(
              res.message,
              res.t,
              this.viewProjectService.getProjectId(),
              projectDetailPermission,
              this.viewProjectService.getCurrentUsername(),
            );
            return this.topmenusService.postAssetsDetail(obj).pipe(
              tap(
                (resData) => {
                  if (
                    Object.keys(resData.assetDetail.Image).length === 0 &&
                    Object.keys(resData.assetDetail.Other).length === 0
                  ) {
                    this.alertCustom('No image size is available for download');
                  } else {
                    this.openDownloadComponent(resData);
                  }

                  this.loaderService.displayLoader(false);
                },
                () => {
                  this.loaderService.displayLoader(false);
                },
              ),
            );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(res.message);
          }
        }),
        catchError(() => {
          this.loaderService.displayLoader(false);
          return throwError('');
        }),
      )
      .subscribe();
  };

  openDownloadComponent(resData): void {
    this.dialogService.open(DownloadComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
      ],
      listener: {
        withListener: true,
        id: 'DownloadComponent',
      },
      data: resData,
    });
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  getExclusiveImages(): Observable<AssetInfoDTOList[]> {
    const data = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      projectId: this.viewProjectService.getProjectId(),
      projectRoleId: this.viewProjectService.getProjectRole(),
      filter: 'showall',
    };

    this.loaderService.displayLoader(true);
    return this.topmenusService
      .getExclusiveAssets(data)
      .pipe(takeUntil(this.destroy$));
  }

  getSensitiveImages(): Observable<AssetInfoDTOList[]> {
    const data = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      projectId: this.viewProjectService.getProjectId(),
      projectRoleId: this.viewProjectService.getProjectRole(),
      filter: 'showall',
    };

    this.loaderService.displayLoader(true);
    return this.viewProjectService
      .getSensitiveAssets(data)
      .pipe(takeUntil(this.destroy$));
  }

  getVideoAssetInfo(): Observable<AssetInfoDTOList[]> {
    const data = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      projectId: this.viewProjectService.getProjectId(),
      projectRoleId: this.viewProjectService.getProjectRole(),
      filter: 'showall',
    };

    this.loaderService.displayLoader(true);
    return this.viewProjectService
      .getVideoAssets(data)
      .pipe(takeUntil(this.destroy$));
  }

  private handleAssetDownloads(): any {
    const sensitiveAssets$ = this.getSensitiveImages();
    const exclusiveAssets$ = this.getExclusiveImages();

    return forkJoin([sensitiveAssets$, exclusiveAssets$]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
