import { DashboardDepartmentFavouriteAddProjectRule } from '../rules/dashboard-department-favourite-add-project-rule';
import { FavSelectedProjectModel } from '../../dashboard-favourite-project/interfaces/dashboard-favourite-icon-rule.model';

type DashboardEventsUnion = DashboardDepartmentFavouriteAddProjectRule;

export class DashboardDepartmentFavouriteIconRuleset {
  ruleSet: DashboardEventsUnion[];

  constructor(
    private active: boolean,
    private selectedProject: FavSelectedProjectModel,
  ) {
    this.ruleSet = [
      new DashboardDepartmentFavouriteAddProjectRule(active, selectedProject),
    ];
  }

  apply(): void {
    for (const rule of this.ruleSet) {
      if (rule.matches()) {
        rule.apply();
      }
    }
  }
}
