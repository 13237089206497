import { defer, Observable, Observer } from 'rxjs';
import { ResizeObserverArgs } from '../models/resize-observer-args.model';

export class ResizeUtils {
  static elementResizeListener(element: HTMLElement) {
    return defer(() => {
      return new Observable((observer: Observer<ResizeObserverArgs>) => {
        try {
          new ResizeObserver(
            (
              entries: ResizeObserverEntry[],
              resizeObserver: ResizeObserver,
            ) => {
              observer.next({
                entries,
                observer: resizeObserver,
              } as ResizeObserverArgs);
            },
          ).observe(element);
        } catch (e) {
          observer.error(e);
        }
      });
    });
  }
}
