import { LauncherService } from '../../../../launcher/launcher.service';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';
import { FavSelectedProjectModel } from '../interfaces/dashboard-favourite-icon-rule.model';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { Group } from '../../../../../shared/models/projectlist.model';
import { EventEmitter } from '@angular/core';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';

export class DashboardFavouriteRemoveProjectRule implements DashboardRuleModel {
  private launcherService = GetStaticInjector().get(LauncherService);
  private loaderService = GetStaticInjector().get(LoaderService);
  private apiErrorHandlerService = GetStaticInjector().get(ApiErrorHandlerService);

  constructor(
    private active: boolean,
    private selectedProject: FavSelectedProjectModel,
    private groupedProjectList: Group[],
    private updatedFavStatus: EventEmitter<Group[]>,
  ) {}

  matches(): boolean {
    return !this.active;
  }

  apply(): void {
    const objToRemove = {
      active: false,
      id: this.selectedProject.value,
      name: this.selectedProject.label,
    };
    const favouriteProjects = this.launcherService.getFavProjectList();

    this.loaderService.displayLoader(true);
    this.launcherService.updateFav(objToRemove).subscribe((res) => {
      if (res.status) {
        const index = favouriteProjects.findIndex(
          (item) => item.value === this.selectedProject.value,
        );
        favouriteProjects.splice(index, 1);
        this.launcherService.setFavProjectList(favouriteProjects);
        this.groupedProjectList.forEach((group) => {
          group.items.forEach((item) => {
            if (item.value === this.selectedProject.value) {
              item['fav'] = false;
            }
          });
        });
        this.updatedFavStatus.emit(this.groupedProjectList);
      }
      this.loaderService.displayLoader(false);
    },
      (error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }
}
