<div class="search-wrapper">
  <div class="search-wrapper-cont">
    <input
      matInput
      type="text"
      class="fs-input-hide-placeholder-focused"
      [formControl]="searchFormControl"
      [placeholder]="'dashboard.search' | translate"
      [matAutocomplete]="auto"
    />

    <mat-autocomplete
      class="fs-selectbox-dark fs-selectbox-dark-txt-sm"
      autoActiveFirstOption
      #auto="matAutocomplete"
    >
      <mat-option
        class="fs-sm-txt fs-project-selectbox my-0 fs-selectbox-options"
        *ngFor="let option of arrAlbumList"
        [value]="option.label"
        (onSelectionChange)="navigateToProject(option)"
      >
        {{ option.label }}
      </mat-option>
    </mat-autocomplete>
  </div>
</div>
