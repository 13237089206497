<div mat-dialog-title class="d-flex justify-content-between">
  <div class="modal-title">
    {{ 'top-menu.printTool.labels.compare' | translate }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeSecondCompare()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="range_cont">
    <div class="range_inner d-flex flex-column">
      <div class="range_indicator_cont">
        <div class="indicator_num indicator_rule1">0</div>
        <div class="indicator_num indicator_rule2">25</div>
        <div class="indicator_num indicator_rule3">50</div>
        <div class="indicator_num indicator_rule4">75</div>
        <div class="indicator_num indicator_rule5">100</div>
      </div>
      <mat-slider
        class="slider-wrapper"
        name="rageValue"
        [max]="100"
        [min]="1"
        [style]="{ width: '205px' }"
        [step]="1"
      >
        <input
          matSliderThumb
          (input)="zoomImageWidth($event)"
          [(ngModel)]="rangeValue1"
          #slider
        />
      </mat-slider>
    </div>
    <div class="image-toggle"></div>
  </div>

  <div
    #parentabs
    class="img-comp-container"
    [ngStyle]="{ width: compareContWidth, 'height.px': imgContHeight }"
  >
    <div
      class="level_1"
      [ngStyle]="{ 'width.px': span3Width, 'height.px': imgContHeight }"
    >
      <div class="level_2">
        <div #childabs class="img-comp-img" [hidden]="!imageVisible1">
          <img
            preventdrag
            [lazySrc]="
              viewProjectService.getWebImageURL(
                iterateDataFromObjectMap(1),
                '1600'
              )
            "
            alt=""
            [width]="imgWidth"
            [ngStyle]="{ 'max-width.px': imgWidth }"
            (mouseup)="mouseUpShow(1)"
            (mousedown)="mouseDownHide(1)"
          />
        </div>
        <div class="img-comp-slider" [hidden]="!imageCenterVisible"></div>

        <div
          [ngClass]="{ image0_complete: isImage0CompleteShown }"
          class="img-comp-img img-comp-overlay"
          [ngStyle]="{ 'width.px': imgWidth }"
          [hidden]="!imageVisible0"
        >
          <img
            preventdrag
            [lazySrc]="
              viewProjectService.getWebImageURL(
                iterateDataFromObjectMap(0),
                '1600'
              )
            "
            alt=""
            class="compimg"
            [width]="imgWidth"
            [ngStyle]="{ 'max-width.px': imgWidth }"
            (mouseup)="mouseUpShow(0)"
            (mousedown)="mouseDownHide(0)"
            (load)="onLoad()"
          />
        </div>
      </div>
    </div>
  </div>
</div>
