<div class="dialog-wrapper">
  <div
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    mat-dialog-title
    class="d-flex justify-content-between align-items-center"
  >
    <div class="modal-title" id="taggign_view_1">
      {{ 'image-caption.title' | translate }}
    </div>
    <button class="fs-close-btn transition-none w-auto" (click)="closeWindow()">
      <i class="fa fa-times-circle"></i>
    </button>
  </div>
  <div
    mat-dialog-content
    class="dialog-content-container"
    (mouseup)="mouseUpShow()"
  >
    <!-- body start -->
    <div class="navigateImages">
      {{ 'image-caption.navigate' | translate }}:
    </div>
    <div class="rowOne row d-flex">
      <div class="col-sm-6 mf-sngle">
        <button
          cdkDrag
          cdkDragRootElement=".cdk-overlay-pane"
          cdkDragHandle
          class="form-control btn font_12"
          (mousedown)="mouseDownHide()"
        >
          {{ 'image-caption.buttons.hideCaption' | translate }}
        </button>
      </div>
      <div class="col-sm-3 mf-sngle mt-10">
        <button
          class="btn"
          (click)="onClickPrevious()"
          *ngIf="currentImage > 1"
        >
          <i class="fa fa-caret-left" aria-hidden="true"></i>
        </button>
      </div>

      <div class="col-sm-3 mf-sngle mt-10">
        <button
          class="btn"
          *ngIf="currentImage < totalProjectIds.length"
          (click)="onClickNext()"
        >
          <i class="fa fa-caret-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="rowTwo">
      <div class="col-sm-6">
        <span class="imageCaptionLabel">
          <span *ngIf="displaySelectedImages.length > 1">
            ({{ displaySelectedImages.length }})
            {{ 'image-caption.image-selected' | translate }} :
            {{ displaySelectedImages[0].name }} thru
            {{ displaySelectedImages[displaySelectedImages.length - 1].name }}
          </span>

          <span *ngIf="displaySelectedImages.length === 1">
            ({{ displaySelectedImages.length }})
            {{ 'image-caption.image-selected' | translate }}:
            {{ displaySelectedImages[0].name }}
          </span>
        </span>
      </div>
      <div *ngIf="displaySelectedImages.length > 1">
        <div class="col-sm-3 mf-sngle">
          <button
            (click)="onClickSelectedPreviousButton()"
            *ngIf="priviousButton"
          >
            {{ 'image-caption.buttons.previous' | translate }}
          </button>
        </div>
        <div class="col-sm-3 mf-sngle">
          <button (click)="onClickSelectedNextButton()" *ngIf="nextButton">
            {{ 'image-caption.buttons.next' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="rowthree">
      <div class="gwt-HTML">
        {{ 'image-caption.actors' | translate }}:
        <span *ngFor="let tag of tagList">
          <br />
          <font size="1.5">{{ tag.characterName }} ({{ tag.tagName }})</font>
        </span>
      </div>
      <div class="rowFour">
        <div class="short-header">
          <label
            [ngClass]="isActivateCtrl ? 'active' : ''"
            class="labelShortCutKey fs-sm-txt"
            (click)="shortCutKey()"
          >
            <i
              _ngcontent-c19=""
              aria-hidden="true"
              [ngClass]="isSortCutKey ? 'fa-chevron-down' : 'fa-chevron-right'"
              class="fa"
            ></i>
            {{
              isActivateCtrl
                ? 'image-caption.shorcut-keydeactive'
                : ('image-caption.shorcut-keyactive' | translate)
            }}
          </label>
          <div class="fnClass" *ngIf="isSortCutKey">
            <div (click)="addKeyPopUP()">
              {{ 'image-caption.add' | translate }}
            </div>
            <div (click)="removeKeyPopUP()">
              {{ 'image-caption.remove' | translate }}
            </div>
            <div (click)="editKeyPopUP()">
              {{ 'image-caption.edit' | translate }}
            </div>
          </div>
        </div>
        <div
          *ngIf="isSortCutKey"
          class="list-added-key"
          [ngClass]="isActivateCtrl ? 'active' : ''"
        >
          <div *ngFor="let key of captionKeys">
            {{ key.keyText }}-{{ key.value }}
          </div>
        </div>
      </div>
      <div class="rowFive">
        <div class="form-group">
          <textarea
            #textareaInput
            class="findKeysInField text-area form-control"
            [(ngModel)]="captionText"
            (focus)="onFocus()"
            (focusout)="onBlur()"
          >
          </textarea>
        </div>
      </div>
      <div class="rowSix">
        <div class="form-group">
          <mat-select
            class="form-control fs-selectbox-dark"
            required
            [placeholder]="'image-caption.select' | translate"
            [(ngModel)]="selectedCaption"
            optionLabel="label"
            (valueChange)="selectedItems($event.value)"
          >
            <mat-option
              class="fs-sm-txt fs-selectbox-options"
              *ngFor="let caption of captionArray"
              [value]="caption"
            >
              {{ caption.label }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="rowSix">
        <div class="col-md-8 pl-0">
          <div class="text-left">
            <mat-checkbox
              name="filterOpetion"
              class="fs-checkbox"
              [checked]="isAutoSave"
              (change)="isAutoSave = !isAutoSave"
            >
              <span class="cb-title">
                {{ 'image-caption.auto' | translate }}
              </span>
            </mat-checkbox>
          </div>
        </div>
        <div class="col-md-4 mf-sngle"></div>
      </div>
      <div *ngIf="radioShow" class="rowSix full_width report">
        <div>
          <div class="captionText full_width">
            {{
              'left-menu.tab-bar.sidemenus.administration.imagenotes-report.labels.label1'
                | translate
            }}
          </div>
          <div class="caption-report">
            <div class="imreport-cont">
              <div class="radio down-input captio_reprt_radio">
                <label class="custom-radio">
                  <input
                    type="radio"
                    name="repotytype"
                    value="1"
                    [(ngModel)]="repotyType"
                    (change)="onCaptionChange()"
                  />
                  <span class="cb-title">{{
                    'left-menu.tab-bar.sidemenus.administration.imagenotes-report.labels.all'
                      | translate
                  }}</span>
                </label>
              </div>
              <div class="radio down-input captio_reprt_radio">
                <label class="custom-radio">
                  <input
                    type="radio"
                    name="repotytype"
                    value="2"
                    [(ngModel)]="repotyType"
                    (change)="onCaptionChange()"
                  />
                  <span class="cb-title">
                    {{
                      'left-menu.tab-bar.sidemenus.administration.imagenotes-report.labels.current-filter'
                        | translate
                    }}</span
                  >
                </label>
              </div>
            </div>
          </div>
          <div class="caption-report-actions mf-sngle">
            <button
              class="btn caption_btn"
              (click)="captionReport()"
              name="generateNew"
              value="Generate New"
            >
              {{ 'image-caption.buttons.generateNew' | translate }}
            </button>

            <button
              class="btn caption_btn"
              [ngClass]="{
                captionreturn: existingImageCaptionData.length === 0
              }"
              (click)="captionReportExisting()"
              name="Return"
              value="Return to Previous"
            >
              {{ 'image-caption.buttons.returnToPrev' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-between">
    <div>
      <button
        *ngIf="orgCaptionText !== ''"
        class="fs-dialog-btn transition-none w-auto ml-3 remove_btn"
        (click)="removeCaption()"
        name="generateNew"
        value="Generate New"
      >
        {{ 'image-caption.buttons.removecaption' | translate }}
      </button>
    </div>
    <div class="d-flex">
      <button
        (click)="superZoomCaption()"
        name="superZoom"
        class="fs-dialog-btn transition-none w-auto ml-3"
        [value]="zoomChangeText"
      >
        {{ 'image-caption.buttons.zoom' | translate }}
      </button>
      <button
        [disabled]="textareaInput.value.length === 0"
        name="save"
        class="fs-dialog-btn transition-none w-auto ml-3"
        value="save"
        (click)="saveCaption(true)"
      >
        {{ 'image-caption.buttons.save' | translate }}
      </button>
    </div>
  </div>
</div>
