import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettings } from '../../../../appSettings/appSettings';
import { ApiService } from '../../../../shared/services/api.service';

/*
  Generated class for the DbService provider.
  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/

@Injectable()
export class DrawImageService {
  private retouchDrawsSubject = new BehaviorSubject<any>([]);
  retouchDrawsGlobal = this.retouchDrawsSubject.asObservable();

  private retouchCommentsSubject = new BehaviorSubject<any>([]);
  retouchCommentsGlobal = this.retouchCommentsSubject.asObservable();

  private retouchDrawsContributorListSubject = new BehaviorSubject<any>([]);
  retouchDrawsContributorListGlobal =
    this.retouchDrawsContributorListSubject.asObservable();

  private retouchRemoveUsersSubject = new BehaviorSubject<any>([]);
  retouchRemoveUsersGlobal = this.retouchRemoveUsersSubject.asObservable();

  @Output() closeDrawGlobal: EventEmitter<boolean> = new EventEmitter(false);
  @Output() saveDrawGlobal: EventEmitter<boolean> = new EventEmitter(false);

  constructor(private apiService: ApiService) {}

  setCloseDrawGlobal(status) {
    this.closeDrawGlobal.emit(status);
  }

  // Global Image Selection
  setRetouchDraws(data) {
    this.retouchDrawsSubject.next(data);
  }

  setRetouchComments(data) {
    this.retouchCommentsSubject.next(data);
  }

  reSetRetouchComments() {
    this.retouchCommentsSubject.next({});
  }

  getRetouchComments() {
    return this.retouchCommentsSubject.value;
  }

  setRetouchContributorList(data) {
    this.retouchDrawsContributorListSubject.next(data);
  }

  getRetouchContributorList() {
    return this.retouchDrawsContributorListSubject.value;
  }

  setRemoveUsers(data) {
    this.retouchRemoveUsersSubject.next(data);
  }

  callRetouchDraw(projectId, assetId): Observable<any> {
    const path = AppSettings.RETOUCH_DRAWS_GET(projectId, assetId);
    return this.apiService.get(path).pipe(map((data) => data));
  }

  saveRetouchDraw(data): Observable<any> {
    const path = AppSettings.RETOUCH_DRAWS();
    return this.apiService.post(path, data).pipe(map((data) => data));
  }

  callRetouchComments(projectId, assetId): Observable<any> {
    const path = AppSettings.RETOUCH_COMMENTS_GET(projectId, assetId);
    return this.apiService.get(path).pipe(
      map((data) => {
        return data;
      }),
    );
  }

  saveRetouchComments(data): Observable<any> {
    const path = AppSettings.RETOUCH_COMMENTS();
    return this.apiService.post(path, data).pipe(
      map((data) => {
        return data;
      }),
    );
  }

  updateRetouchComments(data): Observable<any> {
    const path = AppSettings.RETOUCH_COMMENTS();
    return this.apiService.put(path, data).pipe(
      map((data) => {
        return data;
      }),
    );
  }

  deleteRetouchComments(projectId, assetId, id): Observable<any> {
    const path =
      AppSettings.RETOUCH_COMMENTS_GET(projectId, assetId) + '/' + id;
    return this.apiService.delete(path).pipe(
      map((data) => {
        return data;
      }),
    );
  }

  deleteRetouchDraw(projectId, assetId): Observable<any> {
    const path = AppSettings.RETOUCH_DRAWS_GET(projectId, assetId);
    return this.apiService.delete(path).pipe(
      map((data) => {
        return data;
      }),
    );
  }
}
