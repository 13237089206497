<div
  class="fs-sm-txt fs-txt-white-dark-color list-unstyled components sideBar-menu py-2"
>
  <div class="right-content">
    <div class="container d-block sub-menu-list sub-menu-text-spacing m-0">
      <div class="row">
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="filteredfileName()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.buttonse.filtered-list'
              | translate
          }}
        </span>
      </div>
      <div class="row">
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="selectedfileName()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.buttonse.selected-list'
              | translate
          }}
        </span>
      </div>

      <div class="row" *ngIf="exclusiveAccess && !isTaggerRole">
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="imageExclusives()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.buttonse.image-exclusives'
              | translate
          }}
        </span>
      </div>

      <div
        class="row"
        *ngIf="
          (userAuthority === userRole.ROLE_ADMIN || talentManagementAccess) &&
          !isTaggerRole
        "
      >
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="telentManagement()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.talent-management.title'
              | translate
          }}
        </span>
      </div>

      <div
        class="row"
        *ngIf="
          domainObjAbbr === appSource.WDTV &&
          (userAuthority === userRole.ROLE_ADMIN ||
            projectAuthority === projectRole.ROLE_EXEC)
        "
      >
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="approvalReport()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.buttonse.actor-approval-report'
              | translate
          }}
        </span>
      </div>

      <div class="row">
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="actorIdReport()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.buttonse.actor-id-report'
              | translate
          }}
        </span>
      </div>

      <div
        class="row"
        *ngIf="
          (userAuthority === userRole.ROLE_ADMIN ||
            projectAuthority === projectRole.ROLE_EXEC) &&
          !isTaggerRole
        "
      >
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="projectSummaryReport()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.buttonse.project-summary-report'
              | translate
          }}
        </span>
      </div>
      <div class="row" *ngIf="!isTaggerRole">
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="imageNotesReport()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.buttonse.image-notes-report'
              | translate
          }}
        </span>
      </div>

      <div class="row" *ngIf="!isTaggerRole">
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover global-help-icon-wraper"
          data-backdrop="static"
          (click)="imageCaptionReport()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.buttonse.image-caption-report'
              | translate
          }}
        </span>
      </div>

       <div *ngIf="userAuthority === userRole.ROLE_ADMIN">
        <hr class="fs-bg-white-dark-color mr-0 ml-0" />
      </div>

      <div class="row" *ngIf="userAuthority === userRole.ROLE_ADMIN">
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="onTagView1()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.buttonse.tagging-#1'
              | translate
          }}
        </span>
      </div>

      <div class="row" *ngIf="userAuthority === userRole.ROLE_ADMIN">
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="onTagView2()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.buttonse.tagging-#2'
              | translate
          }}
        </span>
      </div>
      <div
        class="row"
        *ngIf="userAuthority === userRole.ROLE_ADMIN && !isTaggerRole"
      >
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="batchManagement()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.buttonse.batch-management'
              | translate
          }}
        </span>
      </div>

      <div
        class="row"
        *ngIf="userAuthority === userRole.ROLE_ADMIN && !isTaggerRole"
      >
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="applyExif()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.buttonse.apply-exif'
              | translate
          }}
        </span>
      </div>

      <div class="row" *ngIf="userAuthority === userRole.ROLE_ADMIN">
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="advancedImageTagging()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.buttonse.advanced-image-tagging'
              | translate
          }}
        </span>
      </div>

      <div
        class="row"
        *ngIf="userAuthority === userRole.ROLE_ADMIN && !isTaggerRole"
      >
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          data-backdrop="static"
          (click)="Delivery()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.buttonse.delivery'
              | translate
          }}
        </span>
      </div>

      <div
        class="row"
        *ngIf="userAuthority === userRole.ROLE_ADMIN && !isTaggerRole"
      >
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="finalizeUnfinalize()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.buttonse.finalize-unfinalize'
              | translate
          }}
        </span>
      </div>

      <div
        class="row"
        *ngIf="userAuthority === userRole.ROLE_ADMIN && !isTaggerRole"
      >
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="rejectionToggle()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.buttonse.rejection-toggle'
              | translate
          }}
        </span>
      </div>

      <div
        class="row"
        *ngIf="userAuthority === userRole.ROLE_ADMIN && !isTaggerRole"
      >
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="onOpenDuplicateListMenu()"
        >
          {{
          'left-menu.tab-bar.sidemenus.administration.buttonse.duplicate-list-menu'
            | translate
          }}
        </span>
      </div>

      <div
        class="row"
        *ngIf="userAuthority === userRole.ROLE_ADMIN && !isTaggerRole"
      >
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="onOpenOriginalNameListMenu()"
        >
        {{'left-menu.tab-bar.sidemenus.administration.buttonse.original-names' | translate}}
        </span>
      </div>

      <div
        class="row"
        *ngIf="
          domainObjAbbr === appSource.WDTV &&
          (userAuthority === userRole.ROLE_ADMIN ||
            projectAuthority === projectRole.ROLE_EXEC)
        "
      >
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="makeCoverTool()"
        >
          {{
            'left-menu.tab-bar.sidemenus.administration.buttonse.makeCoverTool'
              | translate
          }}
        </span>
      </div>
    </div>
  </div>
</div>
