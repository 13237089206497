<div class="fs-txt-white-dark-color components mb-1 py-2 sign-off-main">
  <div class="right-content">
    <div class="sub-menu-list m-0 d-block">
      <div class="tree-parent">
        <div class="tree-inner">
          <tree-root
            #treeSignOff
            [nodes]="signoffNode"
            [options]="options1"
            (event)="onEvent($event)"
          >
            <ng-template
              #treeNodeTemplate
              let-node
              let-index="index"
              name="name"
            >
              <div>
                <label *ngIf="node.data.active" class="folder-name-count">
                  <i
                    fsImagebuttonhover
                    aria-hidden="true"
                    (click)="onClickAlbum(index)"
                    [ngClass]="[
                      node.data.tagKey !== -1
                        ? 'folder-icon-' + node.data.tagKey
                        : 'folder-icon-blank',
                      activeAlbumFolder.id == node.data.id
                        ? 'fs-button-selected'
                        : ''
                    ]"
                    (drop)="getSelectedImagesOnDrop($event)"
                    [id]="node.data.id"
                  >
                  </i>
                  <span
                    class="text-album-name pl-1 fs-sm-txt"
                    (click)="onClickAlbumName(index, node)"
                    fsContextMenuHandler
                    [matMenuTrigger]="matMenuTrigger"
                    [node]="node"
                    (setUpContextMenuPosition)="
                      setUpContextMenuPosition($event)
                    "
                    >{{ node.data.value }} ({{ node.data.assetCount }})</span
                  >
                </label>
              </div>
            </ng-template>
          </tree-root>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  style="visibility: hidden; position: fixed"
  [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y"
  [matMenuTriggerFor]="withFunctions"
></div>

<mat-menu
  #withFunctions
  hasBackdrop="false"
  class="fs-menu"
  [classList]="
    'overflow-hidden mw-100 fs-drop-down-menu fs-drop-down-no-padding fs-context-menu-container'
  "
>
  <ng-template matMenuContent let-item="node">
    <div
      mat-menu-item
      *ngIf="adhocLinkAccess"
      (click)="linkPage(item)"
      class="mat-menu-text left-menu-img-with-txt fs-txt-white-dark-color"
    >
      <img src="/assets/images/icon-link.png" alt="icon" />
      {{ 'left-menu.tab-bar.sidemenus.albums.rightClick.adhoc' | translate }}
    </div>

    <div
      mat-menu-item
      *ngIf="printAccess"
      (click)="printTool(item)"
      class="mat-menu-text left-menu-img-with-txt fs-txt-white-dark-color"
    >
      <img src="/assets/images/icon-print.png" alt="icon" />
      {{ 'left-menu.tab-bar.sidemenus.albums.rightClick.pdf' | translate }}
    </div>
  </ng-template>
</mat-menu>
