import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogsStatePool } from './dialog-state-pool';
import { DialogConfig } from './dialog.service';

@Injectable({ providedIn: 'root' })
export class DialogsStateService implements OnDestroy {
  private dialogsStatePool: DialogsStatePool = new DialogsStatePool();
  private destroy$ = new Subject(); // todo think what to do with this in class
  private openedDialogs = new BehaviorSubject([]);

  constructor(public dialog: MatDialog) {}

  ngOnDestroy(): void {
    this.dialogsStatePool.destroy();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  set(id: string) {
    const dialogState = this.dialogsStatePool.get(id);
    if (!dialogState) {
      const dialogRef = this.dialog.getDialogById(id);
      const opened = Boolean(dialogRef);
      const closed = !opened;
      const newState = {
        opened,
        closed,
        data: dialogRef?._containerInstance?._config?.data || null,
        dialogRef: dialogRef || null,
        config: dialogRef?._containerInstance?._config,
      };
      this.dialogsStatePool.set(id, newState);
    } else {
      console.log('Dialog state with id: ' + id + 'is already exist');
    }
  }

  checkAndUpdateDialogState(id) {
    const dialogState = this.dialogsStatePool.get(id);
    if (dialogState) {
      const dialogRef = this.dialog.getDialogById(id);
      const opened = Boolean(dialogRef);
      const closed = !opened;
      dialogState.update({
        opened,
        closed,
        data: dialogRef?._containerInstance?._config?.data || null,
        dialogRef: dialogRef || null,
        config: dialogRef?._containerInstance?._config,
      });
    } else {
      this.set(id);
    }
  }

  setOpenDialogs(dialogs: MatDialogRef<any>[]) {
    this.openedDialogs.next(dialogs);
  }

  getAndRegisterWithDefaultStateIfNeed(id: string) {
    const state = this.find(id);
    if (state) {
      return state;
    } else {
      const opened = false;
      const closed = false;
      const newState = {
        opened,
        closed,
      };
      this.dialogsStatePool.set(id, newState);
      return this.find(id);
    }
  }

  find(id: string) {
    const state = this.dialogsStatePool.get(id);
    return state?.getState() ?? null;
  }

  isAtLeastOneOpened(): boolean {
    return this.dialog.openDialogs.length > 0;
  }

  isHandlingUpKeyEnabled() {
    return this.dialog.openDialogs.some(
      (item) =>
        (item?._containerInstance?._config as DialogConfig).enableHandlingUpKey,
    );
  }

  isAtLeastOneOpened$(): Observable<boolean> {
    return this.openedDialogs.pipe(
      map((dialogs: MatDialogRef<any>[]) => dialogs.length > 0),
    );
  }
}
