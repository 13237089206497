import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettings } from '../../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../../shared/services/api.service';

@Injectable()
export class HideImagesService {
  constructor(private apiService: ApiService) {}

  putHideImages(obj): Observable<any> {
    const path = AppSettings.HIDE_IMAGES;
    return this.apiService.put(path, obj).pipe(
      map((res) => {
        return res;
      }),
    );
  }
}
