<div class="sidebar-header">
  <div class="search-filter" *ngIf="!isPrioritypixBtnShown">
    <a
      class="text-uppercase text-center filter-btn fs-cursor-pointer"
      (click)="openFinilizePopup()"
      data-toggle="modal"
      type="button"
      data-backdrop="static"
      data-keyboard="false"
    >
      {{ 'left-menu.finalization.buttontitle' | translate }}
    </a>
  </div>

  <div class="search-filter" *ngIf="isPrioritypixBtnShown">
    <a
      class="text-uppercase text-center filter-btn"
      (click)="onClickFinalizePriorityPix()"
    >
      {{ 'left-menu.finalization.priorityPix' | translate }}
    </a>
  </div>
</div>
