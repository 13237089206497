import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  AfterViewInit,
} from '@angular/core';

@Directive({
  selector: '[fsAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  private _autofocus;
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.elementRef.nativeElement.focus();
  }

  @Input() set autofocus(condition: boolean) {
    this._autofocus = condition !== false;
  }
}
