import { Component, Input } from '@angular/core';

@Component({
  selector: 'fs-tagger-panel-component',
  templateUrl: './tagger-panel-component.component.html',
  styleUrls: ['./tagger-panel-component.component.scss'],
})
export class TaggerPanelComponentComponent {
  @Input() isTaggingView1Active;
  @Input() isTaggingView2Active;
  @Input() imgData;
  @Input() isConflictsActive;
  @Input() isNoConflictsActive;
  @Input() isByPass2ndTaggerActive;
  @Input() simpleTagger;
}
