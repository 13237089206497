import { Component, Input } from '@angular/core';

@Component({
  selector: 'fs-tagging-audit-component',
  templateUrl: './tagging-audit-component.component.html',
  styleUrls: ['./tagging-audit-component.component.scss'],
})
export class TaggingAuditComponentComponent {
  @Input() isConflictsActive: boolean;
  @Input() imgData;
}
