import { Utils } from '../../../../../shared/utils/utils';
import { ProjectRoleId } from '../../../../../shared/enum/project-role.enum';
import { Router } from '@angular/router';
import { DomainConfigService } from '../../../../../shared/services/domain-config.service';
import { LauncherService } from '../../../../launcher/launcher.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { UserService } from '../../../../../shared/services/user.service';
import { AppSource } from '../../../../../shared/models/enum/AppSource';
import { FilterEnum } from '../../../../../shared/enum/filter.enum';
import { SessionTypeEnum } from '../../../../../shared/enum/session-type.enum';
import { ViewGalleryResumeModalStateService } from '../../../../../shared/services/view-gallery-resume-modal.service';
import {
  SelectedProjectModel,
  SelectedProjectTypeModel,
} from '../interfaces/dashboard-select-project-rule.model';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { PROJECT } from '../../../../../shared/constants/constants';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardSelectPkoExecProjectRule implements DashboardRuleModel {
  private readonly DEFAULT_PAGE_NUMBER = 1;

  private router = GetStaticInjector().get(Router);
  private launcherService = GetStaticInjector().get(LauncherService);
  private viewProjectService = GetStaticInjector().get(ViewProjectService);
  private userService = GetStaticInjector().get(UserService);
  private domainConfigService = GetStaticInjector().get(DomainConfigService);
  private viewGalleryResumeModalService = GetStaticInjector().get(
    ViewGalleryResumeModalStateService,
  );

  constructor(
    private currentSelectedProjectData: SelectedProjectModel,
    private selectedProject: string,
    private selectedProjectType: SelectedProjectTypeModel,
    private selectedAlbum: SelectedProjectModel,
  ) {}

  matches(): boolean {
    return (
      this.selectedProjectType?.label === PROJECT &&
      !(
        this.selectedAlbum.useSetup &&
        this.selectedAlbum.role === Number(ProjectRoleId.ROLE_EXEC)
      ) &&
      !(
        this.domainConfigService.getDomainConfigData().abbr ===
          AppSource.WDTV &&
        this.selectedAlbum.role === Number(ProjectRoleId.ROLE_TALENT)
      )
    );
  }

  apply(): void {
    this.viewProjectService.setProjectRole(
      this.currentSelectedProjectData.role,
    );
    this.userService.resetData();
    this.launcherService.updateProjectChangeState(true);
    this.router.navigate([
      Utils.prepareFilterNavigationURL(
        this.selectedAlbum.role,
        this.selectedProject,
        FilterEnum.ShowAll,
        this.DEFAULT_PAGE_NUMBER,
      ),
    ]);
    this.viewGalleryResumeModalService.setDialogActionState(
      SessionTypeEnum.Continue,
    );
  }
}
