export class Executive {
  groupName: string;
  groupLogo: string;
  groupId: number;
  items: Items[];
}

class Items {
  value: number;
  label: string;
  createdAt: string;
  setupCount: number;
  useSetup: boolean;
}
