import { Router } from '@angular/router';
import { LauncherService } from '../../../../launcher/launcher.service';
import { SearchProjectModel } from '../interfaces/dashboard-search-project-rule.model';
import { DepartmentService } from '../../../../department/department.service';
import { AppSource } from '../../../../../shared/models/enum/AppSource';
import { ProjectRoleId } from '../../../../../shared/enum/project-role.enum';
import { DomainConfigService } from '../../../../../shared/services/domain-config.service';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardSearchWdtvTalentRule implements DashboardRuleModel {
  private router = GetStaticInjector().get(Router);
  private launcherService = GetStaticInjector().get(LauncherService);
  private departmentService = GetStaticInjector().get(DepartmentService);
  private domainConfigService = GetStaticInjector().get(DomainConfigService);

  constructor(
    private fromDepartment: boolean,
    private selectedProjectId: number,
    private selectedProjectData: SearchProjectModel,
  ) {}

  matches(): boolean {
    return (
      !this.fromDepartment &&
      !this.selectedProjectData.useSetup &&
      this.domainConfigService.getDomainConfigData().abbr === AppSource.WDTV &&
      this.selectedProjectData.role === Number(ProjectRoleId.ROLE_TALENT)
    );
  }

  apply(): void {
    this.launcherService.setSelectedProject(this.selectedProjectData.value);
    this.launcherService.setSelectedProjectName(
      this.selectedProjectData.displayText,
    );
    this.launcherService.updateProjectChangeState(true);
    this.departmentService.setSetUpNameGlobal(this.selectedProjectData.label);
    this.launcherService.setProjectRole(this.selectedProjectData.role);
    this.router.navigate(
      ['/dashboard/viewgallery/' + this.selectedProjectData.value],
      { state: { selectedProjectRole: this.selectedProjectData.role } },
    );
  }
}
