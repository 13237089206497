import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AssetTransactionData } from '../../transmodel';
import { MatTableDataSource } from '@angular/material/table';
import { MatStepper } from '@angular/material/stepper';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { ImageTransactionsService } from '../../image-transactions.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Utils } from '../../../../../../../shared/utils/utils';
import {
  ExpressDownloadSizeType,
  ImageSize,
  ImageTypeSize,
} from '../../../../../../../shared/models/enum/download-size';
import { formData } from '../../../../../dashboard-shared/top-menu/printtool/printtool.data';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

const TransactionType = {
  ZIP: 'ZIP',
  DOWNLOAD: 'DOWNLOAD',
  EXPRESS_DOWNLOAD: 'EXPRESS_DOWNLOAD',
  ADHOC_LINK: 'ADHOC_LINK',
  META_TOOL: 'META_TOOL',
};

enum PrintKeys {
  gutter = 'gutter',
  pageSize = 'pageSize',
  angle = 'angle',
  perPage = 'perPage',
  to = 'to',
}

@Component({
  selector: 'fs-transaction-report-details',
  templateUrl: './transaction-report-details.component.html',
  styleUrls: ['./transaction-report-details.component.scss'],
})
export class TransactionReportDetailsComponent implements OnInit, OnDestroy {
  @Input() stepper: MatStepper;

  displayedColumns: string[] = ['transactionDetails', 'browserInfo'];
  dataSource = new MatTableDataSource([]);
  private destroy$: Subject<any> = new Subject<any>();

  DOWNLOAD_SIZE = 'download-size';
  CREATOR_KEY = 'Creator';
  PRINT = 'PRINT';

  constructor(
    private loaderService: LoaderService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private imageTransactionsService: ImageTransactionsService,
  ) {}

  ngOnInit(): void {
    this.imageTransactionsService
      .getImageTransactionDetailData()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: AssetTransactionData) => {
        const detailsData = Utils.deepCopyObject(data);
        detailsData.transactionDetails = Object.keys(
          detailsData.transactionDetails,
        ).map((key) => {
          if (PrintKeys[key]) {
            this.renamePrintConfigurationList(key, detailsData);
          }

          if (key === this.DOWNLOAD_SIZE) {
            this.setDownloadSizeType(detailsData, key);
          }
          return [key, detailsData.transactionDetails[key]];
        });
        this.addImageNumberColumn(detailsData);
        this.dataSource.data = [detailsData];
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  setDownloadSizeType(detailsData: AssetTransactionData, key: string): void {
    detailsData.transactionDetails[key] = this.getDownloadAssetSizeType(
      detailsData.transactionDetails[key],
      detailsData.transactionType,
    );
  }

  getDownloadAssetSizeType(
    downloadType: string,
    transactionType: string,
  ): string {
    return {
      [ImageTypeSize.Small]: ImageSize.SMALL_JPEG,
      [ImageTypeSize.Medium]: ImageSize.MEDIUM_JPEG,
      [ImageTypeSize.Large]:
        transactionType === TransactionType.EXPRESS_DOWNLOAD
          ? ExpressDownloadSizeType.LARGE
          : ImageSize.LARGE_JPEG,
      [ImageTypeSize.DNG]:
        transactionType === TransactionType.EXPRESS_DOWNLOAD
          ? ExpressDownloadSizeType.RAW
          : ImageSize.DNG,
      [ImageTypeSize.TIF]: ImageSize.TIF,
    }[downloadType];
  }

  renamePrintConfigurationList(key, detailsData) {
    switch (key) {
      case PrintKeys.gutter:
        detailsData.transactionDetails[key] = formData.gutter.find(
          (gutter) =>
            Number(detailsData.transactionDetails.gutter) ===
            gutter.transactionValue,
        ).label;
        break;
      case PrintKeys.pageSize:
        detailsData.transactionDetails[key] = formData.pageSize[0].label;
        break;
      case PrintKeys.angle:
        detailsData.transactionDetails[key] = formData.angle.find(
          (item) => item.value === Number(detailsData.transactionDetails.angle),
        ).label;
        break;
      case PrintKeys.perPage:
        detailsData.transactionDetails[key] =
          detailsData.transactionDetails.layout;
        break;
      case PrintKeys.to:
        if (detailsData.transactionType === this.PRINT) {
          detailsData.transactionDetails[key] =
            detailsData.transactionDetails.to.slice(1, -1);
        }
        break;
    }
  }

  addImageNumberColumn(detailsData: AssetTransactionData): void {
    if (TransactionType[detailsData.transactionType]) {
      this.displayedColumns.push('imageNumber');
    }
  }

  onPrevious(): void {
    if (this.displayedColumns.includes('imageNumber')) {
      this.displayedColumns.splice(
        this.displayedColumns.indexOf('imageNumber'),
        1,
      );
    }

    this.stepper.previous();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
