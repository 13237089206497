import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../../../../../shared/services/dialog/dialog.service';
import { SendEmailNotificationComponent } from './send-email-notification/send-email-notification.component';
import { FilteredfilenameListComponent } from '../administration/filteredfilename-list/filteredfilename-list.component';
import { ImagenotesReportComponent } from '../administration/imagenotes-report/imagenotes-report.component';
import { Activemodal } from '../../../../../../shared/models/activemodal.model';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../shared/store/selected-images-store';
import { Utils } from '../../../../../../shared/utils/utils';
import { SelectedfilenameListComponent } from '../administration/selectedfilename-list/selectedfilename-list.component';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { UserService } from '../../../../../../shared/services/user.service';

@Component({
  selector: 'fs-wide-edit-administration',
  templateUrl: './wide-edit-administration.component.html',
  styleUrls: ['./wide-edit-administration.component.scss'],
})
export class WideEditAdministrationComponent
  extends UnsubscriberComponent
  implements OnInit
{
  activemodal: Activemodal = new Activemodal();
  // Permission
  internalAccess = false;
  exclusiveAccess = false;
  isAdministrationActive = false;
  constantsGlobalData: any;
  isTaggerRole: boolean;

  constructor(
    private translate: TranslateService,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private userService: UserService,
    private selectedImagesStore: SelectedImagesStore,
  ) {
    super();
  }

  ngOnInit() {
    this.isTaggerRole = this.userService.isAdminWithTaggerRole();

    this.constantsGlobalData = ConstantsGlobal;
    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.internalAccess = data.permissionDTO.internalAccess;
          this.exclusiveAccess = data.permissionDTO.exclusiveAccess;
        } else {
          this.internalAccess = false;
          this.exclusiveAccess = false;
        }
      });
  }

  sendEmailNotification() {
    this.dialogService.open(SendEmailNotificationComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
      ],
    });
  }

  selectedFileName() {
    if (this.selectedImagesStore.hasEntities()) {
      this.dialogService.open(SelectedfilenameListComponent, {
        panelClass: [
          'fs-dialog-panel',
          'fs-dialog-size-lg-panel',
          'fs-dialog-link-panel',
        ],
        listener: {
          withListener: true,
          id: 'SelectedfilenameListComponent',
        },
      });
    } else {
      this.alertCustom(this.translate.instant('view-project.alert13'));
    }
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  filteredfileName() {
    this.dialogService.open(FilteredfilenameListComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
      ],
    });
  }

  imageNotesReport() {
    this.dialogService.open(ImagenotesReportComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-md-panel',
        'fs-dialog-link-panel',
      ],
    });
  }
}
