<ng-container *ngIf="!isPrioritypixBtnShown">
  <div
    class="fs-drop-down-btn fs-btn-container"
    [class.open]="customFilterMenuTrigger.menuOpen"
  >
    <div
      #customFilterMenuTriggerRef
      class="fs-cursor-pointer"
      [matMenuTriggerFor]="customFilterMenu"
      #customFilterMenuTrigger="matMenuTrigger"
    >
      {{ selectedOpt }}
      <i class="fa fa-caret-down" aria-hidden="true"></i>
    </div>
  </div>

  <mat-menu
    class="w-100"
    #customFilterMenu="matMenu"
    [classList]="
      'overflow-hidden mw-100 fs-drop-down-menu fs-drop-down-no-padding'
    "
  >
    <div
      class="container fs-txt-white-dark-color fs-sm-txt overflow-auto py-2"
      [style.width]="customFilterMenuTriggerRef.offsetWidth - 2 + 'px'"
    >
      <div class="row">
        <div class="col">
          <span>{{ 'left-menu.filter.label' | translate }}</span>

          <div class="ml-3" *ngFor="let static of staticFilter">
            <span
              class="fs-cursor-pointer fs-txt-white-grey-color-hover"
              (click)="clickOnSelectFilter(static.name, $event)"
              >{{ static.name }}</span
            >
          </div>

          <div class="ml-3" *ngFor="let G_list of dataGeneral">
            <span
              class="fs-cursor-pointer fs-txt-white-grey-color-hover"
              (click)="clickOnGeneral(G_list, $event)"
              >{{ G_list.name }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </mat-menu>
</ng-container>
