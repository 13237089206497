export interface UserExtended {
  firstName: string;
  lastName: string;
}

export const fullNameFormatter = <T extends UserExtended>(
  user: T,
  notExistsPlaceholder = '...',
) => {
  if (!user) {
    return null;
  }

  if (user.firstName || user.lastName) {
    let firstname = user.firstName;
    let lastname = user.lastName;

    firstname = firstname ? `${firstname} ` : '';
    lastname = lastname || '';

    return `${firstname}${lastname}`;
  }

  return notExistsPlaceholder;
};
