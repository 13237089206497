import { ProjectRoleId } from '../../../../../shared/enum/project-role.enum';
import { Router } from '@angular/router';
import { DepartmentService } from '../../../../department/department.service';
import { DomainConfigService } from '../../../../../shared/services/domain-config.service';
import { LauncherService } from '../../../../launcher/launcher.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { AppSource } from '../../../../../shared/models/enum/AppSource';
import {
  SelectedProjectModel,
  SelectedProjectTypeModel,
} from '../interfaces/dashboard-select-project-rule.model';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { PROJECT } from '../../../../../shared/constants/constants';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardSelectWdtvTalentProjectRule
  implements DashboardRuleModel
{
  private router = GetStaticInjector().get(Router);
  private launcherService = GetStaticInjector().get(LauncherService);
  private viewProjectService = GetStaticInjector().get(ViewProjectService);
  private departmentService = GetStaticInjector().get(DepartmentService);
  private domainConfigService = GetStaticInjector().get(DomainConfigService);

  constructor(
    private currentSelectedProjectData: SelectedProjectModel,
    private selectedProject: string,
    private selectedProjectType: SelectedProjectTypeModel,
    private selectedAlbum: SelectedProjectModel,
  ) {}

  matches(): boolean {
    return (
      this.selectedProjectType?.label === PROJECT &&
      !(
        this.selectedAlbum.useSetup &&
        this.selectedAlbum.role === Number(ProjectRoleId.ROLE_EXEC)
      ) &&
      this.domainConfigService.getDomainConfigData().abbr === AppSource.WDTV &&
      this.selectedAlbum.role === Number(ProjectRoleId.ROLE_TALENT)
    );
  }

  apply(): void {
    // if user role talent and App source is WDTV
    this.viewProjectService.setProjectRole(
      this.currentSelectedProjectData.role,
    );
    this.launcherService.setSelectedProject(this.selectedAlbum.value);
    this.launcherService.setSelectedProjectName(this.selectedAlbum.displayText);
    this.launcherService.updateProjectChangeState(true);
    this.departmentService.setSetUpNameGlobal(this.selectedAlbum.label);
    this.launcherService.setProjectRole(this.selectedAlbum.role);
    this.router.navigate(
      ['/dashboard/viewgallery/' + this.selectedAlbum.value],
      { state: { selectedProjectRole: this.selectedAlbum.role } },
    );
  }
}
