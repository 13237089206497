export const formData = {
  imageSize: [
    {
      label: 'High Res',
      value: 1600,
    },
    {
      label: 'Low Res',
      value: 900,
    },
  ],
  layout: [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 4, value: 4 },
    { label: 6, value: 6 },
    { label: 9, value: 9 },
    { label: 12, value: 12 },
    { label: 20, value: 20 },
  ],
  pageSize: [
    {
      label: '8.5 * 11',
      value: {
        width: 8.5,
        height: 11,
      },
    },
  ],
  gutter: [
    {
      label: 'No Gutter',
      value: 'No Gutter',
      transactionValue: 0,
    },
    {
      label: '1/2 left margin',
      value: '1/2 left margin',
      transactionValue: 1,
    },
  ],
  opacity: [
    { label: 0.1, value: 0.1 },
    { label: 0.2, value: 0.2 },
    { label: 0.3, value: 0.3 },
    { label: 0.4, value: 0.4 },
    { label: 0.5, value: 0.5 },
    { label: 0.6, value: 0.6 },
    { label: 0.7, value: 0.7 },
    { label: 0.8, value: 0.8 },
    { label: 0.9, value: 0.9 },
  ],
  angle: [
    {
      label: 'Default Diagonal',
      value: 0.0,
    },
    {
      label: 'Horizontal',
      value: 360.0,
    },
    {
      label: 'Left Diagonal',
      value: 45.0,
    },
    {
      label: 'Vertical',
      value: 270.0,
    },
    {
      label: 'Right Diagonal',
      value: 315.0,
    },
  ],
};
