import { Component, Inject, ViewChild } from '@angular/core';
import { ExportType } from '../../../../../../../shared/models/enum/ExportType';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { UserService } from '../../../../../../../shared/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { OriginalNamesService } from '../../../../../../../shared/services/original-names.service';
import { OriginalNamesModel } from './original-names.model';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'fs-original-names',
  templateUrl: './original-names.component.html',
  styleUrl: './original-names.component.scss'
})
export class OriginalNamesComponent extends UnsubscriberComponent {
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  exportType = ExportType;
  originalNamesList: OriginalNamesModel[];
  placeholderHeight = 20;

  constructor(
    private dialogRef: MatDialogRef<OriginalNamesComponent>,
    public viewProjectService: ViewProjectService,
    private userService: UserService,
    private originalNamesService: OriginalNamesService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: OriginalNamesModel[]
  ) {
    super();
    this.originalNamesList = data;
  }

  onClickExport(reportType) {
    if (reportType === this.exportType.Excel) {
      this.userService.exportReport(
        'Original Asset Names List',
        this.originalNamesList,
        'OriginalAssetNamesList.xlsx',
      );
    } else {
      const columns = [
        { header: this.translate.instant('left-menu.tab-bar.sidemenus.administration.original-names.labels.id'), dataKey: 'id' },
        { header: this.translate.instant('left-menu.tab-bar.sidemenus.administration.original-names.labels.currentName'), dataKey: 'currentName' },
        { header: this.translate.instant('left-menu.tab-bar.sidemenus.administration.original-names.labels.originalName'), dataKey: 'originalName' },
        { header: this.translate.instant('left-menu.tab-bar.sidemenus.administration.original-names.labels.description'), dataKey: 'description' }
      ];

      const documentTitle = `${this.viewProjectService.getProjectDetailPermissionData().projectName} OriginalAssetNamesList.pdf`;
      this.originalNamesService.exportPDFDataWithDocName(this.originalNamesList, documentTitle, columns);
    }
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
