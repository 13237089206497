<div
  #popupContainer
  class="fs-popup-container"
  [ngClass]="panelClass"
  fsOverlayZIndex
  [cdkOverlayContainerClass]="cdkOverlayContainerClass"
  [class.mat-tooltip-handset]="(isHandset | async)?.matches"
  [@state]="visibility"
  (@state.done)="animationDone($event)"
>
  <div
    class="fs-popup-pointer-panel"
    *ngIf="withArrow"
    popupArrowPosition
    [position]="positionChange | async"
    [popupContainer]="popupContainer"
    [targetElement]="target"
    [overlayContainerElement]="overlayContainerRef"
  ></div>

  <div class="fs-popup-content-wrapper">
    <ng-template cdkPortalOutlet></ng-template>
  </div>
</div>
