import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ProjectsummaryreportService } from './projectsummary-report.service';

import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { UserService } from '../../../../../../../shared/services/user.service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { ExportType } from '../../../../../../../shared/models/enum/ExportType';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-projectsummary-report',
  templateUrl: './projectsummary-report.component.html',
  styleUrls: ['./projectsummary-report.component.scss'],
})
export class ProjectsummaryReportComponent
  extends UnsubscriberComponent
  implements OnInit
{
  // array of all items to be paged
  projectReportData: any = {};
  sortF: any;
  getDataFromService: any;
  constantsGlobalData: any;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'name',
    'lastLogin',
    'lastFinalization',
    'reviewStatus',
    'excludedAssets',
    'sensitiveAssets',
    'pendingAssets',
    'finalizedAssets',
    'soloRejected',
    'groupRejected',
    'totalRejected',
    'lockedApproved',
    'lastRejectionPage',
  ];
  dataSource = new MatTableDataSource([]);
  exportType = ExportType;

  today: any;

  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering

  dtTrigger: Subject<any> = new Subject();
  constructor(
    private projectSummaryReport: ProjectsummaryreportService,
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private userService: UserService,
    private viewProjectService: ViewProjectService,
    private dialogRef: MatDialogRef<ProjectsummaryReportComponent>,
    private dialogService: DialogService,
  ) {
    super();
    this.today = Date.now();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.loaderService.displayLoader(true);

    this.projectSummaryReport
      .getProjectSummaryReport(this.viewProjectService.getProjectId())
      .pipe(takeUntil(this.destroy$))
      .subscribe((reportData) => {
        if (reportData) {
          this.projectReportData = reportData;
          this.dataSource.data = this.projectReportData.actors;
          this.dataSource.sort = this.sort;
        } else {
          this.closeWindow();
          this.projectReportData = {};
          this.alertCustom(
            this.translate.instant(
              'left-menu.tab-bar.sidemenus.administration.nodata-found',
            ),
          );
        }
        this.loaderService.displayLoader(false);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  exportReport(reportType) {
    if (reportType === this.exportType.Excel) {
      this.userService.exportReport(
        'Project Summary Report',
        this.projectReportData.actors,
        'ProjectSummary.xlsx',
      );
    } else {
      const colNames = [
        'name',
        'lastLogin',
        'lastFinalization',
        'reviewStatus',
        'excludedAssets',
        'sensitiveAssets',
        'pendingAssets',
        'finalizedAssets',
        'soloRejected',
        'groupRejected',
        'totalRejected',
        'lockedApproved',
        'lastRejectionPage',
      ];
      this.userService.exportPDFData(this.projectReportData.actors, colNames);
    }
  }

  changeSort(event) {
    if (!event.order) {
      this.sortF = 'year';
    } else {
      this.sortF = event.field;
    }
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
