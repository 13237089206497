<div class="sidebar-header">
  <div class="mainrole_admin" *ngIf="userAuthority === userRole.ROLE_ADMIN">
    <div *ngIf="selectedProjectValue !== 4 && selectedProjectValue !== 3">
      <mat-accordion [displayMode]="'flat'" class="fs-accordion-dark-theme">
        <fs-accordion-panel
          class="fs-album-accordion"
          *ngIf="currentState.displaySetting.leftMenu.items.menu.items.albums"
          [expanded]="tabIndex === leftMenuAccordionType.ALBUMS"
          [togglePosition]="'before'"
          [title]="'left-menu.tab-bar.sidemenus.title.albums' | translate"
          (clickOnHelpIcon)="
            $event.stopPropagation(); showHelpWindow('album', 'album')
          "
          (afterExpand)="onTabOpen(leftMenuAccordionType.ALBUMS)"
          (closed)="onTabClose()"
        >
          <fs-shared-menu
            fs-expansion-panel-accordion-header-title
            albumType="album"
            [hide]="tabIndex !== leftMenuAccordionType.ALBUMS"
          ></fs-shared-menu>
          <fs-albums fs-expansion-panel-accordion-header-content></fs-albums>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="
            currentState.displaySetting.leftMenu.items.menu.items.imageInfo
          "
          [expanded]="tabIndex === leftMenuAccordionType.IMAGE_INFO"
          [togglePosition]="'before'"
          [tooltip]="'left-menu.tab-bar.sidemenus.title.assetinfo' | translate"
          [title]="'left-menu.tab-bar.sidemenus.title.assetinfo' | translate"
          (afterExpand)="onTabOpen(leftMenuAccordionType.IMAGE_INFO)"
          (closed)="onTabClose()"
        >
          <fs-imageinfo
            fs-expansion-panel-accordion-header-content
          ></fs-imageinfo>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="
            currentState.displaySetting.leftMenu.items.menu.items.administrator
          "
          [expanded]="tabIndex === leftMenuAccordionType.ADMINISTRATOR"
          [togglePosition]="'before'"
          [title]="
            'left-menu.tab-bar.sidemenus.title.administration' | translate
          "
          (afterExpand)="onTabOpen(leftMenuAccordionType.ADMINISTRATOR)"
          (closed)="onTabClose()"
        >
          <fs-administration
            fs-expansion-panel-accordion-header-content
          ></fs-administration>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="
            userAuthority === userRole.ROLE_ADMIN || metaTool
          "
          [expanded]="tabIndex === leftMenuAccordionType.META_DATA"
          [togglePosition]="'before'"
          [title]="
            'left-menu.tab-bar.sidemenus.administration.meta-data.title' | translate
          "
          (afterExpand)="onTabOpen(leftMenuAccordionType.META_DATA)"
          (closed)="onTabClose()"
        >
          <fs-meta-data-menu
            [isExpanded]="tabIndex === leftMenuAccordionType.META_DATA"
            fs-expansion-panel-accordion-header-content
          ></fs-meta-data-menu>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="currentState.displaySetting.leftMenu.items.menu.items.audit"
          [expanded]="tabIndex === leftMenuAccordionType.AUDIT"
          [togglePosition]="'before'"
          [title]="'left-menu.tab-bar.sidemenus.title.audit' | translate"
          (afterExpand)="onTabOpen(leftMenuAccordionType.AUDIT)"
          (closed)="onTabClose()"
        >
          <fs-audit fs-expansion-panel-accordion-header-content></fs-audit>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="
            currentState.displaySetting.leftMenu.items.menu.items.signoff &&
            !isTaggerRole &&
            signoffManagement
          "
          [expanded]="tabIndex === leftMenuAccordionType.SIGN_OFF"
          [togglePosition]="'before'"
          [title]="'left-menu.tab-bar.sidemenus.title.signoff' | translate"
          (afterExpand)="onTabOpen(leftMenuAccordionType.SIGN_OFF)"
          (closed)="onTabClose()"
        >
          <fs-shared-menu
            fs-expansion-panel-accordion-header-title
            albumType="signOff"
            [hide]="tabIndex !== leftMenuAccordionType.SIGN_OFF"
          ></fs-shared-menu>
          <fs-signoff fs-expansion-panel-accordion-header-content></fs-signoff>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="
            currentState.displaySetting.leftMenu.items.menu.items.wideedit &&
            !isTaggerRole &&
            datgManagement
          "
          [expanded]="tabIndex === leftMenuAccordionType.WIDEEEDIT"
          [togglePosition]="'before'"
          [title]="'left-menu.tab-bar.sidemenus.title.wideedit' | translate"
          (afterExpand)="onTabOpen(leftMenuAccordionType.WIDEEEDIT)"
          (closed)="onTabClose()"
        >
          <fs-shared-menu
            fs-expansion-panel-accordion-header-title
            albumType="wideEdit"
            [hide]="tabIndex !== leftMenuAccordionType.WIDEEEDIT"
          ></fs-shared-menu>
          <fs-wide-edit
            fs-expansion-panel-accordion-header-content
          ></fs-wide-edit>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="currentState.displaySetting.leftMenu.items.menu.items.adhoc"
          [expanded]="tabIndex === leftMenuAccordionType.ADHOC"
          [togglePosition]="'before'"
          [title]="'left-menu.tab-bar.sidemenus.title.adhoc' | translate"
          (afterExpand)="onTabOpen(leftMenuAccordionType.ADHOC)"
          (closed)="onTabClose()"
        >
          <fs-adhoc fs-expansion-panel-accordion-header-content></fs-adhoc>
        </fs-accordion-panel>
      </mat-accordion>

      <div>
        <div
          class="show sub-menu-list admin-list"
          *ngIf="
            (isEnlarge || isSuperZoom) &&
            (taggingView1Open || taggingView2Open || auditTagViewOpen)
          "
        >
          <div>
            <fs-tag-info></fs-tag-info>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mainrole_admin" *ngIf="userAuthority === userRole.ROLE_ADMIN">
    <div class="main-role-admin-wide" *ngIf="selectedProjectValue === 4">
      <mat-accordion [displayMode]="'flat'" class="fs-accordion-dark-theme">
        <fs-accordion-panel
          *ngIf="currentState.displaySetting.leftMenu.items.menu.items.wideedit"
          [togglePosition]="'before'"
          [title]="
            'left-menu.tab-bar.sidemenus.title.administration' | translate
          "
          [expanded]="tabIndex === leftMenuAccordionType.ADMINISTRATOR"
        >
          <fs-wide-edit-administration
            fs-expansion-panel-accordion-header-content
          ></fs-wide-edit-administration>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="
            currentState.displaySetting.leftMenu.items.menu.items.wideedit &&
            !isTaggerRole
          "
          [togglePosition]="'before'"
          [title]="'left-menu.tab-bar.sidemenus.title.wideedit' | translate"
          (afterExpand)="onTabOpen(leftMenuAccordionType.WIDEEEDIT)"
          (closed)="onTabClose()"
        >
          <fs-shared-menu
            fs-expansion-panel-accordion-header-title
            albumType="wideEdit"
            [hide]="tabIndex !== leftMenuAccordionType.WIDEEEDIT"
          ></fs-shared-menu>
          <fs-wide-edit
            fs-expansion-panel-accordion-header-content
          ></fs-wide-edit>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="
            currentState.displaySetting.leftMenu.items.menu.items.imageInfo
          "
          [togglePosition]="'before'"
          [title]="'left-menu.tab-bar.sidemenus.title.assetinfo' | translate"
          (afterExpand)="onTabOpen(leftMenuAccordionType.IMAGE_INFO)"
          [expanded]="tabIndex === leftMenuAccordionType.IMAGE_INFO"
          (closed)="onTabClose()"
        >
          <fs-talent-note
            fs-expansion-panel-accordion-header-content
          ></fs-talent-note>
        </fs-accordion-panel>
      </mat-accordion>
    </div>
  </div>

  <div class="mainrole_admin" *ngIf="userAuthority === userRole.ROLE_ADMIN">
    <div class="main-role-admin-signoff" *ngIf="selectedProjectValue === 3">
      <mat-accordion [displayMode]="'flat'" class="fs-accordion-dark-theme">
        <fs-accordion-panel
          *ngIf="
            currentState.displaySetting.leftMenu.items.menu.items.signoff &&
            !isTaggerRole
          "
          [togglePosition]="'before'"
          [title]="
            'left-menu.tab-bar.sidemenus.title.administration' | translate
          "
          [expanded]="tabIndex === leftMenuAccordionType.SIGN_OFF"
        >
          <fs-signoff-administration
            fs-expansion-panel-accordion-header-content
          ></fs-signoff-administration>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="
            currentState.displaySetting.leftMenu.items.menu.items.imageInfo
          "
          [togglePosition]="'before'"
          [title]="'left-menu.tab-bar.sidemenus.title.assetinfo' | translate"
          [expanded]="tabIndex === leftMenuAccordionType.IMAGE_INFO"
        >
          <fs-talent-note
            fs-expansion-panel-accordion-header-content
          ></fs-talent-note>
        </fs-accordion-panel>
      </mat-accordion>
    </div>
  </div>

  <div
    class="main-role-talent"
    *ngIf="
      userAuthority === userRole.ROLE_EXTERNAL &&
      projectAuthority === projectRole.ROLE_TALENT
    "
  >
    <mat-accordion [displayMode]="'flat'" class="fs-accordion-dark-theme">
      <fs-accordion-panel
        *ngIf="currentState.displaySetting.leftMenu.items.menu.items.imageInfo"
        [togglePosition]="'before'"
        [title]="'left-menu.tab-bar.sidemenus.title.notes' | translate"
        [tooltip]="'left-menu.tab-bar.sidemenus.title.assetinfo' | translate"
        [expanded]="tabIndex === leftMenuAccordionType.IMAGE_INFO"
        (afterExpand)="onTabOpen(leftMenuAccordionType.IMAGE_INFO)"
        (closed)="onTabClose()"
      >
        <fs-imageinfo
          fs-expansion-panel-accordion-header-content
        ></fs-imageinfo>
      </fs-accordion-panel>
    </mat-accordion>
  </div>

  <div
    class="main-role-exec"
    *ngIf="
      userAuthority === userRole.ROLE_EXTERNAL &&
      projectAuthority === projectRole.ROLE_EXEC
    "
  >
    <div *ngIf="selectedProjectValue !== 4 && selectedProjectValue !== 3">
      <mat-accordion [displayMode]="'flat'" class="fs-accordion-dark-theme">
        <fs-accordion-panel
          class="fs-album-accordion"
          [expanded]="tabIndex === leftMenuAccordionType.ALBUMS"
          [togglePosition]="'before'"
          [title]="'left-menu.tab-bar.sidemenus.title.albums' | translate"
          (afterExpand)="onTabOpen(leftMenuAccordionType.ALBUMS)"
          (closed)="onTabClose()"
        >
          <fs-shared-menu
            fs-expansion-panel-accordion-header-title
            albumType="album"
            [hide]="tabIndex !== leftMenuAccordionType.ALBUMS"
          ></fs-shared-menu>
          <fs-albums
            *ngIf="currentState.displaySetting.leftMenu.items.menu.items.albums"
            fs-expansion-panel-accordion-header-content
          ></fs-albums>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="
            currentState.displaySetting.leftMenu.items.menu.items.imageInfo
          "
          [expanded]="tabIndex === leftMenuAccordionType.IMAGE_INFO"
          [togglePosition]="'before'"
          [tooltip]="'left-menu.tab-bar.sidemenus.title.assetinfo' | translate"
          [title]="'left-menu.tab-bar.sidemenus.title.assetinfo' | translate"
          (afterExpand)="onTabOpen(leftMenuAccordionType.IMAGE_INFO)"
          (closed)="onTabClose()"
        >
          <fs-imageinfo
            fs-expansion-panel-accordion-header-content
          ></fs-imageinfo>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="
            currentState.displaySetting.leftMenu.items.menu.items.administrator
          "
          [expanded]="tabIndex === leftMenuAccordionType.ADMINISTRATOR"
          [togglePosition]="'before'"
          [title]="
            'left-menu.tab-bar.sidemenus.title.administration' | translate
          "
          (afterExpand)="onTabOpen(leftMenuAccordionType.ADMINISTRATOR)"
          (closed)="onTabClose()"
        >
          <fs-administration
            fs-expansion-panel-accordion-header-content
          ></fs-administration>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="metaTool"
          [expanded]="tabIndex === leftMenuAccordionType.META_DATA"
          [togglePosition]="'before'"
          [title]="
            'left-menu.tab-bar.sidemenus.administration.meta-data.title' | translate
          "
          (afterExpand)="onTabOpen(leftMenuAccordionType.META_DATA)"
          (closed)="onTabClose()"
        >
          <fs-meta-data-menu
            [isExpanded]="tabIndex === leftMenuAccordionType.META_DATA"
            fs-expansion-panel-accordion-header-content
          ></fs-meta-data-menu>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="
            currentState.displaySetting.leftMenu.items.menu.items.signoff &&
            signoffManagement
          "
          [expanded]="tabIndex === leftMenuAccordionType.SIGN_OFF"
          [togglePosition]="'before'"
          [title]="'left-menu.tab-bar.sidemenus.title.signoff' | translate"
          (afterExpand)="onTabOpen(leftMenuAccordionType.SIGN_OFF)"
          (closed)="onTabClose()"
        >
          <fs-shared-menu
            fs-expansion-panel-accordion-header-title
            albumType="signOff"
            [hide]="tabIndex !== leftMenuAccordionType.SIGN_OFF"
          ></fs-shared-menu>
          <fs-signoff fs-expansion-panel-accordion-header-content></fs-signoff>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="
            currentState.displaySetting.leftMenu.items.menu.items.wideedit &&
            datgManagement
          "
          [expanded]="tabIndex === leftMenuAccordionType.WIDEEEDIT"
          [togglePosition]="'before'"
          [title]="'left-menu.tab-bar.sidemenus.title.wideedit' | translate"
          (afterExpand)="onTabOpen(leftMenuAccordionType.WIDEEEDIT)"
          (closed)="onTabClose()"
        >
          <fs-shared-menu
            fs-expansion-panel-accordion-header-title
            albumType="wideEdit"
            [hide]="tabIndex !== leftMenuAccordionType.WIDEEEDIT"
          ></fs-shared-menu>
          <fs-wide-edit
            fs-expansion-panel-accordion-header-content
          ></fs-wide-edit>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="currentState.displaySetting.leftMenu.items.menu.items.adhoc"
          [expanded]="tabIndex === leftMenuAccordionType.ADHOC"
          [togglePosition]="'before'"
          [title]="'left-menu.tab-bar.sidemenus.title.adhoc' | translate"
          (afterExpand)="onTabOpen(leftMenuAccordionType.ADHOC)"
          (closed)="onTabClose()"
        >
          <fs-adhoc fs-expansion-panel-accordion-header-content></fs-adhoc>
        </fs-accordion-panel>
      </mat-accordion>
    </div>
  </div>

  <div
    class="main-role-exec"
    *ngIf="
      userAuthority === userRole.ROLE_EXTERNAL &&
      projectAuthority === projectRole.ROLE_EXEC
    "
  >
    <div class="main-role-exec-wide" *ngIf="selectedProjectValue === 4">
      <mat-accordion [displayMode]="'flat'" class="fs-accordion-dark-theme">
        <fs-accordion-panel
          *ngIf="currentState.displaySetting.leftMenu.items.menu.items.wideedit"
          [togglePosition]="'before'"
          [title]="
            'left-menu.tab-bar.sidemenus.title.administration' | translate
          "
          [expanded]="tabIndex === leftMenuAccordionType.ADMINISTRATOR"
        >
          <fs-wide-edit-administration
            fs-expansion-panel-accordion-header-content
          ></fs-wide-edit-administration>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="currentState.displaySetting.leftMenu.items.menu.items.wideedit"
          [togglePosition]="'before'"
          [title]="'left-menu.tab-bar.sidemenus.title.wideedit' | translate"
          [expanded]="tabIndex === leftMenuAccordionType.WIDEEEDIT"
          (afterExpand)="onTabOpen(leftMenuAccordionType.WIDEEEDIT)"
          (closed)="onTabClose()"
        >
          <fs-shared-menu
            fs-expansion-panel-accordion-header-title
            albumType="wideEdit"
            [hide]="tabIndex !== leftMenuAccordionType.WIDEEEDIT"
          ></fs-shared-menu>
          <fs-wide-edit
            fs-expansion-panel-accordion-header-content
          ></fs-wide-edit>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="
            currentState.displaySetting.leftMenu.items.menu.items.imageInfo
          "
          [togglePosition]="'before'"
          [title]="'left-menu.tab-bar.sidemenus.title.assetinfo' | translate"
          [expanded]="tabIndex === leftMenuAccordionType.IMAGE_INFO"
        >
          <fs-talent-note
            fs-expansion-panel-accordion-header-content
          ></fs-talent-note>
        </fs-accordion-panel>
      </mat-accordion>
    </div>
  </div>

  <div
    class="main-role-exec"
    *ngIf="
      userAuthority === userRole.ROLE_EXTERNAL &&
      projectAuthority === projectRole.ROLE_EXEC
    "
  >
    <div class="main-role-exec-signoff" *ngIf="selectedProjectValue === 3">
      <mat-accordion [displayMode]="'flat'" class="fs-accordion-dark-theme">
        <fs-accordion-panel
          *ngIf="currentState.displaySetting.leftMenu.items.menu.items.signoff"
          [togglePosition]="'before'"
          [title]="
            'left-menu.tab-bar.sidemenus.title.administration' | translate
          "
          [expanded]="tabIndex === leftMenuAccordionType.SIGN_OFF"
        >
          <fs-signoff-administration
            fs-expansion-panel-accordion-header-content
          ></fs-signoff-administration>
        </fs-accordion-panel>

        <fs-accordion-panel
          *ngIf="
            currentState.displaySetting.leftMenu.items.menu.items.imageInfo
          "
          [togglePosition]="'before'"
          [title]="'left-menu.tab-bar.sidemenus.title.assetinfo' | translate"
          [expanded]="tabIndex === leftMenuAccordionType.IMAGE_INFO"
        >
          <fs-talent-note
            fs-expansion-panel-accordion-header-content
          ></fs-talent-note>
        </fs-accordion-panel>
      </mat-accordion>
    </div>
  </div>

  <div
    class="mainrole_admin"
    *ngIf="
      userAuthority === userRole.ROLE_EXTERNAL &&
      projectAuthority === projectRole.ROLE_ALBUMVIEWER
    "
  >
    <mat-accordion [displayMode]="'flat'" class="fs-accordion-dark-theme">
      <fs-accordion-panel
        class="fs-album-accordion"
        *ngIf="currentState.displaySetting.leftMenu.items.menu.items.albums"
        [togglePosition]="'before'"
        [title]="'left-menu.tab-bar.sidemenus.title.albums' | translate"
        [expanded]="tabIndex === leftMenuAccordionType.ALBUMS"
        (clickOnHelpIcon)="
          $event.stopPropagation(); showHelpWindow('album', 'album')
        "
        (afterExpand)="onTabOpen(leftMenuAccordionType.ALBUMS)"
        (closed)="onTabClose()"
      >
        <fs-shared-menu
          fs-expansion-panel-accordion-header-title
          albumType="album"
          [hide]="tabIndex !== leftMenuAccordionType.ALBUMS"
        ></fs-shared-menu>
        <fs-albums fs-expansion-panel-accordion-header-content></fs-albums>
      </fs-accordion-panel>

      <fs-accordion-panel
        *ngIf="currentState.displaySetting.leftMenu.items.menu.items.imageInfo"
        [togglePosition]="'before'"
        [title]="'left-menu.tab-bar.sidemenus.title.assetinfo' | translate"
        [tooltip]="'left-menu.tab-bar.sidemenus.title.assetinfo' | translate"
        [expanded]="tabIndex === leftMenuAccordionType.IMAGE_INFO"
        (afterExpand)="onTabOpen(leftMenuAccordionType.IMAGE_INFO)"
        (closed)="onTabClose()"
      >
        <fs-imageinfo
          fs-expansion-panel-accordion-header-content
        ></fs-imageinfo>
      </fs-accordion-panel>
    </mat-accordion>
  </div>
</div>
