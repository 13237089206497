import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';

import { ProjectComponent } from './project.component';
import { TopMenuModule } from './dashboard-shared/top-menu/top-menu.module';
import { PaginationModule } from './dashboard-shared/pagination/pagination.module';
import { LeftMenuModule } from './dashboard-shared/left-menu/left-menu.module';

import { ColorPickerModule } from 'ngx-color-picker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomAlbumComponent } from './project-detail/view-project/custom-album/custom-album.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { ViewProjectComponent } from './project-detail/view-project/view-project.component';
import { ImageVideoComponent } from './project-detail/view-project/image-video/image-video.component';
import { Designation } from './project-detail/view-project/designation/designation';
import { EmailNotification } from './project-detail/view-project/email-notification/email-notification';
import { ImageTransactionComponent } from './project-detail/view-project/image-transaction/image-transaction.component';
import { ImageHistoryComponent } from './project-detail/view-project/image-history/image-history.component';
import { MatMenuModule } from '@angular/material/menu';
import { BookMarkComponent } from './project-detail/view-project/book-mark/bookmark.component';
import { DrawImageCommentPopupComponent } from './project-detail/draw-image/draw-image-comment-popup/draw-image-comment-popup.component';
import { SharedModule } from '../../shared/shared.module';
import { SliderModule } from './project-detail/enlarge/slider/slider.module';
import { ViewGalleryComponent } from './project-detail/view-gallery/view-gallery.component';
import { EnlargeComponent } from './project-detail/enlarge/enlarge.component';
import { CaptionEnlargeComponent } from './project-detail/caption-enlarge/caption-enlarge.component';
import { DrawImageComponent } from './project-detail/draw-image/draw-image.component';
import { CompareComponent } from './project-detail/compare/compare.component';
import { GroupComponent } from './project-detail/view-project/group/group-component';
import { CaptionComponent } from './project-detail/caption-enlarge/caption/caption.component';
import { GroupSingleComponent } from './project-detail/view-project/group-single/group-single.component';
import { TaggingAuditComponentComponent } from './project-detail/view-project/tagger-panel/tagging-audit-component/tagging-audit-component.component';
import { TitleForReviewPanelComponentComponent } from './project-detail/view-project/tagger-panel/title-for-review-panel-component/title-for-review-panel-component.component';
import { TagNameTitleComponentComponent } from './project-detail/view-project/tagger-panel/tag-name-title-component/tag-name-title-component.component';
import { ImageVideoPanelComponentComponent } from './project-detail/view-project/tagger-panel/image-video-panel-component/image-video-panel-component.component';
import { GroupIconComponentComponent } from './project-detail/view-project/tagger-panel/group-icon-component/group-icon-component.component';
import { CharIconComponentComponent } from './project-detail/view-project/tagger-panel/char-icon-component/char-icon-component.component';
import { TaggerPanelComponentComponent } from './project-detail/view-project/tagger-panel/tagger-panel-component/tagger-panel-component.component';
import { PublishingPanelComponentComponent } from './project-detail/view-project/tagger-panel/publishing-panel-component/publishing-panel-component.component';
import { ZoomIconComponentComponent } from './project-detail/view-project/tagger-panel/zoom-icon-component/zoom-icon-component.component';
import { ChatIconComponentComponent } from './project-detail/view-project/tagger-panel/chat-icon-component/chat-icon-component.component';
import { NoteIconComponentComponent } from './project-detail/view-project/tagger-panel/note-icon-component/note-icon-component.component';
import { ImageDocumentPanelComponentComponent } from './project-detail/view-project/tagger-panel/image-document-panel-component/image-document-panel-component.component';
import { RetouchIconComponentComponent } from './project-detail/view-project/tagger-panel/retouch-icon-component/retouch-icon-component.component';
import { ExclusiveHistoryIconComponentComponent } from './project-detail/view-project/tagger-panel/exclusive-history-icon-component/exclusive-history-icon-component.component';
import { HistoryIconComponentComponent } from './project-detail/view-project/tagger-panel/history-icon-component/history-icon-component.component';
import { TransactionsIconComponentComponent } from './project-detail/view-project/tagger-panel/transactions-icon-component/transactions-icon-component.component';
import { SoloIconComponentComponent } from './project-detail/view-project/tagger-panel/solo-icon-component/solo-icon-component.component';
import { PriorityPixIconComponentComponent } from './project-detail/view-project/tagger-panel/priority-pix-icon-component/priority-pix-icon-component.component';
import { AlbumFolderIconComponentComponent } from './project-detail/view-project/tagger-panel/album-folder-icon-component/album-folder-icon-component.component';
import { ViewProjectSoloIconComponentComponent } from './project-detail/view-project/tagger-panel/view-project-solo-icon-component/view-project-solo-icon-component.component';
import { AddKeyCaptionComponentComponent } from './project-detail/caption-enlarge/caption/add-key-caption-component/add-key-caption-component.component';
import { EditKeyCaptionComponentComponent } from './project-detail/caption-enlarge/caption/edit-key-caption-component/edit-key-caption-component.component';
import { RemoveKeyCaptionComponentComponent } from './project-detail/caption-enlarge/caption/remove-key-caption-component/remove-key-caption-component.component';
import { NoteMenuComponent } from './project-detail/view-project/tagger-panel/note-menu-component/note-mat-menu.component';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ViewProjectSoloIconWithBorderComponent } from './project-detail/view-project/tagger-panel/view-project-solo-icon-with-border.component/view-project-solo-icon-with-border.component';
import { CharIconWithBorderComponent } from './project-detail/view-project/tagger-panel/char-icon-with-border/char-icon-with-border.component';
import { ViewProjectPageComponent } from './project-detail/view-project/view-project-page/view-project-page.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TransactionReportDetailsComponent } from './project-detail/view-project/image-transaction/image-transaction-details/transaction-report-details/transaction-report-details.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MetadataComponent } from './project-detail/view-project/metadata/metadata.component';

@NgModule({
  imports: [
    NgxPaginationModule,
    TopMenuModule,
    PaginationModule,
    BrowserAnimationsModule,
    LeftMenuModule,
    SharedModule,
    SliderModule,
    MatStepperModule,
    ColorPickerModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    CommonModule,
    MatTooltipModule,
    MatSidenavModule,
  ],
  declarations: [
    ProjectComponent,
    ViewGalleryComponent,
    ViewProjectComponent,
    EnlargeComponent,
    CaptionEnlargeComponent,
    DrawImageComponent,
    CompareComponent,
    GroupComponent,
    ImageHistoryComponent,
    ImageTransactionComponent,
    CaptionComponent,
    GroupSingleComponent,
    CustomAlbumComponent,
    EmailNotification,
    Designation,
    BookMarkComponent,
    ImageVideoComponent,
    DrawImageCommentPopupComponent,
    TaggingAuditComponentComponent,
    TitleForReviewPanelComponentComponent,
    TagNameTitleComponentComponent,
    ImageVideoPanelComponentComponent,
    GroupIconComponentComponent,
    CharIconComponentComponent,
    TaggerPanelComponentComponent,
    PublishingPanelComponentComponent,
    ZoomIconComponentComponent,
    ChatIconComponentComponent,
    NoteIconComponentComponent,
    ImageDocumentPanelComponentComponent,
    RetouchIconComponentComponent,
    ExclusiveHistoryIconComponentComponent,
    HistoryIconComponentComponent,
    TransactionsIconComponentComponent,
    SoloIconComponentComponent,
    ViewProjectSoloIconWithBorderComponent,
    PriorityPixIconComponentComponent,
    AlbumFolderIconComponentComponent,
    ViewProjectSoloIconComponentComponent,
    AddKeyCaptionComponentComponent,
    EditKeyCaptionComponentComponent,
    RemoveKeyCaptionComponentComponent,
    NoteMenuComponent,
    ViewProjectPageComponent,
    CharIconWithBorderComponent,
    TransactionReportDetailsComponent,
    MetadataComponent,
  ],
  providers: [],
  exports: [ViewProjectComponent],
})
export class ProjectModule {}
