<div class="list-unstyled components sideBar-menu leftsideMenuBarSecond">
  <div class="right-content">
    <div class="sub-menu-list d-block">
      <div class="tree-parent">
        <div class="tree-inner">
          <tree-root
            #tree
            [nodes]="adhocNode"
            [options]="options1"
            (event)="onEvent($event)"
          >
            <ng-template
              #treeNodeTemplate
              let-node
              let-index="index"
              name="name"
            >
              <div class="folder_drop_cont">
                <i
                  fsImagebuttonhover
                  class="fa fa-folder"
                  aria-hidden="true"
                  (click)="onClickAlbum(index)"
                  [ngClass]="{
                    'fs-button-selected': activeAlbumFolder.id == node.data.id
                  }"
                  [id]="node.data.id"
                >
                </i>

                <label
                  fsImagebuttonhover
                  class="folder-name-count"
                  (click)="onClickAlbumName(index)"
                >
                  {{ node.data.value }} ({{ node.data.assetCount }})
                </label>
              </div>
            </ng-template>
          </tree-root>
        </div>
      </div>
    </div>
  </div>
</div>
