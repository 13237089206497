import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fs-retouch-icon-component',
  templateUrl: './retouch-icon-component.component.html',
  styleUrls: ['./retouch-icon-component.component.scss'],
})
export class RetouchIconComponentComponent {
  @Input() retouchAccess;
  @Input() isTaggerRole: boolean;
  @Input() assetType;
  @Input() imgData;
  @Input() projectDetailPermission;
  @Output() drawImage = new EventEmitter();
}
