<div [hidden]="!(data && data.visible)">
  <div
    id="iframModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="fsModaliframe"
    aria-hidden="true"
  >
    <div>
      <div
        cdkDrag
        cdkDragRootElement=".cdk-overlay-pane"
        cdkDragHandle
        mat-dialog-title
        class="fs-cursor-move d-flex justify-content-between cursor_move"
      >
        <div class="modal-title">XPress Download - Powered by Signiant</div>
        <div class="d-flex ml-2">
          <button
            type="button"
            class="fs-close-btn w-auto ml-3"
            aria-hidden="true"
          >
            <i class="fa fa-minus-circle"></i>
          </button>
          <button
            type="button"
            (click)="closeWindow()"
            class="fs-close-btn w-auto ml-3"
            aria-hidden="true"
          >
            <i class="fa fa-times-circle"></i>
          </button>
        </div>
      </div>

      <div mat-dialog-content id="iframeDiv">
        <iframe
          class="embed-responsive-item"
          id="xpress-download-iframe"
          [src]="data.url | safe : 'url'"
          allowfullscreen
        >
        </iframe>
      </div>
    </div>
  </div>
</div>
