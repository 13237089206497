import { DownloadManagerService } from './../../../../../shared/layout/download-manager/download-manager.service';
import { Component, ElementRef, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Modalstatusservice } from '../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';

@Component({
  selector: 'fs-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
})
export class IFrameComponent {
  constructor(
    private elementRef: ElementRef,
    private modalService: Modalstatusservice,
    public viewProjectService: ViewProjectService,
    private downloadManagerService: DownloadManagerService,
    private dialogRef: MatDialogRef<IFrameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  closeWindow() {
    this.dialogRef.close();
    const downloadItems = this.downloadManagerService.getDownloadItems();
    downloadItems.forEach((data, index) => {
      if (data.id === this.data.id) {
        downloadItems.splice(index, 1);
      }
    });
    this.downloadManagerService.setIsDownloadEnabled(false);
    this.downloadManagerService.setDownloadItems([...downloadItems]);

    this.dialogRef.close();
  }
}
