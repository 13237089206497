<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.taggingview-1.removal'
        | translate
    }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        {{ selectedAssestIdList.length }}
        {{
          'left-menu.tab-bar.sidemenus.administration.taggingview-1.selected'
            | translate
        }}
      </div>

      <div class="col-12" id="add_new_item_drp">
        <mat-select
          class="fs-selectbox-dark pl-2 pr-3"
          required
          disableOptionCentering
          panelClass="fs-tag-option-position-panel fs-option-position-panel"
          [placeholder]="'Select Tag'"
          [value]="taggingListModel"
          (valueChange)="taggingListModel = $event"
        >
          <mat-option
            class="fs-tiny-txt fs-clear-tag-selectbox fs-selectbox-options pl-1"
            *ngFor="let tag of taggingList"
            [value]="tag"
          >
            {{ tag.value }}
          </mat-option>
        </mat-select>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <button
    class="fs-dialog-btn w-auto ml-3"
    data-toggle="modal"
    data-target="#sda_aar"
    (click)="closeWindow()"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.taggingview-1.button.close'
        | translate
    }}
  </button>

  <button
    class="fs-dialog-btn w-auto ml-3"
    data-toggle="modal"
    data-target="#sda_aar"
    (click)="onClickedonExecute()"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.taggingview-1.button.execute'
        | translate
    }}
  </button>
</div>
