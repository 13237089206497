import {
  Directive,
  ElementRef,
  Renderer2,
  HostListener,
  Input,
  HostBinding,
} from '@angular/core';

@Directive({
  selector: '[fsPasswordVisibility]',
})
export class PasswordVisibilityDirective {
  @Input() inputDisabled: boolean;

  private isVisible = false;
  private eyeIcon: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.addEyeIcon();
    this.secureInputField();
  }

  @HostBinding('attr.disabled')
  get isDisabled(): string {
    this.disableEyeIcon();
    return this.inputDisabled ? 'disabled' : null;
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const isEyeIcon = target === this.eyeIcon;
    if (isEyeIcon) {
      this.toggleVisibility();
    }
  }

  private toggleVisibility(): void {
    this.isVisible = !this.isVisible;
    this.updateInputType();
    this.updateEyeIcon();
  }

  private updateInputType(): void {
    this.isVisible ? this.unSecureInputField() : this.secureInputField();
  }

  private unSecureInputField(): void {
    const input = this.el.nativeElement;
    this.renderer.removeStyle(input, '-webkit-text-security');
    this.renderer.removeStyle(input, 'text-security');
  }

  private secureInputField(): void {
    const input = this.el.nativeElement;
    this.renderer.setStyle(input, '-webkit-text-security', 'disc');
    this.renderer.setStyle(input, 'text-security', 'disc');
  }

  private updateEyeIcon(): void {
    const eyeIconClass = this.isVisible ? 'fa-eye-slash' : 'fa-eye';
    this.renderer.removeClass(this.eyeIcon, 'fa-eye');
    this.renderer.removeClass(this.eyeIcon, 'fa-eye-slash');
    this.renderer.addClass(this.eyeIcon, eyeIconClass);
  }

  private disableEyeIcon(): void {
    if (!this.inputDisabled) {
      this.renderer.setStyle(this.eyeIcon, 'display', 'inline-block');
    } else {
      this.renderer.setStyle(this.eyeIcon, 'display', 'none');
    }
  }

  private addEyeIcon(): void {
    this.eyeIcon = this.renderer.createElement('i');
    this.renderer.addClass(this.eyeIcon, 'eye-icon');
    this.renderer.addClass(this.eyeIcon, 'fa');
    this.renderer.addClass(this.eyeIcon, 'fa-eye');
    this.renderer.addClass(this.eyeIcon, 'fs-eye-icon-container');
    this.renderer.appendChild(
      this.el.nativeElement.parentElement,
      this.eyeIcon,
    );

    this.renderer.listen(this.eyeIcon, 'click', () => {
      this.toggleVisibility();
    });
  }
}
