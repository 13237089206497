import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'fs-remove-key-caption-component',
  templateUrl: './remove-key-caption-component.component.html',
  styleUrls: ['./remove-key-caption-component.scss'],
})
export class RemoveKeyCaptionComponentComponent {
  static identifier = 'RemoveKeyCaptionComponentComponent';
  captionKeys;
  onFilterKeysChangeFn: (e: {
    e: MatCheckboxChange;
    removeKey: number;
  }) => void;
  removeKeyFn: () => void;

  constructor(
    private dialogRef: MatDialogRef<RemoveKeyCaptionComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.onFilterKeysChangeFn = data.onFilterKeysChange;
    this.removeKeyFn = data.removeKey;
    this.captionKeys = data.captionKeys;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
