import { Directive, EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettings } from '../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../shared/services/api.service';

@Directive()
@Injectable()
export class WideEditService {
  @Output() copyWideEdit: EventEmitter<any> = new EventEmitter();

  constructor(private apiService: ApiService) {}

  addWideEditAlbum(album: any) {
    this.copyWideEdit.emit(album);
  }

  putAlbumStatus(obj): Observable<any> {
    const path = AppSettings.ALBUM_STATUS;
    return this.apiService.put(path, obj).pipe(
      map((res) => {
        return res;
      }),
    );
  }
}
