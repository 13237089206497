import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CurrentStateService {
  private currentState = new BehaviorSubject<any>({});
  getCurrentState = this.currentState.asObservable();

  private currentUserState = new BehaviorSubject<any>({});
  getCurrentUserState = this.currentUserState.asObservable();

  setCurrentState(state: any) {
    this.currentState.next(state);
  }

  setCurrentUserState(state: any) {
    this.currentUserState.next(state);
  }
}
