import { IHandler } from '../../../interfaces/dashboard-handler.model';
import { DashboardLauncherFavouriteIconRuleset } from '../rule-set/dashboard-launcher-favourite-icon-ruleset';
import { FavSelectedProjectModel } from '../../dashboard-favourite-project/interfaces/dashboard-favourite-icon-rule.model';

export class DashboardLauncherFavouriteIconHandler implements IHandler {
  constructor(
    private active: boolean,
    private selectedProject: FavSelectedProjectModel,
    private setHeightToProjectListContainerFn: () => void,
    private getFavClassFn: (count: number) => void,
  ) {}

  handle() {
    return new DashboardLauncherFavouriteIconRuleset(
      this.active,
      this.selectedProject,
      this.setHeightToProjectListContainerFn,
      this.getFavClassFn,
    ).apply();
  }
}
