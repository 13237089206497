import { IHandler } from '../../../interfaces/dashboard-handler.model';
import { FavSelectedProjectModel } from '../../dashboard-favourite-project/interfaces/dashboard-favourite-icon-rule.model';
import { DashboardDepartmentFavouriteIconRuleset } from '../rule-set/dashboard-department-favourite-icon-ruleset';

export class DashboardDepartmentFavouriteIconHandler implements IHandler {
  constructor(
    private active: boolean,
    private selectedProject: FavSelectedProjectModel,
  ) {}

  handle() {
    return new DashboardDepartmentFavouriteIconRuleset(
      this.active,
      this.selectedProject,
    ).apply();
  }
}
