import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettings } from '../../../appSettings/appSettings';
import { ApiService } from '../../../shared/services/api.service';

@Injectable()
export class SidebarService {
  private projectDetailData = new BehaviorSubject<any>({});
  albumImageDataGlobal = this.projectDetailData.asObservable();

  private albumListData = new BehaviorSubject<any>({});
  albumList = this.albumListData.asObservable();

  private updatedAlbumListData = new BehaviorSubject<any>({});
  updatedAlbumList = this.updatedAlbumListData.asObservable();

  private updatedProjectAlbumListData = new BehaviorSubject<any>({});
  updatedProjectAlbumList = this.updatedProjectAlbumListData.asObservable();

  private albumName = new BehaviorSubject<any>({});
  selectedAlbumName = this.albumName.asObservable();

  private currentSelectedAlbum = new BehaviorSubject<any>({});
  selectedAlbum = this.currentSelectedAlbum.asObservable();

  private albumPermission = new BehaviorSubject<any>({});
  selectedAlbumPermission = this.albumPermission.asObservable();

  private albumKeys = new BehaviorSubject<any>([]);
  selectedAlbumKeys = this.albumKeys.asObservable();

  private projectAlbumKeys = new BehaviorSubject<any>([]);
  projectAlbumKeysGlobal = this.projectAlbumKeys.asObservable();

  private updateTaggingAlbumSubject = new BehaviorSubject<any>({});
  updateTaggingCustomAlbum = this.updateTaggingAlbumSubject.asObservable();

  private updateTaggingWideEdit = new BehaviorSubject<any>({});
  updateTaggingWideEditGlobal = this.updateTaggingWideEdit.asObservable();

  private updateTaggingProjectAlbumSubject = new BehaviorSubject<any>({});
  updateTaggingProjectAlbumGlobal =
    this.updateTaggingProjectAlbumSubject.asObservable();

  private advanceImageToAlbumID = new BehaviorSubject<any>({});
  advanceImageToAlbumIDGlobal = this.advanceImageToAlbumID.asObservable();

  private advanceImageToWideEditAlbumID = new BehaviorSubject<any>({});
  advanceImageToAlbumWideEditIDGlobal =
    this.advanceImageToWideEditAlbumID.asObservable();

  private updateTaggingWideEditSubject = new BehaviorSubject<any>({});
  updateTaggingWideEditAlbum = this.updateTaggingWideEditSubject.asObservable();

  private updateTaggingSignOffSubject = new BehaviorSubject<any>({});
  updateTaggingSignOffAlbum = this.updateTaggingSignOffSubject.asObservable();

  private hoverAlbumSubject = new BehaviorSubject<any>({});
  hoverAlbumGlobal = this.hoverAlbumSubject.asObservable();

  private hoverAlbumClickedSubject = new BehaviorSubject<any>({});
  hoverAlbumClickedGlobal = this.hoverAlbumClickedSubject.asObservable();

  constructor(private apiService: ApiService) {}

  setAlbumList(albumList) {
    this.albumListData.next(albumList);
  }

  setUpdatedAlbumList(albumList) {
    this.updatedAlbumListData.next(albumList);
  }

  setUpdatedProjectAlbumList(albumList) {
    this.updatedProjectAlbumListData.next(albumList);
  }

  resetAlbumListData() {
    this.albumListData.next({});
  }

  setAlbumImageData(albumList) {
    this.projectDetailData.next(albumList);
  }

  resetAlbumImageData() {
    this.projectDetailData.next({});
  }

  setSelectedAlbumPermission(data) {
    this.albumPermission.next(data);
  }

  setSelectedAlbumName(albumName) {
    this.albumName.next(albumName);
  }

  setSelectedAlbum(data) {
    this.currentSelectedAlbum.next(data);
  }

  setDeSelectedAlbum(data) {
    this.currentSelectedAlbum.next(data);
  }

  setSelectedAlbumKeys(folderName) {
    this.albumKeys.next(folderName);
  }

  setSelectedProjectAlbumKeys(folderName) {
    this.projectAlbumKeys.next(folderName);
  }

  setDeSelectedAlbumKeys(folderName) {
    this.albumKeys.next(folderName);
  }

  callAPIToTaggingAlbumData(data): Observable<any> {
    const path = AppSettings.ALBUM_TAGGING;
    return this.apiService.post(path, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  setAdvanceImageToAlbumID(id) {
    this.advanceImageToAlbumID.next(id);
  }

  setAdvanceImageToWideEditAlbumID(id) {
    this.advanceImageToWideEditAlbumID.next(id);
  }

  setUpdatedTaggingAlbum(albumdata) {
    this.updateTaggingAlbumSubject.next(albumdata);
  }

  setUpdatedTaggingWideEdit(albumdata) {
    this.updateTaggingWideEdit.next(albumdata);
  }

  setUpdatedTaggingProjectAlbum(albumdata) {
    this.updateTaggingProjectAlbumSubject.next(albumdata);
  }

  setUpdatedTaggingWideEditAlbum(albumdata) {
    this.updateTaggingWideEditSubject.next(albumdata);
  }

  setUpdatedTaggingSignOffAlbum(albumdata) {
    this.updateTaggingSignOffSubject.next(albumdata);
  }

  setHoverAlbum(album) {
    this.hoverAlbumSubject.next(album);
  }

  removeHoverAlbum() {
    this.hoverAlbumSubject.next({});
  }

  setHoverAlbumClicked(album) {
    this.hoverAlbumClickedSubject.next(album);
  }

  removeHoverAlbumClicked() {
    this.hoverAlbumClickedSubject.next({});
  }
}
