import { DashboardSelectProjectRuleset } from '../rule-set/dashboard-select-project-ruleset';
import {
  SelectedProjectModel,
  SelectedProjectTypeModel,
} from '../interfaces/dashboard-select-project-rule.model';
import { IHandler } from '../../../interfaces/dashboard-handler.model';

export class DashboardSelectProjectHandler implements IHandler {
  constructor(
    private currentSelectedProjectData: SelectedProjectModel,
    private selectedProject: string,
    private selectedProjectType: SelectedProjectTypeModel,
    private selectedAlbum: SelectedProjectModel,
  ) {}

  handle() {
    return new DashboardSelectProjectRuleset(
      this.currentSelectedProjectData,
      this.selectedProject,
      this.selectedProjectType,
      this.selectedAlbum,
    ).apply();
  }
}
