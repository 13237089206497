import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettings } from '../../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../../shared/services/api.service';

@Injectable()
export class ConflictsService {
  constructor(private apiService: ApiService) {}

  getAuditTaggStart(projectId): Observable<any> {
    const path = AppSettings.AUDIT_START(projectId);
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }
}
