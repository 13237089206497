<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.meta-data.title' | translate
    }}
  </div>
  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content class="theme-form d-flex flex-column">
  <div *ngIf="metaDataService.isSingleAssetSelected()" class="d-flex justify-content-center mb-3">
    <fs-paginator
      [pageIndex]="currentIndex + 1"
      [length]="projectImageIDs.length"
      [pageSize]="projectImageIDs.length"
      [isFirstElement]="metaDataService.isFirstAssetSelected(data.assetInfoDTOList[0].id)"
      [isLastElement]="metaDataService.isLastAssetSelected(data.assetInfoDTOList[0].id)"
      (goToFirst)="navigateToAsset(assetNavigateActionType.First)"
      (goToLast)="navigateToAsset(assetNavigateActionType.Last)"
      (goToPrevious)="navigateToAsset(assetNavigateActionType.Previous)"
      (goToNext)="navigateToAsset(assetNavigateActionType.Next)"
    ></fs-paginator>
  </div>

  <div class="d-flex fs-meta-data-container">
    <div class="col-5">
      <ng-scrollbar autoHeightDisabled="false" trackY="'true'">
        <ng-container
          *ngIf="selectedImagesList?.length === 1"
          [ngTemplateOutlet]="singleImage"
          [ngTemplateOutletContext]="{ imgSize: 700 }"
        >
        </ng-container>
        <ng-container
          *ngIf="selectedImagesList?.length > 1"
          [ngTemplateOutlet]="multipleImages"
          [ngTemplateOutletContext]="{ imgSize: 200 }"
        >
        </ng-container>
      </ng-scrollbar>
    </div>
    <div class="col-7 fs-scroll-container">
      <ng-scrollbar autoHeightDisabled="false" trackY="'true'">
        <form class="mr-4" [formGroup]="metadataForm">
          <div class="form-group">
            <div class="fs-txt-white-dark-color">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.creator'
                | translate
              }}
            </div>
            <div>
              <input
                class="chover"
                [placeholder]="
                placeholderText(
                  'left-menu.tab-bar.sidemenus.administration.meta-data.placeholder.creator'
                )
              "
                (input)="trimWhitespace($event, 'creator')"
                formControlName="creator"
                type="text"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="fs-txt-white-dark-color">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.credit'
                | translate
              }}
            </div>
            <div>
              <input
                class="chover"
                [placeholder]="
                placeholderText(
                  'left-menu.tab-bar.sidemenus.administration.meta-data.placeholder.credit'
                )
              "
                (input)="trimWhitespace($event, 'credit')"
                formControlName="credit"
                type="text"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="fs-txt-white-dark-color">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.title'
                | translate
              }}
            </div>
            <div>
              <input
                class="chover"
                [placeholder]="
                placeholderText(
                  'left-menu.tab-bar.sidemenus.administration.meta-data.placeholder.title'
                )
              "
                (input)="trimWhitespace($event, 'title')"
                formControlName="title"
                type="text"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="fs-txt-white-dark-color">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.description-caption'
                | translate
              }}
            </div>
            <div>
              <input
                class="chover"
                [placeholder]="
                placeholderText(
                  'left-menu.tab-bar.sidemenus.administration.meta-data.placeholder.description-caption'
                )
              "
                (input)="trimWhitespace($event, 'description')"
                formControlName="description"
                type="text"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="fs-txt-white-dark-color">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.source'
                | translate
              }}
            </div>
            <div>
              <input
                class="chover"
                [placeholder]="
                placeholderText(
                  'left-menu.tab-bar.sidemenus.administration.meta-data.placeholder.source'
                )
              "
                (input)="trimWhitespace($event, 'source')"
                formControlName="source"
                type="text"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="fs-txt-white-dark-color">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.copyright-status'
                | translate
              }}
            </div>
            <div>
              <mat-select
                class="fs-selectbox-dark d-flex"
                [placeholder]="placeholderText(
                  'left-menu.tab-bar.sidemenus.administration.meta-data.placeholder.copyright-status'
                )
            "
                formControlName="marked"
                [value]="metadataForm.get('marked').value"
              >
                <mat-option
                  class="fs-sm-txt fs-selectbox-options"
                  *ngFor="let copyrightStatus of copyrightStatuses"
                  [value]="copyrightStatus.value"
                >
                  {{ copyrightStatus.label }}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="form-group">
            <div class="fs-txt-white-dark-color">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.copyright-notice'
                | translate
              }}
            </div>
            <div>
              <input
                class="chover"
                [placeholder]="
                placeholderText(
                  'left-menu.tab-bar.sidemenus.administration.meta-data.placeholder.copyright-notice'
                )
              "
                (input)="trimWhitespace($event, 'copyrightNotice')"
                formControlName="copyrightNotice"
                type="text"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="fs-txt-white-dark-color">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.keywords'
                | translate
              }}
            </div>
            <div class="fs-chips">
              <mat-form-field
                floatLabel="never"
                class="fs-chip-list"
                appearance="fill"
              >
                <mat-chip-grid #chipGrid class="ml-2">
                  <ng-container>
                    <mat-chip-row
                      *ngFor="
                      let keyword of metadataForm.controls.keywords.value;
                      let i = index
                    "
                      (removed)="remove(keyword)"
                      (edited)="edit(keyword, $event)"
                      [removable]="true"
                    >
                      {{ keyword }}
                      <button
                        matChipRemove
                        [attr.aria-label]="'remove ' + keyword"
                      >
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip-row>
                  </ng-container>

                  <input
                    matInput
                    [placeholder]="
                    'left-menu.tab-bar.sidemenus.administration.meta-data.placeholder.new-keyword'
                      | translate
                  "
                    [matChipInputFor]="chipGrid"
                    [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="addKeyWord($event)"
                  />
                </mat-chip-grid>
              </mat-form-field>
            </div>
          </div>
          <div class="form-group">
            <div class="fs-txt-white-dark-color">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.persons-shown'
                | translate
              }}
            </div>
            <div class="fs-mat-chip-wrapper p-2">
              <div class="d-flex flex-wrap" cdkDropListGroup>
                <div
                  cdkDropList
                  (cdkDropListEntered)="onDropListEntered($event)"
                  (cdkDropListDropped)="onDropListDropped()"
                ></div>
                <div
                  *ngIf="metadataForm.get('personInImage').value.length === 0"
                  class="text-uppercase fs-text-color-shade-gray"
                >
                  {{
                  personShownPlaceholderText(
                    'left-menu.tab-bar.sidemenus.administration.meta-data.fields.persons-shown'
                  )
                  }}
                </div>
                <div
                  cdkDropList
                  (cdkDropListEntered)="onDropListEntered($event)"
                  (cdkDropListDropped)="onDropListDropped()"
                  *ngFor="let item of metadataForm.get('personInImage').value"
                >
                  <div cdkDrag class="fs-chip-person-element">{{ item }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pb-2 form-group">
            <div class="fs-txt-white-dark-color">
              {{
              'left-menu.tab-bar.sidemenus.administration.meta-data.fields.headline'
                | translate
              }}
            </div>
            <div>
              <input
                class="chover"
                [placeholder]="
                placeholderText(
                  'left-menu.tab-bar.sidemenus.administration.meta-data.placeholder.headline'
                )
              "
                (input)="trimWhitespace($event, 'headline')"
                formControlName="headline"
                type="text"
              />
            </div>
          </div>
        </form>
      </ng-scrollbar>
    </div>
  </div>
</div>

<div mat-dialog-actions class="mf-sngle d-flex justify-content-end">
  <div class="d-flex">
    <ng-container>
      <button class="fs-dialog-btn w-auto ml-3" (click)="onSave()">
        {{
        'left-menu.tab-bar.sidemenus.administration.meta-data.button.apply'
          | translate
        }}
      </button>
    </ng-container>
    <ng-container>
      <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
        {{
        'left-menu.tab-bar.sidemenus.administration.meta-data.button.close'
          | translate
        }}
      </button>
    </ng-container>
  </div>
</div>

<ng-template #singleImage let-img="img" let-imgSize="imgSize">
  <div
    class="image-container single-image-container d-flex justify-content-center align-items-center"
    *ngFor="let img of selectedImagesList"
  >
    <ng-container
      [ngTemplateOutlet]="assetTypeTmp"
      [ngTemplateOutletContext]="{ img: img, imgSize: imgSize }"
    >
    </ng-container>
  </div>
</ng-template>

<ng-template #multipleImages let-imgSize="imgSize">
  <div class="fs-display-image d-flex flex-wrap">
    <div class="image-container p-2" *ngFor="let img of selectedImagesList">
      <ng-container
        [ngTemplateOutlet]="assetTypeTmp"
        [ngTemplateOutletContext]="{ img: img, imgSize: imgSize }"
      >
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #assetTypeTmp let-img="img" let-imgSize="imgSize">
  <div class="btm-images" [ngSwitch]="img.assetType">
    <div
      class="img-sngl d-flex flex-column align-items-center text-center"
      *ngSwitchCase="assetType.IMAGE"
    >
      <ng-container
        [ngTemplateOutlet]="imageUrl"
        [ngTemplateOutletContext]="{ img: img, imgSize: imgSize }"
      >
      </ng-container>
      <ng-container
        [ngTemplateOutlet]="imageDescription"
        [ngTemplateOutletContext]="{ img: img }"
      >
      </ng-container>
    </div>
    <div
      class="img-sngl d-flex flex-column align-items-center text-center"
      *ngSwitchCase="assetType.VIDEO"
    >
      <ng-container
        [ngTemplateOutlet]="imageUrl"
        [ngTemplateOutletContext]="{ img: img, imgSize: imgSize }"
      >
      </ng-container>
      <ng-container
        [ngTemplateOutlet]="imageDescription"
        [ngTemplateOutletContext]="{ img: img }"
      >
      </ng-container>
    </div>

    <div
      class="img-sngl d-flex flex-column align-items-center text-center"
      *ngSwitchCase="assetType.DOCX"
    >
      <ng-container
        [ngTemplateOutlet]="imageType"
        [ngTemplateOutletContext]="{ imgType: '/assets/images/doc_icon.png' }"
      >
      </ng-container>
      <ng-container
        [ngTemplateOutlet]="imageDescription"
        [ngTemplateOutletContext]="{ img: img }"
      >
      </ng-container>
    </div>

    <div
      class="img-sngl d-flex flex-column align-items-center text-center"
      *ngSwitchCase="assetType.PDF"
    >
      <ng-container
        [ngTemplateOutlet]="imageType"
        [ngTemplateOutletContext]="{ imgType: '/assets/images/pdf_icon.png' }"
      >
      </ng-container>
      <ng-container
        [ngTemplateOutlet]="imageDescription"
        [ngTemplateOutletContext]="{ img: img }"
      >
      </ng-container>
    </div>

    <div
      class="img-sngl d-flex flex-column align-items-center text-center"
      *ngSwitchCase="assetType.ZIP"
    >
      <ng-container
        [ngTemplateOutlet]="imageType"
        [ngTemplateOutletContext]="{ imgType: '/assets/images/zip_icon.png' }"
      >
      </ng-container>
      <ng-container
        [ngTemplateOutlet]="imageDescription"
        [ngTemplateOutletContext]="{ img: img }"
      >
      </ng-container>
    </div>

    <div
      class="img-sngl d-flex flex-column align-items-center text-center"
      *ngSwitchCase="assetType.XLSX"
    >
      <ng-container
        [ngTemplateOutlet]="imageType"
        [ngTemplateOutletContext]="{ imgType: '/assets/images/xls_icon.png' }"
      >
      </ng-container>
      <ng-container
        [ngTemplateOutlet]="imageDescription"
        [ngTemplateOutletContext]="{ img: img }"
      >
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #imageDescription let-img="img">
  <div class="fs-image-name w-100 mt-1">{{ img.name }}</div>
</ng-template>

<ng-template #imageUrl let-img="img" , let-imgSize="imgSize">
  <div
    [class.fs-single-asset]="selectedImagesList.length === 1"
    class="is-img overflow-hidden d-flex"
  >
    <img
      class="w-100 fs-single-image"
      loading="lazy"
      [lazySrc]="viewProjectService.getWebImageURL(img, imgSize)"
      alt=""
    />
  </div>
</ng-template>

<ng-template #imageType let-imgType="imgType">
  <div class="is-img overflow-hidden d-flex align-items-center">
    <img [src]="imgType" alt="" />
  </div>
</ng-template>
