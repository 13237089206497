import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import EntityState = Store.EntityState;
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import ID = Store.ID;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Store {
  export interface EntityState<T> {
    ids: ID[];
    entities: Record<ID, T>;
  }

  export const isEntityId = (id: any): id is ID =>
    typeof id === 'string' || typeof id === 'number';

  export type ID = string | number;
}

import isEntityId = Store.isEntityId;

const getCachedCollection = function <T>(
  initialState: EntityState<T>,
  collection: T[],
  toArrayFn: (state: EntityState<T>) => T[],
) {
  let prevState = initialState;
  let cachedCollection = collection;
  return function (state: EntityState<T>) {
    if (prevState.entities === state.entities) {
      return cachedCollection;
    }
    prevState = state;
    cachedCollection = toArrayFn(state);
    return cachedCollection;
  };
};

export class EntityStore<T> {
  private state: BehaviorSubject<EntityState<T>>;
  private readonly ENTITY_IDENTIFIER_KEY = 'id';
  private readonly getCachedCollectionFn: (state: EntityState<T>) => T[];
  private get state$(): Observable<EntityState<T>> {
    return this.state.asObservable().pipe(distinctUntilChanged());
  }

  constructor(initialState: T[] = []) {
    this.state = new BehaviorSubject<EntityState<T>>(
      this.toEntitiesMap(initialState),
    );
    this.getCachedCollectionFn = getCachedCollection<T>(
      this.getState(),
      initialState,
      this.toCollection.bind(this),
    );
  }

  update(id: ID, patch: Partial<T>): void {
    const state = this.getState();

    if (this.hasEntity(id, state.entities)) {
      const newIds = [];
      const newEntities = {};

      for (const entityId of state.ids) {
        const currentEntity = state.entities[entityId] || ({} as T);

        if (entityId === id) {
          const newEntityId = patch[this.ENTITY_IDENTIFIER_KEY] || id;
          newEntities[newEntityId] = { ...currentEntity, ...patch };
          newIds.push(newEntityId);
        } else {
          newEntities[entityId] = currentEntity;
          newIds.push(entityId);
        }
      }

      this.state.next({
        ids: newIds,
        entities: newEntities,
      });
    }
  }

  remove(id: ID): void {
    const state = this.getState();

    if (this.hasEntity(id, state.entities)) {
      const newIds = [];
      const newEntities = {};

      for (const entityId of state.ids) {
        if (entityId !== id) {
          newEntities[entityId] = state.entities[entityId];
          newIds.push(entityId);
        }
      }

      this.state.next({
        ids: newIds,
        entities: newEntities,
      });
    }
  }

  /**
   * Adds entity to store
   */
  add(item: T): void;
  add(list: T[]): void;
  add(id: ID, item: T): void;
  add(itemOrListOrId: T | T[] | ID, item?: T): void {
    if (!itemOrListOrId) return;

    if (Array.isArray(itemOrListOrId)) {
      this.addCollection(itemOrListOrId);
    } else if (
      isEntityId(itemOrListOrId) &&
      typeof itemOrListOrId === 'object'
    ) {
      this.addOne(itemOrListOrId, item);
    } else if (typeof itemOrListOrId === 'object') {
      this.addOne(itemOrListOrId[this.ENTITY_IDENTIFIER_KEY], itemOrListOrId);
    }
  }

  /**
   * Resets current state and sets entity
   */
  set(item: T): void;
  set(list: T[]): void;
  set(itemOrList: T | T[]): void {
    if (!itemOrList) return;

    Array.isArray(itemOrList)
      ? this.addCollection(itemOrList, true)
      : this.addOne(itemOrList[this.ENTITY_IDENTIFIER_KEY], itemOrList, true);
  }

  getState(): EntityState<T> {
    return this.state.getValue();
  }

  getAll(): T[] {
    return this.getCachedCollectionFn(this.getState());
  }

  getEntity(id: ID): T {
    return this.getState().entities[id];
  }

  getCollection(): Observable<T[]> {
    return this.state$.pipe(
      map((state: EntityState<T>) => this.getCachedCollectionFn(state)),
    );
  }

  select(
    predicate?: (state: EntityState<T>) => T | T[] | EntityState<T>,
  ): Observable<T | T[] | EntityState<T>> {
    return this.state$.pipe(
      map((state: EntityState<T>) => (predicate ? predicate(state) : state)),
      distinctUntilChanged(),
    );
  }

  clear(): void {
    this.state.next(this.getDefaultEntityState());
  }

  hasEntity(
    id: ID,
    entities: Record<ID, T> = this.getState().entities,
  ): boolean {
    return Object.prototype.hasOwnProperty.call(entities, id);
  }

  size(): number {
    return this.getState().ids.length;
  }

  private addCollection(collection: T[], hard?: boolean): void {
    const currentState = this.getState();
    const newState = this.toEntitiesMap(collection);
    this.state.next({
      entities: hard
        ? newState.entities
        : { ...currentState.entities, ...newState.entities },
      ids: hard ? newState.ids : this.mergeIds(newState.ids),
    });
  }

  private addOne(id: ID, item: T, hard?: boolean): void {
    const currentState = this.getState();

    this.state.next({
      entities: hard
        ? { [id]: item }
        : { ...currentState.entities, [id]: item },
      ids: hard ? [id] : this.mergeIds([id]),
    });
  }

  private mergeIds(ids): ID[] {
    const currentState = this.getState();
    return [...new Set([...currentState.ids, ...ids])];
  }

  private toEntitiesMap(collection: T[]): EntityState<T> {
    return collection.reduce((state: EntityState<T>, item: T) => {
      if (this.hasEntity(item[this.ENTITY_IDENTIFIER_KEY], state.entities)) {
        return state;
      }

      state.entities[item[this.ENTITY_IDENTIFIER_KEY]] = item;
      state.ids.push(item[this.ENTITY_IDENTIFIER_KEY]);
      return state;
    }, this.getDefaultEntityState());
  }

  private toCollection(state: EntityState<T>): T[] {
    return state.ids.map((id: string) => state.entities[id]);
  }

  private getDefaultEntityState(): EntityState<T> {
    return {
      ids: [],
      entities: {},
    };
  }
}
