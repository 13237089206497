import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { SidebarService } from '../../../sidebar-service/sidebar-service';
import { AlbumsService } from '../../../dashboard-shared/left-menu/menus/albums/albums.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { Utils } from '../../../../../shared/utils/utils';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../shared/store/selected-images-store';
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-custom-album',
  templateUrl: './custom-album.component.html',
  styleUrls: ['./custom-album.component.scss'],
})
export class CustomAlbumComponent
  extends UnsubscriberComponent
  implements OnInit
{
  customAlbumList: any = [];
  currentFilter: string;
  assetsIds: any;
  noOfImages: any;

  constructor(
    private router: Router,
    private sidebarService: SidebarService,
    private albumService: AlbumsService,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private selectedImagesStore: SelectedImagesStore,
    private dialogRef: MatDialogRef<CustomAlbumComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { customAlbumData: any[] },
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.noOfImages = res.viewSettingDTO.imagePerPage;
        }
      });

    this.sidebarService.albumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.customAlbumList = [];
          this.data.customAlbumData.forEach((customAlbum) => {
            Utils.getValuesByKey(albumList.albums, 'id').forEach(
              (albumElement, index) => {
                if (albumElement.id === customAlbum) {
                  this.customAlbumList.push(albumElement);
                }
              },
            );
          });
        }
      });
  }

  onClickAlbum(album: any) {
    this.currentFilter = 'album:' + album.id;
    this.loaderService.displayLoader(true);

    this.viewProjectService
      .callProjectImageIDs(
        this.viewProjectService.getProjectId(),
        this.currentFilter,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res1) => {
          if (res1) {
            this.assetsIds = Utils.getImageIdsBasedOnPage(
              1,
              this.noOfImages,
              res1,
            );
            const data = {
              assetIds: this.assetsIds,
              projectId: this.viewProjectService.getProjectId(),
              filter: this.currentFilter,
            };

            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  this.viewProjectService.setExecutiveAlbum(false);
                  this.selectedImagesStore.clear();
                  this.sidebarService.setAlbumImageData(resData);
                  this.sidebarService.setSelectedAlbumName(album);
                  this.loaderService.displayLoader(false);
                  this.closeWindow();
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(this.translate.instant('view-project.alert2'));
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
