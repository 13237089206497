import { TooltipPosition } from '@angular/material/tooltip';
import {
  ConnectedPosition,
  HorizontalConnectionPos,
  OriginConnectionPosition,
  OverlayConnectionPosition,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PopupPositionService {
  getConnectedPosition(position: TooltipPosition) {
    const origin = this.getOrigin(position);
    const overlay = this.getOverlayPosition(position);

    return [
      { ...origin.main, ...overlay.main },
      { ...origin.fallback, ...overlay.fallback },
    ] as ConnectedPosition[];
  }

  /**
   * Returns the origin position and a fallback position based on position
   * The fallback position is the inverse of the origin (e.g. `'below' -> 'above'`).
   */
  getOrigin(position: TooltipPosition): {
    main: OriginConnectionPosition;
    fallback: OriginConnectionPosition;
  } {
    let originPosition: OriginConnectionPosition;

    if (position === 'above' || position === 'below') {
      originPosition = {
        originX: 'center',
        originY: position === 'above' ? 'top' : 'bottom',
      };
    } else if (position === 'before' || position === 'left') {
      originPosition = { originX: 'start', originY: 'center' };
    } else if (position === 'after' || position === 'right') {
      originPosition = { originX: 'end', originY: 'center' };
    }

    const { x, y } = this.invertPosition(
      position,
      originPosition!.originX,
      originPosition!.originY,
    );

    return {
      main: originPosition!,
      fallback: { originX: x, originY: y },
    };
  }

  /** Returns the overlay position and a fallback position based on position */
  getOverlayPosition(position: TooltipPosition): {
    main: OverlayConnectionPosition;
    fallback: OverlayConnectionPosition;
  } {
    let overlayPosition: OverlayConnectionPosition;

    if (position === 'above') {
      overlayPosition = { overlayX: 'center', overlayY: 'bottom' };
    } else if (position === 'below') {
      overlayPosition = { overlayX: 'center', overlayY: 'top' };
    } else if (position === 'before' || position === 'left') {
      overlayPosition = { overlayX: 'end', overlayY: 'center' };
    } else if (position === 'after' || position === 'right') {
      overlayPosition = { overlayX: 'start', overlayY: 'center' };
    }

    const { x, y } = this.invertPosition(
      position,
      overlayPosition!.overlayX,
      overlayPosition!.overlayY,
    );

    return {
      main: overlayPosition!,
      fallback: { overlayX: x, overlayY: y },
    };
  }

  /** Inverts an overlay position. */
  private invertPosition(
    position: TooltipPosition,
    x: HorizontalConnectionPos,
    y: VerticalConnectionPos,
  ) {
    if (position === 'above' || position === 'below') {
      if (y === 'top') {
        y = 'bottom';
      } else if (y === 'bottom') {
        y = 'top';
      }
    } else {
      if (x === 'end') {
        x = 'start';
      } else if (x === 'start') {
        x = 'end';
      }
    }

    return { x, y };
  }
}
