<div
  class="container fs-dialog-comment-wrapper"
  *ngIf="ifTagEnable"
  [ngStyle]="{
    top: ifTagEnableOffsetY + 'px',
    left: ifTagEnableOffsetX + 'px'
  }"
>
  <div class="row">
    <div class="p-1 pr-2 pl-2 w-100 d-flex justify-content-between">
      <div class="fs-dialog-comment-title">
        {{ 'image-caption.buttons.comment' | translate }}
      </div>
      <button class="fs-close-btn w-auto" (click)="hideTagForm.emit($event)">
        <i class="fa fa-times-circle"></i>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <form *ngIf="!isEditComment" (submit)="submitComment.emit($event)">
        <textarea name="comment"></textarea>
        <input type="hidden" name="x" [value]="ifTagEnableOffsetX" />
        <input type="hidden" name="y" [value]="ifTagEnableOffsetY" />
        <div class="w-100 pt-1 pb-1 d-flex justify-content-between">
          <input
            type="submit"
            class="fs-dialog-button-save pl-0"
            name="submit"
            value="Save"
          />
          <button
            class="fs-close-btn w-auto"
            *ngIf="isEditComment"
            (click)="deleteComment.emit($event)"
          >
            <i class="fa fa-sm fa-trash"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <form *ngIf="isEditComment" (submit)="submitCommentEdit.emit($event)">
        <textarea name="comment">{{ editComment }}</textarea>
        <input type="hidden" name="x" [value]="ifTagEnableOffsetX" />
        <input type="hidden" name="y" [value]="ifTagEnableOffsetY" />
        <div class="w-100 pt-1 pb-1 d-flex justify-content-between">
          <input
            type="submit"
            class="fs-dialog-button-save pl-0"
            name="submit"
            value="Save"
          />
          <button
            class="fs-close-btn w-auto"
            *ngIf="isEditComment"
            (click)="deleteComment.emit($event)"
          >
            <i class="fa fa-sm fa-trash"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
