<div
  id="bookmark"
  tabindex="-1"
  role="dialog"
  aria-labelledby="imdone"
  aria-hidden="true"
>
  <div
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    mat-dialog-title
    class="d-flex justify-content-between align-items-center"
  >
    <h4 class="modal-title">Fspko8</h4>
    <button
      type="button"
      (click)="closeNextSetUpWindow()"
      class="fs-close-btn w-auto"
      data-dismiss="modal"
      aria-hidden="true"
    >
      <i class="fa fa-times-circle"></i>
    </button>
  </div>

  <div mat-dialog-content class="fullwidth text-center">Image Navigation</div>
  <div mat-dialog-actions class="d-flex justify-content-end">
    <div class="d-flex justify-content-end align-items-center">
      <button
        type="button"
        class="fs-dialog-btn w-auto ml-3"
        data-dismiss="modal"
        (click)="gotoFirstImageInCurrentSetUp()"
      >
        GO TO FIRST IMAGE IN CURRENT SETUP
      </button>
      <button
        type="button"
        class="fs-dialog-btn w-auto ml-3"
        data-dismiss="modal"
        (click)="gotoNextSetUp()"
      >
        GO TO NEXT SETUP
      </button>
    </div>
  </div>
</div>
