import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MessageDialogData } from '../../components/message-dialog/message-dialog-data.interface';
import { MessageDialogComponent } from '../../components/message-dialog/message-dialog.component';
import { ComponentType, Overlay } from '@angular/cdk/overlay';
import { DialogsStateService } from './dialogs-state.service';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface DialogConfig<D = any> extends MatDialogConfig<D> {
  // we allow using keyboards Events
  enableHandlingUpKey?: boolean;
  // we add the dialog to the dialog state pool
  listener?: {
    withListener: boolean;
    id: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class DialogService implements OnDestroy {
  private destroy$ = new Subject(); // todo think what to do with this in class

  constructor(
    private dialog: MatDialog,
    private dialogsStateService: DialogsStateService,
    private overlay: Overlay,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  openConfirmationDialog(data: MessageDialogData) {
    return this.open(MessageDialogComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-md-panel',
        'fs-dialog-message-base-panel',
      ],
      data,
    });
  }

  openInformationDialog(data: MessageDialogData) {
    return this.open(MessageDialogComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-md-panel',
        'fs-dialog-message-base-panel',
      ],
      disableClose: false,
      data: {
        showRejectBtn: false,
        acceptBtn: 'Ok',
        ...data,
      },
    });
  }

  open<T, D = any>(
    component: ComponentType<T>,
    config: DialogConfig<D> = {} as DialogConfig,
  ) {
    const ref = this.dialog.open(component, {
      id: config.listener?.id,
      autoFocus: false,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      restoreFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      backdropClass: 'fs-dialog-overlay',
      ...config,
    });

    if (config.listener?.withListener) {
      this.dialogsStateService.checkAndUpdateDialogState(ref.id);
    }

    this.dialogsStateService.setOpenDialogs(this.dialog.openDialogs);

    ref
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        tap(() =>
          this.dialogsStateService.setOpenDialogs(this.dialog.openDialogs),
        ),
      )
      .subscribe();

    return ref.afterClosed();
  }

  /**
   * Gets dialogs state.
   * Make sure you opened dialog with withListener flag
   * @param id
   */
  getDialogState(id: string) {
    return this.dialogsStateService.getAndRegisterWithDefaultStateIfNeed(id);
  }

  closeDialogById(id: string, data = null) {
    const dialogRef = this.dialog.getDialogById(id);
    dialogRef?.close(data);
  }

  closeAll() {
    this.dialog.closeAll();
  }

  isAtLeastOneOpened() {
    return this.dialogsStateService.isAtLeastOneOpened();
  }

  isHandlingUpKeyEnabled() {
    return this.dialogsStateService.isHandlingUpKeyEnabled();
  }
}
