import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { DownloadService } from './download.service';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';
import { IFrameComponent } from '../iframe/iframe.component';
import { ImageTypeDetails } from '../../../../../shared/models/image-type-details.model';
import { AssetType } from '../../../../../shared/models/asset-type.model';
import { DownloadManagerService } from '../../../../../shared/layout/download-manager/download-manager.service';
import { Utils } from '../../../../../shared/utils/utils';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../shared/constants/constants';
import { take, takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../shared/store/selected-images-store';
import { LastSelectedImageStore } from '../../../../../shared/store/last-selected-image-store';
import { SelectedImage } from './download.model';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import { AppConfig } from '../../../../../app.configuration';
import { IpService } from '../../../../../shared/services/ip-service.service';
import { UserService } from '../../../../../shared/services/user.service';
import { UserDetails } from '../../../../../shared/models/userdetails.model';
import { UserRole } from '../../../../../shared/enum/user-role.enum';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';

enum FileType {
  Image = 'image',
}

@Component({
  selector: 'fs-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
})
export class DownloadComponent extends UnsubscriberComponent implements OnInit {
  assetDetail;
  token: any;
  appConfig = AppConfig;
  isDropDown = true;
  numberOfZipFile = '1';
  selectedImagesList: SelectedImage[] = [];
  imageKeys = [];
  fileKeys = [];
  imageTypeDisplay: ImageTypeDetails[] = [];
  projectID;
  selectedImageType;
  imgsize = 300;
  constantsGlobalData: any;
  isEnlargeView = false;
  utils = Utils;
  projectDetailPermission: any;
  projectAuthority: string;
  userAuthority: string;
  assetType = AssetType;
  imageSize: any;
  downloadOnlyLA: boolean;
  downloadType: number;

  constructor(
    private router: Router,
    public viewProjectService: ViewProjectService,
    private downloadService: DownloadService,
    private downloadManagerService: DownloadManagerService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private logger: NGXLogger,
    private dialogService: DialogService,
    private ipService: IpService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private userService: UserService,
    private selectedImagesStore: SelectedImagesStore,
    private lastSelectedImageStore: LastSelectedImageStore,
    private dialogRef: MatDialogRef<DownloadComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(): void {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority) => {
        this.userAuthority = userAuthority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.search('enlarge') !== -1 ||
          event.url.search('compare') !== -1
        ) {
          this.isEnlargeView = true;
        } else {
          this.isEnlargeView = false;
        }
      }
    });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.projectDetailPermission = data;
          this.downloadType =
            this.projectDetailPermission.permissionDTO.downloadType;
          this.downloadOnlyLA =
            this.projectDetailPermission.permissionDTO.downloadOnlyLA;
        } else {
          this.projectDetailPermission = undefined;
        }
      });

    if (this.data) {
      this.isDropDown = true;
      this.assetDetail = this.data.assetDetail;

      if (this.userAuthority !== UserRole.ROLE_ADMIN) {
        this.assetDetail = this.viewProjectService.excludeDownloadTypes(
          this.downloadType,
          this.downloadOnlyLA,
          this.assetDetail,
        );
      }

      // PKOBUG-296 this is a workaround fix for PKOBUG-294
      if (this.assetDetail && this.assetDetail.Image) {
        for (const assetType of Object.keys(this.assetDetail.Image)) {
          this.assetDetail.Image[assetType].sort((a, b) => a.id - b.id);
        }
      }
      this.token = this.data.token;
      this.imageKeys = Object.keys(this.assetDetail.Image);
      this.fileKeys = Object.keys(this.assetDetail.Other);

      if (this.imageKeys.length > 0 && this.fileKeys.length === 0) {
        this.imageTypeDisplay = [];
        let imageCount = 0;
        const fileCount = 0;
        let totalSize = 0;
        this.imageKeys.forEach((imagKeys, index) => {
          totalSize = 0;
          [imageCount, totalSize] = this.calculateTotalSizeAndCount(
            imageCount,
            imagKeys,
            totalSize,
          );
          this.imageTypeDisplay.push({
            name: imagKeys + ' (' + imageCount + ')',
            key: imagKeys,
            value: index + 1,
            imageCount: imageCount,
            fileCount: fileCount,
            totalSize: totalSize / 1024,
          });
        });
        this.fistTimeSmallImageLoad(this.imageTypeDisplay[0]);
      } else if (this.imageKeys.length === 0 && this.fileKeys.length > 0) {
        this.imageTypeDisplay = [];
        const imageCount = 0;
        let fileCount = 0;
        let totalSize = 0;
        [fileCount, totalSize] = this.calculateTotalSize(fileCount, totalSize);

        this.imageTypeDisplay.push({
          name: 'Total file ' + fileCount,
          key: 'Other',
          value: 1,
          imageCount: imageCount,
          fileCount: fileCount,
          totalSize: totalSize / 1024,
        });
        this.fistTimeFilesLoad();
      } else if (this.imageKeys.length > 0 && this.fileKeys.length > 0) {
        this.imageTypeDisplay = [];
        let imageCount = 0;
        let fileCount = 0;
        let totalSize = 0;
        this.imageKeys.forEach((imagKeys, index) => {
          totalSize = 0;
          fileCount = 0;
          [imageCount, totalSize] = this.calculateTotalSizeAndCount(
            imageCount,
            imagKeys,
            totalSize,
          );
          [fileCount, totalSize] = this.calculateTotalSize(
            fileCount,
            totalSize,
          );

          this.imageTypeDisplay.push({
            name:
              imagKeys + ' IMAGE (' + imageCount + ') && FILES ' + fileCount,
            key: imagKeys,
            imageCount: imageCount,
            value: index + 1,
            fileCount: fileCount,
            totalSize: totalSize / 1024,
          });
        });
        this.fistTimeSmallImageLoad(this.imageTypeDisplay[0]);
      }
    }
  }

  calculateTotalSize(fileCount, totalSize) {
    this.fileKeys.forEach((fileKeys) => {
      fileCount = fileCount + this.assetDetail.Other[fileKeys].length;
      totalSize = this.assetDetail.Other[fileKeys].reduce(
        (res, type) => res + type.size,
        0,
      );
    });
    return [fileCount, totalSize];
  }

  calculateTotalSizeAndCount(imageCount, imagKeys, totalSize) {
    imageCount = this.assetDetail.Image[imagKeys].length;
    totalSize = this.assetDetail.Image[imagKeys].reduce(
      (res, type) => res + type.size,
      0,
    );
    return [imageCount, totalSize];
  }

  fistTimeSmallImageLoad(type) {
    this.fillDownloadsData(type);
  }

  fistTimeFilesLoad() {
    this.selectedImagesList = [];
    this.fileKeys.forEach((fileKeys) => {
      this.assetDetail.Other[fileKeys].forEach((element) => {
        const titleName = element.name;
        const name = titleName.substr(0, titleName.lastIndexOf('.')) + '.jpg';
        this.selectedImagesList.push({
          id: element.id,
          size: element.size,
          name,
          titleName,
          url: '',
          locator: element.locator,
          assetType: fileKeys.toLowerCase(),
        });
      });
    });
  }

  onChangeImageType(type) {
    this.fillDownloadsData(type);
  }

  private fillDownloadsData(type) {
    this.selectedImageType = type.key;
    this.imageSize = this.downloadManagerService.getImageItemWidth(
      this.selectedImageType,
    );

    this.selectedImagesList = [];
    this.assetDetail.Image[type.key].forEach((element) => {
      const titleName = element.name;
      const name = titleName.substr(0, titleName.lastIndexOf('.')) + '.jpg';
      this.selectedImagesList.push({
        id: element.id,
        size: element.size,
        name,
        titleName,
        locator: element.locator,
        assetType: FileType.Image,
        batchId: element.batchId,
        batchName: element.batchName,
        batchLocator: element.batchLocator,
        token: this.token,
      });
    });

    this.fileKeys.forEach((fileKeys) => {
      this.assetDetail.Other[fileKeys].forEach((element) => {
        this.selectedImagesList.push({
          size: element.size,
          id: element.id,
          locator: element.locator,
          name: element.name,
          url: '',
          assetType: fileKeys.toLowerCase(),
        });
      });
    });
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  onClickDownloadFile() {
    this.alertCustom(
      this.translate.instant('top-menu.downloadImages.downloadAlert'),
    );
    this.downloadManagerService.setIsDownloadEnabled(true);
    if (this.selectedImagesList.length === 1) {
      const httpParams = this.downloadManagerService.setHttpDownloadParams(
        this.ipService.userIPAddress$,
        this.userService.getCurrentUser().id,
        this.userService.getFullUserName(),
        this.selectedImagesList.map((item: SelectedImage) => item.id),
        this.userAuthority,
        this.viewProjectService.getProjectId(),
        this.projectDetailPermission.projectName,
        this.selectedImagesList[0].size,
        this.ipService.browserInfo$,
        this.imageSize,
        false,
      );

      if (this.projectDetailPermission.permissionDTO.watermark) {
        this.downloadManagerService
          .pushDownloadItem(this.selectedImagesList[0], httpParams, true)
          .subscribe();
      } else {
        this.downloadManagerService
          .pushDownloadItem(this.selectedImagesList[0], httpParams)
          .subscribe();
      }
    } else {
      const downloadItems = this.downloadManagerService.getDownloadItems();
      const downloadItemDetails = {
        url: '',
        id: downloadItems.length,
        downloadedPercent: -1,
        filename: 'Preparing download - Zipping',
        fileSize: 0,
      };
      this.downloadManagerService.setDownloadItems([
        ...downloadItems,
        downloadItemDetails,
      ]);

      const obj = {
        ip: this.ipService.userIPAddress$,
        userId: this.userService.getCurrentUser().id,
        assetIds: this.selectedImagesList.map((item: SelectedImage) => item.id),
        authorityName: this.userAuthority,
        userName: this.userService.getFullUserName(),
        locator: Utils.getLocatorIds(this.selectedImagesList),
        downloadSize: this.selectedImagesList.reduce(
          (totalSize, item) => totalSize + item.size,
          0,
        ),
        projectId: this.viewProjectService.getProjectId(),
        zipCount: this.numberOfZipFile,
        downloadId: downloadItemDetails.id,
        token: this.token,
        projectName: this.projectDetailPermission.projectName,
        browserInfo: this.ipService.browserInfo$,
        imageSize: this.imageSize,
      };
      this.downloadService
        .downloadZipFile(obj)
        .pipe(take(1))
        .subscribe(
          (resData) => {
            if (resData && resData.files && resData.files.length > 0) {
              resData.files.forEach((element) => {
                const httpParams =
                  this.downloadManagerService.setHttpDownloadParams(
                    this.ipService.userIPAddress$,
                    this.userService.getCurrentUser().id,
                    this.userService.getFullUserName(),
                    this.selectedImagesList.map(
                      (item: SelectedImage) => item.id,
                    ),
                    this.userAuthority,
                    this.viewProjectService.getProjectId(),
                    this.projectDetailPermission.projectName,
                    element.size,
                    this.ipService.browserInfo$,
                    this.imageSize,
                    false,
                  );
                element.token = resData.token;
                this.downloadManagerService
                  .pushDownloadItem(element, httpParams)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(
                    () => {},
                    (error) => {
                    this.apiErrorHandlerService.getHandler().handle(error);
                    this.loaderService.displayLoader(false);
                  });
              });
              this.loaderService.displayLoader(false);
            }
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false)
          },
        );
    }
    this.dialogRef.close();
  }

  onClickExpressDownloadFile() {
    this.downloadManagerService.setIsDownloadEnabled(true);
    const downloadItems = this.downloadManagerService.getDownloadItems();

    const obj = {
      ip: this.ipService.userIPAddress$,
      assetIds: this.selectedImagesList.map((item: SelectedImage) => item.id),
      userId: this.userService.getCurrentUser().id,
      authorityName: this.userAuthority,
      userName: this.userService.getFullUserName(),
      projectName: this.projectDetailPermission.projectName,
      locator: Utils.getLocatorIds(this.selectedImagesList),
      projectId: this.viewProjectService.getProjectId(),
      zipCount: this.numberOfZipFile,
      downloadId: downloadItems.length,
      token: this.token,
      browserInfo: this.ipService.browserInfo$,
      imageSize: this.imageSize,
    };
    this.loaderService.displayLoader(true);
    this.downloadService
      .downloadExpressZipFile(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (resData) => {
          if (resData.status) {
            const downloadItemDetails = {
              id: downloadItems.length,
              downloadedPercent: -1,
              filename: 'Preparing download - Zipping',
              fileSize: 0,
              isExpressDownload: true,
              url: resData.message,
              visible: true,
            };

            setTimeout(() => {
              this.downloadManagerService.setDownloadItems([
                ...downloadItems,
                downloadItemDetails,
              ]);
              this.closeWindow();
              this.dialogService.open(IFrameComponent, {
                panelClass: [
                  'fs-dialog-panel',
                  'fs-dialog-size-lg-panel',
                  'fs-dialog-link-panel',
                ],
                data: downloadItemDetails,
              });
              this.loaderService.displayLoader(false);
            }, 3000);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false)
        },
      );
  }

  closeWindow() {
    this.imageTypeDisplay = [];
    this.dialogRef.close();
  }
}
