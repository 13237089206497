<div
  class="fs-sm-txt fs-txt-white-dark-color list-unstyled components sideBar-menu py-2"
  id="leftsideMenuBar"
>
  <div class="right-content">
    <div class="d-block sub-menu-list sub-menu-text-spacing m-0">
        <div class="scroll_section container">
          <div class="row" *ngIf="!isTaggerRole">
            <span
              class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
              (click)="imageNotesReport()"
            >
              {{
                'left-menu.tab-bar.sidemenus.administration.buttonse.image-notes-report'
                  | translate
              }}
            </span>
          </div>

          <div class="row">
            <div class="col fs-cursor-pointer fs-txt-white-grey-color-hover">
              <fs-hide-images></fs-hide-images>
            </div>
          </div>

          <div class="row">
            <div class="col fs-cursor-pointer fs-txt-white-grey-color-hover">
              <fs-unhide-images></fs-unhide-images>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
