import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Activemodal } from '../../../../../shared/models/activemodal.model';
import { Modalstatusservice } from '../../../../../shared/services/modalstatus.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';

@Component({
  selector: 'fs-designation',
  templateUrl: './designation.html',
  styleUrls: ['./designation.scss'],
})
export class Designation implements OnInit {
  activemodal: Activemodal = new Activemodal();
  assetId;

  arrListDestinationOptions: any = [];
  designationValue = '';

  constructor(
    private modalService: Modalstatusservice,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<Designation>,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.assetId = data.assetId;
  }

  ngOnInit() {
    this.arrListDestinationOptions = [
      { name: 'LAUNCH' },
      { name: 'FALL' },
      { name: 'LATER' },
    ];
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  onDesignationValueChanged(value) {
    this.designationValue = value;
  }

  onClickApply() {
    if (this.designationValue === '') {
      this.dialogRef.close();
      this.alertCustom(this.translate.instant('view-project.alert39'));
    } else {
      const obj = {
        assetId: this.assetId,
        designation: this.designationValue,
      };
      this.dialogRef.close(obj);
    }
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
