export enum ProjectRoleId {
  ROLE_EXEC = '1',
  ROLE_TALENT = '2',
  ROLE_ALBUMVIEWER = '3',
}

export enum ProjectRole {
  ROLE_EXEC = 'ROLE_EXEC',
  ROLE_TALENT = 'ROLE_TALENT',
  ROLE_ALBUMVIEWER = 'ROLE_ALBUMVIEWER',
}
