<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{ 'top-menu.downloadImages.title' | translate }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content class="theme-form d-flex">
  <div class="col-sm-3">
    <mat-radio-group class="d-flex flex-column">
      <mat-radio-button
        name="radiogroup"
        class="fs-radio-button"
        *ngFor="let displayType of imageTypeDisplay; index as indx"
        [value]="displayType.value"
        (change)="onChangeImageType(displayType)"
        [checked]="indx === 0"
      >
        <span class="cb-title fs-sm-txt">{{ displayType.name }}</span>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="col-sm-9">
    <ng-scrollbar autoHeightDisabled="false" trackY="'true'">
      <div class="display-image d-flex flex-wrap">
        <div class="image-container" *ngFor="let img of selectedImagesList">
          <div class="btm-images" [ngSwitch]="img.assetType">
            <div
              class="img-sngl d-flex flex-column align-items-center text-center"
              *ngSwitchCase="assetType.IMAGE"
            >
              <ng-container
                [ngTemplateOutlet]="imageUrl"
                [ngTemplateOutletContext]="{ img: img }"
              >
              </ng-container>
              <ng-container
                [ngTemplateOutlet]="imageDescription"
                [ngTemplateOutletContext]="{ img: img }"
              >
              </ng-container>
            </div>
            <div
              class="img-sngl d-flex flex-column align-items-center text-center"
              *ngSwitchCase="assetType.VIDEO"
            >
              <ng-container
                [ngTemplateOutlet]="imageUrl"
                [ngTemplateOutletContext]="{ img: img }"
              >
              </ng-container>
              <ng-container
                [ngTemplateOutlet]="imageDescription"
                [ngTemplateOutletContext]="{ img: img }"
              >
              </ng-container>
            </div>

            <div
              class="img-sngl d-flex flex-column align-items-center text-center"
              *ngSwitchCase="assetType.DOCX"
            >
              <ng-container
                [ngTemplateOutlet]="imageType"
                [ngTemplateOutletContext]="{
                  imgType: '/assets/images/doc_icon.png'
                }"
              >
              </ng-container>
              <ng-container
                [ngTemplateOutlet]="imageDescription"
                [ngTemplateOutletContext]="{ img: img }"
              >
              </ng-container>
            </div>

            <div
              class="img-sngl d-flex flex-column align-items-center text-center"
              *ngSwitchCase="assetType.PDF"
            >
              <ng-container
                [ngTemplateOutlet]="imageType"
                [ngTemplateOutletContext]="{
                  imgType: '/assets/images/pdf_icon.png'
                }"
              >
              </ng-container>
              <ng-container
                [ngTemplateOutlet]="imageDescription"
                [ngTemplateOutletContext]="{ img: img }"
              >
              </ng-container>
            </div>

            <div
              class="img-sngl d-flex flex-column align-items-center text-center"
              *ngSwitchCase="assetType.ZIP"
            >
              <ng-container
                [ngTemplateOutlet]="imageType"
                [ngTemplateOutletContext]="{
                  imgType: '/assets/images/zip_icon.png'
                }"
              >
              </ng-container>
              <ng-container
                [ngTemplateOutlet]="imageDescription"
                [ngTemplateOutletContext]="{ img: img }"
              >
              </ng-container>
            </div>

            <div
              class="img-sngl d-flex flex-column align-items-center text-center"
              *ngSwitchCase="assetType.XLSX"
            >
              <ng-container
                [ngTemplateOutlet]="imageType"
                [ngTemplateOutletContext]="{
                  imgType: '/assets/images/xls_icon.png'
                }"
              >
              </ng-container>
              <ng-container
                [ngTemplateOutlet]="imageDescription"
                [ngTemplateOutletContext]="{ img: img }"
              >
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</div>

<div mat-dialog-actions class="mf-sngle d-flex justify-content-end">
  <ng-container>
    <button class="fs-dialog-btn w-auto ml-3" (click)="onClickDownloadFile()">
      {{ 'top-menu.downloadImages.buttons.download' | translate }}
    </button>
  </ng-container>

  <ng-container
    *ngIf="
      appConfig.SETTINGS.EXPRESS_DOWNLOAD &&
      selectedImagesList.length > 1 &&
      projectDetailPermission &&
      projectDetailPermission.permissionDTO &&
      !projectDetailPermission.permissionDTO.watermark
    "
  >
    <button
      class="fs-dialog-btn w-auto ml-3"
      (click)="onClickExpressDownloadFile()"
    >
      Express Download
    </button>
  </ng-container>
</div>

<ng-template #imageDescription let-img="img">
  <div class="is-name w-100">{{ img.titleName }}</div>
  <div class="is-name w-100">({{ utils.formatBytes(img.size) }})</div>
</ng-template>

<ng-template #imageUrl let-img="img">
  <div class="is-img overflow-hidden d-flex align-items-center">
    <img
      class="w-100"
      [lazySrc]="viewProjectService.getWebImageURL(img, '125')"
      alt=""
    />
  </div>
</ng-template>

<ng-template #imageType let-imgType="imgType">
  <div class="is-img overflow-hidden d-flex align-items-center">
    <img [src]="imgType" alt="" />
  </div>
</ng-template>
