export const Notification: any = [
  {
    id: 1,
    title: 'New Notification 1',
  },
  {
    id: 2,
    title: 'New Notification 2',
  },
  {
    id: 3,
    title: 'New Notification 3',
  },
  {
    id: 4,
    title: 'New Notification 4',
  },
  {
    id: 5,
    title: 'New Notification 5',
  },
];
