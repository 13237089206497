<div
  #actionsContainerRef
  *ngIf="!hide"
  class="fs-menu-actions-container d-flex pr-2 h-100 align-items-center"
>
  <div
    class="switch_cont_color"
    *ngIf="
      albumType === 'album' && projectAuthority !== projectRole.ROLE_ALBUMVIEWER
    "
  >
    <mat-slide-toggle
      class="fs-toggle-small fs-toggle-bar-dark-white fs-toggle-thumb-white fs-toggle-thumb-active-white"
      [(ngModel)]="tagColorOnViewPage"
      (change)="handleTagColorOnViewPage($event)"
    >
    </mat-slide-toggle>
  </div>

  <div class="p-1" *ngIf="albumType === 'wideEdit'">
    <a
      #tagsIcon
      class="d-block fs-txt-white-grey-color-hover"
      [title]="
        'left-menu.tab-bar.sidemenus.administration.advanceimage-tagging.title'
          | translate
      "
      [ngClass]="{ active: activePopup === dialogType.AdvancedImageTagging }"
      (click)="advancedImageTagging()"
    >
      <i class="fs-menu-tags-icon fa fa-tags" aria-hidden="true"></i>
    </a>
  </div>

  <div class="p-1">
    <a
      class="d-block fs-txt-white-grey-color-hover"
      [title]="'addalbum.title' | translate"
      [ngClass]="{ active: activePopup === dialogType.AddAlbum }"
      (click)="showAddAlbumPopup()"
    >
      <img class="fs-menu-icon" src="/assets/images/icon-plus.png" alt="" />
    </a>
  </div>

  <div class="p-1" (click)="showDeleteAlbumPopup()">
    <a
      class="d-block fs-txt-white-grey-color-hover"
      [title]="'deletealbum.title' | translate"
      [ngClass]="{ active: activePopup === dialogType.DeleteAlbum }"
    >
      <img class="fs-menu-icon" src="/assets/images/icon-minus.png" alt="" />
    </a>
  </div>

  <div class="d-block p-1" (click)="showRenameAlbumPopup()">
    <a
      #renameAlbum
      class="d-block fs-txt-white-grey-color-hover"
      [ngClass]="{ active: activePopup === dialogType.Rename }"
      [title]="'renamealbum.title' | translate"
    >
      <img class="fs-menu-icon" src="/assets/images/icon-cursor.png" alt="" />
    </a>
  </div>

  <div class="d-block p-1">
    <a
      #permissionsRef
      class="d-block fs-txt-white-grey-color-hover"
      [title]="'editalbum.title' | translate"
      [ngClass]="{ active: activePopup === dialogType.EditAlbum }"
      (click)="showEditAlbumPopup()"
    >
      <img class="fs-menu-icon" src="/assets/images/icon-key.png" alt="" />
    </a>
  </div>

  <div class="d-block p-1" *ngIf="albumType === 'wideEdit'">
    <a
      class="d-block fs-txt-white-grey-color-hover"
      [ngClass]="{ active: activePopup === dialogType.EmailNotification }"
      (click)="sendEmailNotification()"
    >
      <img
        class="fs-menu-icon email_icon"
        src="/assets/images/icon-email.png"
        alt=""
      />
    </a>
  </div>
</div>
