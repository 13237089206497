<div class="ml-1">
  <div class="fs-cursor-pointer" title="{{ 'view-project.notes' | translate }}">
    <span class="fs-char-icon">
      <img
        #noteIconContainer
        src="/assets/images/icon-file_previous.png"
        [class.highlighted-icon]="notesSize"
        (click)="onNoteMenuOpen(imgData, ind, $event)"
      />
    </span>
  </div>
</div>
