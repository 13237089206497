import { AdministrationService } from './../administration.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImagecaptionService } from './imagecaption-report.service';
import { MatDialogRef } from '@angular/material/dialog';
import { CaptionReportComponent } from './caption-report/caption-report.component';
import { Activemodal } from '../../../../../../../shared/models/activemodal.model';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../../../../../../../shared/utils/utils';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'fs-imagecaption-report',
  templateUrl: './imagecaption-report.component.html',
  styleUrls: ['./imagecaption-report.component.scss'],
})
export class ImagecaptionReportComponent
  extends UnsubscriberComponent
  implements OnInit
{
  activemodal: Activemodal = new Activemodal();
  captionType: any = '1';
  filterName = '';
  constantsGlobalData: any;
  existingImageCaptionData: any;
  isVisisble = false;

  constructor(
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private imagecaptionService: ImagecaptionService,
    private administrationService: AdministrationService,
    private viewProjectService: ViewProjectService,
    private dialogRef: MatDialogRef<ImagecaptionReportComponent>,
    private dialogService: DialogService,
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.filterName = this.viewProjectService.getCurrentFilter();
    this.captionType = '1';
    this.imagecaptionService.reportCaptionGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.existingImageCaptionData = res;
        } else {
          this.existingImageCaptionData = [];
        }
      });

    this.imagecaptionService.setCaptionType(this.captionType);
  }

  onCaptionChange(e) {
    this.imagecaptionService.setCaptionType(e.value);
  }

  closeWindow() {
    this.dialogRef.close();
  }

  captionReport() {
    this.dialogRef.close();
    this.dialogService.open(CaptionReportComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
        'fs-dialog-auto-height-panel',
      ],
      data: {
        existingCaptionData: [],
      },
    });
  }

  captionReportExisting() {
    this.dialogRef.close();
    this.dialogService.open(CaptionReportComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
        'fs-dialog-auto-height-panel',
      ],
      data: {
        existingCaptionData: [],
      },
    });
  }
}
