<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.imagecaption-report.title'
        | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div
    class="db_com caption-report-table cbackground fs-header-wrapper_fixed"
    #scrollTableCont
  >
    <div class="table-responsive">
      <mat-table
        class="fs-table-container fs-header-sort-color fs-scroll-table-body"
        matSort
        [hidden]="!dataSource.data.length"
        [dataSource]="dataSource"
      >
        <ng-container matColumnDef="imageId">
          <mat-header-cell
            mat-sort-header
            disableClear
            class="pl-2"
            *matHeaderCellDef
          >
            {{
              'left-menu.tab-bar.sidemenus.administration.imagecaption-report.caption-report.table.image'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="pl-2 fs-txt-white-dark-color"
            *matCellDef="let element"
          >
            <span
              class="celllink fs-sm-txt fs-txt-white-dark-color"
              *ngIf="element.imageId.length >= 2"
              (click)="onClickImageName(element.imageId)"
            >
              <span> {{ element.imageId[0].value }} </span> Thru <br />
              <span>
                {{ element.imageId[element.imageId.length - 1].value }}
              </span>
            </span>
            <span
              class="celllink fs-sm-txt fs-txt-white-dark-color"
              *ngIf="element.imageId.length == 1"
              (click)="onClickImageName(element.imageId)"
            >
              {{ element.imageId[0].value }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="count">
          <mat-header-cell
            mat-sort-header
            disableClear
            class="pl-2"
            *matHeaderCellDef
          >
            {{
              'left-menu.tab-bar.sidemenus.administration.imagecaption-report.caption-report.table.count'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="pl-2 fs-sm-txt fs-txt-white-grey-color-hover"
            *matCellDef="let element"
          >
            <span class="fs-txt-white-dark-color">
              {{ element.count }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actors">
          <mat-header-cell
            mat-sort-header="actors"
            disableClear
            class="pl-2"
            *matHeaderCellDef
          >
            {{
              'left-menu.tab-bar.sidemenus.administration.imagecaption-report.caption-report.table.actors'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="pl-2 fs-sm-txt fs-txt-white-grey-color-hover flex-column"
            *matCellDef="let element"
          >
            <div
              *ngFor="let actorName of element.actors"
              class="fs-txt-white-dark-color"
            >
              {{ actorName }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="captions">
          <mat-header-cell
            mat-sort-header="captions"
            disableClear
            class="pl-2"
            *matHeaderCellDef
          >
            {{
              'left-menu.tab-bar.sidemenus.administration.imagecaption-report.caption-report.table.caption'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="pl-2 fs-txt-white-grey-color-hover"
            *matCellDef="let element"
          >
            <span
              class="caption-colimn fs-sm-txt fs-txt-white-dark-color"
              *ngFor="let captionsData of element.captions; let idx = index"
              [ngClass]="{ 'border-row': element.isTextFound }"
              innerHTML="{{ captionsData.value | highlight : strFindTOtext }}"
            >
            </span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <div *ngIf="!dataSource.data.length">
        <div class="p-4 text-center">
          {{ 'global.no-record' | translate }}
        </div>
      </div>
    </div>
    <div *ngIf="reports?.length">
      <mat-paginator (page)="setPagination($event)"
                     [length]="totalItems"
                     [showFirstLastButtons]='true'
                     [pageSize]="itemsPerPage"
      ></mat-paginator>
    </div>
  </div>

  <div class="genicreport-abso" *ngIf="captionAccess">
    <form
      (submit)="onsubmitEvent()"
      id="captionReport"
      name="captionReport"
      #form="ngForm"
    >
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 theme-form">
        <div class="row">
          <div class="db_com gen-icreport-cont d-flex">
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
              <h6 class="fs-sm-txt font-weight-bold">
                {{
                  'left-menu.tab-bar.sidemenus.administration.imagecaption-report.caption-report.labels.find'
                    | translate
                }}:
              </h6>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <input
                type="text"
                class="form-control input-sm fs-sm-txt"
                name="findto"
                [(ngModel)]="strFindTOtext"
                id="usr"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <button class="btn btn-sub" (click)="onClickFindBtn()">
                {{
                  'left-menu.tab-bar.sidemenus.administration.imagecaption-report.caption-report.button.find'
                    | translate
                }}
              </button>
              <button
                class="btn btn-sub ml-3"
                [ngClass]="{
                  disabled:
                    strFindTOtext.length === 0 && strReplaceTOtext.length === 0
                }"
                (click)="onClickClearValues()"
              >
                {{
                  'left-menu.tab-bar.sidemenus.administration.imagecaption-report.caption-report.button.clear'
                    | translate
                }}
              </button>
            </div>
          </div>

          <div class="gen-icreport-cont d-flex">
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
              <h6 class="fs-sm-txt font-weight-bold">
                {{
                  'left-menu.tab-bar.sidemenus.administration.imagecaption-report.caption-report.labels.replace with'
                    | translate
                }}:
              </h6>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-3">
              <input
                type="text"
                class="form-control input-sm fs-sm-txt"
                id="usr"
                [(ngModel)]="strReplaceTOtext"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex flex-row">
              <button
                class="btn btn-sub"
                (click)="replaceCaption(1)"
                [ngClass]="{ disabled: this.strReplaceTOtext.length === 0 }"
              >
                {{
                  'left-menu.tab-bar.sidemenus.administration.imagecaption-report.caption-report.button.replace'
                    | translate
                }}
              </button>
              <button
                class="btn btn-sub ml-3"
                (click)="replaceCaption(2)"
                [ngClass]="{ disabled: this.strReplaceTOtext.length === 0 }"
              >
                {{
                  'left-menu.tab-bar.sidemenus.administration.imagecaption-report.caption-report.button.replace All'
                    | translate
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div mat-dialog-actions class="d-flex" align="end">
  <button
    class="fs-dialog-btn w-auto ml-3"
    [disabled]="!reports?.length"
    (click)="onClickExportBtn(exportType.Excel)"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.export'
        | translate
    }}
    as xls
  </button>

  <button
    [disabled]="!reports?.length"
    class="fs-dialog-btn w-auto ml-3"
    (click)="onClickExportBtn(exportType.Pdf)"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.export'
        | translate
    }}
    as PDF
  </button>
</div>
