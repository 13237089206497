<div
  [title]="'view-project.solo' | translate"
  *ngIf="imgData.excludedFrom?.length === 0 && !isTaggerRole"
>
  <span class="fs-char-icon">
    <img
      src="/assets/icons/s.png"
      (click)="clickOnGroupImages.emit({ imgData: imgData, event: $event })"
      [ngClass]="{ 'with-border': imgData.excludedFrom?.length > 0 }"
    />
  </span>
</div>
