import { UserRole } from '../enum/user-role.enum';
import { ProjectRole } from '../enum/project-role.enum';

export const ShortcutsStore = [
  {
    role: UserRole.ROLE_ADMIN,
    hotkeys: [
      { key: 'A', action: 'shortcuts.actionDescription.selectAll' },
      { key: 'B', action: 'shortcuts.actionDescription.openBookmark' },
      { key: 'D', action: 'shortcuts.actionDescription.openDownload' },
      { key: 'P', action: 'shortcuts.actionDescription.updatePriorityPixProp' },
      {
        key: 'T',
        action: 'shortcuts.actionDescription.addOrDeleteSelectedAsset',
      },
      {
        key: 'X',
        action: 'shortcuts.actionDescription.setExcludeReleaseStatus',
      },
      { key: 'Z', action: 'shortcuts.actionDescription.navigateToEnlargeView' },
      { key: 'Space', action: 'shortcuts.actionDescription.openEnlargeView' },
      {
        key: 'Esc',
        action: 'shortcuts.actionDescription.navigateToProjectView',
      },
      {
        key: 'ArrowLeft',
        action: 'shortcuts.actionDescription.selectPreviousAsset',
      },
      {
        key: 'ArrowRight',
        action: 'shortcuts.actionDescription.selectNextAsset',
      },
      {
        key: 'ArrowUp',
        action: 'shortcuts.actionDescription.selectPreviousAssetsRow',
      },
      {
        key: 'ArrowDown',
        action: 'shortcuts.actionDescription.selectNextAssetsRow',
      },
    ],
  },
  {
    role: ProjectRole.ROLE_EXEC,
    hotkeys: [
      { key: 'A', action: 'shortcuts.actionDescription.selectAll' },
      { key: 'B', action: 'shortcuts.actionDescription.openBookmark' },
      { key: 'D', action: 'shortcuts.actionDescription.openDownload' },
      { key: 'P', action: 'shortcuts.actionDescription.updatePriorityPixProp' },
      {
        key: 'T',
        action: 'shortcuts.actionDescription.addOrDeleteSelectedAsset',
      },
      {
        key: 'X',
        action: 'shortcuts.actionDescription.setExcludeReleaseStatus',
      },
      { key: 'Z', action: 'shortcuts.actionDescription.navigateToEnlargeView' },
      { key: 'Space', action: 'shortcuts.actionDescription.openEnlargeView' },
      {
        key: 'Esc',
        action: 'shortcuts.actionDescription.navigateToProjectView',
      },
      {
        key: 'ArrowLeft',
        action: 'shortcuts.actionDescription.selectPreviousAsset',
      },
      {
        key: 'ArrowRight',
        action: 'shortcuts.actionDescription.selectNextAsset',
      },
      {
        key: 'ArrowUp',
        action: 'shortcuts.actionDescription.selectPreviousAssetsRow',
      },
      {
        key: 'ArrowDown',
        action: 'shortcuts.actionDescription.selectNextAssetsRow',
      },
    ],
  },
  {
    role: ProjectRole.ROLE_TALENT,
    hotkeys: [
      { key: 'A', action: 'shortcuts.actionDescription.selectAll' },
      { key: 'B', action: 'shortcuts.actionDescription.openBookmark' },
      { key: 'Z', action: 'shortcuts.actionDescription.navigateToEnlargeView' },
      {
        key: 'X',
        action: 'shortcuts.actionDescription.appointApproveOrRejectAssetStatus',
      },
      { key: 'Space', action: 'shortcuts.actionDescription.openEnlargeView' },
      {
        key: 'Esc',
        action: 'shortcuts.actionDescription.navigateToProjectView',
      },
      {
        key: 'ArrowLeft',
        action: 'shortcuts.actionDescription.selectPreviousAsset',
      },
      {
        key: 'ArrowRight',
        action: 'shortcuts.actionDescription.selectNextAsset',
      },
      {
        key: 'ArrowUp',
        action: 'shortcuts.actionDescription.selectPreviousAssetsRow',
      },
      {
        key: 'ArrowDown',
        action: 'shortcuts.actionDescription.selectNextAssetsRow',
      },
    ],
  },
  {
    role: UserRole.ROLE_EXTERNAL,
    hotkeys: [],
  },
  {
    role: ProjectRole.ROLE_ALBUMVIEWER,
    hotkeys: [
      { key: 'A', action: 'shortcuts.actionDescription.selectAll' },
      { key: 'B', action: 'shortcuts.actionDescription.openBookmark' },
      { key: 'D', action: 'shortcuts.actionDescription.openDownload' },
      { key: 'Z', action: 'shortcuts.actionDescription.navigateToEnlargeView' },
      { key: 'Space', action: 'shortcuts.actionDescription.openEnlargeView' },
      {
        key: 'Esc',
        action: 'shortcuts.actionDescription.navigateToProjectView',
      },
      {
        key: 'ArrowLeft',
        action: 'shortcuts.actionDescription.selectPreviousAsset',
      },
      {
        key: 'ArrowRight',
        action: 'shortcuts.actionDescription.selectNextAsset',
      },
      {
        key: 'ArrowUp',
        action: 'shortcuts.actionDescription.selectPreviousAssetsRow',
      },
      {
        key: 'ArrowDown',
        action: 'shortcuts.actionDescription.selectNextAssetsRow',
      },
    ],
  },
];
