<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.filtered-list.title'
        | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="div_flist">
    <div class="filter-imgs" id="filteredList">
      <textarea
        #listTextArea
        class="list-textarea fs-sm-txt"
        readonly
        name="listTemp"
        [(ngModel)]="filteredList"
      >
      </textarea>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button
    class="fs-dialog-btn w-auto ml-3"
    (click)="onClickExport(exportType.Excel)"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.export'
        | translate
    }}
    as xls
  </button>

  <button
    class="fs-dialog-btn w-auto ml-3"
    (click)="onClickExport(exportType.Pdf)"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.export'
        | translate
    }}
    as PDF
  </button>

  <button class="fs-dialog-btn w-auto ml-3" (click)="onClickSelectAll()">
    {{
      'left-menu.tab-bar.sidemenus.administration.filtered-list.button.select-all'
        | translate
    }}
  </button>

  <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
    {{
      'left-menu.tab-bar.sidemenus.administration.filtered-list.button.close'
        | translate
    }}
  </button>
</div>
