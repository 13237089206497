<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.advanceimage-tagging.title'
        | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <form [formGroup]="taggingForm" class="theme-form">
    <div class="row image_tagging_row">
      <div class="col-3">
        <div>
          <fieldset class="form-group">
            <input
              type="text"
              #prefix
              formControlName="prefix"
              class="form-control"
              [placeholder]="
                'left-menu.tab-bar.sidemenus.administration.advanceimage-tagging.placeholder.prefix'
                  | translate
              "
            />
          </fieldset>
        </div>
      </div>

      <div class="col-3">
        <div>
          <fieldset class="form-group">
            <input
              type="text"
              #pad
              formControlName="pad"
              class="form-control"
              [placeholder]="
                'left-menu.tab-bar.sidemenus.administration.advanceimage-tagging.placeholder.pad'
                  | translate
              "
            />
          </fieldset>
        </div>
      </div>

      <div class="col-3">
        <div>
          <fieldset class="form-group">
            <input
              type="text"
              name="append"
              #append
              formControlName="append"
              class="form-control"
              [placeholder]="
                'left-menu.tab-bar.sidemenus.administration.advanceimage-tagging.placeholder.append'
                  | translate
              "
            />
          </fieldset>
        </div>
      </div>

      <div class="col-3">
        <div>
          <button type="button" (click)="format()" class="btn btn-sub">
            {{
              'left-menu.tab-bar.sidemenus.administration.advanceimage-tagging.button.format'
                | translate
            }}
          </button>
        </div>
      </div>
    </div>

    <div>
      <div class="fs-txt-white-dark-color font-weight-bold">
        {{
          'left-menu.tab-bar.sidemenus.administration.advanceimage-tagging.labels.filename'
            | translate
        }}
      </div>
      <div class="ccontent">
        <div *ngFor="let fields of imageTagging">
          <input class="chover" type="text" value="{{ fields }}" />
          <br />
        </div>
        <textarea
          class="form-control"
          #unkownImageList
          formControlName="unkownImageList"
          name="unkownImageList"
        >
        </textarea>
      </div>
    </div>
    <div class="row mt-3">
        <div class="col-3 m-0">
          {{
            'left-menu.tab-bar.sidemenus.administration.advanceimage-tagging.labels.select-tag'
              | translate
          }}
        </div>
        <div class="col-6 select_talent">
          <div class="form-group down-selectpic">
            <div
              class="list-album_actor"
              *ngIf="groupedAlbumActor.length > 0 && !comingFromWideEdit"
            >
              <mat-select
                class="form-control fs-selectbox-dark"
                formControlName="selectedAlbumActor"
                disableOptionCentering
                panelClass="fs-actor-option-position-panel fs-option-position-panel"
                [placeholder]="
                  'left-menu.tab-bar.sidemenus.administration.advanceimage-tagging.placeholder.albumoractor'
                    | translate
                "
                (valueChange)="selectedItems($event)"
              >
                <mat-optgroup
                  *ngFor="let group of groupedAlbumActor"
                  [label]="group.label"
                >
                  <mat-option
                    class="fs-sm-txt"
                    *ngFor="let item of group.items"
                    [value]="item.value"
                  >
                    {{ item.label }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
            </div>

            <div class="list-album_actor" *ngIf="comingFromWideEdit">
              <mat-select
                class="form-control fs-selectbox-dark"
                formControlName="selectedAlbumActor"
                disableOptionCentering
                panelClass="fs-actor-option-position-panel fs-option-position-panel"
                [placeholder]="
                  'left-menu.tab-bar.sidemenus.administration.advanceimage-tagging.placeholder.album'
                    | translate
                "
                (valueChange)="selectedItems($event)"
              >
                <mat-optgroup
                  *ngFor="let group of groupedAlbumForWideEdit"
                  [label]="group.label"
                >
                  <mat-option
                    class="fs-sm-txt"
                    *ngFor="let item of group.items"
                    [value]="item.value"
                  >
                    {{ item.label }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
            </div>
          </div>
        </div>
      </div>
  </form>

  <div>
    <mat-checkbox
      name="isCaseSensitive"
      class="fs-checkbox"
      [checked]="isCaseSensitive"
      (change)="isCaseSensitiveEvent($event)"
    >
      <span class="cb-title">
        {{
          'left-menu.tab-bar.sidemenus.administration.advanceimage-tagging.labels.case-sensitive'
            | translate
        }}</span
      >
    </mat-checkbox>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button class="fs-dialog-btn w-auto mr-3" (click)="reset()">
    {{
      'left-menu.tab-bar.sidemenus.administration.advanceimage-tagging.button.reset'
        | translate
    }}
  </button>

  <button
    class="fs-dialog-btn w-auto"
    [disabled]="!taggingForm.valid || !gotoSettings"
    (click)="getApply()"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.advanceimage-tagging.button.apply'
        | translate
    }}
  </button>
</div>
