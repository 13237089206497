import { Component, Input } from '@angular/core';

@Component({
  selector: 'fs-char-icon-component',
  templateUrl: './char-icon-component.component.html',
  styleUrls: ['./char-icon-component.component.scss'],
})
export class CharIconComponentComponent {
  @Input() isCharIcon;
  @Input() isChar;
  @Input() isHighlighted;
  @Input() imgData;
}
