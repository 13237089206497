<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.apply-exif.title' | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="container">
    <div class="row form-group">
      <label class="control-label col-4 col-form-label">
        {{
          'left-menu.tab-bar.sidemenus.administration.apply-exif.labels.copyright'
            | translate
        }}
      </label>

      <div class="col-8">
        <input
          type="text"
          [(ngModel)]="applyExifData.copyright"
          class="form-control"
          name="Copyright"
          required
        />
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-4 col-form-label">
        {{
          'left-menu.tab-bar.sidemenus.administration.apply-exif.labels.author'
            | translate
        }}
      </label>

      <div class="col-8">
        <input
          type="text"
          [(ngModel)]="applyExifData.author"
          class="form-control"
          name="Author"
          required
        />
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button
    class="fs-dialog-btn w-auto ml-3"
    data-dismiss="modal"
    (click)="confirmAction()"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.apply-exif.button.apply'
        | translate
    }}
  </button>

  <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
    {{
      'left-menu.tab-bar.sidemenus.administration.apply-exif.button.close'
        | translate
    }}
  </button>
</div>
