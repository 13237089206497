import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ImagecaptionService } from '../imagecaption-report.service';
import { TranslateService } from '@ngx-translate/core';
import { SidebarService } from '../../../../../../sidebar-service/sidebar-service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Modalstatusservice } from '../../../../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../../../shared/services/loader.service';
import { UserService } from '../../../../../../../../shared/services/user.service';
import { ConstantsGlobal } from '../../../../../../../../shared/constants/constants';
import { Utils } from '../../../../../../../../shared/utils/utils';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../../../shared/store/selected-images-store';
import { ExportType } from '../../../../../../../../shared/models/enum/ExportType';
import { DialogService } from '../../../../../../../../shared/services/dialog/dialog.service';
import { FilterEnum } from '../../../../../../../../shared/enum/filter.enum';
import { UnsubscriberComponent } from '../../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../../shared/services/api-error-handler.service';
import { MatPaginator } from '@angular/material/paginator';

class Assets {
  count: number;
  actors: any;
  caption: any;
}

@Component({
  selector: 'fs-caption-report',
  templateUrl: './caption-report.component.html',
  styleUrls: ['./caption-report.component.scss'],
})
export class CaptionReportComponent
  extends UnsubscriberComponent
  implements OnInit, OnDestroy
{
  // array of all items to be paged
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('scrollTableCont') scrollTableCont: ElementRef;
  @ViewChild('primengTable') primengTable: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['imageId', 'count', 'actors', 'captions'];

  defaultPDFColumnNames = ['Image Range', 'Count', 'Actors', 'Captions'];
  dataSource = new MatTableDataSource([]);

  allItems: any;
  getDataFromService: any;
  sortF: string;
  Imagecaptionreportassets: Assets[] = [];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject();

  strFindTOtext = '';
  strReplaceTOtext = '';
  projectDetail;
  currentProjectid: number;
  filterName: string;
  captionType: string;
  selectedAlbumImageIds: Array<any> = [];
  textFoundRowNumber = 0;
  textFoundIndexes: any = [];
  findIndexCounter = 0;
  arrListToExport: any = [];
  noOfImages: number;
  exportType = ExportType;
  constantsGlobalData: typeof ConstantsGlobal;
  existingImageCationData = [];
  captionAccess = false;
  existingCaptionData: any[];
  itemsPerPage = 250;
  totalItems: number;
  totalPages: number;
  currentPage = 0;
  reports = [];
  filteredReports = [];

  constructor(
    private imagecaptionService: ImagecaptionService,
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private viewProjectService: ViewProjectService,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private userService: UserService,
    private router: Router,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private sidebarService: SidebarService,
    private dialogService: DialogService,
    private selectedImagesStore: SelectedImagesStore,
    private dialogRef: MatDialogRef<CaptionReportComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { existingCaptionData: any[] },
  ) {
    super();
    this.existingCaptionData = data.existingCaptionData;
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(): void {
    this.closeWindow();
  }

  ngOnInit(): void {
    this.constantsGlobalData = ConstantsGlobal;

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectDetailPermission) => {
        if (!Utils.isObjectEmpty(projectDetailPermission)) {
          this.projectDetail = projectDetailPermission;
          this.noOfImages = projectDetailPermission.viewSettingDTO.imagePerPage;
          this.captionAccess = projectDetailPermission.permissionDTO
            ? projectDetailPermission.permissionDTO.captionAccess
            : false;
        } else {
          this.captionAccess = false;
        }
      });

    this.currentProjectid = this.viewProjectService.getProjectId();
    this.filterName = this.viewProjectService.getCurrentFilter();
    this.captionType = this.imagecaptionService.getCaptionType();

    this.existingImageCationData = [];
    if (this.existingCaptionData?.length > 0) {
      this.loaderService.displayLoader(true);
      this.onClickClearValues();
      this.reports = this.existingCaptionData;
      this.initPagination(this.existingCaptionData);

      this.loaderService.displayLoader(false);
    } else {
      if (this.captionType === '1') {
        this.filterName = FilterEnum.ShowAll;
      }
      this.loaderService.displayLoader(true);
      this.imagecaptionService
        .callImagecaptionReport(this.currentProjectid, this.filterName || FilterEnum.ShowAll)
        .pipe(takeUntil(this.destroy$))
        .subscribe((resData) => {
          if (resData?.length) {
            this.reports = resData;
            this.onClickClearValues();
            this.initPagination(resData);
          }
          this.loaderService.displayLoader(false);
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    }
  }

  initPagination(resData: any[]): void {
    if (resData?.length) {
      this.totalItems = resData.length;
      this.totalPages = this.totalItems / this.itemsPerPage;
      this.currentPage = 0;
      const captionArray = this.totalItems > this.itemsPerPage
        ? resData.slice(this.currentPage * this.itemsPerPage, (this.currentPage + 1) * this.itemsPerPage)
        : resData;

      this.prepareData(captionArray);
    } else {
      this.totalItems = 0;
      this.totalPages = 0;
      this.currentPage = 0;
      this.dataSource.data = [];
    }
  }

  setPagination(event: {length: number, pageIndex: number}): void {
    this.totalItems = event.length;
    this.totalPages = this.totalItems / this.itemsPerPage;
    this.currentPage = event.pageIndex;
    const dataArray = this.filteredReports.length ? this.filteredReports : this.reports;
    this.prepareData(dataArray.slice(this.currentPage * this.itemsPerPage, (this.currentPage + 1) * this.itemsPerPage));
  }

  highlight(str, text) {
    const val = str.value;
    const splitStrInArr = val.split(' ');
    const convertedArr = splitStrInArr
      .map((item) => {
        if (item.toLowerCase() === text.toLowerCase()) {
          item = '<span class="fs-highlight-text">' + item + '</span>';
        }
        return item;
      })
      .join(' ');
    return {
      ...str,
      value: convertedArr,
    };
  }

  prepareData(response): void {
    const captionData = [];
    response.forEach((imgdata) => {
      captionData.push({
        imageId: imgdata.assets,
        count: imgdata.assets.length,
        actors: imgdata.tags.map((actors) => actors.value),
        captions: imgdata.caption ? [imgdata.caption] : [],
        isTextFound: false,
      });
      this.dataSource.data = captionData;
      this.dataSource.sort = this.sort;
      this.setSortConditions();
    });
  }

  setSortConditions() {
    this.dataSource.sortingDataAccessor = (row, columnName): string => {
      if (columnName === 'imageId') return row['imageId'][0].value;
      if (columnName === 'captions') return row['captions'][0].value;
      const columnValue = row[columnName];
      return columnValue;
    };
  }

  getImageTagList(listIds) {
    const arrayToSend = [];
    listIds.forEach((publishedTag) => {
      const index = this.projectDetail.projectTagListDTO
        .map(function (e) {
          return e.id;
        })
        .indexOf(publishedTag);
      if (index !== -1) {
        arrayToSend.push(this.projectDetail.projectTagListDTO[index].tagName);
      }
    });

    return arrayToSend;
  }

  onClickFindBtn() {
    this.loaderService.displayLoader(true);
    if (this.strFindTOtext) {
      const filteredArray = this.reports.filter((item) => {
        if (item.caption) {
          if (
            item.caption.value
              .toLowerCase()
              .includes(this.strFindTOtext.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });

      this.filteredReports = Utils.deepCopyObject(filteredArray);
      this.paginator?.firstPage();
      this.initPagination(this.filteredReports);
      this.dataSource.data = this.dataSource.data.map(
        (item) => {
          item.captions[0] = this.highlight(
            item.captions[0],
            this.strFindTOtext,
          );
          return item;
        },
      );
      this.loaderService.displayLoader(false);
    } else {
      this.paginator?.firstPage();
      this.initPagination(this.reports);
      this.filteredReports = [];
      this.loaderService.displayLoader(false);
    }
  }

  changeSort(event): void {
    if (!event.order) {
      this.sortF = 'year';
    } else {
      this.sortF = event.field;
    }
  }

  onClickImageName(imageRowData): void {
    if (!this.captionAccess) {
      return;
    }
    const filter = 'report:caption';
    this.selectedAlbumImageIds = imageRowData.map((item) => item.id);
    this.viewProjectService.setProjectImageIDs(this.selectedAlbumImageIds);

    const assetsIds = Utils.getImageIdsBasedOnPage(
      1,
      this.noOfImages,
      this.selectedAlbumImageIds,
    );
    const data = {
      assetIds: assetsIds,
      projectId: this.viewProjectService.getProjectId(),
      filter: filter,
    };
    this.loaderService.displayLoader(true);
    this.viewProjectService
      .callAssetsInfoBasedOnAssetsId(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (resData) => {
          if (resData.assetInfoDTOList.length > 0) {
            this.closeWindow();
            this.viewProjectService.setCurrentFilter(filter);
            this.selectedImagesStore.clear();
            this.router.navigate([
              Utils.prepareFilterNavigationURL(
                this.viewProjectService.getProjectRole(),
                this.viewProjectService.getProjectId(),
                filter,
                1,
              ),
            ]);

            this.sidebarService.setAlbumImageData(resData);
            this.viewProjectService.isProjectDataLoadedFromNoteReports.next(
              true,
            );
          } else {
            this.alertCustom(this.translate.instant('view-project.alert2'));
          }
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  alertCustom(displayText: string): void {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  closeWindow(): void {
    this.dataSource.data = [];
    this.dialogRef.close();
  }

  onClickClearValues(): void {
    this.strReplaceTOtext = '';
    this.strFindTOtext = '';
    this.dataSource.data = [];
  }

  onsubmitEvent(): void {
    this.onClickFindBtn();
  }

  replaceCaption(type) {
    if (this.strFindTOtext.length === 0) {
      this.alertCustom('Please search value first');
      return false;
    }
    const payload = [];
    const filteredArray = (this.filteredReports.length
      ? this.filteredReports
      : this.reports).filter((item) => {
      if (
        item.caption.value
          .toLowerCase()
          .indexOf(this.strFindTOtext.toLowerCase()) !== -1
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (!filteredArray.length) {
      this.alertCustom('No matches found, please enter another search value');
      return false;
    }

    if (type === 1) {
      const firstcaption = filteredArray[0].caption;
      const val = firstcaption.value;
      const splitStrInArr = val.split(' ');
      const convertedArr = splitStrInArr
        .map((item) => {
          if (item.toLowerCase().includes(this.strFindTOtext.toLowerCase())) {
            item = this.strReplaceTOtext;
          }
          return item;
        })
        .join(' ');

      payload.push({
        ...firstcaption,
        value: convertedArr,
      });
    } else {
      filteredArray.forEach((item) => {
        const firstcaption = item.caption;
        const val = firstcaption.value;
        const splitStrInArr = val.split(' ');
        const convertedArr = splitStrInArr
          .map((item) => {
            if (item.toLowerCase().includes(this.strFindTOtext.toLowerCase())) {
              item = this.strReplaceTOtext;
            }
            return item;
          })
          .join(' ');
        payload.push({
          ...firstcaption,
          value: convertedArr,
        });
      });
    }
    if (payload.length > 0) {
      const payloadModi = {
        projectId: Number(this.currentProjectid),
        caption: payload,
      };
      this.loaderService.displayLoader(true);
      this.imagecaptionService
        .callReplaceCaption(payloadModi)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res) {
            if (type === 1) {

              this.reports = JSON.parse(
                JSON.stringify(this.reports),
              ).map((item) => {
                if (item.caption.id === payload[0].id) {
                  item.caption = payload[0];
                }
                return item;
              });

              this.filteredReports = JSON.parse(
                JSON.stringify(this.filteredReports),
              ).map((item) => {
                if (item.caption.id === payload[0].id) {
                  item.caption = payload[0];
                }
                return item;
              });


              this.dataSource.data = this.dataSource.data.filter(
                (item, index) => {
                  if (index === 0) {
                    return false;
                  } else {
                    return true;
                  }
                },
              );
              if (this.dataSource.data.length === 0) {
                this.onClickClearValues();
                this.initPagination(
                  this.filteredReports.length
                    ? this.filteredReports
                    : this.reports
                );
              }
              this.alertCustom('Changes successfully !');
            } else {
              this.reports = JSON.parse(
                JSON.stringify(this.reports),
              ).map((item) => {
                payload.forEach((child) => {
                  if (item.caption.id === child.id) {
                    item.caption = child;
                  }
                });
                return item;
              })

              this.filteredReports = JSON.parse(
                JSON.stringify(this.filteredReports),
              ).map((item) => {
                payload.forEach((child) => {
                  if (item.caption.id === child.id) {
                    item.caption = child;
                  }
                });
              return item;
              });

              this.onClickClearValues();
                this.initPagination(
                  this.filteredReports.length
                    ? this.filteredReports
                    : this.reports
                );
              this.alertCustom('Changes successfully !');
            }
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom('No replace of text happen');
          }
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    }
  }

  onClickExportBtn(reportType): void {
    this.arrListToExport = [];

    this.reports.forEach((noteList) => {
      this.arrListToExport.push({
        'Image Range': this.returnImageIds(noteList.assets),
        Count: noteList.assets.length,
        Actors: noteList.tags.map((actors) => actors.value  ).toString(),
        Captions:
          noteList.caption ? noteList.caption.value : '',
      });
    });

    if (reportType === this.exportType.Excel) {
      this.userService.exportReport(
        'Caption Report',
        this.arrListToExport,
        'CaptionsReport.xlsx',
      );
    } else {
      this.userService.exportPDFData(
        this.arrListToExport,
        this.defaultPDFColumnNames,
      );
    }
  }

  returnImageIds(imageId) {
    if (imageId.length > 1) {
      return imageId[0].value + ' Thru ' + imageId[imageId.length - 1].value;
    } else {
      return imageId[0].value;
    }
  }

  ngOnDestroy(): void {
    this.dataSource.data = [];
    super.ngOnDestroy();
  }
}
