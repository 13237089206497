import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CookieBannerComponent } from './cookie-banner.component';

@Injectable()
export class CookieBannerService {
  private renderer: Renderer2;
  private overlayRef: OverlayRef = this.overlay.create({
    panelClass: [
      'col-md-4',
      'mr-3',
      'ml-3',
      'col-sm-12',
      'fs-cookie-container',
    ],
  });

  constructor(
    private overlay: Overlay,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  open() {
    if (!this.isOverlayAttached()) {
      const bannerOverlayPortal = new ComponentPortal(CookieBannerComponent);
      this.overlayRef.attach(bannerOverlayPortal);
      this.show();
    }
  }

  close() {
    if (this.isOverlayAttached()) {
      setTimeout(() => {
        this.overlayRef.detach();
      }, 400);
    }
  }

  private show() {
    setTimeout(() => {
      this.renderer.addClass(
        this.overlayRef.overlayElement,
        'fs-active-cookie-container',
      );
    }, 500);
  }

  isOverlayAttached() {
    return this.overlayRef.hasAttached();
  }
}
