import {
  Component,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { ViewProjectService } from '../../services/view-project.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnsubscriberComponent } from '../unsubscriber/unsubscriber.component';
import { AssetInfoDTOList } from '../../models/assetInfo.model';
import { SelectedImagesStore } from '../../store/selected-images-store';

@Component({
  selector: 'fs-exclusive-sensitive-asset-dialog',
  templateUrl: './exclusive-sensitive-asset-dialog.component.html',
  styleUrls: ['./exclusive-sensitive-asset-dialog.component.scss'],
})
export class ExclusiveSensitiveAssetDialogComponent
  extends UnsubscriberComponent
  implements OnDestroy
{
  openDownloadDialogFn: (
    id?: number,
    imageCount?: number,
    assetIds?: number[],
  ) => void;
  extractAssets: AssetInfoDTOList[];

  constructor(
    public viewProjectService: ViewProjectService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      openDownloadDialog: (
        id?: number,
        imageCount?: number,
        assetIds?: number[],
      ) => void;
      extractAssets: AssetInfoDTOList[];
      isFromTopMenu: boolean;
      assetIds?: number[];
      albumId?: number;
      imageCount?: number;
    },
    public selectedImagesStore: SelectedImagesStore,
    private dialogRef: MatDialogRef<ExclusiveSensitiveAssetDialogComponent>,
  ) {
    super();
    this.openDownloadDialogFn = data.openDownloadDialog;
    this.extractAssets = data.extractAssets;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  closeWindow() {
    this.dialogRef.close();
  }

  onOpenExpressDownload(): void {
    if (this.data.isFromTopMenu) {
      this.handleTopMenuCase();
    } else {
      this.handleNonTopMenuCase();
    }
    this.closeWindow();
  }

  private handleTopMenuCase(): void {
    this.extractAssets.forEach((asset) =>
      this.selectedImagesStore.remove(asset.id),
    );
    this.openDownloadDialogFn();
  }

  private handleNonTopMenuCase(): void {
    const filteredAssetIds = this.data.assetIds.filter(
      (item) => !this.isAssetInExtractAssets(item),
    );
    this.openDownloadDialogFn(
      this.data.albumId,
      this.data.imageCount,
      filteredAssetIds,
    );
  }

  private isAssetInExtractAssets(assetId: number): boolean {
    return this.extractAssets.some((obj) => obj.id === assetId);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
