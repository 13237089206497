import { LauncherService } from './../../../../../launcher/launcher.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '../../../../../../shared/services/dialog/dialog.service';
import { AssetInfo } from '../../../../../../shared/models/assetInfo.model';
import { Modalstatusservice } from '../../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { ConstantsGlobal } from '../../../../../../shared/constants/constants';
import { Utils } from '../../../../../../shared/utils/utils';
import { FinalizationComponent } from './finalization/finalization.component';
import { PkoLogoutComponent } from './pko-logout/pko-logout.component';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../../../../../shared/services/user.service';
import { UserDetails } from '../../../../../../shared/models/userdetails.model';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'fs-finalize-images',
  templateUrl: './finalize-images.component.html',
  styleUrls: ['./finalize-images.component.scss'],
})
export class FinalizeImagesComponent
  extends UnsubscriberComponent
  implements OnInit
{
  isPrioritypixBtnShown: boolean;
  userData: UserDetails;
  priritypixImageCount: number;
  priorityPixImageAssetsIds: number[];
  projectImageData: AssetInfo = new AssetInfo();
  constantsGlobalData: any;
  selectedProjectname = '';
  projectDetail: any;

  constructor(
    private modalService: Modalstatusservice,
    private router: Router,
    private viewProjectService: ViewProjectService,
    private launcherService: LauncherService,
    private dialogService: DialogService,
    private userService: UserService,
  ) {
    super();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.viewProjectService.priorityPixAssetIds$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: number[] = []) => {
        this.priorityPixImageAssetsIds = data;
      });

    this.viewProjectService.isPiorityPixDataLoadedGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.isPrioritypixBtnShown = res;
      });

    this.viewProjectService.priorityPixImageCountGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.priritypixImageCount = res;
      });

    this.userService.currentUserGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((userData: UserDetails) => {
        if (!Utils.isObjectEmpty(userData)) {
          this.userData = userData;
        }
      });

    this.launcherService.selectedProjectNameGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.selectedProjectname = data;
      });

    this.userService.currentUserGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((userData: UserDetails) => {
        if (Object.keys(userData).length !== 0) {
          this.userData = userData;
        }
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.projectDetail = data;
        }
      });
  }

  getTagId() {
    try {
      const userId = this.userData.id;
      const filteredArray = this.projectDetail.projectTagListDTO.filter(
        (item) => {
          if (item.userId === userId) {
            return true;
          } else {
            return false;
          }
        },
      );
      return filteredArray[0].id;
    } catch {
      return 0;
    }
  }

  onClickFinalizePriorityPix() {
    const obj = {
      action: 'priority-finalize',
      assetIds: this.priorityPixImageAssetsIds,
      projectId: this.viewProjectService.getProjectId(),
      tagId: this.getTagId(),
      batchId: 0,
      batchName: '',
      projectName: this.projectDetail.projectName,
      useSetup: this.projectDetail.useSetup,
    };
    this.callfinalizationModal(obj);
  }

  callfinalizationModal(data: any) {
    this.dialogService.open(FinalizationComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
      ],
      data,
    });
  }

  openFinilizePopup() {
    this.dialogService.open(PkoLogoutComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
      ],
    });
  }
}
