import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Directive({
  selector: '[fsNgSelectDropDownMinHeight]',
})
export class NgSelectDropDownMinHeightDirective implements OnDestroy {
  private resizeObserver: ResizeObserver;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private ngSelectComponent: NgSelectComponent,
  ) {
    this.resizeObserver = new ResizeObserver(this.initDropDownHeight);
    this.resizeObserver.observe(
      this.ngSelectComponent.element.querySelector('.total-padding'),
    );
  }

  initDropDownHeight = (): void => {
    const minItemHeightWithPadding = this.getNgSelectOptionItemRef().reduce(
      (totalOffset, htmlItem) => totalOffset + htmlItem.offsetHeight,
      0,
    );
    if (this.getNgSelectDropdownRef())
      this.renderer.setStyle(
        this.getNgSelectDropdownRef(),
        'height',
        `${minItemHeightWithPadding}px`,
      );
  };

  getNgSelectOptionItemRef(): Array<HTMLElement> {
    return Array.from(
      this.ngSelectComponent.element.querySelectorAll('.ng-option'),
    );
  }

  getNgSelectDropdownRef(): HTMLElement {
    return this.ngSelectComponent.element.querySelector('.scroll-host');
  }

  ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.elementRef.nativeElement);
    this.resizeObserver.disconnect();
  }
}
