<div class="fs-popup-title notes-menu-wrapper" align="center">
  <div (blur)="closeWindow()">
    <label
      class="notes-menu-option"
      *ngIf="imgData.assetNoteDTOList.length > 0"
      (click)="
        onSelectImageOnClickNotes({
          selectImg: imgData,
          index: ind,
          event: $event,
          noteOptionType: 'view'
        })
      "
    >
      {{ 'left-menu.tab-bar.sidemenus.imageInfo.labels.viewNote' | translate }}
    </label>
    <label
      class="notes-menu-option m-0"
      (click)="
        onSelectImageOnClickNotes({
          selectImg: imgData,
          index: ind,
          event: $event,
          noteOptionType: 'add'
        })
      "
    >
      {{ 'left-menu.tab-bar.sidemenus.imageInfo.labels.addNote' | translate }}
    </label>
  </div>
</div>
