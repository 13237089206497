import { Component, Input } from '@angular/core';
import { UnhideImagesService } from './unhide-images.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { Activemodal } from '../../../../../../../shared/models/activemodal.model';
import { AssetInfo } from '../../../../../../../shared/models/assetInfo.model';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../../shared/store/selected-images-store';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-unhide-images',
  templateUrl: './unhide-images.component.html',
  styleUrls: ['./unhide-images.component.scss'],
})
export class UnhideImagesComponent extends UnsubscriberComponent {
  @Input() isTaggerRole: boolean;
  activemodal: Activemodal = new Activemodal();
  projectImageData: AssetInfo = new AssetInfo();

  constructor(
    private unhideImagesService: UnhideImagesService,
    private viewProjectService: ViewProjectService,
    private modalStatusService: Modalstatusservice,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private selectedImagesStore: SelectedImagesStore,
    private dialogService: DialogService,
  ) {
    super();
  }

  onClickUnHide() {
    if (this.selectedImagesStore.hasEntities()) {
      this.dialogService
        .openConfirmationDialog({
          message:
            this.translate.instant('view-project.confirm13') +
            this.selectedImagesStore.size() +
            this.translate.instant('view-project.confirm8'),
          title: this.translate.instant('view-project.confirm9'),
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((state: boolean) => {
          if (state) {
            this.callUnHideAPI();
          }
        }, (error => {
          this.apiErrorHandlerService.getHandler().handle(error);
        }));
    } else {
      this.alertCustom(this.translate.instant('view-project.alert25'));
    }
  }

  callUnHideAPI() {
    this.loaderService.displayLoader(true);
    const data = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      projectId: this.viewProjectService.getProjectId(),
    };
    this.unhideImagesService
      .putUnHideImages(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.loaderService.displayLoader(false);
          if (res.status) {
            this.updateProjectData(res.t);
          } else {
            this.alertCustom(res.message);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  updateProjectData(res) {
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    res.forEach((eachObj) => {
      const imageIndex = this.projectImageData.assetInfoDTOList
        .map((e) => e.id)
        .indexOf(eachObj.id);

      if (imageIndex !== -1) {
        this.projectImageData.assetInfoDTOList[imageIndex].hidden =
          eachObj.value;
      }
    });
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
