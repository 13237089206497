import { Component, Input, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';
import { ImageInfoService } from '../../../dashboard-shared/left-menu/menus/imageinfo/imageinfo.service';
import { LauncherService } from '../../../../launcher/launcher.service';
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';
import { TopmenusService } from '../../top-menu/top-menu.service';
import { HelpWindowComponent } from '../../../../../shared/layout/help/help.component';
import { Activemodal } from '../../../../../shared/models/activemodal.model';
import { UserRole } from '../../../../../shared/enum/user-role.enum';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { CurrentStateService } from '../../../../../shared/services/current.state.service';
import { Modalstatusservice } from '../../../../../shared/services/modalstatus.service';
import { UserService } from '../../../../../shared/services/user.service';
import { ConstantsGlobal } from '../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../../../../../shared/utils/utils';
import { Taggingview1Component } from './administration/taggingview-1/taggingview-1.component';
import { Taggingview2Component } from './administration/taggingview-2/taggingview-2.component';
import { ConflictsComponent } from './audit/conflicts/conflicts.component';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import { PopupService } from '../../../../../shared/services/popup/popup.service';
import { ProjectRole } from '../../../../../shared/enum/project-role.enum';

enum LeftMenuAccordionType {
  ALBUMS,
  IMAGE_INFO,
  ADMINISTRATOR,
  META_DATA,
  AUDIT,
  SIGN_OFF,
  WIDEEEDIT,
  ADHOC,
}

@Component({
  selector: 'fs-tab-bar-menus',
  templateUrl: './sidemenus.component.html',
  styleUrls: ['./sidemenus.component.scss'],
})
export class SideMenusComponent
  extends UnsubscriberComponent
  implements OnInit
{
  @Input() accordionElemRef: HTMLDivElement;
  isLeftMenuPartShown = true;
  currentState: any;
  projectAuthority: string;
  userAuthority: string;
  selectedProjectValue: any;
  signoffManagement?: false;
  datgManagement?: false;
  tabIndex = -1;
  constantsGlobalData: any;
  activemodal: Activemodal = new Activemodal();
  isEnlargeActive = false;
  activateModelHolder: any = {};
  isEnlarge = false;
  isSuperZoom = false;
  isTaggerRole: boolean;
  taggingView1Open = false;
  taggingView2Open = false;
  auditTagViewOpen = false;
  leftMenuAccordionType = LeftMenuAccordionType;
  userRole = UserRole;
  projectRole = ProjectRole;
  metaTool = false;

  constructor(
    private router: Router,
    private sessionStorageSer: SessionStorageService,
    private translate: TranslateService,
    private viewProjectService: ViewProjectService,
    private topmenuService: TopmenusService,
    private popupService: PopupService,
    private currentStateService: CurrentStateService,
    private modalStatusService: Modalstatusservice,
    private userService: UserService,
    private launcherService: LauncherService,
    private imageInfoService: ImageInfoService,
    private dialogService: DialogService,
  ) {
    super();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.isTaggerRole = this.userService.isAdminWithTaggerRole();

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority) => {
        this.userAuthority = userAuthority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url.toLowerCase().includes('enlarge')) {
          this.isEnlargeActive = true;
          this.setTabIndexOnEnlarge();
        } else {
          this.isEnlargeActive = false;
        }
      }
    });

    this.topmenuService.topMenuStatus
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res === 'Enlarge') {
          this.isEnlarge = true;
        } else {
          this.isEnlarge = false;
        }
        if (res === 'SuperZoom') {
          this.isSuperZoom = true;
        } else {
          this.isSuperZoom = false;
        }
      });

    this.viewProjectService.isTaggingView1OpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.taggingView1Open = status;
      });

    this.viewProjectService.isTaggingView2OpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.taggingView2Open = status;
      });

    this.viewProjectService.isAuditTaggingViewGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.auditTagViewOpen = status;
      });

    this.viewProjectService.activatedLeftTabIndexGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((index) => {
        this.tabIndex = index;
      });

    this.viewProjectService.isAddNoteFromAddNoteGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response) {
          this.tabIndex = -1;
        }
      });

    this.viewProjectService.isAddNoteFromTopMenuGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response) {
          this.tabIndex = -1;
        }
      });

    this.viewProjectService.isLeftMenuOpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((leftmenuState) => {
        this.isLeftMenuPartShown = leftmenuState;
      });

    this.currentStateService.getCurrentState
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentState) => {
        this.currentState = currentState;
      });

    this.launcherService.selectedProjectGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectValue) => {
        this.selectedProjectValue = Number(projectValue);
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          if (res.permissionDTO) {
            this.signoffManagement = res.permissionDTO.signoffManagement;
            this.datgManagement = res.permissionDTO.datgManagement;
            this.metaTool = res.permissionDTO.metaTool;
          }
        }
      });

    this.viewProjectService.viewProjectNoteIconClick
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.tabIndex = -1;
        if (status) {
          this.tabIndex = LeftMenuAccordionType.IMAGE_INFO;
          this.imageInfoService.setImageTab('notes');
        }
      });

    this.viewProjectService.activatedLeftTabIndexGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((index) => {
        if (index === LeftMenuAccordionType.ALBUMS) {
          this.accordionScrollToTop();
        }
      });

    this.setTabIndexOnEnlarge();
  }

  setTabIndexOnEnlarge() {
    if (this.checkIfTaggingEnable()) {
      if (this.isEnlargeActive) {
        this.tabIndex = -1;
      }
    }
  }

  checkIfTaggingEnable() {
    const taggingEnableFor = [
      Taggingview1Component.identifier,
      Taggingview2Component.identifier,
      ConflictsComponent.identifier,
    ];
    return taggingEnableFor.some((tag: string) => {
      return this.dialogService.getDialogState(tag).currentState.opened;
    });
  }

  onTabClose() {
    this.viewProjectService.setActivatedLeftTabIndex(-1);
    if (this.currentState.displaySetting.leftMenu.items.menu.items.imageInfo) {
      this.viewProjectService.isAddNoteFromAddNote.next(false);
    }
    this.viewProjectService.setViewProjectNoteIconClick(false);
    this.viewProjectService.isAddNoteFromTopMenu.next(false);
  }

  onTabOpen(index: LeftMenuAccordionType) {
    this.tabIndex = index;

    this.projectAuthority === ProjectRole.ROLE_TALENT
      ? this.viewProjectService.setActivatedLeftTabIndex(
          LeftMenuAccordionType.IMAGE_INFO,
        )
      : this.viewProjectService.setActivatedLeftTabIndex(index);

    this.viewProjectService.isAddNoteFromAddNote.next(false);
    this.viewProjectService.isAddNoteFromTopMenu.next(false);
  }

  accordionScrollToTop() {
    if (this.accordionElemRef) {
      this.accordionElemRef.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  showHelpWindow(helpTopic?, videoName?) {
    this.dialogService.open(HelpWindowComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
        'fs-dialog-no-overflow-panel',
      ],
      data: {
        topicName: helpTopic,
        videoName: videoName,
      },
    });
  }
}
