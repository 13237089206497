import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProjectList } from '../../shared/models/projectlist.model';
import { ApiService } from '../../shared/services/api.service';
import { AppSettings } from '../../appSettings/appSettings';
import { Utils } from '../../shared/utils/utils';

@Injectable()
export class LauncherService {
  private isProjectChange = new BehaviorSubject<boolean>(false);
  isProjectChangeGlobal = this.isProjectChange.asObservable();

  searchList: any = [];
  list: any = [];
  private projectListSubject = new BehaviorSubject<any>({});
  public projectListGlobal = this.projectListSubject.asObservable();

  private selectedProject = new BehaviorSubject<string>('');
  selectedProjectGlobal = this.selectedProject;

  // TODO Add Enum for this 1, 2, 3
  // '1' for exec, '2' - for talent, '3' - for albumviewer. Needed to correctly proceed on BE SIDE
  private projectRole = new BehaviorSubject<string>('');
  projectRoleGlobal = this.projectRole.asObservable();

  private selectedProjectName = new BehaviorSubject<string>('');
  selectedProjectNameGlobal = this.selectedProjectName;

  private selectedSetUpId = new BehaviorSubject<string>('');
  selectedSetUpIdGlobal = this.selectedSetUpId;

  private favProjectListSubject = new BehaviorSubject<any>([]);
  public favProjectListGlobal = this.favProjectListSubject.asObservable();

  private isGallaryMode = new BehaviorSubject<boolean>(false);
  isGallaryModeGlobal = this.isGallaryMode.asObservable();

  private isFavGallaryMode = new BehaviorSubject<boolean>(false);
  isFavGallaryModeGlobal = this.isFavGallaryMode.asObservable();

  private isProjectUseSetupStatus = new BehaviorSubject<boolean>(false);
  isProjectUseSetupStatusGlobal = this.isProjectUseSetupStatus.asObservable();

  private gallariesDepartmentID = new BehaviorSubject<string>('');
  gallariesDepartmentIDGlobal = this.gallariesDepartmentID;

  private selectedGroupLogo = new BehaviorSubject<string>('');
  selectedGroupLogoGlobal = this.selectedGroupLogo;

  constructor(private apiService: ApiService) {}

  get projectList$() {
    return this.projectListSubject.getValue();
  }

  get selectedProject$() {
    return this.selectedProject.getValue();
  }

  setSelectedDepLogo(logo) {
    this.selectedGroupLogo.next(logo);
  }

  setIsGallaryModeStatus(status) {
    this.isGallaryMode.next(status);
  }

  setProjectRole(role) {
    this.projectRole.next(role);
  }

  setGallariesDepartmentID(id) {
    this.gallariesDepartmentID.next(id);
  }

  setIsFavGallaryModeStatus(status) {
    this.isFavGallaryMode.next(status);
  }

  setIsProjectUseSetupStatus(status) {
    this.isProjectUseSetupStatus.next(status);
  }

  setProjectList(projectList: ProjectList) {
    projectList.list = Utils.getDepartmentNproject(projectList);
    this.projectListSubject.next(projectList);
  }

  resetSelectedDepLogo(): void {
    this.selectedGroupLogo.next('');
  }

  resetProjectList() {
    this.projectListSubject.next([]);
  }

  setSelectedProject(selectedProject) {
    this.selectedProject.next(selectedProject);
  }

  resetSelectedProject() {
    this.selectedProject.next('');
  }

  setSelectedProjectName(selectedProjectName) {
    this.selectedProjectName.next(selectedProjectName);
  }

  setSelectedSetUpId(selectedSetUpId) {
    this.selectedSetUpId.next(selectedSetUpId);
  }

  getProjectList() {
    return this.projectListSubject.value;
  }

  updateProjectChangeState(state) {
    this.isProjectChange.next(state);
  }

  setFavProjectList(favProjectList) {
    this.favProjectListSubject.next(favProjectList);
  }

  getFavProjectList() {
    return this.favProjectListSubject.value;
  }

  getProjectListApiCall(): Observable<ProjectList> {
    this.setFavProjectList([]);
    const path = AppSettings.PROJECT_LIST;
    return this.apiService.get(path).pipe(
      map((data) => {
        this.searchList = [];
        this.setProjectList(Utils.transformData(data));
        this.setFavProjectList(data.fav);
        return data;
      }),
    );
  }

  updateFav(data): Observable<any> {
    const path = AppSettings.UPDATE_FAV;
    return this.apiService.put(path, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  saveFavSeq(data): Observable<any> {
    const path = AppSettings.SAVE_FAV_SEQ;
    return this.apiService.put(path, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }
}
