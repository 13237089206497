import { ElementRef, Inject, Injectable } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { _isTestEnvironment, Platform } from '@angular/cdk/platform';

@Injectable({ providedIn: 'root' })
export class FsOverlayContainer extends OverlayContainer {
  constructor(
    @Inject(DOCUMENT) private document: any,
    public platform: Platform,
    public target: ElementRef,
  ) {
    super(document, platform);
  }

  getAttachedElement() {
    return this.target.nativeElement || this.document.body;
  }

  protected override _createContainer(): void {
    const containerClass = 'cdk-overlay-container';

    if (this._platform.isBrowser || _isTestEnvironment()) {
      const oppositePlatformContainers = this._document.querySelectorAll(
        `.${containerClass}[platform="server"], ` +
          `.${containerClass}[platform="test"]`,
      );

      for (let i = 0; i < oppositePlatformContainers.length; i++) {
        oppositePlatformContainers[i].remove();
      }
    }

    const container = this._document.createElement('div');
    container.classList.add(containerClass);

    if (_isTestEnvironment()) {
      container.setAttribute('platform', 'test');
    } else if (!this._platform.isBrowser) {
      container.setAttribute('platform', 'server');
    }
    this.getAttachedElement().appendChild(container);
    this._containerElement = container;
  }
}
