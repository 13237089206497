import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '../../services/user.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Utils } from '../../utils/utils';
import { takeUntil } from 'rxjs/operators';
import { ShortcutsStore } from '../../store/shortcut-store';
import { ViewProjectService } from '../../services/view-project.service';
import { UserRole } from '../../enum/user-role.enum';
import { UserDetails } from '../../models/userdetails.model';
import { UnsubscriberComponent } from '../unsubscriber/unsubscriber.component';

@Component({
  selector: 'fs-shortcut-details',
  templateUrl: './shortcut-details.component.html',
  styleUrls: ['./shortcut-details.component.scss'],
})
export class ShortcutDetailsComponent
  extends UnsubscriberComponent
  implements OnInit, OnDestroy
{
  projectAuthority: string;
  shortcutsArray = ShortcutsStore;
  displayedColumns: string[] = ['key', 'action'];
  dataSource = new MatTableDataSource([]);

  constructor(
    private userService: UserService,
    private dialogRef: MatDialogRef<ShortcutDetailsComponent>,
    private viewProjectService: ViewProjectService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.dataSource.data = [];

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.dataSource.data = this.shortcutsArray.find(
      (item) => item.role === this.projectAuthority,
    )?.hotkeys;
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(): void {
    this.closeWindow();
  }

  closeWindow(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
