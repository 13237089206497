import { DEPARTMENT, PROJECT } from '../constants/constants';
import { AssetInfo, AssetInfoDTOList } from '../models/assetInfo.model';
import { AppSource } from '../models/enum/AppSource';

export class Utils {
  // Give the list of parent and child album in single array.
  static getValuesByKey(mainObject, key) {
    if (mainObject.length > 0) {
      const albumArray = [];
      mainObject.forEach((element) => {
        if (element.id !== 0) {
          albumArray.push(element);
        }
      });
      const values = [];
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      recursiveFx(albumArray);

      // eslint-disable-next-line no-inner-declarations
      function recursiveFx(object) {
        if (key in object) {
          values.push(object);
        }
        for (const property in object) {
          if (Object.prototype.hasOwnProperty.call(object, property)) {
            if (typeof object[property] === 'object') {
              if (
                property !== 'albumTypeId' &&
                property !== 'active' &&
                property !== 'tagKey' &&
                property !== 'tagColor' &&
                property !== 'tagColorCode' &&
                property !== 'parentAlbumId' &&
                property !== 'permission' &&
                property !== 'expireAt' &&
                property !== 'ownerName'
              ) {
                recursiveFx(object[property]);
              }
            }
          }
        }
      }

      return values;
    } else {
      return [];
    }
  }

  static getImageObjByimageId(imgObj, imageId) {
    const obj = [];
    imgObj.forEach((element) => {
      if (element.id === imageId) {
        obj.push(element);
      }
    });
    return obj;
  }

  static getAssetIds(data) {
    const assetIds = [];
    data.forEach((imageData) => {
      assetIds.push(imageData.id);
    });
    return assetIds;
  }

  static getLocatorIds(data) {
    const locatorIds = [];
    data.forEach((locatorData) => {
      locatorIds.push(locatorData.locator);
    });
    return locatorIds;
  }

  static getAssetDetaillObj(
    token,
    assetUrlRequestDTOList,
    projectID,
    projectDetail,
    username,
    selectedDownloadType = '',
  ) {
    const obj = {
      assetUrlRequestDTOList: assetUrlRequestDTOList,
      projectId: projectID,
      projectName: projectDetail.projectName,
      projectGroupName: projectDetail.projectGroupName,
      location: projectDetail.location,
      locator: projectDetail.locator,
      token: token,
      downloadSize: selectedDownloadType,
    };
    return obj;
  }

  static getWebImageUrl(baseURL, encryptedURL, imageDim) {
    return `${baseURL}${encryptedURL}/${imageDim}`;
  }

  static getGalleryWebImageUrl1(baseURL, encryptedData, imageDim) {
    return baseURL + encryptedData + '/' + imageDim;
  }

  static getImageIdsBasedOnPage(
    currentPageNumber,
    noOfImages,
    projectImageIds,
  ) {
    currentPageNumber =
      Math.ceil(projectImageIds.length / noOfImages) >= currentPageNumber
        ? currentPageNumber
        : 1;
    if (currentPageNumber !== 0) {
      const startIndex =
        (currentPageNumber - 1) * noOfImages <= 0
          ? 0
          : (currentPageNumber - 1) * noOfImages;
      const endIndex =
        currentPageNumber * noOfImages > projectImageIds.length
          ? projectImageIds.length - 1
          : currentPageNumber * noOfImages - 1;
      return projectImageIds.slice(startIndex, endIndex + 1);
    }

    return [];
  }

  static getImageIdsBasedOnPageRange(
    fromPage,
    toPage,
    noOfImages,
    projectImageIds,
  ) {
    const assetsIds = [];
    for (let i = fromPage; i <= toPage; i++) {
      const startIndex = (i - 1) * noOfImages <= 0 ? 0 : (i - 1) * noOfImages;
      const endIndex =
        i * noOfImages > projectImageIds.length
          ? projectImageIds.length - 1
          : i * noOfImages - 1;
      for (let j = startIndex; j <= endIndex; j++) {
        // why 'i' again ? now changed to j
        assetsIds.push(projectImageIds[j]);
      }
    }
    return assetsIds;
  }

  static prepareFilterNavigationURL(
    role: any,
    projectId: any,
    currentFilter: any,
    currentPageNumber: any,
  ) {
    return (
      '/dashboard/viewproject/' +
      role +
      projectId +
      '/filter/' +
      currentFilter +
      '/page/' +
      currentPageNumber
    );
  }

  static transformData(data) {
    const groupData = [...data.group];
    const searchData = [...data.search];
    groupData.map((item) => {
      const fiterItems = searchData.filter(
        (child) => child.groupId === item.groupId,
      );
      if (item.groupType !== DEPARTMENT) {
        item.items = fiterItems;
      }
      return item;
    });
    const returnObj = {
      ...data,
      group: groupData,
      search: searchData,
    };
    return returnObj;
  }

  static getDepartmentNproject(list) {
    const listData = [];
    const project = {
      label: PROJECT,
      items: list.search.filter((res) => res.groupId > 0),
    };
    const department = {
      label: DEPARTMENT,
      items: list.search.filter(
        (res) => !res.departmentId && res.groupId === 0,
      ),
    };
    listData.push(department);
    listData.push(project);
    return listData;
  }

  static talentRejectionCalculation(
    imageData,
    talentUseSetup,
    projectDetailsObj,
    domainObjAbbr,
  ) {
    let imgLeft = 0;
    //  Check image is solo or group
    if (imageData.publishedTags.length === 1) {
      // Solo
      if (talentUseSetup && domainObjAbbr === AppSource.WDTV) {
        projectDetailsObj.talentStatDTOList.forEach((talentState) => {
          if (talentState.batchId === imageData.batchId) {
            const rejectedFinalized =
              talentState.totalSoloRejectedCount -
              talentState.pendingSoloRejectedCount;
            imgLeft = this.countImagesLeft(
              talentState.totalSoloCount,
              projectDetailsObj.talentProjectInfoDTO.soloApprovalMin,
              rejectedFinalized,
              talentState.pendingSoloRejectedCount,
            );
          }
        });
      } else {
        imgLeft = this.talentSoloRejectionCalculation(projectDetailsObj);
      }
    } else if (imageData.publishedTags.length > 1) {
      // Group
      if (talentUseSetup && domainObjAbbr === AppSource.WDTV) {
        projectDetailsObj.talentStatDTOList.forEach((talentState) => {
          if (talentState.batchId === imageData.batchId) {
            const rejectedFinalized =
              talentState.totalGroupRejectedCount -
              talentState.pendingGroupRejectedCount;
            imgLeft = this.countImagesLeft(
              talentState.totalGroupCount,
              projectDetailsObj.talentProjectInfoDTO.groupApprovalMin,
              rejectedFinalized,
              talentState.pendingGroupRejectedCount,
            );
          }
        });
      } else {
        imgLeft = this.talentGroupRejectionCalculation(projectDetailsObj);
      }
    }

    return (imgLeft = imageData.rejected ? 1 : imgLeft);
  }

  static talentSoloRejectionCalculation(projectDetailsObj): number {
    let totalPending = 0;
    let totalFinalized = 0;
    let totalRejectedFinalized = 0;
    let totalRejectedPending = 0;
    if (projectDetailsObj.talentStatDTOList[0]) {
      // count all totalPending
      const totalPerBatch = projectDetailsObj.talentStatDTOList.map(
        (talentStatDTO) => talentStatDTO.pendingSoloCount,
      );
      totalPending = totalPerBatch.reduce((prev, curr) => prev + curr, 0);

      // count all totalFinalized
      const finalizedPerBatch = projectDetailsObj.talentStatDTOList.map(
        (talentStatDTO) =>
          talentStatDTO.totalSoloCount - talentStatDTO.pendingSoloCount,
      );
      totalFinalized = finalizedPerBatch.reduce((prev, curr) => prev + curr, 0);

      // count all totalRejectedFinalized
      const rejectedFinalizedPerBatch = projectDetailsObj.talentStatDTOList.map(
        (talentStatDTO) =>
          talentStatDTO.totalSoloRejectedCount -
          talentStatDTO.pendingSoloRejectedCount,
      );
      totalRejectedFinalized = rejectedFinalizedPerBatch.reduce(
        (prev, curr) => prev + curr,
        0,
      );

      // count all totalRejectedPending
      const rejectedPendingPerBatch = projectDetailsObj.talentStatDTOList.map(
        (talentStatDTO) => talentStatDTO.pendingSoloRejectedCount,
      );
      totalRejectedPending = rejectedPendingPerBatch.reduce(
        (prev, curr) => prev + curr,
        0,
      );
    }
    return this.countImagesLeft(
      totalPending + totalFinalized,
      projectDetailsObj.talentProjectInfoDTO.soloApprovalMin,
      totalRejectedFinalized,
      totalRejectedPending,
    );
  }

  /*
  IMPORTANT!!! Logic to describe how count
  1) rejectedFinalized - Need to take a look how much photos were already REJECTED && FINALIZED
    a) for each item in talentStatDTOList -> talentStatDTOList.totalGroupRejectedCount ( total group rejected)
        - talentStatDTOList.pendingGroupRejectedCount (rejected, but not finalized)
  2) allowableRejection - Need to count by calculating (talentProjectInfoDTO.groupAssetCount(currently availalbe photos)
        + projectDetailsObj.talentProjectInfoDTO.groupFinalizedCount) * by approvalRate; Note: Math.Floor to take round to smallest number
  3) imagesLeft - Need to count how much photos we still can kill,
        by allowableRejection - alreadyRejectedFinalized - talentProjectInfoDTO.groupAssetRejection (currently rejected, but not finalized)
  4) imgLeft - if we still have groupImagesLeft, then return, if no then return 0;
   */
  static talentGroupRejectionCalculation(projectDetailsObj): number {
    let totalPending = 0;
    let totalFinalized = 0;
    let totalRejectedFinalized = 0;
    let totalRejectedPending = 0;
    if (projectDetailsObj.talentStatDTOList[0]) {
      // count all totalPending
      const totalPerBatch = projectDetailsObj.talentStatDTOList.map(
        (talentStatDTO) => talentStatDTO.pendingGroupCount,
      );
      totalPending = totalPerBatch.reduce((prev, curr) => prev + curr, 0);

      // count all totalFinalized
      const finalizedPerBatch = projectDetailsObj.talentStatDTOList.map(
        (talentStatDTO) =>
          talentStatDTO.totalGroupCount - talentStatDTO.pendingGroupCount,
      );
      totalFinalized = finalizedPerBatch.reduce((prev, curr) => prev + curr, 0);

      // count all totalRejectedFinalized
      const rejectedFinalizedPerBatch = projectDetailsObj.talentStatDTOList.map(
        (talentStatDTO) =>
          talentStatDTO.totalGroupRejectedCount -
          talentStatDTO.pendingGroupRejectedCount,
      );
      totalRejectedFinalized = rejectedFinalizedPerBatch.reduce(
        (prev, curr) => prev + curr,
        0,
      );

      // count all totalRejectedPending
      const rejectedPendingPerBatch = projectDetailsObj.talentStatDTOList.map(
        (talentStatDTO) => talentStatDTO.pendingGroupRejectedCount,
      );
      totalRejectedPending = rejectedPendingPerBatch.reduce(
        (prev, curr) => prev + curr,
        0,
      );
    }
    return this.countImagesLeft(
      totalPending + totalFinalized,
      projectDetailsObj.talentProjectInfoDTO.groupApprovalMin,
      totalRejectedFinalized,
      totalRejectedPending,
    );
  }

  static formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static checkExclusiveImage(selectedImages) {
    return selectedImages.some((imageObj) => imageObj.exclusive);
  }

  static imageCornerColor(imgData, hoverAlbumClicked, hoverAlbum) {
    // customAlbums
    if (hoverAlbumClicked.id !== 0) {
      if (
        imgData &&
        imgData.customAlbums &&
        imgData.customAlbums.indexOf(hoverAlbumClicked.id) !== -1
      ) {
        try {
          const color = hoverAlbumClicked.tagColorCode.slice(1);
          return 'img-corner-' + color.toLowerCase();
        } catch {
          return '';
        }
      } else {
        return '';
      }
    } else {
      if (
        imgData &&
        imgData.customAlbums &&
        imgData.customAlbums.indexOf(hoverAlbum.id) !== -1
      ) {
        try {
          const color = hoverAlbum.tagColorCode.slice(1);
          return 'img-corner-' + color.toLowerCase();
        } catch {
          return '';
        }
      } else {
        return '';
      }
    }
  }

  static onClickTalentApproveReject(
    imageData,
    talentUseSetup,
    projectDetailsObj,
    domainObjAbbr,
  ) {
    let totalImageCount = 0; // Get totalImageCount for WDTV warning message
    if (talentUseSetup && domainObjAbbr === AppSource.WDTV) {
      projectDetailsObj.talentStatDTOList.forEach((talentState) => {
        if (talentState.batchId === imageData.batchId) {
          totalImageCount =
            imageData.publishedTags.length === 1
              ? talentState.totalSoloCount
              : talentState.totalGroupCount;
        }
      });
    }
    switch (true) {
      case talentUseSetup &&
        domainObjAbbr === AppSource.WDTV &&
        imageData.publishedTags.length === 1:
        return `WARNING: Your Solo rejection limit has been reached for setup: ${imageData.batchName} which contains  ${totalImageCount} images.  At this time, you may unreject some images from this setup to allow for additional rejections.
         PLEASE NOTE: Approval rights are applied per setup.  Reaching your limit on current setup does not impede your ability to move forward with approvals on subsequent setups.`;
      case imageData.publishedTags.length === 1:
        return `WARNING: Your Solo Rejection limit has been reached. You may Unreject some Solo images to free up additional Rejections.`;
      case talentUseSetup &&
        domainObjAbbr === AppSource.WDTV &&
        imageData.publishedTags.length > 1:
        return `WARNING: Your Group rejection limit has been reached for setup: ${imageData.batchName} which contains  ${totalImageCount} images.  At this time, you may unreject some images from this setup to allow for additional rejections.
         PLEASE NOTE: Approval rights are applied per setup.  Reaching your limit on current setup does not impede your ability to move forward with approvals on subsequent setups.`;
      case imageData.publishedTags.length > 1:
        return `WARNING: Your Group Rejection limit has been reached. You may Unreject some Group images to free up additional Rejections.`;
      default:
        return '';
    }
  }

  /*
    1) count allPossible allowableRejection by multiply allImages and approvalRate
    2) Count possible imagesLeft by deducting alreadyRejectedFinalized - pendingRejectedImages
 */
  private static countImagesLeft(
    allImages: number,
    approvalRate: number,
    alreadyRejectedFinalized: number,
    pendingRejectedImages: number,
  ): number {
    const allowableRejection = Math.floor(
      allImages * (100 - approvalRate) * 0.01,
    );
    const imagesLeft =
      allowableRejection - alreadyRejectedFinalized - pendingRejectedImages;
    return imagesLeft > 0 ? imagesLeft : 0;
  }

  static isObjectEmpty(data): boolean {
    return !Object.keys(data || {}).length;
  }

  static addValueToObject(obj, key, value): object {
    return Object.assign(obj, { [key]: value });
  }

  static synchronizeAssetIdsWithAssetInfoDTOListIds(
    res,
    assetInfoData,
  ): AssetInfo {
    if (assetInfoData.assetIds.length && res.assetInfoDTOList.length) {
      const synchronizedAssetIdsArray = [];

      assetInfoData.assetIds.forEach((id: number) => {
        res.assetInfoDTOList.forEach((item: AssetInfoDTOList) => {
          Number(item.id) === Number(id) &&
            synchronizedAssetIdsArray.push(item);
        });
      });

      res.assetInfoDTOList = Object.assign([], synchronizedAssetIdsArray);
    }
    return res;
  }

  static deepCopyObject<T>(data: T) {
    return JSON.parse(JSON.stringify(data));
  }

  static mergeAndRemoveDuplicatesByKey<T>(array: T[], property: string): T[] {
    const uniqueArray = [];
    const keys = new Set();
    for (const item of array) {
      const key = item[property];
      if (!keys.has(key)) {
        keys.add(key);
        uniqueArray.push(item);
      }
    }
    return uniqueArray;
  }
}
