import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'fs-number-incrementer',
  templateUrl: './number-incrementer.component.html',
  styleUrls: ['./number-incrementer.component.scss'],
})
export class NumberIncrementerComponent implements OnInit {
  inputNumber: number;
  @Output() onNumberChanged: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit() {
    this.inputNumber = 3;
  }

  down(min) {
    if (this.inputNumber > min) {
      this.inputNumber = Number(this.inputNumber) - 1;
      this.onNumberChanged.emit(this.inputNumber);
    } else {
      return false;
    }
  }

  up(max) {
    if (this.inputNumber < max) {
      this.inputNumber = Number(this.inputNumber) + 1;
      this.onNumberChanged.emit(this.inputNumber);
    } else {
      return false;
    }
  }
}
