import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import {catchError, finalize, map, takeUntil} from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DialogService } from './dialog/dialog.service';
import { AppStateService } from './app-state.service';
import { AppSettings } from '../../appSettings/appSettings';

@Injectable()
export class ApiService {
  private destroy$ = new Subject(); // todo think what to do with this in service
  apiUrl: string;
  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private appStateService: AppStateService,
    private router: Router,
  ) {
    this.apiUrl = environment.apiUrl;
  }

  get(path: string, apiUrl = this.apiUrl): Observable<any> {
    return this.httpClient.get<any>(apiUrl + path).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => {
        this.loaderService.displayLoader(false);
      })
    );
  }

  getIP(path: string): Observable<any> {
    return this.httpClient.get<any>(path, {}).pipe(
      map((res) => res),
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => {
        this.loaderService.displayLoader(false);
      })
    );
  }

  put(path: string, body: Record<string, any> = {}): Observable<any> {
    return this.httpClient.put<any>(this.apiUrl + path, body).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => {
        this.loaderService.displayLoader(false);
      })
    );
  }

  post(
    path: string,
    body: Record<string, any> = {}
  ): Observable<any> {
    return this.httpClient.post<any>(this.apiUrl + path, body).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => {
        this.loaderService.displayLoader(false);
      })
    );
  }

  postAssets(
    baseURL: string,
    path: string,
    body: Record<string, any> = {}
    ): Observable<any> {
    return this.httpClient.post<any>(baseURL + path, body).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => {
        this.loaderService.displayLoader(false);
      })
    );
  }

  delete(path: string): Observable<any> {
    return this.httpClient.delete<any>(this.apiUrl + path).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => {
        this.loaderService.displayLoader(false);
      })
    );
  }

  deleteWithBody(
    path: string,
    body: Record<string, any> = {},
  ): Observable<any> {
    return this.httpClient.delete<any>(this.apiUrl + path, body).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => {
        this.loaderService.displayLoader(false);
      })
    );
  }

  handleError(
    error: any,
    errorDetailsMessageCallback?: (e: HttpErrorResponse) => string,
  ) {
    this.loaderService.displayLoader(false);
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { detect } = require('detect-browser');
    const browser = detect();
    let exicuteSwitch = false;

    if (
      !error.url?.includes('domain') &&
      !error.url?.includes('authenticate') &&
      !error.url?.includes('bug-report') &&
      !error.url?.includes('refresh') &&
      !error.url?.includes('logout')
    ) {
      const data = {
        apiError: JSON.stringify(error),
        browserInfo: JSON.stringify(browser),
        version: environment.version,
      };
      if (errorDetailsMessageCallback && errorDetailsMessageCallback(error)) {
        this.alertCustom(errorDetailsMessageCallback(error));
      } else {
        this.post(AppSettings.BUG_REPORT, data)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (resData) => {
              this.alertCustom(resData.message);
              exicuteSwitch = false;
            },
            (errorData) => {
              exicuteSwitch = true;
              this.handleCustomeError(error, exicuteSwitch);
            },
          );
      }
    } else if (error.url.includes('domain')) {
      exicuteSwitch = true;
      this.handleCustomeError(error, exicuteSwitch);
    }
  }

  handleCustomeError(error, exicuteSwitch) {
    if (exicuteSwitch) {
      switch (error.status) {
        case 401:
          this.alertCustom(error.error?.title);
          break;
        case 500:
          this.alertCustom(this.translate.instant('error.message'));
          break;
        case 501:
          this.alertCustom(this.translate.instant('error.message'));
          this.router.navigate(['/dashboard']);
          break;
        case 504:
          this.alertCustom(error.error?.title);
          break;
        case 400:
          this.alertCustom(this.translate.instant('error.invalidRequest'));
          break;
        case 403:
          this.alertCustom(error.error?.detail);
          break;
        default:
          this.alertCustom(error.error?.title);
      }
    }
  }

  alertCustom(displayText: string) {
    this.dialogService
      .openInformationDialog({
        title: this.translate.instant('view-project.confirm22'),
        message: displayText,
      })
      .subscribe(() => {
        this.appStateService.setDisplayCommonDialog({ loaded: true });
      });
  }
}
