import { Utils } from '../../../../../shared/utils/utils';
import { ProjectRoleId } from '../../../../../shared/enum/project-role.enum';
import { Router } from '@angular/router';
import { DepartmentService } from '../../../../department/department.service';
import { LauncherService } from '../../../../launcher/launcher.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { FilterEnum } from '../../../../../shared/enum/filter.enum';
import { SidebarService } from '../../../../project/sidebar-service/sidebar-service';
import {
  GroupDataModel,
  GroupItemDataModel,
} from '../interfaces/dashboard-department-rule.model';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { ALBUM } from '../../../../../shared/constants/constants';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardDepartmentExecSetupRule implements DashboardRuleModel {
  private readonly DEFAULT_PAGE_NUMBER = 1;

  private router = GetStaticInjector().get(Router);
  private departmentService = GetStaticInjector().get(DepartmentService);
  private launcherService = GetStaticInjector().get(LauncherService);
  private viewProjectService = GetStaticInjector().get(ViewProjectService);
  private sidebarService = GetStaticInjector().get(SidebarService);

  constructor(
    private group: GroupDataModel,
    private galBotInfo: GroupItemDataModel,
  ) {}

  matches(): boolean {
    return (
      this.group.groupType !== ALBUM &&
      !this.galBotInfo.useSetup &&
      this.galBotInfo.role === Number(ProjectRoleId.ROLE_EXEC)
    );
  }

  apply(): void {
    this.viewProjectService.setProjectID(this.galBotInfo.value);
    const selectedProject = this.launcherService.projectList$.search.find(
      (project) =>
        project.role === this.galBotInfo.role &&
        project.value === this.galBotInfo.value,
    );
    this.viewProjectService.setSelectedProjectData(selectedProject);
    this.launcherService.setSelectedDepLogo(this.galBotInfo.logo);
    this.launcherService.setSelectedProject(this.galBotInfo.value);
    this.launcherService.setIsGallaryModeStatus(false);
    this.viewProjectService.setProjectRole(this.galBotInfo.role);
    this.departmentService.setSetUpNameGlobal(selectedProject.displayText);
    this.launcherService.setSelectedProjectName(selectedProject.displayText);
    this.viewProjectService.setProjectID(this.galBotInfo.value);
    this.router.navigate([
      Utils.prepareFilterNavigationURL(
        this.galBotInfo.role,
        this.galBotInfo.value,
        FilterEnum.ShowAll,
        this.DEFAULT_PAGE_NUMBER,
      ),
    ]);
    this.sidebarService.resetAlbumImageData();
  }
}
