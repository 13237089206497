<div class="fs-popup">
  <div
    class="fs-popup-title fs-cursor-move d-flex justify-content-between text-break"
  >
    <div class="modal-title text-uppercase mr-2 d-flex align-items-center">
      {{ 'deletealbum.title' | translate }} {{ albumName }}
    </div>

    <button
      type="button"
      class="fs-close-btn w-auto"
      aria-hidden="true"
      (click)="closeDeleteAlbum()"
    >
      <i class="fa fa-times-circle"></i>
    </button>
  </div>

  <div class="fs-popup-content d-flex justify-content-center">
    <button class="fs-dialog-btn w-auto mr-3" (click)="closeDeleteAlbum()">
      {{ 'deletealbum.buttons.cancel' | translate }}
    </button>
    <button class="fs-dialog-btn w-auto" (click)="deleteAlbum()">
      {{ 'deletealbum.buttons.delete' | translate }}
    </button>
  </div>
</div>
