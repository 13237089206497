<mat-accordion [displayMode]="'flat'" class="fs-accordion-dark-theme">
  <mat-expansion-panel [expanded]="true" [disabled]="true" [hideToggle]="true">
    <mat-expansion-panel-header
      [collapsedHeight]="'30px'"
      [expandedHeight]="'30px'"
    >
      <mat-panel-title>
        <span class="fs-sm-txt">
          {{ 'left-menu.tab-bar.title2' | translate }}
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div
      class="pl-4 pt-2 pb-2 fs-txt-white-dark-color fs-sm-txt fs-talent-project-info-container fs-cursor-default"
    >
      <div>
        {{ 'left-menu.talent-project-info.SoloApprovalMin' | translate }}
        : {{ talentUserProjectInfo.soloApprovalMin }}
        %
      </div>

      <div>
        {{ 'left-menu.talent-project-info.GroupApprovalMin' | translate }}
        : {{ talentUserProjectInfo.groupApprovalMin }}%
      </div>

      <div *ngIf="tertiary">
        Group Plus Approval Min :
        {{ talentUserProjectInfo.groupPlusApprovalMin }}%
      </div>

      <div *ngIf="tertiary">
        Group Plus Approval Min :
        {{ talentUserProjectInfo.groupPlusApprovalMin }}%
      </div>

      <div *ngIf="tertiary">
        Group Plus Approval Min :
        {{ talentUserProjectInfo.groupPlusApprovalMin }}%
      </div>

      <div>
        {{ 'left-menu.talent-project-info.CurrentBatch' | translate }}
        : {{ talentUserProjectInfo.currentBatch }}
      </div>

      <div [ngClass]="soloColor">
        {{ 'left-menu.talent-project-info.SoloPhotos' | translate }}
        : {{ talentUserProjectInfo.soloAssetCount }}
      </div>

      <div [ngClass]="soloColor">
        {{ 'left-menu.talent-project-info.SoloRejections' | translate }}
        : {{ talentUserProjectInfo.soloAssetRejection }}
      </div>

      <div [ngClass]="groupColor">
        {{ 'left-menu.talent-project-info.GroupPhotos' | translate }}
        : {{ talentUserProjectInfo.groupAssetCount }}
      </div>

      <div [ngClass]="groupColor">
        {{ 'left-menu.talent-project-info.GroupRejections' | translate }}
        : {{ talentUserProjectInfo.groupAssetRejection }}
      </div>

      <div *ngIf="tertiary" [ngClass]="groupPlusColor">
        Group Plus Photos : {{ talentUserProjectInfo.groupPlusAssetCount }}
      </div>

      <div *ngIf="tertiary" [ngClass]="groupPlusColor">
        Group Plus Rejection :
        {{ talentUserProjectInfo.groupPlusAssetRejection }}
      </div>

      <div>
        {{ 'left-menu.talent-project-info.ProjectTotalToDate' | translate }}
      </div>

      <div [ngClass]="soloColor">
        {{ 'left-menu.talent-project-info.SoloApproved' | translate }}
        : {{ talentUserProjectInfo.soloApproved }}%
      </div>

      <div [ngClass]="groupColor">
        {{ 'left-menu.talent-project-info.GroupApproved' | translate }}
        : {{ talentUserProjectInfo.groupApproved }}%
      </div>

      <div *ngIf="tertiary" [ngClass]="groupPlusColor">
        Group Plus Approved : {{ talentUserProjectInfo.groupPlusApproved }}%
      </div>

      <div>
        {{ 'left-menu.talent-project-info.Filter' | translate }} :
        {{ totalFilterCount }}
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
