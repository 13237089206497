import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
} from '@angular/core';
import { ActorapprovalreportService } from '../actoreapproval-report.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Modalstatusservice } from '../../../../../../../../shared/services/modalstatus.service';
import { UserService } from '../../../../../../../../shared/services/user.service';
import { ViewProjectService } from '../../../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { ExportType } from '../../../../../../../../shared/models/enum/ExportType';
import { UnsubscriberComponent } from '../../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../../shared/services/api-error-handler.service';

class Assets {
  job: string;
  actor: string;
  setup: string;
  status: string;
  fileQty: number;
  count: number;
  file: string;
  comment: string;
}

@Component({
  selector: 'fs-approval-report',
  templateUrl: './approval-report.component.html',
  styleUrls: ['./approval-report.component.scss'],
})
export class ApprovalReportComponent
  extends UnsubscriberComponent
  implements OnInit
{
  sortF: any;
  selectedTalent: any;
  dataTalents: Array<object>;
  isApproveCheck = false;
  isApproveProcessing = false;
  printExcelData = [];
  arrListActorApprovalReport: any = [];
  constantsGlobalData: any;
  displayedColumns: string[] = [
    'job',
    'actor',
    'setup',
    'rank',
    'totalAssets',
    'ranked',
    'asset',
    'comment',
  ];
  exportType = ExportType;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    evt: KeyboardEvent,
  ) {
    this.closeWindow();
  }

  constructor(
    private actorApprovalReport: ActorapprovalreportService,
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private userService: UserService,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private elementRef: ElementRef,
    private matDialogRef: MatDialogRef<ApprovalReportComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    super();
    this.selectedTalent = data.selectedTalent;
    this.isApproveCheck = data.isApproveCheck;
    this.dataTalents = data.taggedList;
    this.arrListActorApprovalReport = this.formatActorApprovalReport(data.data);
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.printExcelData = this.formatePrintData(
      this.arrListActorApprovalReport,
    );
  }

  formatePrintData(array) {
    const obj = [];
    array.forEach((element) => {
      if (
        !(!element.job && element.rank === 'Approved') &&
        !this.isApproveCheck
      ) {
        const data = {
          JOB: element.job,
          ACTOR: element.actor,
          SETUP: element.setup,
          STATUS: element.rank,
          'FILE QTY': element.totalAssets === 0 ? '' : element.totalAssets,
          COUNT:
            element.ranked === 101010
              ? ''
              : element.ranked === 0
              ? ''
              : element.ranked,
          FILE: element.rank !== 'Approved' ? element.asset : '',
          COMMENT: element.comment,
        };
        obj.push(data);
      } else if (this.isApproveCheck) {
        const data = {
          JOB: element.job,
          ACTOR: element.actor,
          SETUP: element.setup,
          STATUS:
            !element.job && element.rank === 'Approved' ? '' : element.rank,
          'FILE QTY': element.totalAssets === 0 ? '' : element.totalAssets,
          COUNT:
            element.ranked === 101010
              ? ''
              : element.ranked === 0
              ? ''
              : element.ranked,
          FILE: element.asset,
          COMMENT: element.comment,
        };
        obj.push(data);
      }
    });
    return obj;
  }

  formatActorApprovalReport(data: any) {
    let count = 0;
    data.forEach((element) => {
      if (element.rank === 'Approved') {
        count = 0;
      } else if (count >= 0 && !element.job) {
        element.rank = 'Approved';
        count++;
      } else if (
        element.rank === 'Killed' ||
        element.rank === 'Approved (with Notes)'
      ) {
        count = -1;
      }
    });
    return data;
  }

  changeSort(event) {
    if (!event.order) {
      this.sortF = 'year';
    } else {
      this.sortF = event.field;
    }
  }

  toggleisApproveCheck() {
    this.isApproveProcessing = true;
    this.printExcelData = this.formatePrintData(
      this.arrListActorApprovalReport,
    );
    this.isApproveProcessing = false;
  }

  onChangeListDropdown(talent) {
    this.loaderService.displayLoader(true);
    this.actorApprovalReport
      .getActorApprovalReports(
        this.viewProjectService.getProjectId(),
        talent.id,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.loaderService.displayLoader(false);
          if (response) {
            this.arrListActorApprovalReport =
              this.formatActorApprovalReport(response);
            this.printExcelData = this.formatePrintData(
              this.arrListActorApprovalReport,
            );
          } else {
            this.arrListActorApprovalReport = [];
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  exportReport(reportType) {
    if (reportType === this.exportType.Excel) {
      this.userService.exportReportApprovalReport(
        'Project Transaction Report',
        this.printExcelData,
        this.selectedTalent.tagName,
      );
    } else {
      const colNames = this.userService.getColumnsName(this.printExcelData[0]);
      this.userService.exportPDFData(this.printExcelData, colNames);
    }
  }

  closeWindow() {
    this.matDialogRef.close();
  }
}
