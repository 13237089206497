export const viewsettingconfig = {
  size: [
    { id: 1, name: 'L' },
    { id: 2, name: 'M' },
    { id: 3, name: 'S' },
  ],
  imagePerPage: [
    { id: 10, name: '10' },
    { id: 20, name: '20' },
    { id: 50, name: '50' },
    { id: 100, name: '100' },
    { id: 250, name: '250' },
  ],
  columnPerPage: [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
    { id: 8, name: 'Auto' },
  ],
};
