import {
  Inject,
  Injectable,
  Injector,
  OnDestroy,
  StaticProvider,
} from '@angular/core';
import {
  ComponentType,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { PopupContainerComponent } from '../../components/popup-container/popup-container.component';
import { PopupRef } from './popup-ref';
import { PopupConfig, PopupConfigModel } from './popup-config';
import { FS_POPUP_DATA } from './popup.constants';
import { TooltipPosition } from '@angular/material/tooltip';
import { PopupPositionService } from './popup-position.service';
import { WINDOW } from '../window.service';
import {
  filter,
  skip,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { ResizeUtils } from '../../utils/resize.utils';
import { ResizeObserverArgs } from '../../models/resize-observer-args.model';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class PopupService implements OnDestroy {
  private destroy$ = new Subject(); //// todo think what to do with this in service
  private pool: Map<string, PopupRef<any>> = new Map<string, PopupRef<any>>();

  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private popupPositionService: PopupPositionService,
    @Inject(WINDOW) private window: Window,
    private router: Router,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * @param component. Angular component class opens as a popup next to the target element.
   * @param target element. Component opens next to the target element
   * @param config of popup
   */
  open<T, D = any>(
    component: ComponentType<T>,
    target: HTMLElement,
    config: PopupConfigModel<D> = {},
  ) {
    const popupConfig = new PopupConfig<D>(config);
    const positionStrategy = this.getPositionStrategy(
      target,
      popupConfig.position,
    );
    const overlayRef = this.createOverlay(positionStrategy, popupConfig);
    const containerRef = this.attachPopupContainer(overlayRef, popupConfig);
    const popupRef = new PopupRef<T>(
      overlayRef,
      popupConfig,
      containerRef,
      target,
    );
    const contentRef = this.attachPopupContent<T, D>(
      popupRef,
      component,
      containerRef.instance,
      popupConfig,
    );
    this.handleOnCloseDialog(popupRef, config.id);
    this.initializePropertiesForContainerComponent(
      containerRef.instance,
      target,
    );
    this.initializePopupRef<T>(popupRef, contentRef.instance);
    this.handleCloseOnNavigate(popupConfig.closeOnNavigate, popupRef);
    this.handleCloseOnBackdropClick(
      popupRef,
      popupConfig.closeOnBackdropClick,
      overlayRef,
      config,
    );

    this.updatePositionOnChangePopupSize(overlayRef, popupRef);
    this.setContainerInitialSize(containerRef, overlayRef);

    if (config.id) {
      this.pool.set(config.id, popupRef);
    }

    return popupRef;
  }

  private handleOnCloseDialog(popupRef, id) {
    return popupRef
      .afterClosed()
      .pipe(
        take(1),
        tap(() => {
          this.pool.delete(id);
        }),
      )
      .subscribe();
  }

  closePopupById$(id: string): Observable<unknown> {
    const popupRef = this.pool.get(id);
    if (popupRef) {
      popupRef.hide();
      return popupRef.afterClosed();
    }
    return of(null);
  }

  isPopupActive(id: string): boolean {
    return this.pool.has(id);
  }

  private setContainerInitialSize(containerRef, overlayRef: OverlayRef): void {
    setTimeout(() => {
      const containerOffsetLeft =
        containerRef.instance.overlayContainerRef.offsetLeft;
      const containerOffsetWidth =
        containerRef.instance.overlayContainerRef.offsetWidth;
      const containerOffsetTop =
        containerRef.instance.overlayContainerRef.offsetTop;

      const containerElementOffset = containerOffsetLeft + containerOffsetWidth;
      const viewPortMargin = 16;

      if (containerOffsetTop < 0) {
        containerRef.instance.overlayContainerRef.classList.add(
          'fs-popup-container-top',
        );
        overlayRef.updatePosition();
      }

      if (containerElementOffset >= window.innerWidth) {
        const containerWidth =
          (containerElementOffset - window.innerWidth) * 2 +
          viewPortMargin +
          containerOffsetWidth;
        overlayRef.updateSize({ width: containerWidth });
        overlayRef.updatePosition();
      }
    });
  }

  private updatePositionOnChangePopupSize(
    overlayRef: OverlayRef,
    popupRef: PopupRef<unknown>,
  ) {
    ResizeUtils.elementResizeListener(overlayRef.overlayElement)
      .pipe(
        skip(1),
        tap(() => {
          if (overlayRef.hasAttached()) {
            overlayRef.updatePosition();
          }
        }),
        withLatestFrom(popupRef.afterClosed()),
        tap(([data, closedWithData]: any[]) => {
          data.observer.disconnect();
        }),
        take(1),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private initializePopupRef<T>(popupRef: PopupRef<T>, popupComponent: T) {
    popupRef.componentInstance = popupComponent;
  }

  private initializePropertiesForContainerComponent(
    instance: PopupContainerComponent,
    target: HTMLElement,
  ) {
    instance.target = target;
  }

  private handleCloseOnNavigate(closeOnNavigate, popupRef: PopupRef<unknown>) {
    // TODO: maybe we can find better solution.
    if (closeOnNavigate) {
      const navigationSubscription = this.router.events
        .pipe(
          filter((event: any) => event instanceof NavigationStart),
          tap(() => {
            this.pool.clear();
            popupRef.hide();
          }),
          take(1),
          takeUntil(this.destroy$),
        )
        .subscribe();

      popupRef
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => navigationSubscription.unsubscribe());
    }
  }

  private handleCloseOnBackdropClick(
    popupRef: PopupRef<unknown>,
    closeOnBackdropClick: boolean,
    overlayRef: OverlayRef,
    config: PopupConfigModel,
  ) {
    if (closeOnBackdropClick) {
      const backdropClickSubscription = overlayRef
        .outsidePointerEvents()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          popupRef.hide();
        });

      popupRef
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => backdropClickSubscription.unsubscribe());
    }
  }

  private createOverlay(
    positionStrategy: FlexibleConnectedPositionStrategy,
    config: PopupConfig<any>,
  ) {
    return this.overlay.create({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backDropClass || 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy,
    });
  }

  private getPositionStrategy(target: HTMLElement, position: TooltipPosition) {
    const connectedPosition =
      this.popupPositionService.getConnectedPosition(position);

    return this.overlay
      .position()
      .flexibleConnectedTo(target)
      .withFlexibleDimensions(false)
      .withViewportMargin(8)
      .withPositions(connectedPosition)
      .withPush(false);
  }

  /**
   * Attaches internal popup container with basic functionality to overlay
   * @param overlayRef
   * @param config
   */
  private attachPopupContainer<D>(
    overlayRef: OverlayRef,
    config: PopupConfig<D>,
  ) {
    const injector = this.createInjector([
      {
        provide: PopupConfig,
        useValue: config,
      },
      {
        provide: OverlayRef,
        useValue: overlayRef,
      },
    ]);

    const containerPortal = new ComponentPortal(
      PopupContainerComponent,
      null,
      injector,
    );

    return overlayRef.attach(containerPortal);
  }

  /**
   * Attaches popup component to internal popup container
   * @param popupRef
   * @param component to inject
   * @param container. Popup container with basic functionality
   * @param config
   */
  private attachPopupContent<T, D>(
    popupRef: PopupRef<T>,
    component: ComponentType<any>,
    container: PopupContainerComponent,
    config: PopupConfig<D>,
  ) {
    const injector = this.createInjector([
      {
        provide: FS_POPUP_DATA,
        useValue: config.data || null,
      },
      {
        provide: PopupRef,
        useValue: popupRef,
      },
    ]);

    const contentComponentPortal = new ComponentPortal(
      component,
      null,
      injector,
    );
    return container.attachContentPortal(contentComponentPortal);
  }

  private createInjector(providers: StaticProvider[]): Injector {
    return Injector.create({
      parent: this.injector,
      providers,
    });
  }
}
