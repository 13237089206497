<div
  class="ml-1"
  *ngIf="
    projectDetailPermission.permissionDTO.exclusiveAccess &&
    imgData.exclusiveHistory &&
    imgData.assetType == assetType.IMAGE &&
    !isTaggerRole
  "
>
  <div
    class="fs-cursor-pointer"
    title="{{ 'view-project.exclusive' | translate }}"
    (click)="
      openImageHistory.emit({
        imageData: imgData,
        historyReportType: 'exclusive'
      })
    "
  >
    <span class="fs-char-icon">
      <img src="/assets/icons/e.png" />
    </span>
  </div>
</div>
