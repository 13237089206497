import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettings } from '../../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../../shared/services/api.service';

@Injectable()
export class BatchManagementService {
  constructor(private apiService: ApiService) {}

  updateBatch(data): Observable<any> {
    const path = AppSettings.UPDATE_BATCH;
    return this.apiService.put(path, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  deleteBatch(data): Observable<any> {
    const path = AppSettings.DELETE_BATCH(data.id, data.projectId);
    return this.apiService.delete(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }
}
