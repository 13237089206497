<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">{{ 'image-history.title2' | translate }}</div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div
    class="d-flex flex-column align-items-center justify-content-center"
    *ngFor="let album of customAlbumList"
  >
    <div *ngIf="album.value" (click)="onClickAlbum(album)">
      <span class="album-link fs-txt-white-grey-color-hover">
        {{ album.value }}
      </span>
    </div>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <div class="d-flex justify-content-end align-items-center">
    <button class="fs-dialog-btn w-auto" (click)="closeWindow()">
      {{ 'image-history.buttons.close' | translate }}
    </button>
  </div>
</div>
