<div
  class="fs-sm-txt fs-txt-white-dark-color list-unstyled components sideBar-menu py-2"
  id="leftsideMenuBar"
>
  <div class="container sub-menu-list sub-menu-text-spacing">
    <div class="row">
      <span
        class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
        (click)="filteredfileName()"
      >
        {{
          'left-menu.tab-bar.sidemenus.administration.buttonse.filtered-list'
            | translate
        }}
      </span>
    </div>

    <div class="row">
      <span
        class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
        (click)="selectedFileName()"
      >
        {{
          'left-menu.tab-bar.sidemenus.administration.buttonse.selected-list'
            | translate
        }}
      </span>
    </div>

    <div class="row">
      <span
        class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
        (click)="sendEmailNotification()"
      >
        {{
          'left-menu.tab-bar.sidemenus.administration.buttonse.sendemailnotification'
            | translate
        }}
      </span>
    </div>

    <div class="row" *ngIf="!isTaggerRole">
      <span
        class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
        (click)="imageNotesReport()"
      >
        {{
          'left-menu.tab-bar.sidemenus.administration.buttonse.image-notes-report'
            | translate
        }}
      </span>
    </div>
  </div>
</div>
