<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.rejection-toggle.title'
        | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="fs-txt-white-dark-color font-weight-bold text-center mb-2">
    <span>{{ selectedAssestIdList.length }}</span>
    {{
      'left-menu.tab-bar.sidemenus.administration.finalize-unfinalize.labels.image-selected'
        | translate
    }}
  </div>

  <div class="mb-2">
    <div class="col-md-12 col-sm-12">
      <mat-select
        class="fs-selectbox-dark form-control flex-fill"
        required
        [value]="selectedName"
        [placeholder]="
          'left-menu.tab-bar.sidemenus.administration.finalize-unfinalize.drpdown.select-talent'
            | translate
        "
        (valueChange)="selectedName = $event"
      >
        <mat-option
          class="fs-sm-txt fs-selectbox-options"
          *ngFor="let tag of tagList"
          [value]="tag"
        >
          {{ tag.value }}
        </mat-option>
      </mat-select>
    </div>
  </div>

  <div>
    <mat-radio-group
      class="d-flex justify-content-center pt-2"
      [(ngModel)]="selectvalue"
    >
      <mat-radio-button class="fs-radio-button mr-3" value="reject">
        <span class="cb-title fs-sm-txt">
          {{
            'left-menu.tab-bar.sidemenus.administration.rejection-toggle.labels.reject'
              | translate
          }}
        </span>
      </mat-radio-button>

      <mat-radio-button class="fs-radio-button ml-3" value="unreject">
        <span class="cb-title fs-sm-txt">
          {{
            'left-menu.tab-bar.sidemenus.administration.rejection-toggle.labels.unreject'
              | translate
          }}
        </span>
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button
    class="fs-dialog-btn w-auto ml-3"
    (click)="execute()"
    [disabled]="!selectedName"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.rejection-toggle.button.execute'
        | translate
    }}
  </button>
</div>
