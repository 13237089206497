<mat-accordion [displayMode]="'flat'" class="fs-accordion-dark-theme">
  <fs-accordion-panel
    [expanded]="false"
    [title]="'retouch.comments' | translate"
  >
    <div
      fs-expansion-panel-accordion-header-content
      class="pl-3 pt-2 pb-2 fs-txt-white-dark-color fs-sm-txt"
    >
      <ng-scrollbar autoHeightDisabled="false" trackY="'true'">
        <div class="messages-sec">
          <div
            class="single-message pr-4 mb-4"
            *ngFor="let comment of commentList; let i = index"
          >
            <div
              [ngClass]="{
                'my-comment': currentLoggedInId == comment.userId,
                'fs-msg-wrapper': currentLoggedInId != comment.userId
              }"
            >
              <div>{{ comment.comment }}</div>
              <div class="fs-tiny-txt d-flex">
                <span>{{ comment.userName }}</span>
                <span
                  >{{ comment.createdAt | date : 'yyyy-MM-dd' }} &#64;
                  {{ comment.createdAt | date : 'hh:mm:ss' }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </ng-scrollbar>
    </div>
  </fs-accordion-panel>
</mat-accordion>
