<div
  class="p-1 fs-txt-white-grey-color-hover w-100 note-info-container"
  *ngFor="let note of slicedArray"
>
  <ng-container
    *ngTemplateOutlet="customTemplate.template; context: { note: note }"
  >
  </ng-container>
</div>

<div
  class="p-1 ml-1 w-auto fs-cursor-pointer fs-text-underline fs-text-color-white-dark"
  *ngIf="
    dataList.length > initialNumberOfItemsToShow &&
    dataList.length != slicedArray.length
  "
  (click)="viewMore(dataList.length)"
>
  {{
    'left-menu.tab-bar.sidemenus.administration.imagenotes-report.button.view-more'
      | translate
  }}
</div>
<div
  class="p-1 ml-1 w-auto fs-cursor-pointer fs-text-underline fs-text-color-white-dark"
  *ngIf="
    slicedArray.length === dataList.length &&
    dataList.length > initialNumberOfItemsToShow
  "
  (click)="viewLess()"
>
  {{
    'left-menu.tab-bar.sidemenus.administration.imagenotes-report.button.view-less'
      | translate
  }}
</div>
