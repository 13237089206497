<div
  class="ml-1 mr-1"
  *ngIf="
    !isTaggerRole &&
    priorityPixAccess &&
    imgData.priorityPix &&
    imgData.assetType == assetType.IMAGE
  "
>
  <span
    class="fs-char-icon"
    title="{{ 'view-project.transaction' | translate }}"
  >
    <img src="/assets/icons/p.png" />
  </span>
</div>
