export enum FilterEnum {
  ShowAll = 'showall',
  Album = 'album',
  Audit = 'audit',
  Actor = 'actor',
  General = 'general',
  Taggedwith = 'taggedwith',
  Taggingwith = 'taggingwith',
  Tagging = 'tagging',
  CustomFilter = 'customfilter',
  Advancesearch = 'advancesearch',
  BypassTagger2 = 'bypass-tagger-2',
  Conflicts = 'conflicts',
  PendingApproved = 'pending-approved',
  PendingRejected = 'pending-rejected',
  Tagging1 = 'tagging:1',
  Batch = 'batch',
  Tagging2 = 'tagging:2',
  NoConflicts = 'no-conflicts',
  AuditAndConflicts = 'audit:conflicts',
  AuditAndBypassTagger2 = 'audit:bypass-tagger-2',
  AuditAndNoConflicts = 'audit:no-conflicts',
}
