import { ViewProjectService } from './../../../../../shared/services/view-project.service';
import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DeleteassetsService } from './deleteassets.service';
import { SnackBarService } from '../../../../../shared/services/snack-bar.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Modalstatusservice } from '../../../../../shared/services/modalstatus.service';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../shared/store/selected-images-store';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-deleteasset',
  templateUrl: './deleteassets.component.html',
})
export class DeleteComponent extends UnsubscriberComponent implements OnInit {
  deleteForm: UntypedFormGroup;
  @Output() setDeleteTextEvt: EventEmitter<any> = new EventEmitter();
  constantsGlobalData: any;
  readonly DELETE_KEY = 'DELETE';

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  constructor(
    private modalService: Modalstatusservice,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private snackBarService: SnackBarService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private deleteAssetsSrc: DeleteassetsService,
    private selectedImagesStore: SelectedImagesStore,
    private dialogRef: MatDialogRef<DeleteComponent>,
  ) {
    super();
    this.deleteForm = this.fb.group({
      deleteasset: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.deleteForm.reset();
  }

  submitForm() {
    if (this.deleteForm.value.deleteasset === this.DELETE_KEY) {
      const obj = {
        assetIds: this.selectedImagesStore.getItemsIds(),
        projectId: this.viewProjectService.getProjectId(),
      };
      this.loaderService.displayLoader(true);
      this.deleteAssetsSrc
        .callDeleteAssetsAPI(obj)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            if (res.status) {
              this.selectedImagesStore.clear();
              this.viewProjectService.setDeleteAssetGlobal(true);
              this.snackBarService.showSnackBar(res.message);
            } else {
              this.snackBarService.showSnackBarError(res.message);
            }
            this.loaderService.displayLoader(false);
            this.closeWindow();
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    }
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
