import { Component, Input } from '@angular/core';

@Component({
  selector: 'fs-title-for-review-panel-component',
  templateUrl: './title-for-review-panel-component.component.html',
  styleUrls: ['./title-for-review-panel-component.component.scss'],
})
export class TitleForReviewPanelComponentComponent {
  @Input() imgData;
  @Input() isTaggingView1Active;
  @Input() isTaggingView2Active;
}
