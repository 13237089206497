import { Component, Inject, OnInit } from '@angular/core';
import { HelpWindowComponent } from '../../help/help.component';
import { DialogService } from '../../../services/dialog/dialog.service';
import { FS_POPUP_DATA } from '../../../services/popup/popup.constants';
import { PopupRef } from '../../../services/popup/popup-ref';
import { UserRole } from '../../../enum/user-role.enum';
import { ProjectRole } from '../../../enum/project-role.enum';
import { ShortcutDetailsComponent } from '../../../components/shortcut-details/shortcut-details.component';

@Component({
  selector: 'fs-helper-dialog',
  templateUrl: './helper-dialog.component.html',
  styleUrls: ['./helper-dialog.component.scss'],
})
export class HelperDialogComponent {
  projectAuthority: string;
  userRole = UserRole;
  projectRole = ProjectRole;

  constructor(
    private popupRef: PopupRef<HelperDialogComponent>,
    @Inject(FS_POPUP_DATA) public data: { projectAuthority: string },
    private dialogService: DialogService,
  ) {
    this.projectAuthority = data.projectAuthority;
  }

  showHelpWindow(helpTopic?, videoName?) {
    this.dialogService.open(HelpWindowComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
        'fs-dialog-no-overflow-panel',
      ],
      data: {
        topicName: helpTopic,
        videoName: videoName,
      },
    });
  }

  showShortCutWindow(): void {
    this.dialogService.open(ShortcutDetailsComponent, {
      closeOnNavigation: true,
      disableClose: false,
      backdropClass: 'fs-dialog-overlay',
      hasBackdrop: true,
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
        'fs-dialog-message-base-panel',
      ],
    });
  }
}
