import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { SetStaticInjector } from './app/shared/injector/static-injector';

if (environment.production) {
  enableProdMode();
}
// enableProdMode();

const bootstrapPromise = platformBrowserDynamic().bootstrapModule(AppModule);

// Logging bootstrap information
bootstrapPromise
  .then((module) => {
    SetStaticInjector(module.injector);
  })
  .then((success) => console.log(`Bootstrap success`))
  .then((success) => console.log('Application started'))
  .catch((err) => console.error(err));
