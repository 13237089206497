import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  IAnnounce,
  INewFeatureAnnounce,
} from '../../models/domainConfig.model';
import { FS_POPUP_DATA } from '../../services/popup/popup.constants';
import { DialogService } from '../../services/dialog/dialog.service';
import { AnnounceImgViewerComponent } from './announce-img-viewer/announce-img-viewer.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'fs-app-updates',
  templateUrl: './app-updates.component.html',
  styleUrls: ['./app-updates.component.scss'],
})
export class AppUpdatesComponent {
  newFeatureAnnounce: INewFeatureAnnounce = {} as INewFeatureAnnounce;
  announces: IAnnounce[];

  constructor(
    private translate: TranslateService,
    private dialogService: DialogService,
    @Inject(FS_POPUP_DATA)
    private data: { newFeatureAnnounce: INewFeatureAnnounce },
  ) {
    this.newFeatureAnnounce = data.newFeatureAnnounce;
    this.announces = this.normalizeLanguageKeys(data.newFeatureAnnounce);
  }

  normalizeLanguageKeys(newFeatureAnnounce: INewFeatureAnnounce): IAnnounce[] {
    const languageKey: string = this.translate.currentLang.replace('-', '');

    return newFeatureAnnounce.announces.map((announce) => {
      announce.announceText = announce[languageKey];
      return announce;
    });
  }

  openAnnounceImgViewerModal(announce: IAnnounce): void {
    this.dialogService.open(AnnounceImgViewerComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
        'fs-dialog-no-overflow-panel',
      ],
      data: {
        announce: announce,
        appAnnounceVersion: this.newFeatureAnnounce.version,
      },
    });
  }
}
