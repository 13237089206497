<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{ 'left-menu.finalization.modaltitle' | translate }}
    {{ currentUserName }}
    {{ 'left-menu.finalization.modaltitle2' | translate }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    aria-hidden="true"
    (click)="closeWindow()"
  >
    <i class="fa fa-times-circle"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="container">
    <div class="row" style="padding: 20px">
      <div class="col" style="border: 2px solid #fff; padding: 20px">
        <div class="single-filter">
          <p>{{ 'left-menu.finalization.option4' | translate }}</p>
        </div>

        <div class="single-filter">
          <p>
            {{ 'left-menu.finalization.option5' | translate }} '{{
              projectName
            }}'.
          </p>
        </div>

        <div class="single-filter">
          <p>{{ 'left-menu.finalization.option6' | translate }}</p>
        </div>
      </div>
    </div>

    <div *ngIf="showConfirm">
      <div style="float: left">
        <label
          >{{
            'left-menu.finalization.option7' | translate
          }}
          &nbsp;:&nbsp;&nbsp;</label
        >
      </div>
      <div style="float: left">
        <input
          class="form-control imageText"
          oninput="this.value = this.value.toUpperCase()"
          [(ngModel)]="confirm"
          name="confirm"
          type="text"
        />
      </div>
      <div style="float: left" *ngIf="confirm == translatedConfirmWord">
        <div class="row">
          <div class="mf-sngle ftr-btns col-sm-12 col-xs-12">
            <div class="align-left">
              <div style="padding-left: 10px">
                <button style="margin: 0" (click)="onClickGo()">
                  {{ 'top-menu.goto.button.go' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <button
    class="fs-dialog-btn w-auto ml-3"
    *ngIf="showFinalize"
    (click)="showConfirmMethod()"
  >
    {{ 'left-menu.finalization.finalize' | translate }}
  </button>

  <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
    {{ 'left-menu.finalization.close' | translate }}
  </button>
</div>
