import { TopmenusService } from './../../../top-menu/top-menu.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlbumsService } from './albums.service';
import { TranslateService } from '@ngx-translate/core';
import { AlbumssharedService } from '../shared-menus/albums-service/albums-shared.service';
import { SignoffService } from '../signoff/signoff.service';
import { WideEditService } from '../wide-edit/wide-edit.service';
import { PaginationService } from '../../../pagination/pagination.service';
import { NGXLogger } from 'ngx-logger';
import { SnackBarService } from '../../../../../../shared/services/snack-bar.service';
import { AlbumColorTagsService } from '../../../../../../shared/services/album-color-tags.service';
import {
  AssetInfo,
  AssetInfoDTOList,
} from '../../../../../../shared/models/assetInfo.model';
import { AlbumType } from '../../../../../../shared/enum/album-type.enum';
import { UserDetails } from '../../../../../../shared/models/userdetails.model';
import { ProjectDetailPermission } from '../../../../../../shared/models/projectpermission.model';
import { SidebarService } from '../../../../sidebar-service/sidebar-service';
import { Modalstatusservice } from '../../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../shared/services/loader.service';
import { UserService } from '../../../../../../shared/services/user.service';
import { UserRole } from '../../../../../../shared/enum/user-role.enum';
import { ConstantsGlobal } from '../../../../../../shared/constants/constants';
import { Utils } from '../../../../../../shared/utils/utils';
import { AppStateService } from '../../../../../../shared/services/app-state.service';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../shared/store/selected-images-store';
import { DialogService } from '../../../../../../shared/services/dialog/dialog.service';
import { ALBUM } from '../shared-menus/albumType';
import { LinkComponent } from '../../../top-menu/link/link.component';
import { PrinttoolComponent } from '../../../top-menu/printtool/printtool.component';
import { SendmailComponent } from '../../../top-menu/sendmail/sendmail.component';
import { DownloadComponent } from '../../../top-menu/download/download.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { ContextMenuPosition } from '../../../../../../shared/models/contextMenuPosition.model';
import { UnsubscriberComponent } from 'src/app/shared/components/unsubscriber/unsubscriber.component';
import { ProjectRole } from '../../../../../../shared/enum/project-role.enum';
import { ExpressDownloadComponent } from '../../../top-menu/express-download/express-download.component';
import { forkJoin, Observable } from 'rxjs';
import { ExclusiveSensitiveAssetDialogComponent } from '../../../../../../shared/components/exclusive-sensitive-asset-dialog/exclusive-sensitive-asset-dialog.component';
import { DownloadService } from '../../../top-menu/download/download.service';
import {ApiErrorHandlerService} from '../../../../../../shared/services/api-error-handler.service';
import { ITreeOptions, TreeComponent, TreeModel, TreeNode } from '@bugsplat/angular-tree-component';

@Component({
  selector: 'fs-albums',
  templateUrl: 'albums.component.html',
  styleUrls: ['./albums.component.scss'],
})
export class AlbumsComponent
  extends UnsubscriberComponent
  implements OnInit, OnDestroy
{
  @ViewChild('tree') treeComponent: TreeComponent;
  @ViewChild('tree1') treeComponent1: TreeComponent;
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  projectImageData: AssetInfo = new AssetInfo();
  currentAlbumInfo: any;
  albumNode = [];
  userDataInfo: any;
  tagColorResponseDTOList = [];
  tagKeyResponseDTOList = [];
  isClickOnAlbumName = false;
  isEnabaleToAddActiveAlbumFolder = false;
  isClickOnAlbumFolder = false;
  indexAlbumName: number;
  indexAlbumFolder: number;
  isDragNodeTree = false;
  activeAlbumFolder: any;
  copyToClipBoardData: any = {};
  albumTypeId: AlbumType = AlbumType.Albums;
  currentUser: UserDetails = new UserDetails();
  currentFilter: string;
  preventSingleClickTimer: any;
  preventSingleClick = false;
  timer: any;
  activateNode: any;
  activateNodeID = 0;
  advanceImageToAlbumID: any;
  executiveAlbumManagement = false;
  isNotifyShown = false;
  assetsIds: any;
  noOfImages: any;
  constantsGlobalData: any;
  projectAuthority: string;
  hasWatermark = false;
  userAuthority: string;
  treeOptions: ITreeOptions = {
    isExpandedField: 'expanded',
    idField: 'id',
    childrenField: 'children',
    allowDrag: (node) => {
      if (node.data.id !== 0) {
        return true;
      } else {
        return false;
      }
    },
    allowDrop: (node, event) => {
      if (
        event.parent.data.id !== 0 &&
        event.parent.data.ownerId === this.currentUser.id
      ) {
        return true;
      } else if (
        event.parent.data.id === 0 &&
        event.parent.data.ownerId === this.currentUser.id
      ) {
        return true;
      } else {
        return false;
      }
    },
  };

  options: ITreeOptions = {
    isExpandedField: 'expanded',
    idField: 'id',
    childrenField: 'children',
    allowDrag: () => {
      return false;
    },
    allowDrop: () => {
      return false;
    },
  };
  menuTopLeftPosition: ContextMenuPosition = { x: '0', y: '0' };

  projectAlbums: any = [];
  tagColorOnViewPage = false;
  minExpressDownload: number;
  isTaggerRole: boolean;

  // Added right click menus
  downloadType = 0;
  downloadOnlyLA = false;
  exclusiveStatus = 0;
  emailAccess = false;
  expressDownload = false;
  printAccess = false;
  deleteAssetAccess = false;
  adhocLinkAccess = false;
  SELECTION_LIMIT = 250;
  SELECTION_PRINT_LIMIT = 1000;
  projectDetailPermission: ProjectDetailPermission =
    new ProjectDetailPermission();
  userRole = UserRole;
  projectRole = ProjectRole;

  constructor(
    private router: Router,
    private sidebarService: SidebarService,
    private modalstatusservice: Modalstatusservice,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private albumSharedService: AlbumssharedService,
    private snackBarService: SnackBarService,
    private albumService: AlbumsService,
    private topmenuService: TopmenusService,
    private userService: UserService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private signoffService: SignoffService,
    private wideEditService: WideEditService,
    private paginationService: PaginationService,
    private logger: NGXLogger,
    private topmenusService: TopmenusService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private albumColorTagsService: AlbumColorTagsService,
    private downloadService: DownloadService,
    private appStateService: AppStateService,
    private selectedImagesStore: SelectedImagesStore,
  ) {
    super();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.isTaggerRole = this.userService.isAdminWithTaggerRole();

    this.currentUser = this.userService.mergeWithCurrentUser(this.currentUser);

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority) => {
        this.userAuthority = userAuthority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.albumService.userDataInfoGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.userDataInfo = data;
      });

    this.sidebarService.selectedAlbum
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        if (!Utils.isObjectEmpty(album)) {
          this.activeAlbumFolder = album;
        } else {
          const obj = {
            id: 0,
          };
          this.activeAlbumFolder = obj;
        }
      });

    this.handaleModalStatus();

    this.sidebarService.advanceImageToAlbumIDGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((id) => {
        this.advanceImageToAlbumID = id;
      });

    this.sidebarService.updateTaggingCustomAlbum
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        if (!Utils.isObjectEmpty(album)) {
          if (Object.prototype.hasOwnProperty.call(album, 'id')) {
            this.isEnabaleToAddActiveAlbumFolder = false;
            this.updateAlbumInNodeKeys(album);
          } else {
            this.isEnabaleToAddActiveAlbumFolder = true;
            this.updateAlbumInNode1(album);
          }
        }
      });

    this.sidebarService.updateTaggingProjectAlbumGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        if (!Utils.isObjectEmpty(album)) {
          this.updateProjectAlbumInNodeKeys(album);
        }
      });

    this.sidebarService.albumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.albumNode = [];
          this.projectAlbums = [];
          this.tagColorResponseDTOList = [];
          this.tagKeyResponseDTOList = [];
          this.tagColorResponseDTOList = albumList.tagColorResponseDTOList;
          this.tagKeyResponseDTOList = albumList.tagKeyResponseDTOList;
          this.albumNode = albumList.albums;

          if (albumList.projectAlbum) {
            this.projectAlbums = [albumList.projectAlbum];
            this.updateKeysInProjectAlbum(albumList.projectAlbum.children);
            this.treeComponent1.treeModel.update();
          } else {
            this.projectAlbums = [];
          }

          this.updateKeysInArray(this.tagKeyResponseDTOList);
          this.treeComponent.treeModel.update();
        }
      });

    const temp = {
      id: 0,
    };
    this.activeAlbumFolder = temp;

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.projectDetailPermission = res;
          this.setAllPermission(res);
          this.executiveAlbumManagement =
            res.permissionDTO.executiveAlbumManagement;
          this.noOfImages = res.viewSettingDTO.imagePerPage;
        }
      });

    this.albumColorTagsService.isColorPanelEnabled$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.tagColorOnViewPage = status;
        if (status) {
          this.activateNodeID = 0;
        }
      });

    if (this.projectAuthority === ProjectRole.ROLE_ALBUMVIEWER) {
      this.callAlbumViewerDataAPI();
    } else {
      this.callAlbumDataAPI();
    }
  }

  setUpContextMenuPosition(pos: ContextMenuPosition) {
    this.menuTopLeftPosition = pos;
  }

  onMoveNode($event) {
    this.isDragNodeTree = true;
    if (
      $event.to.parent.id === 0 &&
      $event.to.parent.ownerId === this.currentUser.id
    ) {
      this.setAlbumInOunersNode($event.node);
      this.treeComponent.treeModel.update();
    }
  }

  callAlbumDataAPI() {
    this.albumService
      .getAlbumGroupInfo(this.viewProjectService.getProjectId())
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (Object.keys(res).length !== 0) {
          this.sidebarService.setAlbumList(res);
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  callAlbumViewerDataAPI() {
    this.albumService
      .getAlbumViewerInfo(this.viewProjectService.getProjectId())
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (Object.keys(res).length !== 0) {
          this.sidebarService.setAlbumList(res);
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  onClickOnSubMenuTag(nodeData?: any, tagData?: any) {
    this.isEnabaleToAddActiveAlbumFolder = false;
    const obj = {
      id: nodeData.data.id,
      tagKey: tagData.tagKey,
      value: nodeData.data.value,
    };
    this.loaderService.displayLoader(true);
    this.albumService
      .putAlbumTagKey(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.loaderService.displayLoader(false);
        if (res.status) {
          this.tagKeyResponseDTOList = res.t;
          this.updateKeysInArray(this.tagKeyResponseDTOList);
          this.updateKeysInAlbum(nodeData, tagData);
          this.snackBarService.showSnackBar(res.message);
        } else {
          this.snackBarService.showSnackBarError(res.message);
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  onClickOnSubMenuColor(nodeData?: any, tagData?: any) {
    const obj = {
      id: nodeData.data.id,
      tagColor: tagData.tagColor,
      value: nodeData.data.value,
    };

    this.loaderService.displayLoader(true);
    this.albumService
      .putAlbumTagColor(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.loaderService.displayLoader(false);
          if (res.status) {
            this.tagColorResponseDTOList = res.t;
            this.updateColorsInAlbum(nodeData, tagData);
            this.snackBarService.showSnackBar(res.message);
          } else {
            this.snackBarService.showSnackBarError(res.message);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  removeTagKey(node: any) {
    this.isEnabaleToAddActiveAlbumFolder = false;
    const obj = {
      id: node.data.id,
      tagKey: -1,
      value: node.data.value,
    };

    this.loaderService.displayLoader(true);
    this.albumService
      .putAlbumTagKey(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.loaderService.displayLoader(false);
          if (res.status) {
            this.tagKeyResponseDTOList = res.t;
            this.updateKeysInArray(this.tagKeyResponseDTOList);
            this.updateKeysInAlbum(node);

            this.snackBarService.showSnackBar(res.message);
          } else {
            this.snackBarService.showSnackBarError(res.message);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  removeColorKey(node: any) {
    const obj = {
      id: node.data.id,
      tagColor: -1,
      value: node.data.value,
    };

    this.loaderService.displayLoader(true);
    this.albumService
      .putAlbumTagColor(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.loaderService.displayLoader(false);
          if (res.status) {
            this.tagColorResponseDTOList = res.t;
            this.updateColorsInAlbum(node);
            this.snackBarService.showSnackBar(res.message);
          } else {
            this.snackBarService.showSnackBarError(res.message);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  updateKeysInArray(albumList) {
    const obj = [];
    this.tagKeyResponseDTOList.forEach((keyObj) => {
      if (keyObj.id !== -1) {
        obj.push(keyObj);
      }
    });
    this.sidebarService.setSelectedAlbumKeys(obj);
  }

  updateKeysInProjectAlbum(projectAlbum) {
    const obj = [];
    projectAlbum.forEach((keyObj) => {
      if (keyObj.id !== -1) {
        obj.push(keyObj);
      }
    });
    this.sidebarService.setSelectedProjectAlbumKeys(obj);
  }

  updateKeysInAlbum(node, tag?) {
    this.treeComponent.treeModel
      .getNodeById(node.data.id)
      .setActiveAndVisible();
    const treeModel: TreeModel = this.treeComponent.treeModel;
    const activateNode: TreeNode = treeModel.getActiveNode();
    if (tag) {
      activateNode.data.tagKey = tag.tagKey;
    } else {
      activateNode.data.tagKey = -1;
    }
    this.treeComponent.treeModel.update();
    this.treeComponent.treeModel.getNodeById(node.data.id).toggleActivated();
    if (this.activeAlbumFolder.id !== 0) {
      this.treeComponent.treeModel
        .getNodeById(this.activeAlbumFolder.id)
        .setActiveAndVisible();
    }
  }

  updateColorsInAlbum(node, tag?) {
    this.treeComponent.treeModel
      .getNodeById(node.data.id)
      .setActiveAndVisible();
    const treeModel: TreeModel = this.treeComponent.treeModel;
    const activateNode: TreeNode = treeModel.getActiveNode();
    if (tag) {
      activateNode.data.tagColor = tag.tagColor;
      activateNode.data.tagColorCode = tag.tagColorCode;
    } else {
      activateNode.data.tagColor = -1;
      activateNode.data.tagColorCode = '#1069C9';
    }
    this.treeComponent.treeModel.update();
    this.treeComponent.treeModel.getNodeById(node.data.id).toggleActivated();
    if (this.activeAlbumFolder.id !== 0) {
      this.treeComponent.treeModel
        .getNodeById(this.activeAlbumFolder.id)
        .setActiveAndVisible();
    }
    this.sidebarService.setUpdatedAlbumList(treeModel.nodes);
    if (this.tagColorOnViewPage) {
      this.albumColorTagsService.setIsColorPanelEnabled(
        this.tagColorOnViewPage,
      );
    }
  }

  updateAlbumExecutiveStatus(node, data?) {
    this.treeComponent.treeModel
      .getNodeById(node.data.id)
      .setActiveAndVisible();
    const treeModel: TreeModel = this.treeComponent.treeModel;
    const activateNode: TreeNode = treeModel.getActiveNode();
    activateNode.data.executive = data.active;
    activateNode.data.executiveView = false;
    activateNode.data.executiveViewLocal = false;
    activateNode.data.executivePermission = [];
    this.treeComponent.treeModel.update();
    this.treeComponent.treeModel.getNodeById(node.data.id).toggleActivated();
  }

  editAlbumExecutiveStatus(data?) {
    this.treeComponent.treeModel
      .getNodeById(data.albumId)
      .setActiveAndVisible();
    const treeModel: TreeModel = this.treeComponent.treeModel;
    const activateNode: TreeNode = treeModel.getActiveNode();
    activateNode.data.executive = data.executive;
    activateNode.data.executiveView = data.executiveView;
    activateNode.data.executivePermission = data.executivePermission;
    activateNode.data.permission = data.permission;
    this.treeComponent.treeModel.update();
    this.treeComponent.treeModel.getNodeById(data.albumId).toggleActivated();
  }

  public onlyForCurrentUser = (item: any): boolean => {
    if (item.data.ownerId === this.currentUser.id) {
      this.currentAlbumInfo = item;
      return true;
    }
    return false;
  };

  public executive = (item: any): boolean => {
    if (this.executiveAlbumManagement && !item.data.executive) {
      return true;
    }
    return false;
  };

  public revertExecutive = (item: any): boolean => {
    if (this.executiveAlbumManagement && item.data.executive) {
      return true;
    }
    return false;
  };

  public copyToClipBoardAvailable = (item: any): boolean => {
    if (!Utils.isObjectEmpty(this.copyToClipBoardData)) {
      return true;
    }
    return false;
  };

  notifyExecutivePermissions(item: any) {
    this.sidebarService.setSelectedAlbumPermission(item.data);
    this.isNotifyShown = true;
  }

  onCloseNotifyWindow(evt) {
    this.isNotifyShown = false;
  }

  public onlyForOtherUser = (item: any): boolean => {
    if (item.data.ownerId === this.currentUser.id) {
      return false;
    }
    return true;
  };

  findColor(id) {
    this.tagColorResponseDTOList.forEach((obj) => {
      if (id === obj.tagColor && id !== -1) {
        return obj.tagColorCode;
      }
    });
  }

  copyCreate(item: any, type) {
    const obj = {
      destinationAlbumTypeId: type,
      projectId: this.viewProjectService.getProjectId(),
      sourceAlbumId: item.data.id,
    };
    this.loaderService.displayLoader(true);
    this.albumService
      .postAlbumCopyCreate(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.loaderService.displayLoader(false);
          if (type === 3) {
            this.signoffService.addSignOffAlbum(res);
          } else {
            this.wideEditService.addWideEditAlbum(res);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  handaleModalStatus() {
    this.appStateService.renameAlbum$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (status.loaded && status.data.dialogName === ALBUM) {
          this.renameAlbuminNode(status.data);
        }
      });

    this.appStateService.addAlbum$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (status.loaded && status.data.dialogName === ALBUM) {
          this.setAlbumInNode(status.data);
        }
      });

    this.appStateService.deleteAlbum$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (status.loaded && status.data.dialogName === ALBUM) {
          this.removeAlbumInNode(status.data);
          this.snackBarService.showSnackBar(
            this.translate.instant(
              'left-menu.tab-bar.sidemenus.albums.messageTitles.albumDeleted',
            ),
          );
        }
      });

    this.appStateService.editAlbum$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (status.loaded && status.data.dialogName === ALBUM) {
          this.updatePermissionsInNode(status.data);
        }
      });

    this.appStateService.editPermissionAlbum$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (status.loaded) {
          this.editAlbumExecutiveStatus(status.data);
        }
      });
  }

  onClickProjectAlbum(albumData) {
    if (albumData.assetCount !== 0) {
      this.loaderService.displayLoader(true);
      const obj = {
        projectId: this.viewProjectService.getProjectId(),
        albumId: albumData.id,
      };
      this.currentFilter = 'album:' + albumData.id;

      this.viewProjectService
        .callProjectImageIDs(
          this.viewProjectService.getProjectId(),
          this.currentFilter,
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res1) => {
            if (res1) {
              this.assetsIds = Utils.getImageIdsBasedOnPage(
                1,
                this.noOfImages,
                res1,
              );
              const data = {
                assetIds: this.assetsIds,
                projectId: this.viewProjectService.getProjectId(),
                filter: this.currentFilter,
              };

              this.viewProjectService
                .callAssetsInfoBasedOnAssetsId(data)
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                  (resData) => {
                    this.router.navigate([
                      Utils.prepareFilterNavigationURL(
                        this.viewProjectService.getProjectRole(),
                        this.viewProjectService.getProjectId(),
                        this.currentFilter,
                        1,
                      ),
                    ]);
                    this.loaderService.displayLoader(false);
                    this.selectedImagesStore.clear();
                    this.sidebarService.setAlbumImageData(resData);
                    this.sidebarService.setSelectedAlbumName(albumData);
                    this.paginationService.setPaginationIndex(1);
                  },
                  (error) => {
                    this.apiErrorHandlerService.getHandler().handle(error);
                    this.loaderService.displayLoader(false);
                  },
                );
            } else {
              this.loaderService.displayLoader(false);
              this.alertCustom(this.translate.instant('view-project.alert2'));
            }
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    } else {
      this.alertCustom('No image(s) found!');
    }
  }

  onClickAlbum(index) {
    this.indexAlbumFolder = index;
    this.isClickOnAlbumFolder = !this.isClickOnAlbumFolder;
    this.isEnabaleToAddActiveAlbumFolder = true;
    if (!this.isClickOnAlbumFolder) {
      this.resetSelectedAlbum();
    }
  }

  onClickAlbumName(index) {
    this.isEnabaleToAddActiveAlbumFolder = false;
    this.preventSingleClickTimer = 0;
    this.preventSingleClick = false;
    const delay = 400;
    this.isClickOnAlbumName = true;
    this.indexAlbumName = index;

    this.timer = setTimeout(() => {
      if (!this.preventSingleClick) {
        this.isEnabaleToAddActiveAlbumFolder = false;
        this.sidebarService.removeHoverAlbum();
        this.activateNodeData();
      }
    }, delay);
  }

  onDoubleClickAlbumName() {
    this.isClickOnAlbumName = false;
    this.preventSingleClick = true;
    clearTimeout(this.preventSingleClickTimer);
    this.activateNodeData();
  }

  callMoveNodeAPI(obj: any, event: any) {
    this.loaderService.displayLoader(true);
    this.albumSharedService
      .callRenameAlbum(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.isDragNodeTree = false;
          this.loaderService.displayLoader(false);
          this.snackBarService.showSnackBar(
            this.translate.instant(
              'left-menu.tab-bar.sidemenus.albums.messageTitles.albumMoved',
            ),
          );
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.isDragNodeTree = false;
          this.loaderService.displayLoader(false);
        },
      );
    this.resetSelectedAlbum();
  }

  onPrejectAlbumExpanded(event: any) {
    this.viewProjectService.setActivatedLeftProjectAlbum(event.isExpanded);
    if (event.isExpanded) {
      this.snackBarService.showSnackBarWarning(
        this.translate.instant(
          'left-menu.tab-bar.sidemenus.albums.customAlbumsHotKeys',
        ),
      );
    }
  }

  onEvent(event: any) {
    this.logger.log(event.eventName);
    if (event.eventName === 'moveNode') {
      let obj: any;
      if (event.to.parent.id !== 0) {
        obj = {
          id: event.node.id,
          value: event.node.value,
          parentAlbumId: event.to.parent.id,
          projectId: this.viewProjectService.getProjectId(),
          albumTypeId: event.node.albumTypeId,
        };
        this.treeComponent.treeModel
          .getNodeById(event.node.id)
          .toggleActivated();
        this.callMoveNodeAPI(obj, event);
      } else if (event.to.parent.ownerId === this.currentUser.id) {
        obj = {
          id: event.node.id,
          value: event.node.value,
          parentAlbumId: 0,
          projectId: this.viewProjectService.getProjectId(),
          albumTypeId: event.node.albumTypeId,
        };

        this.treeComponent.treeModel
          .getNodeById(event.to.parent.id)
          .toggleActivated();
        const treeModel: TreeModel = this.treeComponent.treeModel;
        const activateNode: TreeNode = treeModel.getActiveNode();
        if (activateNode && activateNode.parent) {
          activateNode.parent.data.children[0].children.splice(
            activateNode.parent.data.children[0].children.indexOf(event.node),
            1,
          );
          this.treeComponent.treeModel.update();
        }
        this.callMoveNodeAPI(obj, event);
      }
    }
    if (event.eventName === 'updateData') {
      //**//
    }
    if (event.eventName === 'deactivate') {
      if (this.isClickOnAlbumName) {
        this.treeComponent.treeModel.getFocusedNode().blur();
        if (this.indexAlbumName === this.indexAlbumFolder) {
          this.treeComponent.treeModel
            .getNodeById(event.node.data.id)
            .toggleActivated();
        }
      }
      if (this.isEnabaleToAddActiveAlbumFolder) {
        this.isClickOnAlbumFolder = false;
      }
    }
    if (event.eventName === 'activate') {
      this.activateNode = event.node.data;
      if (this.isEnabaleToAddActiveAlbumFolder) {
        if (
          this.userAuthority === UserRole.ROLE_ADMIN ||
          this.currentUser.id === event.node.data.ownerId ||
          this.checkPermission(event.node.data)
        ) {
          this.isClickOnAlbumFolder = true;
          this.isEnabaleToAddActiveAlbumFolder = false;
          this.activeAlbumFolder = this.activateNode;
          this.sidebarService.setSelectedAlbum(this.activateNode);
        } else {
          // You don't have permission to select this album
          this.alertCustom(this.translate.instant('view-project.alert20'));
          this.treeComponent.treeModel
            .getNodeById(event.node.data.id)
            .toggleActivated();
        }
      }
    }
  }

  checkPermission(data) {
    let permissionStatus = false;
    if (data.permission.length > 0) {
      data.permission.forEach((element) => {
        if (element.userId === this.currentUser.id) {
          if (element.permission === 2) {
            permissionStatus = true;
          }
        }
      });
    }
    return permissionStatus;
  }

  activateNodeData() {
    if (this.isClickOnAlbumName) {
      if (this.activateNode.id === 0) {
        this.treeComponent.treeModel
          .getNodeById(this.activateNode.id)
          .toggleActivated();
      } else if (Number(this.activateNode.assetCount) === 0) {
        this.alertCustom(this.translate.instant('view-project.alert2'));
      } else {
        this.loaderService.displayLoader(true);
        this.currentFilter = 'album:' + this.activateNode.id;
        if (this.activateNode.executiveViewLocal) {
          this.viewProjectService.setExecutiveAlbumView(true);
          this.viewProjectService.setExecutiveAlbumID(this.activateNode.id);

          this.viewProjectService
            .callProjectImageIDs(
              this.viewProjectService.getProjectId(),
              this.currentFilter,
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (ProImageIdsRes) => {
                if (ProImageIdsRes) {
                  this.assetsIds = Utils.getImageIdsBasedOnPage(
                    1,
                    this.noOfImages,
                    ProImageIdsRes,
                  );
                  const data = {
                    assetIds: this.assetsIds,
                    projectId: this.viewProjectService.getProjectId(),
                    filter: this.currentFilter,
                    view: 'executive-album-2',
                  };

                  this.viewProjectService
                    .callAssetsInfoBasedOnAssetsId(data)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((executiveAlbumData) => {
                      this.router.navigate([
                        Utils.prepareFilterNavigationURL(
                          this.viewProjectService.getProjectRole(),
                          this.viewProjectService.getProjectId(),
                          this.currentFilter,
                          1,
                        ),
                      ]);
                      this.loaderService.displayLoader(false);
                      this.viewProjectService.setExecutiveAlbum(
                        this.activateNode.executive,
                      );
                      this.selectedImagesStore.clear();
                      this.sidebarService.setAlbumImageData(executiveAlbumData);
                      this.sidebarService.setSelectedAlbumName(
                        this.activateNode,
                      );
                      if (this.activeAlbumFolder.id !== 0) {
                        this.treeComponent.treeModel
                          .getNodeById(this.activeAlbumFolder.id)
                          .setActiveAndVisible();
                      } else {
                        this.treeComponent.treeModel
                          .getNodeById(this.activateNode.id)
                          .toggleActivated();
                      }
                      this.paginationService.setPaginationIndex(1);
                    },(error) => {
                      this.apiErrorHandlerService.getHandler().handle(error);
                      this.loaderService.displayLoader(false);
                    });
                } else {
                  this.loaderService.displayLoader(false);
                  this.alertCustom(
                    this.translate.instant('view-project.alert2'),
                  );
                }
              },
              (error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              },
            );
        } else {
          this.viewProjectService.setExecutiveAlbumView(false);
          this.viewProjectService
            .callProjectImageIDs(
              this.viewProjectService.getProjectId(),
              this.currentFilter,
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res1) => {
                if (res1) {
                  this.assetsIds = Utils.getImageIdsBasedOnPage(
                    1,
                    this.noOfImages,
                    res1,
                  );
                  const data = {
                    assetIds: this.assetsIds,
                    projectId: this.viewProjectService.getProjectId(),
                    filter: this.currentFilter,
                  };

                  this.viewProjectService
                    .callAssetsInfoBasedOnAssetsId(data)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(
                      (resData) => {
                        this.router.navigate([
                          Utils.prepareFilterNavigationURL(
                            this.viewProjectService.getProjectRole(),
                            this.viewProjectService.getProjectId(),
                            this.currentFilter,
                            1,
                          ),
                        ]);
                        this.loaderService.displayLoader(false);
                        this.viewProjectService.setExecutiveAlbum(false);
                        this.selectedImagesStore.clear();
                        this.sidebarService.setAlbumImageData(resData);
                        this.sidebarService.setSelectedAlbumName(
                          this.activateNode,
                        );
                        if (this.activeAlbumFolder.id !== 0) {
                          this.treeComponent.treeModel
                            .getNodeById(this.activeAlbumFolder.id)
                            .setActiveAndVisible();
                        } else {
                          this.treeComponent.treeModel
                            .getNodeById(this.activateNode.id)
                            .toggleActivated();
                        }
                        this.paginationService.setPaginationIndex(1);
                      },
                      (error) => {
                        this.apiErrorHandlerService.getHandler().handle(error);
                        this.loaderService.displayLoader(false);
                      },
                    );
                } else {
                  this.loaderService.displayLoader(false);
                  this.alertCustom(
                    this.translate.instant('view-project.alert2'),
                  );
                }
              },
              (error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              },
            );
        }
      }
      this.isClickOnAlbumName = false;
    } else {
      if (this.activateNodeID === 0) {
        this.activateNodeID = this.activateNode.id;
        this.albumNameClicked(this.activateNode);
        this.snackBarService.showSnackBarWarning(
          this.translate.instant('genericalbum.visualizerOn'),
        );
      } else if (this.activateNodeID === this.activateNode.id) {
        this.activateNodeID = 0;
        this.removeAlbumNameClicked();
        this.snackBarService.showSnackBarWarning(
          this.translate.instant('genericalbum.visualizerOff'),
        );
        this.onHoverRemoveOverAlbumName();
      } else {
        this.activateNodeID = this.activateNode.id;
        this.albumNameClicked(this.activateNode);
        this.snackBarService.showSnackBarWarning(
          this.translate.instant('genericalbum.visualizerOn'),
        );
      }
      if (this.activeAlbumFolder.id !== 0) {
        this.treeComponent.treeModel
          .getNodeById(this.activeAlbumFolder.id)
          .setActiveAndVisible();
      } else {
        this.treeComponent.treeModel
          .getNodeById(this.activateNode.id)
          .setActiveAndVisible();
        this.treeComponent.treeModel
          .getNodeById(this.activateNode.id)
          .toggleActivated();
      }
    }
  }

  handleChangeExecutive(status, index) {
    this.onClickAlbumName(index);
  }

  setAlbumInNode(newAlbumData) {
    this.snackBarService.showSnackBar(
      this.translate.instant(
        'left-menu.tab-bar.sidemenus.albums.messageTitles.albumAdded',
      ),
    );
    const treeModel: TreeModel = this.treeComponent.treeModel;
    const activateNode: TreeNode = treeModel.getActiveNode();

    if (activateNode !== null && this.activeAlbumFolder.id !== 0) {
      if (activateNode.hasChildren) {
        activateNode.data.children.push(newAlbumData);
        activateNode.data.children = this.sortingData(
          activateNode.data.children,
        );
      } else {
        activateNode.data.children.push(newAlbumData);
        activateNode.data.children = this.sortingData(
          activateNode.data.children,
        );
      }
    } else {
      this.setAlbumInOunersNode(newAlbumData);
    }
    this.sidebarService.setUpdatedAlbumList(treeModel.nodes);
    this.treeComponent.treeModel.update();
    this.isEnabaleToAddActiveAlbumFolder = true;
    this.isClickOnAlbumFolder = true;
    this.treeComponent.treeModel
      .getNodeById(newAlbumData.id)
      .setActiveAndVisible();
    this.treeComponent.treeModel.getNodeById(newAlbumData.id).scrollIntoView();
  }

  setAlbumInOunersNode(newAlbumData) {
    let inserIndex = -1;
    this.albumNode.forEach((obj, index) => {
      if (obj.ownerId === this.currentUser.id && obj.id === 0) {
        for (let i = index + 1; i < this.albumNode.length; i++) {
          if (this.albumNode[i].id === 0) {
            inserIndex = i;
            this.albumNode.splice(inserIndex, 0, newAlbumData);

            let copyData = this.albumNode.splice(1, inserIndex);
            copyData = this.sortingData(copyData);
            copyData.forEach((objTest, indexTest) => {
              this.albumNode.splice(indexTest + 1, 0, objTest);
            });
            return;
          }
          if (this.albumNode.length - 1 === i) {
            inserIndex = i + 1;
            this.albumNode.splice(inserIndex, 0, newAlbumData);
            let copyData = this.albumNode.splice(1, inserIndex);
            copyData = this.sortingData(copyData);
            copyData.forEach((objTest, indexTest) => {
              this.albumNode.splice(indexTest + 1, 0, objTest);
            });
            return;
          }
        }
      }
    });

    if (inserIndex === -1) {
      const ownerObj = {
        id: 0,
        ownerId: this.currentUser.id,
      };
      this.albumNode.splice(0, 0, ownerObj);
      this.albumNode.splice(1, 0, newAlbumData);
    }
  }

  sortingData(copyData) {
    return copyData.sort((a, b) => {
      if (a.value.toLowerCase() < b.value.toLowerCase()) {
        return -1;
      }
      if (b.value.toLowerCase() < a.value.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  removeAlbumInNode(removeAlbumData) {
    const treeModel: TreeModel = this.treeComponent.treeModel;
    const activateNode: TreeNode = treeModel.getActiveNode();

    this.updateCustomeAlbumsID(removeAlbumData.id, false);
    this.updateCustomeAlbumsIDForAll(removeAlbumData.id, false);
    if (activateNode && activateNode.parent) {
      this.tagKeyResponseDTOList.forEach((data) => {
        if (data.id === removeAlbumData.id) {
          data.id = -1;
          data.value = '';
        }
      });

      this.tagColorResponseDTOList.forEach((data) => {
        if (data.id === removeAlbumData.id) {
          data.id = -1;
          data.value = '';
        }
      });

      this.updateKeysInArray(this.tagKeyResponseDTOList);
      activateNode.parent.data.children.splice(
        activateNode.parent.data.children.indexOf(removeAlbumData),
        1,
      );
      let count = 0;
      activateNode.parent.data.children.forEach((element) => {
        if (element.ownerId === this.currentUser.id) {
          count++;
        }
      });

      this.resetSelectedAlbum();
      this.sidebarService.setUpdatedAlbumList(treeModel.nodes);
      this.treeComponent.treeModel.update();
    }
  }

  updateCustomeAlbumsIDForAll(id, isAdd) {
    const updatedSelectedImages = [];
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );

    this.projectImageData.assetInfoDTOList.forEach((imageData, i) => {
      const customeAlbumIndex: number =
        this.projectImageData.assetInfoDTOList[i].customAlbums.indexOf(id);
      if (customeAlbumIndex !== -1) {
        this.projectImageData.assetInfoDTOList[i].customAlbums.splice(
          customeAlbumIndex,
          1,
        );
      }
    });

    this.selectedImagesStore.getItemsIds().forEach((imageId) => {
      const index = this.projectImageData.assetInfoDTOList
        .map((e) => e.id)
        .indexOf(imageId);

      if (index !== -1) {
        updatedSelectedImages.push(
          this.projectImageData.assetInfoDTOList[index],
        );
        if (isAdd) {
          this.projectImageData.assetInfoDTOList[index].customAlbums.push(id);
        } else {
          const customeAlbumIndex: number =
            this.projectImageData.assetInfoDTOList[index].customAlbums.indexOf(
              id,
            );
          if (customeAlbumIndex !== -1) {
            this.projectImageData.assetInfoDTOList[index].customAlbums.splice(
              customeAlbumIndex,
              1,
            );
          }
        }
      } else {
        updatedSelectedImages.push({
          id: imageId,
        });
      }
    });
  }

  renameAlbuminNode(renameAlbumData) {
    this.snackBarService.showSnackBar(
      this.translate.instant(
        'left-menu.tab-bar.sidemenus.albums.messageTitles.albumRenamed',
      ),
    );
    const treeModel: TreeModel = this.treeComponent.treeModel;
    const activateNode: TreeNode = treeModel.getActiveNode();
    activateNode.data.value = renameAlbumData.value;
    this.setAlbumInOunersNodeRename();
    this.treeComponent.treeModel.update();
    this.sidebarService.setUpdatedAlbumList(treeModel.nodes);
  }

  setAlbumInOunersNodeRename() {
    let inserIndex = -1;
    this.albumNode.forEach((obj, index) => {
      if (obj.ownerId === this.currentUser.id && obj.id === 0) {
        for (let i = index + 1; i < this.albumNode.length; i++) {
          if (this.albumNode[i].id === 0) {
            inserIndex = i;
            let copyData = this.albumNode.splice(1, inserIndex - 1);
            copyData = this.sortingData(copyData);
            copyData.forEach((objTest, indexTest) => {
              this.albumNode.splice(indexTest + 1, 0, objTest);
            });
            return;
          }
          if (this.albumNode.length - 1 === i) {
            inserIndex = i + 1;

            let copyData = this.albumNode.splice(1, inserIndex - 1);
            copyData = this.sortingData(copyData);
            copyData.forEach((objTest, indexTest) => {
              this.albumNode.splice(indexTest + 1, 0, objTest);
            });
            return;
          }
        }
      }
    });

    if (inserIndex === -1) {
      const ownerObj = {
        id: 0,
        ownerId: this.currentUser.id,
      };
      this.albumNode.splice(0, 0, ownerObj);
    }
  }

  updatePermissionsInNode(data) {
    this.snackBarService.showSnackBar('Permission updated');
    const treeModel: TreeModel = this.treeComponent.treeModel;
    const activateNode: TreeNode = treeModel.getActiveNode();
    activateNode.data.permission = data;
    this.treeComponent.treeModel.update();
  }

  // old code
  updateAlbumInNode1(album) {
    if (album.status) {
      const treeModel: TreeModel = this.treeComponent.treeModel;
      const activateNode: TreeNode = treeModel.getActiveNode();
      if (activateNode) {
        if (album.status) {
          activateNode.data.assetCount = album.message;
        }
        this.treeComponent.treeModel.update();
        this.sidebarService.setUpdatedAlbumList(treeModel.nodes);
      } else {
        this.treeComponent.treeModel
          .getNodeById(this.advanceImageToAlbumID)
          .setActiveAndVisible();
        const activateNode1: TreeNode = treeModel.getActiveNode();
        if (activateNode1) {
          activateNode1.data.assetCount = album.message;
          this.treeComponent.treeModel.update();
          this.sidebarService.setUpdatedAlbumList(treeModel.nodes);
          this.treeComponent.treeModel
            .getNodeById(this.advanceImageToAlbumID)
            .toggleActivated();
        }
      }
    } else {
      this.alertCustom(album.message);
    }
  }

  updateProjectAlbumInNodeKeys(album) {
    if (album.status) {
      this.projectAlbums.forEach((obj) => {
        obj.children.forEach((element) => {
          if (element.id === album.id) {
            element.assetCount = Number(album.message);
          }
        });
      });
    } else {
      this.alertCustom(album.message);
    }
  }

  updateAlbumInNodeKeys(album) {
    if (album.status) {
      this.treeComponent.treeModel.getNodeById(album.id).setActiveAndVisible();
      const treeModel: TreeModel = this.treeComponent.treeModel;
      const activateNode: TreeNode = treeModel.getActiveNode();
      if (activateNode) {
        if (album.status) {
          activateNode.data.assetCount = album.message;
        }
        this.treeComponent.treeModel.update();
        this.sidebarService.setUpdatedAlbumList(treeModel.nodes);
        this.treeComponent.treeModel.getNodeById(album.id).toggleActivated();
        if (this.activeAlbumFolder.id !== 0) {
          this.treeComponent.treeModel
            .getNodeById(this.activeAlbumFolder.id)
            .setActiveAndVisible();
        }
      }
    } else {
      this.alertCustom(album.message);
    }
  }

  updateAlbumInNode(album) {
    const treeModel: TreeModel = this.treeComponent.treeModel;
    const activateNode: TreeNode = treeModel.getActiveNode();

    if (activateNode) {
      this.treeComponent.treeModel.update();
      this.sidebarService.setUpdatedAlbumList(treeModel.nodes);
    }
  }

  getSelectedImagesOnDrop(evt) {
    setTimeout(() => {
      if (!this.isDragNodeTree) {
        this.copyDropedImages(evt.target.id);
      }
    }, 100);
  }

  copyDropedImages(id) {
    if (this.selectedImagesStore.hasEntities()) {
      this.isEnabaleToAddActiveAlbumFolder = true;
      this.treeComponent.treeModel
        .getNodeById(Number(id))
        .setActiveAndVisible();
      this.treeComponent.treeModel.update();
      const data = {
        assetIds: this.selectedImagesStore.getItemsIds(),
        projectId: this.viewProjectService.getProjectId(),
        id: id,
      };
      this.sidebarService
        .callAPIToTaggingAlbumData(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.status) {
            this.sidebarService.setUpdatedTaggingAlbum(res);
            this.updateAlbumInNode(res);
            this.updateCustomeAlbumsID(Number(id), res.t);
          } else {
            this.alertCustom(res.message);
          }
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    }
  }

  updateCustomeAlbumsID(id, isAdd) {
    const updatedSelectedImages = [];
    isAdd = isAdd.tag;
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );

    this.selectedImagesStore.getItemsIds().forEach((imageId) => {
      const index = this.projectImageData.assetInfoDTOList
        .map((e) => e.id)
        .indexOf(imageId);

      if (index !== -1) {
        updatedSelectedImages.push(
          this.projectImageData.assetInfoDTOList[index],
        );
        if (isAdd) {
          this.projectImageData.assetInfoDTOList[index].customAlbums.push(id);
        } else {
          const customeAlbumIndex: number =
            this.projectImageData.assetInfoDTOList[index].customAlbums.indexOf(
              id,
            );
          if (customeAlbumIndex !== -1) {
            this.projectImageData.assetInfoDTOList[index].customAlbums.splice(
              customeAlbumIndex,
              1,
            );
          }
        }
      } else {
        updatedSelectedImages.push({
          id: imageId,
        });
      }
    });

    this.selectedImagesStore.set(updatedSelectedImages);
  }

  resetSelectedAlbum() {
    this.sidebarService.setDeSelectedAlbum({});
    const temp = {
      id: 0,
    };
    this.activeAlbumFolder = temp;
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  onHoverOverAlbumName(album) {
    this.sidebarService.setHoverAlbum(album);
  }

  onHoverRemoveOverAlbumName() {
    this.sidebarService.removeHoverAlbum();
  }

  albumNameClicked(album) {
    this.sidebarService.setHoverAlbumClicked(album);
  }

  removeAlbumNameClicked() {
    this.sidebarService.removeHoverAlbumClicked();
  }

  // Link
  linkPage(node: any) {
    const imageCount = node.data.assetCount;
    const albumId = node.data.id;
    if (imageCount > 0) {
      this.albumService.getAlbumAssets(node.data.id, imageCount).subscribe(
        (assetInfo: number[]) => {
          if (assetInfo && assetInfo.length) {
            this.albumService
              .getAlbumSensitiveAndLinkExclusiveImages(
                assetInfo,
                imageCount,
                albumId,
              )
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                ([sensitiveAssets, exclusiveAssets]: [
                  AssetInfoDTOList[],
                  AssetInfoDTOList[],
                ]) => {
                  if (!sensitiveAssets.length && !exclusiveAssets.length) {
                    if (
                      this.userAuthority === UserRole.ROLE_EXTERNAL &&
                      this.projectAuthority === ProjectRole.ROLE_EXEC
                    ) {
                      if (this.checkDownloadStatus()) {
                        if (this.checkExclusiveStatus()) {
                          this.callLinkAPI(albumId);
                        } else {
                          this.loaderService.displayLoader(false);
                          this.alertCustom(
                            "You do not have permission to share image'(s).",
                          );
                        }
                      } else {
                        this.loaderService.displayLoader(false);
                        this.alertCustom(
                          "You do not have permission to share imae'(s).",
                        );
                      }
                    } else {
                      this.callLinkAPI(albumId);
                    }
                  } else {
                    this.loaderService.displayLoader(false);
                    this.alertCustom(
                      this.translate.instant(
                        'top-menu.linkTool.error.exclusiveOrSensitive',
                      ),
                    );
                  }
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom('No image(s) found!');
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
    } else {
      this.alertCustom('No image(s) found!');
    }
  }

  callLinkAPI = (id, filteredAssetIds?) => {
    this.loaderService.displayLoader(true);
    const dataObj = this.albumService.buildActionPayload(
      id,
      filteredAssetIds,
      this.constantsGlobalData.MODELNAMES.LINK,
      this.userAuthority === UserRole.ROLE_ADMIN
        ? this.userAuthority
        : this.projectAuthority,
    );

    this.topmenusService
      .postAssetsInfodata(dataObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            this.dialogService.open(LinkComponent, {
              panelClass: [
                'fs-dialog-panel',
                'fs-dialog-size-lg-panel',
                'fs-dialog-link-panel',
                'fs-dialog-auto-height-panel',
              ],
              listener: {
                withListener: true,
                id: 'LinkComponent',
              },
              data: res,
            });
          } else {
            this.alertCustom(res.message);
          }
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  };

  // Email
  sendMail(node: any) {
    const imageCount = node.data.assetCount;
    const albumId = node.data.id;
    if (imageCount > 0 && imageCount <= this.SELECTION_LIMIT) {
      this.albumService.getAlbumAssets(node.data.id, imageCount).subscribe(
        (assetInfo: number[]) => {
          if (assetInfo && assetInfo.length) {
            this.albumService
              .getSensitiveAlbumImages(assetInfo, imageCount, albumId)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (sensitiveAssets: AssetInfoDTOList[]) => {
                  if (!sensitiveAssets.length) {
                    if (
                      this.userAuthority === UserRole.ROLE_EXTERNAL &&
                      this.projectAuthority === ProjectRole.ROLE_EXEC
                    ) {
                      if (this.emailAccess) {
                        if (this.topmenusService.checkExclusiveImage()) {
                          if (this.checkExclusiveStatus()) {
                            this.callEmailAPI(albumId);
                          } else {
                            this.loaderService.displayLoader(false);
                            this.alertCustom(
                              'You do not have permission to send email.',
                            );
                          }
                        } else {
                          this.callEmailAPI(albumId);
                        }
                      } else {
                        this.loaderService.displayLoader(false);
                        this.alertCustom(
                          'You do not have permission to send email.',
                        );
                      }
                    } else {
                      this.callEmailAPI(albumId);
                    }
                  } else {
                    this.loaderService.displayLoader(false);
                    this.alertCustom(
                      'Sorry, you cannot email sensitive images.',
                    );
                  }
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom('Sorry, you cannot share sensitive images.');
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
    } else if (imageCount >= this.SELECTION_LIMIT + 1) {
      this.alertCustom(this.translate.instant('view-project.alert41'));
    } else {
      this.alertCustom('No image(s) found!');
    }
  }

  callEmailAPI = (id, filteredAssetIds?) => {
    this.loaderService.displayLoader(true);
    const dataObj = this.albumService.buildActionPayload(
      id,
      filteredAssetIds,
      'email',
      this.userAuthority === UserRole.ROLE_ADMIN
        ? this.userAuthority
        : this.projectAuthority,
    );

    this.topmenusService
      .postAssetsInfodata(dataObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            const obj = Utils.getAssetDetaillObj(
              res.message,
              res.t,
              this.viewProjectService.getProjectId(),
              this.projectDetailPermission,
              this.viewProjectService.getCurrentUsername(),
            );
            this.topmenusService
              .postAssetsDetail(obj)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  this.dialogService.open(SendmailComponent, {
                    panelClass: [
                      'fs-dialog-panel',
                      'fs-dialog-size-lg-panel',
                      'fs-dialog-link-panel',
                      'fs-dialog-auto-height-panel',
                    ],
                    data: resData,
                  });
                  this.loaderService.displayLoader(false);
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(res.message);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  };

  onOpenExpressDownloadModal(node: any) {
    const imageCount = node.data.assetCount;
    const albumId = node.data.id;
    if (imageCount > 0) {
      this.albumService
        .getAlbumAssets(node.data.id, imageCount)
        .subscribe((assetIds: number[]) => {
          if (assetIds && assetIds.length) {
            this.albumService
              .getSensitiveAlbumImages(assetIds, imageCount, albumId)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (sensitiveAssets: AssetInfoDTOList[]) => {
                  this.albumService
                    .getExclusiveAlbumImages(assetIds, imageCount, albumId)
                    .subscribe((exclusiveAssets: AssetInfoDTOList[]) => {
                      if (!sensitiveAssets.length && !exclusiveAssets.length) {
                        this.openExpressDownloadDialog(
                          albumId,
                          imageCount,
                          assetIds,
                        );
                      } else if (
                        this.albumService.isAllAlbumSelectedAssetsExclusiveOrSensitive(
                          sensitiveAssets,
                          exclusiveAssets,
                          assetIds,
                        )
                      ) {
                        this.loaderService.displayLoader(false);
                        this.alertCustom(
                          this.translate.instant(
                            'top-menu.expressDownload.error.allAssetAreExclusiveOrSensitive',
                          ),
                        );
                      } else {
                        this.loaderService.displayLoader(false);
                        this.albumService.openAlbumExclusiveSensitiveAssetModal(
                          albumId,
                          assetIds,
                          Utils.mergeAndRemoveDuplicatesByKey<AssetInfoDTOList>(
                            [...sensitiveAssets, ...exclusiveAssets],
                            'id',
                          ),
                          this.openExpressDownloadDialog,
                          imageCount,
                        );
                      }
                    },(error) => {
                      this.apiErrorHandlerService.getHandler().handle(error);
                      this.loaderService.displayLoader(false);
                    });
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom('No image(s) found!');
          }
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    } else {
      this.alertCustom('No image(s) found!');
    }
  }

  openExpressDownloadDialog = (
    albumId: number,
    imageCount: number,
    assetIds: number[],
  ): void => {
    if (this.minExpressDownload <= assetIds.length) {
      this.loaderService.displayLoader(true);
      this.albumService
        .getVideoAlbumAssetInfo(assetIds, imageCount, albumId)
        .subscribe(
          (videoAssets) => {
            this.loaderService.displayLoader(false);
            this.dialogService.getDialogState('ExpressDownloadComponent');
            this.dialogService.open(ExpressDownloadComponent, {
              panelClass: [
                'fs-dialog-panel',
                'fs-dialog-size-lg-panel',
                'fs-dialog-link-panel',
              ],
              listener: {
                withListener: true,
                id: 'ExpressDownloadComponent',
              },
              data: {
                albumId: albumId,
                stepIndex: 0,
                isFromTopMenu: false,
                assetIds: assetIds,
                videoAssets,
              },
            });
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    } else {
      this.alertCustom(
        this.translate.instant(
          'top-menu.expressDownload.error.minExpressDownloadNumber',
        ) + this.minExpressDownload,
      );
    }
  };

  openExclusiveSensitiveAssetModal(
    albumId: number,
    assetIds: number[],
    extractAssets: AssetInfoDTOList[],
    downloadFn,
  ) {
    this.dialogService.open(ExclusiveSensitiveAssetDialogComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
        'fs-dialog-link-panel',
      ],
      listener: {
        withListener: true,
        id: 'ExclusiveSensitiveAssetDialogComponent',
      },
      data: {
        openDownloadDialog: downloadFn,
        extractAssets: extractAssets,
        isFromTopMenu: false,
        assetIds: assetIds,
        albumId: albumId,
      },
    });
  }

  // Download
  download(node: any) {
    const imageCount = node.data.assetCount;
    const albumId = node.data.id;

    if (imageCount > 0 && imageCount <= this.SELECTION_LIMIT) {
      this.albumService.getAlbumAssets(node.data.id, imageCount).subscribe(
        (assetIds: number[]) => {
          if (assetIds && assetIds.length) {
            this.albumService
              .handleAlbumAssetDownloads(assetIds, imageCount, albumId)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                ([sensitiveAssets, exclusiveAssets]: [
                  AssetInfoDTOList[],
                  AssetInfoDTOList[],
                ]) => {
                  if (!sensitiveAssets.length && !exclusiveAssets.length) {
                    if (
                      this.userAuthority === UserRole.ROLE_EXTERNAL &&
                      this.projectAuthority === ProjectRole.ROLE_EXEC
                    ) {
                      if (this.checkDownloadStatus()) {
                        this.callDownloadAPI(albumId);
                      } else {
                        this.loaderService.displayLoader(false);
                        this.alertCustom(
                          "You do not have permission to download image'(s).",
                        );
                      }
                    } else {
                      this.callDownloadAPI(albumId);
                    }
                  } else if (
                    this.albumService.isAllAlbumSelectedAssetsExclusiveOrSensitive(
                      sensitiveAssets,
                      exclusiveAssets,
                      assetIds,
                    )
                  ) {
                    this.loaderService.displayLoader(false);
                    this.alertCustom(
                      this.translate.instant(
                        'top-menu.expressDownload.error.allAssetAreExclusiveOrSensitive',
                      ),
                    );
                  } else {
                    this.loaderService.displayLoader(false);
                    this.albumService.openAlbumExclusiveSensitiveAssetModal(
                      albumId,
                      assetIds,
                      Utils.mergeAndRemoveDuplicatesByKey<AssetInfoDTOList>(
                        [...sensitiveAssets, ...exclusiveAssets],
                        'id',
                      ),
                      this.callDownloadAPI,
                    );
                  }
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom('No image(s) found!');
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
    } else if (imageCount >= this.SELECTION_LIMIT + 1) {
      this.alertCustom(this.translate.instant('view-project.alert41'));
    } else {
      this.alertCustom('No image(s) found!');
    }
  }

  callDownloadAPI = (id, filteredAssetIds?) => {
    this.loaderService.displayLoader(true);
    const dataObj = this.albumService.buildActionPayload(
      id,
      filteredAssetIds,
      'download',
      this.userAuthority === UserRole.ROLE_ADMIN
        ? this.userAuthority
        : this.projectAuthority,
    );

    this.topmenusService
      .postAssetsInfodata(dataObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            const obj = Utils.getAssetDetaillObj(
              res.message,
              res.t,
              this.viewProjectService.getProjectId(),
              this.projectDetailPermission,
              this.viewProjectService.getCurrentUsername(),
            );
            this.topmenusService
              .postAssetsDetail(obj)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  if (
                    Object.keys(resData.assetDetail.Image).length === 0 &&
                    Object.keys(resData.assetDetail.Other).length === 0
                  ) {
                    this.alertCustom('No image size is available for download');
                  } else {
                    this.dialogService.open(DownloadComponent, {
                      panelClass: [
                        'fs-dialog-panel',
                        'fs-dialog-size-lg-panel',
                        'fs-dialog-link-panel',
                      ],
                      listener: {
                        withListener: true,
                        id: 'DownloadComponent',
                      },
                      data: resData,
                    });
                  }

                  this.loaderService.displayLoader(false);
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(res.message);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  };

  buildDownloadPayload(id: number, filteredAssetIds: number[]) {
    return filteredAssetIds && filteredAssetIds.length
      ? {
          assetIds: filteredAssetIds,
          filter: 'download',
          projectId: this.viewProjectService.getProjectId(),
          authorities: [this.userAuthority],
        }
      : {
          assetIds: [],
          albumId: id,
          filter: 'download',
          projectId: this.viewProjectService.getProjectId(),
          authorities: [this.userAuthority],
        };
  }

  // Print
  printTool(node: any) {
    const imageCount = node.data.assetCount;
    const albumId = node.data.id;
    if (imageCount > 0 && imageCount <= this.SELECTION_PRINT_LIMIT) {
      this.albumService.getAlbumAssets(node.data.id, imageCount).subscribe(
        (assetInfo: number[]) => {
          if (assetInfo && assetInfo.length) {
            this.albumService
              .getSensitiveAlbumImages(assetInfo, imageCount, albumId)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (sensitiveAssets: AssetInfoDTOList[]) => {
                  if (!sensitiveAssets.length) {
                    if (
                      this.userAuthority === UserRole.ROLE_EXTERNAL &&
                      this.projectAuthority === ProjectRole.ROLE_EXEC
                    ) {
                      if (this.printAccess) {
                        if (this.topmenusService.checkExclusiveImage()) {
                          if (this.checkExclusiveStatus()) {
                            this.callPrintAPI(albumId);
                          } else {
                            this.loaderService.displayLoader(false);
                            this.alertCustom(
                              'You do not have permission to print.',
                            );
                          }
                        } else {
                          this.callPrintAPI(albumId);
                        }
                      } else {
                        this.loaderService.displayLoader(false);
                        this.alertCustom(
                          'You do not have permission to print.',
                        );
                      }
                    } else {
                      this.callPrintAPI(albumId);
                    }
                  } else {
                    this.loaderService.displayLoader(false);
                    this.alertCustom(
                      'Sorry, you cannot print sensitive images.',
                    );
                  }
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom('No image(s) found!');
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
    } else if (imageCount >= this.SELECTION_PRINT_LIMIT + 1) {
      this.alertCustom(this.translate.instant('view-project.alert42'));
    } else {
      this.alertCustom(this.translate.instant('view-project.alert13'));
    }
  }

  callPrintAPI = (id, filteredAssetIds?) => {
    this.loaderService.displayLoader(true);
    const dataObj = this.albumService.buildActionPayload(
      id,
      filteredAssetIds,
      'print',
      this.userAuthority === UserRole.ROLE_ADMIN
        ? this.userAuthority
        : this.projectAuthority,
    );

    this.topmenusService
      .postAssetsInfodata(dataObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            this.dialogService.open(PrinttoolComponent, {
              panelClass: [
                'fs-dialog-panel',
                'fs-dialog-size-lg-panel',
                'fs-dialog-link-panel',
              ],
              data: res,
            });
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(res.message);
          }
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  };

  // Common
  checkExclusiveStatus() {
    if (this.exclusiveStatus === 0) {
      return false;
    } else if (this.exclusiveStatus === 1 || this.exclusiveStatus === 2) {
      return true;
    }
  }

  // Permission for right click dpwnload, email, link and print
  setAllPermission(data) {
    if (
      this.userAuthority === UserRole.ROLE_ADMIN ||
      this.projectAuthority === ProjectRole.ROLE_EXEC ||
      this.projectAuthority === ProjectRole.ROLE_ALBUMVIEWER
    ) {
      this.downloadType = data.permissionDTO.downloadType;
      this.downloadOnlyLA = data.permissionDTO.downloadOnlyLA;
      this.exclusiveStatus = data.permissionDTO.exclusiveStatus;
      this.emailAccess = data.permissionDTO.emailAccess;
      this.expressDownload = data.permissionDTO.expressDownload;
      this.printAccess = data.permissionDTO.printAccess;
      this.deleteAssetAccess = data.permissionDTO.deleteAssetAccess;
      this.adhocLinkAccess = data.permissionDTO.adhocLinkAccess;
      this.minExpressDownload = data.permissionDTO.minExpressDownload;
      this.hasWatermark = data.permissionDTO.watermark;
    }
  }

  resetAllPermission() {
    this.emailAccess = false;
    this.printAccess = false;
    this.deleteAssetAccess = false;
    this.adhocLinkAccess = false;
  }

  checkDownloadStatus() {
    if (this.downloadType) {
      return true;
    } else if (this.downloadOnlyLA) {
      let lockApproved = true;
      this.selectedImagesStore.getAll().forEach((item) => {
        if (item.status !== 5) {
          lockApproved = false;
          return lockApproved;
        }
      });
      return lockApproved;
    }
  }

  getExclusiveImages(
    assetIds: number[],
    imageCount: number,
    albumId: number,
  ): Observable<AssetInfoDTOList[]> {
    const currentFilter = 'album:' + albumId;
    const data = {
      assetIds: Utils.getImageIdsBasedOnPage(1, imageCount, assetIds),
      projectRoleId: this.viewProjectService.getProjectRole(),
      projectId: this.viewProjectService.getProjectId(),
      filter: currentFilter,
    };

    this.loaderService.displayLoader(true);
    return this.topmenuService
      .getExclusiveAssets(data)
      .pipe(takeUntil(this.destroy$));
  }

  private isAllSelectedAssetsExclusiveOrSensitive(
    sensitiveAssets,
    exclusiveAssets,
    assetIds: number[],
  ): boolean {
    return (
      Utils.mergeAndRemoveDuplicatesByKey<AssetInfoDTOList>(
        [...sensitiveAssets, ...exclusiveAssets],
        'id',
      ).length === assetIds.length
    );
  }

  private handleAssetDownloads(
    assetIds: number[],
    imageCount: number,
    albumId: number,
  ) {
    const sensitiveAssets$ = this.albumService.getSensitiveAlbumImages(
      assetIds,
      imageCount,
      albumId,
    );
    const exclusiveAssets$ = this.getExclusiveImages(
      assetIds,
      imageCount,
      albumId,
    );

    return forkJoin([sensitiveAssets$, exclusiveAssets$]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
