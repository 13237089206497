import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnInit,
} from '@angular/core';
import { ExpandableListTemplateDirective } from '../../directive/expandable-list-template.directive';

@Component({
  selector: 'fs-expandable-list',
  templateUrl: './expandable-list.component.html',
  styleUrls: ['./expandable-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandableListComponent implements OnInit {
  @ContentChild(ExpandableListTemplateDirective)
  customTemplate: ExpandableListTemplateDirective;
  @Input() dataList: any[];
  @Input() initialNumberOfItemsToShow: number;
  slicedArray: any[];

  ngOnInit() {
    this.slicedArray = this.dataList.slice(0, this.initialNumberOfItemsToShow);
  }

  viewMore(fullLength: number): void {
    this.slicedArray = this.dataList.slice(0, fullLength);
  }

  viewLess(): void {
    this.slicedArray = this.dataList.slice(0, this.initialNumberOfItemsToShow);
  }
}
