<div class="fs-image-info-toggle-container d-flex">
  <div class="note-sec active text-uppercase">
    <span
      *ngIf="!hideDataNoteListOption"
      class="fs-txt-white-dark-color fs-cursor-pointer mt-1"
    >
      {{ 'left-menu.tab-bar.sidemenus.imageInfo.tab.note.title' | translate }}
    </span>
  </div>
  <button
    type="button"
    *ngIf="hideDataNoteListOption"
    class="fs-close-btn w-auto"
    (click)="closeWindow({ close: false, noteSave: false })"
  >
    <i class="fa fa-times-circle"></i>
  </button>
</div>
<div
  class="list-unstyled components sideBar-menu leftsideMenuBarImageInfo"
  id="leftsideMenuBar"
>
  <div class="right-content open">
    <div #scrollMe class="notescroll">
      <div
        class="sngl-data-note data-sec-dtl"
        [ngClass]="{ imageinfodis: imageInfoTab == 'data' }"
      >
        <div class="multipleimg" *ngIf="selectedImagesStore.size() > 1">
          {{
            'left-menu.tab-bar.sidemenus.imageInfo.labels.multipleImageLabel'
              | translate
          }}
        </div>
        <div class="multipleimg" *ngIf="selectedImagesStore.size() === 0">
          {{
            'left-menu.tab-bar.sidemenus.imageInfo.labels.noImageLabel'
              | translate
          }}
        </div>
        <div *ngIf="selectedImagesStore.size() === 1">
          <div class="clearfix">
            <dt>
              {{
                'left-menu.tab-bar.sidemenus.imageInfo.tab.data.released'
                  | translate
              }}:
            </dt>

            <dd>
              {{ imgInfo.releasedDate | date : 'yyyy-MM-dd' }} &#64;
              {{ imgInfo.releasedDate | date : 'hh:mm:ss' }}
            </dd>
          </div>
          <div class="clearfix">
            <dt>
              {{
                'left-menu.tab-bar.sidemenus.imageInfo.tab.data.batch'
                  | translate
              }}:
            </dt>
            <dd>{{ imgInfo.batch }}</dd>
          </div>
          <div class="clearfix">
            <dt>
              {{
                'left-menu.tab-bar.sidemenus.imageInfo.tab.data.image'
                  | translate
              }}:
            </dt>
            <dd>{{ imgInfo.name }}</dd>
          </div>

          <div class="clearfix">
            <dt>
              {{
                'left-menu.tab-bar.sidemenus.imageInfo.tab.data.imageStatus'
                  | translate
              }}:
            </dt>
            <dd></dd>
          </div>

          <div class="clearfix">
            <dt>
              {{
                'left-menu.tab-bar.sidemenus.imageInfo.tab.data.taggedWith'
                  | translate
              }}:
            </dt>
            <dd>
              {{ imgInfo.publishedTags | json }}
              <span *ngFor="let publishedBy of imgInfo.publishedBy">
                {{ publishedBy }}
              </span>
            </dd>
          </div>

          <div class="clearfix">
            <dt>
              {{
                'left-menu.tab-bar.sidemenus.imageInfo.tab.data.approvedBy'
                  | translate
              }}:
            </dt>
            <dd>
              <span *ngFor="let approveBy of imgInfo.approvedBy">
                {{ approveBy }}
              </span>
            </dd>
          </div>

          <div class="clearfix">
            <dt>
              {{
                'left-menu.tab-bar.sidemenus.imageInfo.tab.data.rejectedBy'
                  | translate
              }}:
            </dt>
            <dd>
              <span *ngFor="let rejectedBy of imgInfo.rejectedBy">
                {{ rejectedBy }}
              </span>
            </dd>
          </div>
          <div class="clearfix">
            <dt>Albums:</dt>
            <dd>
              <span *ngFor="let customAlbum of imgInfo.customAlbums">
                {{ customAlbum }}
              </span>
            </dd>
          </div>

          <div class="clearfix">
            <dt>
              {{
                'left-menu.tab-bar.sidemenus.imageInfo.tab.data.caption'
                  | translate
              }}:
            </dt>
            <dd>
              <span *ngIf="imgInfo.caption">{{ imgInfo.caption }}</span>
            </dd>
          </div>
          <div class="clearfix">
            <dt>
              {{
                'left-menu.tab-bar.sidemenus.imageInfo.tab.data.photographer'
                  | translate
              }}:
            </dt>
            <dd>{{ imgInfo.photographer }}</dd>
          </div>
        </div>
      </div>
      <!-- image infodis -->

      <div class="sngl-data-note note-sec-dtl image-notes-ids">
        <div class="multipleimg" *ngIf="selectedImagesStore.size() > 1">
          {{
            'left-menu.tab-bar.sidemenus.imageInfo.labels.multipleImageLabel'
              | translate
          }}
        </div>
        <div class="multipleimg" *ngIf="selectedImagesStore.size() === 0">
          {{
            'left-menu.tab-bar.sidemenus.imageInfo.labels.noImageLabel'
              | translate
          }}
        </div>
        <div class="note-msg-type" *ngIf="selectedImagesStore.size() === 1">
          <form #formName="ngForm">
            <textarea
              rows="2"
              name="imageNoteText"
              [preventSpecialChars]="['|']"
              [(ngModel)]="submitNoteData.imageNoteText"
            ></textarea>
            <div
              class="nmt-btm d-flex justify-content-between nmt-btm mt-2 clearfix mr-0 ml-0"
            >
              <div class="mf-sngle mr-0 ml-0">
                <mat-radio-group
                  formControlName="noteType"
                  class="d-flex flex-row"
                  [(ngModel)]="submitNoteData.noteType"
                >
                  <mat-radio-button
                    class="mr-2 fs-radio-button"
                    *ngFor="let ntType of noteData.note; index as ind"
                    [value]="ntType.id"
                  >
                    <span class="fs-sm-txt cb-title">
                      {{
                        'left-menu.tab-bar.sidemenus.imageInfo.tab.note.radioBtn' +
                          '.' +
                          ntType.key | translate
                      }}
                    </span>
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="right-save-btn d-flex ml-2">
                <button type="submit" (click)="saveImageNote(formName.value)">
                  {{
                    'left-menu.tab-bar.sidemenus.imageInfo.tab.note.saveNote'
                      | translate
                  }}
                </button>
              </div>
            </div>
          </form>
          <div
            class="messages-sec"
            *ngIf="
              selectedImagesStore.hasEntities() && !hideDataNoteListOption
            "
          >
            <div
              class="single-message"
              *ngFor="
                let savedNote of this.selectedImagesStore.getHeadItem()
                  .assetNoteDTOList
              "
            >
              <div class="fs-msg-wrapper">
                <div class="sm-msg">
                  {{ savedNote.value }}
                </div>
                <div class="sm-btm clearfix">
                  <span class="user-name pull-left">{{
                    savedNote.name
                  }}</span>
                  <span class="created-datetime pull-right"
                    >{{ savedNote.createdAt | date : 'yyyy-MM-dd' }} &#64;
                    {{ savedNote.createdAt | date : 'hh:mm:ss' }}
                    <i
                      *ngIf="
                        savedNote.type == 1 || savedNote.type == 'Private'
                      "
                      class="fa fa-lg fa-eye-slash"
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
