import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteConfig } from '../shared/config/routeconfig';

const dashboardRoutes: Routes = RouteConfig;

@NgModule({
  imports: [RouterModule.forChild(dashboardRoutes)],
  exports: [RouterModule],
})
export class DashboardRouting {}
