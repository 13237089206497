<div #scrolling>
  <div #horizontalscroll id="easyPaginate">
    <div
      #scrollportion
      cdk-scrollable
      class="image-gallery pb-0"
      id="imgList"
      [ngClass]="{ 'full-width': isImageAuto }"
    >
      <div *ngIf="projectImageData?.assetInfoDTOList">
        <div
          [ngStyle]="{ 'grid-template-columns': columnsNumber }"
          #galleryList
          class="gallery-list"
          [ngClass]="{
            'small-img': projectDetailPermission.viewSettingDTO.size == '3',
            'large-img': projectDetailPermission.viewSettingDTO.size == '1',
            'medium-img': projectDetailPermission.viewSettingDTO.size == '2',
            align_auto: isImageAuto
          }"
        >
          <div
            *ngFor="
              let imgData of projectImageData.assetInfoDTOList
                | paginate
                  : {
                      itemsPerPage: noOfImages,
                      currentPage: currentPageNumber,
                      id: 'ViewProject',
                      totalItems: projectImageIds.length
                    };
              index as ind
            "
            class="imgItem"
            [id]="'parent-' + imgData.id.toString()"
          >
            <div class="imgItem-inner" [ngClass]="imageCornerColor(imgData)">
              <!-- ADMIN VIEW -->
              <div
                class="imgItem-inner-child"
                [ngClass]="imageCornerColor(imgData)"
                *ngIf="userAuthority === userRole.ROLE_ADMIN"
                (contextmenu)="onRightClick()"
              >
                <div
                  class="admin-user-wrapper"
                  *ngIf="
                    selectedProjectValue !== 3 &&
                    selectedProjectValue !== 4 &&
                    !isExecutiveAlbumView
                  "
                >
                  <!--Image container-->
                  <div #sectionToDrag class="imge">
                    <ng-container
                      *ngIf="imgData.assetType === assetType.IMAGE"
                      [ngTemplateOutlet]="imagePanel"
                      [ngTemplateOutletContext]="{
                        imgData: imgData,
                        ind: ind,
                        rejectedXCondition:
                          imgData.rejectedBy.length > 0 && imageLoaded[ind],
                        withExclusive: true,
                        withIcons: true,
                        withRejectIcon: true,
                        isSignOffWideEditView: true,
                        withNoteBorder: false
                      }"
                    >
                    </ng-container>
                    <ng-container
                      *ngIf="imgData.assetType !== assetType.IMAGE"
                      [ngTemplateOutlet]="videoAndDocsPanel"
                      [ngTemplateOutletContext]="{
                        imgData: imgData,
                        ind: ind,
                        withRejectIcon: true,
                        rejectedXCondition:
                          imgData.rejectedBy.length > 0 && imageLoaded[ind],
                        withExclusive: true
                      }"
                    >
                    </ng-container>
                    <fs-image-name-panel
                      [imgData]="imgData"
                    ></fs-image-name-panel>
                    <ng-container
                      [ngTemplateOutlet]="colorTagPanel"
                      [ngTemplateOutletContext]="{ imgData: imgData }"
                    >
                    </ng-container>
                  </div>
                  <!--Action menu-->
                  <ng-container
                    [ngTemplateOutlet]="adminActionPanel"
                    [ngTemplateOutletContext]="{ imgData: imgData, ind: ind }"
                  >
                  </ng-container>
                </div>
              </div>

              <!-- ADMIN VIEW  With Sign Off-->
              <div *ngIf="userAuthority === userRole.ROLE_ADMIN">
                <ng-container
                  [ngTemplateOutlet]="signOff"
                  [ngTemplateOutletContext]="{ imgData: imgData, ind: ind }"
                >
                </ng-container>
              </div>

              <!-- ADMIN VIEW Wide Edit-->
              <div
                class="telent_wpr imgItem-inner-child"
                *ngIf="userAuthority === userRole.ROLE_ADMIN"
                [ngClass]="{ hideportion: selectedProjectValue != 4 }"
                (contextmenu)="onRightClick()"
              >
                <div class="act-desc" *ngIf="selectedProjectValue == 4">
                  <!--Image container-->
                  <div #sectionToDrag class="imge">
                    <ng-container
                      *ngIf="imgData.assetType === assetType.IMAGE"
                      [ngTemplateOutlet]="imagePanel"
                      [ngTemplateOutletContext]="{
                        imgData: imgData,
                        ind: ind,
                        withExclusive: false,
                        withIcons: false,
                        withRejectIcon: false,
                        isSignOffWideEditView: true,
                        withNoteBorder: true
                      }"
                    >
                    </ng-container>
                    <ng-container
                      *ngIf="imgData.assetType !== assetType.IMAGE"
                      [ngTemplateOutlet]="videoAndDocsPanel"
                      [ngTemplateOutletContext]="{
                        imgData: imgData,
                        ind: ind,
                        withRejectIcon: false,
                        withExclusive: false
                      }"
                    >
                    </ng-container>
                    <fs-image-name-panel
                      [imgData]="imgData"
                    ></fs-image-name-panel>
                  </div>
                  <!--Action menu-->
                  <div
                    class="act-desc imgex-links-parent d-flex flex-column justify-content-end"
                  >
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">
                        <!-- SPECIAL PROJECT TOOLS -->
                        <ng-container
                          [ngTemplateOutlet]="exicutiveAlbumTag"
                          [ngTemplateOutletContext]="{
                            imgData: imgData,
                            isUserApprovedImage: true
                          }"
                        >
                        </ng-container>
                      </div>
                      <div class="d-flex">
                        <ng-container
                          [ngTemplateOutlet]="likeIcon"
                          [ngTemplateOutletContext]="{ imgData: imgData }"
                        >
                        </ng-container>
                        <ng-container
                          [ngTemplateOutlet]="editIcons"
                          [ngTemplateOutletContext]="{ imgData: imgData }"
                        >
                        </ng-container>
                        <div
                          *ngIf="
                            retouchAccess &&
                            imgData.assetType == assetType.IMAGE &&
                            projectDetailPermission.permissionDTO.retouchAccess
                          "
                        >
                          <fs-retouch-icon-component
                            [imgData]="imgData"
                            [isTaggerRole]="isTaggerRole"
                            [assetType]="assetType"
                            (drawImage)="drawImage($event)"
                            [projectDetailPermission]="projectDetailPermission"
                            [retouchAccess]="retouchAccess"
                          >
                          </fs-retouch-icon-component>
                        </div>
                        <ng-container
                          [ngTemplateOutlet]="viewProjectNotes"
                          [ngTemplateOutletContext]="{
                            imgData: imgData,
                            ind: ind
                          }"
                        >
                        </ng-container>
                        <div *ngIf="imgData.assetType == assetType.IMAGE">
                          <ng-container
                            [ngTemplateOutlet]="iconZoom"
                            [ngTemplateOutletContext]="{ imgData: imgData }"
                          >
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive user_action_list_cont">
                      <table class="table user_action_list">
                        <tbody class="table-color">
                          <tr
                            class="row"
                            *ngFor="
                              let executiveWideEditDTOList of imgData.executiveWideEditDTOList;
                              let idx = index
                            "
                          >
                            <td class="col-6 py-0">
                              <span
                                [class.fs-text-color-red]="
                                  executiveWideEditDTOList.actionTypeId === 3
                                "
                                [class.fs-text-color-green]="
                                  executiveWideEditDTOList.actionTypeId === 1
                                "
                              >
                                {{
                                  getFullName(executiveWideEditDTOList.userId)
                                }}
                              </span>
                            </td>
                            <div class="col-6 px-0">
                              <td class="col-4 px-1 py-0">
                                <span *ngIf="executiveWideEditDTOList.like">
                                  Like it!
                                </span>
                              </td>
                              <td class="col-4 px-1 py-0">
                                {{ executiveWideEditDTOList.available }}
                              </td>
                              <td class="col-4 px-1 py-0">
                                {{ executiveWideEditDTOList.gs }}
                              </td>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <!-- EXEC View -->
              <div
                class="imgItem-inner-child"
                [ngClass]="imageCornerColor(imgData)"
                *ngIf="
                  userAuthority === userRole.ROLE_EXTERNAL &&
                  projectAuthority === projectRole.ROLE_EXEC
                "
                (contextmenu)="onRightClick()"
              >
                <div
                  class="admin-user-wrapper"
                  *ngIf="
                    selectedProjectValue !== 3 &&
                    selectedProjectValue !== 4 &&
                    !isExecutiveAlbumView
                  "
                >
                  <!--Image container-->
                  <div #sectionToDrag class="imge">
                    <ng-container
                      *ngIf="imgData.assetType === assetType.IMAGE"
                      [ngTemplateOutlet]="imagePanel"
                      [ngTemplateOutletContext]="{
                        imgData: imgData,
                        ind: ind,
                        rejectedXCondition:
                          imgData.rejectedBy.length > 0 && imageLoaded[ind],
                        withExclusive: true,
                        withIcons: true,
                        withRejectIcon: true,
                        isSignOffWideEditView: false,
                        withNoteBorder: false
                      }"
                    >
                    </ng-container>
                    <ng-container
                      *ngIf="imgData.assetType !== assetType.IMAGE"
                      [ngTemplateOutlet]="videoAndDocsPanel"
                      [ngTemplateOutletContext]="{
                        imgData: imgData,
                        ind: ind,
                        withRejectIcon: true,
                        rejectedXCondition:
                          imgData.rejectedBy.length > 0 && imageLoaded[ind],
                        withExclusive: true
                      }"
                    >
                    </ng-container>
                    <fs-image-name-panel
                      [imgData]="imgData"
                    ></fs-image-name-panel>
                    <ng-container
                      [ngTemplateOutlet]="colorTagPanel"
                      [ngTemplateOutletContext]="{ imgData: imgData }"
                    >
                    </ng-container>
                  </div>
                  <!--Action menu-->
                  <div
                    class="act-desc d-flex justify-content-between align-items-center imgex-links-parent"
                  >
                    <div class="customAlbum">
                      <span class="fa-stack fa-3x">
                        <span *ngIf="imgData.customAlbums.length > 0">
                          <a
                            href="javascript:void(0);"
                            data
                            (click)="customAlbumView(imgData)"
                          >
                            <i
                              class="fa fa-folder fa-stack-2x customFolderCount"
                            ></i>
                            <span class="fa fa-stack-1x customFolderCount">
                              <span>
                                {{ imgData.customAlbums.length }}
                              </span>
                            </span>
                          </a>
                        </span>
                      </span>
                    </div>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <ng-container
                        [ngTemplateOutlet]="viewProjectIcon"
                        *ngIf="
                          priorityPixAccess &&
                          imgData.priorityPix &&
                          imgData.assetType == assetType.IMAGE
                        "
                      >
                      </ng-container>
                      <div>
                        <a
                          href="javascript:void(0);"
                          title="{{ 'view-project.solo' | translate }}"
                          *ngIf="
                            imgData.publishedTags.length > 1 &&
                            imgData.assetType == assetType.IMAGE
                          "
                          data-toggle="modal"
                          data-target="#imagesg"
                          (click)="
                            clickOnGroupImages({
                              imgData: imgData,
                              event: $event
                            })
                          "
                        >
                          <fs-char-icon-component
                            [isCharIcon]="false"
                            [isChar]="true"
                            [isHighlighted]="true"
                            [imgData]="imgData"
                          >
                          </fs-char-icon-component>
                          <fs-char-icon-with-border [imgData]="imgData">
                          </fs-char-icon-with-border>
                        </a>
                      </div>

                      <div
                        *ngIf="
                          imgData.publishedTags.length == 1 &&
                          imgData.assetType == assetType.IMAGE
                        "
                      >
                        <fs-view-project-solo-icon-component
                          [imgData]="imgData"
                          [isTaggerRole]="isTaggerRole"
                          (clickOnGroupImages)="clickOnGroupImages($event)"
                        >
                        </fs-view-project-solo-icon-component>
                        <fs-view-project-solo-icon-with-border-component
                          [imgData]="imgData"
                          [isTaggerRole]="isTaggerRole"
                          [assetType]="assetType"
                          (clickOnGroupImages)="clickOnGroupImages($event)"
                        >
                        </fs-view-project-solo-icon-with-border-component>
                      </div>
                      <!-- Only Visible for Admin -->
                      <div
                        *ngIf="
                          projectDetailPermission.permissionDTO
                            .exclusiveAccess &&
                          imgData.exclusiveHistory &&
                          imgData.assetType == assetType.IMAGE
                        "
                      >
                        <a
                          class="fs-cursor-pointer"
                          title="{{ 'view-project.exclusive' | translate }}"
                          (click)="
                            openImageHistory({
                              imageData: imgData,
                              historyReportType: 'exclusive'
                            })
                          "
                        >
                          <span class="char">
                            <img src="/assets/icons/e.png" />
                          </span>
                        </a>
                      </div>
                      <div
                        *ngIf="
                          retouchAccess &&
                          imgData.assetType == assetType.IMAGE &&
                          projectDetailPermission.permissionDTO.retouchAccess
                        "
                      >
                        <fs-retouch-icon-component
                          [imgData]="imgData"
                          [isTaggerRole]="isTaggerRole"
                          [assetType]="assetType"
                          (drawImage)="drawImage($event)"
                          [projectDetailPermission]="projectDetailPermission"
                          [retouchAccess]="retouchAccess"
                        >
                        </fs-retouch-icon-component>
                      </div>
                      <ng-container
                        [ngTemplateOutlet]="viewProjectNotes"
                        [ngTemplateOutletContext]="{
                          imgData: imgData,
                          ind: ind
                        }"
                      >
                      </ng-container>
                      <div class="d-flex align-items-center ml-2">
                        <a
                          style="padding: 1px"
                          href="javascript:void(0);"
                          *ngIf="captionAccess"
                          class="btn fs-char-icon"
                          title="{{ 'view-project.captions' | translate }}"
                          data-toggle="modal"
                          data-target="#imagecap"
                          (click)="captionView(imgData)"
                        >
                          <img
                            src="/assets/images/icon-chat_previous.png"
                            [class.highlighted-icon]="imgData.caption"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="ml-2 mr-2">
                      <div *ngIf="imgData.assetType == assetType.IMAGE">
                        <ng-container
                          [ngTemplateOutlet]="iconZoom"
                          [ngTemplateOutletContext]="{ imgData: imgData }"
                        >
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="w-100">
                    <!--Publishing panel-->
                    <fs-publishing-panel-component
                      [imgData]="imgData"
                      [isTaggingView1Active]="isTaggingView1Active"
                      [isTaggingView2Active]="isTaggingView2Active"
                      [isByPass2ndTaggerActive]="isByPass2ndTaggerActive"
                      [isConflictsActive]="isConflictsActive"
                      [isNoConflictsActive]="isNoConflictsActive"
                    >
                    </fs-publishing-panel-component>
                    <!--Tagger panel-->
                    <fs-tagger-panel-component
                      [imgData]="imgData"
                      [isTaggingView1Active]="isTaggingView1Active"
                      [isTaggingView2Active]="isTaggingView2Active"
                      [isConflictsActive]="isConflictsActive"
                      [isNoConflictsActive]="isNoConflictsActive"
                      [isByPass2ndTaggerActive]="isByPass2ndTaggerActive"
                      [simpleTagger]="true"
                    >
                    </fs-tagger-panel-component>
                  </div>
                </div>
              </div>

              <!-- EXEC VIEW  With Sign Off-->
              <div
                *ngIf="
                  userAuthority === userRole.ROLE_EXTERNAL &&
                  projectAuthority === projectRole.ROLE_EXEC
                "
              >
                <ng-container
                  [ngTemplateOutlet]="signOff"
                  [ngTemplateOutletContext]="{ imgData: imgData, ind: ind }"
                >
                </ng-container>
              </div>

              <!-- EXEC VIEW Wide Edit-->
              <div
                class="telent_wpr imgItem-inner-child"
                *ngIf="
                  userAuthority === userRole.ROLE_EXTERNAL &&
                  projectAuthority === projectRole.ROLE_EXEC
                "
                [ngClass]="{ hideportion: selectedProjectValue != 4 }"
                (contextmenu)="onRightClick()"
              >
                <div class="act-desc" *ngIf="selectedProjectValue == 4">
                  <!--Image container-->
                  <div #sectionToDrag class="imge">
                    <ng-container
                      *ngIf="imgData.assetType === assetType.IMAGE"
                      [ngTemplateOutlet]="imagePanel"
                      [ngTemplateOutletContext]="{
                        imgData: imgData,
                        ind: ind,
                        withExclusive: false,
                        withIcons: false,
                        withRejectIcon: false,
                        isSignOffWideEditView: true,
                        withNoteBorder: true
                      }"
                    >
                    </ng-container>
                    <ng-container
                      *ngIf="imgData.assetType !== assetType.IMAGE"
                      [ngTemplateOutlet]="videoAndDocsPanel"
                      [ngTemplateOutletContext]="{
                        imgData: imgData,
                        ind: ind,
                        withRejectIcon: false,
                        withExclusive: false
                      }"
                    >
                    </ng-container>
                    <fs-image-name-panel
                      [imgData]="imgData"
                    ></fs-image-name-panel>
                  </div>
                  <!--Action menu-->
                  <div class="act-desc imgex-links-parent">
                    <div
                      class="imgex-links d-flex justify-content-end flex-column"
                    >
                      <div class="d-flex justify-content-between">
                        <div>
                          <!-- SPECIAL PROJECT TOOLS -->
                          <ng-container
                            [ngTemplateOutlet]="exicutiveAlbumTag"
                            [ngTemplateOutletContext]="{
                              imgData: imgData,
                              isUserApprovedImage: true
                            }"
                          >
                          </ng-container>
                        </div>
                        <div class="d-flex">
                          <ng-container
                            [ngTemplateOutlet]="likeIcon"
                            [ngTemplateOutletContext]="{ imgData: imgData }"
                          >
                          </ng-container>
                          <ng-container
                            [ngTemplateOutlet]="editIcons"
                            [ngTemplateOutletContext]="{ imgData: imgData }"
                          >
                          </ng-container>
                          <div
                            *ngIf="
                              retouchAccess &&
                              imgData.assetType == assetType.IMAGE &&
                              projectDetailPermission.permissionDTO
                                .retouchAccess
                            "
                          >
                            <fs-retouch-icon-component
                              [imgData]="imgData"
                              [isTaggerRole]="isTaggerRole"
                              [assetType]="assetType"
                              (drawImage)="drawImage($event)"
                              [projectDetailPermission]="
                                projectDetailPermission
                              "
                              [retouchAccess]="retouchAccess"
                            >
                            </fs-retouch-icon-component>
                          </div>
                          <ng-container
                            [ngTemplateOutlet]="viewProjectNotes"
                            [ngTemplateOutletContext]="{
                              imgData: imgData,
                              ind: ind
                            }"
                          >
                          </ng-container>
                        </div>
                        <div *ngIf="imgData.assetType == assetType.IMAGE">
                          <ng-container
                            [ngTemplateOutlet]="iconZoom"
                            [ngTemplateOutletContext]="{ imgData: imgData }"
                          >
                          </ng-container>
                        </div>
                      </div>
                      <div class="table-responsive user_action_list_cont">
                        <table class="user_action_list">
                          <tbody class="table-color">
                            <tr
                              class="row"
                              *ngFor="
                                let executiveWideEditDTOList of imgData.executiveWideEditDTOList;
                                let idx = index
                              "
                            >
                              <td class="col-6 py-0">
                                <span
                                  [class.fs-text-color-red]="
                                    executiveWideEditDTOList.actionTypeId === 3
                                  "
                                  [class.fs-text-color-green]="
                                    executiveWideEditDTOList.actionTypeId === 1
                                  "
                                >
                                  {{
                                    getFullName(executiveWideEditDTOList.userId)
                                  }}
                                </span>
                              </td>
                              <span class="d-block col-6 px-0 m-0">
                                <td class="col-4 px-1 py-0">
                                  <span *ngIf="executiveWideEditDTOList.like">
                                    Like it!
                                  </span>
                                </td>
                                <td class="col-4 px-1 py-0">
                                  {{ executiveWideEditDTOList.available }}
                                </td>
                                <td class="col-4 px-1 py-0">
                                  {{ executiveWideEditDTOList.gs }}
                                </td>
                              </span>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- TALENT VIEW -->
              <div
                class="telent_wpr imgItem-inner-child"
                [ngClass]="imageCornerColor(imgData)"
                *ngIf="
                  userAuthority === userRole.ROLE_EXTERNAL &&
                  projectAuthority === projectRole.ROLE_TALENT
                "
                (contextmenu)="onRightClick()"
              >
                <div>
                  <!--Image container-->
                  <div #sectionToDrag class="imge">
                    <ng-container
                      *ngIf="imgData.assetType === assetType.IMAGE"
                      [ngTemplateOutlet]="imagePanel"
                      [ngTemplateOutletContext]="{
                        imgData: imgData,
                        ind: ind,
                        withExclusive: false,
                        rejectedXCondition:
                          imgData.rejected && imageLoaded[ind],
                        withIcons: false,
                        withRejectIcon: true,
                        isSignOffWideEditView: false,
                        withNoteBorder: false
                      }"
                    >
                    </ng-container>
                    <fs-image-video-panel-component
                      *ngIf="imgData.assetType === assetType.VIDEO"
                      (dragImages)="dragImages($event)"
                      (imageProtectDragStart)="imageProtectDragStart($event)"
                      (selectImageOnClick)="selectImageOnClick($event)"
                      (selectImageOnClickUP)="selectImageOnClickUP($event)"
                      (onClickVideoIcon)="onClickVideoIcon($event)"
                      [lastSelectedImage2]="lastSelectedImage2"
                      [imgData]="imgData"
                      [withRejectIcon]="true"
                      [withExclusive]="false"
                      [assetSize]="projectDetailPermission.viewSettingDTO.size"
                      [rejectedXCondition]="
                        imgData.rejected && imageLoaded[ind]
                      "
                      [imageLoaded]="imageLoaded"
                      [ind]="ind"
                      [projectImageData]="projectImageData"
                      [imageDim]="imageDim"
                      [selectedImages2]="selectedImagesStore.getAll()"
                      [blockedHover]="blockedHover"
                      [onLoad]="onLoad"
                    >
                    </fs-image-video-panel-component>
                    <fs-image-name-panel
                      [imgData]="imgData"
                    ></fs-image-name-panel>
                  </div>
                  <!--Action menu-->
                  <div
                    class="act-desc imgex-links-parent telent_link_parent px-3"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <!-- TALENT REJECTED  -->
                      <div class="mr-1">
                        <span
                          *ngIf="
                            (selectedProjectValue !== 3 ||
                              selectedProjectValue !== 4) &&
                            imgData.status === 1
                          "
                          (click)="onClickTelentApproveReject(imgData)"
                        >
                          <i
                            *ngIf="imgData.rejected"
                            class="fa fa-check-circle fs-approved-rejected-icon-size fs-cursor-pointer fs-text-color-green"
                            aria-hidden="true"
                          ></i>
                          <i
                            *ngIf="!imgData.rejected"
                            class="fa fa-times-circle fs-approved-rejected-icon-size fs-cursor-pointer fs-text-color-red"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                      <div class="d-flex justify-content-between">
                        <ng-container
                          [ngTemplateOutlet]="viewProjectIcon"
                          *ngIf="
                            priorityPixAccess &&
                            imgData.priorityPix &&
                            imgData.assetType == assetType.IMAGE
                          "
                        >
                        </ng-container>
                        <div class="mr-1">
                          <span
                            href="javascript:void(0);"
                            [title]="'view-project.solo' | translate"
                            data-toggle="modal"
                            data-target="#imagesg"
                            (click)="
                              clickOnGroupImages({
                                imgData: imgData,
                                event: $event
                              })
                            "
                            *ngIf="imgData.publishedTags.length > 1"
                          >
                            <ng-container
                              [ngTemplateOutlet]="
                                viewProjectTalentViewGroupIcon
                              "
                              [ngTemplateOutletContext]="{
                                imgData: imgData,
                                ind: ind
                              }"
                            >
                            </ng-container>
                          </span>
                        </div>
                        <div
                          class="mr-1"
                          *ngIf="
                            imgData.publishedTags.length == 1 &&
                            imgData.assetType == assetType.IMAGE
                          "
                        >
                          <ng-container
                            [ngTemplateOutlet]="viewProjectTalentViewSoloIcon"
                            [ngTemplateOutletContext]="{ imgData: imgData }"
                          >
                          </ng-container>
                        </div>
                        <div class="mr-1">
                          <ng-container
                            [ngTemplateOutlet]="viewProjectNotes"
                            [ngTemplateOutletContext]="{
                              imgData: imgData,
                              ind: ind
                            }"
                          >
                          </ng-container>
                        </div>
                      </div>
                      <div>
                        <ng-container
                          [ngTemplateOutlet]="iconZoom"
                          [ngTemplateOutletContext]="{ imgData: imgData }"
                        >
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Album Viewer VIEW -->
              <div
                class="imgItem-inner-child"
                *ngIf="
                  userAuthority === userRole.ROLE_EXTERNAL &&
                  projectAuthority === projectRole.ROLE_ALBUMVIEWER
                "
                [ngClass]="imageCornerColor(imgData)"
                (contextmenu)="onRightClick()"
              >
                <div
                  class="admin-user-wrapper"
                  *ngIf="
                    selectedProjectValue !== 3 &&
                    selectedProjectValue !== 4 &&
                    !isExecutiveAlbumView
                  "
                >
                  <!--Image container-->
                  <div #sectionToDrag class="imge">
                    <ng-container
                      *ngIf="imgData.assetType === assetType.IMAGE"
                      [ngTemplateOutlet]="imagePanel"
                      [ngTemplateOutletContext]="{
                        imgData: imgData,
                        ind: ind,
                        rejectedXCondition:
                          imgData.rejected && imageLoaded[ind],
                        withExclusive: true,
                        withIcons: true,
                        withRejectIcon: true,
                        isSignOffWideEditView: false,
                        withNoteBorder: false
                      }"
                    >
                    </ng-container>
                    <ng-container
                      *ngIf="imgData.assetType !== assetType.IMAGE"
                      [ngTemplateOutlet]="videoAndDocsPanel"
                      [ngTemplateOutletContext]="{
                        imgData: imgData,
                        ind: ind,
                        withRejectIcon: true,
                        rejectedXCondition:
                          imgData.rejected && imageLoaded[ind],
                        withExclusive: true
                      }"
                    >
                    </ng-container>
                    <fs-image-name-panel
                      [imgData]="imgData"
                    ></fs-image-name-panel>
                    <ng-container
                      [ngTemplateOutlet]="colorTagPanel"
                      [ngTemplateOutletContext]="{ imgData: imgData }"
                    >
                    </ng-container>
                  </div>
                  <!--Action menu-->
                  <div
                    class="act-desc imgex-links-parent albumViewerImageBottom"
                  >
                    <div class="w-100 d-flex flex-row justify-content-end">
                      <div
                        *ngIf="
                          imgData.publishedTags.length > 1 &&
                          imgData.excludedFrom.length == 0
                        "
                        class="image-link-wrapper d-flex justify-content-center"
                      >
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#imagesg"
                          [title]="'view-project.solo' | translate"
                          (click)="
                            clickOnGroupImages({
                              imgData: imgData,
                              event: $event
                            })
                          "
                        >
                          <fs-char-icon-component
                            [isCharIcon]="false"
                            [isChar]="true"
                            [isHighlighted]="true"
                            [imgData]="imgData"
                          >
                          </fs-char-icon-component>
                          <fs-char-icon-with-border [imgData]="imgData">
                          </fs-char-icon-with-border>
                        </a>
                      </div>
                      <div
                        class="image-link-wrapper d-flex justify-content-center"
                        *ngIf="
                          imgData.publishedTags.length == 1 &&
                          imgData.assetType == assetType.IMAGE
                        "
                      >
                        <fs-view-project-solo-icon-component
                          [imgData]="imgData"
                          [isTaggerRole]="isTaggerRole"
                          (clickOnGroupImages)="clickOnGroupImages($event)"
                        >
                        </fs-view-project-solo-icon-component>
                        <fs-view-project-solo-icon-with-border-component
                          [imgData]="imgData"
                          [isTaggerRole]="isTaggerRole"
                          [assetType]="assetType"
                          (clickOnGroupImages)="clickOnGroupImages($event)"
                        >
                        </fs-view-project-solo-icon-with-border-component>
                      </div>
                      <div
                        *ngIf="
                          retouchAccess &&
                          imgData.assetType == assetType.IMAGE &&
                          projectDetailPermission.permissionDTO.retouchAccess
                        "
                      >
                        <fs-retouch-icon-component
                          [imgData]="imgData"
                          [isTaggerRole]="isTaggerRole"
                          [assetType]="assetType"
                          (drawImage)="drawImage($event)"
                          [projectDetailPermission]="projectDetailPermission"
                          [retouchAccess]="retouchAccess"
                        >
                        </fs-retouch-icon-component>
                      </div>
                      <div
                        class="zoom_li ml-2 mr-2"
                        *ngIf="imgData.assetType == assetType.IMAGE"
                      >
                        <ng-container
                          [ngTemplateOutlet]="iconZoom"
                          [ngTemplateOutletContext]="{ imgData: imgData }"
                        >
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Admin view-->
<ng-template #adminActionPanel let-imgData="imgData" let-ind="ind">
  <div class="actions-panel d-flex justify-content-between align-items-center">
    <div class="left-actions d-flex justify-content-start">
      <fs-album-folder-icon-component
        [imgData]="imgData"
        (customAlbumView)="customAlbumView($event)"
      >
      </fs-album-folder-icon-component>
    </div>
    <div class="center-actions d-flex mx-3 justify-content-center">
      <fs-priority-pix-icon-component
        [imgData]="imgData"
        [isTaggerRole]="isTaggerRole"
        [priorityPixAccess]="priorityPixAccess"
        [assetType]="assetType"
      >
      </fs-priority-pix-icon-component>
      <fs-group-icon-component
        [imgData]="imgData"
        [isTaggerRole]="isTaggerRole"
        [assetType]="assetType"
        (clickOnGroupImages)="clickOnGroupImages($event)"
      >
      </fs-group-icon-component>
      <fs-solo-icon-component
        [imgData]="imgData"
        [isTaggerRole]="isTaggerRole"
        [assetType]="assetType"
        (clickOnGroupImages)="clickOnGroupImages($event)"
      >
      </fs-solo-icon-component>
      <fs-view-project-solo-icon-with-border-component
        [imgData]="imgData"
        [isTaggerRole]="isTaggerRole"
        [assetType]="assetType"
        (clickOnGroupImages)="clickOnGroupImages($event)"
      >
      </fs-view-project-solo-icon-with-border-component>
      <fs-history-icon-component
        [imgData]="imgData"
        [isTaggerRole]="isTaggerRole"
        [assetType]="assetType"
        (openImageHistory)="openImageHistory($event)"
      >
      </fs-history-icon-component>
      <fs-transactions-icon-component
        [imgData]="imgData"
        [isTaggerRole]="isTaggerRole"
        (openImageTransaction)="openImageTransaction($event)"
      >
      </fs-transactions-icon-component>
      <fs-exclusive-history-icon-component
        [imgData]="imgData"
        [isTaggerRole]="isTaggerRole"
        [projectDetailPermission]="projectDetailPermission"
        [assetType]="assetType"
        (openImageHistory)="openImageHistory($event)"
      >
      </fs-exclusive-history-icon-component>
      <fs-retouch-icon-component
        [imgData]="imgData"
        [isTaggerRole]="isTaggerRole"
        [assetType]="assetType"
        (drawImage)="drawImage($event)"
        [projectDetailPermission]="projectDetailPermission"
        [retouchAccess]="retouchAccess"
      >
      </fs-retouch-icon-component>
      <fs-note-icon-component
        [imgData]="imgData"
        [ind]="ind"
        [notesSize]="imgData.assetNoteDTOList.length"
        (selectImageOnClickNotes)="selectImageOnClickNotes($event)"
      >
      </fs-note-icon-component>
      <fs-chat-icon-component
        [imgData]="imgData"
        [isTaggerRole]="isTaggerRole"
        [captionAccess]="captionAccess"
        (captionView)="captionView($event)"
      >
      </fs-chat-icon-component>
    </div>
    <div class="right-actions d-flex justify-content-end flex-column">
      <fs-zoom-icon-component
        [imgData]="imgData"
        [assetType]="assetType"
        (enlargeView)="enlargeView($event)"
      >
      </fs-zoom-icon-component>
    </div>
  </div>

  <!--Publishing panel-->
  <fs-publishing-panel-component
    [imgData]="imgData"
    [isTaggingView1Active]="isTaggingView1Active"
    [isTaggingView2Active]="isTaggingView2Active"
    [isByPass2ndTaggerActive]="isByPass2ndTaggerActive"
    [isConflictsActive]="isConflictsActive"
    [isNoConflictsActive]="isNoConflictsActive"
  >
  </fs-publishing-panel-component>
  <!--Tagger panel-->
  <fs-tagger-panel-component
    [imgData]="imgData"
    [isTaggingView1Active]="isTaggingView1Active"
    [isTaggingView2Active]="isTaggingView2Active"
    [isConflictsActive]="isConflictsActive"
    [isNoConflictsActive]="isNoConflictsActive"
    [isByPass2ndTaggerActive]="isByPass2ndTaggerActive"
    [simpleTagger]="true"
  >
  </fs-tagger-panel-component>
</ng-template>

<!--Image panel-->
<ng-template
  #imagePanel
  let-imgData="imgData"
  let-isSignOffWideEditView="isSignOffWideEditView"
  let-withIcons="withIcons"
  let-withExclusive="withExclusive"
  let-ind="ind"
  let-rejectedXCondition="rejectedXCondition"
  let-withNoteBorder="withNoteBorder"
  let-withRejectIcon="withRejectIcon"
>
  <div
    class="fs-image-panel-container mb-1"
    (mousedown)="
      selectImageOnClick({ selectImg: imgData, index: ind, event: $event })
    "
    (mouseup)="
      selectImageOnClickUP({ selectImg: imgData, index: ind, event: $event })
    "
    (dblclick)="selectImageOnDoubleClick(imgData, $event)"
  >
    <div
      class="image-container"
      [class.d-block]="imgData.status == 5"
      [class.last-select-border]="
        lastSelectedImageStore.hasEntity(imgData.id) && imageLoaded[ind]
      "
      [class.fs-border-none]="
        blockedHover &&
        !(selectedImagesStore.hasEntity(imgData.id) && imageLoaded[ind])
      "
    >
      <!--Red cross on z-index top of the image-->
      <img
        src="/assets/images/rejected_x_small.png"
        class="reject-image"
        *ngIf="withRejectIcon && rejectedXCondition"
        [class.reject-image-small]="
          projectDetailPermission.viewSettingDTO.size == '3'
        "
      />
      <!--Exclusive word on z-index top of the image-->
      <img
        src="/assets/images/EXCLUSIVE.png"
        class="exclusive-image"
        *ngIf="withExclusive && imgData.exclusive && imageLoaded[ind]"
      />

      <div class="hover-border d-inline-block position-relative">
        <ng-container *ngIf="withIcons">
          <!--Approve icon on top right corner-->
          <div
            *ngIf="
              imageLoaded[ind] &&
              imgData.status == 5 &&
              imgData.rejectedBy.length == 0
            "
            class="approve-icon"
            [class.apr-rej-large-img]="
              projectDetailPermission.viewSettingDTO.size == '1'
            "
            [class.apr-rej-medium-img]="
              projectDetailPermission.viewSettingDTO.size == '2'
            "
            [class.apr-rej-small-img]="
              projectDetailPermission.viewSettingDTO.size == '3'
            "
          >
            <i class="fs-approve-icon-sp bg-sprite"></i>
          </div>
          <!--Reject icon on top right corner-->
          <div
            *ngIf="
              imageLoaded[ind] &&
              imgData.status == 5 &&
              imgData.rejectedBy.length > 0
            "
            class="rejected-icon"
            [class.apr-rej-large-img]="
              projectDetailPermission.viewSettingDTO.size == '1'
            "
            [class.apr-rej-medium-img]="
              projectDetailPermission.viewSettingDTO.size == '2'
            "
            [class.apr-rej-small-img]="
              projectDetailPermission.viewSettingDTO.size == '3'
            "
          >
            <i class="fs-reject-icon-sp bg-sprite"></i>
          </div>
        </ng-container>
        <img
          class="image"
          *ngIf="projectImageData && imgData"
          name="imgData.assetName"
          [lazySrc]="viewProjectService.getWebImageURL(imgData, imageDim)"
          (load)="onLoad(ind)"
          onerror="this.src='/assets/images/noImage/no_image.jpg';"
          [class.selected-border]="selectedImagesStore.hasEntity(imgData.id)"
          [class.notes-border]="
            isSignOffWideEditView &&
            imgData?.assetNoteDTOList.length > 0 &&
            withNoteBorder
          "
          [class.approved-border]="
            isSignOffWideEditView && imgData?.exicutiveRejected === 1
          "
          [class.rejected-border]="
            isSignOffWideEditView && imgData?.exicutiveRejected === 3
          "
          [class.blur_img]="withExclusive && imgData.excluded"
          [id]="'target-' + imgData.id.toString()"
        />
        <span
          draggable="true"
          class="image-protect"
          (dragstart)="imageProtectDragStart($event)"
        >
          <img draggable="true" src="/assets/images/clear.cache.gif" />
        </span>
      </div>
    </div>
  </div>
</ng-template>

<!--Video and docs panel-->
<ng-template
  #videoAndDocsPanel
  let-imgData="imgData"
  let-ind="ind"
  let-withRejectIcon="withRejectIcon"
  let-rejectedXCondition="rejectedXCondition"
  let-withExclusive="withExclusive"
>
  <ng-container [ngSwitch]="imgData.assetType">
    <fs-image-video-panel-component
      *ngSwitchCase="assetType.VIDEO"
      (dragImages)="dragImages($event)"
      (imageProtectDragStart)="imageProtectDragStart($event)"
      (selectImageOnClick)="selectImageOnClick($event)"
      (selectImageOnClickUP)="selectImageOnClickUP($event)"
      (onClickVideoIcon)="onClickVideoIcon($event)"
      [lastSelectedImage2]="lastSelectedImage2"
      [imgData]="imgData"
      [withExclusive]="withExclusive"
      [assetSize]="projectDetailPermission.viewSettingDTO.size"
      [withRejectIcon]="withRejectIcon"
      [rejectedXCondition]="rejectedXCondition"
      [imageLoaded]="imageLoaded"
      [ind]="ind"
      [projectImageData]="projectImageData"
      [imageDim]="imageDim"
      [selectedImages2]="selectedImagesStore.getAll()"
      [blockedHover]="blockedHover"
      [onLoad]="onLoad"
    >
    </fs-image-video-panel-component>
    <fs-image-document-panel-component
      *ngSwitchCase="assetType.DOCX"
      [imgData]="imgData"
      (selectImageOnClickUP)="selectImageOnClickUP($event)"
      (selectImageOnClick)="selectImageOnClick($event)"
      [format]="'doc'"
      [lastSelectedImage2]="lastSelectedImage2"
      [imageLoaded]="imageLoaded"
      [selectedImages2]="selectedImagesStore.getAll()"
      [blockedHover]="blockedHover"
      [ind]="ind"
    >
    </fs-image-document-panel-component>
    <fs-image-document-panel-component
      *ngSwitchCase="assetType.PDF"
      [imgData]="imgData"
      (selectImageOnClickUP)="selectImageOnClickUP($event)"
      (selectImageOnClick)="selectImageOnClick($event)"
      [format]="'pdf'"
      [lastSelectedImage2]="lastSelectedImage2"
      [imageLoaded]="imageLoaded"
      [selectedImages2]="selectedImagesStore.getAll()"
      [blockedHover]="blockedHover"
      [ind]="ind"
    >
    </fs-image-document-panel-component>
    <fs-image-document-panel-component
      *ngSwitchCase="assetType.ZIP"
      [imgData]="imgData"
      (selectImageOnClickUP)="selectImageOnClickUP($event)"
      (selectImageOnClick)="selectImageOnClick($event)"
      [format]="'zip'"
      [lastSelectedImage2]="lastSelectedImage2"
      [imageLoaded]="imageLoaded"
      [selectedImages2]="selectedImagesStore.getAll()"
      [blockedHover]="blockedHover"
      [ind]="ind"
    >
    </fs-image-document-panel-component>
    <fs-image-document-panel-component
      *ngSwitchCase="assetType.XLSX"
      [imgData]="imgData"
      (selectImageOnClickUP)="selectImageOnClickUP($event)"
      (selectImageOnClick)="selectImageOnClick($event)"
      [format]="'xls'"
      [lastSelectedImage2]="lastSelectedImage2"
      [imageLoaded]="imageLoaded"
      [selectedImages2]="selectedImagesStore.getAll()"
      [blockedHover]="blockedHover"
      [ind]="ind"
    >
    </fs-image-document-panel-component>
  </ng-container>
</ng-template>

<ng-template #viewProjectNotes let-imgData="imgData" let-ind="ind">
  <div class="note-wrapper d-flex align-items-center">
    <fs-note-icon-component
      [imgData]="imgData"
      [ind]="ind"
      [notesSize]="imgData.assetNoteDTOList.length"
      (selectImageOnClickNotes)="selectImageOnClickNotes($event)"
    >
    </fs-note-icon-component>
  </div>
</ng-template>

<ng-template
  #viewProjectTalentViewSoloIcon
  let-imgData="imgData"
  let-isValues="isValues"
>
  <div [title]="'view-project.solo' | translate">
    <span class="fs-char-icon">
      <img src="/assets/icons/s.png" />
    </span>
  </div>
</ng-template>

<ng-template
  #viewProjectTalentViewGroupIcon
  let-imgData="imgData"
  let-isValues="isValues"
>
  <span class="fs-char-icon">
    <img src="/assets/icons/g_previous.png" alt="" />
  </span>
</ng-template>

<ng-template #colorTagPanel let-imgData="imgData" let-isValues="isValues">
  <ng-container
    class="image-color-cont"
    *ngIf="
      (albumColorTagsService.isColorPanelEnabled$ | async) &&
      imgData?.colorCodes?.length > 0
    "
  >
    <ng-container
      class="d-flex justify-content-center"
      *ngFor="let colorName of imgData.colorCodes"
    >
      <div
        *ngIf="colorName"
        class="fs-album-color-container mr-1 d-inline-block"
      >
        <label
          class="fs-album-color-round"
          [style.background-color]="colorName"
        >
        </label>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<!--Action edit icons-->
<ng-template #editIcons let-imgData="imgData">
  <div class="ml-1">
    <a
      class="fs-char-icon"
      href="javascript:void(0);"
      (click)="onClickUndoIcon(imgData)"
    >
      <img src="/assets/images/undo_icon.png" />
    </a>
  </div>
  <div *ngIf="domainObjAbbr === appSource.WDTV">
    <a
      class="fs-char-icon"
      href="javascript:void(0);"
      (click)="onClickDesignation(imgData)"
    >
      <img src="/assets/images/designation.png" />
    </a>
  </div>
  <div *ngIf="domainObjAbbr === appSource.WDTV">
    <a
      class="fs-char-icon"
      href="javascript:void(0);"
      (click)="onClickGroupSingleIcon(imgData)"
    >
      <img src="/assets/images/group.png" />
    </a>
  </div>
</ng-template>

<!--Action zoom icon-->
<ng-template #iconZoom let-imgData="imgData">
  <a
    class="fs-cursor-pointer ml-1"
    [title]="'view-project.enlarge' | translate"
    (click)="enlargeView(imgData)"
  >
    <span class="fs-char-icon">
      <img src="/assets/images/icon-zoom_previous.png" />
    </span>
  </a>
</ng-template>

<!--Priority pix icon-->
<ng-template #viewProjectIcon>
  <div class="mr-1">
    <span
      title="{{ 'view-project.transaction' | translate }}"
      data-toggle="modal"
    >
      <span class="char"><img src="/assets/icons/p.png" /></span>
    </span>
  </div>
</ng-template>

<!--Full name section-->
<ng-template #fullName let-imgData="imgData">
  <div
    [ngClass]="{
      default_name_hidden: !(imgData.executiveSignoffDTOList.length > 0)
    }"
    class="default_name"
  >
    <span *ngIf="imgData.executiveSignoffDTOList.length > 0">
      {{ getFullName(imgData.executiveSignoffDTOList[0].userId) }}
    </span>
    <span *ngIf="imgData.executiveSignoffDTOList.length > 1">
      and other {{ imgData.executiveSignoffDTOList.length - 1 }}
    </span>
    performed action on this
  </div>
</ng-template>

<!--Executive Album Tag-->
<ng-template
  #exicutiveAlbumTag
  let-imgData="imgData"
  let-isUserApprovedImage="isUserApprovedImage"
>
  <div class="d-flex" [ngClass]="{ margin_right: !isUserApprovedImage }">
    <a
      class="mr-1"
      href="javascript:void(0);"
      (click)="onClickExicutiveAlbumTag(1, imgData.id)"
    >
      <i
        class="fs-cursor-pointer fa fa-check-circle fs-approved-rejected-icon-size fs-text-color-green"
        aria-hidden="true"
      ></i>
    </a>
    <a
      class="mr-1"
      href="javascript:void(0);"
      (click)="onClickExicutiveAlbumTag(3, imgData.id)"
    >
      <i
        class="fs-cursor-pointer fa fa-times-circle fs-approved-rejected-icon-size fs-text-color-red"
        aria-hidden="true"
      ></i>
    </a>
  </div>
</ng-template>

<!--Action like icon-->
<ng-template #likeIcon let-imgData="imgData">
  <div class="ml-1 fs-char-icon">
    <a
      href="javascript:void(0);"
      class="fs-char-icon"
      *ngIf="imgData.exicutiveRejected == 1"
      (click)="onClickLikeIcon(imgData)"
    >
      <img *ngIf="!imgData.exicutiveLike" src="/assets/icons/thumb.png" />

      <img
        src="/assets/icons/thumb.png"
        *ngIf="imgData.exicutiveLike"
        [ngClass]="{ 'highlighted-icon': imgData.exicutiveLike }"
      />
    </a>
  </div>
</ng-template>

<!--Sigoff panel-->
<ng-template #signOff let-imgData="imgData" let-ind="ind">
  <div
    class="executive_album_wpr"
    [ngClass]="{ hideportion: selectedProjectValue != 3 }"
    (contextmenu)="onRightClick()"
  >
    <div
      class="act-desc"
      *ngIf="selectedProjectValue == 3 || isExecutiveAlbumView"
    >
      <!--Image container-->
      <div #sectionToDrag class="imge">
        <ng-container
          *ngIf="imgData.assetType === assetType.IMAGE"
          [ngTemplateOutlet]="imagePanel"
          [ngTemplateOutletContext]="{
            imgData: imgData,
            ind: ind,
            withExclusive: false,
            withIcons: false,
            withRejectIcon: false,
            isSignOffWideEditView: true,
            withNoteBorder: true
          }"
        >
        </ng-container>
        <ng-container
          *ngIf="imgData.assetType !== assetType.IMAGE"
          [ngTemplateOutlet]="videoAndDocsPanel"
          [ngTemplateOutletContext]="{
            imgData: imgData,
            ind: ind,
            withRejectIcon: false,
            withExclusive: false
          }"
        >
        </ng-container>
        <fs-image-name-panel [imgData]="imgData"></fs-image-name-panel>
      </div>
      <!--Action menu-->
      <div class="act-desc imgex-links-parent d-flex flex-column">
        <div class="d-flex justify-content-between">
          <div class="d-flex justify-content-start">
            <ng-container
              [ngTemplateOutlet]="exicutiveAlbumTag"
              [ngTemplateOutletContext]="{
                imgData: imgData,
                isUserApprovedImage: true
              }"
            >
            </ng-container>
            <div
              class="executive_pos_li mr-1 position-relative"
              *ngIf="imgData.executiveSignoffDTOList.length > 0"
              [ngClass]="{ margin_right: !isUserApprovedImage }"
            >
              <i class="fa fa-list-ol list_executive"></i>

              <div class="user_action_list_cont executive_pos">
                <div class="user_action_list">
                  <div
                    *ngFor="
                      let executiveSignoffDTOList of imgData.executiveSignoffDTOList;
                      let idx = index
                    "
                  >
                    <span
                      [class.fs-text-color-red]="
                        executiveSignoffDTOList.actionTypeId === 3
                      "
                      [class.fs-text-color-green]="
                        executiveSignoffDTOList.actionTypeId === 1
                      "
                    >
                      {{ getFullName(executiveSignoffDTOList.userId) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <div style="margin-right: 20px !important">&nbsp;</div>
            <div
              *ngIf="
                retouchAccess &&
                imgData.assetType == assetType.IMAGE &&
                projectDetailPermission.permissionDTO.retouchAccess
              "
            >
              <fs-retouch-icon-component
                [imgData]="imgData"
                [isTaggerRole]="isTaggerRole"
                [assetType]="assetType"
                (drawImage)="drawImage($event)"
                [projectDetailPermission]="projectDetailPermission"
                [retouchAccess]="retouchAccess"
              >
              </fs-retouch-icon-component>
            </div>
            <ng-container
              [ngTemplateOutlet]="viewProjectNotes"
              [ngTemplateOutletContext]="{ imgData: imgData, ind: ind }"
            >
            </ng-container>
            <div *ngIf="imgData.assetType == assetType.IMAGE">
              <ng-container
                [ngTemplateOutlet]="iconZoom"
                [ngTemplateOutletContext]="{ imgData: imgData }"
              >
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ng-container
          [ngTemplateOutlet]="fullName"
          [ngTemplateOutletContext]="{ imgData: imgData }"
        >
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
