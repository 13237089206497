<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{ 'Help' + '.' + data.topicName | translate }}
    {{ 'Help.help' | translate }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    aria-hidden="true"
    (click)="closeWindow()"
  >
    <i class="fa fa-times-circle"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="imreport-divtable">
    <video controls autoplay style="width: 100%">
      <source
        [src]="videoAssetBaseUrl + defaultLanguage + '/' + videoName + '.mp4'"
        type="video/mp4"
      />
      <track
        label="{{ defaultLanguage }}"
        kind="subtitles"
        srclang="{{ defaultLanguage }}"
        [src]="videoAssetBaseUrl + 'subtitle/' + defaultLanguage + '/' + videoName + '.vtt'"
        default
      />
      Your browser does not support the video tag.
    </video>
  </div>
</div>
