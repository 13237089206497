<div class="fs-popup">
  <div class="fs-popup-content">
    <div class="fs-app-updates-container">
      <div
        class="fs-app-updates-title fs-md-txt fs-text-color-light-black mb-2"
      >
        <span
          >{{ 'header.newFeatureAnnounceTitle' | translate }}
          {{ newFeatureAnnounce?.version }}:</span
        >
      </div>
      <ng-scrollbar
        autoHeightDisabled="false"
        appearance="standard"
        class="fs-updates-list-container pr-2"
      >
        <ul class="ml-4 fs-updates-list mb-0">
          <li
            class="fs-text-color-light-black fs-md-txt fs-app-update-feature fs-break-word"
            *ngFor="let announce of announces"
          >
            <span>{{ announce.announceText }}</span>
            <img
              class="fs-cursor-pointer my-2"
              *ngIf="announce.announceImgPath"
              (click)="openAnnounceImgViewerModal(announce)"
              [src]="'assets/images/announces/' + announce.announceImgPath | projectAssetsBaseUrl"
              [alt]="announce.announceText"
            />
          </li>
        </ul>
      </ng-scrollbar>
    </div>
  </div>
</div>
