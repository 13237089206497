import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettings } from '../../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../../shared/services/api.service';

@Injectable()
export class EditPermmissionService {
  constructor(private apiService: ApiService) {}

  callGetActorApprovalTalentlist(data): Observable<any> {
    return this.apiService.get(data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  callPostExecutiveAlbumNotify(data): Observable<any> {
    const path = AppSettings.EXECUTIVE_ALBUM_NOTIFY;
    return this.apiService.post(path, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }
}
