import { Router } from '@angular/router';
import { DepartmentService } from '../../../../department/department.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { ProjectRoleId } from '../../../../../shared/enum/project-role.enum';
import { AppSource } from '../../../../../shared/models/enum/AppSource';
import { DomainConfigService } from '../../../../../shared/services/domain-config.service';
import {
  GroupDataModel,
  GroupItemDataModel,
} from '../interfaces/dashboard-department-rule.model';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { ALBUM } from '../../../../../shared/constants/constants';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardDepartmentTalentWdtvRule implements DashboardRuleModel {
  private router = GetStaticInjector().get(Router);
  private departmentService = GetStaticInjector().get(DepartmentService);
  private viewProjectService = GetStaticInjector().get(ViewProjectService);
  private domainConfigService = GetStaticInjector().get(DomainConfigService);

  constructor(
    private group: GroupDataModel,
    private galBotInfo: GroupItemDataModel,
  ) {}

  matches(): boolean {
    return (
      this.group.groupType !== ALBUM &&
      this.domainConfigService.getDomainConfigData().abbr === AppSource.WDTV &&
      this.galBotInfo.role === Number(ProjectRoleId.ROLE_TALENT)
    );
  }

  apply(): void {
    this.viewProjectService.setProjectID(this.galBotInfo.value);
    this.departmentService.setSetUpNameGlobal(this.galBotInfo.label);
    this.departmentService.setSetUpNameGroupGlobal(this.group.groupName);
    this.departmentService.setSetUpIDGlobal(this.galBotInfo.value);
    this.router.navigate(['/dashboard/viewgallery/' + this.galBotInfo.value]);
  }
}
