import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettings } from '../../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../../shared/services/api.service';

@Injectable()
export class ActorapprovalreportService {
  constructor(private apiService: ApiService) {}

  callGetActorApprovalTalentlist(data): Observable<any> {
    const path = '';
    return this.apiService.get(data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getActorApprovalReports(projectID, actorID): Observable<any> {
    const path = AppSettings.GET_ACTOR_APPROVAL_REPORTS(projectID, actorID);
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }
}
