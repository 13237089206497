import { Overlay, OverlayContainer } from '@angular/cdk/overlay';
import { FsOverlayContainer } from './overlay-container';

export const OverlayContainerProvider = {
  provide: OverlayContainer,
  useClass: FsOverlayContainer,
};

export const OverlayProvider = {
  provide: Overlay,
  useClass: Overlay,
};
