import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettings } from '../../../../appSettings/appSettings';
import { ApiService } from '../../../../shared/services/api.service';
import { SelectedImages } from '../../../../shared/models/selectedImages.model';
import { ViewProjectService } from '../../../../shared/services/view-project.service';
import { AssetInfo } from '../../../../shared/models/assetInfo.model';
import { Utils } from '../../../../shared/utils/utils';

/*
  Generated class for the DbService provider.
  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/

@Injectable()
export class CompareService {
  compareSelectedImages: Map<number, SelectedImages> = new Map<
    number,
    SelectedImages
  >();
  private compareSelectedImagesSubject = new BehaviorSubject<
    Map<number, SelectedImages>
  >(new Map());
  compareSelectedImagesGlobal =
    this.compareSelectedImagesSubject.asObservable();

  private defaultRangValueSubject = new BehaviorSubject<any>([]);

  private projectDataCompare = new BehaviorSubject<any>([]);
  projectDataCompareGlobal = this.projectDataCompare.asObservable();

  selectedImagesCompare: Map<number, SelectedImages> = new Map<
    number,
    SelectedImages
  >();

  private selectedImagesCompareSubject = new BehaviorSubject<
    Map<number, SelectedImages>
  >(new Map());

  constructor(
    private apiService: ApiService,
    private viewProjectService: ViewProjectService,
  ) {}

  addCompareSelectedImages(key: number, imageData: SelectedImages) {
    this.compareSelectedImages.set(key, imageData);
    this.compareSelectedImagesSubject.next(this.compareSelectedImages);
  }

  removeSelectedImage(key: number) {
    this.compareSelectedImages.delete(key);
    this.compareSelectedImagesSubject.next(this.compareSelectedImages);
  }

  getAllselectedImages() {
    return this.compareSelectedImages;
  }

  getAllCompareSelectedImageIDs() {
    const ids = [];
    for (const key of this.compareSelectedImages.keys()) {
      ids.push(key);
    }
    return ids;
  }

  removeAllSelectedImages() {
    this.compareSelectedImages.clear();
    this.compareSelectedImagesSubject.next(this.compareSelectedImages);
  }

  setDefaultRangValue(value) {
    this.defaultRangValueSubject.next(value);
  }

  getDefaultRangValue() {
    return this.defaultRangValueSubject.value;
  }

  resetProjectDataCompare() {
    this.projectDataCompare.next(new AssetInfo());
  }

  setProjectDataCompare(data) {
    this.projectDataCompare.next(data);
  }

  getProjectDataCompare() {
    return this.projectDataCompare.value;
  }

  setSelectedImagesCompare(data) {
    this.selectedImagesCompareSubject.next(data);
  }

  getSelectedImagesCompare() {
    return this.selectedImagesCompareSubject.value;
  }

  getAllSelectedImageIDsIndex3(selectedImages2) {
    const id: number[] = [];
    const assetsIds = this.viewProjectService.getProjectImageIDs();
    let i = 0;
    for (const key of selectedImages2) {
      id[i] = assetsIds.indexOf(key) + 1;
      i++;
    }
    return id;
  }

  callAssetsInfoBasedOnAssetsId(data: any): Observable<any> {
    const path = AppSettings.ASSETS_INFO_BASEDON_ASSETS_ID;
    return this.apiService.post(path, data).pipe(
      map((res) => {
        const newAssetsInfoData =
          Utils.synchronizeAssetIdsWithAssetInfoDTOListIds(res, data);
        this.resetProjectDataCompare();
        this.setProjectDataCompare(newAssetsInfoData);
        return newAssetsInfoData;
      }),
    );
  }
}
