<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">{{ 'header.contactUs' | translate }}</div>
  <button class="fs-close-btn w-auto" mat-dialog-close>
    <i class="fa fa-times-circle"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="fx-dialog-icon-container">
    <span class="mr-2">
      <img
        class="fs-txt-white-grey-color-hover fs-txt-white-dark-color phone-mail-icon phone_icon"
        src="/assets/images/icon-phone.png"
      />
    </span>
    <a
      class="mat-dialog-content-link fs-txt-white-dark-color"
      href="tel:+1818-588-3500"
      >+1 (818) 588-3500
    </a>
  </div>
  <div class="fx-dialog-icon-container">
    <span class="mr-2">
      <img class="phone-mail-icon" src="/assets/images/icon-email.png" />
    </span>
    <span>
      <a
        class="mat-dialog-content-link fs-txt-white-dark-color"
        onclick="javascript:window.open('mailto:info@filmsolutions.com','Contact Support');event.preventDefault()"
        href="mailto:info@filmsolution?Subject=Contact%20Support"
        target="_top"
      >
        info&#64;filmsolutions.com
      </a>
    </span>
  </div>
</div>
