<div
  class="tagging-data-cont"
  *ngIf="imgData.publishedTags.length === 0 && imgData.generalTags.length === 0"
>
  <span>Unpublished</span>
</div>
<div
  *ngIf="
    isTaggingView1Active ||
    isTaggingView2Active ||
    isByPass2ndTaggerActive ||
    isConflictsActive ||
    isNoConflictsActive
  "
>
  <span *ngIf="imgData.tagKeys.length > 0">
    Published: {{ imgData.tagKeys }}</span
  >
</div>
