import { LauncherService } from '../../../../launcher/launcher.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { DashboardLauncherRuleset } from '../rule-set/dashboard-launcher-ruleset';
import { SelectedProjectModel } from '../interfaces/dashboard-launcher-rule.model';
import { IHandler } from '../../../interfaces/dashboard-handler.model';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardLauncherHandler implements IHandler {
  private launcherService = GetStaticInjector().get(LauncherService);
  private viewProjectService = GetStaticInjector().get(ViewProjectService);

  constructor(
    private group: string,
    private selectedProject: SelectedProjectModel,
  ) {}

  handle() {
    this.viewProjectService.talentFirstTimePriorityCount.next(true);
    this.launcherService.setSelectedDepLogo(this.selectedProject.groupLogo);
    this.viewProjectService.setProjectRole(this.selectedProject.role);

    return new DashboardLauncherRuleset(
      this.group,
      this.selectedProject,
    ).apply();
  }
}
