<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">{{ 'top-menu.viewsettings.title' | translate }}</div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeViewSetting()"></i>
  </button>
</div>

<div
  mat-dialog-content
  class="fs-view-settings theme-form d-flex justify-content-around"
>
  <div>
    <h5 class="fs-selectbox-label">
      {{ 'top-menu.viewsettings.select.columns' | translate }}
    </h5>
    <select
      class="fs-selectbox"
      name="selectedColumns"
      id="basic1"
      [(ngModel)]="selectedColumns"
    >
      <option
        class="fs-selectbox-dark"
        *ngFor="let col of dataColumns"
        [value]="col.id"
      >
        {{ col.name }}
      </option>
    </select>
  </div>

  <div>
    <h5 class="fs-selectbox-label">
      {{ 'top-menu.viewsettings.select.images' | translate }}
    </h5>
    <select
      class="fs-selectbox"
      name="selectedImages"
      id="basic2"
      [(ngModel)]="selectedImages"
    >
      <option
        class="fs-selectbox-dark"
        *ngFor="let image of dataImages"
        [value]="image.name"
      >
        {{ image.name }}
      </option>
    </select>
  </div>

  <div>
    <h5 class="fs-selectbox-label">
      {{ 'top-menu.viewsettings.select.size' | translate }}
    </h5>
    <select
      class="fs-selectbox"
      name="selectedSizes"
      id="basic3"
      [(ngModel)]="selectedSizes"
    >
      <option
        class="fs-selectbox-dark"
        *ngFor="let size of imageSize"
        [value]="size.id"
      >
        {{ size.name }}
      </option>
    </select>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-between">
  <div class="mf-sngle">
    <mat-checkbox
      name="PLA"
      [checked]="global"
      class="fs-checkbox"
      (change)="global = !global"
    >
      <span class="cb-title">{{
        'top-menu.viewsettings.applySettings' | translate
      }}</span>
    </mat-checkbox>
  </div>

  <div class="mf-sngle ftr-btns">
    <button
      type="submit"
      class="fs-dialog-btn w-auto ml-3"
      data-dismiss="modal"
      (click)="setViewSettings(selectedSizes, selectedImages, selectedColumns)"
    >
      {{ 'top-menu.viewsettings.buttons.submit' | translate }}
    </button>
  </div>
</div>
