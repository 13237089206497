import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { PopupService } from '../../../../../../shared/services/popup/popup.service';
import { SelectedImages } from '../../../../../../shared/models/selectedImages.model';
import { NoteMenuComponent } from '../note-menu-component/note-mat-menu.component';

@Component({
  selector: 'fs-note-icon-component',
  templateUrl: './note-icon-component.component.html',
  styleUrls: ['./note-icon-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteIconComponentComponent {
  @Output() selectImageOnClickNotes = new EventEmitter();
  @Input() imgData: SelectedImages;
  @Input() notesSize: number;
  @Input() ind: number;
  @ViewChild('noteIconContainer') private noteIconContainer: ElementRef;

  constructor(private popupService: PopupService) {}

  onNoteMenuOpen(imgData: SelectedImages, index: number, event: Event): void {
    if (this.notesSize) {
      this.openNoteOptionMenu(imgData, event, index);
    } else {
      this.selectNoteOption({
        selectImg: imgData,
        index: index,
        event: event,
        noteOptionType: 'add',
      });
    }
  }

  openNoteOptionMenu(imgData, event, index) {
    this.popupService
      .closePopupById$('ImageNotesDialogComponent')
      .subscribe(() => {
        this.popupService.open(
          NoteMenuComponent,
          this.noteIconContainer.nativeElement,
          {
            position: 'below',
            hasBackdrop: false,
            withArrow: false,
            cdkOverlayContainerClass: 'fs-overlay-998',
            closeOnBackdropClick: true,
            data: {
              imgData,
              event,
              ind: index,
              selectNoteOption: this.selectNoteOption,
            },
            id: 'ImageNotesDialogComponent',
            panelClass: [
              'fs-popup-base-panel',
              'fs-popup-no-border-radius-panel',
            ],
          },
        );
      });
  }

  selectNoteOption = (event) => {
    this.selectImageOnClickNotes.emit({ ...event });
  };
}
