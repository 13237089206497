import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ViewProjectService } from '../services/view-project.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Utils } from '../utils/utils';

@Pipe({ name: 'assetsStatusPipe' })
export class AssetsStatusPipe implements PipeTransform {
  private destroy$ = new Subject(); // TODO think what todo with this in pipe
  assetStatusDTO = [];
  status = '';
  constructor(
    public viewProjectService: ViewProjectService,
    public translate: TranslateService,
  ) {}
  transform(value: string): string {
    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.assetStatusDTO = [];
          this.assetStatusDTO = res.assetStatusDTO;
        }
      });

    this.assetStatusDTO.forEach((element) => {
      if (element.id === value) {
        this.status = element.value;
      }
    });
    return this.translate.instant(
      'left-menu.tab-bar.sidemenus.imageInfo.imageStatus.' + this.status,
    );
  }
}
