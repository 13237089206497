<div class="fs-popup-title text-uppercase" id="user-info">
  {{ 'Help.helpTopics' | translate }}
</div>

<div class="fs-popup-content pl-2 pt-1 fs-dialog-help-topics-wrapper">
  <div class="mb-1">
    <img src="/assets/images/icon-zoom_previous.png" />
    {{ 'Help.zoom' | translate }}
    <i
      (click)="showHelpWindow('zoom', 'zoom-tool')"
      class="fs-help-dialog-icon fa fa-question"
    ></i>
  </div>
  <div class="mb-1">
    <img src="/assets/images/icon-file_previous.png" />
    {{ 'Help.notes' | translate }}
    <i
      (click)="showHelpWindow('note', 'note-tool')"
      class="fs-help-dialog-icon fa fa-question"
    ></i>
  </div>
  <div class="mb-1" *ngIf="projectAuthority !== projectRole.ROLE_TALENT">
    <img src="/assets/images/icon-chat_previous.png" />
    {{ 'Help.caption' | translate }}
    <i
      (click)="showHelpWindow('imageCaption', 'image-captions')"
      class="fs-help-dialog-icon fa fa-question"
    ></i>
  </div>
  <div class="mb-1" *ngIf="projectAuthority !== projectRole.ROLE_TALENT">
    <img src="/assets/images/icon-pencil_previous.png" />
    {{ 'Help.retouch' | translate }}
    <i
      (click)="showHelpWindow('retouch', 'retouch-tool')"
      class="fs-help-dialog-icon fa fa-question"
    ></i>
  </div>

  <div
    class="mb-1 help-padding"
    *ngIf="projectAuthority !== projectRole.ROLE_TALENT"
  >
    <img src="/assets/images/icons/goto.png" style="width: 14px" />
    {{ 'Help.goto' | translate }}
    <i
      (click)="showHelpWindow('goto', 'go-to')"
      class="fs-help-dialog-icon fa fa-question"
    ></i>
  </div>

  <div
    class="mb-1 help-padding"
    *ngIf="projectAuthority !== projectRole.ROLE_TALENT"
  >
    <img src="/assets/images/icons/album.png" style="width: 16px" />
    {{ 'Help.albums' | translate }}
    <i
      (click)="showHelpWindow('album', 'album')"
      class="fs-help-dialog-icon fa fa-question"
    ></i>
  </div>

  <div
    class="mb-1 help-padding"
    *ngIf="projectAuthority !== projectRole.ROLE_TALENT"
  >
    <img src="/assets/images/icons/advancedsearch.png" style="width: 16px" />
    {{ 'Help.advancedSearch' | translate }}
    <i
      class="fs-help-dialog-icon fa fa-question"
      (click)="showHelpWindow('advancedSearch', 'advance-search')"
    >
    </i>
  </div>

  <div
    class="mb-1 help-padding"
    *ngIf="projectAuthority !== projectRole.ROLE_TALENT"
  >
    <img src="/assets/images/icon-link.png" style="width: 16px" />
    {{ 'Help.adhocLink' | translate }}
    <i
      class="fs-help-dialog-icon fa fa-question"
      (click)="showHelpWindow('adhoc', 'adhoc')"
    >
    </i>
  </div>

  <div
    class="mb-1 help-padding"
    *ngIf="projectAuthority !== projectRole.ROLE_TALENT"
  >
    <img src="/assets/images/icon-download.png" style="width: 16px" />
    {{ 'Help.download' | translate }}
    <i
      class="fs-help-dialog-icon fa fa-question"
      (click)="showHelpWindow('download', 'download')"
    >
    </i>
  </div>

  <div
    class="mb-1 help-padding"
    *ngIf="projectAuthority !== projectRole.ROLE_TALENT"
  >
    <img src="/assets/images/icon-metadata.png" style="width: 16px" />
    {{ 'Help.metadata' | translate }}
    <i
      class="fs-help-dialog-icon fa fa-question"
      (click)="showHelpWindow('metadata', 'metadata')"
    >
    </i>
  </div>

  <div class="mb-1 help-padding" *ngIf="projectAuthority">
    <img src="/assets/images/info.png" style="width: 16px" alt="icon" />
    {{ 'Help.shortcuts' | translate }}
    <i
      class="fs-help-dialog-icon fa fa-question"
      (click)="showShortCutWindow()"
    >
    </i>
  </div>
</div>
