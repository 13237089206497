import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fs-draw-image-comment-popup',
  templateUrl: './draw-image-comment-popup.component.html',
  styleUrls: ['./draw-image-comment-popup.component.scss'],
})
export class DrawImageCommentPopupComponent {
  @Input() ifTagEnable: boolean;
  @Input() ifTagEnableOffsetY: number;
  @Input() ifTagEnableOffsetX: number;
  @Input() isEditComment: boolean;
  @Input() editComment: number;

  @Output() hideTagForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() submitComment: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteComment: EventEmitter<any> = new EventEmitter<any>();
  @Output() submitCommentEdit: EventEmitter<any> = new EventEmitter<any>();
}
