import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { SelectedImagesStore } from '../../../../../../shared/store/selected-images-store';
import { LastSelectedImageStore } from '../../../../../../shared/store/last-selected-image-store';

@Component({
  selector: 'fs-image-video-panel-component',
  templateUrl: './image-video-panel-component.component.html',
  styleUrls: ['./image-video-panel-component.component.scss'],
})
export class ImageVideoPanelComponentComponent {
  @Output() dragImages: EventEmitter<any> = new EventEmitter();
  @Output() imageProtectDragStart: EventEmitter<any> = new EventEmitter();
  @Output() selectImageOnClick: EventEmitter<any> = new EventEmitter();
  @Output() selectImageOnClickUP: EventEmitter<any> = new EventEmitter();
  @Output() onClickVideoIcon: EventEmitter<any> = new EventEmitter();
  @Input() lastSelectedImage2;
  @Input() imgData;
  @Input() rejectedXCondition: boolean;
  @Input() withRejectIcon: boolean;
  @Input() assetSize: string;
  @Input() imageLoaded;
  @Input() ind;
  @Input() withExclusive: boolean;
  @Input() projectImageData;
  @Input() imageDim;
  @Input() selectedImages2;
  @Input() blockedHover;
  @Input() onLoad;

  constructor(
    public viewProjectService: ViewProjectService,
    public selectedImagesStore: SelectedImagesStore,
    public lastSelectedImageStore: LastSelectedImageStore,
  ) {}
}
