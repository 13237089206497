<div class="sidebar-header" *ngIf="priritypixImageCount > 0">
  <div class="search-filter" *ngIf="isPrioritypixBtnShown">
    <a class="text-uppercase filter-btn" type="button" (click)="callShowAll()">
      {{'left-menu.tab-bar.sidemenus.imageInfo.tab.data.all' | translate}}
    </a>
  </div>

  <div
    class="search-filter"
    *ngIf="!isPrioritypixBtnShown && priritypixImageCount > 0"
  >
    <a
      class="text-uppercase filter-btn"
      type="button"
      (click)="onClickPriorityPixBtn()"
    >
      View Pending PriorityPix ( {{ priritypixImageCount }} )
    </a>
  </div>
</div>
