import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectedImagesStore } from '../../../../../../shared/store/selected-images-store';
import { LastSelectedImageStore } from '../../../../../../shared/store/last-selected-image-store';

@Component({
  selector: 'fs-image-document-panel-component',
  templateUrl: './image-document-panel-component.component.html',
  styleUrls: ['./image-document-panel-component.component.scss'],
})
export class ImageDocumentPanelComponentComponent {
  @Output() selectImageOnClickUP = new EventEmitter();
  @Output() selectImageOnClick = new EventEmitter();
  @Input() imgData;
  @Input() lastSelectedImage2;
  @Input() imageLoaded;
  @Input() ind;
  @Input() selectedImages2;
  @Input() format;
  @Input() blockedHover;

  constructor(
    public selectedImagesStore: SelectedImagesStore,
    public lastSelectedImageStore: LastSelectedImageStore,
  ) {}
}
