import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CommentsService {
  private userListDataSubject = new BehaviorSubject<any>([]);

  setUserListData(data) {
    this.userListDataSubject.next(data);
  }
}
