<mat-accordion [displayMode]="'flat'" class="fs-accordion-dark-theme">
  <fs-accordion-panel
    [expanded]="false"
    [title]="'retouch.contributors' | translate"
  >
    <div
      fs-expansion-panel-accordion-header-content
      class="pl-4 pt-2 pb-2 fs-txt-white-dark-color fs-sm-txt"
    >
      <ng-scrollbar autoHeightDisabled="false" trackY="'true'">
        <div class="uiscroll">
          <div *ngFor="let contributor of contributors; let i = index">
            <mat-checkbox
              name="linkExpire"
              [checked]="checked"
              class="fs-checkbox"
              [value]="contributor.id"
              (change)="onFilterChange($event)"
            >
              <span class="cb-title">{{
                contributor.firstName + ' ' + contributor.lastName
              }}</span>
            </mat-checkbox>
          </div>
        </div>
      </ng-scrollbar>
    </div>
  </fs-accordion-panel>
</mat-accordion>
