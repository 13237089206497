import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Modalstatusservice } from '../../../../../../../../shared/services/modalstatus.service';
import { ImagenotesService } from '../../imagenotes-report/imagenotes-report.service';
import { ViewProjectService } from '../../../../../../../../shared/services/view-project.service';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../../../../../../shared/services/dialog/dialog.service';
import { OriginalNamesModel } from '../original-names.model';
import { OriginalNamesComponent } from '../original-names.component';
import { LoaderService } from '../../../../../../../../shared/services/loader.service';
import { OriginalNamesService } from '../../../../../../../../shared/services/original-names.service';
import { SelectedImagesStore } from '../../../../../../../../shared/store/selected-images-store';
import { UnsubscriberComponent } from '../../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { FilterEnum } from '../../../../../../../../shared/enum/filter.enum';

@Component({
  selector: 'fs-original-names-report',
  templateUrl: './original-names-report.component.html',
  styleUrl: './original-names-report.component.scss'
})
export class OriginalNamesReportComponent extends UnsubscriberComponent implements OnInit {
  reportType = '1';
  filterName = '';
  ALL = '1';
  CURRENT_FILTER = '2';
  filterEnum = FilterEnum;
  hasAssetIds: boolean;

  constructor(
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private imagenotesService: ImagenotesService,
    private viewProjectService: ViewProjectService,
    private selectedImagesStore: SelectedImagesStore,
    private loaderService: LoaderService,
    private originalNamesService: OriginalNamesService,
    private dialogRef: MatDialogRef<OriginalNamesReportComponent>,
    private dialogService: DialogService,
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.filterName = this.viewProjectService.getCurrentFilter();
    this.hasAssetIds = !!this.viewProjectService.getProjectImageIDs().length;
    this.reportType = '1';
  }

  onNotesChange(e) {
    this.reportType = e.value;
  }

  closeWindow() {
    this.dialogRef.close();
  }

  originalNamesReport() {
    this.loaderService.displayLoader(true);
    this.originalNamesService.getOriginalNames(
      this.viewProjectService.getProjectId(),
      (this.reportType === this.ALL) ? [] : this.viewProjectService.getProjectImageIDs()
    ).subscribe((res: OriginalNamesModel[]) => {
      this.loaderService.displayLoader(false);
      if (res.length) {
        this.dialogService.open(OriginalNamesComponent, {
          panelClass: [
            'fs-dialog-panel',
            'fs-dialog-size-ls-panel',
            'fs-dialog-link-panel',
            'fs-dialog-container-auto-height-panel',
          ],
          data: res
        });
      } else {
        this.alertCustom(this.translate.instant('left-menu.tab-bar.sidemenus.administration.original-names.warnings.notFound'))
      }
    }, () => {
      this.loaderService.displayLoader(false);
    });
    this.dialogRef.close();
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
