import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ViewProjectComponent } from './project-detail/view-project/view-project.component';
import { ViewProjectService } from '../../shared/services/view-project.service';
import { CurrentStateService } from '../../shared/services/current.state.service';
import { takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Utils } from '../../shared/utils/utils';
import { UnsubscriberComponent } from '../../shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'fs-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  providers: [ViewProjectComponent],
})
export class ProjectComponent
  extends UnsubscriberComponent
  implements OnInit, OnDestroy
{
  bodySelector = this.document.body;
  isLeftMenuPartShown = false;
  currentState: any;
  groupLogo = '';

  private projectId: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private viewProjectService: ViewProjectService,
    private currentStateService: CurrentStateService,
  ) {
    super();
  }

  ngOnInit() {
    this.viewProjectService.isLeftMenuOpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((leftmenuState) => {
        this.isLeftMenuPartShown = leftmenuState;
      });

    this.currentStateService.getCurrentState
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentState) => {
        this.currentState = currentState;
      });

    this.viewProjectService.projectIDGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((id) => {
        if (this.projectId !== id) {
          this.isLeftMenuPartShown = true;
          this.projectId = id;
          this.bodySelector.classList.remove('sidebar');
        }
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.groupLogo = res.groupLogo.toLowerCase();
        }
      });
    this.bodySelector.classList.remove('sidebar');
  }

  togglesidebar(flag) {
    const x = document.activeElement;
    console.log('====================================');
    console.log(x);
    console.log('====================================');
    this.viewProjectService.updateLeftMenuState(flag);
    if (!this.bodySelector.getAttribute('class')) {
      if (!this.isLeftMenuPartShown) {
        this.bodySelector.classList.add('sidebar');
      }
    } else {
      if (this.bodySelector.getAttribute('class').indexOf('sidebar') > -1) {
        this.bodySelector.classList.remove('sidebar');
      } else {
        this.bodySelector.classList.add('sidebar');
      }
    }
  }

  ngOnDestroy() {
    const bodySelector = this.document.body;
    bodySelector.classList.remove('sidebar');
    super.ngOnDestroy();
  }
}
