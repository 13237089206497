<div
  *ngIf="
    !isTaggerRole &&
    imgData.publishedTags.length == 1 &&
    imgData.assetType == assetType.IMAGE &&
    imgData.excludedFrom.length > 0
  "
  [title]="'view-project.solo' | translate"
>
  <span class="fs-char-icon">
    <img
      src="/assets/icons/s-border_previous.png"
      (click)="clickOnGroupImages.emit({ imgData: imgData, event: $event })"
      [ngClass]="{ 'with-border': imgData.excludedFrom?.length > 0 }"
    />
  </span>
</div>
