export const ConfigKey = {
  lowerZ: 122,
  upperZ: 90,
  lowerA: 65,
  upperA: 65,
  lowerD: 68,
  upperD: 68,
  lowerT: 116,
  upperT: 84,
  lowerP: 112,
  upperP: 80,
  lowerX: 120,
  upperX: 88,
  ArrowLeft: 37,
  ArrowRight: 39,
  ArrowUp: 38,
  ArrowDown: 40,
  lowerB: 66,
  Esc: 27,
  Space: 32,
};
