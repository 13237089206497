import { DashboardSearchProjectRuleset } from '../rule-set/dashboard-search-project-ruleset';
import { SearchProjectModel } from '../interfaces/dashboard-search-project-rule.model';
import { IHandler } from '../../../interfaces/dashboard-handler.model';

export class DashboardSearchProjectHandler implements IHandler {
  constructor(
    private fromDepartment: boolean,
    private selectedProjectId: number,
    private selectedProjectData: SearchProjectModel,
  ) {}

  handle() {
    return new DashboardSearchProjectRuleset(
      this.fromDepartment,
      this.selectedProjectId,
      this.selectedProjectData,
    ).apply();
  }
}
