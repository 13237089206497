import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'fs-image-exclusives-dialog-component',
  templateUrl: './image-exclusives-dialog-component.component.html',
})
export class ImageExclusivesDialogComponent {
  popupMsg: string;

  constructor(
    private dialogRef: MatDialogRef<ImageExclusivesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.popupMsg = data.popupMsg;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
