import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppSettings } from '../../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../../shared/services/api.service';

@Injectable()
export class ProjectsummaryreportService {
  constructor(private apiService: ApiService) {}

  getProjectSummaryReport(projectId: any): Observable<{}> {
    const path = AppSettings.PROJECT_REPORTS(projectId);
    return this.apiService.get(path).pipe(
      map((data) => {
        return data;
      }),
    );
  }
}
