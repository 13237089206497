<div class="fs-popup-title fs-cursor-move d-flex justify-content-between">
  <div class="modal-title">{{ 'editalbum.title' | translate }}</div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    aria-hidden="true"
    (click)="closeEditAlbumPopup()"
  >
    <i class="fa fa-times-circle"></i>
  </button>
</div>

<div class="fs-popup-content">
  <ng-scrollbar autoHeightDisabled="false" trackY="'true'">
    <div class="popup-body">
      <div class="row mt-2">
        <div class="col-6 fs-md-txt">{{ 'editalbum.name' | translate }}</div>
        <div class="col-6 d-flex">
          <div class="mr-2 fs-md-txt">{{ 'editalbum.none' | translate }}</div>
          <div class="mr-2 fs-md-txt">{{ 'editalbum.read' | translate }}</div>
          <div class="mr-2 fs-md-txt">{{ 'editalbum.rw' | translate }}</div>
        </div>
      </div>

      <!-- FIXME: Id repete id="data.id + USER"-->
      <ng-container *ngIf="albumData && permissions">
        <div
          class="row mt-2"
          *ngFor="let data of permissions | sortBy : 'name'"
        >
          <div class="col-6">
            {{ data.name }}
          </div>

          <div class="col-6">
            <mat-radio-group class="d-flex" [(ngModel)]="data.permissionType">
              <mat-radio-button
                [checked]="data.permissionType === 0"
                class="ml-1 mr-3 fs-radio-button"
                name="0"
                [value]="0"
                [disabled]="currentUser.id === data.id"
              ></mat-radio-button>
              <mat-radio-button
                [checked]="data.permissionType === 1"
                class="mr-3 fs-radio-button"
                name="1"
                [value]="1"
                [disabled]="currentUser.id === data.id"
              ></mat-radio-button>
              <mat-radio-button
                [checked]="data.permissionType === 2"
                class="mr-3 fs-radio-button"
                name="2"
                [value]="2"
                [disabled]="currentUser.id === data.id"
              ></mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-scrollbar>
</div>

<div class="fs-popup-actions d-flex justify-content-end">
  <button
    type="submit"
    class="fs-dialog-btn w-auto mr-3"
    (click)="closeEditAlbumPopup()"
  >
    {{ 'editalbum.buttons.cancel' | translate }}
  </button>

  <button
    type="submit"
    class="fs-dialog-btn w-auto"
    (click)="addEditAlbumPopup()"
  >
    {{ 'editalbum.buttons.save' | translate }}
  </button>
</div>
