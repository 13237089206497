import { UserService } from './../../../../../shared/services/user.service';
import { Component, OnInit } from '@angular/core';
import { LauncherService } from '../../../../launcher/launcher.service';
import { ViewGalleryService } from '../../../project-detail/view-gallery/view-gallery.service';
import { Observable } from 'rxjs';
import { UserRole } from '../../../../../shared/enum/user-role.enum';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../shared/store/selected-images-store';
import { Utils } from '../../../../../shared/utils/utils';
import { LogoService } from '../../../../../shared/services/logo.service';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import { ProjectRole } from '../../../../../shared/enum/project-role.enum';

@Component({
  selector: 'fs-projectinfo',
  templateUrl: './projectinfo.component.html',
  styleUrls: ['./projectinfo.component.scss'],
})
export class ProjectInfoComponent
  extends UnsubscriberComponent
  implements OnInit
{
  logoName = '';
  groupLogo = '';
  totalImageCount = 0;
  totalFilterCount = 0;
  selectAllImagesCount = 0;
  shootDate = '';
  photoCredit = '';
  projectAuthority: string;
  // Load setup
  isGallaryMode = false;
  isFavGallaryMode = false;
  userRole = UserRole;
  projectRole = ProjectRole;
  alternativeLogo$: Observable<string>;

  constructor(
    private viewProjectService: ViewProjectService,
    private launcherService: LauncherService,
    private viewGalleryService: ViewGalleryService,
    private userService: UserService,
    private selectedImagesStore: SelectedImagesStore,
    private logoService: LogoService,
  ) {
    super();
  }

  ngOnInit() {
    this.alternativeLogo$ = this.logoService.getDepartmentLogo();

    this.launcherService.isGallaryModeGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((isGallaryMode) => {
        this.isGallaryMode = isGallaryMode;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.launcherService.isFavGallaryModeGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((isFavGallaryMode) => {
        this.isFavGallaryMode = isFavGallaryMode;
      });

    this.viewProjectService.projectImageIdsGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data.length) {
          this.totalFilterCount = data.length;
        }
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!this.isGallaryMode && !this.isFavGallaryMode) {
          if (!Utils.isObjectEmpty(res)) {
            this.totalImageCount = res.totalAssets;
          }
        }
        if (!Utils.isObjectEmpty(res)) {
          this.logoName = res.projectGroupName.toLowerCase();
          this.groupLogo = res.groupLogo.toLowerCase();
        }
        this.shootDate = res.shootDate;
        this.photoCredit = res.photoCredit;
      });

    this.viewGalleryService.totalGalleryImageGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((total) => {
        if (this.isGallaryMode || this.isFavGallaryMode) {
          this.totalImageCount = total;
        }
      });

    this.selectedImagesStore
      .getCollection()
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageArray) => {
        this.selectAllImagesCount = imageArray.length;
      });
  }
}
