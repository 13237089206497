import { DashboardFavouriteIconRuleset } from '../rule-set/dashboard-favourite-icon-ruleset';
import { IHandler } from '../../../interfaces/dashboard-handler.model';
import { Group } from '../../../../../shared/models/projectlist.model';
import { EventEmitter } from '@angular/core';
import { FavSelectedProjectModel } from '../interfaces/dashboard-favourite-icon-rule.model';

export class DashboardFavouriteIconHandler implements IHandler {
  constructor(
    private active: boolean,
    private selectedProject: FavSelectedProjectModel,
    private groupedProject?: Group[],
    private updatedFavStatus?: EventEmitter<Group[]>,
  ) {}

  handle() {
    return new DashboardFavouriteIconRuleset(
      this.active,
      this.selectedProject,
      this.groupedProject,
      this.updatedFavStatus,
    ).apply();
  }
}
