import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[preventSpecialChars]',
})
export class PreventSpecialCharsDirective {
  @Input() preventSpecialChars: string[];

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const key = event.key;
    if (this.preventSpecialChars.includes(key)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const pastedText = event.clipboardData?.getData('text/plain') || '';
    const filteredText = pastedText.replace(
      new RegExp(`[${this.preventSpecialChars.join('')}]`, 'g'),
      '',
    );
    document.execCommand('insertHTML', false, filteredText);
    event.preventDefault();
  }
}
