import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Listener } from './listener';
import { tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import DialogStateListenerData = FsStateDialogs.DialogStateListenerData;
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import State = FsStateDialogs.State;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace FsStateDialogs {
  export interface DialogStateListenerData {
    opened: boolean;
    closed: boolean;
    data?: any;
    config?: MatDialogConfig<any>;
    dialogRef?: MatDialogRef<unknown>;
  }

  export interface State {
    currentState: DialogStateListenerData;
    state$: Observable<DialogStateListenerData>;
  }
}

export class DialogState {
  private listener: Listener<DialogStateListenerData> =
    new Listener<DialogStateListenerData>();
  private dialogOnCloseSubscription: Subscription;
  private stateSubscription: Subscription;

  constructor(initData: DialogStateListenerData) {
    this.listener.withInitialValue({
      opened: initData.opened,
      closed: initData.closed,
      data: initData.data || null,
      dialogRef: initData.dialogRef,
      config: initData.config,
    });

    let oldState = this.getState().currentState;
    this.stateSubscription = this.getState().state$.subscribe(
      (state: DialogStateListenerData) => {
        if (!state.dialogRef) {
          this.dialogOnCloseSubscription?.unsubscribe();
        }

        if (state.dialogRef && oldState.dialogRef !== state.dialogRef) {
          this.dialogOnCloseSubscription?.unsubscribe();
          this.dialogOnCloseSubscription = this.handleOnCloseDialog(
            state.dialogRef,
          ).subscribe();
          oldState = state;
        }
      },
    );
  }

  update(data: DialogStateListenerData) {
    this.listener.emit(data);
  }

  getState() {
    return {
      currentState: this.listener.getValue(),
      state$: this.listener.listen(),
    } as State;
  }

  destroy() {
    this.stateSubscription?.unsubscribe();
    this.dialogOnCloseSubscription?.unsubscribe();
    this.listener.stopListen();
  }

  private handleOnCloseDialog(dialogRef: MatDialogRef<unknown>) {
    return dialogRef.afterClosed().pipe(
      tap((closedWithData) => {
        this.update({
          opened: false,
          closed: true,
          data: closedWithData || null,
          dialogRef: null,
          config: null,
        });
      }),
    );
  }
}
