<div
  class="ml-1"
  *ngIf="
    !isTaggerRole &&
    imgData.publishedTags.length == 1 &&
    imgData.assetType == assetType.IMAGE
  "
>
  <fs-view-project-solo-icon-component
    [imgData]="imgData"
    (clickOnGroupImages)="clickOnGroupImages.emit($event)"
  >
  </fs-view-project-solo-icon-component>
</div>
