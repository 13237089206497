import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  forkJoin,
  Observable,
  of,
  Subject,
  throwError,
} from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { AppSettings } from '../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../shared/services/api.service';
import { AlbumsInfo } from '../../../../../../shared/models/albumsInfo.model';
import { Utils } from '../../../../../../shared/utils/utils';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../shared/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../../../../../shared/services/dialog/dialog.service';
import { AssetInfoDTOList } from '../../../../../../shared/models/assetInfo.model';
import { tap } from 'rxjs/internal/operators/tap';
import { TopmenusService } from '../../../top-menu/top-menu.service';
import { UserRole } from '../../../../../../shared/enum/user-role.enum';
import { ExclusiveSensitiveAssetDialogComponent } from '../../../../../../shared/components/exclusive-sensitive-asset-dialog/exclusive-sensitive-asset-dialog.component';

@Injectable()
export class AlbumsService {
  private userDataInfo = new BehaviorSubject<any>('');
  userDataInfoGlobal = this.userDataInfo.asObservable();
  private albumsInfo = new BehaviorSubject<AlbumsInfo>(new AlbumsInfo());
  private destroy$ = new Subject(); // TODO think what to do this in pipe

  constructor(
    private apiService: ApiService,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private topmenusService: TopmenusService,
    private dialogService: DialogService,
  ) {}

  setUserDataInfo(data) {
    this.userDataInfo.next(data);
  }

  setAlbumsInfo(data) {
    this.albumsInfo.next(data);
  }

  resetAlbumsInfo() {
    this.albumsInfo.next(new AlbumsInfo());
  }

  getAlbumsInfo() {
    return this.albumsInfo.value;
  }

  getAlbumImageData(obj): Observable<any> {
    const path = AppSettings.GET_ASSETS_ALBUMID(obj.albumId, obj.projectId);
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getExecutiveAlbumImageData(obj, projectValue): Observable<any> {
    const path = AppSettings.GET_EXECUTIVE_ASSETS_ALBUMID(
      obj.albumId,
      projectValue,
      obj.projectId,
    );
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getAlbumGroupInfo(id): Observable<AlbumsInfo> {
    const path = AppSettings.ALBUMS_GROUP(id);
    return this.apiService.get(path).pipe(
      map((res) => {
        if (res.projectAlbum) {
          res = this.updateHotKeysForProjectAlbum(res);
          this.setAlbumsInfo(res);
        }
        return res;
      }),
    );
  }

  getAlbumViewerInfo(id): Observable<AlbumsInfo> {
    const path = AppSettings.ALBUMS_VIEWER_GROUP(id);
    return this.apiService.get(path).pipe(
      map((res) => {
        if (res.projectAlbum) {
          res = this.updateHotKeysForProjectAlbum(res);
          this.setAlbumsInfo(res);
        }
        return res;
      }),
    );
  }

  updateHotKeysForProjectAlbum(data: AlbumsInfo) {
    data.projectAlbum.children.forEach((element) => {
      if (element.tagKey === -1) {
        element.tagKey = element.value.substring(0, 1);
      }
    });
    return data;
  }

  putAlbumTagKey(obj): Observable<any> {
    const path = AppSettings.ALBUM_TAG_KEY;
    return this.apiService.put(path, obj).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  putAlbumTagColor(obj): Observable<any> {
    const path = AppSettings.ALBUM_TAG_COLOR;
    return this.apiService.put(path, obj).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  postAlbumCopyCreate(obj): Observable<any> {
    const path = AppSettings.ALBUM_COPY_CREATE;
    return this.apiService.post(path, obj).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  postSubmitAlbumExecutivePermissionDTO(obj): Observable<any> {
    const path = AppSettings.ALBUM_EXECUTIVE_ALBUM;
    return this.apiService.post(path, obj).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getSensitiveAlbumImages(
    assetIds: number[],
    imageCount: number,
    albumId: number,
  ): Observable<AssetInfoDTOList[]> {
    const currentFilter = 'album:' + albumId;
    const data = {
      assetIds: Utils.getImageIdsBasedOnPage(1, imageCount, assetIds),
      projectRoleId: this.viewProjectService.getProjectRole(),
      projectId: this.viewProjectService.getProjectId(),
      filter: currentFilter,
    };

    this.loaderService.displayLoader(true);
    return this.viewProjectService
      .getSensitiveAssets(data)
      .pipe(takeUntil(this.destroy$));
  }

  getExclusiveAlbumImages(
    assetIds: number[],
    imageCount: number,
    albumId: number,
  ): Observable<AssetInfoDTOList[]> {
    const currentFilter = 'album:' + albumId;
    const data = {
      assetIds: Utils.getImageIdsBasedOnPage(1, imageCount, assetIds),
      projectRoleId: this.viewProjectService.getProjectRole(),
      projectId: this.viewProjectService.getProjectId(),
      filter: currentFilter,
    };

    this.loaderService.displayLoader(true);
    return this.topmenusService
      .getExclusiveAssets(data)
      .pipe(takeUntil(this.destroy$));
  }

  getVideoAlbumAssetInfo(
    assetIds: number[],
    imageCount: number,
    albumId: number,
  ): Observable<AssetInfoDTOList[]> {
    const currentFilter = 'album:' + albumId;
    const data = {
      assetIds: Utils.getImageIdsBasedOnPage(1, imageCount, assetIds),
      projectRoleId: this.viewProjectService.getProjectRole(),
      projectId: this.viewProjectService.getProjectId(),
      filter: currentFilter,
    };

    this.loaderService.displayLoader(true);
    return this.viewProjectService
      .getVideoAssets(data)
      .pipe(takeUntil(this.destroy$));
  }

  handleAlbumAssetDownloads(
    assetIds: number[],
    imageCount: number,
    albumId: number,
  ) {
    const sensitiveAssets$ = this.getSensitiveAlbumImages(
      assetIds,
      imageCount,
      albumId,
    );
    const exclusiveAssets$ = this.getExclusiveAlbumImages(
      assetIds,
      imageCount,
      albumId,
    );

    return forkJoin([sensitiveAssets$, exclusiveAssets$]);
  }

  getLinkExclusiveAlbumImages(
    assetIds: number[],
    imageCount: number,
    albumId: number,
  ): Observable<AssetInfoDTOList[]> {
    const currentFilter = 'album:' + albumId;

    const data = {
      assetIds: Utils.getImageIdsBasedOnPage(1, imageCount, assetIds),
      projectId: this.viewProjectService.getProjectId(),
      projectRoleId: this.viewProjectService.getProjectRole(),
      filter: currentFilter,
    };

    this.loaderService.displayLoader(true);
    return this.topmenusService
      .getLinkExclusiveAssets(data)
      .pipe(takeUntil(this.destroy$));
  }

  getAlbumSensitiveAndLinkExclusiveImages(
    assetIds: number[],
    imageCount: number,
    albumId: number,
  ): Observable<[AssetInfoDTOList[], AssetInfoDTOList[]]> {
    const sensitiveImages$ = this.getSensitiveAlbumImages(
      assetIds,
      imageCount,
      albumId,
    );
    const linkExclusiveImages$ = this.getLinkExclusiveAlbumImages(
      assetIds,
      imageCount,
      albumId,
    );

    return forkJoin([sensitiveImages$, linkExclusiveImages$]);
  }

  buildActionPayload(
    id: number,
    filteredAssetIds: number[],
    filter,
    authority,
  ) {
    return filteredAssetIds && filteredAssetIds.length
      ? {
          assetIds: filteredAssetIds,
          filter: filter,
          projectId: this.viewProjectService.getProjectId(),
          authorities: [authority],
        }
      : {
          assetIds: [],
          albumId: id,
          filter: filter,
          projectId: this.viewProjectService.getProjectId(),
          authorities: [authority],
        };
  }

  openAlbumExclusiveSensitiveAssetModal(
    albumId: number,
    assetIds: number[],
    extractAssets: AssetInfoDTOList[],
    downloadFn,
    imageCount?: number,
  ) {
    this.dialogService.open(ExclusiveSensitiveAssetDialogComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
        'fs-dialog-link-panel',
      ],
      listener: {
        withListener: true,
        id: 'ExclusiveSensitiveAssetDialogComponent',
      },
      data: {
        openDownloadDialog: downloadFn,
        extractAssets: extractAssets,
        isFromTopMenu: false,
        assetIds: assetIds,
        albumId: albumId,
        imageCount: imageCount,
      },
    });
  }

  isAllAlbumSelectedAssetsExclusiveOrSensitive(
    sensitiveAssets,
    exclusiveAssets,
    assetIds: number[],
  ): boolean {
    return (
      Utils.mergeAndRemoveDuplicatesByKey<AssetInfoDTOList>(
        [...sensitiveAssets, ...exclusiveAssets],
        'id',
      ).length === assetIds.length
    );
  }

  getAlbumAssets(id: number, noOfImages: number) {
    const currentFilter = 'album:' + id;
    this.loaderService.displayLoader(true);
    return this.viewProjectService
      .callAlbumImageIDs(this.viewProjectService.getProjectId(), currentFilter)
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          this.loaderService.displayLoader(false);
          return throwError(error);
        }),
      );
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
