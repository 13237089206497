import { environment } from '../environments/environment';

export const AppConfig = {
  TIME_OUT: {
    IDEL: 1800, // Set IDEL in secound
    IDEL_TIME_OUT: 5, // Set IDEL_TIME_OUT in secound
    REFRESH_TOKEN: 28800, // Set REFRESH_TOKEN in mili secound
    PING_REFRESH_TOKEN: 10000, // Set LAST_IMAGE_SELECTION in mili secound
    LAST_IMAGE_SELECTION: 300, // Set LAST_IMAGE_SELECTION in mili secound
  },
  SETTINGS: {
    EXPRESS_DOWNLOAD: false,
  },
};
