<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{ 'top-menu.expressDownload.title' | translate }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content class="theme-form d-flex">
  <mat-horizontal-stepper [@.disabled]="true" linear #stepper>
    <mat-step completed>
      <span>{{
        'top-menu.expressDownload.step1.description' | translate
      }}</span>
      <div class="ml-2 mt-2 d-flex">
        <mat-radio-group
          class="d-flex flex-column"
          [value]="selectedDownloadType"
          (change)="changeDownloadSize($event)"
        >
          <mat-radio-button
            class="fs-radio-button"
            *ngFor="let option of downloadTypes"
            [value]="option.id"
          >
            <span class="cb-title fs-sm-txt">{{ option.trans }}</span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </mat-step>
    <mat-step completed>
      <h5>{{ 'top-menu.expressDownload.step2.title' | translate }}</h5>
      <span>{{
        'top-menu.expressDownload.step2.description' | translate
      }}</span>
      <mat-progress-bar
        class="mt-2"
        [color]="'primary'"
        [mode]="'indeterminate'"
      >
      </mat-progress-bar>
    </mat-step>
    <mat-step completed>
      <h5>{{ 'top-menu.expressDownload.step3.title' | translate }}</h5>
      <span>
        {{
          'top-menu.expressDownload.step3.descriptionNumberOfFoundAssets'
            | translate
        }}
        <span class="fs-text-light-green-color">{{ assetsCount }}</span>
        {{ 'top-menu.expressDownload.step3.description' | translate }}
      </span>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<div mat-dialog-actions class="mf-sngle d-flex justify-content-end">
  <button
    class="fs-dialog-btn w-auto ml-3"
    *ngIf="stepper.selectedIndex === 0"
    [disabled]="!selectedDownloadType"
    (click)="onCallExpressDownload()"
  >
    {{ 'top-menu.expressDownload.buttons.next' | translate }}
  </button>
  <button
    class="fs-dialog-btn w-auto ml-3"
    *ngIf="stepper.selectedIndex === 1"
    (click)="closeWindow()"
  >
    {{ 'top-menu.expressDownload.buttons.close' | translate }}
  </button>
  <button
    class="fs-dialog-success-btn w-auto ml-3"
    *ngIf="stepper.selectedIndex === 2"
  >
    <a target="_blank" [href]="mediaShuttleLink">{{
      'top-menu.expressDownload.buttons.launchDownload' | translate
    }}</a>
  </button>
</div>
