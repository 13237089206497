import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettings } from '../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../shared/services/api.service';

@Injectable()
export class AuditService {
  constructor(private apiService: ApiService) {}

  putClearAllTags(obj): Observable<any> {
    const path = AppSettings.CLEAR_TAGS;
    return this.apiService.put(path, obj).pipe(
      map((res) => {
        return res;
      }),
    );
  }
}
