<mat-progress-bar
  mode="determinate"
  [bufferValue]="100"
  [value]="startTimer$ | async"
>
</mat-progress-bar>
<div class="d-flex justify-content-between">
  <span>{{ this.message }}</span>
  <span class="d-flex justify-content-center align-items-center">
    <button
      class="bg-transparent fs-close-circle-btn p-0"
      mat-button
      matSnackBarAction
      (click)="snackBarRef.dismiss()"
    >
      <i class="ml-1 fa fa-times-circle"></i>
    </button>
  </span>
</div>
