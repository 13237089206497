import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'fs-select-recepient',
  templateUrl: './select-recepient.component.html',
  styleUrls: ['./select-recepient.component.scss'],
})
export class SelectRecepient {
  arrListEmails;
  isAllemailsSelected = false;

  constructor(
    private dialogRef: MatDialogRef<SelectRecepient>,
    @Inject(MAT_DIALOG_DATA) private data: { arrListEmails: any },
  ) {
    this.arrListEmails = this.data.arrListEmails;
  }

  onSelecDeselectAll(checkBoxVal) {
    this.arrListEmails.forEach((emailList) => {
      emailList['isChecked'] = checkBoxVal;
    });
  }

  onClickSingleCheckBox(idx: number) {
    this.arrListEmails[idx]['isChecked'] =
      !this.arrListEmails[idx]['isChecked'];
  }

  onClickDoneBtn() {
    const tempArray = [];
    this.arrListEmails.forEach((selectedEmail) => {
      if (selectedEmail.isChecked) {
        tempArray.push(selectedEmail.id);
      }
    });

    const tempObj = {
      selectedList: tempArray,
      selectedEmailCount: tempArray.length,
      allEmails: this.arrListEmails,
      isClosed: true,
    };

    this.dialogRef.close(tempObj);
  }

  closeWindow() {
    this.onClickDoneBtn();
  }
}
