import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FsCookieService } from './fs-cookie.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { LanguageCode, LanguageConfigModel } from '../models/langualge.model';


export const LanguageConfig: LanguageConfigModel[] = [
  {
    label: 'English (US)',
    value: {
      id: 1,
      name: 'English (US)',
      code: 'en-us',
      icon: 'english_us.png',
    },
  },
  {
    label: '日本語 (Japanese)',
    value: {
      id: 2,
      name: '日本語 (Japanese)',
      code: 'ja',
      icon: 'japanese.png',
    },
  },
  {
    label: 'français (France)',
    value: {
      id: 3,
      name: 'français (France)',
      code: 'fr',
      icon: 'francais.png',
    },
  },
  {
    label: '한국어 (Korean)',
    value: {
      id: 4,
      name: '한국어 (Korean)',
      code: 'kr',
      icon: 'korean.png'
    },
  },
  {
    label: 'Deutsch (German)',
    value: {
      id: 5,
      name: 'Deutsch (German)',
      code: 'de',
      icon: 'german.png'
    }
  },
  {
    label: 'Español (Spanish)',
    value: {
      id: 6,
      name: 'Español (Spanish)',
      code: 'es',
      icon: 'spain.png'
    }
  },
  {
    label: 'Português (Portuguese)',
    value: {
      id: 7,
      name: 'Português (Portuguese)',
      code: 'br',
      icon: 'portugal.png'
    }
  },
];

@Injectable()
export class LanguageService {
  private enableSelectLanguage: Subject<any> = new Subject<any>();
  private selectedLanguage: BehaviorSubject<LanguageCode> =
    new BehaviorSubject<LanguageCode>('en-us');

  constructor(
    private translate: TranslateService,
    private cookieService: FsCookieService,
    private sessionStorage: SessionStorageService,
    private localStorage: LocalStorageService,
  ) {}

  setSelectedLanguage(language: LanguageCode) {
    this.selectedLanguage.next(language);
  }

  setLanguageCookie(): void {
    const allowedLanguages: LanguageCode[] = ['en-us', 'br', 'de', 'es', 'fr', 'ja', 'kr'];

    this.translate.addLangs(allowedLanguages);

    const languageCode: LanguageCode = this.localStorage.retrieve('selectedLanguage')
      ? this.localStorage.retrieve('selectedLanguage')
      : this.selectedLanguage.getValue();

    this.translate.setDefaultLang(languageCode);
    this.translate.use(languageCode);

    if (this.cookieService.isCookieAllowed()) {
      this.localStorage.store('selectedLanguage', languageCode);
    }
  }

  get enableSelectLanguage$() {
    return this.enableSelectLanguage.asObservable();
  }

  setEnableSelectLanguage(flag: boolean) {
    this.enableSelectLanguage.next(flag);
  }
}
