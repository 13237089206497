import { Router } from '@angular/router';
import { LauncherService } from '../../../../launcher/launcher.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import {
  SelectedProjectModel,
  SelectedProjectTypeModel,
} from '../interfaces/dashboard-select-project-rule.model';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { DEPARTMENT } from '../../../../../shared/constants/constants';
import { ProjectRoleId } from '../../../../../shared/enum/project-role.enum';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardSelectDepartmentRule implements DashboardRuleModel {
  private router = GetStaticInjector().get(Router);
  private launcherService = GetStaticInjector().get(LauncherService);
  private viewProjectService = GetStaticInjector().get(ViewProjectService);

  constructor(
    private currentSelectedProjectData: SelectedProjectModel,
    private selectedProject: string,
    private selectedProjectType: SelectedProjectTypeModel,
    private selectedAlbum: SelectedProjectModel,
  ) {}

  matches(): boolean {
    return this.selectedProjectType?.label === DEPARTMENT;
  }

  apply(): void {
    this.viewProjectService.setProjectRole(
      this.currentSelectedProjectData.role,
    );
    this.launcherService.setProjectRole(ProjectRoleId.ROLE_EXEC);
    this.launcherService.setIsGallaryModeStatus(true);
    this.router.navigate(['/dashboard/department/' + this.selectedProject]);
  }
}
