<div
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.actor-approval-report.title'
        | translate
    }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content class="flex-grow-1">
  <div class="db_com imgHistory-divtable">
    <div *ngIf="!arrListActorApprovalReport.length">No data available</div>

    <div class="table-responsive">
      <table
        [hidden]="!arrListActorApprovalReport.length"
        class="fs-report-table"
        mat-table
        [dataSource]="arrListActorApprovalReport"
      >
        <ng-container matColumnDef="job">
          <th mat-header-cell class="pl-2 fs-header-text" *matHeaderCellDef>
            JOB
          </th>
          <td
            mat-cell
            class="pl-2 fs-txt-white-color"
            *matCellDef="let element"
          >
            {{ element.job }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actor">
          <th mat-header-cell class="pl-2 fs-header-text" *matHeaderCellDef>
            ACTOR
          </th>
          <td
            mat-cell
            class="pl-2 fs-txt-white-color"
            *matCellDef="let element"
          >
            {{ element.actor }}
          </td>
        </ng-container>

        <ng-container matColumnDef="setup">
          <th mat-header-cell class="pl-2 fs-header-text" *matHeaderCellDef>
            SETUP
          </th>
          <td
            mat-cell
            class="pl-2 fs-txt-white-color"
            *matCellDef="let element"
          >
            {{ element.setup }}
          </td>
        </ng-container>

        <ng-container matColumnDef="rank">
          <th mat-header-cell class="pl-2 fs-header-text" *matHeaderCellDef>
            STATUS
          </th>
          <td
            mat-cell
            class="pl-2"
            [class.fs-txt-white-grey-color-hover]="!isApproveCheck"
            [class.fs-txt-white-color]="isApproveCheck"
            *matCellDef="let element"
          >
            <span
              *ngIf="
                !(!element.job && element.rank === 'Approved') && isApproveCheck
              "
            >
              {{ element.rank }}
            </span>
            <span *ngIf="!isApproveCheck">
              {{ element.rank }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="totalAssets">
          <th
            mat-header-cell
            class="pl-2 fs-header-text fs-file-column"
            *matHeaderCellDef
          >
            FILE QTY
          </th>
          <td
            mat-cell
            class="pl-2 fs-txt-white-grey-color-hover"
            *matCellDef="let element"
          >
            <span *ngIf="element.totalAssets !== 0">
              {{ element.totalAssets }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="ranked">
          <th
            mat-header-cell
            class="pl-2 fs-header-text fs-count-column"
            *matHeaderCellDef
          >
            COUNT
          </th>
          <td
            mat-cell
            class="pl-2 fs-txt-white-grey-color-hover"
            *matCellDef="let element"
          >
            <span *ngIf="element.ranked !== 0">
              {{ element.ranked === 101010 ? 0 : element.ranked }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="asset">
          <th mat-header-cell class="pl-2 fs-header-text" *matHeaderCellDef>
            FILE
          </th>
          <td
            mat-cell
            class="pl-2"
            [class.fs-txt-white-grey-color-hover]="!isApproveCheck"
            [class.fs-txt-white-color]="isApproveCheck"
            *matCellDef="let element"
          >
            <span *ngIf="element.rank !== 'Approved' && !isApproveCheck">
              {{ element.asset }}
            </span>
            <span *ngIf="isApproveCheck">
              {{ element.asset }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="comment">
          <th
            mat-header-cell
            class="pl-2 fs-header-text fs-comment-column"
            *matHeaderCellDef
          >
            COMMENT
          </th>
          <td
            mat-cell
            class="pl-2 fs-txt-white-color"
            *matCellDef="let element"
          >
            {{ element.comment }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          class="pl-2 fs-txt-white-grey-color-hover"
          *matRowDef="let row; columns: displayedColumns"
          [class.d-none]="
            !row.job && row.rank === 'Approved' && !isApproveCheck
          "
        ></tr>
      </table>
    </div>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <div
    class="d-flex justify-content-between select_talent w-100"
    id="actor-approval-drp"
  >
    <div class="dialog-selectbox-wrapper mt-2 mb-2">
      <mat-select
        class="fs-selectbox-dark"
        required
        disableOptionCentering
        placeholder="select"
        [(ngModel)]="selectedTalent"
        [ngModelOptions]="{ standalone: true }"
        (valueChange)="onChangeListDropdown($event)"
      >
        <mat-option
          class="fs-sm-txt fs-selectbox-options"
          *ngFor="let talent of dataTalents"
          [value]="talent"
        >
          {{ talent.tagName }}
        </mat-option>
      </mat-select>
      <mat-checkbox
        name="isApproveCheck"
        class="fs-checkbox ml-3"
        [(ngModel)]="isApproveCheck"
        (change)="toggleisApproveCheck()"
        [disabled]="isApproveProcessing"
      >
        <span class="cb-title">{{
          'left-menu.tab-bar.sidemenus.administration.actor-approval-report.labels.approved'
            | translate
        }}</span>
      </mat-checkbox>
    </div>

    <div class="d-flex align-items-center">
      <button
        *ngIf="arrListActorApprovalReport.length"
        class="fs-dialog-btn w-auto ml-3"
        type="button"
        (click)="exportReport(exportType.Excel)"
      >
        {{
          'left-menu.tab-bar.sidemenus.administration.actor-approval-report.button.export'
            | translate
        }}
        as xls
      </button>
      <button
        *ngIf="arrListActorApprovalReport.length"
        class="fs-dialog-btn w-auto ml-3"
        type="button"
        (click)="exportReport(exportType.Pdf)"
      >
        {{
          'left-menu.tab-bar.sidemenus.administration.actor-approval-report.button.export'
            | translate
        }}
        as PDF
      </button>
      <button
        type="button"
        class="fs-dialog-btn w-auto ml-3"
        (click)="closeWindow()"
      >
        {{
          'left-menu.tab-bar.sidemenus.administration.actor-approval-report.button.close'
            | translate
        }}
      </button>
    </div>
  </div>
</div>
