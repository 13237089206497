<div id="container" *ngIf="isEnlarge == 'Enlarge'">
  <div class="overlay-left"></div>
  <div class="overlay-right"></div>
  <div id="grid" class="image-cropper" *ngIf="secureStorage">
    <span
      class="rel_span_reject"
      *ngFor="let image of imageUrls; let i = index"
    >
      <img
        [lazySrc]="viewProjectService.getWebImageURL(image, '125')"
        alt=""
        [ngClass]="{ 'selected-image': imageIdNumber == image.id }"
        (click)="onClickImage(image.id)"
        draggable="false"
        data-id="{{ image.id }}"
      />
      <img
        *ngIf="image.exclusive"
        class="exclusiveImage ng-star-inserted"
        src="/assets/images/EXCLUSIVE.png"
      />
      <img
        (click)="onClickImage(image.id)"
        src="/assets/images/Rejected_X_Large.png"
        class="reject_image"
        *ngIf="image.rejected"
      />
    </span>
  </div>

  <div id="grid" class="image-cropper" *ngIf="!secureStorage">
    <span
      class="rel_span_reject"
      *ngFor="let image of imageUrls; let i = index"
    >
      <img
        [lazySrc]="viewProjectService.getWebImageURL(image, '125')"
        alt=""
        [ngClass]="{ 'selected-image': imageIdNumber == image.id }"
        (click)="onClickImage(image.id)"
        draggable="false"
        data-id="{{ image.id }}"
      />
      <img
        *ngIf="image.exclusive"
        class="exclusiveImage ng-star-inserted"
        src="/assets/images/EXCLUSIVE.png"
      />
      <img
        src="/assets/images/Rejected_X_Large.png"
        class="reject_image"
        *ngIf="image.rejected"
        (click)="onClickImage(image.id)"
      />
    </span>
  </div>
  <div></div>
</div>
