import { DownloadManagerService } from './../shared/layout/download-manager/download-manager.service';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { ComponentCanDeactivate } from '../shared/services/reload-guard.service';
import { UserService } from '../shared/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { ViewGalleryResumeModalStateService } from '../shared/services/view-gallery-resume-modal.service';
import { ViewGalleryPriorityPixStateService } from '../shared/services/view-gallery-priority-pix.service';
import { UnsubscriberComponent } from '../shared/components/unsubscriber/unsubscriber.component';
import { AppConfig } from '../app.configuration';
import { DashboardEventBus } from './dashboard-manager/dashboard-event-bus';
import { DashboardEvents } from './dashboard-manager/interfaces/dashboard-rule.model';

@Component({
  selector: 'fs-homepage',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent
  extends UnsubscriberComponent
  implements OnInit, ComponentCanDeactivate, OnDestroy
{
  public idleState = 'Not started.';
  public timedOut = false;
  public lastPing?: Date = null;
  public downloadItems: any = [];

  constructor(
    private userService: UserService,
    private router: Router,
    private idle: Idle,
    private downloadManagerService: DownloadManagerService,
    private dashboardEventBus: DashboardEventBus,
    private viewGalleryResumeModalStateService: ViewGalleryResumeModalStateService,
    private viewGalleryPriorityPixStateService: ViewGalleryPriorityPixStateService,
  ) {
    super();
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(AppConfig.TIME_OUT.IDEL);

    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(AppConfig.TIME_OUT.IDEL_TIME_OUT);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.idleState = 'No longer idle.'));
    idle.onTimeout.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      if (this.downloadItems.length > 0) {
        let downloadRunning = false;
        this.downloadItems.forEach((element, ind) => {
          if (element.downloadedPercent < 100) {
            downloadRunning = true;
          }
        });
        if (downloadRunning) {
          this.reset();
        } else {
          this.userService.setInactivityLogout(true);
          this.router.navigateByUrl('/');
        }
      } else {
        this.userService.setInactivityLogout(true);
        this.router.navigateByUrl('/');
      }
    });
    idle.onIdleStart
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.idleState = "You've gone idle!"));
    idle.onTimeoutWarning
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (countdown) =>
          (this.idleState = 'You will time out in ' + countdown + ' seconds!'),
      );
    this.reset();
  }

  ngOnInit() {
    this.downloadManagerService.downloadItemsGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.downloadItems = res;
      });

    this.dashboardEventBus
      .events$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: DashboardEvents) => {
        event.getData().handle();
      });
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return false;
  }

  ngOnDestroy(): void {
    this.viewGalleryPriorityPixStateService.setHandledPrioritySession(false);
    this.viewGalleryResumeModalStateService.resetHandledResumeSession();
    super.ngOnDestroy();
  }
}
