import { Component, OnInit } from '@angular/core';
import { AssetInfo } from '../../../../../../../shared/models/assetInfo.model';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { takeUntil } from 'rxjs/operators';
import { SelectedImages } from '../../../../../../../shared/models/selectedImages.model';
import { LastSelectedImageStore } from '../../../../../../../shared/store/last-selected-image-store';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'fs-tag-info',
  templateUrl: './tag-info-enlarge.component.html',
  styleUrls: ['./tag-info-enlarge.component.scss'],
})
export class TagInfoEnlargeComponent
  extends UnsubscriberComponent
  implements OnInit
{
  selectedImage: SelectedImages[];
  tagView: any;
  projectId: any;
  isAuditActive = false;
  projectImageData: AssetInfo = new AssetInfo();

  taggingView1Open = false;
  taggingView2Open = false;
  auditTagViewOpen = false;

  tagData: string;
  constructor(
    private viewProjectService: ViewProjectService,
    private lastSelectedImageStore: LastSelectedImageStore,
  ) {
    super();
  }

  ngOnInit() {
    this.viewProjectService.isTaggingView1OpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.taggingView1Open = status;
        this.tagData = '';
        if (this.taggingView1Open) {
          this.tagData = this.lastSelectedImageStore
            .getHeadItem()
            .taggingView1.join('-');
        }
      });

    this.viewProjectService.isTaggingView2OpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.taggingView2Open = status;
        this.tagData = '';
        if (this.taggingView2Open) {
          this.tagData = this.lastSelectedImageStore
            .getHeadItem()
            .taggingView1.join('-');
        }
      });

    this.lastSelectedImageStore
      .getCollection()
      .pipe(takeUntil(this.destroy$))
      .subscribe((lastImage) => {
        if (lastImage.length === 1) {
          this.selectedImage = lastImage;
          this.tagData = '';
          if (this.taggingView1Open) {
            this.tagData = this.lastSelectedImageStore
              .getHeadItem()
              .taggingView1.join('-');
          } else if (this.taggingView2Open) {
            this.tagData = this.lastSelectedImageStore
              .getHeadItem()
              .taggingView2.join('-');
          } else if (this.auditTagViewOpen) {
            this.tagData = this.lastSelectedImageStore
              .getHeadItem()
              .auditTags.join('-');
          }
        }
      });

    this.viewProjectService.tagginView1Data
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.tagData = '';
        if (this.taggingView1Open) {
          this.tagData = this.lastSelectedImageStore
            .getHeadItem()
            .taggingView1.join('-');
        } else if (this.taggingView2Open) {
          this.tagData = this.lastSelectedImageStore
            .getHeadItem()
            .taggingView2.join('-');
        } else if (this.auditTagViewOpen) {
          this.tagData = this.lastSelectedImageStore
            .getHeadItem()
            .auditTags.join('-');
        }
      });

    this.viewProjectService.tagginView2Data
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.tagData = '';
        if (this.taggingView1Open) {
          this.tagData = this.lastSelectedImageStore
            .getHeadItem()
            .taggingView1.join('-');
        } else if (this.taggingView2Open) {
          this.tagData = this.lastSelectedImageStore
            .getHeadItem()
            .taggingView2.join('-');
        } else if (this.auditTagViewOpen) {
          this.tagData = this.lastSelectedImageStore
            .getHeadItem()
            .auditTags.join('-');
        }
      });

    this.viewProjectService.isAuditTaggingViewGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.auditTagViewOpen = status;
        if (!this.taggingView1Open && !this.taggingView2Open) {
          this.tagData = '';
        }
        if (this.auditTagViewOpen) {
          this.tagData = this.lastSelectedImageStore
            .getHeadItem()
            .auditTags.join('-');
        }
      });

    this.viewProjectService.conflictsTagginData
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!this.taggingView1Open && !this.taggingView2Open) {
          this.tagData = '';
        }
        if (this.auditTagViewOpen) {
          this.tagData = this.lastSelectedImageStore
            .getHeadItem()
            .auditTags.join('-');
        }
      });
  }
}
