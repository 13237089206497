import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format, Locale, parse } from 'date-fns';
import { de, ko, es, fr, ja, enUS, ptBR } from 'date-fns/locale';
import { LanguageCode, LanguageMap } from '../models/langualge.model';


const languageMap: LanguageMap = {
  'en-us': enUS,
  'br': ptBR,
  'kr': ko,
  'de': de,
  'fr': fr,
  'ja': ja,
  'es': es,
};

@Pipe({
  name: 'localizeDatePipe',
})
export class LocalizeDatePipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
  ) {}

  transform(value: string, formatString: string): string {
    const languageCode: LanguageCode = this.translate.currentLang as LanguageCode;

    const locale = this.getLanguageCode(languageCode);
    const date = parse(value, formatString, new Date());
    return format(date, formatString, { locale });
  }

  getLanguageCode(languageCode: LanguageCode): Locale {
    return languageMap[languageCode];
  }
}
