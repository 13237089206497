import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'fs-view-project-solo-icon-component',
  templateUrl: './view-project-solo-icon-component.component.html',
  styleUrls: ['./view-project-solo-icon-component.component.scss'],
})
export class ViewProjectSoloIconComponentComponent {
  @Input() imgData;
  @Input() isTaggerRole: boolean;
  @Output() clickOnGroupImages = new EventEmitter();
}
