import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettings } from '../../../../../appSettings/appSettings';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { ApiService } from '../../../../../shared/services/api.service';

@Injectable()
export class PrinttoolService {
  constructor(
    private apiService: ApiService,
    private viewProjectService: ViewProjectService,
  ) {}

  postPrintTool(payload: any): Observable<any> {
    const path = AppSettings.PRINT_TOOL();
    return this.apiService
      .postAssets(this.viewProjectService.getBaseUrl(), path, payload)
      .pipe(
        map((data) => {
          return data;
        }),
      );
  }
}
