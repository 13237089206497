// our root app component
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ApiImageService {
  private webImageUrlGlobal = new BehaviorSubject<string>('');
  private baseUrlGlobal = new BehaviorSubject<string>('');
  private internalBaseUrl = new BehaviorSubject<string>('');

  get baseUrlGlobal$() {
    return this.baseUrlGlobal.getValue();
  }

  get internalBaseUrl$() {
    return this.internalBaseUrl.getValue();
  }

  get webImageUrlGlobal$() {
    return this.webImageUrlGlobal.getValue();
  }

  setWebImageUrlGlobal(url: any) {
    this.webImageUrlGlobal.next(url);
  }

  setBaseUrlGlobal(url: any) {
    this.baseUrlGlobal.next(url);
  }

  setInternalBaseUrl(url: any) {
    this.internalBaseUrl.next(url);
  }
}
