import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageDialogData } from './message-dialog-data.interface';

@Component({
  templateUrl: './message-dialog.component.html',
})
export class MessageDialogComponent {
  title = '';
  message = '';
  iconStyle = '';
  acceptBtn = 'confirmDialog.buttons.yes';
  rejectBtn = 'confirmDialog.buttons.no';
  showAcceptBtn = true;
  showRejectBtn = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: MessageDialogData,
    private matDialogRef: MatDialogRef<MessageDialogComponent>,
  ) {
    this.initializeData(data);
  }

  accept() {
    this.matDialogRef.close(true);
  }

  reject() {
    this.matDialogRef.close(false);
  }

  private initializeData(data: MessageDialogData) {
    this.title = data.title;
    this.message = data.message;
    this.iconStyle = this.getDialogDataValue(data, 'iconStyle', this.iconStyle);
    this.showAcceptBtn = this.getDialogDataValue(
      data,
      'showAcceptBtn',
      this.showAcceptBtn,
    );
    this.showRejectBtn = this.getDialogDataValue(
      data,
      'showRejectBtn',
      this.showRejectBtn,
    );
    this.acceptBtn = this.getDialogDataValue(data, 'acceptBtn', this.acceptBtn);
    this.rejectBtn = this.getDialogDataValue(data, 'rejectBtn', this.rejectBtn);
  }

  private getDialogDataValue<T, K extends keyof T>(
    data: T,
    propName: K,
    defaultProp: T[K],
  ) {
    return data[propName] ?? defaultProp;
  }
}
