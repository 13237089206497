import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable()
export class AppUpdatesService {
  private newFeatureAnnounceVersion = new BehaviorSubject<string>(
    this.localStorage.retrieve('newFeatureAnnounce'),
  );

  constructor(private localStorage: LocalStorageService) {}

  get newFeatureAnnounceVersion$(): Observable<string> {
    return this.newFeatureAnnounceVersion.asObservable();
  }

  setNewFeatureAnnounceVersion(
    version: string,
    canAddToLocalStorage: boolean,
  ): void {
    if (canAddToLocalStorage) {
      this.localStorage.store('newFeatureAnnounce', version);
    }
    this.newFeatureAnnounceVersion.next(version);
  }

  getLocalStorageNewFeatureAnnounceVersion(): string {
    return this.localStorage.retrieve('newFeatureAnnounce');
  }
}
