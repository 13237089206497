import { Pipe, PipeTransform } from '@angular/core';
import {
  fullNameFormatter,
  UserExtended,
} from '../formatters/full-name.formatter';

@Pipe({
  name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
  public transform(user: UserExtended, notExistPlaceholder = '...'): string {
    return fullNameFormatter(user, notExistPlaceholder);
  }
}
