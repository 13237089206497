export enum ImageSize {
  SMALL_JPEG = 'SMALL JPEG',
  MEDIUM_JPEG = 'MEDIUM JPEG',
  LARGE_JPEG = 'LARGE JPEG',
  DNG = 'DNG',
  TIF = 'TIF',
}

export enum ImageTypeSize {
  Small = '1',
  Medium = '2',
  Large = '3',
  DNG = '4',
  TIF = '5',
}

export enum ExpressDownloadSizeType {
  LARGE = 'HIGH RES JPEG',
  RAW = 'RAW',
}
