import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output, ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { PaginationService } from './pagination.service';
import { LauncherService } from '../../../launcher/launcher.service';
import { ViewGalleryService } from '../../project-detail/view-gallery/view-gallery.service';
import { DepartmentService } from '../../../department/department.service';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import { SidebarService } from '../../sidebar-service/sidebar-service';
import { ViewProjectService } from '../../../../shared/services/view-project.service';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from '../../../../shared/services/dialog/dialog.service';
import { PaginationNextSetUpComponentComponent } from './pagination-next-set-up-component/pagination-next-set-up-component.component';
import { PaginationPrevSetUpComponentComponent } from './pagination-prev-set-up-component/pagination-prev-set-up-component.component';
import { FilterEnum } from '../../../../shared/enum/filter.enum';
import { UnsubscriberComponent } from '../../../../shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'fs-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent
  extends UnsubscriberComponent
  implements OnInit
{
  @ViewChild('p') paginationApi;
  currentIndex = 1;
  currentFilter: string;
  @Input() id: string;
  @Input() template: string;
  @Input() totalRecord?: string;
  @Output() pageChange = new EventEmitter();
  lastElement: number;
  isPopstate = false;
  isGallaryMode = false;
  isFavGallaryMode = false;
  currentSetUpID: any;
  setUpData: any;
  setUpId = '';
  setupIndex: any;
  isNextSetUp = false;
  isPreviousSetUp = false;
  of = '';

  constructor(
    private router: Router,
    private paginationService: PaginationService,
    private route: ActivatedRoute,
    private launcherService: LauncherService,
    private viewGalleryService: ViewGalleryService,
    private departmentService: DepartmentService,
    private sidebarService: SidebarService,
    private viewProjectService: ViewProjectService,
    private logger: NGXLogger,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
    private dialogService: DialogService,
  ) {
    super();
    this.of = this.translate.instant('global.of');
  }

  ngOnInit() {
    this.viewProjectService.currentFilterGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentFilterName) => {
        this.currentFilter = currentFilterName;
      });

    this.launcherService.selectedSetUpIdGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedSetUpIdGlobal) => {
        this.currentSetUpID = selectedSetUpIdGlobal;
      });

    this.departmentService.setUpIDGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((setupid) => {
        this.setUpId = setupid;
      });

    this.viewGalleryService.setupsGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.setUpData = data;
      });

    // back Button

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          this.isPopstate = true;
        } else {
          this.isPopstate = false;
        }
      }
    });

    this.paginationService.paginationIndexGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((index) => {
        this.currentIndex = index;
      });

    this.paginationService.paginationTemplateIdGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((id) => {
        if (id !== true) {
          this.id = id;
        }
      });

    this.paginationService.paginationLastIndexGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((lastIndex) => {
        if (lastIndex || this.totalRecord) {
          this.lastElement = this.totalRecord || lastIndex;
        } else {
          this.lastElement = 1;
        }
        this.refreshCurrentPageIndex();
      });

    this.launcherService.isGallaryModeGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((isGallaryMode) => {
        this.isGallaryMode = isGallaryMode;
      });

    this.launcherService.isFavGallaryModeGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((isFavGallaryMode) => {
        this.isFavGallaryMode = isFavGallaryMode;
      });
  }

  refreshCurrentPageIndex() {
    if (this.paginationApi?.getCurrent() && this.lastElement < this.paginationApi.getCurrent()) {
      this.pageChange.emit(this.lastElement);
      this.paginationApi.setCurrent(this.lastElement)
    }
  }

  onClickFirstpage() {
    if (this.currentIndex !== 1) {
      this.paginationService.setPaginationIndex(1);
    } else {
      if (
        (this.isGallaryMode || this.isFavGallaryMode) &&
        this.currentFilter.includes(FilterEnum.Batch)
      ) {
        this.dialogService
          .open(PaginationPrevSetUpComponentComponent, {
            data: {
              gotoPreviousSetUpFn: this.gotoPreviousSetUp,
              gotoLastImageInCurrentSetUpFn: this.gotoLastImageInCurrentSetUp,
            },
            panelClass: [
              'fs-dialog-panel',
              'fs-dialog-size-lg-panel',
              'fs-dialog-link-panel',
            ],
          })
          .subscribe();
      }
    }
    if (this.id !== 'ViewProject' && this.id !== 'EnlargeImage') {
      this.pageChange.emit(1);
    }
  }

  onPageChange(pageNumberEvent, flage?) {
    if (this.pageChange.observers.length > 0) {
      this.pageChange.emit(pageNumberEvent);
    } else {
      this.paginationService.setPaginationIndex(pageNumberEvent);
    }
  }

  onClickLastPage() {
    if (this.currentIndex !== this.lastElement) {
      this.paginationService.setPaginationIndex(this.lastElement);
    } else {
      if (
        (this.isGallaryMode || this.isFavGallaryMode) &&
        this.currentFilter.includes(FilterEnum.Batch)
      ) {
        this.dialogService.open(PaginationNextSetUpComponentComponent, {
          data: {
            gotoNextSetUpFn: this.gotoNextSetUp,
            gotoFirstImageInCurrentSetUpFn: this.gotoFirstImageInCurrentSetUp,
          },
          panelClass: [
            'fs-dialog-panel',
            'fs-dialog-size-lg-panel',
            'fs-dialog-link-panel',
          ],
        });
      }
    }
    if (
      this.id !== 'ViewProject' &&
      this.id !== 'EnlargeImage' &&
      this.totalRecord
    ) {
      this.pageChange.emit(this.totalRecord);
    }
  }

  gotoFirstImageInCurrentSetUp = () => {
    this.paginationService.setPaginationIndex(1);
    this.isNextSetUp = false;
  };

  gotoNextSetUp = () => {
    this.setupIndex = this.viewGalleryService.findCurrentSetUpId(
      this.currentSetUpID,
    );
    if (this.setupIndex !== this.setUpData.setupDTOList.length) {
      this.currentIndex = 1;
      const nextSetUpIndex = this.setupIndex + 1;
      this.setupIndex = this.setupIndex + 1;
      this.viewProjectService.resetProjectData();
      this.launcherService.setSelectedProjectName(
        this.setUpData.setupDTOList[nextSetUpIndex - 1].label,
      );
      this.launcherService.setSelectedSetUpId(
        this.setUpData.setupDTOList[nextSetUpIndex - 1].value,
      );
      this.sidebarService.setAlbumImageData({});
      this.router.navigate([
        '/dashboard/viewproject/' +
          this.viewProjectService.getProjectRole() +
          this.setUpId +
          '/filter/batch:' +
          this.currentSetUpID +
          '/page/0',
      ]);
      setTimeout(() => {
        this.router.navigate([
          '/dashboard/viewproject/' +
            this.viewProjectService.getProjectRole() +
            this.setUpId +
            '/filter/batch:' +
            this.currentSetUpID +
            '/page/1',
        ]);
      }, 100);
    } else {
      this.snackBarService.showSnackBarWarning('No more setup available!');
    }
    this.isNextSetUp = false;
  };

  gotoPreviousSetUp = () => {
    this.setupIndex = this.viewGalleryService.findCurrentSetUpId(
      this.currentSetUpID,
    );
    if (this.setupIndex !== 1) {
      this.currentIndex = 1;
      const previousSetUpIndex = this.setupIndex - 1;
      this.setupIndex = this.setupIndex - 1;
      this.viewProjectService.resetProjectData();
      this.launcherService.setSelectedProjectName(
        this.setUpData.setupDTOList[previousSetUpIndex - 1].label,
      );
      this.launcherService.setSelectedSetUpId(
        this.setUpData.setupDTOList[previousSetUpIndex - 1].value,
      );
      this.sidebarService.setAlbumImageData({});
      this.router.navigate([
        '/dashboard/viewproject/' +
          this.viewProjectService.getProjectRole() +
          this.setUpId +
          '/filter/batch:' +
          this.currentSetUpID +
          '/page/0',
      ]);

      setTimeout(() => {
        this.router.navigate([
          '/dashboard/viewproject/' +
            this.viewProjectService.getProjectRole() +
            this.setUpId +
            '/filter/batch:' +
            this.currentSetUpID +
            '/page/1',
        ]);
      }, 100);
    } else {
      this.snackBarService.showSnackBarWarning('No more setup available!');
    }
    this.isPreviousSetUp = false;
  };

  gotoLastImageInCurrentSetUp = () => {
    this.paginationService.setPaginationIndex(this.lastElement);
    this.isPreviousSetUp = false;
  };

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    if (this.isNextSetUp) {
      this.isNextSetUp = false;
    } else if (this.isPreviousSetUp) {
      this.isPreviousSetUp = false;
    }
  }
}
