<div
  class="fs-popup-content g-popup-wpr d-flex flex-column"
  *ngIf="isGPopupShown1 && selectedImageId == groupPopupId"
>
  <div class="d-flex flex-row mb-2 justify-content-between align-items-center">
    <div class="exclude">
      {{'view-project.group.exclude' | translate}} {{ allSelectedImages.length
      }} {{'view-project.group.images' | translate}}
      <span *ngIf="allSelectedImages.length > 1"> (s) </span>
    </div>
    <span class="g-profile-close pl-1" (click)="closePopup()">
      <i class="fa fa-times-circle"></i>
    </span>
  </div>
  <div class="g-profile-con">
    <div *ngIf="groupedDataCalculated.length === 0; else calculatedTemplate">
      <div
        class="g-profile d-flex align-items-center"
        *ngFor="let tag of tagList2"
      >
        <div class="d-flex justify-content-center">
          <div class="profile-icon d-flex" title="release-from">
            <i
              *ngIf="tag.released"
              (click)="handleReleaseFrom(tag.tagId)"
              class="fa fa-user"
            >
            </i>
          </div>

          <div class="profile-icon d-flex" title="exclude-from">
            <i
              (click)="handleExcludeFrom(tag.tagId)"
              *ngIf="tag.excluded"
              class="fa fa-user-times"
            >
            </i>
          </div>
        </div>

        <div class="profile-name">{{tag.tagName}}</div>
      </div>
    </div>
    <ng-template #calculatedTemplate>
      <div
        class="g-profile d-flex align-items-center"
        *ngFor="let tag of groupedDataCalculated"
      >
        <div class="d-flex justify-content-center">
          <div class="profile-icon d-flex" title="release-from">
            <i
              *ngIf="tag.release"
              (click)="handleReleaseFrom(tag.tagInfo.value)"
              class="fa fa-user"
            >
            </i>
          </div>

          <div class="profile-icon d-flex" title="exclude-from">
            <i
              *ngIf="tag.exclude"
              (click)="handleExcludeFrom(tag.tagInfo.value)"
              class="fa fa-user-times"
            >
            </i>
          </div>
        </div>

        <span class="profile-name"> {{tag.tagInfo.label}} </span>
      </div>
    </ng-template>
  </div>
</div>
