import { NgModule } from '@angular/core';
import { ErrorPageComponent } from './error-page.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [SharedModule],
  declarations: [ErrorPageComponent],

  providers: [],
})
export class ErrorPageModule {}
