<div
  class="ml-1 mr-1"
  *ngIf="
    !isTaggerRole &&
    imgData.publishedTags.length > 1 &&
    imgData.assetType == assetType.IMAGE
  "
>
  <div
    title="{{ 'view-project.solo' | translate }}"
    (click)="clickOnGroupImages.emit({ imgData: imgData, event: $event })"
  >
    <fs-char-icon-component
      [isCharIcon]="true"
      [isChar]="false"
      [isHighlighted]="false"
      [imgData]="imgData"
    >
    </fs-char-icon-component>
    <fs-char-icon-with-border [imgData]="imgData"> </fs-char-icon-with-border>
  </div>
</div>
