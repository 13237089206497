<div
  class="fs-image-panel-container"
  draggable="true"
  (dragstart)="dragImages.emit($event)"
>
  <div
    class="image-container mb-1"
    [class.last-select-border]="
      lastSelectedImageStore.hasEntity(imgData.id) && imageLoaded[ind]
    "
    [class.fs-border-none]="
      blockedHover &&
      !(selectedImagesStore.hasEntity(imgData.id) && imageLoaded[ind])
    "
  >
    <div class="hover-border d-inline-block position-relative">
      <img
        class="image"
        *ngIf="projectImageData && imgData"
        name="imgData.assetName"
        [lazySrc]="viewProjectService.getWebImageURL(imgData, imageDim)"
        (load)="onLoad(ind)"
        onerror="this.src='/assets/images/noImage/no_image.jpg';"
        [class.selected-border]="selectedImagesStore.hasEntity(imgData.id)"
        [class.fs-excluded-img]="withExclusive && imgData.excluded"
        [id]="'target-' + imgData.id.toString()"
      />
      <img
        src="/assets/images/rejected_x_small.png"
        class="reject-image"
        *ngIf="withRejectIcon && rejectedXCondition"
        [class.reject-image-small]="assetSize == '3'"
      />
      <span
        draggable="true"
        class="image-protect"
        (dragstart)="imageProtectDragStart.emit($event)"
        (mousedown)="
          selectImageOnClick.emit({
            selectImg: imgData,
            index: ind,
            event: $event
          })
        "
        (mouseup)="
          selectImageOnClickUP.emit({
            selectImg: imgData,
            index: ind,
            event: $event
          })
        "
      >
        <img draggable="true" src="/assets/images/clear.cache.gif" />
      </span>
      <i
        class="fa fa-play-circle video-image-icon"
        (click)="onClickVideoIcon.emit(imgData)"
      ></i>
    </div>
  </div>
</div>
