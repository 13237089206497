import { Component, Input } from '@angular/core';

@Component({
  selector: 'fs-tag-name-title-component',
  templateUrl: './tag-name-title-component.component.html',
  styleUrls: ['./tag-name-title-component.component.scss'],
})
export class TagNameTitleComponentComponent {
  @Input() auditTags;
}
