import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { TopMenuComponent } from './top-menu.component';
import { TopmenusService } from './top-menu.service';
import { PaginationModule } from '../pagination/pagination.module';
import { GotoComponent } from './goto/goto.component';
import { SendmailComponent } from './sendmail/sendmail.component';
import { DownloadComponent } from './download/download.component';
import { PrinttoolComponent } from './printtool/printtool.component';
import { IFrameComponent } from './iframe/iframe.component';
import { LinkComponent } from './link/link.component';
import { ViewsettingsComponent } from './viewsettings/viewsettings.component';
import { SecondCompareComponent } from './secondlevelcompare/secondcompare.component';
import { DeleteComponent } from './deleteassets/deleteassets.component';
import { IMDoneComponent } from './IMDone/imdone.component';
import { ViewsettingsService } from './viewsettings/viewsettings.service';
import { DeleteassetsService } from './deleteassets/deleteassets.service';
import { SendmailService } from './sendmail/sendmail.service';
import { LinkService } from './link/link.service';
import { PrinttoolService } from './printtool/printtool.service';
import { SharedModule } from '../../../../shared/shared.module';
import { ExpressDownloadComponent } from './express-download/express-download.component';
import { FsVirtualScrollComponent } from '../../../../shared/components/fs-virtual-scroll/fs-virtual-scroll.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  imports: [
    NgxPaginationModule,
    SharedModule,
    PaginationModule,
    MatStepperModule,
    MatProgressBarModule,
  ],
  declarations: [
    TopMenuComponent,
    GotoComponent,
    SendmailComponent,
    DownloadComponent,
    IFrameComponent,
    PrinttoolComponent,
    LinkComponent,
    ViewsettingsComponent,
    SecondCompareComponent,
    DeleteComponent,
    IMDoneComponent,
    ExpressDownloadComponent,
    FsVirtualScrollComponent,
  ],
  providers: [
    TopmenusService,
    ViewsettingsService,
    DeleteassetsService,
    SendmailService,
    LinkService,
    PrinttoolService,
  ],
  exports: [
    TopMenuComponent,
    DeleteComponent,
    SendmailComponent,
    DownloadComponent,
    PrinttoolComponent,
    IFrameComponent,
  ],
})
export class TopMenuModule {}
