<div class="fs-spinner-ball-spin-clockwise">
  <div class="fs-spinner-ball-spin-clockwise-ball"></div>
  <div class="fs-spinner-ball-spin-clockwise-ball"></div>
  <div class="fs-spinner-ball-spin-clockwise-ball"></div>
  <div class="fs-spinner-ball-spin-clockwise-ball"></div>
  <div class="fs-spinner-ball-spin-clockwise-ball"></div>
  <div class="fs-spinner-ball-spin-clockwise-ball"></div>
  <div class="fs-spinner-ball-spin-clockwise-ball"></div>
  <div class="fs-spinner-ball-spin-clockwise-ball"></div>
</div>
