<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{ 'left-menu.tab-bar.advance-search.labels.advanced' | translate }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    aria-hidden="true"
    (click)="closeWindow()"
  >
    <i class="fa fa-times-circle"></i>
  </button>
</div>

<div mat-dialog-content class="d-flex theme-form row">
  <div class="col settings-container">
    <div class="fs-title-header-text mb-1">
      {{ 'left-menu.tab-bar.advance-search.labels.filter-option' | translate }}
    </div>
    <div>
      <div>
        <div *ngFor="let filterOpetion of advanceSearchObj.filterOpetion">
          <mat-checkbox
            name="filterOpetion"
            class="fs-checkbox fs-checkbox-margin"
            [checked]="filterOpetion.isChecked"
            (change)="filterOpetion.isChecked = !filterOpetion.isChecked"
          >
            <span class="cb-title">{{ filterOpetion.trans | translate }}</span>
          </mat-checkbox>
        </div>
      </div>

      <div class="fs-title-header-text mt-2">
        {{
          'left-menu.tab-bar.advance-search.labels.talent-exec-attributes'
            | translate
        }}
      </div>
      <div
        *ngFor="
          let talentExecAttributes of advanceSearchObj.talentExecAttributes
        "
      >
        <div
          *ngIf="
            talentExecAttributes.name === 'Rejected By' &&
            !lockApprovedRestriction
          "
        >
          <mat-checkbox
            name="talentExecAttributes"
            class="fs-checkbox fs-checkbox-margin"
            [disabled]="
              talentExecAttributes.name === 'Not' &&
              !advanceSearchObj.talentExecAttributes[0].isChecked &&
              !advanceSearchObj.talentExecAttributes[1].isChecked &&
              !advanceSearchObj.talentExecAttributes[2].isChecked
            "
            [checked]="talentExecAttributes.isChecked"
            (change)="onChangeTalentExecAttributes(talentExecAttributes)"
          >
            <span class="cb-title">{{
              talentExecAttributes.trans | translate
            }}</span>
          </mat-checkbox>
        </div>

        <div *ngIf="talentExecAttributes.name !== 'Rejected By'">
          <mat-checkbox
            name="talentExecAttributes"
            class="fs-checkbox fs-checkbox-margin"
            [disabled]="
              talentExecAttributes.name === 'Not' &&
              !advanceSearchObj.talentExecAttributes[0].isChecked &&
              !advanceSearchObj.talentExecAttributes[1].isChecked &&
              !advanceSearchObj.talentExecAttributes[2].isChecked
            "
            [checked]="talentExecAttributes.isChecked"
            (change)="onChangeTalentExecAttributes(talentExecAttributes)"
          >
            <span class="cb-title">{{
              talentExecAttributes.trans | translate
            }}</span>
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div *ngIf="!lockApprovedRestriction">
      <div class="fs-title-header-text mt-2">
        {{ 'left-menu.tab-bar.advance-search.labels.apppr-status' | translate }}
      </div>
      <div>
        <div *ngFor="let approvalStatus of advanceSearchObj.approvalStatus">
          <mat-checkbox
            name="approvalStatus"
            class="fs-checkbox fs-checkbox-margin"
            [checked]="approvalStatus.isChecked"
            (change)="approvalStatus.isChecked = !approvalStatus.isChecked"
          >
            <span class="cb-title">{{ approvalStatus.trans | translate }}</span>
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="settings-container">
      <div class="fs-title-header-text mb-1 mt-2">
        {{
          'left-menu.tab-bar.advance-search.labels.talent-appearance'
            | translate
        }}
      </div>
      <div class="mr-2">
        <div *ngFor="let talentAppearance of advanceSearchObj.talentAppearance">
          <mat-checkbox
            name="talentAppearance"
            class="fs-checkbox fs-checkbox-margin"
            [checked]="talentAppearance.isChecked"
            (change)="talentAppearance.isChecked = !talentAppearance.isChecked"
          >
            <span class="cb-title">{{
              talentAppearance.trans | translate
            }}</span>
          </mat-checkbox>
        </div>

        <div *ngIf="tertiary">
          <mat-checkbox
            name="talentAppearance"
            class="fs-checkbox fs-checkbox-margin"
            [checked]="advanceSearchObj.talentAppearanceGroupPlus.isChecked"
            (change)="
              advanceSearchObj.talentAppearanceGroupPlus.isChecked =
                !advanceSearchObj.talentAppearanceGroupPlus.isChecked
            "
          >
            <span class="cb-title">{{
              advanceSearchObj.talentAppearanceGroupPlus.trans | translate
            }}</span>
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="settings-container">
      <div class="fs-title-header-text mt-2">
        {{ 'left-menu.tab-bar.advance-search.labels.other' | translate }}
      </div>
      <div class="d-flex flex-column mr-2">
        <div *ngFor="let other of advanceSearchObj.other">
          <mat-checkbox
            name="other"
            class="fs-checkbox fs-checkbox-margin"
            [checked]="other.isChecked"
            (change)="other.isChecked = !other.isChecked"
          >
            <span class="cb-title">{{ other.trans | translate }}</span>
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>

  <div class="col pull-left">
    <div class="settings-container">
      <div>
        <h5 class="fs-title-header-text mb-1 ml-1">
          {{ 'left-menu.tab-bar.advance-search.labels.metaData' | translate }}
        </h5>
        <div class="fs-title-header-text mt-2 ml-1">
          {{ 'left-menu.tab-bar.advance-search.labels.source' | translate }}
        </div>
        <div class="image_batch mr-2 ml-1">
          <div class="image_batch_li mt-1">
            <span class="select_parent">
              <input
                type="text"
                class="form-control input_select_width imageText mb-1"
                name="batchText"
                [(ngModel)]="advanceSearchObj.source"
              />
            </span>
            <span class="select_parent">
              <div class="fs-title-header-text mb-2">
                {{
                  'left-menu.tab-bar.advance-search.labels.description'
                    | translate
                }}
              </div>
              <input
                type="text"
                class="form-control input_select_width imageText"
                name="batchText"
                [(ngModel)]="advanceSearchObj.description"
              />
            </span>
            <span class="select_parent form-group">
              <div class="mb-1">
                <div class="fs-title-header-text my-1 mt-2">
                  {{
                    'left-menu.tab-bar.advance-search.labels.keywords'
                      | translate
                  }}
                </div>
                <mat-select
                  multiple
                  class="form-control fs-select-multiply fs-selectbox-dark"
                  [(ngModel)]="advanceSearchObj.keywords"
                  [disabled]="!keywordList?.length"
                  (selectionChange)="onSelectionChange()"
                  [panelClass]="'fs-keyword-dropdown-size-panel'"
                >
                  <button
                    class="fs-clear-btn fs-txt-white-grey-color-hover d-flex pl-3 justify-content-start align-items-center"
                    (click)="onClearArray('keywords')"
                  >
                    {{
                      'left-menu.tab-bar.advance-search.clearAll'
                        | translate
                    }}
                  </button>
                  <mat-option
                    class="fs-selectbox-options"
                    *ngFor="let keyword of keywordList"
                    [value]="keyword"
                  >
                    {{ keyword }}
                  </mat-option>
                </mat-select>
              </div>
            </span>

            <span class="select_parent form-group">
              <div class="mb-1">
                <div class="fs-title-header-text my-1 mt-2">
                  {{
                    'left-menu.tab-bar.advance-search.labels.photographer'
                      | translate
                  }}
                </div>
                <mat-select
                  multiple
                  class="form-control fs-select-multiply fs-selectbox-dark"
                  [disabled]="!photographerList?.length"
                  [(ngModel)]="advanceSearchObj.photographer"
                >
                  <button
                    class="fs-clear-btn fs-txt-white-grey-color-hover d-flex pl-3 justify-content-start align-items-center"
                    (click)="onClearArray('photographer')"
                  >
                    {{'left-menu.tab-bar.advance-search.clearAll'| translate}}
                  </button>
                  <mat-option
                    class="fs-selectbox-options fs-checkbox"
                    *ngFor="let photographer of photographerList"
                    [value]="photographer.id"
                  >
                    {{ photographer.name }}
                  </mat-option>
                </mat-select>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="fs-title-header-text mt-2 ml-1">
        {{ 'left-menu.tab-bar.advance-search.labels.image-batch' | translate }}
      </div>
      <div class="image_batch mr-2 ml-1">
        <div
          class="image_batch_li mt-1"
          *ngFor="let imageBatch of advanceSearchObj.imageBatch"
        >
          <mat-checkbox
            name="imageBatch"
            class="fs-checkbox fs-checkbox-margin"
            [checked]="imageBatch.isChecked"
            (change)="onImageBatchChange(imageBatch)"
          >
            <span class="cb-title">{{ imageBatch.trans | translate }}</span>
          </mat-checkbox>

          <span *ngIf="imageBatch.name === 'Image'" class="select_parent">
            <input
              type="text"
              class="form-control input_select_width imageText"
              name="batchText"
              [disabled]="!advanceSearchObj.imageBatch[0].isChecked"
              [(ngModel)]="advanceSearchObj.imageBatch[0].value"
            />
          </span>

          <span class="ml-2" *ngIf="imageBatch.name === 'Batch'">
            <mat-checkbox
              name="imageBatchSort"
              class="fs-checkbox fs-checkbox-margin"
              [checked]="advanceSearchObj.imageBatch[1].isAscSort"
              [disabled]="!advanceSearchObj.imageBatch[1].isChecked"
              (change)="onSortBatches($event)"
            >
              <span class="cb-title">A-Z</span>
            </mat-checkbox>
          </span>

          <span
            *ngIf="imageBatch.name === 'Batch'"
            class="select_parent form-group"
          >
            <!--              TODO: need to test this  line-->
            <div
              [ngClass]="{
                disabled: !advanceSearchObj.imageBatch[1].isChecked
              }"
            >
              <mat-select
                class="form-control fs-selectbox-dark"
                [(ngModel)]="selectedBatch"
                [disabled]="!advanceSearchObj.imageBatch[1].isChecked"
                (valueChange)="filterForImageBatch($event)"
              >
                <mat-option
                  class="fs-sm-txt fs-selectbox-options"
                  *ngFor="let data of batchList"
                  [value]="data.value"
                >
                  {{ data.label }}
                </mat-option>
              </mat-select>
            </div>
          </span>
        </div>
      </div>

      <div class="fs-title-header-text mt-2 ml-1">
        {{ 'left-menu.tab-bar.advance-search.labels.other' | translate }}
      </div>
      <div class="d-flex flex-column mr-2 ml-1">
        <div
          class="image_batch_li"
          *ngFor="let exclusives of advanceSearchObj.exclusives"
        >
          <div
            class="custom select_sib"
            [ngClass]="{
              'exclusives-dropdown-sibling':
                exclusives.name === 'Select Exclusive'
            }"
          >
            <mat-radio-button
              name="exclusives"
              class="mr-3 fs-radio-button"
              [checked]="exclusives.isChecked"
              (change)="onChnageExclusives(exclusives)"
            >
              <span class="fs-sm-txt cb-title">{{
                exclusives.trans | translate
              }}</span>
            </mat-radio-button>
          </div>
          <span
            *ngIf="exclusives.name === 'Select Exclusive'"
            class="select_parent form-group"
          >
            <!--              TODO: need to test this line-->
            <div
              [ngClass]="{
                disabled: !advanceSearchObj.exclusives[1].isChecked
              }"
            >
              <mat-select
                class="fs-selectbox-dark form-control mb-1"
                [disabled]="!advanceSearchObj.exclusives[1].isChecked"
                [value]="advanceSearchObj.exclusiveId"
                (valueChange)="onSelectExclusive($event)"
              >
                <mat-option
                  class="fs-sm-txt fs-selectbox-options"
                  *ngFor="let data of exclusiveList"
                  [value]="data.value"
                >
                  {{ data.label }}
                </mat-option>
              </mat-select>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="col advance_same_width">
    <h5 class="fs-title-header-text mb-1">
      {{ 'left-menu.tab-bar.advance-search.tags.title' | translate }}
    </h5>
    <div class="album-tag-scroll settings-container">
      <div
        class="m-0 mr-2 d-flex justify-content-between"
        *ngFor="let tags of tagList"
      >
        <mat-checkbox
          name="general"
          class="fs-checkbox fs-checkbox-margin"
          [checked]="
            advanceSearchObj.generalTagIds.indexOf(tags.id) !== -1 ||
            advanceSearchObj.publishedTagIds.indexOf(tags.id) !== -1
          "
          (change)="onChangedTag(tags)"
        >
          <span class="cb-title">{{ tags.tagName }}</span>
        </mat-checkbox>
        <span class="cb-right"> {{ tags.imageCount }}</span>
      </div>
    </div>
  </div>

  <div class="col advance_same_width" *ngIf="projectAlbumList.length > 0">
    <div class="fs-title-header-text mb-1">
      {{ 'left-menu.tab-bar.advance-search.albums.projectAlbum' | translate }}
    </div>
    <div class="album-tag-scroll settings-container">
      <div
        class="m-0 mr-2 d-flex justify-content-between"
        *ngFor="let album of projectAlbumList | sortBy : 'value'"
      >
        <mat-checkbox
          name="album"
          class="fs-checkbox fs-checkbox-margin"
          [checked]="advanceSearchObj.projectAlbum.indexOf(album.id) !== -1"
          (change)="onChangedProjectAlbum(album)"
        >
          <span class="cb-title">{{ album.value | slice : 0 : 25 }}</span>
        </mat-checkbox>
        <span class="cb-right">{{ album.assetCount }}</span>
      </div>
    </div>
  </div>

  <div
    class="col pull-left advance_same_width"
    *ngIf="customAlbumList.length > 0"
  >
    <div class="fs-title-header-text mb-1">
      {{ 'left-menu.tab-bar.advance-search.albums.title' | translate }}
    </div>
    <div class="album-tag-scroll settings-container">
      <div
        class="m-0 mr-2 d-flex justify-content-between"
        *ngFor="let album of customAlbumList | sortBy : 'value'"
      >
        <mat-checkbox
          name="album"
          class="fs-checkbox fs-checkbox-margin"
          [checked]="advanceSearchObj.albums.indexOf(album.id) !== -1"
          (change)="onChangedAlbum(album)"
        >
          <span class="cb-title">{{ album.value | slice : 0 : 25 }}</span>
        </mat-checkbox>
        <span class="cb-right">{{ album.assetCount }}</span>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-between">
  <div class="d-flex mf-sngle col">
    <div class="mr-3">
      <mat-radio-group class="d-flex flex-row">
        <mat-radio-button
          class="mr-3 fs-radio-button"
          name="ERA"
          *ngFor="
            let excludedAsets of adFilterOption.excludedAssets;
            index as ind
          "
          [checked]="
            advanceSearchObj.excludedReleasedStatus === excludedAsets.id
          "
          (change)="onExcludedStatusChange(excludedAsets.name)"
          [value]="excludedAsets.id"
        >
          <span class="cb-title fs-sm-txt">
            {{
              'left-menu.tab-bar.advance-search.excludedAsets' +
                '.' +
                excludedAsets.key | translate
            }}
          </span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="d-flex mf-sngle col pl-0 pr-0" style="text-align: center">
    <ng-container *ngIf="!lockApprovedRestriction">
      <mat-radio-group class="d-flex flex-row">
        <mat-radio-button
          class="mr-3 fs-radio-button"
          name="PLA"
          *ngFor="let statusAsets of adFilterOption.statusAssets; index as ind"
          [checked]="advanceSearchObj.status === statusAsets.id"
          (change)="onStatusChange(statusAsets.name)"
          [value]="statusAsets.id"
        >
          <span class="cb-title fs-sm-txt">
            {{
              'left-menu.tab-bar.advance-search.statusAsets' +
                '.' +
                statusAsets.key | translate
            }}
          </span>
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>
  </div>

  <div class="d-flex mf-sngle ftr-btns col pr-2 justify-content-end">
    <button
      type="button"
      data-dismiss="modal"
      aria-hidden="true"
      class="fs-dialog-btn w-auto ml-2"
      (click)="closeWindow()"
    >
      {{ 'left-menu.tab-bar.advance-search.buttons.cancel' | translate }}
    </button>

    <button
      type="reset"
      class="fs-dialog-btn w-auto ml-2"
      (click)="onClikedReset()"
    >
      {{ 'left-menu.tab-bar.advance-search.buttons.reset' | translate }}
    </button>

    <button
      type="submit"
      data-dismiss="modal"
      class="fs-dialog-btn w-auto ml-2"
      (click)="onClikedSubmit()"
    >
      {{ 'left-menu.tab-bar.advance-search.buttons.apply' | translate }}
    </button>
  </div>
</div>
