import { DashboardSearchDepartmentWideSingOffRule } from '../rules/dashboard-search-department-wide-sing-off-rule';
import { DashboardSearchDepartmentExecTalentProjectRule } from '../rules/dashboard-search-department-exec-talent-project-rule';
import { DashboardSearchProjectSetupRule } from '../rules/dashboard-search-project-setup-rule';
import { DashboardSearchWdtvTalentRule } from '../rules/dashboard-search-wdtv-talent-rule';
import { DashboardSearchExecProjectRule } from '../rules/dashboard-search-exec-project-rule';
import { SearchProjectModel } from '../interfaces/dashboard-search-project-rule.model';
import { DashboardSearchDepartmentRule } from '../rules/dashboard-search-department-rule';
import { DashboardSearchDepartmentTalentPkoRule } from '../rules/dashboard-search-department-talent-pko-rule';
import { DashboardSearchDepartmentAlbumViewerRule } from '../rules/dashboard-search-department-album-viewer-rule';

type DashboardEventsUnion =
  | DashboardSearchDepartmentWideSingOffRule
  | DashboardSearchDepartmentExecTalentProjectRule
  | DashboardSearchProjectSetupRule
  | DashboardSearchWdtvTalentRule
  | DashboardSearchExecProjectRule
  | DashboardSearchDepartmentRule
  | DashboardSearchDepartmentTalentPkoRule
  | DashboardSearchDepartmentAlbumViewerRule;

export class DashboardSearchProjectRuleset {
  ruleSet: DashboardEventsUnion[];

  constructor(
    private fromDepartment: boolean,
    private selectedProjectId: number,
    private selectedProjectData: SearchProjectModel,
  ) {
    this.ruleSet = [
      new DashboardSearchDepartmentRule(
        fromDepartment,
        selectedProjectId,
        selectedProjectData,
      ),
      new DashboardSearchDepartmentWideSingOffRule(
        fromDepartment,
        selectedProjectId,
        selectedProjectData,
      ),
      new DashboardSearchDepartmentTalentPkoRule(
        fromDepartment,
        selectedProjectId,
        selectedProjectData,
      ),
      new DashboardSearchDepartmentAlbumViewerRule(
        fromDepartment,
        selectedProjectId,
        selectedProjectData,
      ),
      new DashboardSearchDepartmentExecTalentProjectRule(
        fromDepartment,
        selectedProjectId,
        selectedProjectData,
      ),
      new DashboardSearchProjectSetupRule(
        fromDepartment,
        selectedProjectId,
        selectedProjectData,
      ),
      new DashboardSearchWdtvTalentRule(
        fromDepartment,
        selectedProjectId,
        selectedProjectData,
      ),
      new DashboardSearchExecProjectRule(
        fromDepartment,
        selectedProjectId,
        selectedProjectData,
      ),
    ];
  }

  apply(): void {
    for (const rule of this.ruleSet) {
      if (rule.matches()) {
        rule.apply();
      }
    }
  }
}
