import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LauncherService } from '../launcher.service';
import { DepartmentService } from '../../department/department.service';
import { Group } from '../../../shared/models/projectlist.model';
import { LoaderService } from '../../../shared/services/loader.service';
import { SidebarService } from '../../project/sidebar-service/sidebar-service';
import { ViewProjectService } from '../../../shared/services/view-project.service';
import { takeUntil } from 'rxjs/operators';
import { UnsubscriberComponent } from '../../../shared/components/unsubscriber/unsubscriber.component';
import { DashboardFavouriteProjectEvent } from '../../dashboard-manager/dashboard-manager-rules/dashboard-favourite-project/events/dashboard-favourite-project-event';
import { DashboardEventBus } from '../../dashboard-manager/dashboard-event-bus';
import { DashboardFavouriteProjectHandler } from '../../dashboard-manager/dashboard-manager-rules/dashboard-favourite-project/handler/dashboard-favourite-project-handler';
import { DashboardFavouriteIconHandler } from '../../dashboard-manager/dashboard-manager-rules/dashboard-favourite-project/handler/dashboard-favourite-icon-handler';

@Component({
  selector: 'fs-favourite-project',
  templateUrl: './favourite-project.component.html',
  styleUrls: ['./favourite-project.component.scss'],
})
export class FavouriteProjectComponent
  extends UnsubscriberComponent
  implements OnInit
{
  favouriteProjects = [];
  @Input() groupedProject: Group[];
  @Output() updatedFavStaus = new EventEmitter();

  constructor(
    private router: Router,
    private launcherService: LauncherService,
    private loaderService: LoaderService,
    private sidebarService: SidebarService,
    private viewProjectService: ViewProjectService,
    private departmentService: DepartmentService,
    private dashboardEventBus: DashboardEventBus,
  ) {
    super();
  }

  ngOnInit() {
    this.launcherService.favProjectListGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((favProjects) => {
        this.favouriteProjects = favProjects;
      });
  }

  saveFavouriteSeq() {
    const sequence = this.favouriteProjects.map((favProj) => {
      return favProj.value;
    });

    this.loaderService.displayLoader(true);
    this.launcherService
      .saveFavSeq(sequence)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.loaderService.displayLoader(false);
        }
      });
  }

  removeFromFavList(dataObj) {
    this.dashboardEventBus.emit(
      new DashboardFavouriteProjectEvent(
        new DashboardFavouriteIconHandler(
          false,
          dataObj,
          this.groupedProject,
          this.updatedFavStaus,
        ),
      ),
    );
  }

  navigateToProject(selectedProject) {
    this.dashboardEventBus.emit(
      new DashboardFavouriteProjectEvent(
        new DashboardFavouriteProjectHandler(selectedProject),
      ),
    );
  }
}
