import { Pipe, PipeTransform } from '@angular/core';
import { FilterEnum } from '../enum/filter.enum';
import { AlbumList } from '../../dashboard/department/department.model';

@Pipe({ name: 'filterNameDisplay' })
export class FilterNameDisplayPipe implements PipeTransform {
  transform(
    filterString: string,
    albumList: AlbumList[],
    taggingList: any[],
  ): string {
    return this.prepareFilterName(filterString, albumList, taggingList);
  }

  prepareFilterName(currentFilter, albumList, taggingList): string {
    const splitFIlter = currentFilter.split(':');

    if (splitFIlter.length > 1) {
      if (splitFIlter[0] === FilterEnum.Album) {
        return this.forAlbumList(splitFIlter, albumList);
      } else if (splitFIlter[0] === FilterEnum.Taggingwith) {
        return this.forTaggingList(splitFIlter, taggingList);
      } else if (splitFIlter[0] === FilterEnum.General) {
        return this.forGeneral(splitFIlter);
      } else if (splitFIlter[0] === FilterEnum.Tagging) {
        return this.forTagging(splitFIlter);
      }
    } else if (splitFIlter.length === 1) {
      return 'Show All';
    }
  }

  forAlbumList(strSplitedString, albumList): string {
    const index = albumList
      .map(function (data: { id: number }) {
        return data.id;
      })
      .indexOf(Number(strSplitedString[1]));
    return albumList[index].value;
  }

  forTaggingList(strSplitedString, taggingList): string {
    const index = taggingList
      .map(function (data: { id: number }) {
        return data.id;
      })
      .indexOf(Number(strSplitedString[1]));
    return taggingList[index].tagName;
  }

  forGeneral(strSplitedString): string {
    return strSplitedString[1];
  }

  forTagging(strSplitedString): string {
    return strSplitedString[1];
  }
}
