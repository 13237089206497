import { WebImageSource } from './enum/WebImageSource';

export class UserDetails {
  id: string;
  login: string;
  firstName: string;
  lastName: string;
  email: string;
  imageUrl: string;
  activated: boolean;
  langKey: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;

  authorities: string[];
  userProfileDTO: UserProfileDTO;
}

class UserProfileDTO {
  id: string;
  streetAddress: string;
  zipCode: string;
  city: string;
  stateProvince: string;
  tagger: boolean;
  country: string;
  phone: string;
  userTypeId: string;
  userTypeValue: string;
  urlSecureAccess: boolean;
  webImageSource: WebImageSource;
}
