export class Department {
  groupName: string;
  groupLogo: string;
  items: Items[];
  groupId: number;
}

export class Items {
  value: number;
  label: string;
  fav?: boolean;
  createdAt: string;
  setupCount: number;
  useSetup: boolean;
  groupName?: string;
  groupId?: number;
}

export class AlbumList {
  item: Items;
  element: Department;
}
