import { ViewGalleryService } from './../../../project-detail/view-gallery/view-gallery.service';
import { LauncherService } from './../../../../launcher/launcher.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { SidebarService } from '../../../sidebar-service/sidebar-service';
import { PaginationService } from '../../pagination/pagination.service';
import { AlbumsService } from '../menus/albums/albums.service';
import { NGXLogger } from 'ngx-logger';
import { AppStateService } from '../../../../../shared/services/app-state.service';
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';
import { UserDataService } from '../../../../../shared/services/userdata.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { Modalstatusservice } from '../../../../../shared/services/modalstatus.service';
import { UserService } from '../../../../../shared/services/user.service';
import { ConstantsGlobal } from '../../../../../shared/constants/constants';
import { UserRole } from '../../../../../shared/enum/user-role.enum';
import { Utils } from '../../../../../shared/utils/utils';
import { ConflictsComponent } from '../menus/audit/conflicts/conflicts.component';
import { takeUntil } from 'rxjs/operators';
import { LastSelectedImageStore } from '../../../../../shared/store/last-selected-image-store';
import { SelectedImagesStore } from '../../../../../shared/store/selected-images-store';
import { FilterEnum } from '../../../../../shared/enum/filter.enum';
import { FsStateDialogs } from '../../../../../shared/services/dialog/dialog-state';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import DialogStateListenerData = FsStateDialogs.DialogStateListenerData;
import { ProjectRole } from '../../../../../shared/enum/project-role.enum';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';
import { AdvanceSearchService } from '../advancesearch/advancesearch.service';

@Component({
  selector: 'fs-customfilter',
  templateUrl: './customfilter.component.html',
  styleUrls: ['./customfilter.component.scss'],
})
export class CustomFilterComponent
  extends UnsubscriberComponent
  implements OnInit
{
  selectedOpt: any = this.translate.instant('left-menu.filter.options.showAll');

  customAlbumList = [];
  projectAlbumList = [];
  dataTaggingWith = [];
  isLeftMenuPartShown = true;
  albumList_cont: any[];
  staticFilter = this.translate.instant('left-menu.filter.options.showAll');
  assetsIds: any;
  noOfImages: any;
  lastSelectedImageOnRestat: any;

  isGallaryMode = false;
  isFavGallaryMode = false;
  lockApprovedRestriction = false;
  staticFilterArray = [
    { name: 'Show All', key: 'showAll' },
    { name: 'Locked Approved', key: 'lockedApproved' },
  ];

  dataGeneral = [
    { name: 'Approved', key: 'Approved' },
    { name: 'Rejected', key: 'Rejected' },
    { name: 'Solo', key: 'Solo' },
    { name: 'Group', key: 'Group' },
    { name: 'Retouch Comment', key: 'RetouchComment' },
    { name: 'Image Notes', key: 'ImageNotes' },
  ];

  viewFilter = false;

  // Back route
  currentFilter: any;
  currentPageNumber = 1;
  currentFilterAlbumId: number;

  dataTagging = [];
  projectAuthority: string;
  userAuthority: string;
  customeFilter = [
    { name: 'Custom Filter', key: 'CustomFilter', visible: true },
    { name: 'Setup Filter', key: 'Setupfilter', visible: false },
  ];

  gotoSettings: any = {};
  restartDataDTO: any;
  constantsGlobalData: any;
  setUpData: any;

  // Project Permission
  viewUntaggedAccess = false;

  constructor(
    private router: Router,
    private activRoute: ActivatedRoute,
    private sidebarService: SidebarService,
    private albumsService: AlbumsService,
    private userDataService: UserDataService,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private modalStatusService: Modalstatusservice,
    private userService: UserService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private translate: TranslateService,
    private paginationService: PaginationService,
    private launcherService: LauncherService,
    private viewGalleryService: ViewGalleryService,
    private logger: NGXLogger,
    private advanceSearchService: AdvanceSearchService,
    private appStateService: AppStateService,
    private dialogService: DialogService,
    private lastSelectedImageStore: LastSelectedImageStore,
    private selectedImagesStore: SelectedImagesStore,
  ) {
    super();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority) => {
        this.userAuthority = userAuthority;
      });

    this.launcherService.isGallaryModeGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((isGallaryMode) => {
        this.isGallaryMode = isGallaryMode;
      });

    this.launcherService.isFavGallaryModeGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((isFavGallaryMode) => {
        this.isFavGallaryMode = isFavGallaryMode;
      });

    if (this.isGallaryMode || this.isFavGallaryMode) {
      this.customeFilter[1].visible = true;
    } else {
      this.customeFilter[1].visible = false;
    }

    if (this.userAuthority === UserRole.ROLE_ADMIN) {
      this.dataTagging = [
        { name: 'Unpublished', key: 'Unpublished', count: 0 },
        {
          name: 'Tagged/Unsubmitted-Tagger #1',
          key: 'Tagged/Unsubmitted-Tagger#1',
        },
        {
          name: 'Tagged/Unsubmitted-Tagger #2',
          key: 'Tagged/Unsubmitted-Tagger#2',
        },
        {
          name: 'Unpublished/Untagged-Tagger #1',
          key: 'Unpublished/Untagged-Tagger#1',
        },
        {
          name: 'Unpublished/Untagged-Tagger #2',
          key: 'Unpublished/Untagged-Tagger#2',
        },
        { name: 'Hidden', key: 'Hidden' },
        { name: 'Published', key: 'Published' },
      ];
    } else if (
      this.userAuthority === UserRole.ROLE_EXTERNAL &&
      this.projectAuthority === ProjectRole.ROLE_EXEC
    ) {
      this.dataTagging = [
        { name: 'Unpublished', key: 'Unpublished', count: 0 },
      ];
    }

    this.viewProjectService.restartDataDTO
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.restartDataDTO = data;
      });

    this.activRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe((parameters) => {
        if (parameters.filterId) {
          this.currentFilter = parameters.filterId;
        }
      });

    this.viewProjectService.currentFilterGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentFilterName) => {
        this.currentFilter = currentFilterName;
        if (this.currentFilter === FilterEnum.Batch) {
          this.selectedOpt = this.translate.instant(
            'left-menu.filter.Setupfilter',
          );
        } else if (this.currentFilter.includes('batch')) {
          this.selectedOpt = this.translate.instant(
            'left-menu.filter.Setupfilter',
          );
        }
        this.findSelectedAlbumOpt();
      });

    // Restart Data
    this.viewProjectService.restartDataAlbum
      .pipe(takeUntil(this.destroy$))
      .subscribe((restartFilter) => {
        const filter = restartFilter.split(':');
        const index = this.customAlbumList
          .map(function (data: any) {
            return data.id;
          })
          .indexOf(Number(filter[1]));
        if (index !== -1) {
          if (
            this.selectedOpt !==
            this.customAlbumList[index].value +
              ' (' +
              this.customAlbumList[index].assetCount +
              ')'
          ) {
            this.clickOnAlbum(
              this.customAlbumList[index],
              undefined,
              this.restartDataDTO.pageNo,
            );
          }
        } else {
          this.callShowAll();
        }
      });

    this.viewProjectService.restartDataCustomFilter
      .pipe(takeUntil(this.destroy$))
      .subscribe((restartFilter) => {
        this.currentFilter = restartFilter;
        this.callShowAll();
      });

    this.viewProjectService.restartDataAdvanceSearch
      .pipe(takeUntil(this.destroy$))
      .subscribe((restartFilter) => {
        const filter = restartFilter.split(':');
        this.onBackAdvanceSearch(filter[1], this.restartDataDTO.pageNo);
      });

    this.viewProjectService.restartDataTaggingWith
      .pipe(takeUntil(this.destroy$))
      .subscribe((restartFilter) => {
        const filter = restartFilter.split(':');
        const index = this.dataTaggingWith
          .map(function (data: any) {
            return data.id;
          })
          .indexOf(Number(filter[1]));
        if (
          index !== -1 &&
          this.selectedOpt !== this.dataTaggingWith[index].tagName
        ) {
          this.clickOnTaggingWith(
            this.dataTaggingWith[index],
            undefined,
            this.restartDataDTO.pageNo,
          );
        } else {
          this.callShowAll();
        }
      });

    this.viewGalleryService.setupsGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.setUpData = data;
      });

    this.viewProjectService.restartDataGeneral
      .pipe(takeUntil(this.destroy$))
      .subscribe((restartFilter) => {
        const filter = restartFilter.split(':');
        switch (filter[1]) {
          case 'locked-approved':
            this.selectedOpt = this.translate.instant(
              'left-menu.filter.options.lockedApproved',
            );
            this.callLockedApproved(this.restartDataDTO.pageNo);
            break;

          case 'lockedapproved':
            this.selectedOpt = this.translate.instant(
              'left-menu.filter.options.lockedApproved',
            );
            this.callLockedApproved(this.restartDataDTO.pageNo);
            break;

          case 'approved':
            this.callDataFilterAPI(
              'Approved',
              'approved',
              this.restartDataDTO.pageNo,
            );
            break;
          case 'rejected':
            this.callDataFilterAPI(
              'Rejected',
              'rejected',
              this.restartDataDTO.pageNo,
            );
            break;
          case 'solo':
            this.callDataFilterAPI('Solo', 'solo', this.restartDataDTO.pageNo);
            break;
          case 'group':
            this.callDataFilterAPI(
              'Group',
              'group',
              this.restartDataDTO.pageNo,
            );
            break;
          case 'retouch':
            this.callDataFilterAPI(
              'Retouch Comment',
              'retouch',
              this.restartDataDTO.pageNo,
            );
            break;
          case 'notes':
            this.callDataFilterAPI(
              'Image Notes',
              'notes',
              this.restartDataDTO.pageNo,
            );
            break;
          case 'hidden':
            this.callDataFilterAPI(
              'Hidden',
              'hidden',
              this.restartDataDTO.pageNo,
            );
            break;
          case 'published':
            this.callDataFilterAPI(
              'Published',
              'published',
              this.restartDataDTO.pageNo,
            );
            break;
          case 'unpublished':
            this.callDataFilterAPI(
              'Unpublished',
              'unpublished',
              this.restartDataDTO.pageNo,
            );
            break;
          default:
            break;
        }
      });

    this.viewProjectService.restartTaggingView1
      .pipe(takeUntil(this.destroy$))
      .subscribe((restartFilter) => {
        const filter = restartFilter.split('-');
        this.onClickTagListRestart(filter[1], 1);
      });

    this.viewProjectService.restartTaggingView2
      .pipe(takeUntil(this.destroy$))
      .subscribe((restartFilter) => {
        const filter = restartFilter.split('-');
        this.onClickTagListRestart(filter[1], 2);
      });

    this.viewProjectService.restartDataTagging
      .pipe(takeUntil(this.destroy$))
      .subscribe((restartFilter) => {
        const filter = restartFilter.split(':');
        if (filter[1] === 'unsubmitted-untagged-tagger-1') {
          this.callDataTaggingAPI(
            'Unpublished/Untagged-Tagger #1',
            'unsubmitted-untagged-tagger-1',
            this.restartDataDTO.pageNo,
          );
        } else if (filter[1] === 'unsubmitted-untagged-tagger-2') {
          this.callDataTaggingAPI(
            'Unpublished/Untagged-Tagger #2',
            'unsubmitted-untagged-tagger-2',
            this.restartDataDTO.pageNo,
          );
        } else if (filter[1] === 'unsubmitted-tagger-1') {
          this.callDataTaggingAPI(
            'Tagged/Unsubmitted-Tagger #1',
            'unsubmitted-tagger-1',
            this.restartDataDTO.pageNo,
          );
        } else if (filter[1] === 'unsubmitted-tagger-2') {
          this.callDataTaggingAPI(
            'Tagged/Unsubmitted-Tagger #2',
            'unsubmitted-tagger-2',
            this.restartDataDTO.pageNo,
          );
        }
      });

    this.viewProjectService.restartDataAll
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.selectedOpt = this.translate.instant(
          'left-menu.filter.options.showAll',
        );
        if (this.restartDataDTO) {
          this.callShowAll(
            this.restartDataDTO.projectId,
            this.restartDataDTO.filter,
            this.noOfImages,
            this.restartDataDTO.pageNo,
            this.restartDataDTO.assetId,
          );
        } else {
          this.callShowAll(
            this.viewProjectService.getProjectId(),
            FilterEnum.ShowAll,
            this.noOfImages,
            1,
            0,
          );
        }
      });

    this.selectedOpt = this.staticFilter;

    this.sidebarService.albumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.customAlbumList = Utils.getValuesByKey(albumList.albums, 'id');
          if (albumList.projectAlbum) {
            this.projectAlbumList = albumList.projectAlbum.children;
          } else {
            this.projectAlbumList = [];
          }
        }
      });

    this.sidebarService.updatedAlbumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.customAlbumList = Utils.getValuesByKey(albumList, 'id');
        }
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          if (this.userAuthority === UserRole.ROLE_ADMIN) {
            this.dataTagging = [
              {
                name: 'Unpublished',
                key: this.translate.instant(
                  'left-menu.filter.dataTagging.Unpublished',
                ),
                count: 0,
              },
              {
                name: 'Tagged/Unsubmitted-Tagger #1',
                key: this.translate.instant(
                  'left-menu.filter.dataTagging.TaggedUnsubmitted1',
                ),
              },
              {
                name: 'Tagged/Unsubmitted-Tagger #2',
                key: this.translate.instant(
                  'left-menu.filter.dataTagging.TaggedUnsubmitted2',
                ),
              },
              {
                name: 'Unpublished/Untagged-Tagger #1',
                key: this.translate.instant(
                  'left-menu.filter.dataTagging.Unpublished1',
                ),
              },
              {
                name: 'Unpublished/Untagged-Tagger #2',
                key: this.translate.instant(
                  'left-menu.filter.dataTagging.Unpublished2',
                ),
              },
              {
                name: 'Hidden',
                key: this.translate.instant(
                  'left-menu.filter.dataTagging.Hidden',
                ),
              },
              {
                name: 'Published',
                key: this.translate.instant(
                  'left-menu.filter.dataTagging.Published',
                ),
              },
            ];
            this.dataTaggingWith = res.projectTagListDTO;
          } else if (
            this.userAuthority === UserRole.ROLE_EXTERNAL &&
            this.projectAuthority === ProjectRole.ROLE_EXEC
          ) {
            this.lockApprovedRestriction =
              res.permissionDTO.lockApprovedRestriction;
            if (this.lockApprovedRestriction) {
              this.dataGeneral = this.dataGeneral.filter(
                (item) => item.name !== 'Rejected',
              );
            }
            this.dataTagging = [
              { name: 'Unpublished', key: 'Unpublished', count: 0 },
            ];
            if (res.permissionDTO.viewSensitiveAccess) {
              this.dataTaggingWith = res.projectTagListDTO;
            } else {
              this.dataTaggingWith = res.projectTagListDTO.filter(
                (item) => item.tagName !== 'Sensitive',
              );
            }
          }
          try {
            this.dataTagging[0].count = res.unpublishedAssets;
            this.noOfImages = res.viewSettingDTO.imagePerPage;
            this.viewUntaggedAccess = res.permissionDTO.viewUntaggedAccess;
          } catch {
            // Update
          }
        }
      });

    this.sidebarService.selectedAlbumName
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumName) => {
        if (!Utils.isObjectEmpty(albumName)) {
          this.selectedOpt =
            albumName.value + ' (' + albumName.assetCount + ')';
        }
      });

    this.viewProjectService.isLeftMenuOpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((leftmenuState) => {
        this.isLeftMenuPartShown = leftmenuState;
      });

    this.handleModalStatus();

    this.viewProjectService.isProjectDataLoadedFromNoteReportsGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.selectedOpt = this.translate.instant(
            'left-menu.filter.CustomFilter',
          );
        }
      });
  }

  onClickTagListRestart(id, tagNumber) {
    this.loaderService.displayLoader(true);
    const filter = 'tagging:' + tagNumber + '-' + id;

    this.viewProjectService
      .callProjectImageIDs(this.viewProjectService.getProjectId(), filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res1) => {
          if (res1) {
            this.currentFilter = filter;
            const assetsIds = Utils.getImageIdsBasedOnPage(
              this.restartDataDTO.pageNo,
              this.noOfImages,
              res1,
            );
            const data = {
              assetIds: assetsIds,
              projectId: this.viewProjectService.getProjectId(),
              filter: filter,
            };
            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  if (resData.assetInfoDTOList.length > 0) {
                    this.loaderService.displayLoader(false);
                    this.viewProjectService.setCurrentFilter(
                      this.currentFilter,
                    );
                    this.router.navigate([
                      Utils.prepareFilterNavigationURL(
                        this.viewProjectService.getProjectRole(),
                        this.viewProjectService.getProjectId(),
                        this.currentFilter,
                        this.restartDataDTO.pageNo,
                      ),
                    ]);
                    this.selectedImagesStore.clear();
                    this.sidebarService.setAlbumImageData(resData);
                    if (this.restartDataDTO) {
                      if (
                        this.restartDataDTO.assetId !== 0 &&
                        this.restartDataDTO.assetId
                      ) {
                        this.lastSelectedImageOnRestat =
                          this.getLastSelectedImagesOnRestatData(
                            resData,
                            this.restartDataDTO.assetId,
                          );
                        this.lastSelectedImageStore.set(
                          this.lastSelectedImageOnRestat[0],
                        );
                      }
                    }
                  } else {
                    this.alertCustom(
                      this.translate.instant('view-project.alert2'),
                    );
                  }
                  this.loaderService.displayLoader(false);
                  this.viewProjectService.setExecutiveAlbum(false);
                },
                (error) => {
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(this.translate.instant('view-project.alert2'));
          }
        },
        (error) => {
          this.loaderService.displayLoader(false);
        },
      );
  }

  callAlbumDataAPI(filter: any) {
    this.albumsService
      .getAlbumGroupInfo(this.viewProjectService.getProjectId())
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (Object.keys(res).length !== 0) {
          this.sidebarService.setAlbumList(res);
          this.callUserDataInfoAPI(res);

          this.customAlbumList = Utils.getValuesByKey(res.albums, 'id');
          const index = this.customAlbumList
            .map(function (data: any) {
              return data.id;
            })
            .indexOf(Number(filter[1]));
          if (
            this.selectedOpt !==
            this.customAlbumList[index].value +
              ' (' +
              this.customAlbumList[index].assetCount +
              ')'
          ) {
            this.clickOnAlbum(this.customAlbumList[index]);
          }
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  callUserDataInfoAPI(data) {
    this.userDataService
      .getUserData(this.viewProjectService.getProjectId(), data.users)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.albumsService.setUserDataInfo(value);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  handleModalStatus() {
    this.appStateService.noConflicts$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ loaded }) => {
        if (loaded) {
          this.selectedOpt = this.customeFilter[0].name;
        }
      });

    this.appStateService.bypass2ndtagger$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ loaded }) => {
        if (loaded) {
          this.selectedOpt = this.customeFilter[0].name;
        }
      });

    this.appStateService.goTOFileName$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ loaded }) => {
        if (loaded) {
          this.selectedOpt = this.translate.instant(
            'left-menu.filter.options.showAll',
          );
        }
      });

    this.appStateService.goTOAlbumActor$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ loaded, data }) => {
        if (loaded) {
          this.selectedOpt = this.customeFilter[0].name;
          if (data) {
            if (data.type === 'actor') {
              const index = this.dataTaggingWith
                .map(function (data: any) {
                  return data.id;
                })
                .indexOf(data.id);
              if (this.selectedOpt !== this.dataTaggingWith[index].tagName) {
                this.selectedOpt = this.dataTaggingWith[index].tagName;
              }
            } else {
              const index = this.customAlbumList
                .map(function (data: any) {
                  return data.id;
                })
                .indexOf(data.id);
              if (
                this.selectedOpt !==
                this.customAlbumList[index].value +
                  ' (' +
                  this.customAlbumList[index].assetCount +
                  ')'
              ) {
                this.selectedOpt =
                  this.customAlbumList[index].value +
                  ' (' +
                  this.customAlbumList[index].assetCount +
                  ')';
              }
            }
          }
        }
      });

    this.appStateService.conflicts$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ loaded }) => {
        if (loaded) {
          this.selectedOpt = this.customeFilter[0].name;
        }
      });

    const conflictsDialogState = this.dialogService.getDialogState(
      ConflictsComponent.identifier,
    );
    conflictsDialogState.state$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: DialogStateListenerData) => {
        if (state.closed && !state.data?.withoutChanges) {
          this.selectedOpt = this.translate.instant(
            'left-menu.filter.options.showAll',
          );
          this.callShowAllFromConflict();
        }
      });

    this.appStateService.showAll$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ loaded }) => {
        if (loaded) {
          this.selectedOpt = this.translate.instant(
            'left-menu.filter.options.showAll',
          );
        }
      });

    this.appStateService.noAuditImageAvailable$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ loaded }) => {
        if (loaded) {
          this.selectedOpt = this.translate.instant(
            'left-menu.filter.options.showAll',
          );
          this.resetAuditConfig();
          this.appStateService.setNoAuditImageAvailable({ loaded: false });
        }
      });

    this.appStateService.advanceSearchCustomFilter$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ loaded }) => {
        if (loaded) {
          this.selectedOpt = this.customeFilter[0].name;
        }
      });

    this.appStateService.restartToShowAll$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.selectedOpt = this.translate.instant(
          'left-menu.filter.options.showAll',
        );
      });
  }

  onBackAdvanceSearch(uuid, pageNo?) {
    this.loaderService.displayLoader(true);
    this.advanceSearchService
      .getAdvanceSearchBack(uuid)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.assetIds.length > 0) {
          this.viewProjectService.setProjectImageIDs(res.assetIds);
          this.currentPageNumber = pageNo ? pageNo : 1;
          this.currentPageNumber =
            res.assetIds.length / this.noOfImages >= this.currentPageNumber
              ? this.currentPageNumber
              : 1;
          this.assetsIds = [];
          this.assetsIds = Utils.getImageIdsBasedOnPage(
            this.currentPageNumber,
            this.noOfImages,
            res.assetIds,
          );
          const data = {
            assetIds: this.assetsIds,
            projectId: this.restartDataDTO.projectId,
            filter: 'advancesearch:' + res.filter,
          };

          this.viewProjectService
            .callAssetsInfoBasedOnAssetsId(data)
            .pipe(takeUntil(this.destroy$))
            .subscribe((resData) => {
              this.viewProjectService.setProjectID(resData.projectId);
              this.router.navigate([
                Utils.prepareFilterNavigationURL(
                  this.viewProjectService.getProjectRole(),
                  resData.projectId,
                  'advancesearch:' + res.filter,
                  this.currentPageNumber,
                ),
              ]);
              this.selectedImagesStore.clear();
              this.sidebarService.setAlbumImageData(resData);
            });
        } else {
          this.alertCustom(this.translate.instant('view-project.alert2'));
        }
        this.viewProjectService.setExecutiveAlbum(false);
      });
  }

  callShowAll(projectId?, filterName?, numOfImages?, pageNum?, assetId?) {
    this.loaderService.displayLoader(true);
    this.viewProjectService.setExecutiveAlbumView(false);
    this.viewProjectService
      .callProjectImageIDs(
        projectId ? projectId : this.viewProjectService.getProjectId(),
        filterName ? filterName : FilterEnum.ShowAll,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res1) => {
          if (res1) {
            this.assetsIds = Utils.getImageIdsBasedOnPage(
              pageNum ? pageNum : 1,
              numOfImages ? numOfImages : this.noOfImages,
              res1,
            );
            const data = {
              assetIds: this.assetsIds,
              projectId: this.viewProjectService.getProjectId(),
              filter: filterName ? filterName : FilterEnum.ShowAll,
            };

            const routingProjectId = this.viewProjectService.getProjectId();
            const routingPageNum = pageNum ? pageNum : 1;

            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  this.resetAuditConfig();
                  this.router.navigate([
                    Utils.prepareFilterNavigationURL(
                      this.viewProjectService.getProjectRole(),
                      routingProjectId,
                      FilterEnum.ShowAll,
                      routingPageNum,
                    ),
                  ]);
                  if (pageNum) {
                    this.paginationService.setPaginationIndex(
                      pageNum ? pageNum : 1,
                    );
                  }
                  this.handleProjectData(resData);
                  if (assetId !== 0 && assetId) {
                    this.lastSelectedImageOnRestat =
                      this.getLastSelectedImagesOnRestatData(resData, assetId);
                    this.lastSelectedImageStore.add(
                      this.lastSelectedImageOnRestat[0],
                    );
                  }
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(this.translate.instant('view-project.alert2'));
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  getLastSelectedImagesOnRestatData(res, assetId) {
    return res.assetInfoDTOList.filter((imageBbj) => {
      return imageBbj.id === assetId;
    });
  }

  callLockedApproved(pageNum?) {
    this.loaderService.displayLoader(true);
    this.viewProjectService.setExecutiveAlbumView(false);
    const filter = 'general:' + 'locked-approved';
    this.viewProjectService
      .callProjectImageIDs(this.viewProjectService.getProjectId(), filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res1) => {
          if (res1) {
            this.selectedOpt = this.translate.instant(
              'left-menu.filter.options.lockedApproved',
            );
            this.currentFilter = 'general:' + 'locked-approved';
            this.paginationService.setPaginationIndex(pageNum ? pageNum : 1);
            this.assetsIds = Utils.getImageIdsBasedOnPage(
              pageNum ? pageNum : 1,
              this.noOfImages,
              res1,
            );
            const data = {
              assetIds: this.assetsIds,
              projectId: this.viewProjectService.getProjectId(),
              filter: this.currentFilter,
            };

            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  this.viewProjectService.setProjectData(resData);
                  this.resetAuditConfig();
                  this.viewProjectService.setCurrentFilter(this.currentFilter);
                  this.router.navigate([
                    Utils.prepareFilterNavigationURL(
                      this.viewProjectService.getProjectRole(),
                      this.viewProjectService.getProjectId(),
                      this.currentFilter,
                      pageNum ? pageNum : this.currentPageNumber,
                    ),
                  ]);
                  this.handleProjectData(resData);
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(this.translate.instant('view-project.alert2'));
            this.callShowAll();
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  callShowAllFromConflict() {
    this.viewProjectService.setExecutiveAlbumView(false);
    this.loaderService.displayLoader(true);

    this.viewProjectService
      .callProjectImageIDs(
        this.viewProjectService.getProjectId(),
        FilterEnum.ShowAll,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res1) => {
          if (res1) {
            this.viewProjectService.setCurrentFilter(FilterEnum.ShowAll);
            this.paginationService.setPaginationIndex(1);
            this.assetsIds = Utils.getImageIdsBasedOnPage(
              1,
              this.noOfImages,
              res1,
            );
            const data = {
              assetIds: this.assetsIds,
              projectId: this.viewProjectService.getProjectId(),
              filter: FilterEnum.ShowAll,
            };
            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  this.resetAuditConfig();
                  this.router.navigate([
                    Utils.prepareFilterNavigationURL(
                      this.viewProjectService.getProjectRole(),
                      this.viewProjectService.getProjectId(),
                      FilterEnum.ShowAll,
                      this.currentPageNumber,
                    ),
                  ]);
                  this.handleProjectData(resData);
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.viewProjectService.resetProjectData();
            this.viewProjectService.resetProjectImageIDs();
            this.alertCustom(this.translate.instant('view-project.alert2'));
            this.callShowAll();
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  handleProjectData(res) {
    this.selectedImagesStore.clear();
    this.viewProjectService.setExecutiveAlbum(false);
    this.sidebarService.setAlbumImageData(res);
    this.loaderService.displayLoader(false);
  }

  clickOnSelectFilter(selectedOptionData, evt) {
    this.lastSelectedImageStore.clear();
    evt.preventDefault();
    if (this.selectedOpt !== selectedOptionData) {
      if (selectedOptionData === 'Show All') {
        this.selectedOpt = this.translate.instant(
          'left-menu.filter.options.showAll',
        );
        this.callShowAll();
      } else if (selectedOptionData === 'Locked Approved') {
        this.callLockedApproved();
      }
    }
  }

  clickOnAlbum(selectedOptionData, evt?: any, pageNo?) {
    this.lastSelectedImageStore.clear();
    const filter = 'album:' + selectedOptionData.id;

    this.currentPageNumber = pageNo ? pageNo : 1;
    if (evt) {
      evt.preventDefault();
    }
    if (selectedOptionData.assetCount === 0) {
      if (pageNo) {
        this.loaderService.displayLoader(true);
        this.callShowAll();
      } else {
        this.alertCustom(this.translate.instant('view-project.alert2'));
        this.loaderService.displayLoader(false);
      }

      return;
    }
    if (selectedOptionData.id) {
      this.selectedOpt =
        selectedOptionData.value + ' (' + selectedOptionData.assetCount + ')';
      this.loaderService.displayLoader(true);

      if (selectedOptionData.executiveViewLocal) {
        this.viewProjectService.setExecutiveAlbumView(true);
        this.viewProjectService.setExecutiveAlbumID(selectedOptionData.id);

        this.viewProjectService
          .callProjectImageIDs(this.viewProjectService.getProjectId(), filter)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (res1) => {
              if (res1) {
                this.currentFilter = filter;
                this.paginationService.setPaginationIndex(
                  this.currentPageNumber,
                );
                this.assetsIds = Utils.getImageIdsBasedOnPage(
                  this.currentPageNumber,
                  this.noOfImages,
                  res1,
                );
                const data = {
                  assetIds: this.assetsIds,
                  projectId: this.viewProjectService.getProjectId(),
                  filter: this.currentFilter,
                };

                this.viewProjectService
                  .callAssetsInfoBasedOnAssetsId(data)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(
                    (resData) => {
                      this.viewProjectService.setProjectData(resData);
                      this.resetAuditConfig();
                      this.viewProjectService.setExecutiveAlbum(
                        selectedOptionData.executiveViewLocal ? true : false,
                      );
                      this.viewProjectService.setCurrentFilter(
                        this.currentFilter,
                      );
                      this.router.navigate([
                        Utils.prepareFilterNavigationURL(
                          this.viewProjectService.getProjectRole(),
                          this.viewProjectService.getProjectId(),
                          this.currentFilter,
                          this.currentPageNumber,
                        ),
                      ]);
                      this.selectedImagesStore.clear();
                      this.sidebarService.setAlbumImageData(resData);
                      this.loaderService.displayLoader(false);
                    },
                    (error) => {
                      this.apiErrorHandlerService.getHandler().handle(error);
                      this.loaderService.displayLoader(false);
                    },
                  );
              } else {
                this.loaderService.displayLoader(false);
                this.alertCustom(this.translate.instant('view-project.alert2'));
                this.callShowAll();
              }
            },
            (error) => {
              this.apiErrorHandlerService.getHandler().handle(error);
              this.loaderService.displayLoader(false);
            },
          );
      } else {
        this.viewProjectService.setExecutiveAlbumView(false);
        this.viewProjectService
          .callProjectImageIDs(this.viewProjectService.getProjectId(), filter)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (res1) => {
              if (res1) {
                this.currentFilter = filter;
                this.paginationService.setPaginationIndex(
                  this.currentPageNumber,
                );
                this.assetsIds = Utils.getImageIdsBasedOnPage(
                  this.currentPageNumber,
                  this.noOfImages,
                  res1,
                );
                const data = {
                  assetIds: this.assetsIds,
                  projectId: this.viewProjectService.getProjectId(),
                  filter: this.currentFilter,
                };

                this.viewProjectService
                  .callAssetsInfoBasedOnAssetsId(data)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(
                    (resData) => {
                      this.viewProjectService.setProjectData(resData);
                      this.resetAuditConfig();
                      this.viewProjectService.setExecutiveAlbum(
                        selectedOptionData.executiveViewLocal ? true : false,
                      );
                      this.viewProjectService.setCurrentFilter(
                        this.currentFilter,
                      );
                      this.router.navigate([
                        Utils.prepareFilterNavigationURL(
                          this.viewProjectService.getProjectRole(),
                          this.viewProjectService.getProjectId(),
                          this.currentFilter,
                          this.currentPageNumber,
                        ),
                      ]);
                      this.selectedImagesStore.clear();
                      this.sidebarService.setAlbumImageData(resData);
                      this.loaderService.displayLoader(false);
                    },
                    (error) => {
                      this.apiErrorHandlerService.getHandler().handle(error);
                      this.loaderService.displayLoader(false);
                    },
                  );
              } else {
                this.loaderService.displayLoader(false);
                this.alertCustom(this.translate.instant('view-project.alert2'));
                this.callShowAll();
              }
            },
            (error) => {
              this.apiErrorHandlerService.getHandler().handle(error);
              this.loaderService.displayLoader(false);
            },
          );
      }
    }
  }

  clickOnTaggingWith(selectedOptionData: any, evt?: any, pageNo?) {
    this.loaderService.displayLoader(true);
    this.viewProjectService.isTaggingWithkillRight.next(
      selectedOptionData.killRight,
    );
    this.lastSelectedImageStore.clear();

    if (selectedOptionData.imageCount === 0) {
      if (evt) {
        evt.preventDefault();
      }
      if (pageNo) {
        this.loaderService.displayLoader(true);
        this.callShowAll();
      } else {
        this.alertCustom(this.translate.instant('view-project.alert2'));
        this.loaderService.displayLoader(false);
      }
      return;
    }

    if (evt) {
      this.currentPageNumber = pageNo ? pageNo : 1;
      evt.preventDefault();
    }
    if (selectedOptionData.id) {
      this.loaderService.displayLoader(true);
      this.viewProjectService.setExecutiveAlbumView(false);
      const filter = 'taggedwith:' + selectedOptionData.id;

      this.viewProjectService
        .callProjectImageIDs(this.viewProjectService.getProjectId(), filter)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res1) => {
            if (res1) {
              this.currentFilter = 'taggedwith:' + selectedOptionData.id;
              this.assetsIds = Utils.getImageIdsBasedOnPage(
                pageNo ? pageNo : 1,
                this.noOfImages,
                res1,
              );
              const data = {
                assetIds: this.assetsIds,
                projectId: this.viewProjectService.getProjectId(),
                filter: this.currentFilter,
              };

              this.viewProjectService
                .callAssetsInfoBasedOnAssetsId(data)
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                  (resData) => {
                    if (resData.assetInfoDTOList.length > 0) {
                      this.paginationService.setPaginationIndex(
                        pageNo ? pageNo : 1,
                      );
                      this.selectedOpt = selectedOptionData.tagName;
                      this.resetAuditConfig();
                      this.viewProjectService.setCurrentFilter(
                        this.currentFilter,
                      );
                      setTimeout(() => {
                        this.router.navigate([
                          Utils.prepareFilterNavigationURL(
                            this.viewProjectService.getProjectRole(),
                            this.viewProjectService.getProjectId(),
                            this.currentFilter,
                            this.currentPageNumber,
                          ),
                        ]);
                      }, 50);
                      this.selectedImagesStore.clear();
                      this.sidebarService.setAlbumImageData(resData);
                    } else {
                      this.alertCustom(
                        this.translate.instant('view-project.alert2'),
                      );
                    }
                    this.loaderService.displayLoader(false);
                    this.viewProjectService.setExecutiveAlbum(false);
                  },
                  (error) => {
                    this.apiErrorHandlerService.getHandler().handle(error);
                    this.loaderService.displayLoader(false);
                  },
                );
            } else {
              this.loaderService.displayLoader(false);
              this.alertCustom(this.translate.instant('view-project.alert2'));
              this.callShowAll();
            }
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    }
  }

  findSelectedAlbumOpt() {
    const currentFilter = this.viewProjectService.getCurrentFilter();
    if (currentFilter.includes('album')) {
      this.currentFilterAlbumId = Number(currentFilter.replace(/\D/g, ''));
    } else {
      this.currentFilterAlbumId = null;
    }
  }

  isFilterSelected(
    selectedOpt: string | number,
    filterName: string | number,
  ): boolean {
    return selectedOpt === filterName;
  }

  clickOnGeneral(list, evt) {
    this.lastSelectedImageStore.clear();
    evt.preventDefault();
    this.currentPageNumber = 1;
    switch (list) {
      case 'Approved':
        this.callDataFilterAPI(list, 'approved');
        break;
      case 'Rejected':
        this.callDataFilterAPI(list, 'rejected');
        break;
      case 'Solo':
        this.callDataFilterAPI(list, 'solo');
        break;
      case 'Group':
        this.callDataFilterAPI(list, 'group');
        break;
      case 'Retouch Comment':
        this.callDataFilterAPI(list, 'retouch');
        break;
      case 'Image Notes':
        this.callDataFilterAPI(list, 'notes');
        break;
      case 'Hidden':
        this.callDataFilterAPI(list, 'hidden');
        break;
      case 'Locked Approved':
        this.callDataFilterAPI(list, 'locked-approved');
        break;
    }
  }

  callDataFilterAPI(name, filterName?, pageNo?) {
    this.loaderService.displayLoader(true);
    this.viewProjectService.setExecutiveAlbumView(false);
    const filter = 'general:' + filterName;
    this.viewProjectService
      .callProjectImageIDs(this.viewProjectService.getProjectId(), filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res1) => {
          if (res1) {
            this.currentFilter = 'general:' + filterName;
            this.paginationService.setPaginationIndex(pageNo ? pageNo : 1);
            this.assetsIds = Utils.getImageIdsBasedOnPage(
              pageNo ? pageNo : 1,
              this.noOfImages,
              res1,
            );
            const data = {
              assetIds: this.assetsIds,
              projectId: this.viewProjectService.getProjectId(),
              filter: this.currentFilter,
            };

            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  if (resData.assetInfoDTOList.length > 0) {
                    this.viewProjectService.setProjectData(resData);
                    this.resetAuditConfig();
                    this.viewProjectService.setCurrentFilter(
                      this.currentFilter,
                    );
                    this.selectedOpt = name;
                    this.router.navigate([
                      Utils.prepareFilterNavigationURL(
                        this.viewProjectService.getProjectRole(),
                        this.viewProjectService.getProjectId(),
                        this.currentFilter,
                        pageNo ? pageNo : this.currentPageNumber,
                      ),
                    ]);
                    this.selectedImagesStore.clear();
                    this.sidebarService.setAlbumImageData(resData);
                  } else {
                    this.alertCustom(
                      this.translate.instant('view-project.alert2'),
                    );
                  }
                  this.loaderService.displayLoader(false);
                  this.viewProjectService.setExecutiveAlbum(false);
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(this.translate.instant('view-project.alert2'));
            this.selectedOpt = this.translate.instant(
              'left-menu.filter.options.showAll',
            );
            this.callShowAll();
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  clickOnCustomeFilter(selectedOptionData, evt) {
    evt.preventDefault();
    if (selectedOptionData === 'Setup Filter') {
      this.callFirstSetupData();
    } else {
      this.alertCustom(this.translate.instant('view-project.alert12'));
      this.selectedOpt = this.translate.instant(
        'left-menu.filter.options.showAll',
      );
    }
  }

  callFirstSetupData() {
    this.loaderService.displayLoader(true);
    this.viewProjectService.setExecutiveAlbumView(false);
    const filterName = 'batch:' + this.setUpData.setupDTOList[0].value;
    this.viewProjectService
      .callProjectImageIDs(this.viewProjectService.getProjectId(), filterName)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res1) => {
          if (res1) {
            this.assetsIds = Utils.getImageIdsBasedOnPage(
              1,
              this.noOfImages,
              res1,
            );
            const data = {
              assetIds: this.assetsIds,
              projectId: this.viewProjectService.getProjectId(),
              filter: filterName,
            };

            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  this.resetAuditConfig();
                  this.router.navigate([
                    Utils.prepareFilterNavigationURL(
                      this.viewProjectService.getProjectRole(),
                      this.viewProjectService.getProjectId(),
                      filterName,
                      1,
                    ),
                  ]);
                  this.paginationService.setPaginationIndex(1);
                  this.handleProjectData(resData);
                  this.loaderService.displayLoader(false);
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(this.translate.instant('view-project.alert2'));
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  clickOnDataTaggingFilter(list, evt, data) {
    this.lastSelectedImageStore.clear();
    evt.preventDefault();
    this.currentPageNumber = 1;
    switch (list) {
      case 'Unpublished':
        if (data.count > 0) {
          this.callDataFilterAPI(list, 'unpublished');
        } else {
          this.alertCustom(this.translate.instant('view-project.alert2'));
        }
        break;
      case 'Tagged/Unsubmitted-Tagger #1':
        this.callDataTaggingAPI(list, 'unsubmitted-tagger-' + 1);
        break;
      case 'Tagged/Unsubmitted-Tagger #2':
        this.callDataTaggingAPI(list, 'unsubmitted-tagger-' + 2);
        break;
      case 'Unpublished/Untagged-Tagger #1':
        this.callDataTaggingAPI(list, 'unsubmitted-untagged-tagger-' + 1);
        break;
      case 'Unpublished/Untagged-Tagger #2':
        this.callDataTaggingAPI(list, 'unsubmitted-untagged-tagger-' + 2);
        break;
      case 'Published':
        this.callDataFilterAPI(list, 'published');
        break;
      case 'Hidden':
        this.callDataFilterAPI(list, 'hidden');
        break;
    }
  }

  callDataTaggingAPI(name, filter?, pageNo?) {
    this.loaderService.displayLoader(true);
    this.viewProjectService.setExecutiveAlbumView(false);
    const filterdata = 'tagging:' + filter;
    this.viewProjectService
      .callProjectImageIDs(this.viewProjectService.getProjectId(), filterdata)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res1) => {
          if (res1) {
            this.currentFilter = 'tagging:' + filter;
            this.paginationService.setPaginationIndex(pageNo ? pageNo : 1);
            this.assetsIds = Utils.getImageIdsBasedOnPage(
              pageNo ? pageNo : 1,
              this.noOfImages,
              res1,
            );
            const data = {
              assetIds: this.assetsIds,
              projectId: this.viewProjectService.getProjectId(),
              filter: this.currentFilter,
            };
            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  if (resData.assetInfoDTOList.length > 0) {
                    this.viewProjectService.setProjectData(resData);
                    this.selectedOpt = name;
                    this.resetAuditConfig();

                    this.viewProjectService.setCurrentFilter(
                      this.currentFilter,
                    );
                    this.router.navigate([
                      Utils.prepareFilterNavigationURL(
                        this.viewProjectService.getProjectRole(),
                        this.viewProjectService.getProjectId(),
                        this.currentFilter,
                        pageNo ? pageNo : this.currentPageNumber,
                      ),
                    ]);
                    this.selectedImagesStore.clear();
                    this.sidebarService.setAlbumImageData(resData);
                  } else {
                    this.alertCustom(
                      this.translate.instant('view-project.alert2'),
                    );
                  }
                  this.loaderService.displayLoader(false);
                  this.viewProjectService.setExecutiveAlbum(false);
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(this.translate.instant('view-project.alert2'));
            this.selectedOpt = this.translate.instant(
              'left-menu.filter.options.showAll',
            );
            this.callShowAll();
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  resetAuditConfig() {
    this.viewProjectService.setCurrentPageNumber(null);
    this.dialogService.closeDialogById(ConflictsComponent.identifier);
    this.appStateService.setNoConflicts({ loaded: false });
    this.appStateService.setBypass2ndtagger({ loaded: false });
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
