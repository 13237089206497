import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettings } from '../../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../../shared/services/api.service';

@Injectable()
export class ImageexclusivesService {
  private exclusivesUserListDataSubject = new BehaviorSubject<any>([]);
  exclusivesUserListDataSubjectGlobal =
    this.exclusivesUserListDataSubject.asObservable();

  constructor(private apiService: ApiService) {}

  callGetImageExclusiveslist(projectId): Observable<any> {
    const path = AppSettings.IMAGE_EXCLUSIVES_LIST(projectId);
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  addExclusiveLabel(formData): Observable<any> {
    const path = AppSettings.ADD_IMAGE_EXCLUSIVES_LABEL();
    return this.apiService.post(path, formData).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  submitExclusiveForm(formData): Observable<any> {
    const path = AppSettings.SUBMIT_IMAGE_EXCLUSIVES_FORM();
    return this.apiService.post(path, formData).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  submitEditExclusiveForm(formData): Observable<any> {
    const path = AppSettings.SUBMIT_IMAGE_EXCLUSIVES_FORM();
    return this.apiService.put(path, formData).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getExclusiveData(projectId, assetsId): Observable<any> {
    const path =
      AppSettings.SUBMIT_IMAGE_EXCLUSIVES_FORM() + projectId + '/' + assetsId;
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  removeExclusive(formData) {
    const path = AppSettings.ADD_IMAGE_EXCLUSIVES_LABEL_RELEASE();
    return this.apiService.put(path, formData).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  setExClusiveUserListData(data) {
    this.exclusivesUserListDataSubject.next(data);
  }

  getExClusiveUserListData() {
    return this.exclusivesUserListDataSubject.value;
  }
}
