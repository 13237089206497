import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettings } from '../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../shared/services/api.service';
import { DomainConfig } from '../../../../../shared/models/domainConfig.model';
import { AdvanceSearchFilter } from './advancesearch.modal';

@Injectable()
export class AdvanceSearchService {
  private advanceSearchingData = new BehaviorSubject<DomainConfig>(
    JSON.parse(JSON.stringify(AdvanceSearchFilter)),
  );
  advanceSearchingDataDataGlobal = this.advanceSearchingData.asObservable();

  constructor(private apiService: ApiService) {}

  postAdvanceSearch(obj): Observable<any> {
    const path = AppSettings.ADVANCE_SEARCH;
    return this.apiService.post(path, obj).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getAdvanceSearchBack(uuid): Observable<any> {
    const path = AppSettings.ADVANCE_SEARCH_BACK(uuid);
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  saveAdvanceSearchSettings(advanceSearchObj) {
    this.advanceSearchingData.next(advanceSearchObj);
  }

  getAdvanceSearchBackCreators(
    projectId: number,
  ): Observable<Array<{ id: number; name: string }>> {
    const path = AppSettings.ADVANCE_SEARCH_CREATORS(projectId);
    return this.apiService.get(path).pipe(map((res) => res));
  }

  getAdvanceSearchKeywords(
    projectId: number,
  ): Observable<Array<{ assetId: number; keywords: Array<string> }>> {
    const path = AppSettings.ADVANCE_SEARCH_KEYWORDS(projectId);
    return this.apiService.get(path).pipe(map((res) => res));
  }

  clearAdvanceSearchSettings() {
    this.advanceSearchingData.next(
      JSON.parse(JSON.stringify(AdvanceSearchFilter)),
    );
  }

  removeDuplicates(
    array: Array<{ id: number; name: string }>,
  ): Array<{ id: number; name: string }> {
    return array.reduce((accumulator, currentValue) => {
      const existingItem = accumulator.find(
        (item) => item.id === currentValue.id,
      );
      if (!existingItem) {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, []);
  }

  getKeywordsFromArray(list) {
    const allKeywords: string[] = list.flatMap((item) =>
      item && item.keywords ? item.keywords : [],
    );
    return [...new Set(allKeywords.filter((keyword) => keyword !== null))];
  }
}
