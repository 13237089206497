export class AlbumsInfo {
  albums: Album[];
  tagColorResponseDTOList: TagColorResponseDTOList[];
  tagKeyResponseDTOList: TagKeyResponseDTOList[];
  execUsers: number[];
  projectAlbum: any;
}

class Album {
  active: boolean;
  albumTypeId: number;
  assetCount: number;
  children: Children[];
  executive: boolean;
  executivePermission: any;
  executiveView: boolean;
  expireAt: any;
  id: number;
  ownerId: number;
  parentAlbumId: number;
  permission: Permission[];
  tagColor: number;
  tagColorCode: string;
  tagKey: string;
  value: string;

  executiveViewLocal? = false;
}

class Children {
  id: number;
  value: string;
  albumId: number;
  ownerId: number;
  albumCount: number;
  albumTypeId: number;
}

class Permission {
  userId: number;
  permission: number;
}

class TagColorResponseDTOList {
  id: number;
  tagColor: number;
  tagColorCode: string;
  value: string;
}

class TagKeyResponseDTOList {
  id: number;
  tagKey: number;
  value: string;
}
