import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { LauncherService } from '../../../launcher/launcher.service';
import { DialogService } from '../../../../shared/services/dialog/dialog.service';
import { UserDetails } from '../../../../shared/models/userdetails.model';
import { UserRole } from '../../../../shared/enum/user-role.enum';
import { CurrentStateService } from '../../../../shared/services/current.state.service';
import { UserService } from '../../../../shared/services/user.service';
import { ConstantsGlobal } from '../../../../shared/constants/constants';
import { AdvancesearchComponent } from './advancesearch/advancesearch.component';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../../../../shared/utils/utils';
import { HelpWindowComponent } from '../../../../shared/layout/help/help.component';
import { ViewProjectService } from '../../../../shared/services/view-project.service';
import { UnsubscriberComponent } from '../../../../shared/components/unsubscriber/unsubscriber.component';
import { ProjectRole } from '../../../../shared/enum/project-role.enum';

@Component({
  selector: 'fs-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent extends UnsubscriberComponent implements OnInit {
  @Input() sidenavElementRef: ElementRef;
  currentState: any;
  currentUser: UserDetails = new UserDetails();
  projectAuthority: string;
  userAuthority: string;
  selectedProjectValue: any;
  constantsGlobalData: any;
  userRole = UserRole;
  projectRole = ProjectRole;

  constructor(
    private currentStateService: CurrentStateService,
    private userService: UserService,
    private launcherService: LauncherService,
    private dialogService: DialogService,
    private viewProjectService: ViewProjectService,
  ) {
    super();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.currentUser = this.userService.mergeWithCurrentUser(this.currentUser);

    this.currentStateService.getCurrentState
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentState) => {
        this.currentState = currentState;
      });

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((authority) => {
        this.userAuthority = authority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.launcherService.selectedProjectGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectValue) => {
        this.selectedProjectValue = Number(projectValue);
      });
  }

  showHelpWindow(helpTopic?, videoName?) {
    this.dialogService.open(HelpWindowComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
        'fs-dialog-container-no-overflow-panel',
      ],
      data: {
        topicName: helpTopic,
        videoName: videoName,
      },
    });
  }

  openSearchDialog() {
    this.dialogService.open(AdvancesearchComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-width-1123-panel',
        'fs-dialog-link-panel',
        'fs-dialog-auto-height-panel',
      ],
    });
  }
}
