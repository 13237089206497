import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[alternativeImage]',
})
export class AlternativeImageDirective {
  @Input() alternativeImage: string;

  constructor(private elRef: ElementRef) {}

  @HostListener('error') onError(): void {
    if (!this.elRef.nativeElement.isLinkBroken) {
      this.elRef.nativeElement.isLinkBroken = true;
      this.elRef.nativeElement.src = this.alternativeImage;
    } else {
      this.elRef.nativeElement.style.display = 'none';
    }
  }
}
