import { Component, Input, OnInit } from '@angular/core';
import { AssetInfoDTOList } from '../../models/assetInfo.model';

@Component({
  selector: 'fs-image-name-panel',
  templateUrl: './image-name-panel.component.html',
  styleUrls: ['./image-name-panel.component.scss'],
})
export class ImageNamePanelComponent implements OnInit {
  @Input() imgData: AssetInfoDTOList;
  truncatedAssetName: string;
  maxAssetNameLength = 40;

  ngOnInit() {
    this.truncatedAssetName = this.truncateMiddle(this.imgData.name);
  }

  truncateMiddle(imageName: string): string {
    if (imageName.length <= this.maxAssetNameLength) {
      return imageName;
    }

    const truncationLength = Math.floor((this.maxAssetNameLength - 3) / 2);
    return (
      imageName.substr(0, truncationLength) +
      '...' +
      imageName.substr(imageName.length - truncationLength)
    );
  }

  showFullName(): void {
    if (this.imgData.name.length > this.maxAssetNameLength) {
      this.truncatedAssetName = this.imgData.name;
    }
  }

  hideFullName(): void {
    if (this.imgData.name.length > this.maxAssetNameLength) {
      this.truncatedAssetName = this.truncateMiddle(this.imgData.name);
    }
  }
}
