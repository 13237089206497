import { DashboardComponent } from '../../dashboard/dashboard.component';
import { LauncherComponent } from '../../dashboard/launcher/launcher.component';
import { DepartmentComponent } from '../../dashboard/department/department.component';
import { ExecutiveComponent } from '../../dashboard/executive/executive.component';

import { ProjectComponent } from '../../dashboard/project/project.component';
import { ViewProjectComponent } from '../../dashboard/project/project-detail/view-project/view-project.component';
import { EnlargeComponent } from '../../dashboard/project/project-detail/enlarge/enlarge.component';

import { CaptionEnlargeComponent } from '../../dashboard/project/project-detail/caption-enlarge/caption-enlarge.component';

import { CompareComponent } from '../../dashboard/project/project-detail/compare/compare.component';
import { DrawImageComponent } from '../../dashboard/project/project-detail/draw-image/draw-image.component';
import { ViewGalleryComponent } from '../../dashboard/project/project-detail/view-gallery/view-gallery.component';

import { AuthGuard } from '../services/auth-guard.service';
import { ViewProjectPageComponent } from '../../dashboard/project/project-detail/view-project/view-project-page/view-project-page.component';
import { UserTaggerRoleGuard } from '../services/user-tagger-role-guard.service';

export const RouteConfig = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [
      {
        path: '',
        component: LauncherComponent,
        canActivate: [AuthGuard],
        displaySetting: {
          header: {
            visible: true,
            items: {
              notification: true,
              phone: true,
              breadcrumb: true,
              dropdown: true,
              profile: true,
            },
          },
          topMenu: {
            visible: true,
            hideAllItems: false,
            items: {
              pagination: true,
              selectAll: true,
              deselectAll: true,
              goto: true,
              viewSetting: true,
              addNotes: true,
              favorite: false,
              compare: true,
              scompare: false,
              link: true,
              email: true,
              download: true,
              expressDownload: true,
              print: true,
              delete: true,
              closeImage: true,
              enlargeImage: true,
              close: true,
              setUpTitle: false,
              imdone: false,
              approved: false,
              rejected: false,
            },
          },
          leftMenu: {
            visible: false,
            defaultOpen: true,
            items: {
              advanceSearch: true,
              customeFilter: false,
              projectInfo: true,
              retouch: false,
              menu: {
                visible: true,
                items: {
                  albums: true,
                  imageInfo: true,
                  administrator: true,
                  audit: true,
                  signoff: true,
                  wideedit: true,
                  adhoc: false,
                },
              },
            },
          },
        },
      },
      {
        path: 'department/:deptid',
        component: DepartmentComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Galleries',
        },
        displaySetting: {
          header: {
            visible: true,
            items: {
              notification: true,
              phone: true,
              breadcrumb: true,
              dropdown: true,
              profile: true,
            },
          },
          topMenu: {
            visible: true,
            hideAllItems: false,
            items: {
              pagination: true,
              selectAll: true,
              deselectAll: true,
              goto: true,
              viewSetting: true,
              addNotes: true,
              favorite: false,
              compare: true,
              scompare: false,
              link: true,
              email: true,
              download: true,
              expressDownload: true,
              print: true,
              delete: true,
              closeImage: true,
              enlargeImage: true,
              close: true,
              setUpTitle: false,
              imdone: false,
              approved: false,
              rejected: false,
            },
          },
          leftMenu: {
            visible: false,
            defaultOpen: true,
            items: {
              advanceSearch: true,
              customeFilter: true,
              projectInfo: true,
              retouch: false,
              menu: {
                visible: true,
                items: {
                  albums: true,
                  imageInfo: true,
                  administrator: true,
                  audit: true,
                  signoff: true,
                  wideedit: true,
                  adhoc: false,
                },
              },
            },
          },
        },
      },
      {
        path: 'executive/:execid',
        component: ExecutiveComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Galleries',
        },
        displaySetting: {
          header: {
            visible: true,
            items: {
              notification: true,
              phone: true,
              breadcrumb: true,
              dropdown: true,
              profile: true,
            },
          },
          topMenu: {
            visible: true,
            hideAllItems: false,
            items: {
              pagination: true,
              selectAll: true,
              deselectAll: true,
              goto: true,
              viewSetting: true,
              addNotes: true,
              favorite: false,
              compare: true,
              scompare: false,
              link: true,
              email: true,
              download: true,
              expressDownload: true,
              print: true,
              delete: true,
              closeImage: true,
              enlargeImage: true,
              close: true,
              setUpTitle: false,
              imdone: true,
              approved: false,
              rejected: false,
            },
          },
          leftMenu: {
            visible: false,
            defaultOpen: true,
            items: {
              advanceSearch: true,
              customeFilter: true,
              projectInfo: true,
              retouch: false,
              menu: {
                visible: true,
                items: {
                  albums: true,
                  imageInfo: true,
                  administrator: true,
                  audit: true,
                  signoff: true,
                  wideedit: true,
                  adhoc: false,
                },
              },
            },
          },
        },
      },
      {
        path: '',
        component: ProjectComponent,
        children: [
          {
            path: 'viewgallery/:gAlbumid',
            component: ViewGalleryComponent,
            canActivate: [AuthGuard],
            data: {
              breadcrumb: 'View Gallery',
            },
            displaySetting: {
              header: {
                visible: true,
                items: {
                  notification: true,
                  phone: true,
                  breadcrumb: true,
                  dropdown: true,
                  profile: true,
                },
              },
              topMenu: {
                visible: true,
                hideAllItems: false,
                items: {
                  pagination: false,
                  selectAll: false,
                  deselectAll: false,
                  goto: false,
                  viewSetting: false,
                  addNotes: false,
                  favorite: false,
                  compare: false,
                  scompare: false,
                  link: false,
                  email: false,
                  download: false,
                  expressDownload: false,
                  print: false,
                  delete: false,
                  closeImage: false,
                  enlargeImage: false,
                  close: false,
                  setUpTitle: true,
                  imdone: false,
                  approved: false,
                  rejected: false,
                },
              },
              leftMenu: {
                visible: true,
                defaultOpen: true,
                items: {
                  advanceSearch: true,
                  customeFilter: true,
                  projectInfo: true,
                  retouch: false,
                  menu: {
                    visible: true,
                    items: {
                      albums: true,
                      imageInfo: true,
                      administrator: true,
                      audit: true,
                      signoff: true,
                      wideedit: true,
                      adhoc: false,
                    },
                  },
                },
              },
            },
          },
          {
            path: 'viewproject/:projectId/filter/:filterId/page/:pageNumber',
            component: ViewProjectPageComponent,
            canActivate: [AuthGuard],
            data: {
              breadcrumb: 'View Project',
            },
            displaySetting: {
              header: {
                visible: true,
                items: {
                  notification: true,
                  phone: true,
                  breadcrumb: true,
                  dropdown: true,
                  profile: true,
                },
              },
              topMenu: {
                visible: true,
                hideAllItems: false,
                items: {
                  pagination: true,
                  selectAll: true,
                  deselectAll: true,
                  goto: true,
                  viewSetting: true,
                  addNotes: true,
                  favorite: true,
                  compare: true,
                  scompare: false,
                  link: true,
                  email: true,
                  download: true,
                  expressDownload: true,
                  print: true,
                  delete: true,
                  closeImage: false,
                  enlargeImage: false,
                  close: false,
                  setUpTitle: false,
                  imdone: true,
                  approved: false,
                  rejected: false,
                },
              },
              leftMenu: {
                visible: true,
                // defaultOpen: false,
                defaultOpen: true,
                items: {
                  advanceSearch: true,
                  customeFilter: true,
                  projectInfo: true,
                  retouch: false,
                  menu: {
                    visible: true,
                    items: {
                      albums: true,
                      imageInfo: true,
                      administrator: true,
                      audit: true,
                      signoff: true,
                      wideedit: true,
                      adhoc: false,
                    },
                  },
                },
              },
            },
          },
          {
            path: 'viewproject/:projectId/filter/:filterId/page/:pageNumber/enlarge/:imageId',
            component: EnlargeComponent,
            canActivate: [AuthGuard],
            data: {
              breadcrumb: 'Enlarge Image',
            },
            displaySetting: {
              header: {
                visible: true,
                items: {
                  notification: true,
                  phone: true,
                  breadcrumb: true,
                  dropdown: true,
                  profile: true,
                },
              },
              topMenu: {
                visible: true,
                hideAllItems: false,
                items: {
                  pagination: true,
                  selectAll: false,
                  deselectAll: false,
                  goto: false,
                  viewSetting: false,
                  addNotes: false,
                  favorite: false,
                  compare: false,
                  scompare: false,
                  link: false,
                  email: true,
                  download: true,
                  expressDownload: true,
                  print: true,
                  delete: false,
                  closeImage: true,
                  enlargeImage: true,
                  close: false,
                  setUpTitle: false,
                  imdone: false,
                  approved: true,
                  rejected: true,
                },
              },
              leftMenu: {
                visible: true,
                defaultOpen: true,
                items: {
                  advanceSearch: true,
                  customeFilter: true,
                  projectInfo: true,
                  retouch: false,
                  menu: {
                    visible: true,
                    items: {
                      albums: true,
                      imageInfo: true,
                      administrator: true,
                      audit: true,
                      signoff: true,
                      wideedit: true,
                      adhoc: false,
                    },
                  },
                },
              },
            },
          },
          {
            path: 'viewproject/:projectId/filter/:filterId/page/:pageNumber/enlarge/:imageId/:type',
            component: CaptionEnlargeComponent,
            canActivate: [AuthGuard, UserTaggerRoleGuard],
            data: {
              breadcrumb: 'Caption Image',
            },
            displaySetting: {
              header: {
                visible: true,
                items: {
                  notification: true,
                  phone: true,
                  breadcrumb: true,
                  dropdown: true,
                  profile: true,
                },
              },
              topMenu: {
                visible: true,
                hideAllItems: false,
                items: {
                  pagination: true,
                  selectAll: false,
                  deselectAll: false,
                  goto: false,
                  viewSetting: false,
                  addNotes: false,
                  favorite: false,
                  compare: false,
                  scompare: false,
                  link: false,
                  email: true,
                  download: true,
                  expressDownload: true,
                  print: true,
                  delete: false,
                  closeImage: true,
                  enlargeImage: true,
                  close: false,
                  setUpTitle: false,
                  imdone: false,
                  approved: true,
                  rejected: true,
                },
              },
              leftMenu: {
                visible: true,
                defaultOpen: true,
                items: {
                  advanceSearch: true,
                  customeFilter: true,
                  projectInfo: true,
                  retouch: false,
                  menu: {
                    visible: true,
                    items: {
                      albums: true,
                      imageInfo: true,
                      administrator: true,
                      audit: true,
                      signoff: true,
                      wideedit: true,
                      adhoc: false,
                    },
                  },
                },
              },
            },
          },
          {
            path: 'viewproject/:projectId/filter/:filterId/page/:pageNumber/draw/:imageId',
            component: DrawImageComponent,
            canActivate: [AuthGuard, UserTaggerRoleGuard],
            data: {
              breadcrumb: 'Draw Image',
            },
            displaySetting: {
              header: {
                visible: true,
                items: {
                  notification: true,
                  phone: true,
                  breadcrumb: true,
                  dropdown: true,
                  profile: true,
                },
              },
              topMenu: {
                visible: true,
                hideAllItems: false,
                items: {
                  pagination: true,
                  selectAll: false,
                  deselectAll: false,
                  goto: false,
                  viewSetting: false,
                  addNotes: false,
                  favorite: false,
                  compare: false,
                  scompare: false,
                  link: false,
                  email: false,
                  download: false,
                  expressDownload: false,
                  print: false,
                  delete: false,
                  closeImage: false,
                  enlargeImage: false,
                  close: false,
                  saveDraw: true,
                  closeDraw: true,
                  setUpTitle: false,
                  imdone: false,
                  approved: false,
                  rejected: false,
                },
              },
              leftMenu: {
                visible: true,
                defaultOpen: true,
                items: {
                  advanceSearch: false,
                  customeFilter: false,
                  projectInfo: false,
                  retouch: true,
                  menu: {
                    visible: true,
                    items: {
                      albums: false,
                      imageInfo: false,
                      administrator: false,
                      audit: false,
                      signoff: false,
                      wideedit: false,
                      adhoc: false,
                    },
                  },
                },
              },
            },
          },
          {
            path: 'viewproject/:projectId/filter/:filterId/page/:pageNumber/compare',
            component: CompareComponent,
            canActivate: [AuthGuard],
            data: {
              breadcrumb: 'Image Compare',
            },
            displaySetting: {
              header: {
                visible: true,
                items: {
                  notification: true,
                  phone: true,
                  breadcrumb: true,
                  dropdown: true,
                  profile: true,
                },
              },
              topMenu: {
                visible: true,
                hideAllItems: false,
                items: {
                  pagination: false,
                  selectAll: false,
                  deselectAll: false,
                  goto: false,
                  viewSetting: false,
                  addNotes: false,
                  favorite: false,
                  compare: false,
                  scompare: true,
                  link: false,
                  email: false,
                  download: false,
                  expressDownload: false,
                  print: false,
                  delete: false,
                  closeImage: false,
                  enlargeImage: false,
                  close: true,
                  setUpTitle: false,
                  imdone: false,
                  approved: false,
                  rejected: false,
                },
              },
              leftMenu: {
                visible: true,
                defaultOpen: true,
                items: {
                  advanceSearch: true,
                  customeFilter: true,
                  projectInfo: true,
                  retouch: false,
                  menu: {
                    visible: true,
                    items: {
                      albums: true,
                      imageInfo: true,
                      administrator: false,
                      audit: false,
                      signoff: false,
                      wideedit: false,
                      adhoc: false,
                    },
                  },
                },
              },
            },
          },
        ],
      },
    ],
  },
];
