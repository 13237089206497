<div mat-dialog-title class="fs-cursor-move d-flex justify-content-between">
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.image-exclusives.labels.label6'
        | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="container">
    <div class="row d-flex flex-column">
      <div class="col-4 col-form-label">
        {{
          'left-menu.tab-bar.sidemenus.administration.image-exclusives.labels.label7'
            | translate
        }}:
      </div>

      <div class="form-group">
        <input
          id="dofocus"
          type="text"
          class="form-control"
          name="exclusiveDis"
          value="exclusiveDis"
          [(ngModel)]="exclusiveDis"
        />
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button class="fs-dialog-btn w-auto ml-3" (click)="addExclusive()">
    {{
      'left-menu.tab-bar.sidemenus.administration.image-exclusives.button.save'
        | translate
    }}
  </button>
</div>
