import { Directive, EventEmitter, Injectable, Output } from '@angular/core';
import { Activemodal } from '../models/activemodal.model';

@Directive()
@Injectable()
export class Modalstatusservice {
  @Output() getActiveModal: EventEmitter<Activemodal> = new EventEmitter();

  setActiveModal(modal) {
    this.getActiveModal.emit(modal);
  }

  resetActiveModal() {
    this.getActiveModal.emit(new Activemodal());
  }
}
