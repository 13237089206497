<mat-icon class="fs-cursor-pointer" (click)="onPrevious()">
  keyboard_arrow_left
</mat-icon>
<div
  class="fs-overflow-auto fs-transition-details-container fs-scroll-table-body fs-header-sort-color fs-project-summary-report-table"
>
  <mat-table [hidden]="dataSource.data.length === 0" [dataSource]="dataSource">
    <ng-container matColumnDef="transactionDetails">
      <mat-header-cell class="pl-2 flex-grow-1" *matHeaderCellDef>
        {{ 'image-transaction.ddSubMenuLabel.transactionDetails' | translate }}
      </mat-header-cell>
      <mat-cell
        class="fs-txt-white-dark-color flex-grow-1"
        *matCellDef="let element"
      >
        <div class="container">
          <div class="row" *ngFor="let item of element.transactionDetails">
            <div class="col-6 pl-2 p-0 fs-column-border">
              {{ item[0] }}
            </div>
            <div class="col-6 pl-2 p-0 fs-column-border">
              {{ item[1] }}
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="browserInfo">
      <mat-header-cell class="flex-grow-1 pl-2" *matHeaderCellDef>
        {{ 'image-transaction.ddSubMenuLabel.browserInfo' | translate }}
      </mat-header-cell>
      <mat-cell
        class="fs-txt-white-dark-color flex-grow-1"
        *matCellDef="let element"
      >
        <div class="container">
          <div class="row">
            <div class="col-6 pl-2 p-0 fs-column-border">Name:</div>
            <div class="col-6 pl-2 p-0 fs-column-border">
              {{ element.browserInfo.name }}
            </div>
          </div>
          <div class="row">
            <div class="col-6 pl-2 p-0 fs-column-border">Version:</div>
            <div class="col-6 pl-2 p-0 fs-column-border">
              {{ element.browserInfo.version }}
            </div>
          </div>
          <div class="row">
            <div class="col-6 pl-2 p-0 fs-column-border">OS:</div>
            <div class="col-6 pl-2 p-0 fs-column-border">
              {{ element.browserInfo.os }}
            </div>
          </div>
          <div class="row">
            <div class="col-6 pl-2 p-0 fs-column-border">Type:</div>
            <div class="col-6 pl-2 p-0 fs-column-border">
              {{ element.browserInfo.type }}
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="imageNumber">
      <mat-header-cell class="flex-grow-1 pl-2" *matHeaderCellDef>
        {{ 'image-transaction.ddSubMenuLabel.imageNumber' | translate }}
      </mat-header-cell>
      <mat-cell
        class="fs-txt-white-dark-color flex-grow-1"
        *matCellDef="let element"
      >
        <div class="container">
          <div class="row">
            <div class="col-12 pl-2 p-0 fs-column-border">
              {{ element.imageNumber }}
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>

<ng-container *ngIf="dataSource.data.length === 0">
  <div class="p-5 text-center">
    {{ 'global.no-record' | translate }}
  </div>
</ng-container>
