<div class="hd-single header">
  <fs-top-menu></fs-top-menu>
</div>

<div class="wrap-inner">
  <mat-sidenav-container
    id="sidebar"
    class="sidebar-main photo-nav"
    *ngIf="currentState.displaySetting.leftMenu.visible && groupLogo != ''"
    [ngClass]="{
      'sidebar-main-notop': !currentState.displaySetting.topMenu.visible,
      active: !isLeftMenuPartShown
    }"
  >
    <div class="sidebar-position">
      <button
        id="sidebarCollapse"
        class="btn-slide"
        type="button"
        focusRemover
        [ngClass]="{ 'menu-toggle-button-rel': !isLeftMenuPartShown }"
        (click)="
          togglesidebar((this.isLeftMenuPartShown = !this.isLeftMenuPartShown))
        "
      >
        <img src="/assets/images/left-angle.png" alt="" />
      </button>
    </div>
    <mat-sidenav #sideBarWithPhotoNav [opened]="this.isLeftMenuPartShown" disableClose>
      <fs-left-menu [sidenavElementRef]="sideBarWithPhotoNav"></fs-left-menu>
    </mat-sidenav>
  </mat-sidenav-container>

  <mat-sidenav-container
    id="sidebar"
    class="sidebar-main without_photonav"
    *ngIf="currentState.displaySetting.leftMenu.visible && groupLogo == ''"
    [ngClass]="{
      'sidebar-main-notop': !currentState.displaySetting.topMenu.visible,
      active: !isLeftMenuPartShown
    }"
  >
    <div class="sidebar-position">
      <button
        id="sidebarCollapse"
        class="btn-slide"
        type="button"
        focusRemover
        [ngClass]="{ 'menu-toggle-button-rel': !isLeftMenuPartShown }"
        (click)="
          togglesidebar((this.isLeftMenuPartShown = !this.isLeftMenuPartShown))
        "
      >
        <img src="/assets/images/left-angle.png" alt="" />
      </button>
    </div>
    <mat-sidenav #sideBarWithoutPhotoNav [(opened)]="this.isLeftMenuPartShown" disableClose>
      <fs-left-menu [sidenavElementRef]="sideBarWithoutPhotoNav"></fs-left-menu>
    </mat-sidenav>
  </mat-sidenav-container>
  <div id="content" class="cnt-middle">
    <router-outlet></router-outlet>
  </div>
</div>
