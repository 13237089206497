import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[fsImagebuttonhover]',
})
export class ImagebuttonhoverDirective {
  constructor(private el: ElementRef) {}

  @HostListener('mouseenter') onMouseEnter() {
    this.highlight('rgb(255, 255, 255, 0.8');
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.highlight(null);
  }

  private highlight(color: string) {
    this.el.nativeElement.style.color = color;
  }
}
