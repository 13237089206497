import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from './user.service';
import { UserRole } from '../enum/user-role.enum';

@Injectable()
export class UserTaggerRoleGuard  {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(): Observable<boolean> {
    return of(
      !(
        this.userService.getCurrentUser().authorities[0] ===
          UserRole.ROLE_ADMIN &&
        this.userService.getCurrentUser().userProfileDTO.tagger
      ),
    );
  }
}
