import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SidebarService } from '../../../sidebar-service/sidebar-service';
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { Modalstatusservice } from '../../../../../shared/services/modalstatus.service';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { Utils } from '../../../../../shared/utils/utils';
import { SelectedImagesStore } from '../../../../../shared/store/selected-images-store';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-book-mark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.scss'],
})
export class BookMarkComponent extends UnsubscriberComponent implements OnInit {
  objCurrentBookmarkData: any = {};
  bookMarkDescription = '';
  arrListExistingBookmarkData: any = [];
  selectedBookmarkDataCount = 0;
  gotoSettings: any = {};
  selectedIdToDelete = '';
  imageName = '';
  albumList: any = [];
  dataTaggingWith: any = [];

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  constructor(
    private viewProjectService: ViewProjectService,
    private modalService: Modalstatusservice,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private sidebarService: SidebarService,
    private dialogService: DialogService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private selectedImagesStore: SelectedImagesStore,
    private dialogRef: MatDialogRef<BookMarkComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { projectId: any; currentFilter: any },
  ) {
    super();
  }

  ngOnInit() {
    this.sidebarService.albumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.albumList = Utils.getValuesByKey(albumList.albums, 'id');
        }
      });

    this.sidebarService.updatedAlbumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.albumList = Utils.getValuesByKey(albumList, 'id');
        }
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.dataTaggingWith = res.projectTagListDTO;
      });

    this.getBookMarkOnLoad();
    this.objCurrentBookmarkData['projectId'] = this.data.projectId;
    this.objCurrentBookmarkData['filter'] = this.data.currentFilter;
  }

  confirmDialogForTagging(msg) {
    this.dialogService
      .openConfirmationDialog({
        message: msg.trim(),
        title: this.translate.instant('confirmDialog.bookmark.title'),
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: boolean) => {
        if (state) {
          this.geleteBookMarkData();
        }
      });
  }

  getBookMarkOnLoad() {
    this.loaderService.displayLoader(true);
    this.objCurrentBookmarkData['assetId'] =
      this.selectedImagesStore.getItemsIds()[0];
    const assetIds = [];
    assetIds.push(this.selectedImagesStore.getItemsIds()[0]);
    const data = {
      assetIds: assetIds,
      projectId: this.data.projectId,
    };

    this.viewProjectService
      .getImagesNameBasedOnAssetsId(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((resData) => {
        this.imageName = resData[0];
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });

    this.viewProjectService
      .getBookMarkData(this.data.projectId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response) {
          this.arrListExistingBookmarkData = response;
        } else {
          this.arrListExistingBookmarkData = [];
        }
        this.loaderService.displayLoader(false);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  onDescriptionChange() {
    this.objCurrentBookmarkData['description'] = this.bookMarkDescription;
  }

  onClickAddBtn() {
    this.viewProjectService
      .createBookMark(this.objCurrentBookmarkData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        const tempResponse = response;
        tempResponse.assetName = this.imageName;
        this.arrListExistingBookmarkData.push(tempResponse);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  onChangeDesCheckbox(currentData) {
    this.selectedBookmarkDataCount = 0;
    currentData.isSelected = !currentData.isSelected;
    this.checkBookMarkDataSelected();
  }

  checkBookMarkDataSelected() {
    this.selectedIdToDelete = '';
    this.arrListExistingBookmarkData.forEach((objBookmarkData) => {
      if (objBookmarkData['isSelected']) {
        this.selectedBookmarkDataCount += 1;
        this.selectedIdToDelete += objBookmarkData.id + ',';
      }
    });
  }

  onClickSelectAll() {
    this.selectedIdToDelete = '';
    this.arrListExistingBookmarkData.forEach((objBookmarkData) => {
      if (!objBookmarkData.isSelected) {
        objBookmarkData['isSelected'] = true;
      }
      this.selectedIdToDelete += objBookmarkData.id + ',';
    });
    this.selectedBookmarkDataCount = this.arrListExistingBookmarkData.length;
  }

  onClickGoBtn(objCurrentBookmarkData) {
    const restartDataDTO = {
      assetId: objCurrentBookmarkData.assetId,
      filter: objCurrentBookmarkData.filter,
    };
    this.viewProjectService.setRestartDataDTO(restartDataDTO);
    this.viewProjectService.assignFilter(objCurrentBookmarkData.filter);
    this.closeWindow();
  }

  onClickDeleteBtn() {
    this.confirmDialogForTagging(
      this.translate.instant('confirmDialog.bookmark.message'),
    );
  }

  geleteBookMarkData() {
    const idsToDelete = this.selectedIdToDelete.slice(0, -1);
    this.viewProjectService
      .deleteBookmark(this.data.projectId, idsToDelete)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.selectedBookmarkDataCount = 0;
        this.reloadDataAfterDelete();
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  reloadDataAfterDelete() {
    const originalData = [];
    this.arrListExistingBookmarkData.forEach((objBookmarkData, indx) => {
      if (!objBookmarkData.isSelected) {
        originalData.push(objBookmarkData);
      }
    });
    this.arrListExistingBookmarkData = [];
    this.arrListExistingBookmarkData = originalData;
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
