import { Component, OnInit } from '@angular/core';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../../../../../../shared/utils/utils';
import { Observable } from 'rxjs';
import { LauncherService } from '../../../../../launcher/launcher.service';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { LogoService } from '../../../../../../shared/services/logo.service';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'fs-executive-projectinfo',
  templateUrl: './executive-projectinfo.component.html',
  styleUrls: ['./executive-projectinfo.component.scss'],
})
export class ExecutiveProjectinfoComponent
  extends UnsubscriberComponent
  implements OnInit
{
  logoName = '';
  shootDate = '';
  photoCredit = '';
  alternativeLogo$: Observable<string>;
  constructor(
    private launcherService: LauncherService,
    private viewProjectService: ViewProjectService,
    private logoService: LogoService,
  ) {
    super();
  }

  ngOnInit() {
    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.logoName = data.projectGroupName.toLowerCase();
          this.shootDate = data.shootDate;
          this.photoCredit = data.photoCredit;
        }
      });

    this.alternativeLogo$ = this.logoService.getDepartmentLogo();
  }
}
