import { Component, OnInit } from '@angular/core';
import { ExpressDownloadStoreService } from '../../services/express-download-store.service';
import { DialogService } from '../../services/dialog/dialog.service';
import { ExpressDownloadComponent } from '../../../dashboard/project/dashboard-shared/top-menu/express-download/express-download.component';
import { ExpressDownloadResultData } from '../../../dashboard/project/dashboard-shared/top-menu/express-download/express-download-assets-data.model';

@Component({
  selector: 'fs-express-download-results-container',
  templateUrl: './express-download-results-container.component.html',
  styleUrls: ['./express-download-results-container.component.scss'],
})
export class ExpressDownloadResultsContainerComponent implements OnInit {
  expressDownloadResultPool: ExpressDownloadResultData[] = [];

  constructor(
    private dialogService: DialogService,
    public expressDownloadStoreService: ExpressDownloadStoreService,
  ) {}

  ngOnInit(): void {
    this.expressDownloadStoreService.expressDownloadResultsGlobal.subscribe(
      (expressDownloadItems: ExpressDownloadResultData[]) =>
        (this.expressDownloadResultPool = expressDownloadItems.reverse()),
    );
  }

  onOpenExpressDownloadFinalStep(
    expressDownloadResult: ExpressDownloadResultData,
  ): void {
    this.openExpressDownloadComponent(expressDownloadResult);
    this.expressDownloadStoreService.removeExpressDownloadResultFromPool(
      expressDownloadResult.id,
    );
  }

  openExpressDownloadComponent(result: ExpressDownloadResultData): void {
    if (
      this.dialogService.getDialogState('ExpressDownloadComponent').currentState
        .opened
    ) {
      this.dialogService.closeDialogById('ExpressDownloadComponent');
    }
    setTimeout(() => {
      this.dialogService.open(ExpressDownloadComponent, {
        panelClass: [
          'fs-dialog-panel',
          'fs-dialog-size-lg-panel',
          'fs-dialog-link-panel',
        ],
        listener: {
          withListener: true,
          id: 'ExpressDownloadComponent',
        },
        data: {
          albumId: result.albumId,
          stepIndex: result.lastIndexStep,
          token: result.token,
          assetsCount: result.assetsCount,
        },
      });
    }, 200);
  }
}
