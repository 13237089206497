import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { LinkService } from './link.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../../../shared/services/snack-bar.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Modalstatusservice } from '../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  ConstantsGlobal,
  EmailPattern,
} from '../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../shared/store/selected-images-store';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { Utils } from '../../../../../shared/utils/utils';
import { IpService } from '../../../../../shared/services/ip-service.service';
import { UserService } from '../../../../../shared/services/user.service';
import { LinkSettingsData } from './link.modal';
import { UserRole } from '../../../../../shared/enum/user-role.enum';
import { DownloadType } from '../../../../../shared/models/enum/download-type';
import { ProjectRole } from '../../../../../shared/enum/project-role.enum';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent extends UnsubscriberComponent implements OnInit {
  @ViewChild('limitTo') limitto;
  @ViewChild('passPhrase') passphrase;

  adhocLinkForm: UntypedFormGroup;
  selectedImages = [];
  disabledAllowDownloadValue = true;
  disabledExpressAllowDownloadValue = true;
  toEmail: any;
  isAdhocExtraSecured: boolean;
  projectAuthority: string;
  userAuthority: string;
  constantsGlobalData: any;
  downloadTypes = [];
  minExpressDownload: number;
  expressDownload = false;
  expressDownloadTypes = [];
  downloadType: number;
  hasWatermark = false;
  userRole = UserRole;
  projectRole = ProjectRole;
  DOWNLOAD_TYPES = [
    {
      id: '1',
      name: 'Small',
      trans: this.translate.instant('top-menu.linkTool.labels.small'),
    },
    {
      id: '2',
      name: 'Medium',
      trans: this.translate.instant('top-menu.linkTool.labels.medium'),
    },
    {
      id: '3',
      name: 'Large',
      trans: this.translate.instant('top-menu.linkTool.labels.large'),
    },
  ];

  EXPRESS_DOWNLOAD_TYPES = [
    {
      id: '3',
      name: 'HIGH RES JPEG',
      trans: this.translate.instant(
        'top-menu.expressDownload.sizeOptions.highResJPEG',
      ),
    },
  ];

  constructor(
    private modalService: Modalstatusservice,
    private fb: FormBuilder,
    private linkService: LinkService,
    private loaderService: LoaderService,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
    private ipService: IpService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private userService: UserService,
    private translate: TranslateService,
    public viewProjectService: ViewProjectService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<LinkComponent>,
    public selectedImagesStore: SelectedImagesStore,
  ) {
    super();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority) => {
        this.userAuthority = userAuthority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.downloadType = data.permissionDTO.downloadType;
          this.expressDownload = data.permissionDTO.expressDownload;
          this.hasWatermark = data.permissionDTO.watermark;
          this.minExpressDownload = data.permissionDTO.minExpressDownload;
          this.isAdhocExtraSecured = data.permissionDTO.isAdhocExtraSecured;
        }
      });

    this.adhocLinkForm = this.fb.group(
      {
        to: [
          [],
          Validators.compose([Validators.required, this.emailArrayValidator]),
        ],
        subject: ['', Validators.required],
        message: [''],
        passwordEmails: [[]],
        linkExpires: ['', Validators.required],
        numberOfViews: this.fb.group({
          isCheckedValue: 'unlimited',
          value: [{ value: '', disabled: true }, Validators.required],
        }),
        addPassphrase: this.fb.group({
          name: 'Add Passphrase',
          isChecked: this.isAdhocExtraSecured,
          value: [
            { value: '', disabled: !this.isAdhocExtraSecured },
            Validators.compose([Validators.required, Validators.minLength(6)]),
          ],
        }),
        allowDownload: this.fb.group({
          name: 'Allow Download',
          isChecked: false,
          value: false,
        }),
        allowExpressDownload: this.fb.group({
          name: 'Allow Express Download',
          isChecked: false,
          value: false,
        }),
        downloadTypeValue: new UntypedFormArray([]),
        expressDownloadTypeValue: new UntypedFormArray([]),
        rememberSetting: [false],
      },
      { validators: this.checkFieldsMatch('to', 'passwordEmails') },
    );

    this.initPasswordEmailValidators();
    this.initDownloadTypes();

    this.selectedImages = this.data.t;

    if (!Utils.isObjectEmpty(this.data.adhocSettingsDTO)) {
      this.setRememberSettings(this.data.adhocSettingsDTO);
      this.initEnableFormFields(this.data.adhocSettingsDTO);
    }

    this.setLinkExpireFormState();
  }

  initEnableFormFields(data) {
    if (data.allowDownload) {
      this.disabledAllowDownloadValue = false;
    }

    if (data.allowExpressDownload) {
      this.disabledExpressAllowDownloadValue = false;
    }

    if (this.isAdhocExtraSecured) {
      this.adhocLinkForm.get('addPassphrase').get('value').enable();
    } else if (this.isPassphraseCheckboxAvailable(data.passphrase)) {
      this.adhocLinkForm.get('addPassphrase').get('value').enable();
    }

    if (data.numberOfViews) {
      this.adhocLinkForm.get('numberOfViews').get('value').enable();
    }
  }

  setLinkExpireFormState() {
    if (
      !this.adhocLinkForm.get('linkExpires').value ||
      this.adhocLinkForm.get('linkExpires').value === '0'
    ) {
      this.adhocLinkForm.get('linkExpires').disable();
      this.adhocLinkForm.get('linkExpires').setValue('');
    }
  }

  isPassphraseCheckboxAvailable(value: string): boolean {
    return !!value;
  }

  initPasswordEmailValidators(): void {
    if (this.isAdhocExtraSecured) {
      this.adhocLinkForm
        .get('passwordEmails')
        .setValidators([Validators.required]);
    } else {
      this.adhocLinkForm.get('passwordEmails').clearValidators();
    }
    this.adhocLinkForm.get('passwordEmails').updateValueAndValidity();
  }

  initDownloadTypes() {
    this.downloadTypes = JSON.parse(JSON.stringify(this.DOWNLOAD_TYPES));
    this.expressDownloadTypes = JSON.parse(
      JSON.stringify(this.EXPRESS_DOWNLOAD_TYPES),
    );

    if (
      this.userAuthority === UserRole.ROLE_ADMIN ||
      this.downloadType !== 10
    ) {
      this.downloadTypes.push({
        id: '4',
        name: 'Raw',
        trans: this.translate.instant('top-menu.linkTool.labels.raw'),
      });
    }

    if (
      (this.userAuthority === UserRole.ROLE_EXTERNAL &&
        this.projectAuthority === ProjectRole.ROLE_EXEC &&
        this.downloadType === DownloadType.ALL) ||
      this.userAuthority === UserRole.ROLE_ADMIN
    ) {
      this.expressDownloadTypes.push({
        id: '4',
        name: 'Raw',
        trans: this.translate.instant('top-menu.linkTool.labels.raw'),
      });
    }
  }

  setRememberSettings(adhocSettingsDTO) {
    this.setDownloadTypeValues(adhocSettingsDTO);
    this.setExpressDownloadTypeValues(adhocSettingsDTO);

    this.adhocLinkForm.patchValue({
      linkExpires: adhocSettingsDTO.linkExpires.toString(),
      numberOfViews: {
        isCheckedValue:
          adhocSettingsDTO.numberOfViews === 0 ? 'unlimited' : 'limitto',
        value:
          adhocSettingsDTO.numberOfViews === 0
            ? ''
            : adhocSettingsDTO.numberOfViews,
      },
      addPassphrase: {
        name: 'Add Passphrase',
        isChecked:
          this.isAdhocExtraSecured ||
          this.isPassphraseCheckboxAvailable(adhocSettingsDTO.passphrase),
        value: adhocSettingsDTO.passphrase,
      },
      allowDownload: {
        name: 'Allow Download',
        isChecked: adhocSettingsDTO.allowDownload,
        value: adhocSettingsDTO.allowDownload
          ? adhocSettingsDTO.downloadSize
          : '',
      },
      allowExpressDownload: {
        name: 'Allow Express Download',
        isChecked: adhocSettingsDTO.allowExpressDownload,
        value: adhocSettingsDTO.allowExpressDownload
          ? adhocSettingsDTO.expressDownloadSize
          : '',
      },
      rememberSetting: true,
    });
  }

  setDownloadTypeValues(adhocSettingsDTO) {
    const downloadTypeValue: UntypedFormArray = this.adhocLinkForm.get(
      'downloadTypeValue',
    ) as UntypedFormArray;
    const downloadSizeList = adhocSettingsDTO.downloadSize.split(',');

    if (adhocSettingsDTO.allowDownload && downloadSizeList.length) {
      downloadSizeList.forEach((item) =>
        downloadTypeValue.push(new UntypedFormControl(item)),
      );
    }
  }

  setExpressDownloadTypeValues(adhocSettingsDTO) {
    const expressDownloadTypeValue: UntypedFormArray = this.adhocLinkForm.get(
      'expressDownloadTypeValue',
    ) as UntypedFormArray;
    const expressDownloadSizeList =
      adhocSettingsDTO.expressDownloadSize.split(',');

    if (
      adhocSettingsDTO.allowExpressDownload &&
      expressDownloadSizeList.length
    ) {
      expressDownloadSizeList.forEach((item) =>
        expressDownloadTypeValue.push(new UntypedFormControl(item)),
      );
    }
  }

  addLinkExpireNumber(event) {
    if (event.checked) {
      this.adhocLinkForm.get('linkExpires').enable();
      this.adhocLinkForm.get('linkExpires').setValue('1');
    } else {
      this.adhocLinkForm.get('linkExpires').setValue('');
      this.adhocLinkForm.get('linkExpires').disable();
    }
  }

  addPassphraseCheackBoxChange(event) {
    if (event.checked) {
      this.adhocLinkForm.get('addPassphrase').get('value').enable();
      setTimeout(() => this.passphrase.nativeElement.focus(), 0);
    } else {
      this.adhocLinkForm.patchValue({
        addPassphrase: {
          value: '',
        },
      });
      this.adhocLinkForm.get('addPassphrase').get('value').disable();
    }
  }

  numberOfViewsRadioChange(event) {
    if (event.value === 'limitto') {
      setTimeout(() => this.limitto.nativeElement.focus(), 0);
      this.adhocLinkForm.get('numberOfViews').get('value').enable();
    } else {
      this.adhocLinkForm.patchValue({
        numberOfViews: {
          value: '',
        },
      });
      this.adhocLinkForm.get('numberOfViews').get('value').disable();
    }
  }

  allowDownloadCheackBoxChange() {
    const formArray: UntypedFormArray = this.adhocLinkForm.get(
      'downloadTypeValue',
    ) as UntypedFormArray;
    if (this.adhocLinkForm.value.allowDownload.isChecked) {
      this.disabledAllowDownloadValue = false;
      formArray.push(new UntypedFormControl('1'));
    } else {
      formArray.clear();
      this.adhocLinkForm.value.downloadTypeValue = [];
      this.disabledAllowDownloadValue = true;
    }
  }

  allowDownloadTypeCheackBoxChange(
    event: MatCheckboxChange,
    type: number,
    fieldName,
  ) {
    const formArray: UntypedFormArray = this.adhocLinkForm.get(
      fieldName,
    ) as UntypedFormArray;
    if (event.checked) {
      if (!formArray.value.includes(type)) {
        formArray.push(new UntypedFormControl(type));
      }
    } else {
      const index = formArray.value.indexOf(type);
      if (index !== -1) {
        formArray.removeAt(index);
      }

      if (!formArray.controls.length) {
        formArray.clear();
        this.adhocLinkForm
          .get('allowDownload')
          .get('isChecked')
          .setValue(false);
        this.disabledAllowDownloadValue = true;
      }
    }
  }

  allowExpressDownloadCheackBoxChange() {
    const formArray: UntypedFormArray = this.adhocLinkForm.get(
      'expressDownloadTypeValue',
    ) as UntypedFormArray;
    if (this.adhocLinkForm.value.allowExpressDownload.isChecked) {
      this.disabledExpressAllowDownloadValue = false;
      formArray.push(new UntypedFormControl('3'));
    } else {
      formArray.clear();
      this.adhocLinkForm.value.expressDownloadTypeValue = [];
      this.disabledExpressAllowDownloadValue = true;
    }
  }

  submitForm() {
    let allowableViewCount;
    if (this.adhocLinkForm.value.numberOfViews.isCheckedValue === 'unlimited') {
      allowableViewCount = 0;
    } else if (
      this.adhocLinkForm.value.numberOfViews.isCheckedValue === 'limitto'
    ) {
      allowableViewCount = this.adhocLinkForm.value.numberOfViews.value;
    }
    const emails = this.adhocLinkForm.value.to;
    const assetIds =
      this.selectedImages.length > 0
        ? this.selectedImages.map((val) => val.id)
        : this.selectedImagesStore.getItemsIds();
    const obj = {
      ip: this.ipService.userIPAddress$,
      browserInfo: this.ipService.browserInfo$,
      userId: this.userService.getCurrentUser().id,
      authorityName:
        this.userAuthority === UserRole.ROLE_ADMIN
          ? this.userAuthority
          : this.projectAuthority,
      userName: this.userService.getFullUserName(),
      projectName:
        this.viewProjectService.getProjectDetailPermissionData().projectName,
      projectId: this.viewProjectService.getProjectId(),
      allowableViewCount: allowableViewCount,
      assetIds,
      downloadSize: this.adhocLinkForm.value.allowDownload.isChecked
        ? this.adhocLinkForm.value.downloadTypeValue.join()
        : '',
      expressDownloadSize: this.adhocLinkForm.value.allowExpressDownload
        .isChecked
        ? this.adhocLinkForm.value.expressDownloadTypeValue.join()
        : '',
      emails,
      id: 0,
      message: this.adhocLinkForm.value.message,
      linkExpires: this.adhocLinkForm.value.linkExpires,
      rememberSetting: this.adhocLinkForm.value.rememberSetting,
      subject: this.adhocLinkForm.value.subject,
      watermark: '',
      passphrase: this.isAdhocExtraSecured
        ? this.adhocLinkForm.value.addPassphrase.value
        : this.adhocLinkForm.value.addPassphrase.isChecked
        ? this.adhocLinkForm.value.addPassphrase.value
        : '',
      sendingUrl: window.location.href.split('/dashboard')[0], // todo fix it regexp match(/(?<=\/\/)(.*?)(?=\/)/)[0] doesnt work on safari
      ...(this.isAdhocExtraSecured
        ? {
            passwordEmails: this.adhocLinkForm.value.passwordEmails,
          }
        : {}),
    };

    this.loaderService.displayLoader(true);

    this.linkService
      .postAdhocAlbumData(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.loaderService.displayLoader(false);
          if (res.status) {
            this.snackBarService.showSnackBar(res.message);
            this.dialogRef.close();
          } else {
            this.snackBarService.showSnackBarError(res.message);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  onResetForm() {
    if (!this.isAdhocExtraSecured) {
      this.adhocLinkForm.get('addPassphrase').get('value').disable();
    }

    this.disabledAllowDownloadValue = true;
    this.adhocLinkForm.get('linkExpires').disable();
    this.disabledExpressAllowDownloadValue = true;
    this.adhocLinkForm.get('numberOfViews').get('value').disable();
    this.adhocLinkForm.get('allowDownload').get('value').disable();
    this.adhocLinkForm.get('allowExpressDownload').get('value').disable();
    (this.adhocLinkForm.get('downloadTypeValue') as FormArray).clear();
    this.adhocLinkForm.reset(JSON.parse(JSON.stringify(LinkSettingsData)));
  }

  closeWindow() {
    this.dialogRef.close();
  }

  onAdValidateEmail(event: MatChipInputEvent) {
    const toEmailsControl = this.adhocLinkForm.get('to');
    const emails = toEmailsControl.value;

    if ((!emails.length || !emails.includes(event.value)) && event.value) {
      emails.push(event.value);
      toEmailsControl.updateValueAndValidity();
    }

    event.input.value = '';
  }

  onAdValidatePasswordEmails(event: MatChipInputEvent) {
    const passwordEmailsControl = this.adhocLinkForm.get('passwordEmails');
    const emails = passwordEmailsControl.value;

    if ((!emails.length || !emails.includes(event.value)) && event.value) {
      emails.push(event.value);
      passwordEmailsControl.updateValueAndValidity();
    }

    event.input.value = '';
  }

  onRemoveEmailId(index: number) {
    const toEmailControl = this.adhocLinkForm.get('to');
    const emails = toEmailControl.value;
    emails.splice(index, 1);
    toEmailControl.updateValueAndValidity();
  }

  isPasswordEmailsInvalid(): boolean {
    if (!this.isAdhocExtraSecured) {
      return false;
    }

    const passwordEmailsControl = this.adhocLinkForm.get('passwordEmails');
    if (passwordEmailsControl.value.length === 0) {
      passwordEmailsControl.setErrors({ emptyField: true });
    }

    return passwordEmailsControl.value.some((email) => {
      if (email !== '' && !EmailPattern.test(email)) {
        passwordEmailsControl.setErrors({ invalidEmail: true });
        return true;
      }
      return false;
    });
  }

  onRemovePasswordEmailsId(index: number) {
    const passwordEmailControls = this.adhocLinkForm.get('passwordEmails');
    const emails = passwordEmailControls.value;
    emails.splice(index, 1);
    passwordEmailControls.updateValueAndValidity();
  }

  private checkFieldsMatch(
    to: string,
    passwordEmail: string,
  ): (formGroup: FormGroup) => void {
    if (!this.isAdhocExtraSecured) {
      return null;
    }

    return (formGroup: FormGroup) => {
      const controlTo = formGroup.get(to);
      const controlPasswordEmails = formGroup.get(passwordEmail);

      const emailsTo: string[] = controlTo.value;
      const passwordEmails: string[] = controlPasswordEmails.value;
      const hasMatch = emailsTo.some((email) =>
        passwordEmails.some((passwordEmail) => email === passwordEmail),
      );
      hasMatch
        ? controlPasswordEmails.setErrors({ fieldsMatch: true })
        : controlPasswordEmails.setErrors(null);
    };
  }

  private emailArrayValidator: ValidatorFn = (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    const emails = control.value as string[];
    for (const email of emails) {
      if (!EmailPattern.test(email)) {
        return { invalidEmailList: true };
      }
    }
    return null;
  };

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }
}
