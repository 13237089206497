<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex align-items-center justify-content-between"
>
  <div class="modal-title" id="taggign_view_1">
    {{ 'image-caption.removeShortCut' | translate }}
  </div>
  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    (click)="closeDialog()"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle"></i>
  </button>
</div>

<div mat-dialog-content>
  <span class="main-title fs-md-txt text-center">{{
    'image-caption.check' | translate
  }}</span>
  <div class="remove-data-list">
    <div class="remove-data-list_item row d-flex">
      <span class="col-2">{{ 'image-caption.key' | translate }}</span>
      <span class="col-8">{{
        'image-caption.description' | translate
      }}</span>
    </div>
    <div
      class="remove-data-list_item row d-flex"
      *ngFor="let key of captionKeys; let i = index"
      [attr.data-index]="i"
    >
      <span class="col-2">
        <mat-checkbox
          id="{{ key.id }}"
          class="fs-checkbox"
          (change)="onFilterKeysChangeFn({ e: $event, removeKey: key.id })"
        >
          <span class="cb-title">{{ key.keyText }}</span>
        </mat-checkbox>
      </span>
      <span class="col-8">{{ key.value }}</span>
    </div>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <button
    value="close"
    class="fs-dialog-btn w-auto ml-3"
    (click)="closeDialog()"
  >
    {{ 'image-caption.buttons.close' | translate }}
  </button>
  <button value="Add" class="fs-dialog-btn w-auto ml-3" (click)="removeKeyFn()">
    {{ 'image-caption.buttons.delete' | translate }}
  </button>
</div>
