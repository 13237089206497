import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DashboardEvents } from './interfaces/dashboard-rule.model';

@Injectable()
export class DashboardEventBus {
  private dashboardEvent: Subject<DashboardEvents> =
    new Subject<DashboardEvents>();

  events$(): Observable<DashboardEvents> {
    return this.dashboardEvent.asObservable();
  }

  emit(ruleSet: DashboardEvents) {
    this.dashboardEvent.next(ruleSet);
  }
}
