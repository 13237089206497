import { Utils } from '../../../../../shared/utils/utils';
import { Router } from '@angular/router';
import { DomainConfigService } from '../../../../../shared/services/domain-config.service';
import { LauncherService } from '../../../../launcher/launcher.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';

import { AppSource } from '../../../../../shared/models/enum/AppSource';
import { SidebarService } from '../../../../project/sidebar-service/sidebar-service';
import { SelectedProjectModel } from '../interfaces/dashboard-launcher-rule.model';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { PROJECT } from '../../../../../shared/constants/constants';
import { FilterEnum } from '../../../../../shared/enum/filter.enum';
import { ProjectRoleId } from '../../../../../shared/enum/project-role.enum';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardLauncherTalentProjectPkoRule
  implements DashboardRuleModel
{
  private readonly DEFAULT_PAGE_NUMBER = 1;

  private router = GetStaticInjector().get(Router);
  private launcherService = GetStaticInjector().get(LauncherService);
  private viewProjectService = GetStaticInjector().get(ViewProjectService);
  private sidebarService = GetStaticInjector().get(SidebarService);
  private domainConfigService = GetStaticInjector().get(DomainConfigService);

  constructor(
    private groupType: string,
    private selectedProject: SelectedProjectModel,
  ) {}

  matches(): boolean {
    return (
      this.groupType === PROJECT &&
      this.domainConfigService.getDomainConfigData().abbr === AppSource.ALL &&
      (this.selectedProject.role === Number(ProjectRoleId.ROLE_TALENT) ||
        (this.selectedProject.role === Number(ProjectRoleId.ROLE_EXEC) &&
          !this.selectedProject.useSetup))
    );
  }

  apply(): void {
    this.viewProjectService.setSelectedProjectData(this.selectedProject);
    this.launcherService.setSelectedProject(this.selectedProject.value);
    this.launcherService.setSelectedProjectName(this.selectedProject.label);
    this.launcherService.updateProjectChangeState(true);
    this.router.navigate([
      Utils.prepareFilterNavigationURL(
        this.selectedProject.role,
        this.selectedProject.value,
        FilterEnum.ShowAll,
        this.DEFAULT_PAGE_NUMBER,
      ),
    ]);
    this.sidebarService.resetAlbumImageData();
  }
}
