import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';

/**
 * This component is used as parent component for all components, which use destroy subject logic for unsubscribe from subscriptions
 */
@Directive()
export abstract class UnsubscriberComponent implements OnDestroy {
  destroy$ = new Subject(); // subject to call on ngOnDestroy to unsubscribe all subscriptions

  ngOnDestroy(): void {
    this.destroy$.next(true); // call to unsubscribe all subscriptions
    this.destroy$.complete(); // call to complete the destroy subject
  }
}
