<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title" id="delelabel">
    {{ 'top-menu.deleteAssets.title' | translate }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content [formGroup]="deleteForm" (ngSubmit)="submitForm()">
  <div class="col-12 fs-md-txt text-center my-2">
    {{ 'top-menu.deleteAssets.confirm' | translate }}
  </div>

  <div class="d-flex">
    <div class="col-2 text-center"></div>
    <div class="col-8">
      <fieldset>
        <fieldset class="form-group">
          <input
            type="text"
            placeholder="{{ 'top-menu.deleteAssets.delete' | translate }}"
            formControlName="deleteasset"
            name="deleteasset"
            class="form-control"
          />
        </fieldset>
      </fieldset>
    </div>
    <div class="col-2 text-center"></div>
  </div>

  <div
    class="col-lg-12 col-md-12 col-sm-12 col-xs-7 fs-md-txt text-center my-2"
  >
    {{ 'top-menu.deleteAssets.intimation' | translate }}
  </div>
</div>

<div mat-dialog-actions align="end">
  <button
    class="fs-dialog-btn w-auto ml-3"
    [disabled]="!(deleteForm.value.deleteasset == DELETE_KEY)"
    (click)="submitForm()"
  >
    {{ 'top-menu.deleteAssets.buttons.confirm' | translate }}
  </button>
</div>
