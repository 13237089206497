import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { LauncherService } from '../../../../launcher/launcher.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { SearchProjectModel } from '../interfaces/dashboard-search-project-rule.model';
import { Utils } from '../../../../../shared/utils/utils';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardSearchDepartmentWideSingOffRule
  implements DashboardRuleModel
{
  private readonly DEFAULT_PAGE_NUMBER = 1;

  private router = GetStaticInjector().get(Router);
  private launcherService = GetStaticInjector().get(LauncherService);
  private viewProjectService = GetStaticInjector().get(ViewProjectService);

  constructor(
    private fromDepartment: boolean,
    private selectedProjectId: number,
    private selectedProjectData: SearchProjectModel,
  ) {}

  matches(): boolean {
    return (
      this.fromDepartment &&
      (this.selectedProjectId === 3 || this.selectedProjectId === 4)
    );
  }

  apply(): void {
    this.launcherService.setSelectedProjectName(this.selectedProjectData.label);
    this.viewProjectService.setExecutiveAlbumID(this.selectedProjectData.value);
    this.launcherService.setSelectedProject(this.selectedProjectId);
    this.router.navigate([
      Utils.prepareFilterNavigationURL(
        '1',
        this.selectedProjectData.groupId,
        'album:' + this.selectedProjectData.value,
        this.DEFAULT_PAGE_NUMBER,
      ),
    ]);
  }
}
