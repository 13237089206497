import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettings } from '../../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../../shared/services/api.service';

@Injectable()
export class TalentManagementService {
  constructor(private apiService: ApiService) {}

  submitTalentManagementData(projectId): Observable<any> {
    const path = AppSettings.TALENT_MANAGEMENT(projectId);
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getTalentManagementData(data, projectId): Observable<any> {
    const path = AppSettings.UPDATE_TALENT_MANAGEMENT(projectId);
    return this.apiService.put(path, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getUserData(projectId: number, userIds: number[]): Observable<any> {
    const path = AppSettings.USER_DETAILS(projectId) + userIds;
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }
}
