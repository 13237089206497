<div class="sub-menu-list image-info-content m-2 justify-content-start">
  <div class="d-block">
    <div class="fs-image-info-toggle-container d-flex">
      <ng-container *ngIf="projectAuthority != projectRole.ROLE_TALENT">
        <div [ngClass]="{ active: imageInfoTab == 'data' }">
          <span
            id="kkkk"
            class="fs-cursor-pointer mr-2 text-uppercase fs-txt-white-grey-color-hover"
            *ngIf="projectAuthority != projectRole.ROLE_TALENT"
            (click)="noteDataTab('data')"
          >
            {{
              'left-menu.tab-bar.sidemenus.imageInfo.tab.data.title' | translate
            }}
          </span>
        </div>
        <div class="fs-vertical-line"></div>
        <div [ngClass]="{ active: imageInfoTab == 'notes' }">
          <span
            class="fs-cursor-pointer ml-2 text-uppercase fs-txt-white-grey-color-hover"
            (click)="noteDataTab('notes')"
          >
            {{
              'left-menu.tab-bar.sidemenus.imageInfo.tab.note.title' | translate
            }}
          </span>
        </div>
      </ng-container>
    </div>

    <div #scrollMe class="d-block">
      <ng-scrollbar autoHeightDisabled="false" [disabled]="hideDataNoteListOption" trackY="'true'">
        <div>
          <div
            [class.d-none]="imageInfoTab !== 'data' || hideDataNoteListOption"
            [class.d-block]="imageInfoTab === 'data' && !hideDataNoteListOption"
          >
            <div
              class="fs-popup-img-container"
              *ngIf="selectedImages.length > 1"
            >
              {{
                'left-menu.tab-bar.sidemenus.imageInfo.labels.multipleImageLabel'
                  | translate
              }}
            </div>

            <div
              class="fs-popup-img-container"
              *ngIf="selectedImages.length == 0"
            >
              {{
                'left-menu.tab-bar.sidemenus.imageInfo.labels.noImageLabel'
                  | translate
              }}
            </div>

            <div
              class="container"
              *ngIf="
                selectedImages.length == 1 &&
                projectAuthority != projectRole.ROLE_TALENT &&
                selectedProjectValue !== 3 &&
                selectedProjectValue !== 4
              "
            >
              <div class="fs-sm-txt row pb-1">
                <div class="col-4 pr-0 font-weight-bold">
                  {{
                    'left-menu.tab-bar.sidemenus.imageInfo.tab.data.released'
                      | translate
                  }}:
                </div>
                <div class="col-8 pl-1">
                  <div
                    *ngIf="
                      imgInfo.releasedDate.length > 0 &&
                      imgInfo.releasedDate !== 'N/A'
                    "
                  >
                    {{ imgInfo.releasedDate | date : 'yyyy-MM-dd' }} &#64;
                    {{ imgInfo.releasedDate | date : 'hh:mm:ss' }}
                  </div>
                </div>
              </div>

              <div class="fs-sm-txt row pb-1">
                <div class="col-4 pr-0 font-weight-bold">
                  {{
                    'left-menu.tab-bar.sidemenus.imageInfo.tab.data.batch'
                      | translate
                  }}:
                </div>
                <div class="col-8 pl-1 fs-word-break-all">{{ imgInfo.batch }}</div>
              </div>

              <div class="fs-sm-txt row pb-1">
                <div class="col-4 pr-0 font-weight-bold">
                  {{
                    'left-menu.tab-bar.sidemenus.imageInfo.tab.data.image'
                      | translate
                  }}:
                </div>
                <div class="col-8 pl-1">{{ imgInfo.name }}</div>
              </div>

              <div class="fs-sm-txt row pb-1">
                <div class="col-4 pr-0 font-weight-bold">
                  {{
                    'left-menu.tab-bar.sidemenus.imageInfo.tab.data.imageStatus'
                      | translate
                  }}:
                </div>
                <div class="col-8 pl-1">
                  <span
                    class="approvedBy"
                    [ngClass]="{
                      rejectedBy:
                        imgInfo.rejectedBy && imgInfo.rejectedBy.length > 0
                    }"
                  >
                    {{ imgInfo.status | assetsStatusPipe }}
                  </span>
                </div>
              </div>

              <div class="fs-sm-txt row pb-1">
                <div class="col-4 pr-0 font-weight-bold">
                  {{
                    'left-menu.tab-bar.sidemenus.imageInfo.tab.data.taggedWith'
                      | translate
                  }}:
                </div>
                <div class="col-8 pl-1">
                  {{ imgInfo.publishedTags | json }}
                  <div *ngFor="let publishedBy of imgInfo.publishedBy">
                    <span>{{ publishedBy.label }}</span>
                    <span class="fs-text-light-shade-pink-color" *ngIf="userAuthority === userRole.ROLE_ADMIN && publishedBy.isUserFinalizedAsNonTalent">
                      ❗({{ 'left-menu.tab-bar.sidemenus.imageInfo.imageStatus.Finalized' | translate }})
                    </span>
                  </div>
                </div>
              </div>

              <div class="fs-sm-txt row pb-1">
                <div class="col-4 pr-0 font-weight-bold">
                  {{
                    'left-menu.tab-bar.sidemenus.imageInfo.tab.data.approvedBy'
                      | translate
                  }}:
                </div>
                <div class="col-8 pl-1">
                  <div *ngFor="let approveBy of imgInfo.approvedBy">
                    <span class="align-items-left">
                      {{ approveBy.tagName }} &nbsp;</span
                    >
                    <span class="approvedBy"
                      >({{ approveBy.status | assetsStatusPipe }})
                    </span>
                  </div>
                </div>
              </div>

              <div class="fs-sm-txt row pb-1">
                <div class="col-4 pr-0 font-weight-bold">
                  {{
                    'left-menu.tab-bar.sidemenus.imageInfo.tab.data.rejectedBy'
                      | translate
                  }}:
                </div>
                <div class="col-8 pl-1">
                  <div *ngFor="let rejectedBy of imgInfo.rejectedBy">
                    <div class="align-items-left">
                      {{ rejectedBy.tagName }} &nbsp;
                    </div>
                    <span class="rejectedBy">
                      ({{ rejectedBy.status | assetsStatusPipe }})
                    </span>
                  </div>
                </div>
              </div>

              <div
                class="fs-sm-txt row pb-1"
                *ngIf="
                  selectedImages.length === 1 &&
                  selectedImagesObj.excludedFrom &&
                  selectedImagesObj.excludedFrom.length > 0
                "
              >
                <div class="col-4 pr-0 font-weight-bold">
                  {{
                    'left-menu.tab-bar.sidemenus.imageInfo.tab.data.excludedBy'
                      | translate
                  }}:
                </div>
                <div class="col-8 pl-1">
                  <div *ngFor="let excludedBy of imgInfo.excludedBy">
                    {{ excludedBy }}
                  </div>
                </div>
              </div>

              <div class="fs-sm-txt row pb-1">
                <div class="col-4 pr-0 font-weight-bold">
                  {{
                    'left-menu.tab-bar.sidemenus.imageInfo.tab.data.albums'
                      | translate
                  }}:
                </div>
                <div class="col-8 pl-1">
                  <div *ngFor="let customAlbum of imgInfo.customAlbums">
                    {{ customAlbum }}
                  </div>
                </div>
              </div>

              <div class="fs-sm-txt row pb-1">
                <div class="col-4 pr-0 font-weight-bold">
                  {{
                    'left-menu.tab-bar.sidemenus.imageInfo.tab.data.caption'
                      | translate
                  }}:
                </div>
                <div class="col-8 pl-1">
                  <span *ngIf="imgInfo.caption">{{ imgInfo.caption }}</span>
                </div>
              </div>

              <div class="fs-sm-txt row pb-1">
                <div class="col-4 pr-0 font-weight-bold">
                  {{
                    'left-menu.tab-bar.sidemenus.imageInfo.tab.data.photographer'
                      | translate
                  }}:
                </div>
                <div class="col-8 pl-1">{{ imgInfo.photographer }}</div>
              </div>

              <div class="fs-sm-txt row pb-1" *ngIf="imgInfo.excluded">
                <div class="col-4 pr-0 font-weight-bold">
                  {{
                    'left-menu.tab-bar.sidemenus.imageInfo.tab.data.excluded'
                      | translate
                  }}
                </div>
                <div class="col-8 pl-1"></div>
              </div>
            </div>
          </div>
          <div
            [class.d-none]="imageInfoTab !== 'notes' || !hideDataNoteListOption"
            [class.d-block]="imageInfoTab === 'notes' || hideDataNoteListOption"
          >
            <div
              class="fs-popup-img-container"
              *ngIf="selectedImages.length > 1 && !isTopMenuNoteOpen"
            >
              {{
                'left-menu.tab-bar.sidemenus.imageInfo.labels.multipleImageLabel'
                  | translate
              }}
            </div>

            <div
              class="fs-popup-img-container"
              *ngIf="selectedImages.length == 0"
            >
              {{
                'left-menu.tab-bar.sidemenus.imageInfo.labels.noImageLabel'
                  | translate
              }}
            </div>
            <div
              class="fs-popup-img-form-container note-msg-type"
              *ngIf="selectedImages.length == 1 || isTopMenuNoteOpen"
            >
              <form [formGroup]="noteForm" #formName="ngForm">
                <textarea
                  maxlength="500"
                  fsAutofocus
                  rows="2"
                  [preventSpecialChars]="['|']"
                  formControlName="imageNoteText"
                  [(ngModel)]="submitNoteData.imageNoteText"
                >
                </textarea>

                <div
                  class="nmt-btm w-100 d-flex flex-row align-content-center justify-content-between mt-2 mb-3"
                >
                  <div class="mf-sngle">
                    <mat-radio-group
                      *ngIf="projectRole.ROLE_TALENT !== projectAuthority"
                      formControlName="noteType"
                      class="d-flex flex-row"
                      [(ngModel)]="submitNoteData.noteType"
                    >
                      <mat-radio-button
                        class="mr-2 fs-radio-button"
                        *ngFor="let ntType of noteData.note; index as ind"
                        [value]="ntType.id"
                      >
                        <span class="fs-sm-txt cb-title">
                          {{
                            'left-menu.tab-bar.sidemenus.imageInfo.tab.note.radioBtn' +
                              '.' +
                              ntType.key | translate
                          }}
                        </span>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="m-0 right-save-btn d-flex justify-content-end">
                    <button
                      [disabled]="submitNoteData.imageNoteText == ''"
                      (click)="saveImageNote()"
                    >
                      {{
                        'left-menu.tab-bar.sidemenus.imageInfo.tab.note.saveNote'
                          | translate
                      }}
                    </button>
                  </div>
                </div>
              </form>

              <div
                class="messages-sec"
                *ngIf="selectedImages.length === 1 && !hideDataNoteListOption"
              >
                <div
                  class="single-message"
                  *ngFor="
                    let savedNote of selectedImagesObj.assetNoteDTOList;
                    let idx = index
                  "
                >
                  <div class="fs-msg-wrapper">
                    <span class="sm-msg">
                      {{ savedNote.value }}
                    </span>
                    <div class="sm-btm clearfix">
                      <div class="pull-left fs-tiny-txt">
                        {{ savedNote.name }}
                      </div>
                      <div class="pull-right fs-tiny-txt">
                        {{ savedNote.createdAt | date : 'yyyy-MM-dd' }} &#64;
                        {{ savedNote.createdAt | date : 'hh:mm:ss' }}
                        <i
                          class="fa fa-lg fa-trash"
                          *ngIf="savedNote.createdBy === currentUser.id"
                          (click)="onClickTrash(savedNote, idx)"
                        >
                        </i>
                        <i
                          class="fa fa-lg fa-eye-slash"
                          *ngIf="
                            savedNote.type == 1 || savedNote.type == 'Private'
                          "
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-scrollbar>
    </div>
  </div>
</div>
