import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[preventdrag]',
})
export class PreventDragDirective {
  @HostListener('dragstart', ['$event']) onDragStart(event: MouseEvent) {
    event.preventDefault();
  }
}
