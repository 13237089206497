<div
  class="ml-1 mr-1"
  *ngIf="imgData.assetType == assetType.IMAGE && !isTaggerRole"
>
  <div
    class="fs-cursor-pointer"
    title="{{ 'view-project.history' | translate }}"
    (click)="
      openImageHistory.emit({ imageData: imgData, historyReportType: 'admin' })
    "
  >
    <span class="fs-char-icon">
      <img src="/assets/icons/h.png" />
    </span>
  </div>
</div>
