import { Router } from '@angular/router';
import { LauncherService } from '../../../../launcher/launcher.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { SearchProjectModel } from '../interfaces/dashboard-search-project-rule.model';
import { DashboardRuleModel } from '../../../interfaces/dashboard-rule.model';
import { GetStaticInjector } from '../../../../../shared/injector/static-injector';

export class DashboardSearchDepartmentRule implements DashboardRuleModel {
  private router = GetStaticInjector().get(Router);
  private launcherService = GetStaticInjector().get(LauncherService);
  private viewProjectService = GetStaticInjector().get(ViewProjectService);

  constructor(
    private fromDepartment: boolean,
    private selectedProjectId: number,
    private selectedProjectData: SearchProjectModel,
  ) {}

  matches(): boolean {
    return !this.fromDepartment && this.selectedProjectData.role === 0;
  }

  apply(): void {
    this.launcherService.setSelectedProject(this.selectedProjectData.groupId);
    this.launcherService.setSelectedProjectName(
      this.selectedProjectData.groupId,
    );
    this.viewProjectService.setProjectRole(this.selectedProjectData.role);
    this.launcherService.setProjectRole('1');
    this.launcherService.setIsGallaryModeStatus(true);
    this.router.navigate(['/dashboard/department/' + this.selectedProjectId]);
  }
}
