export class Tagger {
  status: boolean;
  message: string;
  t: TaggingInfo;
}

class TaggingInfo {
  tags: Tags[];
  counts: Counts[];
}

class Tags {
  id: number;
  value: string[];
}

class Counts {
  id: number;
  value: number;
}
