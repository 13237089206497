import { IHandler } from '../../../interfaces/dashboard-handler.model';
import { IDashboardEvent } from '../../../interfaces/dashboard-event.model';

export class DashboardLauncherEvent<T extends IHandler>
  implements IDashboardEvent
{
  constructor(private handler: T) {}

  getData(): T {
    return this.handler;
  }
}
