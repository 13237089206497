import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { UserService } from '../../../../../../../shared/services/user.service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { ExportType } from '../../../../../../../shared/models/enum/ExportType';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { ActorIdHistoryReportComponent } from '../actoreapproval-report/actor-id-history-report/actor-id-history-report.component';

@Component({
  selector: 'fs-actoreid-report',
  templateUrl: './actoreid-report.component.html',
  styleUrls: ['./actoreid-report.component.scss'],
})
export class ActoreidReportComponent implements OnInit {
  originalData: any = [];
  sortF: any;
  objDate = Date.now();
  getDataFromService: any;
  today: any;
  constantsGlobalData: any;
  isHistoryShown = false;
  projectName = '';
  historyColumnsList: any = [];
  printHistoryData: any = [];
  sum: any = [0];
  exportType = ExportType;
  // array of all items to be paged
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['actorName', 'count'];
  dataSource = new MatTableDataSource([]);

  constructor(
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private userService: UserService,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private dialogRef: MatDialogRef<ActoreidReportComponent>,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: { reportData: any },
  ) {}

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.projectName =
      this.viewProjectService.getProjectDetailPermissionData().projectGroupName;
    this.getActorIdReportDetails();
    this.today = Date.now();
  }

  getActorIdReportDetails() {
    this.originalData = this.data.reportData;
    this.formatForIDReport(this.data.reportData[0]);
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  formatForIDReport(data) {
    this.historyColumnsList = [];
    const tempArray = [];
    let i = 0;
    for (const record of Object.keys(data.actorRecords)) {
      this.historyColumnsList.push(record);
      tempArray.push({
        actorName: record,
        count: data.actorRecords[record],
      });
      this.sum[i] = 0;
      i++;
    }
    this.dataSource.data = tempArray;
    this.dataSource.sort = this.sort;
  }

  closeWindow() {
    this.dialogRef.close();
  }

  actorIdHistory() {
    this.formatDataForHistory();
    this.dialogService.open(ActorIdHistoryReportComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-width-900-panel',
        'fs-dialog-link-panel',
      ],
      data: {
        projectName: this.projectName,
        today: this.today,
        printHistoryData: this.printHistoryData,
        historyColumnsList: this.historyColumnsList,
        sum: this.sum,
        exportReportHistory: () => this.exportReportHistory,
      },
    });
    this.isHistoryShown = true;
  }

  formatDataForHistory() {
    const sumArraySize = this.sum.length;
    this.sum = [];
    for (let i = 0; i < sumArraySize; i++) {
      this.sum[i] = 0;
    }

    const tempOuterArray = [];
    this.originalData.forEach((objIDReport) => {
      const tempInnerArray = [];
      tempInnerArray.push(objIDReport.createdDate);
      let i = 0;
      for (const record of Object.keys(objIDReport.actorRecords)) {
        tempInnerArray.push(objIDReport.actorRecords[record]);
        this.sum[i] = this.sum[i] + Number(objIDReport.actorRecords[record]);
        i++;
      }
      tempOuterArray.push(tempInnerArray);
    });
    this.printHistoryData = tempOuterArray;
  }

  exportReport(reportType) {
    if (reportType === ExportType.Excel) {
      this.userService.exportReport(
        'Actor ID Report',
        this.dataSource.data,
        'ActorIDReport.xlsx',
      );
    } else {
      const colNames = this.userService.getColumnsName(this.dataSource.data[0]);
      this.userService.exportPDFData(this.dataSource.data, colNames);
    }
  }

  exportReportHistory = (reportType) => {
    if (reportType === ExportType.Excel) {
      this.userService.exportReport(
        'Actor ID History Report',
        this.printHistoryData,
        'ActorIDHistoryReport.xlsx',
      );
    } else {
      const colNames = this.userService.getColumnsName(
        this.printHistoryData[0],
      );
      this.userService.exportPDFData(this.printHistoryData, colNames);
    }
  };
}
