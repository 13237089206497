import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

import { Pipe, PipeTransform } from '@angular/core';

const maxAge = 60000;

@Pipe({ name: 'fssecureimage' })
export class SecureImagePipe implements PipeTransform {
  cache = new Map();
  constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {}

  transform(url: string) {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((response) => {
        return this.domSanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(response),
        );
      }),
    );
  }

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);

    if (!cached) {
      return undefined;
    }
    return cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.url;
    const entry = { url, response, lastRead: Date.now() };
    this.cache.set(url, entry);

    const expired = Date.now() - maxAge;
    this.cache.forEach((expiredEntry) => {
      if (expiredEntry.lastRead < expired) {
        this.cache.delete(expiredEntry.url);
      }
    });
  }
}
