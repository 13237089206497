import { Component, HostListener, OnInit } from '@angular/core';
import { TalentManagementService } from './talent-management.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

enum UserTalentData {
  groupKillPct = 'groupKillPct',
  soloKillPct = 'soloKillPct',
}

@Component({
  selector: 'fs-talent-management',
  templateUrl: './talent-management.component.html',
  styleUrls: ['./talent-management.component.scss'],
})
export class TalentManagementComponent
  extends UnsubscriberComponent
  implements OnInit
{
  expireAt;
  isChecked;
  activeUserId;
  numberOfCharLeft;
  todaysDate;
  arrListTalentedExistingData: any = [];
  dateTime: Date;
  min: Date;
  constantsGlobalData: any;

  constructor(
    private modalService: Modalstatusservice,
    private _talentManagementService: TalentManagementService,
    private viewProjectService: ViewProjectService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private dialogRef: MatDialogRef<TalentManagementComponent>,
  ) {
    super();
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const date = new Date().getDate();
    this.min = new Date(year, month, date);
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.todaysDate = new Date().toISOString().split('T')[0];
    this.getTalentManagementData(this.viewProjectService.getProjectId());
  }

  onclickSave() {
    if (this.arrListTalentedExistingData) {
      this.loaderService.displayLoader(true);

      const copyOfJsonData = JSON.parse(
        JSON.stringify(this.arrListTalentedExistingData),
      );
      for (let i = 0; i < copyOfJsonData.length; i++) {
        if (copyOfJsonData[i]['expireAt']) {
          copyOfJsonData[i]['expireAt'] = new Date(
            copyOfJsonData[i]['expireAt'],
          );
        }
      }
      this._talentManagementService
        .getTalentManagementData(
          copyOfJsonData,
          this.viewProjectService.getProjectId(),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            if (res.status) {
              this.loaderService.displayLoader(false);
              this.closeWindow();
            }
          },(error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    }
  }

  closeWindow() {
    this.dialogRef.close();
  }

  getTalentManagementData(projectId) {
    this.loaderService.displayLoader(true);
    this._talentManagementService
      .submitTalentManagementData(projectId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.loaderService.displayLoader(false);
          this.arrListTalentedExistingData = res;
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  isValidUserTalentData(key: string, value: number): boolean {
    return UserTalentData[key] && !value;
  }

  setSoloGroupDefaultValue(key) {
    return UserTalentData.groupKillPct === key ? 0 : 1;
  }

  onTalentDataChange(key, value, telentData) {
    for (let i = 0; i < this.arrListTalentedExistingData.length; i++) {
      if (this.arrListTalentedExistingData[i]['id'] === telentData['id']) {
        this.arrListTalentedExistingData[i][key] = this.isValidUserTalentData(
          key,
          value,
        )
          ? this.setSoloGroupDefaultValue(key)
          : value;
        break;
      }
    }
  }

  onClickEyeIcon(index) {
    this.arrListTalentedExistingData[index]['isPasswordShown'] =
      !this.arrListTalentedExistingData[index]['isPasswordShown'];
  }

  checkRemainingCharacters(userId, value) {
    this.activeUserId = userId;
    this.numberOfCharLeft = 100 - value.length;
  }
}
