<div class="fs-popup">
  <div class="fs-popup-title fs-cursor-move d-flex justify-content-between">
    <div class="modal-title text-uppercase">
      {{ 'addalbum.title' | translate }}
    </div>

    <button
      type="button"
      class="fs-close-btn w-auto"
      aria-hidden="true"
      (click)="closeAddAlbumPopup()"
    >
      <i class="fa fa-times-circle"></i>
    </button>
  </div>

  <div class="fs-popup-content">
    <div [formGroup]="albumForm">
      <fieldset class="form-group">
        <input
          fsAutofocus
          type="text"
          class="form-control"
          formControlName="albumName"
          (keypress)="onKeypressHandler($event)"
          name="albumName"
          [placeholder]="'addalbum.placeholder' | translate"
        />

        <span
          class="d-flex align-items-center form-control pr-0 mt-3"
          *ngIf="albumType === 'wideEdit'"
        >
          <input
            matInput
            inputRestriction="onlyInteger"
            class="fs-background-color-white-gray"
            [placeholder]="'left-menu.tab-bar.sidemenus.administration.talent-management.placeholder.selectdate' | translate"
            formControlName="dateTime"
            [matDatepicker]="picker"
            [min]="min"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker [startAt]="min"></mat-datepicker>
        </span>
      </fieldset>
    </div>
  </div>

  <div class="fs-popup-actions d-flex justify-content-center">
    <button class="fs-dialog-btn w-auto mr-3" (click)="closeAddAlbumPopup()">
      {{ 'addalbum.buttons.cancel' | translate }}
    </button>

    <button
      class="fs-dialog-btn w-auto"
      [disabled]="!albumForm.valid"
      (click)="submitForm()"
    >
      {{ 'addalbum.buttons.save' | translate }}
    </button>
  </div>
</div>
