import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettings } from '../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../shared/services/api.service';

@Injectable()
export class ContributorsService {
  private userListDataSubject = new BehaviorSubject<any>([]);
  userListDataGlobal = this.userListDataSubject.asObservable();

  constructor(private apiService: ApiService) {}

  getUserData(projectId: number, userIds: number[]): Observable<any> {
    const path = AppSettings.USER_DETAILS(projectId) + userIds;
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  setUserListData(len) {
    this.userListDataSubject.next(len);
  }

  getUserListData() {
    return this.userListDataSubject.value;
  }
}
