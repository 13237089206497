<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">{{ 'top-menu.goto.title' | translate }}</div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="w-100">
    This will send notification to the owner of this wide edit Album
  </div>

  <div class="w-100">
    <span> Please type "DONE" to initiate this process:&nbsp;</span>
    <span>
      <input
        type="text"
        class="form-control done_input"
        [(ngModel)]="doneText"
        name="doneText"
        (keyup)="onChangeDoneText()"
      />
    </span>
  </div>
</div>

<div mat-dialog-actions class="mf-sngle" align="end">
  <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
    {{ 'top-menu.sendMail.cancel' | translate }}
  </button>

  <button
    *ngIf="isConfirmShown"
    class="btn btn-default w-auto ml-3"
    (click)="onClickDone()"
  >
    {{ 'top-menu.deleteAssets.buttons.confirm' | translate }}
  </button>
</div>
