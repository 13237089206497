import { DownloadManagerService } from './../shared/layout/download-manager/download-manager.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { interval, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectItem } from '../shared/models/select-item';
import { environment } from '../../environments/environment';
import { NGXLogger } from 'ngx-logger';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { SnackBarService } from '../shared/services/snack-bar.service';
import { DomainConfig } from '../shared/models/domainConfig.model';
import { UserService } from '../shared/services/user.service';
import { LoaderService } from '../shared/services/loader.service';
import { DomainConfigService } from '../shared/services/domain-config.service';
import { PreviousRouteService } from '../shared/services/previous-route.service';
import { AuthServerProvider } from '../shared/services/auth.jwt.service';
import { DialogService } from '../shared/services/dialog/dialog.service';
import { Utils } from '../shared/utils/utils';
import { FsCookieService } from '../shared/services/fs-cookie.service';
import {
  LanguageConfig,
  LanguageService,
} from '../shared/services/language.service';
import { UnsubscriberComponent } from '../shared/components/unsubscriber/unsubscriber.component';
import { IpService } from '../shared/services/ip-service.service';
import { ViewProjectService } from '../shared/services/view-project.service';
import { CookieBannerService } from '../shared/components/cookie-banner/cookie-banner.service';
import { ExpressDownloadStoreService } from '../shared/services/express-download-store.service';
import { AppConfig } from '../app.configuration';
import {ApiError, ApiErrorHandlerService} from '../shared/services/api-error-handler.service';
import {ErrorCodeConfig} from '../appSettings/error-codes-config';
import { LanguageCode } from '../shared/models/langualge.model';
import { LogoutConstants } from './auth.enum';

@Component({
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent extends UnsubscriberComponent implements OnInit {
  domainConfigData = new DomainConfig();
  isDomainSuccess = false;
  timer: any;
  authType = '';
  uuid = '';
  title = '';
  isSubmitting = false;
  isSubmittingValidate = false;
  authForm: UntypedFormGroup;
  sub: any;
  availableLanguages: SelectItem[] = LanguageConfig;
  selectedLanguage: any;
  private ngUnSubscribe: Subject<void> = new Subject<void>(); // TODO probably remove this?
  languageCode: LanguageCode;
  version = '';
  environmentName = '';
  isAuthenticated = false;
  refresh_timer_interval = interval(AppConfig.TIME_OUT.REFRESH_TOKEN);
  authResponse: any;
  languageMenuOpened = false;
  USER_IS_NOT_ACTIVE = 'UserIsNotActive';
  HTTP_STATUS_FORBIDDEN = 403;

  constructor(
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private loaderService: LoaderService,
    private ipService: IpService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private cookieService: FsCookieService,
    private cookieBannerService: CookieBannerService,
    private languageService: LanguageService,
    private domainConfigService: DomainConfigService,
    private previousRouteService: PreviousRouteService,
    private authServerProvider: AuthServerProvider,
    private logger: NGXLogger,
    private sessionStorage: SessionStorageService,
    private expressDownloadStoreService: ExpressDownloadStoreService,
    private localStorage: LocalStorageService,
    private viewProjectService: ViewProjectService,
    private downloadManagerService: DownloadManagerService,
    private snackBarService: SnackBarService,
    private dialogService: DialogService,
  ) {
    super();
    const authData = JSON.parse(this.localStorage.retrieve('authData'));
    if (authData && Object.keys(authData).length !== 0) {
      this.loaderService.displayLoader(true);
      this.prapareTocallUserDetail(true);
    }

    if (!this.cookieService.isCookieAllowed()) {
      this.clearPasswordSettings();
    }

    this.router.navigate(['/'])

    //TODO: Try to store password in local storage in more secure way later
    this.authForm = this.fb.group({
      username: [
        this.cookieService.isCookieAllowed() &&
        this.localStorage.retrieve('rememberMe')
          ? atob(this.localStorage.retrieve('username'))
          : '',
        Validators.required,
      ],
      password: ['', Validators.required],
      rememberMe: [
        this.cookieService.isCookieAllowed() &&
          this.localStorage.retrieve('rememberMe'),
      ],
    });

    this.languageCode = this.localStorage.retrieve('selectedLanguage')
      ? this.localStorage.retrieve('selectedLanguage')
      : 'en-us';

    this.cookieService.acceptCookieStatusGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status: boolean) => {
        if (status) {
          this.localStorage.store(
            'rememberMe',
            this.authForm.get('rememberMe').value,
          );
        } else {
          this.authForm.get('rememberMe').setValue(status);
        }
      });

    if (this.languageCode) {
      this.translate.use(this.languageCode);
    }

    const preURL = this.previousRouteService.getPreviousUrl();
    if (preURL) {
      this.loaderService.displayLoader(true);
      const data = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        refresh_token: this.userService.getRefreshTokenErrorStatus() === this.HTTP_STATUS_FORBIDDEN
          ? null
          : `${this.authServerProvider.getRefreshToken()}`,
        inactivityLogout:
          this.userService.getInactivityLogout()
          || Boolean(this.activatedRoute.snapshot.queryParamMap.get('logout'))
      };
      if (data.refresh_token || this.userService.getRefreshTokenErrorStatus()) {
        this.userService
          .signOut(data)
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            this.userService.setRefreshTokenErrorStatus(null);
            this.userService.setInactivityLogout(false);
            this.loaderService.displayLoader(false);
            if (res.status) {
              this.dialogService.closeAll();
              this.router.navigateByUrl('/').then(() => {
                const messages = {
                  [LogoutConstants.LOGOUT_BY_INACTIVITY]: 'login.inactivityLoggOut',
                  [LogoutConstants.LOGOUT_BY_REPETITIVE_LOGIN]: 'login.repetitiveLoggOut',
                };

                const messageKey = messages[res.message] || 'login.loggOutConfirmation';
                this.alertCustom(this.translate.instant(messageKey));

              });
              this.userService.purgeAuth();
              this.domainConfigService.setIsDomainSuccess(true);
              this.downloadManagerService.setIsDownloadEnabled(false);
            }
          });
      } else {
        this.loaderService.displayLoader(false);
      }
    }
  }

  ngOnInit(): void {
    this.expressDownloadStoreService.resetExpressDownloadResultFromPool();
    this.activatedRoute.url.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.authType = 'login';
      this.title = 'Sign in';
    });

    this.domainConfigService.domainConfigDataGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((domainData) => {
        if (!Utils.isObjectEmpty(domainData)) {
          this.domainConfigData = domainData;
          this.handleOutageMessage();
        }
      });

    this.domainConfigService.isDomainSuccess
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isDomainSuccess = status;
        if (this.isDomainSuccess === false) {
          this.authForm.controls['username'].disable();
          this.authForm.controls['password'].disable();
        }
      });

    this.userService.isAuthenticated
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isAuthenticated = status;
        if (!status) {
          this.complatedResetToken();
        }
      });

    this.languageService.enableSelectLanguage$.subscribe(() => {
      this.languageCode = this.localStorage.retrieve('selectedLanguage')
        ? this.localStorage.retrieve('selectedLanguage')
        : 'en-us';
      this.updateSelectedLanguage();
    });

    this.version = environment.version;
    this.environmentName = environment.environmentName;
    this.updateSelectedLanguage();
  }

  onRememberChange() {
    if (this.cookieService.isCookieAllowed()) {
      this.localStorage.store('rememberMe', this.authForm.value.rememberMe);
      //TODO: Try to store password in local storage in more secure way later
      if (!this.localStorage.retrieve('rememberMe')) {
        this.localStorage.store('username', '');
        this.authForm.controls['username'].enable();
      }
    } else {
      this.cookieBannerService.open();
    }
  }

  clearPasswordSettings() {
    this.localStorage.clear('username');
    //TODO: Try to store password in local storage in more secure way later
    this.localStorage.clear('rememberMe');
  }

  submitForm() {
    if (this.isDomainSuccess) {
      this.userService.resetData();
      this.userService.resetUserAuthority();

      this.isSubmitting = true;
      const obj = {
        device: {
          ipAddress: '',
          source: 'web',
          version: environment.version,
        },
        username: this.authForm.getRawValue().username.trim(),
        password: this.authForm.value.password.trim(),
        sendingUrl: window.location.href,
        rememberMe: this.authForm.value.rememberMe,
      };
      this.loaderService.displayLoader(true);
      this.authenticate(obj);
    } else {
      this.alertCustom(this.translate.instant('error.networkError'));
    }
  }

  callUserIpAddress(): void {
    this.ipService
      .receiveUserIPAddress()
      .then((resp) => resp.json())
      .then((res) => {
        this.ipService.setUserIPAddress(res.ip);
      });
  }

  navigateToAppStore(url) {
    window.location.href = url;
  }

  authenticate(credentials) {
    this.userService
      .attemptAuth('api/authenticate', credentials)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          if (data.id_token) {
            if (!this.reloadRequire(data)) {
              this.prapareTocallUserDetail();
            }
          } else {
            this.snackBarService.showSnackBar(data.message);
            this.isSubmitting = false;
            this.uuid = data.uuid;
            this.loaderService.displayLoader(false);
          }
        },
        (error) => {
          this.isSubmitting = false;
          if (error.error.detail === this.USER_IS_NOT_ACTIVE) {
            this.alertCustom(this.translate.instant('login.userIsNotActive'))
          } else if (error.status === ErrorCodeConfig.UNAUTHORIZED) {
            this.alertCustom(this.translate.instant('login.invalidCredentials'));
          } else {
            this.apiErrorHandlerService.getHandler().handle(error);
          }
        },
      );
  }

  prapareTocallUserDetail(isAuthData?) {
    this.snackBarService.showSnackBar(
      this.translate.instant('login.successMsg'),
    );
    this.resetIntervalRefreshToken();

    this.getUserBrowserInfo();
    this.getUserDetails(isAuthData);
  }

  getUserBrowserInfo() {
    this.ipService.getBrowserInfo();
    this.callUserIpAddress();
  }

  reloadRequire(res?) {
    const version = this.localStorage.retrieve('version');
    if (version === '') {
      this.localStorage.store('version', res.version);
      return false;
    } else if (version !== res.version) {
      this.authResponse = res;
      this.loaderService.displayLoader(false);

      this.localStorage.store(
        'authData',
        res ? JSON.stringify(res) : JSON.stringify({}),
      );
      this.reload();

      return true;
    } else {
      return false;
    }
  }

  reload() {
    this.localStorage.store('version', this.authResponse.version);
    location.reload();
  }

  refreshTokenStart() {
    this.ngUnSubscribe = new Subject<void>();
    this.sub = this.refresh_timer_interval
      .pipe(takeUntil(this.ngUnSubscribe))
      .subscribe(() => {
        this.refreshToken();
      });
  }

  resetIntervalRefreshToken() {
    this.ngUnSubscribe.next();
    this.refreshTokenStart();
  }

  complatedResetToken() {
    this.ngUnSubscribe.next();
    this.ngUnSubscribe.complete();
  }

  refreshToken() {
    const data = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      refresh_token: `${this.authServerProvider.getRefreshToken()}`,
    };
    if (data.refresh_token !== 'null') {
      this.userService
        .refreshAuth(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            // this.logger.log(res);
          },
          (err) => {
            this.logger.log(err);
            if (err.status === 403) {
              this.userService.setRefreshTokenErrorStatus(err.status);
              this.sub.unsubscribe();
              this.router.navigateByUrl('/');
            } else {
              this.apiErrorHandlerService.getHandler().handle(err);
            }
          },
        );
    } else {
      this.sub.unsubscribe();
    }
  }

  getUserDetails(isAuthData?) {
    this.userService
      .userDetail('api/users/user')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          if (data.activated) {
            this.viewProjectService.setUserSecureAccess(
              data.userProfileDTO.urlSecureAccess,
            );
            if (isAuthData) {
              this.userService.setAuth(
                JSON.parse(this.localStorage.retrieve('authData')),
              );
              this.localStorage.store('authData', JSON.stringify({}));
            }
            if (this.canSaveAuthData()) {
              this.localStorage.store(
                'username',
                btoa(this.authForm.getRawValue().username),
              );
              //TODO: Try to store password in local storage in more secure way later
            }
            this.router.navigateByUrl('/dashboard');
            this.loaderService.displayLoader(false);
          }
        },
        (error) => {
          this.isSubmitting = false;
          this.apiErrorHandlerService.getHandler().handle(error);
        },
      );
  }

  canSaveAuthData() {
    return (
      this.cookieService.isCookieAllowed() &&
      this.localStorage.retrieve('rememberMe')
    );
  }

  changeLanguage(language: {code: LanguageCode}) {
    this.languageCode = language.code;
    this.languageService.setSelectedLanguage(this.languageCode);

    if (this.cookieService.isCookieAllowed()) {
      this.localStorage.store('selectedLanguage', language.code);
    }

    this.translate.use(language.code);
    this.translate.setDefaultLang(language.code);
    this.updateSelectedLanguage();
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  handleOutageMessage() {
    // display outageMessage for 5 mins if received from BE
    if (this.domainConfigData.outageMessage) {
      this.snackBarService.showSnackBarWarning(
        this.domainConfigData.outageMessage,
        '',
        5 * 60 * 1000,
      );
    }
  }

  private updateSelectedLanguage() {
    this.selectedLanguage = this.availableLanguages.find(
      (element) => element.value.code === this.languageCode,
    );
  }
}
