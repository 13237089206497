import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ImageInfoService } from './imageinfo.service';
import { LauncherService } from './../../../../../launcher/launcher.service';
import { SidebarService } from '../../../../sidebar-service/sidebar-service';
import { CompareService } from '../../../../project-detail/compare/compare.service';
import { TranslateService } from '@ngx-translate/core';
import { UserRole } from './../../../../../../shared/enum/user-role.enum';
import { PaginationService } from '../../../pagination/pagination.service';
import { SnackBarService } from '../../../../../../shared/services/snack-bar.service';
import { DialogService } from '../../../../../../shared/services/dialog/dialog.service';
import { SelectedImages } from '../../../../../../shared/models/selectedImages.model';
import { ProjectDetailPermission } from '../../../../../../shared/models/projectpermission.model';
import { Activemodal } from '../../../../../../shared/models/activemodal.model';
import { AssetInfo } from '../../../../../../shared/models/assetInfo.model';
import { UserDetails } from '../../../../../../shared/models/userdetails.model';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../shared/services/loader.service';
import { Modalstatusservice } from '../../../../../../shared/services/modalstatus.service';
import { UserDataService } from '../../../../../../shared/services/userdata.service';
import { UserService } from '../../../../../../shared/services/user.service';
import { Utils } from '../../../../../../shared/utils/utils';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectedImagesStore } from '../../../../../../shared/store/selected-images-store';
import { LastSelectedImageStore } from '../../../../../../shared/store/last-selected-image-store';
import { ArrayUtils } from '../../../../../../shared/utils/array.utils';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { ProjectRole } from '../../../../../../shared/enum/project-role.enum';
import {ApiErrorHandlerService} from '../../../../../../shared/services/api-error-handler.service';
import {AssetErrorKeys} from '../../../../../../shared/enum/asset-error-keys.enum';

export enum ImageInfoTabEnum {
  Data = 'data',
  Notes = 'notes',
}

@Component({
  selector: 'fs-imageinfo',
  templateUrl: './imageinfo.component.html',
  styleUrls: ['./imageinfo.component.scss'],
})
export class ImageInfoComponent
  extends UnsubscriberComponent
  implements OnInit
{
  @Input() isDialogOpened: boolean;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @Output() closeNotePopup = new EventEmitter();
  selectedProjectValue: any;
  albumList: any;
  response: any;
  imageData: object;
  imgInfo: any;
  userRole = UserRole;
  projectRole = ProjectRole;
  selectedImages: SelectedImages[] = [];
  selectedImagesObj: any = {};
  projectDetail: ProjectDetailPermission = new ProjectDetailPermission();
  noteType: any;
  activemodal: Activemodal = new Activemodal();
  noteData: any;
  submitNoteData: any;
  isImageInfoActive = false;
  imageInfoTab: any = ImageInfoTabEnum.Data;
  distinctUserIds: Array<any> = [];
  distinctUserData;
  isEnlargeView = false;
  projectImageData: AssetInfo = new AssetInfo();
  albumId = 0;
  isExecutiveAlbumView = false;
  hideDataNoteListOption = false;
  isTopMenuNoteOpen = false;
  isAddNotePopupOpen = false;
  closeNotePopupObj: any = {};
  currentUser: UserDetails = new UserDetails();
  projectAuthority: string;
  userAuthority: string;
  isForCompareView = false;
  projectImageIds: any = [];
  noOfImages = 0;
  currentPageNumber = 1;

  noteForm = new UntypedFormGroup({
    imageNoteText: new UntypedFormControl(''),
    noteType: new UntypedFormControl(''),
  });

  FINALIZED_BY = [
    { tagName: 'Crew' },
    { tagName: 'Sensitive' },
    { tagName: 'Misc_Unknown' },
    { tagName: 'Background' },
    { tagName: 'Ensemble' },
    { tagName: 'Equipment' },
    { tagName: 'Film Frames' },
    { tagName: 'Weapons' },
    { tagName: 'Vehicles' }
    ]

  constructor(
    private router: Router,
    private imageInfoService: ImageInfoService,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private modalStatusService: Modalstatusservice,
    private userDataService: UserDataService,
    private sidebarService: SidebarService,
    private compareService: CompareService,
    private userService: UserService,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
    private selectedImageStore: SelectedImagesStore,
    private paginationService: PaginationService,
    private launcherService: LauncherService,
    private selectedImagesStore: SelectedImagesStore,
    private dialogService: DialogService,
    private lastSelectedImageStore: LastSelectedImageStore,
  ) {
    super();
  }

  noteDataTab(whichImageTab: string) {
    this.imageInfoTab = whichImageTab;
  }

  ngOnInit() {
    this.currentUser = this.userService.mergeWithCurrentUser(this.currentUser);

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority) => {
        this.userAuthority = userAuthority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        if (projectAuthority) {
          this.projectAuthority = projectAuthority;
          setTimeout(() => {
            if (this.projectAuthority === ProjectRole.ROLE_TALENT) {
              this.imageInfoTab = ImageInfoTabEnum.Notes;
            } else {
              this.imageInfoTab = ImageInfoTabEnum.Data;
            }
          }, 1000);
        }
      });

    this.launcherService.selectedProjectGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectValue) => {
        this.selectedProjectValue = Number(projectValue);
      });

    this.viewProjectService.projectImageIdsGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageIds) => {
        this.projectImageIds = imageIds;
      });

    this.paginationService.paginationIndexGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((index) => {
        if (index > 0) {
          this.currentPageNumber = index;
        } else {
          this.currentPageNumber = 1;
        }
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectDetailPermission) => {
        if (!Utils.isObjectEmpty(projectDetailPermission)) {
          this.projectDetail = projectDetailPermission;
          this.noOfImages = projectDetailPermission.viewSettingDTO.imagePerPage;
        }
      });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.search('enlarge') !== -1 ||
          event.url.search('compare') !== -1
        ) {
          this.isEnlargeView = true;
        } else {
          this.isEnlargeView = false;
        }
        if (event.url.search('compare') !== -1) {
          this.isForCompareView = true;
        } else {
          this.isForCompareView = false;
        }
      }
    });

    this.noteData = {
      imageNoteText: 'Enter Note',
      note: [
        {
          id: 2,
          lableName: 'Public',
          isSelect: false,
          key: 'publicNote',
        },
        {
          id: 1,
          lableName: 'Private',
          isSelect: false,
          key: 'privateNote',
        },
      ],
    };

    this.submitNoteData = {
      imageNoteText: '',
      noteType: 1,
    };

    this.imgInfo = {
      releasedDate: '',
      batch: '',
      name: '',
      status: '',
      imageStatus: '',
      taggedwith: [],
      approvedBy: [],
      publishedBy: [],
      rejectedBy: [],
      albums: [],
      generalAlbums: [],
      customAlbums: [],
      caption: '',
      photographer: '',
      excludedBy: [],
      excluded: false,
      metaKeywords: [],
      metaDescription: '',
      metaSource: ''
    };

    this.viewProjectService.isAddNoteFromAddNoteGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.isAddNotePopupOpen = response;
        this.showHideDataNoteListOption();
      });

    this.viewProjectService.isAddNoteFromTopMenuGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.isTopMenuNoteOpen = response;
        this.showHideDataNoteListOption();
      });

    this.sidebarService.albumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.albumList = Utils.getValuesByKey(albumList.albums, 'id');
        } else {
          this.albumList = [];
        }
      });

    this.sidebarService.selectedAlbumName
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumName) => {
        if (!Utils.isObjectEmpty(albumName)) {
          this.albumId = albumName.id;
        }
      });

    this.viewProjectService.isExecutiveAlbumGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isExecutiveAlbumView = status;
      });

    this.sidebarService.updatedAlbumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.albumList = Utils.getValuesByKey(albumList, 'id');
        } else {
          this.albumList = [];
        }
      });

    this.imageInfoService.whichImageTabGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.projectAuthority === ProjectRole.ROLE_TALENT
          ? (this.imageInfoTab = ImageInfoTabEnum.Notes)
          : (this.imageInfoTab = res);
      });

    // OLD
    this.compareService.compareSelectedImagesGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((images) => {
        this.selectedImages = Array.from(images.values());
        if (images.size === 1 && Array.from(images)[0][1].id) {
          this.selectedImages = Array.from(images.values());
          this.selectedImagesObj = Array.from(images)[0][1];
          this.bindData(images);
        }
      });

    this.selectedImagesStore
      .getCollection()
      .pipe(takeUntil(this.destroy$))
      .subscribe((images) => {
        this.selectedImages = images;
        if (this.selectedImages.length === 1 && this.selectedImages[0].id) {
          this.selectedImagesObj = this.selectedImages[0];
          if (this.projectAuthority !== ProjectRole.ROLE_TALENT) {
            this.bindData(images);
          }
        } else {
          this.selectedImagesObj = {};
        }
      });

    // OLD

    this.lastSelectedImageStore
      .getCollection()
      .pipe(takeUntil(this.destroy$))
      .subscribe((images) => {
        if (images.length === 1 && this.isEnlargeView && images[0].id) {
          this.selectedImagesObj = images[0];
          this.selectedImages = images;
          if (this.projectAuthority !== ProjectRole.ROLE_TALENT) {
            this.bindData(images);
          }
        }
      });
  }

  showHideDataNoteListOption() {
    if (this.isAddNotePopupOpen || this.isTopMenuNoteOpen) {
      this.hideDataNoteListOption = true;
    } else {
      this.hideDataNoteListOption = false;
    }
  }

  getSelectedImageIDsForCompare() {
    return this.selectedImages.map((selectedImage) => selectedImage.id);
  }

  onClickTrash(data, deletedNoteIndex) {
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    this.dialogService
      .openConfirmationDialog({
        message: this.translate.instant('confirmDialog.deleteNote.message'),
        title: this.translate.instant('confirmDialog.deleteNote.title'),
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: boolean) => {
        if (state) {
          this.loaderService.displayLoader(true);
          this.imageInfoService
            .callDeleteNote(this.viewProjectService.getProjectId(), data)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              () => {
                const imageIndex = this.projectImageData.assetInfoDTOList
                  .map((e) => e.id)
                  .indexOf(this.selectedImages[0].id);

                if (imageIndex !== -1) {
                  const index = this.projectImageData.assetInfoDTOList[
                    imageIndex
                  ].assetNoteDTOList
                    .map((e) => e.id)
                    .indexOf(data.id);
                  if (index > -1) {
                    this.projectImageData.assetInfoDTOList[
                      imageIndex
                    ].assetNoteDTOList.splice(index, 1);
                  }
                }
                if (this.isForCompareView) {
                  this.selectedImagesObj.assetNoteDTOList.splice(
                    deletedNoteIndex,
                    1,
                  );
                } else {
                  this.selectedImagesStore.set(
                    this.projectImageData.assetInfoDTOList[imageIndex],
                  );
                }

                this.snackBarService.showSnackBar(
                  this.translate.instant('confirmDialog.deleteNote.successMsg'),
                );
                this.loaderService.displayLoader(false);
              },
              (error) => {
                this.snackBarService.showSnackBarError(
                  this.translate.instant('confirmDialog.deleteNote.successMsg'),
                );
              },
            );
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
      });
  }

  bindData(selectedImages) {
    this.resetValue();
    this.setValues();
    this.imgInfo.excluded = this.selectedImages[0].excluded;
    this.imgInfo.name = this.selectedImages[0].name;
    this.imgInfo.status = this.selectedImages[0].status;
    this.imgInfo.releasedDate = this.selectedImages[0].releasedDate;
    this.imgInfo.photographer = this.selectedImages[0].photographer;
    this.imgInfo.metaSource = this.selectedImages[0].source;
    this.imgInfo.metaDescription = this.selectedImages[0].description;
    this.imgInfo.metaKeywords = this.selectedImages[0].keywords;
    this.imgInfo.caption = this.selectedImages[0].caption
      ? this.selectedImages[0].caption.value
      : '';
    this.distinctUserIds = [
      ...new Set(
        this.selectedImages[0].assetNoteDTOList.map((item) => item.createdBy),
      ),
    ];
    if (
      this.selectedImages[0].customAlbums &&
      this.selectedImages[0].customAlbums.length > 0
    ) {
      this.imgInfo.customAlbums = [];
      this.selectedImages[0].customAlbums.forEach((cAlbumElement) => {
        if (this.albumList.length > 0) {
          this.albumList.forEach((albumElement) => {
            if (albumElement.id === cAlbumElement) {
              this.imgInfo.customAlbums.push(albumElement.value);
            }
          });
        }
      });
    }
    if (this.distinctUserIds.length > 0) {
      this.userDataService
        .getUserData(
          this.viewProjectService.getProjectId(),
          this.distinctUserIds,
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe((value) => {
          this.distinctUserData = value;
          selectedImages[0].assetNoteDTOList.forEach((item, index) => {
            this.distinctUserData.forEach((child, key) => {
              if (child.id === item.createdBy) {
                item['name'] = child.firstName + ' ' + child.lastName;
              }
            });
          });
        });
    }
  }

  resetValue() {
    this.imgInfo.customAlbums = [];
    this.imgInfo.publishedBy = [];
    this.imgInfo.rejectedBy = [];
    this.imgInfo.excludedBy = [];
    this.imgInfo.generalAlbums = [];
    this.imgInfo.approvedBy = [];
    this.imgInfo.excluded = false;
    this.imgInfo.metaKeywords = [];
    this.imgInfo.metaSource = '';
    this.imgInfo.metaDescription = '';
  }

  setValues() {
    if (
      this.selectedImages.length > 0 &&
      Array.from(this.selectedImages)[0].publishedTags &&
      this.selectedImages[0].publishedTags.length > 0
    ) {
      Array.from(this.selectedImages)[0].publishedTags.forEach(
        (publishedTag) => {
          const index = this.projectDetail.projectTagListDTO
            .map(function (e) {
              return e.id;
            })
            .indexOf(Number(publishedTag));
          if (index !== -1) {

            const isUserFinalizedAsNonTalent = this.isFinalizedByNonTalentUser(this.selectedImages[0], this.projectDetail.projectTagListDTO[index].tagName);

            this.imgInfo.publishedBy.push({
              label: this.projectDetail.projectTagListDTO[index].tagName,
              isUserFinalizedAsNonTalent,
            });
          }
        },
      );
    }

    // FIXME: Cannot read property 'forEach' of undefined
    if (
      this.selectedImages.length > 0 &&
      Array.from(this.selectedImages)[0].generalTags &&
      this.selectedImages[0].generalTags.length > 0
    ) {
      Array.from(this.selectedImages)[0].generalTags.forEach((generalTags) => {
        const index = this.projectDetail.projectTagListDTO
          .map(function (e) {
            return e.id;
          })
          .indexOf(Number(generalTags));
        if (index !== -1) {

          const isUserFinalizedAsNonTalent = this.isFinalizedByNonTalentUser(this.selectedImages[0], this.projectDetail.projectTagListDTO[index].tagName);

          this.imgInfo.publishedBy.push({
            label: this.projectDetail.projectTagListDTO[index].tagName,
            isUserFinalizedAsNonTalent,
          });
        }
      });
    }

    // FIXME: Cannot read property 'forEach' of undefined
    if (
      this.selectedImages.length > 0 &&
      Array.from(this.selectedImages)[0].generalAlbums &&
      this.selectedImages[0].generalAlbums.length > 0
    ) {
      for (
        let i = 0;
        i < Array.from(this.selectedImages)[0].generalAlbums.length;
        i++
      ) {
        const index = this.projectDetail.projectTagListDTO
          .map((e) => e.id)
          .indexOf(Number(Array.from(this.selectedImages)[0].generalAlbums[i]));
        if (index !== -1) {
          this.imgInfo.generalAlbums.push(
            this.projectDetail.projectTagListDTO[index].tagName,
          );
        }
      }
    }

    if (this.selectedImages.length > 0 && this.selectedImages[0].rejectedBy) {
      Array.from(this.selectedImages)[0].rejectedBy.forEach((rejectedBy) => {
        const index = this.projectDetail.projectTagListDTO
          .map((e) => e.id)
          .indexOf(rejectedBy.id);
        if (index !== -1) {
          const obj = {
            tagName: this.projectDetail.projectTagListDTO[index].tagName,
            status: rejectedBy.value,
          };
          this.imgInfo.rejectedBy.push(obj);
        }
      });
    }

    if (this.selectedImages.length > 0 && this.selectedImages[0].excludedFrom) {
      Array.from(this.selectedImages)[0].excludedFrom.forEach((excludedBy) => {
        const index = this.projectDetail.projectTagListDTO
          .map((e) => e.id)
          .indexOf(Number(excludedBy));
        if (index !== -1) {
          this.imgInfo.excludedBy.push(
            this.projectDetail.projectTagListDTO[index].tagName,
          );
        }
      });
    }

    if (this.selectedImages.length > 0 && this.selectedImages[0].approvedBy) {
      Array.from(this.selectedImages)[0].approvedBy.forEach((approvedBy) => {
        const index = this.projectDetail.projectTagListDTO
          .map((e) => e.id)
          .indexOf(approvedBy.id);
        if (index !== -1) {
          this.imgInfo.approvedBy.push({
            tagName: this.projectDetail.projectTagListDTO[index].tagName,
            status: approvedBy.value,
          });
        }
      });
    }

    if (
      this.selectedImages.length > 0 &&
      Array.from(this.selectedImages)[0].batchId
    ) {
      const batchIndex = this.projectDetail.batchListDTO
        .map(function (e) {
          return e.value;
        })
        .indexOf(Array.from(this.selectedImages)[0].batchId);
      if (batchIndex !== -1) {
        this.imgInfo.batch = this.projectDetail.batchListDTO[batchIndex].label;
      }
    }
  }

  saveImageNote() {
    const imageNoteText = this.noteForm.get('imageNoteText').value;
    const noteType = this.noteForm.get('noteType').value;
    if (imageNoteText.trim() !== '') {
      const assetIds = [];
      if (!this.isForCompareView) {
        if (this.isEnlargeView) {
          assetIds.push(this.lastSelectedImageStore.getItemsIds());
        } else {
          assetIds.push(this.selectedImagesStore.getItemsIds());
        }
      } else {
        assetIds.push(this.getSelectedImageIDsForCompare());
      }

      const data = {
        assetIds: assetIds[0],
        currentAssetIds: this.isEnlargeView
          ? assetIds[0]
          : Utils.getImageIdsBasedOnPage(
              this.currentPageNumber,
              this.noOfImages,
              this.projectImageIds,
            ),
        type: ProjectRole.ROLE_TALENT === this.projectAuthority ? 2 : noteType,
        value: imageNoteText.trim(),
        albumId: this.isExecutiveAlbumView ? this.albumId : 0,
        projectId: this.viewProjectService.getProjectId(),
      };
      this.createAssetsNotes(data);
      this.closeNotePopup.emit();
    } else {
      this.alertCustom(this.translate.instant('view-project.note_empty'));
    }
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  isFinalizedByNonTalentUser(assetData, tagName: string) {
    const foundGeneralTag = this.FINALIZED_BY.find(nonTalentUser => nonTalentUser.tagName === tagName);

    if (!foundGeneralTag || !assetData || !assetData.finalizedBy.length) {
      return false;
    }

    const foundGeneralTagWithId = this.projectDetail.projectTagListDTO.find(tag => tag.tagName === foundGeneralTag.tagName);

    return !!foundGeneralTagWithId && assetData.finalizedBy.some(item => item.id === foundGeneralTagWithId.id);
  }

  createAssetsNotes(data) {
    this.loaderService.displayLoader(true);
    this.imageInfoService
      .callCreateAssetsNotes(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.projectImageData = this.viewProjectService.mergeWithProjectData(
            this.projectImageData,
          );
          const notes = res;
          const createdBy = [];
          createdBy.push(res[0].assetNoteResponseDTO.createdBy);
          this.userDataService
            .getUserData(this.viewProjectService.getProjectId(), createdBy)
            .pipe(takeUntil(this.destroy$))
            .subscribe((value) => {
              this.projectImageData.assetInfoDTOList.forEach((imageObj) => {
                notes.forEach((updatedNoteObj) => {
                  if (updatedNoteObj.assetId === imageObj.id) {
                    updatedNoteObj.assetNoteResponseDTO['name'] =
                      value[0].firstName + ' ' + value[0].lastName;
                    if (
                      !ArrayUtils.hasDuplicates(
                        imageObj.assetNoteDTOList,
                        (v) => v.id === updatedNoteObj.assetNoteResponseDTO.id,
                      )
                    ) {
                      imageObj.assetNoteDTOList.push(
                        updatedNoteObj.assetNoteResponseDTO,
                      );
                    }
                  }
                });
              });

              if (this.isForCompareView) {
                const comareImages =
                  this.compareService.getSelectedImagesCompare();
                Array.from(comareImages).forEach((selectedCOmpareImage) => {
                  notes.forEach((updatedNoteObj) => {
                    if (updatedNoteObj.assetId === selectedCOmpareImage[1].id) {
                      updatedNoteObj.assetNoteResponseDTO['name'] =
                        value[0].firstName + ' ' + value[0].lastName;
                      if (
                        !ArrayUtils.hasDuplicates(
                          selectedCOmpareImage[1]['assetNoteDTOList'],
                          (v) =>
                            v.id === updatedNoteObj.assetNoteResponseDTO.id,
                        )
                      ) {
                        selectedCOmpareImage[1]['assetNoteDTOList'].push(
                          updatedNoteObj.assetNoteResponseDTO,
                        );
                      }
                    }
                  });
                });
              }

              this.loaderService.displayLoader(false);
              if (this.hideDataNoteListOption) {
                this.closeNotePopupObj['close'] = false;
                this.closeNotePopupObj['noteSave'] = true;
                this.closeWindow();
              }

              this.submitNoteData = {
                imageNoteText: '',
                noteType: 1,
              };
            });
          try {
            this.myScrollContainer.nativeElement.scrollTop =
              this.myScrollContainer.nativeElement.scrollHeight + 30;
          } catch (err) {
            //**//
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);

          this.loaderService.displayLoader(false);
          if (this.hideDataNoteListOption) {
            this.closeNotePopupObj['close'] = false;
            this.closeNotePopupObj['noteSave'] = true;
            this.closeWindow();
          }
          this.submitNoteData = {
            imageNoteText: '',
            noteType: 1,
          };
        },
      );
  }

  closeWindow() {
    this.closeNotePopup.emit();
  }
}
