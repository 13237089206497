<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex align-center justify-content-between"
>
  <div class="modal-title">{{ 'top-menu.linkTool.title' | translate }}</div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<form [formGroup]="adhocLinkForm">
  <div mat-dialog-content class="theme-form form-horizontal" autocomplete="off">
    <div class="form-group row">
      <label class="control-label col-3">
        {{ 'top-menu.linkTool.labels.to' | translate }}
        <span class="required-indicator">*</span>
      </label>
      <div class="col-9 fs-chips">
        <mat-form-field class="fs-chip-list" appearance="fill">
          <mat-chip-grid #toEmail formControlName="to" class="w-100">
            <ng-container *ngIf="adhocLinkForm?.controls?.to">
              <mat-chip-row
                *ngFor="
                  let email of adhocLinkForm.controls.to.value;
                  let i = index
                "
                [removable]="true"
                (removed)="onRemoveEmailId(i)"
              >
                {{ email }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip-row>
            </ng-container>

            <input
              matInput
              [placeholder]="'top-menu.sendMail.to' | translate"
              [matChipInputFor]="toEmail"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="onAdValidateEmail($event)"
            />
          </mat-chip-grid>
        </mat-form-field>
      </div>
    </div>

    <div
      class="form-group row"
      *ngIf="adhocLinkForm.get('to').errors?.invalidEmailList"
    >
      <div class="col-3"></div>
      <div class="col-9">
        <div class="fs-text-color-red">
          {{ 'top-menu.linkTool.error.invalidEmailList' | translate }}
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="control-label col-3">
        {{ 'top-menu.linkTool.labels.subject' | translate }}
        <span class="required-indicator">*</span>
      </label>
      <div class="col-9">
        <input
          type="text"
          formControlName="subject"
          maxlength="100"
          [placeholder]="'top-menu.linkTool.labels.subject' | translate"
          class="form-control sendmail-msg pl-3"
        />
        <div class="w-100 d-flex fs-txt-white-dark-color justify-content-end">
          {{ adhocLinkForm.value.subject.length }}/100
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="control-label col-3">{{
        'top-menu.linkTool.labels.message' | translate
      }}</label>
      <div class="col-9">
        <textarea
          class="form-control sendmail-msg send-mail-msg pl-3"
          rows="3"
          name="message"
          formControlName="message"
          [placeholder]="'top-menu.linkTool.labels.message' | translate"
        >
        </textarea>
      </div>
    </div>

    <div class="expires-checkbox-wrapper">
      <div class="form-group row">
        <mat-checkbox
          name="isLinkExpire"
          class="fs-link-expire-checkbox-wrapper fs-checkbox col-3"
          [checked]="!!adhocLinkForm.get('linkExpires').value"
          (change)="addLinkExpireNumber($event)"
        >
          <span class="cb-title">
            {{ 'top-menu.linkTool.labels.linkExpire' | translate }}</span
          >
        </mat-checkbox>
        <div class="col-9 d-flex align-items-center">
          <div class="mf-sngle">
            <mat-radio-group formControlName="linkExpires">
              <div>
                <mat-radio-button
                  name="linkExpires"
                  class="fs-radio-button"
                  value="1"
                  checked
                >
                  <span class="cb-title fs-sm-txt">1</span>
                </mat-radio-button>
              </div>

              <div>
                <mat-radio-button
                  name="linkExpires"
                  class="fs-radio-button"
                  value="2"
                >
                  <span class="cb-title fs-sm-txt">2</span>
                </mat-radio-button>
              </div>

              <div>
                <mat-radio-button
                  name="linkExpires"
                  class="fs-radio-button"
                  value="4"
                >
                  <span class="cb-title fs-sm-txt">4</span>
                </mat-radio-button>
              </div>

              <div class="fs-radio-button">
                <mat-radio-button
                  name="linkExpires"
                  class="fs-radio-button"
                  value="7"
                >
                  <span class="cb-title fs-sm-txt">7</span>
                </mat-radio-button>
              </div>

              <div>
                <mat-radio-button
                  name="linkExpires"
                  class="fs-radio-button"
                  value="14"
                >
                  <span class="cb-title fs-sm-txt">14</span>
                </mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="form-group row" formGroupName="numberOfViews">
        <label class="control-label col-3">
          {{ 'top-menu.linkTool.labels.numberViews' | translate }}
        </label>

        <div class="col-9 d-flex align-items-center mb-2">
          <div class="mf-sngle number-of-views mb-0">
            <mat-radio-group formControlName="isCheckedValue">
              <div>
                <mat-radio-button
                  class="radio-button fs-radio-button"
                  name="isCheckedValue"
                  value="unlimited"
                  (change)="numberOfViewsRadioChange($event)"
                  checked
                >
                  <span class="radio-title fs-sm-txt">{{
                    'top-menu.linkTool.labels.unlimited' | translate
                  }}</span>
                </mat-radio-button>
              </div>

              <div>
                <mat-radio-button
                  name="isCheckedValue"
                  class="radio-button w-auto fs-radio-button"
                  (change)="numberOfViewsRadioChange($event)"
                  value="limitto"
                >
                  <span class="radio-title fs-sm-txt">
                    {{ 'top-menu.linkTool.labels.limitTo' | translate }}
                  </span>
                </mat-radio-button>
                <input
                  #limitTo
                  type="number"
                  disableCharacterInputDirective
                  formControlName="value"
                  name="value"
                />
              </div>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="form-group row" formGroupName="addPassphrase">
        <ng-container *ngIf="!isAdhocExtraSecured; else securedPassphraseBlock">
          <div class="col-3">
            <mat-checkbox
              name="isChecked"
              class="fs-checkbox"
              formControlName="isChecked"
              (change)="addPassphraseCheackBoxChange($event)"
            >
              <span class="cb-title">{{
                'top-menu.linkTool.labels.Passphrase' | translate
              }}</span>
            </mat-checkbox>
          </div>
        </ng-container>

        <div class="col-3 d-flex align-items-center">
          <input
            #passPhrase
            type="text"
            class="pr-4"
            name="passwordPassphrase"
            fsPasswordVisibility
            [inputDisabled]="
              !this.isAdhocExtraSecured &&
              adhocLinkForm.get('addPassphrase').get('value').disabled
            "
            formControlName="value"
          />
        </div>

        <div class="col-6 fs-txt-white-dark-color">
          <span class="glyphicon glyphicon-info-sign"></span>
          {{ 'top-menu.linkTool.error.passphrase' | translate }}
        </div>
      </div>

      <div class="form-group row" *ngIf="isAdhocExtraSecured">
        <label class="control-label col-3"
          >{{ 'top-menu.linkTool.labels.secondEmail' | translate }}
          <span class="required-indicator">*</span>
        </label>

        <div class="col-9 fs-chips">
          <mat-form-field class="fs-mail-chip-list" appearance="fill">
            <mat-chip-list
              #passwordEmails
              formControlName="passwordEmails"
              class="w-100"
            >
              <ng-container *ngIf="adhocLinkForm?.controls?.passwordEmails">
                <mat-chip
                  *ngFor="
                    let email of adhocLinkForm.controls.passwordEmails.value;
                    let i = index
                  "
                  [removable]="true"
                  (removed)="onRemovePasswordEmailsId(i)"
                >
                  {{ email }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </ng-container>

              <input
                fsAutofocus
                [placeholder]="
                  'top-menu.sendMail.secondEmailPlaceholder' | translate
                "
                [matChipInputFor]="passwordEmails"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="onAdValidatePasswordEmails($event)"
              />
            </mat-chip-list>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group row" *ngIf="isPasswordEmailsInvalid()">
        <div class="col-3"></div>
        <div class="col-9">
          <div class="fs-text-color-red">
            {{ 'top-menu.linkTool.error.invalidEmailList' | translate }}
          </div>
        </div>
      </div>

      <div
        class="form-group row"
        *ngIf="adhocLinkForm.hasError('fieldsMatch', 'passwordEmails')"
      >
        <div class="col-3"></div>
        <div class="col-9">
          <div class="fs-text-color-red">
            {{ 'top-menu.linkTool.error.fieldsMatch' | translate }}
          </div>
        </div>
      </div>

      <div class="form-group row" formGroupName="allowDownload">
        <div class="fs-download-label-container">
          <mat-checkbox
            name="isChecked"
            class="fs-checkbox"
            formControlName="isChecked"
            (change)="allowDownloadCheackBoxChange()"
          >
            <span class="cb-title">
              {{ 'top-menu.linkTool.labels.allowDownload' | translate }}
            </span>
          </mat-checkbox>
        </div>

        <div class="fs-download-checkbox-container">
          <div class="mf-sngle allow-download">
            <div *ngFor="let type of downloadTypes">
              <mat-checkbox
                name="value"
                [value]="type.id"
                class="fs-checkbox"
                [disabled]="!!disabledAllowDownloadValue"
                [checked]="
                  this.adhocLinkForm.value.downloadTypeValue.indexOf(type.id) >=
                  0
                "
                (change)="
                  allowDownloadTypeCheackBoxChange(
                    $event,
                    type.id,
                    'downloadTypeValue'
                  )
                "
              >
                <span class="cb-title">{{ type.trans }}</span>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="
          ((userAuthority === userRole.ROLE_ADMIN ||
            (projectAuthority === projectRole.ROLE_EXEC && expressDownload)) &&
          !hasWatermark) && minExpressDownload <= selectedImagesStore.size()
        "
        class="form-group row"
        formGroupName="allowExpressDownload"
      >
        <div class="fs-download-label-container">
          <mat-checkbox
            name="isChecked"
            class="fs-checkbox"
            formControlName="isChecked"
            (change)="allowExpressDownloadCheackBoxChange()"
          >
            <span class="cb-title">
              {{ 'top-menu.linkTool.labels.allowExpressDownload' | translate }}
            </span>
          </mat-checkbox>
        </div>

        <div class="fs-download-checkbox-container">
          <div class="mf-sngle allow-download">
            <div *ngFor="let type of expressDownloadTypes">
              <mat-checkbox
                name="value"
                [value]="type.id"
                class="fs-checkbox"
                [disabled]="!!disabledExpressAllowDownloadValue"
                [checked]="
                  this.adhocLinkForm.value.expressDownloadTypeValue.indexOf(
                    type.id
                  ) >= 0
                "
                (change)="
                  allowDownloadTypeCheackBoxChange(
                    $event,
                    type.id,
                    'expressDownloadTypeValue'
                  )
                "
              >
                <span class="cb-title">{{ type.trans }}</span>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="btm-images">
      <div class="clearfix link-img-ul">
        <cdk-virtual-scroll-viewport
          class="fs-virtual-scroll-content-margin"
          minBufferPx="24"
          maxBufferPx="832"
          itemSize="24"
        >
          <div
            class="d-flex align-items-center pl-1 pr-1 mr-2 align-content image-card-container flex-column"
            *cdkVirtualFor="let selectedImage of selectedImages; index as ind"
          >
            <div class="is-img">
              <img
                [lazySrc]="
                  viewProjectService.getWebImageURL(selectedImage, '125')
                "
                alt=""
              />
            </div>
            <div class="is-name">{{ selectedImage.name }}</div>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="mf-sngle">
        <div>
          <mat-checkbox
            name="rememberSetting"
            class="fs-checkbox"
            formControlName="rememberSetting"
            checked
          >
            <span class="cb-title">{{
              'top-menu.linkTool.labels.rememberSetting' | translate
            }}</span>
          </mat-checkbox>
        </div>
      </div>

      <div class="mf-sngle d-flex justify-content-end">
        <button class="fs-dialog-btn w-auto ml-3" (click)="onResetForm()">
          {{ 'top-menu.linkTool.buttons.reset' | translate }}
        </button>

        <button
          class="fs-dialog-btn w-auto ml-3"
          (click)="submitForm()"
          data-dismiss="modal"
          [disabled]="!adhocLinkForm.valid"
        >
          {{ 'top-menu.linkTool.buttons.send' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>

<ng-template #securedPassphraseBlock>
  <div class="col-3">
    <label class="control-label">{{
      'top-menu.linkTool.labels.Passphrase' | translate
    }}</label>
    <span class="required-indicator"> *</span>
  </div>
</ng-template>
