import {
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formData } from './printtool.data';
import { NavigationEnd, Router } from '@angular/router';
import { PrinttoolService } from './printtool.service';
import { DownloadService } from '../download/download.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { DownloadManagerService } from '../../../../../shared/layout/download-manager/download-manager.service';
import { SelectedImages } from '../../../../../shared/models/selectedImages.model';
import { Modalstatusservice } from '../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../shared/constants/constants';
import { switchMap, takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../shared/store/selected-images-store';
import { LastSelectedImageStore } from '../../../../../shared/store/last-selected-image-store';
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';
import { FilterEnum } from '../../../../../shared/enum/filter.enum';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import { UserRole } from '../../../../../shared/enum/user-role.enum';
import { Utils } from '../../../../../shared/utils/utils';
import { UserService } from '../../../../../shared/services/user.service';
import { tap } from 'rxjs/internal/operators/tap';
import { IpService } from '../../../../../shared/services/ip-service.service';
import { SelectedImage } from '../download/download.model';
import { ProjectRole } from '../../../../../shared/enum/project-role.enum';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-printtool',
  templateUrl: './printtool.component.html',
  styleUrls: ['./printtool.component.scss'],
  providers: [DownloadService],
})
export class PrinttoolComponent
  extends UnsubscriberComponent
  implements OnInit
{
  @ViewChild('toemail') toemail;

  data = formData;
  selectedImages2: SelectedImages[] = [];
  lastSelectedImage2: SelectedImages[] = [];
  isEnlargeView = false;

  imageUrl = '/assets/images/1UP.png';
  selectedValue = {
    angle: 0.0,
    imageSize: 1600,
    layout: 1,
    pageSize: { width: 8.5, height: 11 },
    gutter: 'No Gutter',
    imageCount: 0,
    imagePerPage: 0,
    totalPages: 0,
    title: '',
    copyRight: {
      status: false,
      value: '',
    },
    opacity: 0.1,
    photoCredit: {
      status: false,
      value: '',
    },
    date: {
      status: true,
      value: '' || new Date(),
    },
    watermark: {
      status: false,
      value: '',
    },
    autoSize: false,
    size: 100,
    email: [],
  };

  projectAuthority: string;
  userAuthority: string;
  isToEmailIdValid = false;
  emailAccess = false;
  isEmailAvailable = false;
  arrListEmailTo: any = [];
  isToemailFocus = false;
  toEmail: any = [];
  public min: Date;
  constantsGlobalData: any;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  constructor(
    private router: Router,
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private dialogService: DialogService,
    private viewProjectService: ViewProjectService,
    private printtoolService: PrinttoolService,
    private loaderService: LoaderService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private ipService: IpService,
    private userService: UserService,
    private downloadService: DownloadService,
    private downloadManagerService: DownloadManagerService,
    private dialogRef: MatDialogRef<PrinttoolComponent>,
    private selectedImagesStore: SelectedImagesStore,
    private lastSelectedImageStore: LastSelectedImageStore,
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
  ) {
    super();
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const date = new Date().getDate();
    this.min = new Date(year, month, date);
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.search('enlarge') !== -1 ||
          event.url.search('compare') !== -1
        ) {
          this.isEnlargeView = true;
        } else {
          this.isEnlargeView = false;
        }
      }
    });

    this.selectedValue.date.value = new Date();

    this.selectedImagesStore
      .getCollection()
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageArray) => {
        this.selectedImages2 = imageArray;
        if (this.selectedImagesStore.hasEntities()) {
          this.onChangeSelect('layout', 1);
          this.selectedValue.imageCount = this.selectedImagesStore.size();
        }
      });

    this.lastSelectedImageStore
      .getCollection()
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageArray) => {
        this.lastSelectedImage2 = imageArray;
        if (this.isEnlargeView) {
          this.selectedImages2 = this.lastSelectedImage2;
        }
      });

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority) => {
        this.userAuthority = userAuthority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(
        switchMap((projectAuthority) => {
          this.projectAuthority = projectAuthority;

          return this.viewProjectService.projectDetailPermissionGLobal.pipe(
            tap((res) => {
              this.selectedValue.title = res.projectGroupName;
              this.setAllPermission(res);
            }),
          );
        }),
      )
      .subscribe(() => {
        this.isEmailAvailable =
          ProjectRole.ROLE_EXEC === this.projectAuthority &&
          this.userAuthority === UserRole.ROLE_EXTERNAL
            ? this.emailAccess
            : true;
      });

    setTimeout(() => {
      this.selectedValue.title =
        this.viewProjectService.getProjectDetailPermissionData().projectGroupName;
    }, 0);
  }

  closeWindow() {
    this.imageUrl = '/assets/images/1UP.png';
    this.arrListEmailTo = [];
    this.resetObject();
    this.dialogRef.close();
  }

  onChangeSelect(type, value) {
    if (value) {
      this.selectedValue[type] = value;
      if (type === 'layout') {
        this.selectedValue.totalPages =
          Math.round(this.selectedImages2.length / value) === 0
            ? 1
            : Math.ceil(this.selectedImages2.length / value);
        this.imageUrl = '/assets/images/' + value + 'UP.png';
      }
    }
  }

  setAllPermission(res) {
    this.emailAccess = res.permissionDTO.emailAccess;
  }

  onAdValidateEmail(event: MatChipInputEvent, whichEmil: string) {
    this.callOnAddTabEmail(event, whichEmil);
  }

  onRemoveEmailId(whichEmil: string, index: number) {
    switch (whichEmil) {
      case 'to':
        this.arrListEmailTo.splice(index, 1);
        this.selectedValue.email = [...this.arrListEmailTo];
        break;
    }
  }

  callOnAddTabEmail(event: MatChipInputEvent, whichEmil: string) {
    const lastAtPos = event.value.lastIndexOf('@');
    const lastDotPos = event.value.lastIndexOf('.');
    const isEmailValid =
      lastAtPos < lastDotPos &&
      lastAtPos > 0 &&
      event.value.indexOf('@@') === -1 &&
      lastDotPos > 2 &&
      event.value.length - lastDotPos > 2;

    if (isEmailValid) {
      switch (whichEmil) {
        case 'to':
          if (
            (!this.arrListEmailTo.length ||
              !this.arrListEmailTo.includes(event.value)) &&
            event.value
          ) {
            this.arrListEmailTo = [...this.arrListEmailTo, event.value];
            this.selectedValue.email = [...this.arrListEmailTo];
          }

          event.input.value = '';
          break;
      }
    }
  }

  submitPrintTool(type) {
    let payload = {};
    if (this.selectedImages2.length > 1) {
      switch (type) {
        case 'email':
          this.loaderService.displayLoader(true);
          this.preparePayload('email');
          break;
        case 'print':
          this.loaderService.displayLoader(true);
          this.preparePayload('print');
          break;
        case this.constantsGlobalData.MODELNAMES.DOWNLOAD:
          this.preparePayload(this.constantsGlobalData.MODELNAMES.DOWNLOAD);
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case 'email':
          this.loaderService.displayLoader(true);
          payload = this.preparePayload('email');
          break;
        case 'print':
          this.loaderService.displayLoader(true);
          payload = this.preparePayload('print');
          break;
        case this.constantsGlobalData.MODELNAMES.DOWNLOAD:
          payload = this.preparePayload(
            this.constantsGlobalData.MODELNAMES.DOWNLOAD,
          );
          break;
        default:
          break;
      }

      this.printtoolService.postPrintTool(payload).subscribe((res) => {
        this.loaderService.displayLoader(false);
        const fnName = type + 'Action';
        this[fnName](res);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
      this.closeWindow();
    }
  }

  downloadAction(res) {
    const httpParams = this.downloadManagerService.setHttpDownloadParams(
      this.ipService.userIPAddress$,
      this.userService.getCurrentUser().id,
      this.userService.getFullUserName(),
      this.dialogData.t.map((item: SelectedImage) => item.id),
      this.userAuthority,
      this.viewProjectService.getProjectId(),
      this.viewProjectService.getProjectDetailPermissionData().projectName,
      res.t.size,
      this.ipService.browserInfo$,
      '',
      true,
    );

    if (res) {
      this.downloadManagerService
        .pushDownloadItemForPDF(res, httpParams)
        .subscribe(
          () => {},
          (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
      this.closeWindow();
    }
  }

  emailAction(res) {
    if (res.status) {
      this.dialogService.openInformationDialog({
        title: this.translate.instant('view-project.confirm22'),
        message: res.message,
      });
    }
  }

  printAction(res) {
    this.downloadAction(res);
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  getImageSizeLabel(value): string {
    return formData.imageSize.find((item) => item.value === Number(value))
      .label;
  }

  preparePayload(action) {
    let payload = {};
    if (this.constantsGlobalData.MODELNAMES.DOWNLOAD === action) {
      this.alertCustom(
        this.translate.instant('top-menu.printTool.labels.downloadAlert'),
      );
    }

    if (this.selectedImages2.length > 1) {
      const downloadItems = this.downloadManagerService.getDownloadItems();
      const downloadItemDetails = {
        url: '',
        id: downloadItems.length,
        downloadedPercent: -1,
        filename: 'Preparing PDF download',
        fileSize: 0,
      };

      if (this.constantsGlobalData.MODELNAMES.DOWNLOAD === action) {
        this.downloadManagerService.setIsDownloadEnabled(true);
        this.downloadManagerService.setDownloadItems([
          ...downloadItems,
          downloadItemDetails,
        ]);
      }

      const data = {
        assetIds: this.selectedImagesStore.getItemsIds(),
        projectId: this.viewProjectService.getProjectId(),
        filter: FilterEnum.ShowAll,
      };

      this.viewProjectService
        .callSingleAssetsInfoBasedOnAssetsId(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          payload = {
            action: action,
            ip: this.ipService.userIPAddress$,
            browserInfo: this.ipService.browserInfo$,
            userId: this.userService.getCurrentUser().id,
            authorityName: this.userAuthority,
            assetIds: this.dialogData.t.map((item: SelectedImage) => item.id),
            userName: this.userService.getFullUserName(),
            angle:
              this.selectedValue.angle === 1 ? -1 : this.selectedValue.angle,
            assetSize: this.selectedValue.imageSize,
            imageSize: this.getImageSizeLabel(this.selectedValue.imageSize),
            isPrint: this.constantsGlobalData.MODELNAMES.DOWNLOAD === action,
            assetUrlRequestDTOList: this.dialogData.t,
            autoSize: this.selectedValue.autoSize,
            copyrightTitle: this.selectedValue.copyRight.value,
            date: this.selectedValue.date.status
              ? this.changeDateFormate(this.selectedValue.date.value)
              : '',
            gutter: this.getGutter(),
            layout: this.selectedValue.layout,
            location: '',
            opacity: this.selectedValue.opacity,
            pageSize: 1,
            photoCredit: this.selectedValue.photoCredit.value,
            projectGroupName:
              this.viewProjectService.getProjectDetailPermissionData()
                .projectGroupName,
            projectId: this.viewProjectService.getProjectId(),
            totalPages: this.selectedValue.totalPages,
            projectName:
              this.viewProjectService.getProjectDetailPermissionData()
                .projectName,
            size: this.selectedValue.size,
            title: this.selectedValue.title,
            to: this.selectedValue.email,
            watermark: this.selectedValue.watermark.value,
            locator:
              this.viewProjectService.getProjectDetailPermissionData().locator,
            downloadId: downloadItemDetails.id,
            token: this.dialogData.message,
          };

          this.printtoolService.postPrintTool(payload).subscribe((res) => {
            this.loaderService.displayLoader(false);
            const fnName = action + 'Action';
            this[fnName](res);
          });
          this.closeWindow();
        });
    } else {
      payload = {
        ip: this.ipService.userIPAddress$,
        browserInfo: this.ipService.browserInfo$,
        userId: this.userService.getCurrentUser().id,
        authorityName: this.userAuthority,
        assetIds: this.dialogData.t.map((item: SelectedImage) => item.id),
        userName: this.userService.getFullUserName(),
        action: action,
        angle: this.selectedValue.angle,
        assetSize: this.selectedValue.imageSize,
        imageSize: this.getImageSizeLabel(this.selectedValue.imageSize),
        assetUrlRequestDTOList: this.dialogData.t,
        autoSize: this.selectedValue.autoSize,
        copyrightTitle: this.selectedValue.copyRight.value,
        date: this.selectedValue.date.status
          ? this.changeDateFormate(this.selectedValue.date.value)
          : '',
        gutter: this.getGutter(),
        layout: this.selectedValue.layout,
        location: '',
        isPrint: this.constantsGlobalData.MODELNAMES.DOWNLOAD === action,
        opacity: this.selectedValue.opacity,
        totalPages: this.selectedValue.totalPages,
        pageSize: 1,
        photoCredit: this.selectedValue.photoCredit.value,
        projectGroupName:
          this.viewProjectService.getProjectDetailPermissionData()
            .projectGroupName,
        projectId: this.viewProjectService.getProjectId(),
        projectName:
          this.viewProjectService.getProjectDetailPermissionData().projectName,
        size: this.selectedValue.size,
        title: this.selectedValue.title,
        to: this.selectedValue.email,
        watermark: this.selectedValue.watermark.value,
        locator:
          this.viewProjectService.getProjectDetailPermissionData().locator,
        downloadId: -1,
        token: this.dialogData.message,
      };
      return payload;
    }
  }

  getGutter() {
    let returnValue = 0;
    switch (this.selectedValue.gutter) {
      case 'No Gutter':
        returnValue = 0;
        break;
      case '1/2 left margin':
        returnValue = 1;
        break;
    }
    return returnValue;
  }

  changeDateFormate(currentDate) {
    const today = new Date(currentDate);
    let dd = today.getDate();
    let mm = today.getMonth() + 1; // January is 0!
    const yyyy = today.getFullYear();
    if (dd < 10) {
      dd = Number('0' + dd);
    }
    if (mm < 10) {
      mm = Number('0' + mm);
    }
    return mm + '/' + dd + '/' + yyyy;
  }

  resetObject() {
    this.selectedValue = {
      angle: 0.0,
      imageSize: 1600,
      layout: 1,
      pageSize: {
        width: 8.5,
        height: 11,
      },
      gutter: 'No Gutter',
      imageCount: this.selectedImages2.length,
      imagePerPage: 1,
      totalPages: this.selectedImages2.length,
      title:
        this.viewProjectService.getProjectDetailPermissionData()
          .projectGroupName,
      copyRight: {
        status: false,
        value: '',
      },
      photoCredit: {
        status: false,
        value: '',
      },
      date: {
        status: true,
        value: new Date(),
      },
      opacity: 0.1,
      watermark: {
        status: false,
        value: '',
      },
      autoSize: false,
      size: 100,
      email: [],
    };
  }

  selectPageSizeValueComparator = (a, b) =>
    a?.height === b?.height && a?.width === b?.width;
}
